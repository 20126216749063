import {
    ICarouselSelector,
    ICarouselData,
    IGroupedCarousel
} from "./carousel.interface";

export function getSegmentCarousels(selectors: Array<ICarouselSelector>,
                                    className: string,
                                    title: string): Array<ICarouselData>
{
    if (!selectors)
    {
        return [];
    }

    const selector = selectors.find((selector: ICarouselSelector) => selector.class === className);

    const segment = selector.segments.find(segmentRow =>
    {
        return segmentRow.carousels.length > 0
            && segmentRow.title.toLowerCase() === title;
    });

    return segment && segment.carousels.length > 0 ? segment.carousels : [];
}

export function getSegmentCarousel(selectors: Array<ICarouselSelector>,
                                   className: string,
                                   title: string,
                                   index: number,
                                   segmentClass?: string): ICarouselData
{
    if (!selectors)
    {
        return { tiles: [] };
    }

    const selector = selectors.find((selector: ICarouselSelector) => selector.class === className);

    if(!selector)
    {
        return { tiles: [] };
    }

    const segment = selector.segments.find(segmentRow =>
    {
        const isSegmentAvailable = segmentClass ? segmentRow.class === segmentClass :
                                        segmentRow.title.toLowerCase() === title;
        return segmentRow.carousels.length > 0
            && isSegmentAvailable;
    });

    return segment && segment.carousels.length > 0 ? segment.carousels[ index ] : { tiles: [] };
}

/**
 * Used to get the grouped carousels for both podcasts/on_demand, comparing the segmentClass
 * which is meant to be same though title toggles b/w podcasts and on-demand
 * @param {ICarouselSelector} selectors
 * @param {string} className
 * @param {string} segmentClass
 * @returns {IGroupedCarousel}
 */

export function getSegmentGroupedCarousel(selectors: Array<ICarouselSelector>,
                                   selectorClassName: string,
                                   segmentClass: string): Array<IGroupedCarousel>
{
    if (!selectors)
    {
        return [];
    }

    const selector = selectors.find((selector: ICarouselSelector) => selector.class === selectorClassName);

    if(!selector)
    {
        return [];
    }

    const segment = selector.segments.find(segmentRow =>
    {
        return segmentRow.groupedCarousels.length > 0
            && segmentRow.class.toLowerCase() === segmentClass;
    });

    return segment && segment.groupedCarousels.length > 0 ? segment.groupedCarousels : [];
}
