import {
         CarouselTypeConst,
         ITile,
         MediaUtil
} from "../index";

export class CarouselUtil{

    /**
     * Returns content type of the carousel tile
     * @param tile
     */
    public static getContentType(tile: ITile): string
    {
        let contentType: string = tile.tileContentType;
        if(CarouselUtil.isChannelTile(tile))
        {
            contentType = MediaUtil.isMultiTrackAudioMediaType(tile.tileContentSubType)
                          ? tile.tileContentSubType : tile.tileContentType;
        }
        else if(CarouselUtil.isEpisodeTile(tile))
        {
            contentType = tile.tileContentSubType;
        }
        else if(CarouselUtil.isShowTile(tile))
        {
            contentType = tile.tileContentType;
        }
        return contentType;
    }

    /** aa
     * Returns flag indicates show tile or not
     * @param tile
     * @returns {boolean}
     */
    public static isShowTile({ tileContentType = "" }: ITile): boolean
    {
        return tileContentType.toLowerCase() === CarouselTypeConst.SHOW_TILE;
    }

    /**
     * Returns flag indicates if the tile is an episode tile
     * @param tile
     * @returns {boolean}
     */
    public static isEpisodeTile({ tileContentType = "" , tileContentTypeFromAPI= ""}: ITile): boolean
    {
        return tileContentType.toLowerCase() === CarouselTypeConst.EPISODE_TILE
                    || tileContentTypeFromAPI.toLowerCase() === CarouselTypeConst.EPISODE_TILE;
    }

    /**
     * Returns whether the tile is a channel
     * @param {ITile} tile
     * @returns {boolean}
     */
    public static isChannelTile({ tileContentType = "" }: ITile): boolean
    {
        const contentType = tileContentType.toLowerCase();

        return (contentType === CarouselTypeConst.CHANNEL_TILE
            || contentType === CarouselTypeConst.LIVE_AUDIO
            || contentType === CarouselTypeConst.LIVE_CONTINUE_LISTENING_TILE
            || contentType === CarouselTypeConst.SEEDED_RADIO
            || contentType === CarouselTypeConst.LIVE_SHOW_TILE);
    }

}
