import { Logger } from "../logger/logger";
import { addProvider, IProviderDescriptor } from "../service";
import { BehaviorSubject, Observable } from "rxjs";
import { InitializationStatusCodes } from "./initialization.const";

export class InitializationModel
{
    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("TuneModel");

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(InitializationModel,
            InitializationModel,
            []);
    }();

    /**
     * Holds the state
     */
    private _state: string = InitializationStatusCodes.UNCONFIGURED;

    /**
     * subject for delivering the initialization state through an observable
     */
    private initializationStateSubject: BehaviorSubject<string> = new BehaviorSubject<string>(InitializationStatusCodes.UNCONFIGURED);

    /**
     * Internal setter for the current state of the initialization process.
     * @param value
     * @private
     */
    private set __state (value: string)
    {
        if (this._state === value) return;

        this._state = value;

        if (value === InitializationStatusCodes.RUNNING ||
            value === InitializationStatusCodes.RELOADING ||
            value === InitializationStatusCodes.UNAUTHENTICATED ||
            value === InitializationStatusCodes.UNAUTHENTICATED_RESTART ||
            value === InitializationStatusCodes.OPENACCESS)
        {
            this.initializationStateSubject.next(value);
        }
    }

    /**
     * Returns the initialization state
     */
    public get state (): string
    {
        return this._state;
    }

    /**
     * subject for delivering the initialization service state through an observable
     */
    public initializationState$: Observable<string> = this.initializationStateSubject;

    /**
     * Constructor
     */
    constructor ()
    {
        InitializationModel.logger.debug(`constructor()`);
    }

    /**
     * Sets the Initialization state
     * @param state
     */
    public setInitializationState (state: string): void
    {
        this.__state = state;
    }
}
