export interface IAppMessage {
  type: string;
  code: number;
  apiCode: number;
  resourceUrl?: string;
  retryCount?: number;
  message?: string;
}

export interface IAppMonitor {
  message: IAppMessage;
}

export interface IAppByPassState {
  GUP_BYPASS: boolean;
  GUP_BYPASS2: boolean;
  IT_BYPASS: boolean;
  VOD_BYPASS: boolean;
  AOD_BYPASS: boolean;
  SEARCH_BYPASS: boolean;
  CAROUSEL_BYPASS: boolean;
  AIC_BYPASS: boolean;
  ARTIST_RADIO_BYPASS: boolean;
}

// Need ErrorMessageState When we have multiple same response (ex-204's,) we need to handle only one at a time
// As we add more error handlers we might update this interface.

export interface IAppErrorState {
  IS_SIMULTANEOUS_LISTEN: SimultaneousListenStatus;
  IS_IT_DOWN: boolean;
}

export type SimultaneousListenStatus = 'ENABLED' | 'DISABLED' | 'CANCELED';

export const APP_ERROR_STATE_CONST: {
  [key: string]: SimultaneousListenStatus;
} = {
  SIMULTANEOUS_LISTEN_ENABLED: 'ENABLED',
  SIMULTANEOUS_LISTEN_DISABLED: 'DISABLED',
  SIMULTANEOUS_LISTEN_CANCELED: 'CANCELED',
};
