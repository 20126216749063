import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Page } from '@accedo/vdkweb-tv-ui';
import { focusManager } from '@accedo/vdkweb-navigation';
import SiriusXMLogo from '../../assets/images/sxm-logo-blue.svg';
import { appRouteConstants } from '../../routing/app.route.constants';
import './welcome-page.component.scss';
import { StorageKeyConstant } from '../../servicelib';
import { getPageBackId } from '../../redux/selector/xdk.store';
import { storageManager } from '../../config/storage-manager';
import TryFreeModalComponent from './try-free-modal.component';
import BlueCheck from '../../assets/images/blue-check.svg';
import { FocusButton } from '@accedo/vdkweb-tv-ui';
import BlueButton from '../../components/blue-button/blue-button.component';
import { ttsPlayback } from '../../utils/ttsPlayback.util';
import HLS from 'hls.js';

const welcomeIdsObject = {
  'welcome-sign-in-button': 'Sign In',
  'privacy-policy-button': 'Privacy Policy',
};

const pageNav = { id: 'welcome-page' };
const freeTrialButtonNav = {
  id: 'free-trial-button',
  nextleft: 'welcome-sign-in-button',
  nextdown: 'privacy-policy-button',
};
const signInButtonNav = {
  id: 'welcome-sign-in-button',
  nextright: 'free-trial-button',
};
const privacyPolicyButtonNav = {
  id: 'privacy-policy-button',
  nextup: 'free-trial-button',
};
const privacyPolicyButtonTheme = {
  button: 'privacy-policy-button',
  buttonFocused: 'privacy-policy-button-focused',
  buttonActive: 'privacy-policy-button-active',
};

export default props => {
  const history = useHistory();
  const [hasTryFree, setHasTryFree] = useState(false);
  const hasTryFreeContext = useRef(freeTrialButtonNav.id);
  const [hasVideoEnded, setHasVideoEnded] = useState(false);
  let welcomeBackgroundVideo = document.getElementById(
    'welcome-background-video',
  ) as HTMLMediaElement;

  const handleSignIn = () => {
    props.kochava.sendEvent('Click', {
      name: 'Sign In Button',
    });
    history.push(appRouteConstants.ACTIVATE_DEVICE);
  };

  const handleTryFree = () => {
    props.kochava.sendEvent('Click', {
      name: `${freeTrialDurationText} Button`,
    });
    setHasTryFree(true);
    welcomeBackgroundVideo?.pause();
  };

  const handlePrivacyPolicy = () => {
    props.kochava.sendEvent('Click', {
      name: 'Privacy Policy Button',
    });
    history.push(appRouteConstants.PRIVACY_POLICY);
  };

  useEffect(() => {
    focusManager.changeFocus(freeTrialButtonNav.id);
  }, []);

  /* Handles the press of the BACK button to exit the app */
  const isMounted = useRef(false);
  const backId = useSelector(getPageBackId);
  useEffect(() => {
    if (isMounted.current) {
      focusManager.changeFocus(focusManager.getCurrentFocus());
      if (!hasVideoEnded) {
        welcomeBackgroundVideo?.play();
      }
      setHasTryFree(false);
    } else {
      isMounted.current = true;
    }
  }, [backId]);

  const freeTrialDurationText = storageManager.cloudCookieManager.get(
    StorageKeyConstant.FREE_TRIAL_DURATION_TEXT,
  );
  const platinumPlanText = JSON.parse(
    storageManager.cloudCookieManager.get(
      StorageKeyConstant.PLATINUM_PLAN_TEXT,
    ),
  );
  const subscriptionBenefits = [
    platinumPlanText?.planLine1,
    platinumPlanText?.planLine2,
    platinumPlanText?.planLine3,
  ];

  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes(appRouteConstants.FT_WELCOME)) {
      welcomeBackgroundVideo = document.getElementById(
        'welcome-background-video',
      ) as HTMLMediaElement;
      welcomeBackgroundVideo.ontimeupdate = onTimeUpdate;
      welcomeBackgroundVideo.currentTime = 0;
      welcomeBackgroundVideo.play();
      welcomeBackgroundVideo.onerror = e => {
        setHasVideoEnded(true);
      };
      return () => {
        welcomeBackgroundVideo.onerror = undefined;
        welcomeBackgroundVideo.ontimeupdate = undefined;
      };
    }
  }, [location.pathname]);

  const onTimeUpdate = e => {
    const { currentTime, duration } = e.target;
    if (currentTime >= duration - 2) {
      e.target.pause();
    }
    if (currentTime >= duration - 4) {
      setHasVideoEnded(true);
    }
  };

  useEffect(() => {
    if (HLS.isSupported()) {
      const oAvPlayer = document.getElementById(
        'welcome-background-video',
      ) as HTMLMediaElement;
      const backgroundVideoUrl =
        'https://siriusxm-prod-vod.mountain.siriusxm.com/east/tokenbypass-SXM-Onboarding-Video/hls/tokenbypass-SXM-Onboarding-Video.m3u8';
      const oHlsApi = new HLS({
        startLevel: 4,
      });
      oHlsApi.subtitleDisplay = false;
      oHlsApi.loadLevel = 4;
      oHlsApi.loadSource(backgroundVideoUrl);
      oHlsApi.attachMedia(oAvPlayer);
      oHlsApi.on(HLS.Events.MANIFEST_PARSED, function() {
        oAvPlayer.play();
      });
      return () => {
        oHlsApi.destroy();
      };
    }
  }, []);

  useEffect(() => {
    if (hasVideoEnded && !hasTryFree) {
      const focusedBtn = focusManager.getCurrentFocus();
      let text = `SiriusXM ${platinumPlanText?.planTitle}. Music, sports, news, talk and comedy.`;
      subscriptionBenefits.map(val => {
        text += ` ${val}`;
      });
      text += `Sign In Button. ${freeTrialDurationText} button. Try FREE for 3 months, then $9.99/ month. Cancel anytime. Privacy Policies Button. You have currently selected ${welcomeIdsObject[
        focusedBtn
      ] || freeTrialDurationText} button.`;
      ttsPlayback(text);
    }
  }, [subscriptionBenefits]);
  return (
    <>
      <video
        id="welcome-background-video"
        preload={'true'}
        style={{
          visibility:
            location.pathname !== appRouteConstants.FT_WELCOME
              ? 'hidden'
              : 'visible',
        }}
      />
      <div
        id="welcome-gradient"
        style={{ opacity: hasVideoEnded ? 1 : 0 }}
      ></div>
      <Page nav={pageNav} className="welcome-page">
        <TryFreeModalComponent
          hasTryFree={hasTryFree}
          restoreFocus={hasTryFreeContext.current}
          freeTrialDurationText={freeTrialDurationText}
          handlePrivacyPolicy={handlePrivacyPolicy}
        />
        <div className="welcome-wrapper">
          <div className="welcome-content-container">
            <div className="welcome-content">
              <div
                className="welcome-subscription-container"
                style={{ opacity: hasVideoEnded ? 1 : 0 }}
              >
                <div className="welcome-logo">
                  <div style={{ backgroundImage: `url(${SiriusXMLogo})` }} />
                </div>
                <div className="welcome-subscription-title">
                  <span>SiriusXM {platinumPlanText?.planTitle}</span>
                  <span>Music, sports, news, talk and comedy.</span>
                </div>
                <div className="welcome-subscription-details">
                  {subscriptionBenefits.map((benefitText, benefitIndex) => {
                    return (
                      <div
                        className="welcome-subscription-benefit"
                        key={`benefit-${benefitIndex}`}
                      >
                        <div className="subscription-benefit-check">
                          <img src={BlueCheck} />
                        </div>
                        <div className="subscription-benefit-text">
                          {benefitText}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="welcome-buttons-container">
                <div className="welcome-sign-in-button-container">
                  <BlueButton
                    className={'sign-in-button'}
                    nav={signInButtonNav}
                    type={'primary'}
                    onClick={handleSignIn}
                    onFocus={() => {
                      if (hasVideoEnded) {
                        ttsPlayback('Sign In Button');
                      }
                    }}
                  >
                    Sign In
                  </BlueButton>
                </div>
                <div className="welcome-free-trial-button-container">
                  <BlueButton
                    className={'sign-in-button'}
                    nav={freeTrialButtonNav}
                    type={'primary'}
                    isHeight={false}
                    onClick={handleTryFree}
                    onFocus={() => {
                      if (hasVideoEnded) {
                        ttsPlayback(`${freeTrialDurationText} Button`);
                      }
                    }}
                  >
                    {freeTrialDurationText}
                  </BlueButton>
                  <div className="welcome-privacy-policy-container">
                    Try FREE for 3 months, then $9.99/ month. Cancel anytime.
                    <FocusButton
                      nav={privacyPolicyButtonNav}
                      theme={privacyPolicyButtonTheme}
                      onClick={handlePrivacyPolicy}
                      onFocus={() => {
                        if (hasVideoEnded) {
                          ttsPlayback('Privacy Policies Button');
                        }
                      }}
                    >
                      Privacy Policies
                    </FocusButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Page>
    </>
  );
};
