import * as WallClockActions from '../action';
import { IWallClockStore } from '../selector/wall-clock.store';
import { WallClockAction } from '../action/wall-clock.action';

const intialState: IWallClockStore = {
  wallClock: new Date(),
};

export function wallClockReducer(
  state: IWallClockStore = intialState,
  action: WallClockAction,
): IWallClockStore {
  switch (action.type) {
    case WallClockActions.UDPATE_WALL_CLOCK:
      return {
        ...state,
        wallClock: action.payload,
      };

    default:
      return state;
  }
}
