import { IProviderDescriptor } from '../service/provider.descriptor.interface';
import { addProvider } from '../service/providerDescriptors';
import { MediaPlayerService } from '../mediaplayer/media-player.service';
import { MediaTimestampService } from '../mediaplayer/media-timestamp.service';
import { ConsumeService } from '../consume/consume.service';
import { DateUtil } from '../util/date.util';
import { Observable, concat } from 'rxjs';

export class SeekService {
  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(SeekService, SeekService, [
      MediaPlayerService,
      MediaTimestampService,
      ConsumeService,
    ]);
  })();

  constructor(
    private mediaPlayerService: MediaPlayerService,
    private mediaTimestampService: MediaTimestampService,
    private consumeService: ConsumeService,
  ) {}

  public seek(target: number, isRestart: boolean = false): void {
    this.seekObs(target, isRestart).subscribe();
  }

  public seekThenPlay(
    target: number,
    isRestart: boolean = false,
    isLive: boolean = false,
  ): void {
    concat(
      this.seekObs(target, isRestart, isLive),
      this.resumeObs(),
    ).subscribe();
  }

  private seekObs(
    target: number,
    isRestart: boolean = false,
    isLive: boolean = false,
  ): Observable<string> {
    let zeroBasedSeconds;
    let zuluMillisecondsTo;

    // SHOULD IMPLEMENT TUNE START FUNCTIONALITY HERE ???

    this.consumeService.isRestart = isRestart;

    const mediaPlayer = this.mediaPlayerService.mediaPlayer;

    if (DateUtil.isZulu(target)) {
      zuluMillisecondsTo = target;
    } else {
      zeroBasedSeconds = target;
      zuluMillisecondsTo = mediaPlayer.convertZeroBasedSecondsToZulu(
        zeroBasedSeconds,
      );
    }

    // The following two if statements are for a snappy UI if possible.
    // The playheadTimestamp is what adjusts the blue bar.
    // The playheadTimestamp only knows about zero-based seconds.
    // So only if the target is zero-based seconds do we
    // synchronously advance the playhead for a snappy UI experience.
    // TODO: Jordan D. Nelson - write a convertZuluToZeroBasedSeconds function.

    if (zeroBasedSeconds) {
      this.mediaTimestampService.setPlayheadTimestamp(zeroBasedSeconds);
    }

    //TODO: Need to confirm if for restar the zero based second timestamps makes sense, or if we need to provide the epoch
    if (isRestart) {
      this.mediaTimestampService.setPlayheadTimestamp(0);
    }

    return mediaPlayer.seek(
      isLive ? zeroBasedSeconds : zuluMillisecondsTo,
      isLive,
    );
  }

  private resumeObs(): Observable<string> {
    const mediaPlayer = this.mediaPlayerService.mediaPlayer;
    return mediaPlayer.resume();
  }
}
