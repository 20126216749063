import {
    combineLatest as observableCombineLatest,
    of as observableOf,
    SubscriptionLike as ISubscription ,
    Observable
} from 'rxjs';
import {
    tap,
    catchError,
    exhaustMap,
    filter,
    distinctUntilChanged,
    mergeMap,
    first,
    map,
    take
} from 'rxjs/operators';
import {
    IConsumeConfig,
    IConsumeEvent,
    IConsumeRequest,
    ICurrentlyPlayingMedia,
    IMediaCut,
    IMediaItem,
    IMediaPlayer,
    IPlayhead,
    IProviderDescriptor,
    ISession
}                                   from "../index";
import * as _                       from "lodash";
import { addProvider }              from "../service";
import { findMediaItemByTimestamp } from "../util/tune.util";
import { secondsToMs }              from "../util";
import { AuthenticationService }    from "../authentication";
import { ContentTypes }             from "../service/types";
import { CurrentlyPlayingService }  from "../currently-playing";
import { LiveTimeService }          from "../livetime";
import { LiveTime }                 from "../livetime/live-time.interface";
import { Logger }                   from "../logger/logger";
import { MediaUtil }                from "../mediaplayer/media.util";
import { MediaTimeLine }            from "../tune";
import { ProfileService }           from "../profile/profile.service";
import { SettingsConstants }        from "../settings/settings.const";
import { SettingsService }          from "../settings/settings.service";
import { DateUtil }                 from "../util/date.util";
import { ConsumeActionConsts }      from "./consume-action.const";
import { ConsumeEventConsts }       from "./consume-event.const";
import { ConsumeStateConsts }       from "./consume-state.const";
import { ConsumeDelegate }          from "./consume.delegate";
import { MediaPlayerFactory }       from "../mediaplayer/media-player.factory";
import { MediaPlayer }              from "../mediaplayer/media-player";
import { MediaPlayerConstants }     from "../mediaplayer/media-player.consts";
import { ChromecastPlayerConsts }   from "../mediaplayer/chromecastplayer/chromecast-player.consts";
import { ChromecastService }        from "../chromecast/chromecast.service";
import { MediaTimeLineService }     from "../media-timeline/media.timeline.service";

/**
 * @MODULE:     service-lib
 * @CREATED:    10/24/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *  Consume service is used to provide business intelligence analytics reporting for media playback (audio or video).
 */
export class ConsumeService
{
    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("ConsumeService");

    /**
     * Reference to the currently playing data.
     */
    private nowPlaying: ICurrentlyPlayingMedia;

    /**
     * Flag indicating that we're in the middle of a consume `tuneOut` API call so don't allow cut changes until it's
     * back.
     */
    private isTuningOut: boolean = false;

    /**
     * Flag indicating that we're in the Ondemand of a consume `tuneOut` API call.
     */
    private isOnDemandTuningOut: boolean = false;

    /**
     * The last consumption info from the last consume call. We save this so we can populate a consume "Marker End"
     * event with the consumption info used in the "Marker Start" as a cut start and end should have the same
     * consumption info.
     */
    private lastConsumptionInfo: string = "";

    /**
     * The Last Consumption request.
     */
    private lastConsumptionRequests: IConsumeRequest[] = [];

    private previousPotentialCutChange: ICurrentlyPlayingMedia;

    private mediaId: string = "";

    private mediaType: string = "";

    private multiTrackStreamStartDate: Date = new Date();

    /**
     * Error message for an invalid media player.
     */
    public static ERROR_INVALID_MEDIA_PLAYER: string = "The parameter `mediaPlayer` is required and must be a valid player.";

    /**
     * Error message for an invalid media type.
     */
    public static ERROR_INVALID_MEDIA_TYPE: string = "The parameter `mediaType` is required and must be a valid media type.";

    /**
     * Error message for an invalid playback type.
     */
    public static ERROR_INVALID_PLAYBACK_TYPE: string = "The parameter `playbackType` is required and must be a valid playback type.";

    /**
     * Hashmap of API endpoint URLs that allow now data in the response.
     * @type {Map<string, string>}
     */
    private static TUNE_IN_CACHE: Map<string, string> = new Map();

    /**
     * Current Media Player
     */
    private mediaPlayer: MediaPlayer;

    /**
     * The last playback state that the mediaplayer was observed to be in
     */
    private lastPlaybackState : string;

    /**
     * The current chrome cast status. If chrome cast is connected need to stop consume calls.
     */
    private chromecastState : string;

    /**
     * If two audio player ticks are greater than 2 seconds apart
     * the cut change is not passive and we do not make a consume call
     * @type {number}
     */
    public static MAXIMUM_PASSIVE_CUT_CHANGE_TIME_DIFFERENCE = 2000;

    /**
     * This property tells if the user clicked the media player restart button
     * Used to tell the consume service that this condition should be handled specially.
     * @type {boolean}
     */
    public isRestart: boolean = false;

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor : IProviderDescriptor = function()
    {
        return addProvider(ConsumeService,
                           ConsumeService,
                           [ConsumeDelegate,
                            SettingsService,
                            MediaTimeLineService,
                            CurrentlyPlayingService,
                            AuthenticationService,
                            MediaPlayerFactory,
                            ProfileService,
                            LiveTimeService,
                            ChromecastService]);
    }();

    /**
     * Constructor
     * @param {ConsumeDelegate} consumeDelegate - Allows the service to make API calls.
     * @param {SettingsService} settingsService - Provides access to the application settings.
     * @param {MediaTimeLineService} mediaTimeLineService - Provides access to the media time line data.
     * @param {CurrentlyPlayingService} currentlyPlayingService
     * @param {AuthenticationService} authenticationService
     * @param {MediaPlayerFactory} mediaPlayerFactory
     * @param {ProfileService} profileService - Provides access to the profile data.
     * @param {LiveTimeService} liveTimeService - Provides access to the live time.
     * @param {ChromecastService} chromecastService - service for chromecast.
     */
    constructor(private consumeDelegate: ConsumeDelegate,
                private settingsService: SettingsService,
                private mediaTimeLineService : MediaTimeLineService,
                private currentlyPlayingService: CurrentlyPlayingService,
                private authenticationService: AuthenticationService,
                private mediaPlayerFactory: MediaPlayerFactory,
                private profileService: ProfileService,
                private liveTimeService: LiveTimeService,
                private chromecastService: ChromecastService)
    {
        this.observeChromeCastStatus();
        this.observeNowPlayingData();
        this.observeMediaPlayerFactory();
        this.observeMediaTimeLine();
        this.observeSessionState();
    }

    /**
     * Method will call the delegate class to hit the consume API and store the response in model when media
     * playback is tuning in.
     *
     * @param {IMediaPlayer} mediaPlayer - The media player contains current playing data like the zulu playhead
     *     timestamp.
     * @param {string} mediaType - The media type for the current player.
     * @returns {Observable<boolean>}
     */
    public tuneIn(mediaPlayer: IMediaPlayer, mediaType: string): Observable<boolean>
    {
        ConsumeService.logger.debug(`tuneIn()`);

        this.validateMediaPlayer(mediaPlayer);

        if (canTuneIn(mediaPlayer))
        {
            // Make sure not to check for a valid media type unless we're actually trying to tuneIn; ie,
            // do not move this out of the if().
            this.validateMediaType(mediaType);

            // Cache the tune in call for this media player. This means we can start to perform consume cut changes.
            ConsumeService.TUNE_IN_CACHE.set(mediaPlayer.getId(), mediaPlayer.getId());

            return this.createConsumeConfig(
                ConsumeStateConsts.START,
                mediaPlayer,
                mediaType
            ).pipe(exhaustMap((consumeConfig: IConsumeConfig) =>
            {
                return this.consume(consumeConfig);
            }));
        }
        return observableOf(true);

        /**
         * Determines if we can perform a tuneIn consume call based on the following:
         *
         * 1) We haven't already performed a tuneIn for this player.
         * 2) This player is in fact the current player.
         *
         * @param {IMediaPlayer} mediaPlayer
         * @returns {boolean}
         */
        function canTuneIn(mediaPlayer: IMediaPlayer): boolean
        {
            return !ConsumeService.TUNE_IN_CACHE.has(mediaPlayer.getId()) && mediaPlayer.isCurrent;
        }
    }

    /**
     * Method will call the delegate class to hit the consume API and store the response in model when media
     * playback is tuning out.
     *
     * @param {IMediaPlayer} mediaPlayer - The media player contains current playing data like the zulu playhead
     *     timestamp.
     * @param {string} mediaType - The media type for the current player (optional, if not present, will be obtained
     *     from no playing service
     * @returns {Observable<boolean>}
     */
    public tuneOut(mediaPlayer: IMediaPlayer, mediaType?: string): Observable<boolean>
    {
        ConsumeService.logger.debug(`tuneOut()`);

        this.validateMediaPlayer(mediaPlayer);

        if (canTuneOut(mediaPlayer))
        {
            const mediaTypeObs =
                      (mediaType)
                      ? observableOf(mediaType)
                      : this.currentlyPlayingService
                            .currentlyPlayingData.pipe(
                            take(1),
                            map((currentlyPlayingMedia: ICurrentlyPlayingMedia) => currentlyPlayingMedia.mediaType));

            return mediaTypeObs.pipe(exhaustMap((playingMediaType: string) => prepareConsume.bind(this)(playingMediaType, mediaPlayer)),
                               mergeMap((consumeConfig: IConsumeConfig) => this.consume(consumeConfig)),
                               tap((response: boolean) => tuneOutComplete.bind(this)(response)));
        }

        return observableOf(true);

        function prepareConsume(playingMediaType: string, player: IMediaPlayer): Observable<IConsumeConfig>
        {
            // Make sure not to check for a valid media type unless we're actually trying to tuneOut; ie,
            // do not move this out of the if().
            this.validateMediaType(playingMediaType);

            // Set a flag to indicate that we're in the process of tuning out, so we don't want to perform consume
            // cut changes for this player.
            this.isTuningOut = true;

            this.isOnDemandTuningOut = (!!this.nowPlaying)
                                       ? MediaUtil.isOnDemandMediaType(this.nowPlaying.mediaType)
                                       : false;

            // Delete the tune in call for this media player. This means we can't perform consume cut changes
            // until the consume tuneIn for this media player has been called.
            ConsumeService.TUNE_IN_CACHE.delete(player.getId());

            return this.createConsumeConfig(ConsumeStateConsts.STOP, player, playingMediaType);
        }

        /**
         * Determines if we can perform a tuneOut consume call based on the following:
         *
         * 1) We actually performed a tuneIn for this player.
         * 2) This player is in fact the current player.
         *
         * @param {IMediaPlayer} mediaPlayer
         * @returns {boolean}
         */
        function canTuneOut(mediaPlayer: IMediaPlayer): boolean
        {
            return ConsumeService.TUNE_IN_CACHE.has(mediaPlayer.getId()) && mediaPlayer.isCurrent;
        }

        /**
         * Handles the consume API request completion and sets the flag for tuning out to false so the cut change
         *marker start and end consume calls can continue.
         * @param response - Returned by the consume.
         * @returns {Observable<boolean>}
         */
        function tuneOutComplete(response: boolean): boolean
        {
            ConsumeService.logger.debug(`tuneOutComplete()`);
            this.isTuningOut = false;
            //NOTE: Below code used to refresh pausepoint when ondemand tunes out.
            if(this.isOnDemandTuningOut)
            {
                this.profileService.getPausePoints();
                this.isOnDemandTuningOut = false;
            }
            return response;
        }
    }

    /**
     * Method will call the delegate class to hit the consume API and store the response in model when media
     * playback is paused.
     *
     * @param {IMediaPlayer} mediaPlayer - The media player contains current playing data like the zulu playhead
     *     timestamp.
     * @param {string} mediaType - The media type for the current player.
     * @returns {Observable<boolean>}
     */
    public pause(mediaPlayer: IMediaPlayer, mediaType: string): Observable<boolean>
    {
        ConsumeService.logger.debug(`pause()`);

        this.validateMediaPlayer(mediaPlayer);
        this.validateMediaType(mediaType);

        return this.createConsumeConfig(
            ConsumeStateConsts.PAUSE,
            mediaPlayer,
            mediaType
        ).pipe(
        exhaustMap((consumeConfig: IConsumeConfig) =>
        {
            return this.consume(consumeConfig);
        }));
    }

    /**
     * Method will call the delegate class to hit the consume API and store the response in model when media
     * playback is resumed from a pause.
     *
     * @param {IMediaPlayer} mediaPlayer - The media player contains current playing data like the zulu playhead
     *     timestamp.
     * @param {string} mediaType - The media type for the current player.
     * @returns {Observable<boolean>}
     */
    public resume(mediaPlayer: IMediaPlayer, mediaType: string): Observable<boolean>
    {
        ConsumeService.logger.debug(`resume()`);

        this.validateMediaPlayer(mediaPlayer);
        this.validateMediaType(mediaType);

        // Don't allow consume cut changes if the current media player hasn't performed a consume tuneIn.
        if (!ConsumeService.TUNE_IN_CACHE.has(mediaPlayer.getId()))
        {
            return this.tuneIn(mediaPlayer, mediaType);
        }

        return this.createConsumeConfig(
            ConsumeStateConsts.RESUME,
            mediaPlayer,
            mediaType
        ).pipe(
        exhaustMap((consumeConfig: IConsumeConfig) =>
        {
            return this.consume(consumeConfig);
        }));
    }

    /**
     * checks data for potential cut change.
     * If there is one, run a consume for a cut change.
     * @param {ICurrentlyPlayingMedia} data
     * @param {IMediaPlayer} mediaPlayer
     * @returns {boolean}
     */
    public checkForPassiveCutChange(data: ICurrentlyPlayingMedia, mediaPlayer: IMediaPlayer)
    {
        const self             = this;
        const lastCut          = (this.previousPotentialCutChange && this.previousPotentialCutChange.cut)
                                 ? this.previousPotentialCutChange.cut : undefined;
        const thisCut          = (data && data.cut) ? data.cut: undefined;
        const lastMediaId      = (this.previousPotentialCutChange) ? this.previousPotentialCutChange.mediaId : undefined;
        const thisMediaId      = (data) ? data.mediaId : undefined;
        const lastCutArtist    = _.get(lastCut, "artists[0].name") as string;
        const thisCutArtist    = _.get(thisCut, "artists[0].name") as string;
        const lastCutTimeStamp = _.get(lastCut, "lastPlaybackTimestamp") as number;
        const thisCutTimeStamp = _.get(thisCut, "lastPlaybackTimestamp") as number;
        const lastCutZuluEndTime = this.previousPotentialCutChange && this.previousPotentialCutChange.cut
                                   && this.previousPotentialCutChange.cut.times ? this.previousPotentialCutChange.cut.times.zuluEndTime : 0;

        ConsumeService.logger.debug(`Potential passive cut change ` +
                                    `${lastMediaId}:${lastCutTimeStamp}:${lastCutArtist} ` +
                                    `${thisMediaId}:${thisCutTimeStamp}:${thisCutArtist} `);

        if (isPassiveCutChange())
        {
            this.runConsume(
                [
                    `${ ConsumeEventConsts.MARKER_END }/${ ConsumeActionConsts.PASSIVE }`,
                    `${ ConsumeEventConsts.MARKER_START }/${ ConsumeActionConsts.PASSIVE }`
                ],
                mediaPlayer,
                data.mediaType,
                {
                    from: from(),
                    to: to(),
                    fromInfo:  this.previousPotentialCutChange && this.previousPotentialCutChange.cut
                             ? this.previousPotentialCutChange.cut.consumptionInfo: "",
                    toInfo:  data.cut && data.cut.consumptionInfo ? data.cut.consumptionInfo : ""
                }
            ).subscribe();
        }
        this.previousPotentialCutChange = data;


        function isPassiveCutChange(): boolean
        {
            if (data && MediaUtil.isMultiTrackAudioMediaType(data.mediaType))
            {
                return isMultiTrackPassiveCutChange();
            }
            return isNormalPassiveCutChange();
        }

        function isMultiTrackPassiveCutChange(): boolean
        {
            if (!self.mediaPlayer)
            {
                return false;
            }

            const jumpActionCuts = self.mediaPlayer.getJumpActionCuts();

            return lastMediaId === thisMediaId
                && !!lastCut
                && !!thisCut
                && lastCut.assetGUID && thisCut.assetGUID
                && lastCut.assetGUID !== thisCut.assetGUID
                && (lastCut.assetGUID !== jumpActionCuts.from.assetGUID
                    || thisCut.assetGUID !== jumpActionCuts.to.assetGUID);
        }

        function isNormalPassiveCutChange(): boolean
        {
            return lastMediaId === thisMediaId
                && !!lastCut && !!lastCutTimeStamp
                && !!thisCut && !!thisCutTimeStamp
                && lastCut.assetGUID && thisCut.assetGUID
                && lastCut.assetGUID !== thisCut.assetGUID
                && (thisCutTimeStamp > lastCutTimeStamp)
                && (thisCutTimeStamp - lastCutTimeStamp <= ConsumeService.MAXIMUM_PASSIVE_CUT_CHANGE_TIME_DIFFERENCE);
        }

        function from(): number
        {
            if (data
                && MediaUtil.isMultiTrackAudioMediaType(data.mediaType)
                && lastCut)
            {
                return self.multiTrackStreamStartDate.getTime() + secondsToMs(lastCut.duration);
            }
            const cutStartZuluStartTime = data && data.cut && data.cut.times ? data.cut.times.zuluStartTime - 1 : 0;
            let fromTime                = lastCutZuluEndTime === 0 ? cutStartZuluStartTime : lastCutZuluEndTime;
            const playHeadTime          = mediaPlayer.getPlayheadZuluTime();

            if (Math.abs(playHeadTime - fromTime) > 30 * 1000)
            {
                //TODO Vpaindla Used for testing 18000 BI issue.
                console.error("DEBUG from(): Prev Consume Request : ", self.lastConsumptionRequests,
                    "last cut zulu end time: ", lastCutZuluEndTime,
                    "CutStart zulu start time:", cutStartZuluStartTime,
                    "playhead time:", playHeadTime);
                fromTime = cutStartZuluStartTime;
            }
            return fromTime;
        }

        function to(): number
        {
            if (data && MediaUtil.isMultiTrackAudioMediaType(data.mediaType))
            {
                return null;
            }
            let toTime       = data && data.cut && data.cut.times ? data.cut.times.zuluStartTime : 0;
            const playHeadTime = mediaPlayer.getPlayheadZuluTime();
            if (Math.abs(playHeadTime - toTime) > 30 * 1000)
            {
                //TODO Vpaindla Used for testing 18000 BI issue.
                console.error("DEBUG to(): Prev Consume Request : ", self.lastConsumptionRequests,
                    "current cut start time: ", toTime,
                    "playhead time:", playHeadTime);
                toTime = playHeadTime - 1;
            }
            return toTime;
        }
    }

    /**
     * Method will call the delegate class to hit the consume API.
     * This is a refactor method.
     * Should vastly simplify the logic of consume calls.
     *
     * @param {string[]} eventActions
     * @param {IMediaPlayer} mediaPlayer
     * @param {string} mediaType - The media type for the current player.
     * @param {{}} opts - options object
     * @returns {Observable<boolean>}
     */
    public runConsume(eventActions: string[],
                      mediaPlayer: IMediaPlayer,
                      mediaType: string,
                      opts?: { from?: number, to?: number, fromInfo?: string, toInfo?: string }): Observable<boolean>
    {
        if (!eventActions.length
            || (this.chromecastState && this.chromecastState === ChromecastPlayerConsts.STATE.CONNECTED))
        {
            return observableOf(false);
        }

        this.validateMediaPlayer(mediaPlayer);

        const fromTime = (opts) ? opts.from : -1;
        const toTime = (opts) ? opts.to : -1;

        let fromCut : IMediaCut;
        let toCut : IMediaCut;

        const now = new Date();

        this.mediaTimeLineService
            .mediaTimeLine.pipe(
            take(1))
            .subscribe((mediaTimeLine : MediaTimeLine) =>
                       {
                           fromCut = findMediaItemByTimestamp(fromTime, mediaTimeLine.cuts) as IMediaCut;
                           toCut   = findMediaItemByTimestamp(toTime, mediaTimeLine.cuts) as IMediaCut;
                       });


        let consumeRequests: IConsumeRequest[] = eventActions.map((eventAction) =>
        {
            let [event, action] = eventAction.split("/");
            return {
                consumeEvent: { event: event, action: action },
                consumptionInfo: this.nowPlaying && this.nowPlaying.cut ? this.nowPlaying.cut.consumptionInfo: "",
                consumeDateTime: DateUtil.convertDateToISO8601Format1(now),
                consumeStreamDateTime: DateUtil.convertDateToISO8601Format1(
                    new Date(mediaPlayer.getPlayheadZuluTime())
                ),
                mediaType: mediaType
            };
        });

        if (opts && opts.from)
        {
            consumeRequests[0].consumeStreamDateTime = DateUtil.convertDateToISO8601Format1(
                new Date(opts.from)
            );
        }

        if (opts && opts.to)
        {
            consumeRequests[1].consumeStreamDateTime = DateUtil.convertDateToISO8601Format1(
                new Date(opts.to)
            );
        }

        if (MediaUtil.isMultiTrackAudioMediaType(mediaType))
        {
            if (opts && !opts.from && consumeRequests[0])
            {
                consumeRequests[0].consumeStreamDateTime = consumeRequests[1].consumeDateTime;
                this.multiTrackStreamStartDate = now;
            }

            if (opts && !opts.to && consumeRequests[1])
            {
                consumeRequests[1].consumeStreamDateTime = consumeRequests[1].consumeDateTime;
                this.multiTrackStreamStartDate = now;
            }
        }

        ConsumeService.logger.debug(`Potential passive cut change consumeRequests[0].consumeStreamDateTime - ` +
        `${consumeRequests[0].consumeStreamDateTime}`);

        ConsumeService.logger.debug(`Potential passive cut change consumeRequests[1].consumeStreamDateTime - ` +
        `${consumeRequests[1].consumeStreamDateTime}`);


        /**
         * Sometimes we have opts.fromInfo (channel or on demand episode changes).  In these cases we will use those
         * values when reporting consumption info.  We cannot use the media timeline in these cases, because the
         * media timeline will only contain cuts for the *current* channel or episode, and the consumptionInfo for
         * the cut we are coming from comes from the *previous* channel or episode we were tuned to.
         *
         * For passive cut changes or seek based cut changes, we do *not* have fromInfo and toInfo, so in these cases
         * we will use the cuts we found on the media timeline that match the timeestamps in opt from/to.
         *
         * TODO : If we make it so that fromInfo/toInfo are always reliably populated, then fromCut/toCut will be no
         *        longer needed
         */

        if (opts && opts.fromInfo)
        {
            consumeRequests[0].consumptionInfo = opts.fromInfo;
        }
        else if (fromCut)
        {
            consumeRequests[0].consumptionInfo = fromCut.consumptionInfo;
        }

        if (opts && opts.toInfo)
        {
            consumeRequests[1].consumptionInfo = opts.toInfo;
        }
        else if (toCut)
        {
            consumeRequests[1].consumptionInfo = toCut.consumptionInfo;
        }

        this.lastConsumptionInfo = consumeRequests[1].consumptionInfo;
        this.lastConsumptionRequests = consumeRequests;
        return this.consumeDelegate.consume(consumeRequests);
    }

    /**
     * Method will call the delegate class to hit the consume API.
     *
     * @param {IConsumeConfig} consumeConfig - Configuration data required for a consume API call.
     * @returns {Observable<boolean>}
     */
    private consume(consumeConfig: IConsumeConfig): Observable<boolean>
    {
        // NOTE: When Player casted to Chrome cast then Web (Sender) should not make any consume calls.
        // Once casted R will takes care of consume calls.
        if(this.chromecastState && this.chromecastState === ChromecastPlayerConsts.STATE.CONNECTED)
        {
            return observableOf(false);
        }

        const isMarkerEnd: boolean = consumeConfig.consumeEvent.event === ConsumeEventConsts.MARKER_END;
        let currentConsumptionInfo: string = this.nowPlaying && this.nowPlaying.cut && this.nowPlaying.cut.consumptionInfo
                                             ? this.nowPlaying.cut.consumptionInfo : "";

        const liveVideoConsumptionInfo: string = this.nowPlaying && this.nowPlaying.video && this.nowPlaying.video.consumptionInfo
                                                 ? this.nowPlaying.video.consumptionInfo : "";

        currentConsumptionInfo = (consumeConfig.mediaType === ContentTypes.LIVE_VIDEO) ? liveVideoConsumptionInfo : currentConsumptionInfo;

        const consumptionInfo: string = isMarkerEnd ? this.lastConsumptionInfo : currentConsumptionInfo;

        const now = new Date();
        const consumeDateTime: string = DateUtil.convertDateToISO8601Format1(now);
        const consumeStreamDateTime: string = DateUtil.convertDateToISO8601Format1(new Date(consumeConfig.playhead));

        const event: string = consumeConfig.consumeEvent.event;
        const action: string = consumeConfig.consumeEvent.action;
        const mediaType: string = consumeConfig.mediaType;

        // We save this so we can populate a consume "Marker End" event with the consumption info used in the
        // corresponding "Marker Start" as a cut start and end should have the same consumption info.
        this.lastConsumptionInfo = consumptionInfo;

        ConsumeService.logger.debug(
            `consume( event: ${event}, action: ${action}, consumeStreamDateTime: ${consumeStreamDateTime}, playhead: ${consumeConfig.playhead}  )`
        );

        const consumeRequest: IConsumeRequest = {
            consumeEvent: consumeConfig.consumeEvent,
            consumptionInfo: consumptionInfo,
            consumeDateTime: consumeDateTime,
            consumeStreamDateTime: consumeStreamDateTime,
            mediaType: mediaType
        };

        if (MediaUtil.isMultiTrackAudioMediaType(mediaType))
        {
            if (consumeRequest.consumeEvent.action === ConsumeActionConsts.TUNE_IN
               || consumeRequest.consumeEvent.action === ConsumeActionConsts.TUNE_START)
            {
                consumeRequest.consumeStreamDateTime = consumeDateTime;
                this.multiTrackStreamStartDate = now;
            }
            else
            {
                consumeRequest.consumeStreamDateTime = DateUtil.convertDateToISO8601Format1(
                    new Date(this.multiTrackStreamStartDate.getTime() + consumeConfig.playhead)
                );
            }
        }

        this.lastConsumptionRequests = [consumeRequest];
        return this.consumeDelegate.consume([consumeRequest]).pipe(
            mergeMap(onConsumeSuccess.bind(this)),
            catchError(onConsumeFault.bind(this))) as Observable<boolean>;

        /**
         * Handles the successful API call.
         * @param response - Returned by the delegate. The consume response is empty.
         * @returns {any}
         */
        function onConsumeSuccess(response: any): Observable<boolean>
        {
            if (response)
            {
                ConsumeService.logger.debug(`onConsumeSuccess()`);
                return observableOf(true);
            }
            else
            {
                return observableOf(false);
            }
        }

        /**
         * On the delegate throws exception then fault handler get called. and propagates the error back to client.
         * @param error - returned by the delegate.
         */
        function onConsumeFault(error: any): Observable<boolean>
        {
            ConsumeService.logger.error(`onConsumeFault - Error (${JSON.stringify(error)})`);
            return observableOf(false);
        }
    }

    /**
     * Creates the consume config object that contains data required by the API like current playhead, start playhead,
     * etc.
     *
     * @param {string} state
     * @param {IMediaPlayer} mediaPlayer
     * @param {string} mediaType - The media type for the current player.
     * @returns {Observable<IConsumeConfig>}
     */
    private createConsumeConfig(state: string, mediaPlayer: IMediaPlayer, mediaType: string): Observable<IConsumeConfig>
    {
        this.validateMediaPlayer(mediaPlayer);

        let consumeConfig: IConsumeConfig = {
            playbackType: mediaPlayer.getPlaybackType(),
            playhead: 0,
            playheadStart: mediaPlayer.getPlayheadStartZuluTime(),
            isLive: mediaPlayer.isLive(),
            mediaType: mediaType
        };

        return mediaPlayer.playhead$.pipe(
            filter(playhead =>
            {
                if (MediaUtil.isMultiTrackAudioMediaType(mediaType) || MediaUtil.isPodcastMediaType(mediaType))
                {
                    return true;  // don't filter out low timestamps for multi-track.
                }
                return DateUtil.isZulu(playhead.currentTime.zuluMilliseconds);
            }),
            take(1),
            map((playhead: IPlayhead) =>
            {
                consumeConfig.playhead = playhead.currentTime.zuluMilliseconds;
                consumeConfig.consumeEvent = this.createConsumeEvent(state, consumeConfig);
                return consumeConfig;
            }));
    }

    /**
     * Creates the consume event data.
     *
     * @param {string} state
     * @param {IConsumeConfig} consumeConfig
     * @returns {IConsumeEvent}
     */
    private createConsumeEvent(state: string, consumeConfig: IConsumeConfig): IConsumeEvent
    {
        switch (state)
        {
            case ConsumeStateConsts.START:
                return this.getConsumeEventStart(consumeConfig);

            case ConsumeStateConsts.STOP:
                return this.getConsumeEventStop();

            case ConsumeStateConsts.PAUSE:
                return this.getConsumeEventPause();

            case ConsumeStateConsts.RESUME:
                return this.getConsumeEventResume();

            case ConsumeStateConsts.MARKER_START:
                return this.getConsumeEventMarkerStart();

            case ConsumeStateConsts.MARKER_END:
                return this.getConsumeEventMarkerEnd();

            default:
                ConsumeService.logger.warn(`createConsumeEvent( Unknown state: ${state} )`);
                return null;
        }
    }

    /**
     * Creates the consume event for a playback start.
     *
     * @returns {IConsumeEvent}
     */
    private getConsumeEventStart(consumeConfig: IConsumeConfig)
    {
        const playbackType: string = consumeConfig.playbackType;
        const playhead: number = consumeConfig.playhead;
        const playheadStart: number = consumeConfig.playheadStart;
        const isLive: boolean = consumeConfig.isLive;

        ConsumeService.logger.debug(`getConsumeEventStart( playbackType = ${playbackType}, ` +
            `playhead = ${playhead}, ` +
            `playheadStart = ${playbackType},  )`);

        this.validatePlaybackType(playbackType);

        const consumeEvent: IConsumeEvent = {
            event: ConsumeEventConsts.START,
            action: ConsumeActionConsts.TUNE_IN
        };

        const isTuneStart: boolean = this.settingsService.isGlobalSettingOn(SettingsConstants.TUNE_START);

        // If the player is near the start of the track, then set the consume event to marker start.
        if (this.isPlayheadNearTargetTimestamp(playhead, playheadStart))
        {
            consumeEvent.event = ConsumeEventConsts.MARKER_START;
        }

        if (isLive && isTuneStart)
        {
            consumeEvent.event = ConsumeEventConsts.MARKER_START;
            consumeEvent.action = ConsumeActionConsts.TUNE_START;
        }

        if (MediaUtil.isMultiTrackAudioMediaType(consumeConfig.mediaType))
        {
            consumeEvent.event = ConsumeEventConsts.MARKER_START;
            consumeEvent.action = ConsumeActionConsts.TUNE_IN;
        }

        return consumeEvent;
    }

    /**
     * Creates the consume event for a stop.
     * @returns {IConsumeEvent}
     */
    private getConsumeEventStop(): IConsumeEvent
    {
        ConsumeService.logger.debug(`getConsumeEventStop()`);

        const consumeEvent: IConsumeEvent = {
            event: ConsumeEventConsts.END,
            action: ConsumeActionConsts.TUNE_OUT
        };
        return consumeEvent;
    }

    /**
     * Creates the consume event for a pause.
     * @returns {IConsumeEvent}
     */
    private getConsumeEventPause(): IConsumeEvent
    {
        ConsumeService.logger.debug(`getConsumeEventPause()`);

        const consumeEvent: IConsumeEvent = {
            event: ConsumeEventConsts.PAUSE,
            action: ConsumeActionConsts.ACTIVE
        };
        return consumeEvent;
    }

    /**
     * Creates the consume event for a resume.
     * @returns {IConsumeEvent}
     */
    private getConsumeEventResume(): IConsumeEvent
    {
        ConsumeService.logger.debug(`getConsumeEventResume()`);

        const consumeEvent: IConsumeEvent = {
            event: ConsumeEventConsts.START,
            action: ConsumeActionConsts.RESUME
        };
        return consumeEvent;
    }

    /**
     * Creates the consume event for a marker start.
     * @returns {IConsumeEvent}
     */
    private getConsumeEventMarkerStart(): IConsumeEvent
    {
        ConsumeService.logger.debug(`getConsumeEventMarkerStart()`);

        const consumeEvent: IConsumeEvent = {
            event: ConsumeEventConsts.MARKER_START,
            action: ConsumeActionConsts.PASSIVE
        };
        return consumeEvent;
    }

    /**
     * Creates the consume event for a marker end.
     * @returns {IConsumeEvent}
     */
    private getConsumeEventMarkerEnd(): IConsumeEvent
    {
        ConsumeService.logger.debug(`getConsumeEventMarkerEnd()`);

        const consumeEvent: IConsumeEvent = {
            event: ConsumeEventConsts.MARKER_END,
            action: ConsumeActionConsts.PASSIVE
        };
        return consumeEvent;
    }

    /**
     * Indicates if the difference between the playhead and the given time are within +/- one second.
     * This is usually used to determine if the playhead is near the playhead start or near the beginning of a
     * cut, aka marker start.
     *
     * @param {number} playhead - The current media player zulu timestamp.
     * @param {number} targetTimestamp - The target zulu timestamp.
     * @returns {boolean}
     */
    private isPlayheadNearTargetTimestamp(playhead: number, targetTimestamp: number)
    {
        let difference = (playhead - targetTimestamp);
        return (difference > -1000) && (difference < 1000);
    }

    /**
     * Validates the media player -- if bad it throws an error.
     * We want to make consume calls fail hard as BI is a big deal, thus the thrown errors to make it easy to track
     * down.
     *
     * @param {IMediaPlayer} mediaPlayer
     * @throws {Error}
     * @returns {boolean}
     */
    private validateMediaPlayer(mediaPlayer: IMediaPlayer): boolean
    {
        if (!mediaPlayer)
        {
            throw new Error(ConsumeService.ERROR_INVALID_MEDIA_PLAYER);
        }
        return true;
    }

    /**
     * Validates the media type -- if bad it throws an error.
     * We want to make consume calls fail hard as BI is a big deal, thus the thrown errors to make it easy to track
     * down.
     *
     * @param {IMediaPlayer} mediaPlayer
     * @throws {Error}
     * @returns {boolean}
     */
    private validateMediaType(mediaType: string): boolean
    {
        if (!mediaType)
        {
            throw new Error(ConsumeService.ERROR_INVALID_MEDIA_TYPE);
        }
        return true;
    }

    /**
     * Validates the playback type -- if bad it throws an error.
     * We want to make consume calls fail hard as BI is a big deal, thus the thrown errors to make it easy to track
     * down.
     *
     * @param {IMediaPlayer} mediaPlayer
     * @throws {Error}
     * @returns {boolean}
     */
    private validatePlaybackType(playbackType: string): boolean
    {
        if (!playbackType)
        {
            throw new Error(ConsumeService.ERROR_INVALID_PLAYBACK_TYPE);
        }
        return true;
    }

    /**
     * Saves the currently playing data so they can be inspected later for business intelligence reporting.
     * make consume calls on the cut changes
     */
    private observeNowPlayingData(): void
    {
        ConsumeService.logger.debug(`observeNowPlayingData()`);

        this.currentlyPlayingService.currentlyPlayingData.subscribe((data: ICurrentlyPlayingMedia) =>
        {
            // It's possible to try and fire off a consume tuneOut call without any cuts, which in turn
            // leads to bad BI reporting; the check for `data.cut` prevents this issue.
            if (data && data.cut)
            {
                this.nowPlaying = data;
                this.checkForPassiveCutChange(data, this.mediaPlayer);
            }
        });
    }

    /**
     * Observe data changes on the media time line. If there's new media, start audio playback.
     */
    private observeMediaTimeLine():void
    {
        this.mediaTimeLineService.mediaTimeLine.pipe(
            filter((mediaTimeLine: MediaTimeLine) => !!mediaTimeLine))
            .subscribe((mediaTimeLine: MediaTimeLine) =>
            {
                // Save the media type so we can determine if there's a new one next time.
                this.mediaType = mediaTimeLine.mediaType;

                // Save the media type so we can determine if there's a new one next time.
                this.mediaId = mediaTimeLine.mediaId;
            });
    }

    /**
     * Observes the currentMediaPlayer and PlaybackState from both Audio and Video
     */
    private observeMediaPlayerFactory()
    {
        let mediaPlayerStateSubscription : ISubscription;

        this.mediaPlayerFactory.currentMediaPlayer.pipe(
            filter((mediaPlayer: MediaPlayer) => !!mediaPlayer))
            .subscribe((mediaPlayer: MediaPlayer) =>
            {
                this.mediaPlayer = mediaPlayer;

                if (mediaPlayerStateSubscription) { mediaPlayerStateSubscription.unsubscribe(); }

                mediaPlayerStateSubscription = mediaPlayer.playbackState.pipe(
                    distinctUntilChanged())
                    .subscribe((playerState: string) =>
                    {
                        this.handlePlaybackState(mediaPlayer, playerState, mediaPlayer.mediaType);
                    });
            });
    }

    /**
     * Observes the chromecast status
     */
    private observeChromeCastStatus(): void
    {
        ConsumeService.logger.debug(`observeChromeCastStatus()`);

        this.chromecastService.state$.subscribe((status: string) =>
        {
            this.chromecastState = status;
        });
    }

    /**
     * Handles the consume calls based on the playback state(PAUSED/PLAYING/TUNEIN/TUNEOUT...)
     */
    private handlePlaybackState(mediaPlayer: IMediaPlayer, playerState: string, mediaType: string)
    {
        let lastState = playerState;

        switch (playerState)
        {
            case MediaPlayerConstants.FINISHED:
            {
                this.tuneOut(mediaPlayer, mediaType).subscribe();
                break;
            }

            case MediaPlayerConstants.PAUSED:
                if (this.lastPlaybackState === MediaPlayerConstants.PRELOADING)
                {
                    lastState = this.lastPlaybackState;
                }
                else
                {
                    this.pause(mediaPlayer, mediaType).subscribe();
                }
                break;

            case MediaPlayerConstants.PLAYING:
            {
                if (this.lastPlaybackState === MediaPlayerConstants.PAUSED)
                {
                    this.resume(mediaPlayer, mediaType).subscribe();
                }
                else
                {
                    this.tuneIn(mediaPlayer, mediaType).subscribe();
                }
                break;
            }

            case MediaPlayerConstants.SEEKING:
            {
                if (MediaUtil.isMultiTrackAudioMediaType(mediaType))
                {
                    const jumpActionCuts = mediaPlayer.getJumpActionCuts();

                    if (!jumpActionCuts.from || !jumpActionCuts.to) return;

                    return mediaPlayer.playhead$.pipe(
                        take(1))
                        .subscribe((playhead: IPlayhead)=>
                        {
                            const times = this.getFromToTimesMultiTrack(jumpActionCuts, playhead);
                            this.runConsume(
                                this.getSeekEventActionsMultiTrack(jumpActionCuts),
                                mediaPlayer,
                                mediaType,
                                {
                                    from: times.from,
                                    to: times.to,
                                    fromInfo: jumpActionCuts.from.consumptionInfo,
                                    toInfo: jumpActionCuts.to.consumptionInfo
                                }
                            ).subscribe();
                        });
                }

                const toZuluTimestamp: number = mediaPlayer.getLastSeekTime();
                const fromZuluTimestamp: number = mediaPlayer.getPlayheadZuluTime();
                const duration: number = mediaPlayer.getDuration();

                const liveTime$ = this.liveTimeService.liveTime$.pipe(
                    first(),
                    filter( (liveTime:  LiveTime) => liveTime.zuluMilliseconds > 0 ));

                const mediaTimeLine$ = this.mediaTimeLineService.mediaTimeLine.pipe(
                    first(),
                    filter( (mediaTimeLine:  MediaTimeLine) => !!mediaTimeLine && !!mediaTimeLine.cuts && !!mediaTimeLine.segments ));

                observableCombineLatest(
                    liveTime$, mediaTimeLine$,
                    (liveTime: LiveTime, mediaTimeLine:  MediaTimeLine) =>
                    {
                        if (fromZuluTimestamp === toZuluTimestamp) { return; }

                        this.runConsume(
                            this.getSeekEventActions(fromZuluTimestamp, toZuluTimestamp, liveTime, duration, mediaType, mediaTimeLine),
                            mediaPlayer,
                            mediaType,
                            {
                                from: fromZuluTimestamp,
                                to: toZuluTimestamp
                            }
                        ).pipe(
                            first())
                            .subscribe();
                    }
                ).pipe(
                    first())
                    .subscribe();

                break;
            }

        }

        this.lastPlaybackState = lastState;

    }

    /**
     * Gets the consume seek action and event.
     *
     * If the seek to time is greater than the current playhead value then we're seeking forward.
     *
     *      - Assume we're simply seeking to the next marker and performing a forward seek to a cut or segment.
     *      - If the seek time equals the live time and we're playing live content then we're performing a goto live
     * consume call.
     *      - If the seek time doesn't match the next cut or segment then we're scrubbing.
     *      - If the seek time equals the duration then we're scrubbing.
     *
     * If the seek to time is less than the current playhead value then we're seeking backward.
     *
     *      - Assume we're simply seeking to the previous marker and performing a backward seek to a cut or segment.
     *      - If the seek time doesn't match the previous cut or segment and we're not restarting the episode then
     * we're scrubbing.
     *
     * @param {number} fromZuluTimestamp
     * @param {number} toZuluTimestamp
     * @param {LiveTime} liveTime
     * @param {number} duration
     * @param {string} mediaType
     * @param {MediaTimeLine} mediaTimeLine
     * @returns {string[]}
     */
    private getSeekEventActions(
        fromZuluTimestamp: number,
        toZuluTimestamp: number,
        liveTime:  LiveTime,
        duration: number,
        mediaType: string,
        mediaTimeLine: MediaTimeLine): string[]
    {
        const isLive: boolean = MediaUtil.isLiveMediaType(mediaType);
        const episodeStartTimeZuluTimestamp: number = this.currentlyPlayingService.getCurrentEpisodeZuluStartTime();
        const episodeEndTimeZuluTimestamp: number = episodeStartTimeZuluTimestamp + duration;
        const closeToTime: number = 10 * 1000;

        // Default to unknown events and actions so it's really easy to identify unknown consume issues.
        let event0: string = ConsumeEventConsts.UNKNOWN;
        let event1: string = ConsumeEventConsts.UNKNOWN;
        let action0: string = ConsumeActionConsts.UNKNOWN;
        let action1: string = ConsumeActionConsts.UNKNOWN;

        const isForwardSeek: boolean = toZuluTimestamp > fromZuluTimestamp;
        const isBackwardSeek: boolean = toZuluTimestamp < fromZuluTimestamp;
        const isSeekTimeLive: boolean = isLive && Math.abs(toZuluTimestamp - liveTime.zuluMilliseconds) <= closeToTime;
        const isSeekTimeDuration: boolean = !isLive && (episodeEndTimeZuluTimestamp - toZuluTimestamp) <= closeToTime;

        if(isForwardSeek)
        {
            const nextCut: IMediaItem = findMediaItemByTimestamp(toZuluTimestamp, mediaTimeLine.cuts);
            const nextSegment: IMediaItem = findMediaItemByTimestamp(toZuluTimestamp, mediaTimeLine.segments);
            const nextCutTimestamp: number = (_.get(nextCut, "times.zuluStartTime", -1) || -1);
            const nextSegmentTimestamp: number = (_.get(nextSegment, "times.zuluStartTime", -1) || -1);
            const isNextCutTimestamp: boolean = toZuluTimestamp === nextCutTimestamp;
            const isNextSegmentTimestamp: boolean = toZuluTimestamp === nextSegmentTimestamp;
            const isNextMediaItemTimestamp: boolean = isNextCutTimestamp || isNextSegmentTimestamp;
            const isScrubbing: boolean = isSeekTimeDuration || !isNextMediaItemTimestamp;

            event0 = ConsumeEventConsts.END;
            event1 = ConsumeEventConsts.MARKER_START;

            action0 = ConsumeActionConsts.FORWARD;
            action1 = ConsumeActionConsts.FORWARD;

            if(isSeekTimeLive)
            {
                event0 = ConsumeEventConsts.END;
                event1 = ConsumeEventConsts.START;

                action0 = ConsumeActionConsts.LIVE;
                action1 = ConsumeActionConsts.LIVE;
            }
            else if(isScrubbing)
            {
                event0 = ConsumeEventConsts.END;
                event1 = ConsumeEventConsts.START;

                action0 = ConsumeActionConsts.SCRUB;
                action1 = ConsumeActionConsts.SCRUB;
            }

        }
        else if(isBackwardSeek)
        {
            const previousCut: IMediaItem = findMediaItemByTimestamp(toZuluTimestamp, mediaTimeLine.cuts);
            const previousSegment: IMediaItem = findMediaItemByTimestamp(toZuluTimestamp, mediaTimeLine.segments);
            const isPreviousCutTimestamp: boolean = toZuluTimestamp === (_.get(previousCut, "times.zuluStartTime", -1) || -1);
            const isPreviousSegmentTimestamp: boolean = toZuluTimestamp === (_.get(previousSegment, "times.zuluStartTime", -1) || -1);
            const isPreviousMediaItemTimestamp: boolean = isPreviousCutTimestamp || isPreviousSegmentTimestamp;
            const isRestart: boolean = Math.abs(toZuluTimestamp - episodeStartTimeZuluTimestamp) <= closeToTime;
            const isScrubbing: boolean = !isPreviousMediaItemTimestamp && !isRestart;

            event0 = ConsumeEventConsts.END;
            event1 = ConsumeEventConsts.MARKER_START;

            action0 = ConsumeActionConsts.BACK;
            action1 = this.isRestart === true ? ConsumeActionConsts.RESTART :ConsumeActionConsts.BACK;

            this.isRestart = false;

            if(isScrubbing)
            {
                event0 = ConsumeEventConsts.END;
                event1 = ConsumeEventConsts.START;

                action0 = ConsumeActionConsts.SCRUB;
                action1 = ConsumeActionConsts.SCRUB;
            }
        }

        return [
            `${ event0 }/${ action0 }`,
            `${ event1 }/${ action1 }`
        ];
    }


    /**
     *  Gets the seek events and actions for multi track
     * @param multiTrackJumpCuts
     */
    private getSeekEventActionsMultiTrack(multiTrackJumpCuts)
    {
        let event0: string = ConsumeEventConsts.END;
        let event1: string = ConsumeEventConsts.MARKER_START;
        let action0: string = ConsumeActionConsts.BACK;
        let action1: string = ConsumeActionConsts.BACK;

        if (multiTrackJumpCuts.from !== multiTrackJumpCuts.to)
        {
            action0 = action1 = ConsumeActionConsts.FORWARD;
        }

        return [
            `${ event0 }/${ action0 }`,
            `${ event1 }/${ action1 }`
        ];
    }


    private getFromToTimesMultiTrack(multiTrackJumpCuts, playhead: IPlayhead)
    {
        if (multiTrackJumpCuts.from === multiTrackJumpCuts.to)
        {
            return {
                from: this.multiTrackStreamStartDate.getTime() + playhead.currentTime.zuluMilliseconds,
                to: this.multiTrackStreamStartDate.getTime()
            };
        }
        return {
            from: this.multiTrackStreamStartDate.getTime() + playhead.currentTime.zuluMilliseconds,
            to: null
        };
    }

    /**
     * Observes the session state
     * Triggers tuneOut when session state is exited
     */
    private observeSessionState() : void
    {
        ConsumeService.logger.debug(`observeSessionState()`);

        this.authenticationService.userSession.pipe(
            filter((session: ISession) => session.exited === true))
            .subscribe(() =>
               {
                   if (!this.mediaPlayer) { return; } // no need to tuneOut, as we have not tunedIn yet

                   this.tuneOut(this.mediaPlayer).subscribe(() => ConsumeService.logger.debug("Media tuned out"));
               });
    }
}
