import { ApiLayerTypes } from "../service/consts/api.types";
import { IMediaCut, IYouJustHeard } from "../tune/tune.interface";
import { getAlbumArtForCut, getArtistNameForCut } from "../util/tune.util";
import { MediaUtil } from "../mediaplayer/media.util";

/**
 * Examine a cut and see if the user completely listened to the cut. A cut will be added to the
 * cutsJustHeard list if it meets the following criteria
 *
 * 1) Cuts contentType is a song, or contentType is missing and its pid starts with special song characters
 * 2) Cut has been played to within 2 seconds of the end of the cut (has not been skipped)
 *
 * @param {Array<IYouJustHeard>} oldYouJustHeard array of cuts that the user has "heard"
 * @param {IMediaCut} examine to see if it should be added to cutsJustHeard
 * @returns {Array<IYouJustHeard>} new array of cuts that have been heard
 */
export function getYouJustHeard(oldYouJustHeard: Array<IYouJustHeard>, cut: IMediaCut): Array<IYouJustHeard>
{
    const isInterstitial = cut && !MediaUtil.isSongCutContentType(cut)
                               && !MediaUtil.isTalkCutContentType(cut);
    const tileLimit = 5;

    if (!cut || isInterstitial || hasSkipped(cut)) return oldYouJustHeard;

    const newItem =
              {
                  albumImageUrl: cut ? getAlbumArtForCut(cut) : "",
                  title        : cut && cut.title ? cut.title : "",
                  artistName   : cut ? getArtistNameForCut(cut) : "",
                  cut          : cut
              };

    const newYouJustHeard: Array<IYouJustHeard> = oldYouJustHeard.filter(item => item.cut.assetGUID !== newItem.cut.assetGUID);

    newYouJustHeard.unshift(newItem);
    if(newYouJustHeard.length > tileLimit)
    {
        newYouJustHeard.splice(tileLimit);
    }

    return newYouJustHeard;
}

/**
 * Determines if there was a skip within threshold(2secs)
 * @param cut
 */
export function hasSkipped(cut: IMediaCut): boolean
{
    if (!cut) return;

    return cut.times.zuluEndTime - cut.lastPlaybackTimestamp > ApiLayerTypes.CUT_FULL_PLAYBACK_DIFFERENCE;
}
