import React, { useEffect } from 'react';
import Modal from '../../components/Modal/Modal';
import { focusManager } from '@accedo/vdkweb-navigation';
import { ttsPlayback } from '../../utils/ttsPlayback.util';
import { FocusButton, Page } from '@accedo/vdkweb-tv-ui';

const backButtonTheme = {
  button: 'back-button',
  buttonFocused: 'back-button-focused',
  buttonActive: 'back-button-active',
};
const privacyPolicyButtonTheme = {
  button: 'general-privacy-policy-button',
  buttonFocused: 'general-privacy-policy-button-focused',
  buttonActive: 'general-privacy-policy-button-active',
};
const privacyPolicyButtonNav = {
  id: 'privacy-policy-try-button',
};

const modalContainerNav = {
  id: 'modal-container',
  nextdown: 'privacy-policy-try-button',
  nextup: 'privacy-policy-try-button',
  nextleft: 'privacy-policy-try-button',
  nextright: 'privacy-policy-try-button',
};
export default ({
  hasTryFree,
  restoreFocus,
  freeTrialDurationText,
  handlePrivacyPolicy,
}) => {
  useEffect(() => {
    if (hasTryFree) {
      ttsPlayback(
        `${freeTrialDurationText}. Scan the QR code or visit siriusxm.com/vizio. Privacy Policies Button.`,
      );
      focusManager.changeFocus(modalContainerNav.id);
    } else {
      focusManager.changeFocus(restoreFocus);
    }
  }, [hasTryFree]);

  return (
    <Modal
      show={hasTryFree}
      styleOverride={{
        modal: 'overflow-visible',
        closeButton: '',
      }}
    >
      <Page nav={modalContainerNav} autoFocus>
        <div className="try-free-container">
          <div className="try-free-content">
            <div>
              <div className={backButtonTheme.button}>&nbsp;</div>
            </div>
            <div className="try-free-title">{`${freeTrialDurationText.substring(
              0,
              1,
            )}${freeTrialDurationText
              .substring(1)
              .toLowerCase()
              .replace('sxm', 'SXM')}`}</div>
            <div className="try-free-image">
              <img src={'/qr-code-vizio-en.png'} />
            </div>
            <div className="try-free-subtext">
              <span>Scan the QR code or visit </span>
              <span className="bold">siriusxm.com/vizio</span>
            </div>
            <FocusButton
              nav={privacyPolicyButtonNav}
              theme={privacyPolicyButtonTheme}
              onClick={handlePrivacyPolicy}
            >
              Privacy Policies
            </FocusButton>
          </div>
        </div>
      </Page>
    </Modal>
  );
};
