import React from 'react';
import classNames from 'classnames';
import { withFocus } from '@accedo/vdkweb-navigation';
import styles from './button.module.scss';

type Props = {
  onClick?: () => void;
  children?: React.ReactNode | string;
  className?: string;
  classNameFocused?: string;
  classNameActive?: string;
  isActive?: boolean;
};

const Button = ({
  nav: { id },
  onClick,
  isFocused,
  children,
  className,
  classNameFocused,
  classNameActive,
  isActive,
}: Props & WithFocusProps) => {
  return (
    <button
      id={id}
      onClick={onClick}
      className={classNames(
        styles.default,
        className,
        {
          [classNameFocused]: isFocused,
          [classNameActive]: isActive,
        },
      )}
    >
      {children}
    </button>
  );
};

export default withFocus(Button);
