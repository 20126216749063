import {
  CarouselTypeConst,
  ITile,
  IProviderDescriptor,
  addProvider,
} from '../../servicelib';
import { EllipsisStringUtil } from '../../utils/ellipsisString.util';
import { NeriticLinkService } from '../neritic-links';

export class CarouselTileService {
  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(CarouselTileService, CarouselTileService, [
      NeriticLinkService,
    ]);
  })();

  constructor(private neriticLinkService: NeriticLinkService) {}

  /**
   * Returns flag indicates show tile or not
   * @param tile
   * @returns {boolean}
   */
  public isShowTile({ tileContentType = '' }: ITile): boolean {
    return tileContentType.toLowerCase() === CarouselTypeConst.SHOW_TILE;
  }

  public isAodShowTile(tile: ITile): boolean {
    const isAodShowTile =
      tile.tileContentSubType.toLowerCase() === CarouselTypeConst.AOD_SHOW_TILE;

    return this.isShowTile(tile) && isAodShowTile;
  }

  public isVodShowTile(tile: ITile): boolean {
    const isVodShowTile =
      tile.tileContentSubType.toLowerCase() === CarouselTypeConst.VOD_SHOW_TILE;

    return this.isShowTile(tile) && isVodShowTile;
  }

  public isAodTile(tile: ITile): boolean {
    return this.isAodShowTile(tile) || this.isAodEpisode(tile);
  }

  public isVodTile(tile: ITile): boolean {
    return this.isVodShowTile(tile) || this.isVodEpisode(tile);
  }

  /**
   * Returns flag indicates if the tile is an episode tile
   * @param tile
   * @returns {boolean}
   */
  public isEpisodeTile({ tileContentType = '' }: ITile): boolean {
    return tileContentType.toLowerCase() === CarouselTypeConst.EPISODE_TILE;
  }

  /**
   * Returns whether the tile is a channel
   * @param {ITile} tile
   * @returns {boolean}
   */
  public isChannelTile({ tileContentType = '' }: ITile): boolean {
    const contentType = tileContentType.toLowerCase();

    return (
      contentType === CarouselTypeConst.CHANNEL_TILE ||
      contentType === CarouselTypeConst.LIVE_AUDIO ||
      contentType === CarouselTypeConst.LIVE_CONTINUE_LISTENING_TILE ||
      contentType === CarouselTypeConst.SEEDED_RADIO ||
      contentType === CarouselTypeConst.LIVE_SHOW_TILE
    );
  }

  /**
   * Returns flag indicates if the tile is an AOD episode tile
   * @param tile
   * @returns {boolean}
   */
  public isAodEpisode(tile: ITile): boolean {
    if (!tile) return;

    const isEpisode: boolean = this.isEpisodeTile(tile),
      isAodEpisode: boolean =
        tile.tileContentSubType.toLowerCase() ===
        CarouselTypeConst.AOD_EPISODE_TILE;

    return isEpisode && isAodEpisode;
  }

  /**
   * Returns flag indicates if the tile is a seeded radio tile
   * @param tile
   * @returns {boolean}
   */
  public isSeededRadioTile(tile: ITile): boolean {
    return (
      tile.tileContentType.toLowerCase() === CarouselTypeConst.SEEDED_RADIO ||
      tile.tileContentSubType.toLowerCase() === CarouselTypeConst.SEEDED_RADIO
    );
  }

  /**
   * Returns whether the episode is a VOD episode tile
   * @returns {boolean}
   */
  public isVodEpisode(tile: ITile): boolean {
    if (!tile) {
      return;
    }

    const isEpisode: boolean = this.isEpisodeTile(tile),
      isVodEpisode: boolean =
        tile.tileContentSubType.toLowerCase() ===
        CarouselTypeConst.VOD_EPISODE_TILE;

    return isEpisode && isVodEpisode;
  }

  /**
   * Returns flag indicates category/page tile or not
   * @param tile
   * @returns {boolean}
   */
  public isCategoryTile({ tileContentType = '' }: ITile): boolean {
    return (
      tileContentType.toLowerCase() === CarouselTypeConst.CATEGORY_TILE ||
      tileContentType.toLowerCase() ===
        CarouselTypeConst.SUPER_CATEGORY_TILE.toLowerCase()
    );
  }

  /**
   * Returns flag indicates additional channel tile or not
   * @param tile
   * @returns {boolean}
   */
  public isAdditionalChannelTile(tile: ITile): boolean {
    return (
      tile.tileContentSubType.toLowerCase() ===
      CarouselTypeConst.ADDITIONAL_CHANNEL
    );
  }

  /**
   * Returns flag indicates collection tile or not
   * @param tile
   * @returns {boolean}
   */
  public isCollectionTile({ tileContentType = '' }: ITile): boolean {
    return (
      tileContentType.toLowerCase() === CarouselTypeConst.COLLECTION ||
      tileContentType.toLowerCase() === CarouselTypeConst.PAGE_TILE
    );
  }

  /**
   * Returns flag indicates view -all  tile or not
   * @param tile
   * @returns {boolean}
   */
  public isViewAllTile({ tileContentType = '' }: ITile): boolean {
    return (
      tileContentType.toLowerCase() ===
      CarouselTypeConst.VIEW_ALL_TILE.toLowerCase()
    );
  }

  /**
   * Returns string with ellipsis if the imageAltText exceeds 40 characters
   * @param {string} imgAltText
   * @returns {string}
   */
  public getImageAltText(imgAltText: string): string {
    return EllipsisStringUtil.ellipsisString(imgAltText, 40);
  }

  /**
   * Takes the neritic link action for click events
   * @param {ITile} tile
   */
  public onClickNeriticLinkAction(tile: ITile): void {
    this.neriticLinkService.takePrimaryTileAction(tile);
  }

  /**
   * Takes neritic link action without propagating events when using keypress for accessibility
   * @param tile
   */
  public onKeyNeriticLinkAction(tile: ITile): void {
    if (document.activeElement.classList.contains('context-menu-btn')) return;
    this.neriticLinkService.takePrimaryTileAction(tile);
  }

  public getAriaLabelContentType(tile: ITile): string {
    let ariaText = '';

    if (this.isAodTile(tile)) {
      ariaText = 'common.audio';
    } else if (this.isVodTile(tile)) {
      ariaText = 'common.video';
    }

    return ariaText;
  }
}
