import {
  combineLatest as observableCombineLatest,
  of as observableOf,
  Observable,
} from 'rxjs';
import { switchMap, distinctUntilChanged, debounceTime } from 'rxjs/operators';
import * as _ from 'lodash';

import {
  AuthenticationService,
  ChannelLineupService,
  CurrentlyPlayingService,
  ICategory,
  IChannel,
  ICurrentlyPlayingMedia,
  ISubCategory,
  ISuperCategory,
  Logger,
  IProviderDescriptor,
  addProvider,
} from '../../servicelib';
import { displayViewType } from '../../types/display.view.type';

import * as ChannelList from '../../redux/action/channel-list.action';
import { IAppStore, IChannelListStore } from '../../redux/selector';
import {
  getLiveChannels,
  getSelectedChannel,
  ISelectedCategory,
} from '../../redux/selector/channel-list.store';
import { CarouselStoreService } from '../carousel-store/carousel.store.service';
import {
  //EnterNowPlaying,
  EnterNowPlayingPayload,
} from '../../redux/action/now-playing.action';
import { NowPlayingStoreService } from '../now-playing/now-playing.store.service';

export class ChannelListStoreService {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('ChannelListStoreService');

  /**
   * List of channels stores channels and Used to find channel in the list .
   */
  private channels: Array<IChannel>;

  /**
   * List of channels wrapped in observables from the NGRX channels store. The Observable list
   * wrapper will need to be stripped for use in the UI via the async pipe; e.g., "channels | async".
   */
  public channelStore: Observable<IChannelListStore>;

  /**
   * Used to store selected super category . this value used to make discoverOnDemandByCategoryKey call to get the shows and episodes.
   */
  private selectedSuperCategory: ISuperCategory;

  /**
   * Used to store selected category.
   */
  private selectedCategory: ISelectedCategory;

  /**
   * Used to store categories
   */
  private categories: Array<ISubCategory>;

  /**
   * The currently playing channel.
   */
  /*private selectedChannel$: Observable<IChannel> = this.store.select(
    getSelectedChannel,
  );*/
  private selectedChannel$: Observable<IChannel>;

  /**
   * The live channels array
   */
  /*private channels$: Observable<Array<IChannel>> = this.store.select(
    getLiveChannels,
  );*/
  private channels$: Observable<Array<IChannel>>;

  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(ChannelListStoreService, ChannelListStoreService, [
      ChannelLineupService,
      CarouselStoreService,
      CurrentlyPlayingService,
      NowPlayingStoreService,
      //Store<IAppStore>,
      AuthenticationService,
    ]);
  })();

  /**
   * Injects AJS controlled services and sets up the NGRX store.
   *
   * @param {ChannelLineupService} channelLineupService is used to observe the contents of the channel lineup and
   *                              on demand roster
   * @param {CarouselStoreService} carouselStoreService is used to trigger carousel activity
   * @param {nowPlayingStoreService} CurrentlyPlayingService is used to observe what the media player is playing
   * @param {NowPlayingStoreService} nowPlayingStoreService is used to select channels
   * @param {Store<IAppStore>} store is the redux store for the app
   * @param {AuthenticationService} authenticationService is used to exit the app when the lineup changes.
   */
  constructor(
    private channelLineupService: ChannelLineupService,
    private carouselStoreService: CarouselStoreService,
    private currentlyPlayingService: CurrentlyPlayingService,
    private nowPlayingStoreService: NowPlayingStoreService,
    //private store: Store<IAppStore>,
    authenticationService: AuthenticationService,
  ) {
    // Grab a slice of the store so views can subscribe to the data and populate our UI.
    //this.channelStore = this.store.select('channelStore');

    // Kickoff the subscription for the list of channels (which also contains the categories).
    //this.loadLineup();
    //this.loadLiveChannels();
    //this.checkForUnselectedChanel();

    this.channelLineupService.channelLineupChanged.subscribe(
      (flag: boolean) => {
        if (flag !== true) {
          return;
        }

        /**
         * NOTE : when the channel lineup changes, we reload the app.  Channel lineup changes are infrequent and
         * occur in the middle of the night on production systems.  We need to reload the entire lineup, and we may
         * see categories/supercategories/channels/episodes go away and be replaced by new stuff.
         *
         * Rather than scrub all the servicelib to make sure we capture all the changes, we just reStart the app
         * and have it go pull the new lineup and carousels so that we know everything matches the new lineup
         */

        authenticationService.exit();

        // reload after app has had 1 sec to exit and make all the API calls needed to properly terminate the app
        setTimeout(() => {
          location.reload();
        }, 1000);
      },
    );

    /*
    this.channelStore.subscribe(channelStore => {
      this.channels = channelStore.channels;
      this.selectedSuperCategory = channelStore.selectedSuperCategory;
      this.selectedCategory = channelStore.selectedCategory;
      this.categories = channelStore.categories;
    });
    */
  }

  /**
   * Gets a list of channels from the API via the service library. Once the list of channels
   * are acquired we dispatch an action to the store to save the channels on it.
   */
  public loadLineup(): void {
    ChannelListStoreService.logger.debug(`loadLineup()`);

    let loaded = false;

    /**
     * When the super categories array becomes available from the API, we can dispatch an action to the store
     * to trigger this.channelStore. There is also a one time selection of the default super category if
     * necessary
     * @param data
     * @returns {Observable<IChannelListStore>}
     */
    const onLineupLoaded = (data: Array<ISuperCategory>): void => {
      ChannelListStoreService.logger.debug(
        `onLineupLoaded( Channel lineup has ${data.length} supercategories )`,
      );

      if (data.length !== 0) {
        //this.store.dispatch(new ChannelList.LoadSuperCategories(data));
      }
    };

    this.channelLineupService.channelLineup.superCategories
      // TODO: BMR: Any reason we can't use `distinctUntilChanged()` otherwise it seems like it's called all the time?
      // .distinctUntilChanged()
      .subscribe(onLineupLoaded);
  }

  /**
   * Gets a list of live channels from the API via the service library. Once the list of live channels
   * are acquired we dispatch an action to the store to save the liveChannels on it.
   */
  public loadLiveChannels(): void {
    const onLiveChannelsLoaded = (data: Array<IChannel>): void => {
      if (data.length !== 0) {
        //this.store.dispatch(new ChannelList.LoadLiveChannels(data));
      }
    };

    this.channelLineupService.channelLineup.channels.subscribe(
      onLiveChannelsLoaded,
    );
  }

  /**
   * Dispatches the selected super category to the Redux store.
   * @param {ISuperCategory} superCategory
   */
  public selectSuperCategory(superCategory: ISuperCategory): void {
    const current = this.selectedSuperCategory
      ? this.selectedSuperCategory.key
      : '';
    const next = superCategory ? superCategory.key : '';

    if (!next) {
      return;
    }
    if (next !== current || !this.categories) {
      ChannelListStoreService.logger.debug(
        `selectSuperCategory( "${superCategory.name}", ID = ${superCategory.categoryGuid} )`,
      );
      //this.store.dispatch(new ChannelList.SelectSuperCategory(superCategory));
      this.carouselStoreService.selectSuperCategory(superCategory);
    }
  }

  /**
   * Given a channelId, find the first supercategory that channel belongs to and select it
   * @param {string} channelId is the channelId to find the supercategory for
   */
  public selectSuperCategoryByChannelId(channelId: string) {
    const channel = this.channelLineupService.findChannelById(channelId);
    const superCategory = channel
      ? channel.firstSuperCategory
      : this.selectedSuperCategory;

    this.selectSuperCategory(superCategory);
  }

  /**
   * Dispatches the selected category to the Redux store.
   * @param {ICategory} category
   */
  public selectCategory(
    category: ICategory,
    displayView: displayViewType = displayViewType.channels,
  ): void {
    ChannelListStoreService.logger.debug(
      `selectCategory( "${category.name}", ID = ${category.categoryGuid} )`,
    );
    if (!category.key || category.key.length === 0) {
      ChannelListStoreService.logger.debug(
        `invalid category - key is not provided : "${JSON.stringify(
          category,
        )}"`,
      );
      return;
    }

    const selectedCategory: ISelectedCategory = {
      category: category as ISubCategory,
      viewType: displayView,
    };

    // Immediately select the new category in the redux store.
    /*this.store.dispatch(
      new ChannelList.SelectCategory(selectedCategory as ISelectedCategory),
    );*/

    this.carouselStoreService.selectSubCategory(category as ISubCategory);
  }

  /**
   * Given a channelId, find the first category that channel belongs to and select it
   * @param {string} channelId is the channelId to find the category for
   */
  public selectCategoryByChannelId(channelId: string) {
    const channel = this.channelLineupService.findChannelById(channelId);
    const category = channel
      ? channel.firstSubCategory
      : this.selectedCategory.category;

    this.selectCategory(category);
  }

  /**
   * Dispatches the selected channel to the Redux store.
   * @param {IChannel} channel
   */
  public selectChannel(channel: IChannel): void {
    ChannelListStoreService.logger.debug(
      `selectChannel( "${channel?.name}", ID = ${channel?.channelId} )`,
    );
    //this.store.dispatch(new ChannelList.SelectChannel(channel));
    //store.dispatch(ChannelList.selectChannel(channel));
  }

  /**
   * Select a channel using channelId
   * @param channelId of the channel to select
   */
  public selectChannelByChannelID(channelId: string) {
    this.selectChannel(this.channelLineupService.findChannelById(channelId));
  }

  /**
   * Subscribes to changes on the channel filter term and only applies them every n milliseconds (using
   * `debounceTime()` and only when the term has truly changed using `distinctUntilChanged()` -- this
   * means we can rest assured that our filter is only applied when there is a real, incoming change in
   * the filter and not simply on every keystroke which can be unnecessary when you take into account
   * typing with backspaces or deletes.
   *
   * @param {Observable<string>} terms
   * @returns {Subscription}
   */
  public filter(termAndDisplayView: Observable<[string, displayViewType]>) {
    return termAndDisplayView
      .pipe(
        debounceTime(0),
        distinctUntilChanged(),
        switchMap(term => observableOf(term)),
      )
      .subscribe(([term = '', displayType]) => {
        //let action: Action = null;
        let action: any = null;

        if (displayType === displayViewType.channels) {
          action = isNaN(parseInt(term, 10))
            ? new ChannelList.FilterChannelsByName(term)
            : new ChannelList.FilterChannelsByNumber(term);
        } else if (
          displayType === displayViewType.ondemand ||
          displayType === displayViewType.shows
        ) {
          action = new ChannelList.FilterChannelsByShowName(term);
        } else {
          ChannelListStoreService.logger.warn(
            `filter( No matching display type found. )`,
          );
          return;
        }

        ChannelListStoreService.logger.debug(`filter( Term: "${term}" )`);
        //this.store.dispatch(action);
      });
  }

  /**
   * Dispatches the sort type so it's saved on the store.
   */
  public sort(): void {
    ChannelListStoreService.logger.debug(`sort()`);
    //this.store.dispatch(new ChannelList.SortChannelsByNumber());
  }

  /**
   * Determines if there's no selected channel in the store, which can happen when refreshing on or deep linking to the now playing route.
   * If there's no selected channel, we grab the one from the now playing data as well as the super and sub category for it
   * and set them all on the store.
   */
  private checkForUnselectedChanel(): void {
    observableCombineLatest(
      this.selectedChannel$,
      this.currentlyPlayingService.currentlyPlayingData,
      this.channels$,
      (
        channel: IChannel,
        currentlyPlayingData: ICurrentlyPlayingMedia,
        channels: IChannel[],
      ) => {
        if (channels.length === 0) return;
        const isInvalidChannel: boolean = !channel || !channel.channelId;
        const channelId: string = _.get(
          currentlyPlayingData,
          'channelId',
          null,
        );
        if (channels && isInvalidChannel && channelId) {
          const selectedChannel: IChannel = this.channelLineupService.findChannelById(
            currentlyPlayingData.channelId,
          );

          // It's possible that we have a channel ID, but we couldn't find the channel and we don't want to push a
          // falsy value to the store as the selected channel.
          if (selectedChannel) {
            if (!this.selectedSuperCategory) {
              this.selectedSuperCategory = selectedChannel.firstSuperCategory;
              this.selectSuperCategory(selectedChannel.firstSuperCategory);
            }
            if (!this.selectedCategory) {
              this.selectCategory(selectedChannel.firstSubCategory);
            }

            this.setNowPlayingEntering(currentlyPlayingData.mediaType);
            this.nowPlayingStoreService.selectNowPlayingChannel(
              selectedChannel,
            );
            this.selectChannel(selectedChannel);
          }
        }
      },
    ).subscribe();
  }

  /**
   * used to set the content type into store.
   * @param {string} mediaType
   */
  private setNowPlayingEntering(mediaType: string): void {
    const payload: EnterNowPlayingPayload = {
      mediaType: mediaType,
      startTime: 0,
    };
    //this.store.dispatch(new EnterNowPlaying(payload));
  }

  /**
   * Returns the channel pdt
   * Normal cases pdt1-pdt2. if both pdt not exists then uses the channel name
   * @param {string} name
   * @param {string} playingArtist
   * @param {string} playingTitle
   * @returns {string}
   */
  getChannelPdt(
    playingArtist: string,
    playingTitle: string,
    name: string,
  ): string {
    if (!playingArtist && !playingTitle) {
      return name;
    }
    let pdt = playingArtist;
    if (pdt) {
      pdt += ' - ';
    }
    pdt += playingTitle;
    return pdt;
  }
}
