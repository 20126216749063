import { of as observableOf, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpProvider } from "../http";
import { ServiceEndpointConstants } from "../service/consts/service.endpoint.constants";
import { IProviderDescriptor, addProvider, ApiCodes } from "../";
import { GeolocationParams } from "./geolocation.interface";

export class GeolocationDelegate
{
    public fullResponse = {};
    constructor(private httpProvider: HttpProvider)
    {}

    /**
     * Makes a call to check if the user's location is allowed to listen to any given content.
     * Unless we receive either geolocation error code from the API then the content is treated
     * as unrestricted.
     *
     * @param {any} channelID
     * @param {any} episodeId
     * @returns {Observable<boolean>}
     * @memberof GeolocationDelegate
     */
    public getGeoRestrictionStatusForLocation(channelID: string, episodeId: string = ''): Observable<[boolean, number]>
    {
        let paramObj: GeolocationParams = {
            params: {
                channelID
            }
        };

        if (episodeId !== '') paramObj.params.episodeCAID = episodeId;

        return (
            this.httpProvider
                .get(ServiceEndpointConstants.endpoints.GEOLOCATION.V2_CHECK, null, paramObj).pipe(
                tap((response) =>
                {
                    this.fullResponse = response;
                }),
                map(() => [ true, 100 ]),
                catchError(error =>
                {
                    return (error.code === ApiCodes.GEO_LOCATION_UNAVAILABLE
                            || error.code === ApiCodes.GEO_LOCATION_ERROR)
                        ? observableOf([false, error.code])
                        : observableOf([true, 100]) as Observable< [boolean, number] >;
                }))
        ) as Observable<[ boolean, number ]>;
    }

    private static providerDescriptor: IProviderDescriptor = function()
    {
        return addProvider(GeolocationDelegate, GeolocationDelegate, [ HttpProvider ]);
    }();
}
