import { IAppConfig } from '../../config/interfaces/app-config.interface';
import { IRelativeUrlConfig } from '../../config/interfaces/config.interface';
import { ConfigService } from '../../config/config.service';
import {
  ICdnAccessToken,
  ResponseInterceptor,
} from '../../http/http.provider.response.interceptor';
import { Logger } from '../../logger/logger';
import { MediaTimeLine } from '../../../index';
import { ServiceEndpointConstants } from '../../service/consts/service.endpoint.constants';
import { TokenStrategies } from '../../service/consts/token.strategies';
import { IProviderDescriptor } from '../../service/provider.descriptor.interface';
import { SettingsService } from '../../settings/settings.service';
import { MediaUtil } from '../media.util';
import { addProvider } from '../../index';
import { PlayerConfig } from '../audioplayer/audio-player.config';
import { AudioPlayerConstants } from '../audioplayer/audio-player.consts';
import { IClip } from '../../tune/tune.interface';
import { MediaPlayerConstants } from '../media-player.consts';
import { ContentTypes } from '../../service/types/content.types';
import { filter } from 'rxjs/operators';

/**
 * @MODULE:     service-lib
 * @CREATED:    10/12/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * Creational factory (albeit without a static method) to acquire an audio player configuration object.
 */
export class MultiTrackAudioPlayerConfigFactory {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger(
    'MultiTrackAudioPlayerConfigFactory',
  );

  private primaryHLSDomain: string = '';

  /**
   * cdnAccessToken assigned from responseInterceptor.cdnAccessTokens Observable.
   * And used to send token information to web audio player.
   */
  private cdnAccessTokens: ICdnAccessToken;

  /**
   * Reflects the current media timeline.
   * @type {MediaTimeLine}
   */
  private mediaTimeLine: MediaTimeLine;

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(
      MultiTrackAudioPlayerConfigFactory,
      MultiTrackAudioPlayerConfigFactory,
      [ConfigService, SettingsService, ResponseInterceptor, 'IAppConfig'],
    );
  })();

  /**
   * Constructor
   * @param {ConfigService} configService
   * @param {SettingsService} settingsService
   * @param {ResponseInterceptor} responseInterceptor
   * @param {IAppConfig} SERVICE_CONFIG
   */
  constructor(
    private configService: ConfigService,
    private settingsService: SettingsService,
    private responseInterceptor: ResponseInterceptor,
    private SERVICE_CONFIG: IAppConfig,
  ) {
    this.observeCdnAccessTokens();
    this.observeRelativeUrlConfiguration();
  }

  /**
   * Returns the media player service for the given type of media requested.
   */
  public getConfig(mediaTimeLine: MediaTimeLine, track: IClip): PlayerConfig {
    this.mediaTimeLine = mediaTimeLine;
    // this.setMediaTimeLineMediaEndPointsUrls();

    const config: PlayerConfig = new PlayerConfig();

    /**
     * Used to get the tokenized audio player asset URLs, this function sets in config objecta and it sends to audio player.
     *
     *
     * NOTE: The Reason for this Method: Web audio player cant directly invokes `TokenStrategies.getTokenizedUrl()`
     * due to cdnAccessTokens are not available, so this method is wrapper on top of TokenStrategies.
     *
     * @param {string} url
     * @returns {string}
     */
    const getTokenizedUrl = (url: string): string => {
      return TokenStrategies.getTokenizedUrl(url, this.cdnAccessTokens);
    };

    config.getTokenizedUrl = getTokenizedUrl.bind(this);
    config.getTokenStrategy = TokenStrategies.getTokenStrategy;
    config.keyPath =
      this.SERVICE_CONFIG.apiEndpoint + AudioPlayerConstants.KEY_PATH;
    config.connectivityUrl =
      this.SERVICE_CONFIG.apiEndpoint +
      ServiceEndpointConstants.NETWORK_CONNECTIVITY;
    config.autoPlay = this.getAutoPlay(mediaTimeLine);
    config.crossFadeEnabled = false;
    config.crossfade =
      this.SERVICE_CONFIG.deviceInfo.player ===
      MediaPlayerConstants.HTML5_PLAYER
        ? track.crossfade
        : undefined;
    config.duration = track.duration;
    config.hlsProxyEnabled = false;
    config.loggerEnabled = Logger.isEnabled;
    config.playbackType = this.getPlaybackType();
    config.tuneStart = this.settingsService.isTuneStartOn();
    config.higherQualityAudio = this.settingsService.isHigherAudioQuality();
    config.maxQualityAudio = this.settingsService.isMaximumAudioQuality();
    config.lockInMaximumBitrate = config.maxQualityAudio;
    config.primaryAudioURL = this.getDefaultUrl(track);
    config.audioURLs = this.getAudioUrls(track);
    config.audioFirstURLs = this.getAudioFirstUrls();
    config.hlsConsumptionInfo = '';
    config.id = track.assetGUID;
    config.useMediaElement = true;

    //Added to support chromecast
    config.assetGuid = track.assetGUID;
    config.channelId = this.mediaTimeLine.channelId;
    config.mediaType = this.mediaTimeLine.mediaType;

    return config;
  }

  /**
   * determine auto playback.
   * @returns {boolean}
   */
  public getAutoPlay(mediaTimeLine): boolean {
    return (
      !mediaTimeLine.isDataComeFromResume ||
      mediaTimeLine.isDataComeFromResumeWithDeepLink
    );
  }

  /**
   * Used to get playback type based on Live or On Demand audio (ala AOD).
   * @returns {string}
   */
  public getPlaybackType(): string {
    if (MediaUtil.isAudioMediaTypeLive(this.mediaTimeLine.mediaType)) {
      return AudioPlayerConstants.LIVE;
    } else if (
      MediaUtil.isAudioMediaTypeOnDemand(this.mediaTimeLine.mediaType)
    ) {
      return AudioPlayerConstants.AOD;
    } else if (
      this.mediaTimeLine.mediaType.toLowerCase() ===
      ContentTypes.ADDITIONAL_CHANNELS
    ) {
      return AudioPlayerConstants.MY;
    } else if (
      this.mediaTimeLine.mediaType.toLowerCase() === ContentTypes.SEEDED_RADIO
    ) {
      return AudioPlayerConstants.SEEDED;
    }

    return '';
  }

  /**
   * Returns the default HLS URL for audio.
   * @returns {string}
   */
  private getDefaultUrl(track): string {
    return this.getAudioUrls(track)[0].small;
  }

  /**
   * Used to create and get Audio Urls Object which can be fed into config object.
   * @returns {any}
   */
  private getAudioUrls(track): Array<any> {
    return [
      {
        small: track.mediaEndPoints[0].url.replace(
          AudioPlayerConstants.AIC_PRIMARY_HLS,
          this.primaryHLSDomain,
        ),
      },
    ];
  }

  /**
   * Used to create and get Audio First Urls Object which can be fed into config object.
   * @returns {any}
   */
  private getAudioFirstUrls(): Array<any> {
    return [];
  }

  /**
   * Used to normalize configService.relativeUrlConfiguration to this.
   * @param {IRelativeUrlConfig} relativeUrlConfig
   */
  private setRelativeUrlValues(relativeUrlConfig: IRelativeUrlConfig) {
    const setting = relativeUrlConfig.settings.find(setting => {
      return setting.name === AudioPlayerConstants.AIC_PRIMARY_URL;
    });

    if (!setting) {
      throw 'no domain set up';
    }

    this.primaryHLSDomain = setting.url.replace('http:', 'https:');
  }

  /**
   * Observe data changes on the CDN access tokens. If there's changes, update the them.
   */
  private observeCdnAccessTokens() {
    MultiTrackAudioPlayerConfigFactory.logger.debug('observeCdnAccessTokens()');

    this.responseInterceptor.cdnAccessTokens.subscribe(
      onCdnAccessTokensSuccess.bind(this),
      onCdnAccessTokensFault.bind(this),
    );

    /**
     * Updates the CN Access tokens.
     * @param {ICdnAccessToken} cdnAccessTokens
     */
    function onCdnAccessTokensSuccess(cdnAccessTokens: ICdnAccessToken) {
      if (cdnAccessTokens) {
        MultiTrackAudioPlayerConfigFactory.logger.debug(
          'onCdnAccessTokensSuccess( Update tokens. )',
        );
        this.cdnAccessTokens = cdnAccessTokens;
      }
    }

    /**
     * If observable throws a fault then logs the error message.
     * @param error
     */
    function onCdnAccessTokensFault(error: any) {
      MultiTrackAudioPlayerConfigFactory.logger.warn(
        `onCdnAccessTokensFault( Error: ${JSON.stringify(error)}. )`,
      );
    }
  }

  /**
   * Observe data changes on the URL config. If there's changes, update the URLs.
   */
  private observeRelativeUrlConfiguration() {
    MultiTrackAudioPlayerConfigFactory.logger.debug(
      'observeRelativeUrlConfiguration()',
    );

    this.configService.relativeUrlConfiguration
      .pipe(filter(data => !!data))
      .subscribe(
        onRelativeUrlConfigurationSuccess.bind(this),
        onRelativeUrlConfigurationFault.bind(this),
      );

    /**
     * Updates the relative URLs.
     * @param {IRelativeUrlConfig} relativeUrlConfig
     */
    function onRelativeUrlConfigurationSuccess(
      relativeUrlConfig: IRelativeUrlConfig,
    ) {
      MultiTrackAudioPlayerConfigFactory.logger.debug(
        'onRelativeUrlConfigurationSuccess()',
      );
      this.setRelativeUrlValues(relativeUrlConfig);
    }

    /**
     * If observable throws a fault then logs the error message.
     * @param error
     */
    function onRelativeUrlConfigurationFault(/*error: any*/) {
      MultiTrackAudioPlayerConfigFactory.logger.warn(
        'onRelativeUrlConfigurationFault( Error {error} for relative URL config observable. )',
      );
    }
  }
}
