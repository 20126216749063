import {
  of as observableOf,
  timer as observableTimer,
  Observable,
  BehaviorSubject,
} from 'rxjs';
import { catchError, share, first, mergeMap, take, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { IAppConfig, IClientConfiguration, IProfileData } from '../config';
import {
  IProviderDescriptor,
  IDeepLinkData,
  IPausePoint,
  addProvider,
  ApiCodes,
  ApiLayerTypes,
  ContentTypes,
  HTTP_ERROR_MESSAGE,
  HTTP_NETWORK_ERROR,
  tuneApiCodes,
} from '../service';
import { Logger } from '../logger';
import { SessionMonitorService } from '../session/session-monitor.service';
import { IResumeMedia } from './resume.interface';
import { DeepLinkTypes, INeriticLinkData } from '../index';
import { ResumeDelegate } from './resume.delegate';
import { ResumeModel } from './resume.model';
import { ApiDelegate, HttpProvider } from '../http';
import { AuthenticationService, ISession } from '../authentication';
import { AppMonitorService } from '../app-monitor/app-monitor.service';
import { apiToFault } from '../service/consts/app.errors';
import { APP_ERROR_STATE_CONST } from '../app-monitor/app-monitor.interface';
import { ServiceEndpointConstants } from '../service/consts/service.endpoint.constants';
import { AppErrorCodes } from '../service/consts';
import { neriticActionConstants } from '../service/consts/neritic-action-const';
//import { parseTwoDigitYear } from 'moment';

/**
 * @MODULE:     service-lib
 * @CREATED:    07/19/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * Resume Service is used to (1) (re)validate the user's session and (2) find out what the user was last listening to
 * or viewing and go back to that content
 */

export class ResumeService {
  /**
   * Internal logger.
   * @type {Logger}
   */
  private static logger: Logger = Logger.getLogger('ResumeService');

  /**
   * The users session information is stored here
   */
  private session: ISession;

  /**
   *The debounce time and used in seconds
   * @type {number}
   */
  public static RESUME_DEBOUNCE_TIME = 5000; // min 5 seconds allowed between resumes to backend happy

  /**
   * The last time a resume call was triggered in UTC zulu time milliseconds
   * @type {number}
   */
  private lastResumeTimeInMs: number =
    new Date().getTime() - ResumeService.RESUME_DEBOUNCE_TIME;

  /**
   * The observable from the last resume call made (if any)
   */
  private lastResume: Observable<any> = null;

  /**
   * Boolean that indicates whether or not a resume call is already in progress.
   */
  private resumeInProgress: boolean = false;

  /**
   * Behavior subject used to broadcast when the resume has finished
   */
  private resumeIsComplete: BehaviorSubject<boolean> = new BehaviorSubject(
    false,
  );

  /**
   * Observable to communicate resume has finished
   */
  public resumeIsComplete$: Observable<boolean> = this.resumeIsComplete.pipe(
    share(),
  );

  /**
   * resumeMediaData represents the resume service response based on Live or AOD.
   * @type {any}
   */
  private resumeMediaData: IResumeMedia = null;

  /**
   * subject for delivering resume media data through resumeMedia Observable.
   * @type {any}
   */
  private resumeMediaSubject: BehaviorSubject<
    IResumeMedia
  > = new BehaviorSubject(this.resumeMediaData);

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the resumeMediaData
   * and be notified when the resumeMediaData data changes.
   */
  public resumeMedia: Observable<IResumeMedia> = this
    .resumeMediaSubject as Observable<IResumeMedia>;

  /**
   * subject for delivering profileData through profileData Observable.
   * @type {any}
   */
  private profileDataSubject: BehaviorSubject<
    IProfileData
  > = new BehaviorSubject({} as IProfileData);

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the profileData
   * and be notified when the profileData data changes.
   */
  public profileData: Observable<IProfileData> = this.profileDataSubject;

  /**
   * An endpoint we can query for the latest published version of the client
   */
  private versionEndpoint: string;

  /**
   * The resume cool off time in milliseconds.
   * @type {number}
   */
  public static RESUME_COOLOFF_TIME = 5000; // 5 seconds

  private onOpenPeriodStateSubject: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(false);
  public onOpenPeriodState$: Observable<boolean> = this
    .onOpenPeriodStateSubject;

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(ResumeService, ResumeService, [
      ResumeDelegate,
      ResumeModel,
      ApiDelegate,
      AuthenticationService,
      AppMonitorService,
      SessionMonitorService,
      HttpProvider,
      'IAppConfig',
    ]);
  })();

  /**
   * Constructor.
   * @param {ResumeDelegate} resumeDelegate allows the resumeService to make API calls for resume functionality
   * @param {ApiDelegate} apiDelegate allows the resumeService to register API code error handlers
   * @param {AuthenticationService} authService allows the resume service to inform the authentication services of
   *        changes to the user session
   * @param {AppMonitorService} appMonitorService allows the resume service to inform the app monitor service of
   *        errors
   * @param {SessionMonitorService} sessionMonitorService allows the resume service to inform the session monitor
   *        service of changes to the users session
   * @param {HttpProvider} httpProvider allows the resume service to retrieve the json file to determine the
   *        latest published version of the app
   * @param {IAppConfig} SERVICE_CONFIG contains the configuration for the service layer
   */
  constructor(
    private resumeDelegate: ResumeDelegate,
    private resumeModel: ResumeModel,
    private apiDelegate: ApiDelegate,
    private authService: AuthenticationService,
    private appMonitorService: AppMonitorService,
    private sessionMonitorService: SessionMonitorService,
    private httpProvider: HttpProvider,
    private SERVICE_CONFIG: IAppConfig,
  ) {
    this.versionEndpoint =
      SERVICE_CONFIG.apiEndpoint + ServiceEndpointConstants.APP_VERSION;
    this.resumeModel.resumeComplete$ = this.resumeIsComplete;

    // Trigger Resume when either duplicate login or it down properties *were* true and are *now* false
    this.authService.userSession.subscribe(session => {
      const duplicateLoginDetected =
        !!this.session &&
        this.session.duplicateLogin &&
        !session.duplicateLogin;
      const itDownDetected =
        !!this.session && this.session.itDown && !session.itDown;

      if (duplicateLoginDetected || itDownDetected) {
        const reason = duplicateLoginDetected ? 'duplicate login' : 'it down';

        ResumeService.logger.warn(`Api ${reason} encountered, trigger resume`);
        reclaimSession(this);
      }
      this.session = Object.assign({}, session);
    });

    apiDelegate.addApiCodeHandler(
      ApiCodes.SESSION_RESUME,
      (codes, messages, response) => {
        if (
          response.config.url !==
          ServiceEndpointConstants.endpoints.ANALYTICS.V1_ANALYTICS_REPORT
        ) {
          ResumeService.logger.warn(
            `Api ${ApiCodes.SESSION_RESUME} encountered, trigger resume`,
          );
          this.resume(null, isTuneNeeded(response));
        }
      },
    );

    ApiDelegate.addNoResponseDataException(this.versionEndpoint, 'true');
    apiDelegate.addApiErrorHandler(clearDeepLink);

    /**
     * Make a resume call to reclaim the user session.  This can happen when a duplicate login is detected
     * and the user selects to continue this session *or* when the API indicates that an IT down situation has
     * occured and we need to reauthenticate the user session
     *
     * @param resumeService
     */
    function reclaimSession(resumeService: ResumeService) {
      // If it is down, and we are trying to reclaim the session, does not matter if we have a resume
      // in progress or not, we are triggering another resume
      if (resumeService.session.itDown === true) {
        resumeService.resumeInProgress = false;
      }

      resumeService
        .resume()
        .pipe(first())
        .subscribe(response => {
          if (!response) {
            return;
          }

          sessionMonitorService.updateSimultaneousListen(
            APP_ERROR_STATE_CONST.SIMULTANEOUS_LISTEN_DISABLED,
          );
          sessionMonitorService.updateITDown(false);
        });
    }

    /**
     * Clears the deep Link if API sends Channel not available/AOD Expire content.
     * @param {number} apiCodes is an array of API codes that were received with an  API response
     */
    function clearDeepLink(apiCodes: Array<number>) {
      if (
        apiCodes[0] === ApiCodes.AUTH_REQUIRED ||
        apiCodes[0] === ApiCodes.SESSION_RESUME ||
        apiCodes[0] === ApiCodes.SIMULTANEOUS_LISTEN
      ) {
        return;
      }

      if (SERVICE_CONFIG.contextualInfo.deepLink === true) {
        ResumeService.logger.error(
          `Api ${apiCodes[0]} encountered, clear deep links`,
        );
        SERVICE_CONFIG.contextualInfo.id = '';
        SERVICE_CONFIG.contextualInfo.type = '';
        SERVICE_CONFIG.contextualInfo.deepLink = false;
      }
    }

    /**
     * determine if a tune is needed for a session reclaim resume based on an API response structure and endpoint
     * that triggered the session reclaim
     *
     * @param response is the API response from the call that triggered the session reclaim
     */
    function isTuneNeeded(response) {
      return (
        response &&
        response.config.url ===
          ServiceEndpointConstants.endpoints.CHROMECAST.V4_CAST_STATUS &&
        response.config.params.claim === true
      );
    }
  }

  /**
   * Make a resume call to the API.  Resume calls will only go out once every ResumeService.RESUME_DEBOUNCE_TIME
   * seconds.
   * @param pausePointDetails contains (optional) pause point information tn be sent with the resume call
   * @param tuneNeeded indicates whether a tune is required when performing this resume.  Distingiushes between
   *        resumes triggered to authenticate the users session only vs the initial resume needed to also establish
   *        content for playback (defaults to true)
   * @returns {any} observable with the results of the resume
   */
  public resume(
    pausePointDetails?: IPausePoint,
    tuneNeeded: boolean = true,
  ): Observable<boolean> {
    const nowInMs = new Date().getTime();
    const diffInMs = Math.abs(nowInMs - this.lastResumeTimeInMs);
    const openAccess = this.session.activeOpenAccessSession;
    const type = this.SERVICE_CONFIG.contextualInfo.type;
    const id = this.SERVICE_CONFIG.contextualInfo.id;
    let deepLink: IDeepLinkData = null;

    if (
      (type && id) ||
      type === DeepLinkTypes.VIDEOS ||
      type === DeepLinkTypes.PODCASTS
    ) {
      deepLink = {
        type: this.SERVICE_CONFIG.contextualInfo.type,
        id: this.SERVICE_CONFIG.contextualInfo.id,
      };
    }

    /**
     * NOTE, whenever we want to make a resume call, we check the version information against what is embedded
     * in the app.  If they do *not* match this is an indication that a new app has been deployed to our endpoint
     * and we should refresh the app to download the new version.
     *
     * This is especially important for app changes where the API has changed, or we need to rev the app to
     * solve issue where we are cuasing backend problems.  This ensures that whenever engage with the new API
     * we also make sure that we reload the app and get the new app as well.
     */
    /*
    this.verifyAppVersion().subscribe((valid: boolean) => {
      if (!valid) {
        //TODO: Determine how to sync current development app version with version json from dev server to avoid infinite loop
        //this.SERVICE_CONFIG.restart();
      }
    });
    */

    if (this.resumeInProgress === false) {
      this.resumeInProgress = true;

      if (diffInMs >= ResumeService.RESUME_COOLOFF_TIME) {
        this.lastResumeTimeInMs = nowInMs;
        this.lastResume = this.callResume(
          pausePointDetails,
          openAccess,
          deepLink,
          tuneNeeded,
        );
      } else {
        this.lastResume = observableTimer(
          ResumeService.RESUME_DEBOUNCE_TIME - diffInMs,
        ).pipe(
          first(),
          mergeMap(() => {
            this.lastResumeTimeInMs = new Date().getTime();
            return this.callResume(
              pausePointDetails,
              openAccess,
              deepLink,
              tuneNeeded,
            );
          }),
          take(1), // observable should complete after 1 reported event
          share(),
        ); // 1 call for all subscribers
      }

      const onDone = () => {
        this.resumeInProgress = false;
      };

      this.lastResume.subscribe({
        next: onDone,
        error: onDone,
        complete: onDone,
      });
    }

    return this.lastResume;
  }

  /**
   * This function will pull a remote json file and check the app version against the version information embedded
   * in the source. Returns an observable that resolves to true if the versions match and false otherwise
   *
   * @returns {Observable<boolean>}
   */
  private verifyAppVersion(): Observable<boolean> {
    return this.httpProvider
      .get(this.versionEndpoint, null, { isRaw: true })
      .pipe(
        map(
          (version: {
            version: string;
            hash: string;
            build: string;
          }): boolean => {
            if (
              !version ||
              !version.version ||
              !version.build ||
              !version.hash
            ) {
              return true;
            }
            return checkAppVersion(version, this.SERVICE_CONFIG);
          },
        ),
      );

    function checkAppVersion(
      version: { version: string; hash: string; build: string },
      config: IAppConfig,
    ): boolean {
      const remoteAppVersion = version.version + '.' + version.build;
      const remoteAppHash = version.hash;

      if (
        config.deviceInfo.sxmAppVersion !== remoteAppVersion ||
        config.deviceInfo.sxmGitHashNumber !== remoteAppHash
      ) {
        ResumeService.logger.error('Version mismatch detected');
        return false;
      }

      return true;
    }
  }

  /**
   * Used to make resume API Call. Once resume call returns the response, sets the resumeMediaData and
   * triggers Observable. The same Observable subscribed in TuneService.
   * @param pausePointDetails contains (optional) pause point information tn be sent with the resume call
   * @param openAccess contains optional true if this is an open access "accept" resume, false otherwise
   * @param deepLink contains optional information about deep linking
   * @param tuneNeeded indicates whether a tune is required when performing this resume.  Distingiushes between
   *        resumes triggered to authenticate the users session only vs the initial resume needed to also establish
   *        content for playback
   * @returns {Observable<boolean>} - Based on API response returns boolean flag.
   */
  private callResume(
    pausePointDetails?: IPausePoint,
    openAccess?: boolean,
    deepLink?: IDeepLinkData,
    tuneNeeded?: boolean,
  ): Observable<boolean> {
    const resume: Observable<any> = this.resumeDelegate.resume(
      pausePointDetails,
      openAccess,
      deepLink,
      tuneNeeded,
    );

    return resume.pipe(
      map(response => this.onResumeSuccess(response /*tuneNeeded*/)),
      catchError(error => this.onResumeFailure(error)),
      map(result => this.onResumeDone(result, tuneNeeded)),
      share(),
    );
  }

  /**
   * On resume response available, save it to the resume media data.
   * @param response - returned by the delegate.
   * @param tuneNeeded indicates whether a tune is required when performing this resume.  Distingiushes between
   *        resumes triggered to authenticate the users session only vs the initial resume needed to also establish
   *        content for playback
   */
  private onResumeSuccess(response: any /*tuneNeeded: boolean*/) {
    const profileData: any = _.get(
      response,
      'getAllProfilesData.profileData',
      {} as IProfileData,
    );

    this.profileDataSubject.next(profileData[0]);

    if (
      response.globalSettingList &&
      response.globalSettingList.globalSettings
    ) {
      this.resumeModel.globalSettingsSubject.next(
        response.globalSettingList.globalSettings,
      );
    }

    const streamingAccess =
      response.clientConfiguration &&
      response.clientConfiguration.streamingAccess;
    if (!streamingAccess && !this.SERVICE_CONFIG.isFreeTierEnable) {
      this.appMonitorService.triggerFaultError({
        faultCode: AppErrorCodes.FLTT_NO_STREAMING_ACCESS,
      });
    }

    if (!response.moduleType && !response.resumeAction) {
      return response;
    }

    return response;
  }

  /**
   * On the delegate throws exception then fault handler get called.
   *
   * @param error - returned by the delegate
   */
  private onResumeFailure(error: any): Observable<any> {
    if (error.code != ApiCodes.AUTH_REQUIRED) {
      ResumeService.logger.error(
        `onResumeFault - Error (${JSON.stringify(error)})`,
      );
    }

    if (
      (!!error && error.message === HTTP_ERROR_MESSAGE) ||
      error.message === HTTP_NETWORK_ERROR
    ) {
      this.appMonitorService.triggerFaultError({
        faultCode: AppErrorCodes.FLTT_RESUME_ERROR,
      });
    }

    if (
      this.SERVICE_CONFIG.isFreeTierEnable &&
      (error.code == ApiCodes.AUTH_REQUIRED ||
        this.authService.isOpenAccessEligible())
    ) {
      this.authService.updateOpenAccessSession({
        openAccessPeriodState: true,
      } as IClientConfiguration);
    }

    const tuneErrorFound = tuneApiCodes.find(code => code === error.code);

    if (tuneErrorFound) {
      ResumeService.logger.error(
        `Resume Catch - Tune API Code found  (${error.code})`,
      );
      return observableOf(error.modules);
    }

    if (error.code === ApiCodes.INVALID_REQUEST) {
      const currentCode = apiToFault.get(error.code);

      this.appMonitorService.triggerFaultError({
        apiCode: error.code,
        faultCode: currentCode,
      });
    }

    // sending back false, to avoid errors where we check a property of the returned value
    return observableOf(false);
  }

  /**
   * This function will get called when the resume is complete.  It handles work that needs to be performed
   * regardless of whether the resume was successfull or not.
   *
   * @param response is the module payload of the response received by the resume call, or null if the
   *        response has no module body
   */
  private onResumeDone(response, tuneNeeded) {
    this.resumeIsComplete.next(true);

    if (
      !!response &&
      !!response.clientConfiguration &&
      response.clientConfiguration.openAccessPeriodState &&
      !this.session.activeOpenAccessSession
    ) {
      this.authService.updateOpenAccessSession(response.clientConfiguration);
    } else if (
      !!response &&
      !!response.clientConfiguration &&
      !response.clientConfiguration.openAccessPeriodState &&
      this.SERVICE_CONFIG.isFreeTierEnable
    ) {
      this.authService.updateFreeTierAccessSession(response);
      this.onOpenPeriodStateSubject.next(
        response.clientConfiguration.openAccessPeriodState,
      );
    }

    /**
     * Note, if we do not have a module type, then we have no media to resume to.  This is similar to
     * a failure code scenario where one of the codes is the tuneApiCodes.  Trigger the resume as complete
     * but don't trigger the resume media subject because there is no media to play.
     *
     * The app will need to detect ...
     *
     * 1) We don't have anything to play
     * 2) If we are already playing something we should not stop what we are playing
     * 3) If #1 is true and #2 is not, then the UI should reflect that there is nothing to play
     * 4) If #1 is true and #2 is true, since we do not trigger the resumeMedia observable, the client
     *    can continue to play what it is currently playing
     */
    if (!!response && !response.moduleType) {
      if (
        this.SERVICE_CONFIG.contextualInfo.deepLink === true &&
        !response.resumeAction
      ) {
        ResumeService.logger.error(
          'Api encountered moduletype not found, clear deep links',
        );
        this.SERVICE_CONFIG.contextualInfo.id = '';
        this.SERVICE_CONFIG.contextualInfo.type = '';
        this.SERVICE_CONFIG.contextualInfo.deepLink = false;
      }

      this.appMonitorService.triggerFaultApiError(
        ApiCodes.UNAVAILABLE_RESUME_CONTENT,
        null,
      );
    }

    const mediaData = getMediaData(response);

    if (mediaData || response.resumeAction) {
      this.resumeMediaData = normalizeResumeMediaData(
        mediaData,
        response.moduleType,
        response.updateFrequency,
        normalizeResumeAction(response, this.SERVICE_CONFIG),
        this.SERVICE_CONFIG,
      );

      if (response.wallClockRenderTime) {
        this.resumeMediaData.wallClockRenderTime = response.wallClockRenderTime;
      }

      if (
        (!this.SERVICE_CONFIG.isFreeTierEnable && tuneNeeded) ||
        (this.authService.isUserRegistered() && tuneNeeded)
      ) {
        this.resumeMediaSubject.next(this.resumeMediaData);
      }
    }

    return !!response;

    /**
     * From a resume response, get the type of media being resumed to and then get the details about what
     * that media is and return them.
     *
     * @param response is the resume response to examine
     * @returns the media data from the resume response
     */
    function getMediaData(response) {
      let responseType = !!response.moduleType
        ? response.moduleType.toLowerCase()
        : '';

      switch (responseType) {
        case ContentTypes.LIVE_AUDIO:
          responseType = ApiLayerTypes.RESPONSE_TYPE_LIVE;
          break;

        case ContentTypes.AOD:
          responseType = ApiLayerTypes.RESPONSE_TYPE_AOD;
          break;

        case ContentTypes.PODCAST:
          responseType = ApiLayerTypes.RESPONSE_TYPE_PANDORA_PODCAST;
          break;

        case ContentTypes.VOD:
          responseType = ApiLayerTypes.RESPONSE_TYPE_VOD;
          break;

        case ContentTypes.ADDITIONAL_CHANNELS:
          responseType = ApiLayerTypes.RESPONSE_TYPE_AIC;
          break;

        case ContentTypes.SEEDED_RADIO:
          responseType = ApiLayerTypes.RESPONSE_TYPE_SEEDED_RADIO;
          break;

        case ContentTypes.CATEGORY:
          responseType = ApiLayerTypes.CATEGORY;
          break;
      }

      const mediaData: any = _.get(response, responseType);

      return mediaData;
    }

    /**
     * Create a simple object that encapsulates the information about the media we are resuming to
     *
     * @param mediaData is the particulars about the media we are resuming to
     * @param mediaType is the type of media we are resuming to
     * @param updateFrequency is the update frequency for the media we are resuming to
     * @param resumeAction is an deep link action (that may be undefined if there is no deep link in the resume)
     * @param SERVICE_CONFIG is the client configuration
     */
    function normalizeResumeMediaData(
      mediaData,
      mediaType,
      updateFrequency,
      resumeAction,
      SERVICE_CONFIG: IAppConfig,
    ) {
      return {
        data: mediaData,
        type: response.moduleType,
        updateFrequency: response.updateFrequency,
        isDeepLinkResume: SERVICE_CONFIG.contextualInfo.deepLink,
        deepLinkContentType: SERVICE_CONFIG.contextualInfo.type,
        resumeAction: resumeAction,
      };
    }

    /**
     * Normalize deep link actions from the resume response into a simple object for later usage
     *
     * @param response is the API response to a resume request for which actions are to be normalized
     */
    function normalizeResumeAction(
      response: any,
      SERVICE_CONFIG: IAppConfig,
    ): INeriticLinkData {
      if (!response.resumeAction) {
        return {} as INeriticLinkData;
      }

      const actionLink =
        response.resumeAction && response.resumeAction.actionNeriticLink
          ? response.resumeAction.actionNeriticLink
          : '';
      const actionType =
        response.resumeAction && response.resumeAction.actionType
          ? response.resumeAction.actionType
          : '';
      const actionSplit = actionLink ? actionLink.split(/:(.+)/) : '';
      const urlSplit = actionSplit.length > 1 ? actionSplit[1].split('?') : [];
      const isForYouAction =
        actionLink.indexOf(neriticActionConstants.FOR_YOU) >= 0;
      const isVideoLandingAction =
        actionLink.indexOf(neriticActionConstants.ALL_VIDEOS) >= 0;
      const isEdpShowEnhancedAction =
        actionLink.indexOf(neriticActionConstants.EDP_SHOW_ENHANCED) >= 0;
      const isPodcastsAction =
        actionLink.indexOf(neriticActionConstants.ALL_PODCASTS) >= 0;

      let contentType = SERVICE_CONFIG.contextualInfo.type;
      if (isForYouAction) {
        contentType = neriticActionConstants.FOR_YOU;
      }
      if (isVideoLandingAction) {
        contentType = neriticActionConstants.ALL_VIDEOS;
      }
      if (isEdpShowEnhancedAction) {
        contentType = neriticActionConstants.EDP_SHOW_ENHANCED;
      }
      if (isPodcastsAction) {
        contentType = neriticActionConstants.ALL_PODCASTS;
      }
      return {
        functionalGroup: actionType,
        linkType: actionSplit[0],
        actionType: urlSplit.length > 0 ? urlSplit[0] : '',
        url: urlSplit.length > 0 ? urlSplit[1] : '',
        contentType: contentType,
      };
    }
  }
}
