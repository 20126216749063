import { of as observableOf, BehaviorSubject, Observable } from 'rxjs';
import { share, catchError, mergeMap } from 'rxjs/operators';
import {
  IProviderDescriptor,
  addProvider,
  Logger,
  ProfileService,
  IRemoveSeededStationResponse,
} from '../index';
import { SeededStationsDelegate } from './seeded-stations.delegate';
import { SeededStationUpdateItem } from './seeded-stations.interface';

/**
 * @MODULE:     service-lib
 * @CREATED:    05/12/19
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *      Seeded Service used to remove seeded station
 */
export class SeededStationsService {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('SeededStationsService');

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(SeededStationsService, SeededStationsService, [
      SeededStationsDelegate,
      ProfileService,
    ]);
  })();

  /**
   * Subject used to trigger the seededStationRemoved observable
   * @type {BehaviorSubject}
   */
  public seededStationRemovedSubject: BehaviorSubject<
    IRemoveSeededStationResponse
  > = new BehaviorSubject(null);

  /**
   * Observable that can used to get notified when the seeded station removed successfully
   * @type {any}
   */
  public seededStationRemoved: Observable<IRemoveSeededStationResponse> = this
    .seededStationRemovedSubject;

  /**
   * Constructor.
   * @param favoriteDelegate - The delegate that makes the get config HTTP API request.
   */
  constructor(
    private seededStationsDelegate: SeededStationsDelegate,
    private profileService: ProfileService,
  ) {}

  /**
   * This method used to removes the seeded station.
   * @param stationId
   * @param stationFactoryId
   */
  public removeSeededStation(
    stationId: string,
    stationFactoryId: string,
  ): Observable<boolean> {
    if (!stationId || !stationFactoryId) {
      SeededStationsService.logger.debug(
        'removeSeededStation()-- Failed to remove',
      );
      return observableOf(false);
    }
    const response: Observable<any> = this.seededStationsDelegate.removeSeededStation(
      this.profileService.getGupId(),
      new SeededStationUpdateItem(stationId, stationFactoryId),
    );

    return this.processSeededStationsResponse(response, stationId, false);
  }

  /**
   * This method used to removes all the seeded station.
   * @param stationId
   * @param stationFactoryId
   */
  public removeAllSeededStations(
    seededStations: SeededStationUpdateItem[],
  ): Observable<boolean> {
    const response: Observable<any> = this.seededStationsDelegate.removeSeededStations(
      this.profileService.getGupId(),
      seededStations,
    );

    return this.processSeededStationsResponse(response, '', true);
  }

  /**
   * @param response - returned from the API
   * @returns {Observable<boolean>} - return boolean value based on success or failure API call.
   */
  private processSeededStationsResponse(
    response: Observable<any>,
    stationId: string,
    removeAllStations: boolean,
  ): Observable<boolean> {
    response.subscribe(
      onSeededStationSuccess.bind(this),
      onSeededStationFault.bind(this),
    );

    //TODO - Can we clean this up, and make it more succinct.
    return response.pipe(
      mergeMap((/*response: any*/) => {
        return observableOf(true);
      }),
      catchError(() => observableOf(false)),
      share(),
    );

    /**
     * @param response - response is returned by the delegate.
     */
    function onSeededStationSuccess(/*response: any*/) {
      SeededStationsService.logger.debug('onSeededStationSuccess');
      this.seededStationRemovedSubject.next({
        stationId: stationId,
        removeAllSeededStations: removeAllStations,
      });
    }

    /**
     * Once delegate Throws exception then fault handler get called.
     * @param error - error is returned by the delegate
     */
    function onSeededStationFault(error: Error) {
      SeededStationsService.logger.error(
        `onSeededStationFault - Error (${JSON.stringify(error)})`,
      );
    }
  }

  /**
   * Used to reset the seeded remove data after carousel cache cleared.
   */
  public resetSeededStationRemovedData(): void {
    const seededStationRemoved = this.seededStationRemovedSubject.getValue();

    if (
      seededStationRemoved &&
      (seededStationRemoved.removeAllSeededStations ||
        seededStationRemoved.stationId.length > 0)
    ) {
      this.seededStationRemovedSubject.next({
        stationId: '',
        removeAllSeededStations: false,
      });
    }
  }
}
