/**
 * @MODULE:     service-lib
 * @CREATED:    07/19/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *  AuthenticationEvent are events which can be reused in application 
 */
export class AuthenticationEvent
{
    public static APP_EXIT: string  = "authentication.logout.passive";
    public static AUTO_LOGIN : string = "authentication.login.auto";
    public static CHECK_AUTHENTICATION : string = "authentication.login.check";
    public static CONFIGURATION_RETURNED : string =  "authentication.configurationReturned";
    public static DUPLICATE_LOGIN : string =  "authentication.duplicate.passive";
    public static ERROR_ACCOUNT_LOCKED : string =  "authentication.error.accountLocked";
    public static FIRTSTIME_FB_ACCESS : string = "authentication.firsttime.fbaccess.analytics";
    public static FORGOT_PASSWORD_LINK : string = "authentication.forgotPassword.analytics";
    public static GET_STARTED_LINK : string = "authentication.getStartedLink.analytics";
    public static GET_STARTED_SCREEN : string = "authentication.getStartedScreen.analytics";
    public static HTTPS : string =  "authentication.https.passive";
    public static INVOKE_RESUME : string = "authentication.invokeResume";
    public static IT_DOWN : string = "authentication.itDown";
    public static IT_RECLAIM : string = "authentication.itReclaim";
    public static LOGIN_FAILURE : string = "authentication.login.firstthreefailure";
    public static LOGIN_FAILURE_AFTER_FIRST_THREE : string = "authentication.login.failure";
    public static LOGIN_INITIATED : string =  "authentication.login.initiated";
    public static LOGIN_SCREEN : string = "authentication.login.screenLoaded.analytics";
    public static LOGIN_SUCCESS : string = "authentication.login.success.analytics";
    public static LOGOUT_FAILURE : string =  "authentication.logout.failure";
    public static LOGOUT_INITIATED : string =  "authentication.logout.initiated";
    public static LOGOUT_SUCCESS : string = "authentication.logout.success";
    public static NEVER_AUTHENTICATED : string =  "authentication.login.never";
    public static NOT_AUTHENTICATED : string = "authentication.login.invalid";
    public static OAC_PASSWORD : string = "authentication.oacPassword";
    public static RECLAIM_SESSION : string = "authentication.reclaim";
    public static SCREEN_LOGIN : string =  "screen_login";
    public static TRIAL_LOGIN : string = "authentication.login.trial";
    public static CONTENT_NOT_IN_PERSONAL_SUBSCRIPTION_PACKAGE : string = "authentication.outsidePersonalSubscriptionPackage";
    public static CONTENT_NOT_IN_BUSINESS_SUBSCRIPTION_PACKAGE : string = "authentication.outsideBusinessSubscriptionPackage";
}
