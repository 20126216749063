export class ApiLayerTypes
{
    public static CLIENT_DEVICE_ID                             = "clientDeviceId";
    public static OPEN_ACCESS_TRIAL                            = "OAtrial";
    public static DEEP_LINK_ID                                 = "deepLinkId";
    public static AOD_SHOW                                     = "aod show";
    public static SOFT_SEGMENT                                 = "SOFT";
    public static HARD_SEGMENT                                 = "HARD";
    public static CUT_LAYER                                    = "cut";
    public static SEGMENT_LAYER                                = "segment";
    public static LIVE_VIDEO_LAYER                             = "liveVideo";
    public static LIVE_CUE_POINT                               = "livepoint";
    public static FUTURE_EPISODE_LAYER                         = "future-aodEpisode";
    public static RESPONSE_TYPE_PANDORA_PODCAST                = "pandoraPodcastData";
    public static RESPONSE_TYPE_AOD                            = "aodData";
    public static RESPONSE_TYPE_VOD                            = "vodData";
    public static RESPONSE_TYPE_LIVE                           = "liveChannelData";
    public static RESPONSE_TYPE_AIC                            = "additionalChannelData";
    public static RESPONSE_TYPE_SEEDED_RADIO                   = "seededRadioData";
    public static AOD_ASSET_TYPE                               = "aodEpisode";
    public static VOD_ASSET_TYPE                               = "vodEpisode";
    public static CHANNEL_ASSET_TYPE                           = "channel";
    public static CUT_DURATION_TO_FILTER_INTERSTIRIALS_SECONDS = 90;
    public static CUT_CONTENT_TYPE_SONG                        = "song";
    public static CUT_CONTENT_TYPE_EXT                         = "ext";
    public static CUT_CONTENT_TYPE_TALK                        = "talk";
    public static CUT_CONTENT_TYPE_PROMO                       = "promo";
    public static CUT_CONTENT_TYPE_EXP                         = "exp";
    public static TRACK_CONTENT_TYPE_MUSIC                     = "music";
    public static CONDITIONAL_ACCESS_ID                        = "caId";
    public static CONTENT_TYPE                                 = "contentType";
    public static CHANNEL_ID                                   = "channelId";
    public static TIMESTAMP                                    = "timestamp";
    public static SHOW_LAYER                                   = "show";
    public static EPISODE_LAYER                                = "episode";
    public static PRIMARY_AUDIO_ENDPOINT                       = "primary";
    public static SECONDARY_AUDIO_ENDPOINT                     = "secondary";
    public static VIDEO_LAYER                                  = "video";
    public static CUT_FULL_PLAYBACK_DIFFERENCE                 = 2000;
    public static CUT_CONTENT_TYPE_LINK                        = "Link";
    public static AIC_IMAGE_URL                                = "%AIC_Image%";
    public static AIC_IMAGE                                    = "AIC_Image";
    public static REGION_US                                    = "US";
    public static REGION_CA                                    = "CA";
    public static GUP_ID                                       = "gupID";
    public static NO_CONTENT                                   = "NoContent";
    public static CATEGORY                                     = "category";
    public static PODCAST                                      = "podcast";
}
