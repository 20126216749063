import { Logger } from "../logger";
import { addProvider, IProviderDescriptor } from "../service";
import { BehaviorSubject, Observable } from "rxjs";

export class TuneModel
{
    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("TuneModel");

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(TuneModel,
            TuneModel,
            []);
    }();

    private model: ITuneModel = {
        tuneState: TuneState.IDLE,
        tuneStatus: TuneStatus.SUCCESS,
        currentMetaData: null
    };

    private tuneModelSubject: BehaviorSubject<ITuneModel> = new BehaviorSubject(this.model);

    public tuneModel$: Observable<ITuneModel> = this.tuneModelSubject;

    public set tuneState(tuneState: TuneState)
    {
        if (this.model && this.model.tuneState === tuneState) { return; }

        this.model.tuneState = tuneState;

        this.tuneModelSubject.next(this.model);
    }

    public get tuneState()
    {
        return this.model.tuneState;
    }

    public set tuneStatus(tuneStatus: TuneStatus)
    {
        if (this.model && this.model.tuneStatus === tuneStatus) { return; }

        this.model.tuneStatus = tuneStatus;

        this.tuneModelSubject.next(this.model);
    }

    public get tuneStatus()
    {
        return this.model.tuneStatus;
    }

    public set currentMetaData(currentMetaData: ICurrentMetaData)
    {
        if(currentMetaData &&
           this.model.currentMetaData &&
           this.model.currentMetaData.mediaId === currentMetaData.mediaId) { return; }

        this.model.currentMetaData = currentMetaData;

        this.tuneModelSubject.next(this.model);
    }

    public get currentMetaData ()
    {
        return this.model.currentMetaData;
    }

    public set tuneModelData(tuneModel: ITuneModel)
    {
        this.model = tuneModel;

        this.tuneModelSubject.next(this.model);
    }

    public get tuneModelData (): ITuneModel
    {
        return this.model;
    }

    /**
     * Constructor
     */
    constructor ()
    {
        TuneModel.logger.debug(`constructor()`);
    }
}

export interface ITuneModel
{
    tuneStatus?: TuneStatus;
    tuneState?: TuneState;
    currentMetaData?: ICurrentMetaData;
}

export interface ICurrentMetaData
{
    mediaType: string;
    mediaId: string; // station id
    channelId: string; // station Factory
    streamingAdsId?: string;
}

export enum TuneStatus
{
    SUCCESS = "SUCCESS",
    FAILURE = "FAILURE"
}

export enum TuneState
{
    TUNING = "TUNING",
    IDLE = "IDLE"
}

