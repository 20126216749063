import { createSelector } from 'reselect';
import { IChannel, ISubCategory, ISuperCategory } from '../../servicelib';
import { displayViewType } from '../../types/display.view.type';

export interface ISelectedCategory {
  category: ISubCategory;
  viewType: displayViewType;
}

export interface IChannelListStore {
  superCategories: Array<ISuperCategory>;
  selectedSuperCategory: ISuperCategory;
  categories: Array<ISubCategory>;
  selectedCategory: ISelectedCategory;
  channels: Array<IChannel>;
  selectedChannel: IChannel;
  liveChannels: Array<IChannel>;
  nextChannel: IChannel;
  prevChannel: IChannel;
  filtered: Array<IChannel>;
  filter: string;
  sort: string;
}

/*export const selectChannelListState = createFeatureSelector<IChannelListStore>(
  'channelStore',
);*/

export const selectChannelListState = createSelector(
  state => (state as any).channelStore,
  channelStore => channelStore,
);

export const getSuperCategories = createSelector(
  selectChannelListState,
  (state: IChannelListStore): Array<ISuperCategory> => state.superCategories,
);

export const getSelectedSuperCategory = createSelector(
  selectChannelListState,
  (state: IChannelListStore): ISuperCategory => state.selectedSuperCategory,
);

export const getCategories = createSelector(
  selectChannelListState,
  (state: IChannelListStore): Array<ISubCategory> => state.categories,
);

export const getSelectedCategory = createSelector(
  selectChannelListState,
  (state: IChannelListStore): ISelectedCategory => state.selectedCategory,
);

export const getChannels = createSelector(
  selectChannelListState,
  (state: IChannelListStore): Array<IChannel> => state.channels,
);

export const getSelectedChannel = createSelector(
  selectChannelListState,
  (state: IChannelListStore): IChannel => state.selectedChannel,
);

export const getLiveChannels = createSelector(
  selectChannelListState,
  (state: IChannelListStore): Array<IChannel> => state.liveChannels,
);

export const getPreviousChannel = createSelector(
  selectChannelListState,
  (state: IChannelListStore): IChannel => state.prevChannel,
);

export const getNextChannel = createSelector(
  selectChannelListState,
  (state: IChannelListStore): IChannel => state.nextChannel,
);

export const getFilteredChannels = createSelector(
  selectChannelListState,
  (state: IChannelListStore): Array<IChannel> => state.filtered,
);
