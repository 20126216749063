import {
    combineLatest as observableCombineLatest,
    Observable,
    BehaviorSubject,
    SubscriptionLike as ISubscription
} from 'rxjs';
import { take, filter, first } from 'rxjs/operators';
import { VideoPlayerConstants }       from "../mediaplayer/videoplayer/video-player.consts";
import {
    ICarouselSelector,
    ICarouselSelectorSegment,
    IGroupedCarousel,
    ICarouselData,
    ICarouselDataByType, IZoneDataByType
}                                   from "./carousel.interface";
import {
    INeriticLinkData,
    ITile,
    ITileReminders
}                                   from "./raw.tile.interface";
import { IPausePointData }          from "../profile/profile.interface";
import { ICutMarker, IVideoMarker } from "../service/types";
import { IFavoriteItem }            from "../favorite/favorite.interface";
import { IAlert }               from "../alerts/alert.interface";
import { ApiLayerTypes }        from "../service/consts";
import { MediaTimeLine, TunePayload } from "../tune";
import { Logger }               from "../logger";
import { CarouselTypeConst }    from "./carousel.const";
import {
    ChannelLineupService,
    IChannel,
    ISubCategory,
    ISuperCategory
} from "../channellineup";
import { ProfileService }       from "../profile/profile.service";
import { FavoriteService }      from "../favorite/favorite.service";
import { LiveTimeService }      from "../livetime";
import { ContentTypes }         from "../service/types";
import { AlertService }         from "../alerts/alert.service";
import { AlertType }            from "../alerts";
import { MediaTimeLineService } from "../media-timeline/media.timeline.service";
import { CurrentlyPlayingService } from "../currently-playing/currently.playing.service";
import { IRemoveSeededStationResponse,
         SeededStationsService } from "../seeded-stations";
import { FavoriteModel } from "../favorite/favorite.model";

export class CarouselCacheEntry
{
    /**
     * Logger.
     */
    private static logger: Logger = Logger.getLogger("CarouselCacheEntry");

    /**
     * The subject that is used to trigger updates to the cached carousel
     *
     */
    private carouselsSubject: BehaviorSubject<ICarouselDataByType>;

    /**
     * The raw carousel in its current state
     */
    private rawCarousel: ICarouselDataByType;

    /**
     * The carousel observable that can be used to observe the cached carousel and any changes that are made to
     * the carousel
     */
    public carousels: Observable<ICarouselDataByType>;

    /**
     * Indicates that we have recieved the response for the carousel
     */
    public responseRecieved = false;

    private tileUpdateSubscription: ISubscription;

    private responseSubscription: ISubscription;

    constructor(private channelLineupService : ChannelLineupService,
                private profileService: ProfileService,
                private favoriteModel: FavoriteModel,
                private liveTimeService: LiveTimeService,
                private mediaTimeLineService: MediaTimeLineService,
                private currentlyPlayingService: CurrentlyPlayingService,
                private alertService: AlertService,
                private seededStationsService: SeededStationsService,
                public expiryTime: number,
                private cacheKey: string,
                response: Observable<ICarouselDataByType>)
    {
        this.carouselsSubject = new BehaviorSubject(null);
        this.carousels        = this.carouselsSubject.pipe(filter((responseData: ICarouselDataByType) => !!responseData));

        this.responseSubscription = response.subscribe(this.handleResponse.bind(this), this.handleError.bind(this));
    }

    /**
     * Call next on the carouselsSubject
     * @param {value} value is value to be pushed
     */
    public pushValue(value)
    {
        this.carouselsSubject.next(value);
    }

    /**
     * Tears down the CarouselCacheEntry
     */
    public tearDown()
    {
        this.carouselsSubject.complete();
        if (this.tileUpdateSubscription) { this.tileUpdateSubscription.unsubscribe(); }
        this.responseSubscription.unsubscribe();
    }

    /**
     * Take the raw carousel response and trigger the carousel subject.
     *
     * 1) observe the channel lineup and trigger the carousel subject whenever a live tile within the carousels
     *    is updated
     * 2) observe the profile service pause points and trigger the carousel subject whenever the percent consumed
     *    data for an episode tile is updated
     * 3) get the on demand information for all the tiles within the carousel, and add show and episode data to tiles
     *    that pertain to on demand shows or episodes
     *
     * @param {ICarouselDataByType} response is the API response for the carousel
     */
    private handleResponse(response: ICarouselDataByType)
    {
        this.responseRecieved = true;

        response = this.addChannelInfoToCarousels(response);
        response = this.addCategoryInfoToCarousels(response);
        response = this.addSubCategoryListToZone(response);

        this.pushValue(response);
        this.rawCarousel = response;

        // We will only update the expiry time on the carousel if it is *not* empty.  This means we need
        // to have a hero carousel or at least one content carousel
        if (response.zone && response.zone.length > 0
            || response.selectors && response.selectors.length > 0)
        {
            this.expiryTime = response.expirationTime;
        }

        this.tileUpdateSubscription = observableCombineLatest(
            this.channelLineupService.channelLineup.channels,
            this.liveTimeService.liveTime$
        )
        .subscribe((combined) =>
        {
            this.updateChannelTiles(combined);
        });

        this.profileService.pausePoints.subscribe(this.updatePercentConsumed.bind(this));
        this.favoriteModel.favorites$.subscribe(this.updateFavorites.bind(this));
        this.alertService.alerts.subscribe(this.updateShowReminder.bind(this));

        this.mediaTimeLineService.mediaTimeLine.pipe(
            take(1))
            .subscribe((mediaTimeLine) =>
        {
            if ( !mediaTimeLine || ( this.cacheKey !== CarouselTypeConst.RECENTS &&
                !(this.cacheKey === CarouselTypeConst.NOW_PLAYING_VOD_UP_NEXT
                || this.cacheKey === CarouselTypeConst.NOW_PLAYING_AOD_UP_NEXT ) )) return;
            if (this.rawCarousel)
            {
                CarouselCacheEntry.removeCurrentlyPlayedFromCarousel(this.rawCarousel, mediaTimeLine);
                this.pushValue(this.rawCarousel);
            }
        });
        this.currentlyPlayingService.currentlyPlayingData
            .subscribe(
            (currentlyPlayingMedia) =>
            {
                this.updateTunedToTile();
            });

        this.seededStationsService.seededStationRemoved.pipe(
            filter(data =>
            {
                return this.cacheKey === CarouselTypeConst.SEEDED_STATIONS &&
                    (!!data && (data.removeAllSeededStations || !!data.stationId));
            }))
            .subscribe((removedSeededStation) =>
            {
                CarouselCacheEntry.removeSeededStationFromCarousel(this.rawCarousel, removedSeededStation);
                this.pushValue(this.rawCarousel);
                this.seededStationsService.resetSeededStationRemovedData();
            });
    }

    /**
     * Takes the error response and fills the error flag and triggers the carousel subject
     * @param {ICarouselDataByType} response
     */
    private handleError(error: any)
    {
        CarouselCacheEntry.logger.error(`carouse response fault - Error (${JSON.stringify(error)})`);
        const errorResponse = {
            error: true,
            content : [],
            hero: {},
            selectors:[],
            zone:[]
        };

        this.pushValue(errorResponse);
        this.rawCarousel = errorResponse;
    }

    /**
     * Checks the Tuned tile to update the Now Playing Indicator
     */
    private updateTunedToTile()
    {
        if (this.rawCarousel)
        {
            let modified = false;

            modified = this.rawCarousel.zone.reduce(tunedToZoneReducer.bind(this), modified);
            modified = this.rawCarousel.selectors.reduce(tunedToSelectorReducer.bind(this), modified);

            if (modified)
            {
                this.pushValue(this.rawCarousel);
            }
        }

        /**
         * Reduce an selector to true/false value based ont he the selector hero and content in the selector
         * @param modified
         * @param zone
         */
        function tunedToZoneReducer(modified: boolean, zone: IZoneDataByType): boolean
        {
            modified = zone.hero.reduce(tunedToCarouselReducer.bind(this), modified);
            modified = zone.content.reduce(tunedToCarouselReducer.bind(this), modified);
            return modified;
        }

        /**
         * Reduce an selector to a true/false value based on the selector segments in the selector
         *
         * @param {boolean} modified is true or false based on whether we have discovered any modified tiles
         *        already
         * @param {ICarouselSelector} selector is the selector to reduce to a true/false value
         *
         * @returns {boolean} either true or false, based on whether modified was already true or we have
         *          found a modified tile within the carousel selector
         */
        function tunedToSelectorReducer(modified: boolean, selector: ICarouselSelector) : boolean
        {
            return selector.segments.reduce(tunedToCarouselSelectorSegmentReducer.bind(this), modified);
        }

        /**
         * Reduce an segment selector based on the carousel selector
         * @param modified
         * @param segment
         */
        function tunedToCarouselSelectorSegmentReducer(modified: boolean,
                                                       segment: ICarouselSelectorSegment) : boolean
        {
            return segment.carousels.reduce(tunedToCarouselReducer.bind(this), modified);
        }

        /**
         * Reduce an Carousel list based on the tiles selector
         * @param modified
         * @param carousel
         */
        function tunedToCarouselReducer(modified: boolean, carousel: ICarouselData) : boolean
        {
            return carousel.tiles.reduce(tunedToTileReducer.bind(this), modified);
        }

        /**
         *  Take a Tile data from Tiles array and pass the TunePayLoad to isTunedTo to get the tuned status.
         *  Set the true/false of tile.isTunedTo
         *  isTunedTo will decide only channel/Tile tuned status.
         */
        function tunedToTileReducer(modified: boolean, tile: ITile) : boolean
        {
            if(this.currentlyPlayingService.isTileTuned(tile))
            {
                modified = true;
                tile.isTunedTo$.next(true);
            }
            else
            {
                tile.isTunedTo$.next(false);
            }

            return modified;
        }

    }

    /**
     * Check hero and content carousel tiles and see if what is playing live on a given channel has changed since
     * the last channel lineup update
     */
    private updateChannelTiles(combined: Array<any>)
    {
        let liveTime = combined[ 1 ];
        if (this.rawCarousel)
        {
            let modified = false;

            modified = this.rawCarousel.zone.reduce(liveUpdateZoneReducer, modified);
            modified = this.rawCarousel.selectors.reduce(liveUpdateSelectorReducer, modified);

            if (modified)
            {
                this.pushValue(this.rawCarousel);
            }
        }

        /**
         * Reduce an selector to a true/false value based on the selector segments in the selector
         *
         * @param {boolean} modified is true or false based on whether we have discovered any modified tiles
         *        already
         * @param {ICarouselSelector} selector is the selector to reduce to a true/false value
         *
         * @returns {boolean} either true or false, based on whether modified was already true or we have
         *          found a modified tile within the carousel selector
         */
        function liveUpdateSelectorReducer(modified : boolean, selector : ICarouselSelector) : boolean
        {
            return selector.segments.reduce(liveUpdateCarouselSelectorSegmentReducer, modified);
        }

        /**
         * Reduce an selector segment to a true/false value based on the selector segments in the selector
         *
         * @param {boolean} modified is true or false based on whether we have discovered any modified tiles
         *        already
         * @param {ICarouselSelectorSegment} segment is the segment to reduce to a true/false value
         *
         * @returns {boolean} either true or false, based on whether modified was already true or we have
         *          found a modified tile within the carousel selector segment
         */
        function liveUpdateCarouselSelectorSegmentReducer(modified : boolean,
                                                          segment : ICarouselSelectorSegment) : boolean
        {
            return segment.carousels.reduce(liveUpdateCarouselReducer, modified);
        }

        function liveUpdateZoneReducer(modified : boolean, zone : IZoneDataByType) : boolean
        {

            modified = zone.hero.reduce(liveUpdateCarouselReducer, modified);
            modified = zone.content.reduce(liveUpdateCarouselReducer, modified);
            return modified;
        }

        /**
         * Reduce a carousel to a true/false value based on the tiles in the carousel
         *
         * @param {boolean} modified is true or false based on whether we have discovered any modified tiles
         *        already
         * @param { ICarouselData} carousel to reduce to a true/false value
         *
         * @returns {boolean} either true or false, based on whether modified was already true or we have
         *          found a modified tile within the carousel
         */
        function liveUpdateCarouselReducer(modified : boolean, carousel : ICarouselData) : boolean
        {
            return carousel.tiles.reduce(liveUpdateTileReducer, modified);
        }

        /**
         * Reduce a tile to a true/false value based on the whether the live channel information has been changed
         * from the last time the tile was examined by this function
         *
         * We also look up the LiveVideo markers to make sure if the video status has been chnaged from ON <-> OFF
         * @param {boolean} modified is true or false based on whether we have discovered any modified tiles
         *        already
         * @param {ITile} tile to reduce to a true/false value
         *
         * @returns {boolean} either true or false, based on whether modified was already true or we have
         *          found that the playingArtist and/or the playingTitle has been modified since the last time
         *          this function examined the tile.
         */
        function liveUpdateTileReducer(modified : boolean, tile : ITile) : boolean
        {

            if (tile.isEligibleForLiveUpdates !== true
                || !tile.channelInfo
                || !tile.channelInfo.markerLists)
            {
                return modified;
            }

            const cutMarkerList = tile.channelInfo.markerLists.find((list) => list.layer === ApiLayerTypes.CUT_LAYER);
            const videoMarkerList = tile.channelInfo.markerLists.find((list) => list.layer === ApiLayerTypes.LIVE_VIDEO_LAYER);

            if ((!cutMarkerList || !cutMarkerList.markers) && (!videoMarkerList || !videoMarkerList.markers))
            { return modified; }

            const markers = cutMarkerList.markers as Array<ICutMarker>;
            const marker  = markers.reduceRight((prev, current) =>
                                                {
                                                    return (prev.time > liveTime.zuluMilliseconds) ? current : prev;
                                                });

            let videoMarker:IVideoMarker;
            if(videoMarkerList && videoMarkerList.markers)
            {
                const videoMarkers = (videoMarkerList.markers || []) as Array<IVideoMarker>;
                videoMarker  = videoMarkers.reduceRight((prev, current) =>
                {
                    return (prev.time > liveTime.zuluMilliseconds) ? current : prev;
                });
            }

            if ((!marker || !marker.cut || !marker.cut.artists) && (!videoMarker || !videoMarker.video))
            { return modified; }

            const artist = marker.cut.artists.find((artist : any) => artist.name) || { name : "" };
            const title  = marker.cut.title || "";
            const cutAssetGuid = marker.assetGUID || "";

            if (artist.name !== tile.nowArtist
                || title !== tile.nowTitle)
            {
                tile.line1 = setTileTextByFieldType(tile.line1FieldType,artist.name,title,tile.line1);
                tile.line2 = setTileTextByFieldType(tile.line2FieldType,artist.name,title,tile.line2);
                tile.line3 = setTileTextByFieldType(tile.line3FieldType,artist.name,title,tile.line3);

                (tile.line1$ as BehaviorSubject<string>).next(tile.line1);
                (tile.line2$ as BehaviorSubject<string>).next(tile.line2);
                (tile.line3$ as BehaviorSubject<string>).next(tile.line3);

                tile.nowArtist = artist.name || "";
                tile.nowTitle  = title;
                tile.cutAssetGuid = cutAssetGuid;
                modified       = true;
            }

            if (videoMarker && videoMarker.video && tile.tileBanner.bannerClass === CarouselTypeConst.ON_WATCH_LIVE_BANNER_CLASS)
            {
                if ((videoMarker.video.liveVideoStatus === VideoPlayerConstants.LIVE_VIDEO_ON.toLowerCase() && !tile.tileBanner.display)
                    || (videoMarker.video.liveVideoStatus === VideoPlayerConstants.LIVE_VIDEO_OFF.toLowerCase() && tile.tileBanner.display))
                {
                    tile.tileBanner.display = videoMarker.video.liveVideoStatus === VideoPlayerConstants.LIVE_VIDEO_ON.toLowerCase() ? true : false;
                    modified                = true;
                }
            }
            return modified;
        }

        function setTileTextByFieldType(fieldType : string, artist : string, title : string, original : string)
        {
            switch (fieldType)
            {
                case CarouselTypeConst.ARTIST_TEXT:
                    return artist;
                case  CarouselTypeConst.TITLE_TEXT:
                    return title;
                case CarouselTypeConst.ARTIST_TITLE_TEXT:
                    return artist + " - " + title;
                default:
                    return original;
            }
        }
    }

    /**
     * Check hero and content carousel tiles if tile has percent consumed changed
     */
    private updatePercentConsumed(pausePoints : Array<IPausePointData> )
    {
        if (this.rawCarousel)
        {
            let modified = this.rawCarousel.zone.reduce(percentConsumedZoneReducer, false);
            modified     = this.rawCarousel.selectors.reduce(percentConsumedSelectorReducer, modified);

            if (modified)
            {
                this.pushValue(this.rawCarousel);
            }
        }

        /**
         * Reduce an selector to a true/false value based on the selector segments in the selector
         *
         * @param {boolean} modified is true or false based on whether we have discovered any modified tiles
         *        already
         * @param {ICarouselSelector} selector is the selector to reduce to a true/false value
         *
         * @returns {boolean} either true or false, based on whether modified was already true or we have
         *          found a modified tile within the carousel selector
         */
        function percentConsumedSelectorReducer(modified: boolean, selector: ICarouselSelector): boolean
        {
            return selector.segments.reduce(percentConsumedCarouselSelectorSegmentReducer, modified);
        }

        /**
         * Reduce an selector segment to a true/false value based on the selector segments in the selector
         *
         * @param {boolean} modified is true or false based on whether we have discovered any modified tiles
         *        already
         * @param {ICarouselSelectorSegment} segment is the segment to reduce to a true/false value
         *
         * @returns {boolean} either true or false, based on whether modified was already true or we have
         *          found a modified tile within the carousel selector segment
         */
        function percentConsumedCarouselSelectorSegmentReducer(modified: boolean,
                                                               segment: ICarouselSelectorSegment): boolean
        {
            return segment.carousels.reduce(percentConsumedCarouselReducer, modified);
        }

        /**
         * Reduce a carousel to a true/false value based on the tiles in the carousel
         *
         * @param {boolean} modified is true or false based on whether we have discovered any modified tiles
         *        already
         * @param { IZoneDataByType} zone to reduce to a true/false value
         *
         * @returns {boolean} either true or false, based on whether modified was already true or we have
         *          found a modified tile within the carousel
         */
        function percentConsumedZoneReducer(modified: boolean, zone: IZoneDataByType): boolean
        {
            modified = zone.hero.reduce(percentConsumedCarouselReducer, modified);
            return zone.content.reduce(percentConsumedCarouselReducer, modified);
        }

        /**
         * Reduce a carousel to a true/false value based on the tiles in the carousel
         *
         * @param {boolean} modified is true or false based on whether we have discovered any modified tiles
         *        already
         * @param { ICarouselData} carousel to reduce to a true/false value
         *
         * @returns {boolean} either true or false, based on whether modified was already true or we have
         *          found a modified tile within the carousel
         */
        function percentConsumedCarouselReducer(modified: boolean, carousel: ICarouselData): boolean
        {
            return carousel.tiles.reduce(percentConsumedTileReducer, modified);
        }

        /**
         * Reduce a tile to a true/false value based on the whether the live channel information has been changed
         * from the last time the tile was examined by this function
         *
         * @param {boolean} modified is true or false based on whether we have discovered any modified tiles
         *        already
         * @param {ITile} tile to reduce to a true/false value
         *
         * @returns {boolean} either true or false, based on whether modified was already true or we have
         *          found that the percent consumed has been changed since the last time the tile was examined
         *          by this function
         */
        function percentConsumedTileReducer(modified: boolean, tile: ITile): boolean
        {
            const pausePoint = findPausePointForTile(tile, pausePoints);
            if (pausePoint && tile.percentConsumed !== pausePoint.percentConsumed)
            {
                tile.percentConsumed = pausePoint.percentConsumed;
                modified             = true;
            }
            else if (!tile.percentConsumed)
            {
                tile.percentConsumed = 0;
                modified             = true;
            }

            return modified;
        }

        /**
         * Returns the pause point if exists in the pausepoint list for given tile
         * @param {ITile} tile
         * @param {IPausePointData[]} pausePoints
         * @returns {IPausePointData}
         */
        function findPausePointForTile(tile: ITile, pausePoints: IPausePointData[]): IPausePointData
        {
            let episodeGuid: string = "";
            let pausePoint          = null;

            if (tile.tileContentType !== CarouselTypeConst.EPISODE_TILE)
            {
                return null;
            }

            switch (tile.tileContentSubType)
            {
                case CarouselTypeConst.AOD_EPISODE_TILE:
                {
                    episodeGuid = tile.tileAssetInfo.isPandoraPodcast ? tile.tileAssetInfo.episodeGuid
                                                                      : tile.tileAssetInfo.aodEpisodecaId;
                    break;
                }
                case CarouselTypeConst.PODCAST_EPISODE_TILE:
                {
                    episodeGuid = tile.tileAssetInfo.episodeGuid;
                    break;
                }
                case CarouselTypeConst.VOD_EPISODE_TILE:
                {
                    episodeGuid = tile.tileAssetInfo.vodEpisodeGuid;
                    break;
                }
            }

            if (episodeGuid)
            {
                pausePoint = pausePoints.find(pausePointItem =>
                    (pausePointItem.channelGuid === tile.tileAssetInfo.channelId || tile.tileAssetInfo.isPandoraPodcast)
                    && pausePointItem.assetGuid === episodeGuid);
            }

            return pausePoint ? pausePoint : null;
        }
    }

    /**
     * Used to set channelInfo on the tiles within the carousels
     *
     * @param {Observable<ICarouselDataByType>} carousels
     */
    private addChannelInfoToCarousels(carouselData: ICarouselDataByType): ICarouselDataByType
    {

        carouselData.zone.forEach(zone=>
        {
            zone.hero.forEach((carousel: ICarouselData) =>
            {
                this.addChannelInfoToTiles(carousel.tiles);
                carousel.tiles = CarouselCacheEntry.removeTilesWithNoChannelInfo(carousel.tiles);
            });

            zone.content.forEach((carousel: ICarouselData) =>
            {
                this.addChannelInfoToTiles(carousel.tiles);
                carousel.tiles = CarouselCacheEntry.removeTilesWithNoChannelInfo(carousel.tiles);
            });
            CarouselCacheEntry.removeCarouselsWithNoTiles(zone);
        });

        carouselData.selectors.forEach((selector : ICarouselSelector) =>
            selector.segments.forEach((segment : ICarouselSelectorSegment) =>
                segment.carousels.forEach((carousel: ICarouselData) =>
                {
                    this.addChannelInfoToTiles(carousel.tiles);
                    carousel.tiles = CarouselCacheEntry.removeTilesWithNoChannelInfo(carousel.tiles);
                })));

        return carouselData;
    }

    /**
     * Used to set channelInfo on arrays of tiles
     *
     * @param tiles is the tile array to add the channel info for each tile in the array
     */
    private addChannelInfoToTiles(tiles: Array<ITile> = [])
    {
        tiles.forEach((tile: ITile) =>
        {
            if (tile.tileContentType === CarouselTypeConst.CATEGORY_TILE) { return; }

            const channelId   = tile.tileAssetInfo.channelId;
            const channelInfo = channelId ? this.channelLineupService.findChannelById(channelId, false) : null;

            if (channelInfo) { tile.channelInfo = channelInfo; }
            else if(tile.tileContentSubType === ContentTypes.SEEDED_RADIO)
            {
                tile.channelInfo = { name: tile.tileAssetInfo.channelName } as IChannel;
            }
            else  { tile.channelInfo = null; }
        });
    }

    /**
     * Take an array of tiles, and return a new array that contains only tiles from the fiest array that have
     * a defined and non null channelInfo property
     *
     * @param {Array} tiles is the input tiles
     * @returns array of tiles from the input array with tiles that have no channelInfo property removed
     */
    private static removeTilesWithNoChannelInfo(tiles: Array<ITile> = []): Array<ITile>
    {
        return tiles.reduce((tiles: Array<ITile>, tile: ITile) =>
        {
            if (tile.channelInfo
                || tile.tileContentType === CarouselTypeConst.CATEGORY_TILE
                || tile.tileContentType === CarouselTypeConst.SUPER_CATEGORY_TILE
                || tile.tileContentSubType === CarouselTypeConst.ADDITIONAL_CHANNEL
                || tile.tileContentSubType === ContentTypes.SEEDED_RADIO
                || tile.tileContentType === CarouselTypeConst.VIEW_ALL_TILE
                || tile.tileContentType === CarouselTypeConst.PAGE_TILE
                || tile.tileContentType === CarouselTypeConst.COLLECTION
                || (tile && tile.tileAssetInfo && tile.tileAssetInfo.isPandoraPodcast))
            { tiles.push(tile); }
            else if (tile.tileContentType !== CarouselTypeConst.CATEGORY_TILE)
            {
                CarouselCacheEntry.logger.error(`Found an orphan channel tile ${tile.tileAssetInfo.channelId}`);
            }
            return tiles;
        }, []);
    }

    /**
     * Used to set supercategory & sub category info on the tiles within the carousels
     *
     * @param {Observable<ICarouselDataByType>} carousels
     */
    private addSubCategoryListToZone(carousels: ICarouselDataByType): ICarouselDataByType
    {
        carousels.zone.forEach(zone=>
        {
            if(zone.zoneId.indexOf('supercategory_') >-1)
            {
                this.channelLineupService.channelLineup.superCategories.pipe(
                    first())
                    .subscribe((superCategories:ISuperCategory[])=>
                {
                    superCategories.forEach(superCategory=>
                    {
                        if(superCategory.key.indexOf(zone.zoneId.split('supercategory_')[1]) > -1)
                        {
                            zone.subCategories = superCategory.categoryList;
                        }
                    });
                });
            }
        });

        return carousels;
    }
    /**
     * Used to set supercategory & sub category info on the tiles within the carousels
     *
     * @param {Observable<ICarouselDataByType>} carousels
     */
    private addCategoryInfoToCarousels(carousels: ICarouselDataByType): ICarouselDataByType
    {
        carousels.zone.forEach(zone=>
        {
            zone.hero.forEach((carousel: ICarouselData) =>
            {
                this.addCategoryInfoToTiles(carousel.tiles);
                carousel.tiles = CarouselCacheEntry.removeTilesWithNoCategoryInfo(carousel.tiles);
            });

            zone.content.forEach((carousel: ICarouselData) =>
            {
                this.addCategoryInfoToTiles(carousel.tiles);
                carousel.tiles = CarouselCacheEntry.removeTilesWithNoCategoryInfo(carousel.tiles);
            });
        });

        carousels.selectors.forEach((selector : ICarouselSelector) =>
            selector.segments.forEach((segment : ICarouselSelectorSegment) =>
                segment.carousels.forEach((carousel: ICarouselData) =>
                {
                    this.addCategoryInfoToTiles(carousel.tiles);
                    carousel.tiles = CarouselCacheEntry.removeTilesWithNoCategoryInfo(carousel.tiles);
                })));

        // ADD Category info to the categoy tiles
        if(carousels.category && carousels.category.tiles)
        {
            this.addCategoryInfoToTiles(carousels.category.tiles);
            //carousels.category.tiles = CarouselCacheEntry.removeTilesWithNoCategoryInfo(carousels.category.tiles);
        }
        return carousels;
    }

    /**
     * Used to set super category & sub Category info on arrays of tiles
     *
     * @param tiles is the tile array to add the category info for each tile in the array
     */
    private addCategoryInfoToTiles(tiles: Array<ITile>)
    {
        tiles.forEach((tile: ITile) =>
        {
            if (tile.tileContentType === CarouselTypeConst.SUPER_CATEGORY_TILE)
            {
                tile.superCategoryInfo =
                    {
                        key: tile.primaryNeriticLink ? tile.primaryNeriticLink.assetGuid.split('_')[1] : null
                    } as ISuperCategory;
                tile.subCategoryInfo   = null;
                return;
            }

            if (tile.tileContentType !== CarouselTypeConst.CATEGORY_TILE)
            {
                return;
            }
            const subCategoryGuid   = tile.tileAssetInfo.categoryGuid;
            const supercategoryInfo = subCategoryGuid
                ? this.channelLineupService.findSupercategoryByGuid(subCategoryGuid)
                : null;
            const subcategoryInfo   = subCategoryGuid
                ? this.channelLineupService.findSubcategoryByGuid(subCategoryGuid)
                : null;

            if (supercategoryInfo && subcategoryInfo)
            {
                tile.superCategoryInfo = supercategoryInfo;
                tile.subCategoryInfo   = subcategoryInfo;
            }
            else
            {
                CarouselCacheEntry.logger.warn(`addCategoryInfoToTiles( The prop "tileData" is falsy so defaulting to
                 "tile.superCategoryInfo & subCategoryInfo" to null. )`);
                tile.superCategoryInfo = {
                    key : tile.tileAssetInfo.categoryKey
                } as ISuperCategory;
                tile.subCategoryInfo   = {
                    key : tile.primaryNeriticLink.categoryKey
                } as ISubCategory;
            }
        });
    }

    /**
     * Take an array of tiles, and return a new array that contains only tiles from the fiest array that have
     * a defined and non null superCategoryInfo & subCategoryInfo property
     *
     * @param {Array} tiles is the input tiles
     * @returns array of tiles from the input array with tiles that have no channelInfo property removed
     */
    private static removeTilesWithNoCategoryInfo(tiles: Array<ITile>): Array<ITile>
    {
        return tiles.reduce((tiles: Array<ITile>, tile: ITile) =>
        {
            if ((tile.superCategoryInfo && tile.subCategoryInfo) || tile.tileContentType !== CarouselTypeConst.CATEGORY_TILE)
            { tiles.push(tile); }
            return tiles;
        }, []);
    }

    /**
     * Takes an array of neriticLink data then we remove favorites
     * functional group from neriticLinkData and returns new array of neriticLinkData
     * @param {Array<INeriticLinkData>} neriticLinkData
     * @returns {Array<INeriticLinkData>}
     */
    private static removeAddToFavoritesFromNeriticLinkDataIfNoShowInfo(neriticLinkData: Array<INeriticLinkData>): Array<INeriticLinkData>
    {
         return neriticLinkData.reduce((neriticLinkData: Array<INeriticLinkData>, neriticLink: INeriticLinkData) =>
        {
            if (neriticLink.functionalGroup !== CarouselTypeConst.FUNCTIONAL_GROUP_ADD_FAVORITES)
            {
                neriticLinkData.push(neriticLink);
            }
            else
            {
                CarouselCacheEntry.logger.error(`Found an orphan show tile - Removed from neriticLink data ${neriticLink.channelId}`);
            }
            return neriticLinkData;

        }, []);
    }

    /**
     * Take carousels and remove any content carousel with empty tiles and return the carousels (hero and content)
     *
     * @param {ICarouselDataByType} carousels
     * @returns {ICarouselDataByType}
     */
    private static removeCarouselsWithNoTiles(zone: IZoneDataByType) : IZoneDataByType
    {
        zone.content = zone.content.reduce((carousels: Array<ICarouselData>, carousel : ICarouselData) =>
        {
            if (carousel.tiles.length > 0) { carousels.push(carousel); }
            else
            {
                CarouselCacheEntry.logger.error(`Found an empty tiles ${carousel.title}`);
            }
            return carousels;
        },[]);

        zone.hero = zone.hero.reduce((carousels: Array<ICarouselData>, carousel : ICarouselData) =>
        {
            if (carousel.tiles.length > 0) { carousels.push(carousel); }
            else
            {
                CarouselCacheEntry.logger.error(`Found an empty tiles ${carousel.title}`);
            }
            return carousels;
        },[]);

        return zone;
    }

    /**
     * Check hero and content carousel tiles if tile has favorite changed
     */
    private updateFavorites(favorites : Array<IFavoriteItem>)
    {
        if (this.rawCarousel)
        {
            let modified = this.rawCarousel.zone.reduce(favoriteZoneReducer, false);
            modified     = this.rawCarousel.selectors.reduce(favoriteSelectorReducer, modified);

            if (modified)
            {

                this.pushValue(this.rawCarousel);
            }
        }

        /**
         * Reduce an selector to a true/false value based on the selector segments in the selector
         *
         * @param {boolean} modified is true or false based on whether we have discovered any modified tiles
         *        already
         * @param {ICarouselSelector} selector is the selector to reduce to a true/false value
         *
         * @returns {boolean} either true or false, based on whether modified was already true or we have
         *          found a modified tile within the carousel selector
         */
        function favoriteSelectorReducer(modified: boolean, selector: ICarouselSelector): boolean
        {
            return selector.segments.reduce(favoriteCarouselSelectorSegmentReducer, modified);
        }

        /**
         * Reduce an selector segment to a true/false value based on the selector segments in the selector
         *
         * @param {boolean} modified is true or false based on whether we have discovered any modified tiles
         *        already
         * @param {ICarouselSelectorSegment} segment is the segment to reduce to a true/false value
         *
         * @returns {boolean} either true or false, based on whether modified was already true or we have
         *          found a modified tile within the carousel selector segment
         */
        function favoriteCarouselSelectorSegmentReducer(modified: boolean,
                                                               segment: ICarouselSelectorSegment): boolean
        {
            const isModified = segment.carousels.reduce(favoriteCarouselReducer, modified);
            return segment.groupedCarousels.reduce(favoriteGroupedCarouselReducer, isModified);
        }

        /**
         * Reduce an zone to a true/false value based on the zone
         *
         * @param {boolean} modified is true or false based on whether we have discovered any modified tiles
         *        already
         * @param {IZoneDataByType} Zone is the zone to reduce to a true/false value
         *
         * @returns {boolean} either true or false, based on whether modified was already true or we have
         *          found a modified tile within the carousel selector
         */
        function favoriteZoneReducer(modified: boolean, zone: IZoneDataByType): boolean
        {
            modified = zone.hero.reduce(favoriteCarouselReducer, modified);
            return zone.content.reduce(favoriteCarouselReducer, modified);
        }

        /**
         * Reduce a carousel to a true/false value based on the tiles in the carousel
         *
         * @param {boolean} modified is true or false based on whether we have discovered any modified tiles
         *        already
         * @param { ICarouselData} carousel to reduce to a true/false value
         *
         * @returns {boolean} either true or false, based on whether modified was already true or we have
         *          found a modified tile within the carousel
         */
        function favoriteCarouselReducer(modified: boolean, carousel: ICarouselData): boolean
        {
            return carousel.tiles.reduce(callFavoriteTileReducer, modified);

            function callFavoriteTileReducer(modified: boolean, tile: ITile, index: number, array: any)
            {
                return favoriteTileReducer(modified, tile, index, array, carousel.screen === CarouselTypeConst.FAVORITES);
            }
        }

        /**
         * Reduce a carousel to a true/false value based on the tiles in the carousel
         *
         * @param {boolean} modified is true or false based on whether we have discovered any modified tiles
         *        already
         * @param { ICarouselData} carousel to reduce to a true/false value
         *
         * @returns {boolean} either true or false, based on whether modified was already true or we have
         *          found a modified tile within the carousel
         */
        function favoriteGroupedCarouselReducer(modified: boolean, groupedCarousel: IGroupedCarousel): boolean
        {
            return groupedCarousel.carousel.tiles.reduce(callFavoriteTileReducer, modified);

            function callFavoriteTileReducer(modified: boolean, tile: ITile, index: number, array: any)
            {
                return favoriteTileReducer(modified, tile, index, array, groupedCarousel.screen === CarouselTypeConst.FAVORITES);
            }
         }

        /**
         * Reduce a tile to a true/false value based on the whether the live channel information has been changed
         * from the last time the tile was examined by this function
         *
         * @param {boolean} modified is true or false based on whether we have discovered any modified tiles
         *        already
         * @param {ITile} tile to reduce to a true/false value
         *
         * @returns {boolean}
         */
        function favoriteTileReducer(modified: boolean,
                                     tile: ITile,
                                     index: number,
                                     array: any,
                                     isFavoriteCarousel: boolean = false): boolean
        {
            const favorite = findFavoriteForTile(tile, favorites);

            const wasFavorite = tile.isFavorite;

            const isFavorite = favorite ? true : false;

            tile.isFavorite = isFavorite;

            let sortOrderUpdated = false;

            if(isFavoriteCarousel && favorite)
            {
                sortOrderUpdated = tile.tabSortOrder !== favorite.tabSortOrder;
                tile.tabSortOrder = favorite.tabSortOrder;
            }

            return modified || wasFavorite !== isFavorite || sortOrderUpdated;
        }

        /**
         * Used to Find favorite item for given tile
         * @param {ITile} tile
         * @param {IFavoriteItem[]} favorites
         * @returns {IFavoriteItem}
         */
        function findFavoriteForTile(tile: ITile, favorites: IFavoriteItem[]): IFavoriteItem
        {
            let favoriteAssetGuid: string = "";
            let channelId: string         = tile.tileAssetInfo.channelId;
            let favorite                  = null;
            const favAssetGuid            = tile.tileAssetInfo.favAssetGuid;

            switch (tile.tileContentType)
            {
                case CarouselTypeConst.LIVE_SHOW_TILE:
                case CarouselTypeConst.SHOW_TILE:
                {
                    favoriteAssetGuid = favAssetGuid ? favAssetGuid : tile.tileAssetInfo.showGuid;
                    break;
                }
                case CarouselTypeConst.EPISODE_TILE:
                {
                    const assetGuid = tile.tileAssetInfo.isPandoraPodcast ? tile.tileAssetInfo.episodeGuid
                                                                          :  tile.tileAssetInfo.aodEpisodecaId;
                    const aodAssetGuid =  favAssetGuid ?  favAssetGuid : assetGuid;

                    const vodAssetGuid =  favAssetGuid ?  favAssetGuid :
                                          tile.tileAssetInfo.vodEpisodeGuid;

                    favoriteAssetGuid = tile.tileContentSubType === CarouselTypeConst.AOD_EPISODE_TILE
                                        || tile.tileContentSubType === CarouselTypeConst.PODCAST_EPISODE_TILE
                        ? aodAssetGuid
                        : vodAssetGuid;
                    break;
                }
                case CarouselTypeConst.CHANNEL_TILE:
                {
                    if(tile.tileContentSubType === CarouselTypeConst.SEEDED_RADIO)
                    {
                        favoriteAssetGuid = tile.tileAssetInfo.channelGuid;
                    }
                    break;
                }
            }

            if (favoriteAssetGuid)
            {
                favorite = findFavoriteForAssetGuid(favoriteAssetGuid,favorites);
            }
            else
            {
                favorite = favorites.find(fav =>
                {
                    return fav.assetType === tile.tileContentType
                        && fav.channelId === channelId
                        && fav.contentType !== ContentTypes.MYMIX_AUDIO;
                });
            }

            return favorite ? favorite : null;
        }

        function findFavoriteForAssetGuid(assetGUID : string, favorites: IFavoriteItem[])
        {
            return favorites.find(favoriteItem => favoriteItem.assetGUID === assetGUID);
        }
    }

    /**
     * Remove currently played Channel/AOD/VOD tile from recents carousel.
     * Reason: API some times sending currently played item in the response. So we are handling on client side to
     * remove the item.
     * @param {ICarouselDataByType} carousels
     * @param {MediaTimeLine} mediaTimeLine
     * @returns {ICarouselDataByType}
     */
    private static removeCurrentlyPlayedFromCarousel(carouselData: ICarouselDataByType,
                                                     mediaTimeLine: MediaTimeLine): ICarouselDataByType
    {
        carouselData.zone.forEach(zone=>
        {
            zone.hero.forEach((carousel: ICarouselData) =>
            {
                carousel.tiles = CarouselCacheEntry.removeCurrentlyPlayedTiles(carousel.tiles, mediaTimeLine);
            });
            zone.content.forEach((carousel: ICarouselData) =>
            {
                carousel.tiles = CarouselCacheEntry.removeCurrentlyPlayedTiles(carousel.tiles, mediaTimeLine);
            });
        });


        carouselData.selectors.forEach((selector: ICarouselSelector) =>
            selector.segments.forEach((segment: ICarouselSelectorSegment) =>
                segment.carousels.forEach((carousel: ICarouselData) =>
                {
                    carousel.tiles = CarouselCacheEntry.removeCurrentlyPlayedTiles(carousel.tiles, mediaTimeLine);
                })));

        return carouselData;
    }

    /**
     * Remove currently played Channel/AOD/VOD tile from recents carousel.
     * @param {Array<ITile>} tiles
     * @param {MediaTimeLine} mediaTimeLine
     * @returns {Array<ITile>}
     */
    private static removeCurrentlyPlayedTiles(tiles: Array<ITile>,
                                              mediaTimeLine: MediaTimeLine): Array<ITile>
    {
        return tiles.reduce((tiles: Array<ITile>, tile: ITile) =>
        {
            let mediaId;

            switch (tile.tileContentType)
            {
                case CarouselTypeConst.EPISODE_TILE:
                {
                    const episodeGuid = tile.tileAssetInfo.isPandoraPodcast ? tile.tileAssetInfo.episodeGuid
                                                                            : tile.tileAssetInfo.aodEpisodecaId;
                    mediaId = tile.tileContentSubType === CarouselTypeConst.VOD_EPISODE_TILE
                        ? tile.tileAssetInfo.vodEpisodeGuid : episodeGuid;
                    break;
                }
                case CarouselTypeConst.CHANNEL_TILE:
                {
                    if (tile.tileContentSubType === CarouselTypeConst.ADDITIONAL_CHANNEL
                       || tile.tileContentSubType === CarouselTypeConst.SEEDED_RADIO)
                    {
                        mediaId = tile.tileAssetInfo.channelGuid;
                    }
                    else
                    {
                        mediaId = tile.tileAssetInfo.channelId;
                    }
                    break;
                }
                default:
                    mediaId = tile.tileAssetInfo.channelId;
                    break;
            }

            if (mediaTimeLine.mediaId !== mediaId)
            {
                tiles.push(tile);
            }
            else
            {
                CarouselCacheEntry.logger.error(`Found a currently played tile ${tile.tileAssetInfo.recentPlayGuid}`);
            }

            return tiles;
        },[]);
    }

    /**
     * Remove seeded station tile from seeded stations carousel.
     * Reason: After removal of seeded station/stations new list from pandora taking time to return
     * which ended carousel call returns old list. for now removing from local cache - API-20680.
     * @param {ICarouselDataByType} carousels
     * @param {IRemoveSeededStationResponse} removedSeededStation
     * @returns {ICarouselDataByType}
     */
    private static removeSeededStationFromCarousel(carouselData: ICarouselDataByType,
                                                   removedSeededStation: IRemoveSeededStationResponse): ICarouselDataByType
    {
        carouselData.zone.forEach(zone=>
        {
            zone.hero.forEach((carousel: ICarouselData) =>
            {
                carousel.tiles = CarouselCacheEntry.removeSeededStationFromTiles(carousel.tiles, removedSeededStation);
            });
            zone.content.forEach((carousel: ICarouselData) =>
            {
                carousel.tiles = CarouselCacheEntry.removeSeededStationFromTiles(carousel.tiles, removedSeededStation);
            });
        });


        carouselData.selectors.forEach((selector: ICarouselSelector) =>
            selector.segments.forEach((segment: ICarouselSelectorSegment) =>
                segment.carousels.forEach((carousel: ICarouselData) =>
                {
                    carousel.tiles = CarouselCacheEntry.removeSeededStationFromTiles(carousel.tiles, removedSeededStation);
                })));

        return carouselData;
    }

    /**
     * Remove seeded station tile from seeded stations carousel.
     * @param {Array<ITile>} tiles
     * @param {IRemoveSeededStationResponse} removedSeededStation
     * @returns {Array<ITile>}
     */
    private static removeSeededStationFromTiles(tiles: Array<ITile>,
                                                removedSeededStation: IRemoveSeededStationResponse): Array<ITile>
    {
        if(removedSeededStation.removeAllSeededStations) { return [];}
        return tiles.reduce((tiles: Array<ITile>, tile: ITile) =>
        {
            if (tile.tileAssetInfo.stationId !== removedSeededStation.stationId)
            {
                tiles.push(tile);
            }
            else
            {
                CarouselCacheEntry.logger.error(`Found a seeded station tile ${tile.tileAssetInfo.stationId}`);
            }

            return tiles;
        },[]);
    }

    /**
     * Check hero and content carousel tiles if tile has alerts changed
     */
    private updateShowReminder(alerts : Array<IAlert>)
    {
        if (this.rawCarousel)
        {
            let modified = this.rawCarousel.zone.reduce(showReminderZoneReducer, false);
            modified     = this.rawCarousel.selectors.reduce(showReminderSelectorReducer, modified);

            if (modified)
            {
                this.pushValue(this.rawCarousel);
            }
        }

        /**
         * Reduce an selector to a true/false value based on the selector segments in the selector
         *
         * @param {boolean} modified is true or false based on whether we have discovered any modified tiles
         *        already
         * @param {ICarouselSelector} selector is the selector to reduce to a true/false value
         *
         * @returns {boolean} either true or false, based on whether modified was already true or we have
         *          found a modified tile within the carousel selector
         */
        function showReminderSelectorReducer(modified: boolean, selector: ICarouselSelector): boolean
        {
            return selector.segments.reduce(showReminderCarouselSelectorSegmentReducer, modified);
        }

        /**
         * Reduce an selector segment to a true/false value based on the selector segments in the selector
         *
         * @param {boolean} modified is true or false based on whether we have discovered any modified tiles
         *        already
         * @param {ICarouselSelectorSegment} segment is the segment to reduce to a true/false value
         *
         * @returns {boolean} either true or false, based on whether modified was already true or we have
         *          found a modified tile within the carousel selector segment
         */
        function showReminderCarouselSelectorSegmentReducer(modified: boolean,
                                                        segment: ICarouselSelectorSegment): boolean
        {
            const isModified = segment.carousels.reduce(reminderCarouselReducer, modified);
            return segment.groupedCarousels.reduce(reminderGroupedCarouselReducer, isModified);
        }

        /**
         * Reduce an zone to a true/false value based on the hero and content carousels in the zone
         *
         * @param {boolean} modified is true or false based on whether we have discovered any modified tiles
         *        already
         * @param {IZoneDataByType} zone is the selector to reduce to a true/false value
         *
         * @returns {boolean} either true or false, based on whether modified was already true or we have
         *          found a modified tile within the carousel selector
         */
        function showReminderZoneReducer(modified: boolean, zone: IZoneDataByType): boolean
        {
            modified = zone.hero.reduce(reminderCarouselReducer, modified);
            return zone.content.reduce(reminderCarouselReducer, modified);
        }

        /**
         * Reduce a carousel to a true/false value based on the tiles in the carousel
         *
         * @param {boolean} modified is true or false based on whether we have discovered any modified tiles
         *        already
         * @param { ICarouselData} carousel to reduce to a true/false value
         *
         * @returns {boolean} either true or false, based on whether modified was already true or we have
         *          found a modified tile within the carousel
         */
        function reminderCarouselReducer(modified: boolean, carousel: ICarouselData): boolean
        {
            return carousel.tiles.reduce(reminderTileReducer, modified);
        }

        /**
         * Reduce a carousel to a true/false value based on the tiles in the carousel
         *
         * @param {boolean} modified is true or false based on whether we have discovered any modified tiles
         *        already
         * @param { ICarouselData} carousel to reduce to a true/false value
         *
         * @returns {boolean} either true or false, based on whether modified was already true or we have
         *          found a modified tile within the carousel
         */
        function reminderGroupedCarouselReducer(modified: boolean, groupedCarousel: IGroupedCarousel): boolean
        {
            return groupedCarousel.carousel.tiles.reduce(reminderTileReducer, modified);
        }

        /**
         * Reduce a tile to a true/false value based on the whether the live channel information has been changed
         * from the last time the tile was examined by this function and the if reminders are found for tile
         *
         * @param {boolean} modified is true or false based on whether we have discovered any modified tiles
         *        already
         * @param {ITile} tile to reduce to a true/false value
         *
         * @returns {boolean}
         */
        function reminderTileReducer(modified: boolean, tile: ITile): boolean
        {
            const alert: ITileReminders = findRemindersForTile(tile, alerts);

            const oldAlerts: ITileReminders = {...tile.reminders};

            tile.reminders = alert;

            return modified
                || oldAlerts.liveVideoReminderSet !== tile.reminders.liveVideoReminderSet
                || oldAlerts.showReminderSet      !== tile.reminders.showReminderSet;
        }

        /**
         * Used to Find alert item for given tile
         * @param {ITile} tile
         * @param {IAlert[]} alerts
         * @returns {IAlert}
         */
        function findRemindersForTile(tile: ITile, alerts: IAlert[]): ITileReminders
        {
            let showGuid: string = tile.tileAssetInfo.showGuid;
            let reminders: ITileReminders = { liveVideoReminderSet: false, showReminderSet: false };

            if (tile.tileContentType === CarouselTypeConst.EPISODE_TILE || tile.tileContentType === CarouselTypeConst.CHANNEL_TILE)
            {
                return reminders;
            }

            if (showGuid)
            {
                reminders = findAlertsForAssetGuid(showGuid, alerts);
            }

            return reminders;
        }

        function findAlertsForAssetGuid(assetGuid: string, alerts: IAlert[]): ITileReminders
        {
            let filteredAlerts = alerts.filter(alertItem => alertItem.assetGuid === assetGuid);
            let reminders: ITileReminders = { liveVideoReminderSet: false, showReminderSet: false };

            reminders.liveVideoReminderSet = filteredAlerts.some(alert => alert.alertType === AlertType.LIVE_VIDEO_START.toString());
            reminders.showReminderSet = filteredAlerts.some(alert => alert.alertType === AlertType.SHOW.toString());

            return reminders;
        }
    }
}
