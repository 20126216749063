import { BehaviorSubject,
         Observable
} from "rxjs";
import { map, take } from "rxjs/operators";
import { FavoriteAssetTypes,
         IFavoriteItem,
         IGroupedFavorites,
         IRawFavoriteItem
} from "./favorite.interface";
import { Logger } from "../logger";
import { addProvider, IProviderDescriptor } from "../service";
import { FavoriteServiceUtil} from "./favorite.service.util";

export class FavoriteModel
{
    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("FavoriteModel");

    /**
     * Holds the favorite list
     * @type {Array}
     */
    private favoriteList: IFavoriteItem[] = [];

    /**
     * Subject used to trigger the favorites observable
     * @type {BehaviorSubject}
     */
    private favoritesSubject: BehaviorSubject<IFavoriteItem[]> = new BehaviorSubject<IFavoriteItem[]>([]);

    /**
     * Observable that can used to get notified when the favorites have changed
     * @type {any}
     */
    public favorites$: Observable<IFavoriteItem[]> = this.favoritesSubject;

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(FavoriteModel,
            FavoriteModel,
            []);
    }();

    /**
     * set favoriteList
     * @param {IFavoriteItem[]} favs
     * @param triggerFavorites -  to notify the favoriteList subscription
     */
    public setFavorites(favs: IFavoriteItem[], triggerFavorites: boolean)
    {
        this.favoriteList = favs;
         if (triggerFavorites) { this.favoritesSubject.next(this.favoriteList); }
    }

    /**
     * Return the favorite
     * @param {string} assetGuid
     * @returns {IFavoriteItem}
     */
    public getFavorite(assetGuid: string): IFavoriteItem
    {
        return this.favoriteList.find(favoriteItem => favoriteItem.assetGUID === assetGuid);
    }

    /**
     * Return the favorite by channel ID
     * @param {string} channelId
     * @returns {IFavoriteItem}
     */
    public getFavoriteForChannel(channelId: string): IFavoriteItem
    {
        return this.favoriteList.find(favoriteItem => favoriteItem.channelId === channelId
            && (favoriteItem.assetType === FavoriteAssetTypes.LIVE
                || favoriteItem.assetType === FavoriteAssetTypes.CHANNEL));
    }

    /**
     * Method that set Favorite list from carousel end Point
     *
     * @param { Observable<IGroupedFavorites>} favoriteGroupedTiles$  - normalized response from carousel endpoint
     */
    public setFavListFromCarousel(favoriteGroupedTiles$: Observable<IGroupedFavorites>)
    {
         favoriteGroupedTiles$
            .pipe(
                map(FavoriteServiceUtil.unGroupFavoriteItems),
                take(1)
            )
            .subscribe( (favorites: IRawFavoriteItem[]) =>
            {
                 const favoritesList = favorites.map(FavoriteServiceUtil.normalizeFavorites);
                 this.setFavorites(favoritesList, true);
            });
    }
}
