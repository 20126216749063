import { useRef, useEffect } from 'react';

function useUpdateEffect(callback: () => void, inputs: any[]) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      callback();
    } else {
      didMountRef.current = true;
    }
  }, inputs);
}

export default useUpdateEffect;
