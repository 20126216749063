import { of as observableOf, BehaviorSubject, Observable } from 'rxjs';
import { map, tap, share } from 'rxjs/operators';
import { IProviderDescriptor, addProvider, ClientCodes } from "../";
import { GeolocationDelegate } from "./index";

export class GeolocationService
{

    private lastCheckedContent: { channelId: string, episodeId?: string, contentType?: string } = { channelId: '', episodeId: '', contentType: '' };

    /**
     * Returns the the channel and episode id's of the last checked content
     */
    public getLastCheckedContent(): { channelId: string, episodeId?: string, contentType?: string }
    {
        return this.lastCheckedContent;
    }

    private geoRestrictionStatusSubject: BehaviorSubject<[ boolean, number]> = new BehaviorSubject([ true, 100] as [ boolean, number ]);

    public geoRestrictionResponse: BehaviorSubject<any> = new BehaviorSubject({});

    /**
     * Observable used to notify that the geoRestrictionStatus of the last content checked.
     *
     * @type {Observable<boolean>}
     * @memberof GeolocationService
     */
    public geoRestrictionStatus: Observable<[ boolean, number ]> = this.geoRestrictionStatusSubject.pipe(share());

    constructor(private geolocationDelegate: GeolocationDelegate) {}

    /**
     * Sends the request to get geoRestrictionStatus for a given content if location needs to be checked.
     * Updates the geoRestrictionStatus observable with geoRestirctionStatus for the latest content.
     *
     * @param {any} channelId
     * @param {any} episodeId
     * @returns {Observable<boolean>}
     * @memberof GeolocationService
     */
    public checkContent(channelId: string, episodeId: string,
                        checkGeolocation: boolean,
                        isLiveTune: boolean,
                        contentType: string = ""): Observable<number>
    {
        this.lastCheckedContent = { channelId: channelId, episodeId:  isLiveTune ? null : episodeId, contentType: contentType };

        if (checkGeolocation)
        {
            return this.geolocationDelegate
                .getGeoRestrictionStatusForLocation(channelId, episodeId).pipe(
                tap(([isContentAllowed, apiCode]: [boolean, number]) =>
                {
                    this.geoRestrictionResponse.next(this.geolocationDelegate.fullResponse);
                    this.geoRestrictionStatusSubject.next([ isContentAllowed, apiCode ]);
                }),
                map(([isContentAllowed, apiCode]: [boolean, number]) => isContentAllowed ? ClientCodes.SUCCESS : ClientCodes.ERROR));
        }
        else
        {
            this.geoRestrictionStatusSubject.next([true, 100]);
            return observableOf(ClientCodes.SUCCESS);
        }
    }

    private static providerDescriptor: IProviderDescriptor = function()
    {
        return addProvider(GeolocationService, GeolocationService, [ GeolocationDelegate ]);
    }();
}
