import * as _ from 'lodash';
import { IAppConfig } from '../../config/interfaces/app-config.interface';
import { IRelativeUrlConfig } from '../../config/interfaces/config.interface';
import { ConfigService } from '../../config/config.service';
import {
  ICdnAccessToken,
  ResponseInterceptor,
} from '../../http/http.provider.response.interceptor';
import { Logger } from '../../logger/logger';
import {
  IChunk,
  IHlsManifestFile,
  IMediaEndPoint,
  MediaTimeLine,
} from '../../../index';
import { ServiceEndpointConstants } from '../../service/consts/service.endpoint.constants';
import { TokenStrategies } from '../../service/consts/token.strategies';
import { IProviderDescriptor } from '../../service/provider.descriptor.interface';
import { SettingsService } from '../../settings/settings.service';
import { MediaUtil } from '../media.util';
import { relativeUrlToAbsoluteUrl } from '../../util/utilities';
import { DateUtil } from '../../util/date.util';
import { addProvider } from '../../index';
import { PlayerConfig } from './audio-player.config';
import { AudioPlayerConstants } from './audio-player.consts';

/**
 * @MODULE:     service-lib
 * @CREATED:    10/12/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * Creational factory (albeit without a static method) to acquire an audio player configuration object.
 */
export class AudioPlayerConfigFactory {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('AudioPlayerConfigFactory');

  /**
   * cdnAccessToken assigned from responseInterceptor.cdnAccessTokens Observable.
   * And used to send token information to web audio player.
   */
  private cdnAccessTokens: ICdnAccessToken;

  /**
   * Reflects the current media timeline.
   * @type {MediaTimeLine}
   */
  private mediaTimeLine: MediaTimeLine;

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(AudioPlayerConfigFactory, AudioPlayerConfigFactory, [
      ConfigService,
      SettingsService,
      ResponseInterceptor,
      'IAppConfig',
    ]);
  })();

  /**
   * Constructor
   * @param {ConfigService} configService
   * @param {SettingsService} settingsService
   * @param {ResponseInterceptor} responseInterceptor
   * @param {IAppConfig} SERVICE_CONFIG
   */
  constructor(
    private configService: ConfigService,
    private settingsService: SettingsService,
    private responseInterceptor: ResponseInterceptor,
    private SERVICE_CONFIG: IAppConfig,
  ) {
    this.observeCdnAccessTokens();
  }

  /**
   * Returns the media player service for the given type of media requested.
   */
  public getConfig(
    mediaTimeLine: MediaTimeLine,
    playerState?: string,
  ): PlayerConfig {
    this.mediaTimeLine = mediaTimeLine;
    this.setMediaTimeLineMediaEndPointsUrls();

    const config: PlayerConfig = new PlayerConfig();
    const primaryMediaEndPoint = this.getMediaEndPoint(
      AudioPlayerConstants.PRIMARY_END_POINT,
      AudioPlayerConstants.SMALL_SIZE,
    );

    /**
     * Used to get the tokenized audio player asset URLs, this function sets in config objecta and it sends to audio player.
     *
     *
     * NOTE: The Reason for this Method: Web audio player cant directly invokes `TokenStrategies.getTokenizedUrl()`
     * due to cdnAccessTokens are not available, so this method is wrapper on top of TokenStrategies.
     *
     * @param {string} url
     * @returns {string}
     */
    const getTokenizedUrl = (url: string): string => {
      return TokenStrategies.getTokenizedUrl(url, this.cdnAccessTokens);
    };

    config.getTokenizedUrl = getTokenizedUrl.bind(this);
    config.getTokenStrategy = TokenStrategies.getTokenStrategy;
    config.keyPath =
      this.SERVICE_CONFIG.apiEndpoint + AudioPlayerConstants.KEY_PATH;
    config.connectivityUrl =
      this.SERVICE_CONFIG.apiEndpoint +
      ServiceEndpointConstants.NETWORK_CONNECTIVITY;
    config.autoPlay = this.getAutoPlay(playerState);
    config.crossFadeEnabled = false;
    config.hlsProxyEnabled = false;
    config.loggerEnabled = Logger.isEnabled;
    config.playbackType = this.getPlaybackType();
    config.tuneStart = this.settingsService.isTuneStartOn();
    config.higherQualityAudio = this.settingsService.isHigherAudioQuality();
    config.maxQualityAudio = this.settingsService.isMaximumAudioQuality();
    config.primaryAudioURL = this.getDefaultUrl();
    config.audioURLs = this.getAudioUrls();
    config.audioFirstURLs = this.getAudioFirstUrls();
    config.hlsConsumptionInfo = this.mediaTimeLine.hlsConsumptionInfo;
    config.id = this.mediaTimeLine.mediaId + '_' + new Date().getTime();

    //Added to support chromecast
    config.assetGuid = this.mediaTimeLine.mediaId;
    config.channelId = this.mediaTimeLine.channelId;
    config.mediaType = this.mediaTimeLine.mediaType;
    //config.useMediaElement = this.getUseMediaElement();
    config.useMediaElement = true;

    if (mediaTimeLine.streamingAdsId) {
      config.FAILOVER_RETRY_LIMIT = 0;
    }

    return config;
  }

  /**
   * determine auto playback.
   * @returns {boolean}
   */
  public getAutoPlay(playerState: string): boolean {
    const isMediaFromResumeDL = this.mediaTimeLine
      .isDataComeFromResumeWithDeepLink;
    const isMediaFromResume = this.mediaTimeLine.isDataComeFromResume;
    const isPlayerPaused = playerState === AudioPlayerConstants.PAUSED;
    const isForceRetune = this.mediaTimeLine.forceRetune;

    return (
      isMediaFromResumeDL ||
      (!isMediaFromResume && (!isPlayerPaused || !isForceRetune))
    );
  }

  /**
   * Used to get playback type based on Live or On Demand audio (ala AOD).
   * @returns {string}
   */
  public getPlaybackType(): string {
    if (MediaUtil.isAudioMediaTypeLive(this.mediaTimeLine.mediaType)) {
      return AudioPlayerConstants.LIVE;
    } else if (
      MediaUtil.isAudioMediaTypeOnDemand(this.mediaTimeLine.mediaType)
    ) {
      return AudioPlayerConstants.AOD;
    } else if (MediaUtil.isPodcastMediaType(this.mediaTimeLine.mediaType)) {
      return AudioPlayerConstants.PODCAST;
    }

    return '';
  }

  /**
   * Returns an HLS URL for a given origin server endpoint name and manifest size.
   *
   * @param {string} endPointName
   * @param {string} size
   * @returns {string}
   */
  public getAudioUrl(endPointName: string, size: string): string {
    let url: string = '';
    let mediaEndPoint: IMediaEndPoint = _.find(
      this.mediaTimeLine.mediaEndPoints,
      findMediaEndPoint(endPointName),
    ) as IMediaEndPoint;
    let useManifests: boolean = mediaEndPoint
      ? mediaEndPoint.manifestFiles && mediaEndPoint.manifestFiles.length > 0
      : false;

    if (useManifests) {
      let manifest: IHlsManifestFile = _.find(
        mediaEndPoint.manifestFiles,
        findManifest(size),
      ) as IHlsManifestFile;
      return manifest ? manifest.url : url;
    } else {
      return mediaEndPoint ? mediaEndPoint.url : url;
    }

    function findMediaEndPoint(endPointName: string) {
      return function(mediaEndPoint: IMediaEndPoint): boolean {
        return mediaEndPoint.name.toLowerCase() === endPointName.toLowerCase();
      };
    }

    function findManifest(size: string) {
      return function(manifest: IHlsManifestFile): boolean {
        return manifest.size.toLowerCase() === size.toLowerCase();
      };
    }
  }

  /**
   * Gets the media Endpoint based on primary or secondary and size. If media end point not found in small size then
   * will look for large and returns the mediaEndpoint.
   * @param {string} endPointName
   * @param {string} size
   * @returns {IMediaEndPoint}
   */
  private getMediaEndPoint(endPointName: string, size: string): IMediaEndPoint {
    let mediaEndPoint = _.find(
      this.mediaTimeLine.mediaEndPoints,
      (mediaEndPoint: IMediaEndPoint): boolean =>
        mediaEndPoint.name.toLowerCase() === endPointName.toLowerCase() &&
        mediaEndPoint.size.toLowerCase() === size.toLowerCase(),
    );

    if (!mediaEndPoint) {
      mediaEndPoint = _.find(
        this.mediaTimeLine.mediaEndPoints,
        (mediaEndPoint: IMediaEndPoint): boolean =>
          mediaEndPoint.name.toLowerCase() === endPointName.toLowerCase() &&
          mediaEndPoint.size.toLowerCase() ===
            AudioPlayerConstants.LARGE_SIZE.toLowerCase(),
      );
    }
    return mediaEndPoint;
  }

  /**
   * Returns the default HLS URL for audio.
   * @returns {string}
   */
  private getDefaultUrl(): string {
    return this.getAudioUrl(
      AudioPlayerConstants.PRIMARY_END_POINT,
      AudioPlayerConstants.LARGE_SIZE,
    );
  }

  /**
   * Used to create and get Audio Urls Object which can be fed into config object.
   * @returns {any}
   */
  private getAudioUrls(): Array<any> {
    return [
      {
        small: this.getAudioUrl(
          AudioPlayerConstants.PRIMARY_END_POINT,
          AudioPlayerConstants.SMALL_SIZE,
        ),
        medium: this.getAudioUrl(
          AudioPlayerConstants.PRIMARY_END_POINT,
          AudioPlayerConstants.MEDIUM_SIZE,
        ),
        large: this.getAudioUrl(
          AudioPlayerConstants.PRIMARY_END_POINT,
          AudioPlayerConstants.LARGE_SIZE,
        ),
      },
      {
        small: this.getAudioUrl(
          AudioPlayerConstants.SECONDARY_END_POINT,
          AudioPlayerConstants.SMALL_SIZE,
        ),
        medium: this.getAudioUrl(
          AudioPlayerConstants.SECONDARY_END_POINT,
          AudioPlayerConstants.MEDIUM_SIZE,
        ),
        large: this.getAudioUrl(
          AudioPlayerConstants.SECONDARY_END_POINT,
          AudioPlayerConstants.LARGE_SIZE,
        ),
      },
    ];
  }

  /**
   * Return boolean by identifying podcast mp3 support
   */
  private getUseMediaElement(): boolean {
    return MediaUtil.isPodcastMediaType(this.mediaTimeLine.mediaType);
  }

  /**
   * Used to create and get Audio First Urls Object which can be fed into config object.
   * @returns {any}
   */
  private getAudioFirstUrls(): Array<any> {
    const primary: IMediaEndPoint = this.getMediaEndPoint(
      AudioPlayerConstants.PRIMARY_END_POINT,
      AudioPlayerConstants.SMALL_SIZE,
    );
    const secondary: IMediaEndPoint = this.getMediaEndPoint(
      AudioPlayerConstants.SECONDARY_END_POINT,
      AudioPlayerConstants.SMALL_SIZE,
    );

    const primaryChunks =
      primary && primary.mediaFirstChunks.length > 0
        ? primary.mediaFirstChunks.map((chunk: IChunk) =>
            prepareAudioChunk(chunk),
          )
        : [];

    const secondaryChunks =
      secondary && secondary.mediaFirstChunks.length > 0
        ? secondary.mediaFirstChunks.map((chunk: IChunk) =>
            prepareAudioChunk(chunk),
          )
        : [];

    return [
      {
        chunks: primaryChunks,
      },
      {
        chunks: secondaryChunks,
      },
    ];

    function prepareAudioChunk(chunk: IChunk): any {
      const date = DateUtil.parseUnknownFormatToDate(chunk.isoTimestamp);
      const isoTimestampForAudioPlayer = DateUtil.convertDateToISO8601Format2(
        date,
      );
      return {
        key: chunk.key,
        keyUrl: chunk.keyUrl,
        url: chunk.url,
        offset: chunk.interChunkOffset,
        timestamp: isoTimestampForAudioPlayer,
      };
    }
  }

  /**
   * API sends constant string in each url property.
   * Used to replace constant string values to the valid Urls.
   */
  private setMediaTimeLineMediaEndPointsUrls() {
    AudioPlayerConfigFactory.logger.debug(
      `setMediaTimeLineMediaEndPointsUrls()`,
    );

    if (!this.mediaTimeLine || !this.mediaTimeLine.mediaEndPoints) {
      return;
    }

    const relativeUrls = this.configService.getRelativeUrlSettings();

    this.mediaTimeLine.mediaEndPoints = this.mediaTimeLine.mediaEndPoints.map(
      mediaEndPoint => {
        mediaEndPoint.url = relativeUrlToAbsoluteUrl(
          mediaEndPoint.url,
          relativeUrls,
        );

        if (mediaEndPoint.manifestFiles) {
          mediaEndPoint.manifestFiles = mediaEndPoint.manifestFiles.map(
            manifestFile => {
              manifestFile.url = relativeUrlToAbsoluteUrl(
                manifestFile.url,
                relativeUrls,
              );
              return manifestFile;
            },
          );
        }

        if (mediaEndPoint.mediaFirstChunks) {
          mediaEndPoint.mediaFirstChunks = mediaEndPoint.mediaFirstChunks.map(
            mediaFirstChunk => {
              mediaFirstChunk.url = mediaFirstChunk.url
                ? relativeUrlToAbsoluteUrl(mediaFirstChunk.url, relativeUrls)
                : undefined;
              mediaFirstChunk.keyUrl = mediaFirstChunk.keyUrl
                ? relativeUrlToAbsoluteUrl(mediaFirstChunk.url, relativeUrls)
                : undefined;

              return mediaFirstChunk;
            },
          );
        }
        return mediaEndPoint;
      },
    );
  }

  /**
   * Observe data changes on the CDN access tokens. If there's changes, update the them.
   */
  private observeCdnAccessTokens() {
    AudioPlayerConfigFactory.logger.debug(`observeCdnAccessTokens()`);

    this.responseInterceptor.cdnAccessTokens.subscribe(
      onCdnAccessTokensSuccess.bind(this),
      onCdnAccessTokensFault.bind(this),
    );

    /**
     * Updates the CN Access tokens.
     * @param {ICdnAccessToken} cdnAccessTokens
     */
    function onCdnAccessTokensSuccess(cdnAccessTokens: ICdnAccessToken) {
      if (cdnAccessTokens) {
        AudioPlayerConfigFactory.logger.debug(
          `onCdnAccessTokensSuccess( Update tokens. )`,
        );
        this.cdnAccessTokens = cdnAccessTokens;
      }
    }

    /**
     * If observable throws a fault then logs the error message.
     * @param error
     */
    function onCdnAccessTokensFault(error: any) {
      AudioPlayerConfigFactory.logger.warn(
        `onCdnAccessTokensFault( Error: ${JSON.stringify(error)}. )`,
      );
    }
  }
}
