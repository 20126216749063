export class InitializationStatusCodes
{
    public static UNCONFIGURED: string            = "unconfigured";
    public static UNINITIALIZED: string           = "uninitialized";
    public static INITIALIZING: string            = "initializing";
    public static RESUMING: string                = "resuming";
    public static RUNNING: string                 = "running";
    public static FAILURE: string                 = "failure";
    public static RELOADING: string               = "reloading";
    public static UNAUTHENTICATED: string         = "unauthenticated";
    public static OPENACCESS: string              = "openaccess";
    public static UNAUTHENTICATED_RESTART: string = "unauthenticatedrestart";
}
