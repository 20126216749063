import { IMetaData } from '../../chromecast/chromecast.interface';

export class ChromecastPlayerConfig {
  public id = '';
  public startTime = 0;
  public metaData: IMetaData = {
    channelId: '',
    mediaType: '',
    assetGuid: '',
    sequencerId: '',
    stationId: '',
    tracks: '[]',
    skipLimitReached: false,
  };
}
