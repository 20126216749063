import * as RecentlyPlayedActions from '../action';
import { RecentlyPlayedAction } from '../action/recently-played.action';
import { IRecentlyPlayedStore } from '../selector/recently-played.store';
import { IRecentlyPlayed } from '../../servicelib';

const initialState: IRecentlyPlayedStore = {
  recentlyPlayedList: [] as Array<IRecentlyPlayed>,
};

/**
 * Return the updated data store depending on the given action.
 * @param state - current state of the recently played store.
 * @param action - recently played action
 * @returns - updated recently played store.
 */
export function recentlyPlayedListReducer(
  state: IRecentlyPlayedStore = initialState,
  action: RecentlyPlayedAction,
): IRecentlyPlayedStore {
  switch (action.type) {
    case RecentlyPlayedActions.LOAD_RECENTLY_PLAYED_LIST:
      return {
        ...state,
        recentlyPlayedList: action.payload,
      };

    default:
      return state;
  }
}
