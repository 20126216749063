import { of as observableOf,  Observable } from 'rxjs';
import { combineLatest, filter, switchMap, map } from 'rxjs/operators';
import * as _ from "lodash";

import
{
    ICurrentlyPlayingMedia,
    IMediaPlayer,
    IProviderDescriptor,
    AppMonitorService,
    AppErrorCodes,
    addProvider,
    Logger
} from "../index";

import { secondsToMs } from "../util";

import {
    MediaPlayerConstants,
    MediaPlayerFactory
}                               from "../mediaplayer";
import {IAppConfig}             from "../config/interfaces/app-config.interface";
import { CurrentlyPlayingService } from "../currently-playing/currently.playing.service";

interface IPlaybackState
{
    data: ICurrentlyPlayingMedia;
    channelId : string;
    playbackState: string;
}

interface IInactivityState
{
    inactivityState : string;
    channelId : string;
    inactivityTimeOut? : number;
}


/**
 * @MODULE:     service-lib
 * @CREATED:    08/16/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *   Checks periodically to see if the user is still interacting with the client
 */
export class InactivityService
{
    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("InactivityService");

    // internal state definitions
    private static SET_INACTIVITY   = "setinactivity";
    private static CLEAR_INACTIVITY = "clearinactivity";
    private static DO_NOTHING       = "donothing";

    /**
     * Stores the channelGuid of currently playing content
     */
    private channelId : string;

    /**
     * Holds the inactivity timer
     */
    private inactivityTimer: any;

    /**
     * The max time interval allowed for no user activity.
     * @type {number}
     */
    public static USER_INACTIVITY_INTERVAL: number = 0;

    /**
     * An array of functions to be called when the app becomes inactive.
     * @type {Array}
     */
    private inactivityCallbacks: Function[] = [];

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor : IProviderDescriptor = function()
    {
        return addProvider(InactivityService,
                           InactivityService,
                           [CurrentlyPlayingService,
                            MediaPlayerFactory,
                            AppMonitorService,
                            "IAppConfig"]);
    }();

    /**
     * Constructor.
     *
     * @param currentlyPlayingService allows us to observe changes in what is playing
     * @param channelLineupService allows us to look up the inactivity timeout for the channel for what is playing
     * @param mediaPlayerFactory allows us to observe the playback state of the media that is playing
     * @param SERVICE_CONFIG provides access to query string information for the running app
     */
    constructor(private currentlyPlayingService: CurrentlyPlayingService,
                private mediaPlayerFactory : MediaPlayerFactory,
                public  appMonitorService: AppMonitorService,
                private SERVICE_CONFIG: IAppConfig)
    {
        const urlParams    = new URLSearchParams(SERVICE_CONFIG.contextualInfo.queryString);
        const forceTimeout = urlParams.has("inactivity-test") ? parseInt(urlParams.get("inactivity-test")) : 0;

        currentlyPlayingService.currentlyPlayingData.pipe(filter((data : ICurrentlyPlayingMedia) => !!data),
            combineLatest(mediaPlayerFactory.currentMediaPlayer,playbackState),
            switchMap((playback : Observable<IPlaybackState>) => playback),
            filter((playback:IPlaybackState) => playback.playbackState === MediaPlayerConstants.PLAYING && playback.data.inactivityTimeOut !== 0),
            map((playback : IPlaybackState) => getInactivityState(playback,this.channelId)))
            .subscribe((nextState : IInactivityState) => this.gotoNextState(nextState,forceTimeout));

        /**
         * Determines what the next inactivity state should be
         *
         * @param {IPlaybackState} playback contains the state of playback on the client
         * @param {string} channelId contains the channelId that currently has an inactivity timeout (may be undefined)
         * @returns the next state that the inactivity service should go into (set, clear, do nothing)
         */
        function getInactivityState(playback : IPlaybackState, channelId : string) : IInactivityState
        {
            const state          = { inactivityState : InactivityService.DO_NOTHING
                                    , channelId : playback.data.channelId
                                    , inactivityTimeOut : playback.data.inactivityTimeOut };
            const isPlaying      = playback.playbackState === MediaPlayerConstants.PLAYING;
            const contentChanged = channelId !== playback.data.channelId;

            if (isPlaying && contentChanged) { state.inactivityState = InactivityService.SET_INACTIVITY; }
            if (!isPlaying && channelId) { state.inactivityState = InactivityService.CLEAR_INACTIVITY; }

            return state;
        }

        /**
         * Collects data about what is playing and what state the media player is in an creates a new observable
         * with that information
         *
         * @param {ICurrentlyPlayingMedia} data representing what is currently playing
         * @param {IMediaPlayer} mediaPlayer that is playing media currently
         * @returns {Observable<IPlaybackState>} playback state object for monitoring playback
         */
        function playbackState(data : ICurrentlyPlayingMedia,
                               mediaPlayer : IMediaPlayer): Observable<IPlaybackState>
        {
            const playback = { data : data, playbackState : undefined, channelId : undefined };

            if(!mediaPlayer)
            {
                playback.playbackState = MediaPlayerConstants.STOPPED;
                return observableOf(playback);
            }
            return mediaPlayer.playbackState.pipe(map((playbackState : string) =>
            {
                playback.playbackState = playbackState;
                return playback;
            }));
        }
    }

    /**
     * Either set, reset, clear the inactivity timeout, or do nothing
     *
     * @param nextState describes the next state that the inactivity service should go to
     * @param {number} forceTimeout is a timeout that if non-zero, will override the inactivity timeout for the channel
     */
    private gotoNextState(nextState : IInactivityState,forceTimeout : number)
    {
        switch(nextState.inactivityState)
        {
            case InactivityService.SET_INACTIVITY:
                let inactivityTimeout = (forceTimeout !== 0) ? forceTimeout : nextState.inactivityTimeOut;
                this.channelId = nextState.channelId;
                this.setInactivityTimer(inactivityTimeout);
                break;

            case InactivityService.CLEAR_INACTIVITY:
                clearTimeout(this.inactivityTimer);
                InactivityService.logger.debug(`stopping inactivity timer for ${this.channelId}`);
                this.channelId = null;
                break;
        }
    }


    /**
     * Sets the timer using the user inactivity interval defined in the currently playing channel
     *
     * @param inactivityTimeout is the number of seconds of inactivity to allow before triggering an inactivity
     *        timeout. The default is InactivityService.USER_INACTIVITY_INTERVAL, which is the current setting
     *        and will "refresh" an existing timer to start counting down again.
     */
    public setInactivityTimer(inactivityTimeout = InactivityService.USER_INACTIVITY_INTERVAL): void
    {
        if(this.inactivityTimer)
        {
            InactivityService.logger.debug(`re-setting inactivity timer for ${this.channelId} to ${InactivityService.USER_INACTIVITY_INTERVAL}`);
            clearTimeout(this.inactivityTimer);
        }
        else
        {
            InactivityService.logger.debug(`setting inactivity timer for ${this.channelId} to ${InactivityService.USER_INACTIVITY_INTERVAL}`);
        }

        if (inactivityTimeout !== 0)
        {
            InactivityService.USER_INACTIVITY_INTERVAL = inactivityTimeout;

            this.inactivityTimer = setTimeout(() =>
            {
                this.pushFault();
                this.inactivityCallbacks.forEach(func => func());
                this.channelId = null;
            }, secondsToMs(inactivityTimeout));
        }
    }

    /**
     * Allows the inactivity timer to be reset only once per minute
     */
    public throttledInactivityTimer: any = _.throttle(function ()
    {
        if (this.channelId) { this.setInactivityTimer(); }
    }, 60000).bind(this);

    /**
     * Allows the client to register call back functions to be called when the app becomes inactive.
     *
     * NOTE: ** This should not be used to set the main behavior of the UI when inactivity is detected. **
     *
     * The passed functions can be used to react to the app becoming inactive.
     * EX: Pass a function to stop audio.
     *
     * @param {Function} func
     */
    public addInactivityCallback(func: Function): void
    {
        this.inactivityCallbacks.push(func);
    }

    public pushFault()
    {
        const faultCode = AppErrorCodes.FLTT_INACTIVITY_TIMEOUT;
        this.appMonitorService.triggerFaultError({ faultCode: faultCode });
    }
}
