import {
  IProviderDescriptor,
  InitializationStatusCodes,
  ServiceEndpointConstants,
  IAppConfig,
} from '../index';
import { addProvider } from '../service';
import { msToSeconds } from '../util';
import { NoopDelegate } from './noop.delegate';
import { AppMonitorService } from '../app-monitor/app-monitor.service';
import { Logger } from '../logger';
import { ApiDelegate } from '../http';
import { BypassMonitorService } from '../app-monitor';
import { InitializationService } from '../initialization';
import {
  filter,
  take,
  delayWhen,
  throttleTime,
  switchMap,
} from 'rxjs/operators';
import { Subscription, interval, of, Observable } from 'rxjs';
import { TuneModel, TuneState, ITuneModel } from '../tune/tune.model';
import { AuthenticationService } from '../authentication';

/**
 * @MODULE:     service-lib
 * @CREATED:    09/25/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * Pings the API every 50 seconds when audio or video playback has started playing to ensure delivery of fresh CDN access tokens to the players.
 */

export class NoopService {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('NoopService');

  /**
   * Number of milliseconds between each API pinging NOOP call.
   * @type {number}
   */
  public static readonly NOOP_PERIOD = 1000 * 50;

  /*
   * Interval timer for calling periodic NOOP API requests.
   */
  private period: number = NoopService.NOOP_PERIOD;

  private intervalSubscription: Subscription;

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(NoopService, NoopService, [
      NoopDelegate,
      AppMonitorService,
      ApiDelegate,
      BypassMonitorService,
      InitializationService,
      TuneModel,
      AuthenticationService,
      'IAppConfig',
    ]);
  })();

  /**
   * Constructor.
   * @param noopDelegate - The delegate that makes the NOOP HTTP API request.
   * @param appMonitorService - service that monitors app state
   * @param apiDelegate - allows adding a handler to looks at the API codes on noop responses
   * @param bypassMonitorService
   * @param initService
   * @param tuneModel
   */
  constructor(
    private noopDelegate: NoopDelegate,
    private appMonitorService: AppMonitorService,
    private apiDelegate: ApiDelegate,
    private bypassMonitorService: BypassMonitorService,
    private initService: InitializationService,
    private tuneModel: TuneModel,
    private authenticationService: AuthenticationService,
    private SERVICE_CONFIG: IAppConfig,
  ) {
    apiDelegate.addApiEndpointReporter(
      ServiceEndpointConstants.endpoints.CHANNEL.V2_NOOP,
      noopCodeHandler.bind(this),
    );

    function noopCodeHandler(codes: number[]) {
      this.bypassMonitorService.handleNoopMessage(codes);
    }

    // In some cases we are not seeing the resume data from API and until user is tuned-
    // we are not kicking off the noop which results in not having the notifications for that user
    // So we need to Kick-Off noop on load of the application after the init service enters the running state
    this.initService.initState
      .pipe(
        filter(state => state === InitializationStatusCodes.RUNNING),
        take(1),
      )
      .subscribe(() => {
        if (
          !this.SERVICE_CONFIG.isFreeTierEnable ||
          this.authenticationService.isUserRegistered()
        ) {
          this.start();
        }
      });
  }

  /*
   * Start a timer interval that will periodically make NOOP calls to ensure the delivery of fresh CDN access tokens.
   */
  public start(period: number = 0): void {
    this.period = period || NoopService.NOOP_PERIOD;
    NoopService.logger.debug(
      `start( Period: ${msToSeconds(period)} seconds. )`,
    );

    this.stop();

    const interval$ = interval(this.period);

    const obs = interval$.pipe(
      delayWhen(() => {
        const state: TuneState = this.tuneModel.tuneState;
        if (state === TuneState.IDLE) {
          return of(state);
        } else {
          return this.tuneModel.tuneModel$.pipe(
            filter((model: ITuneModel) => {
              return model.tuneState === TuneState.IDLE;
            }),
          );
        }
      }),
      throttleTime(this.period * 0.9), // same as period would a race condition with the period.
      switchMap(() => {
        return this.noop();
      }),
    );

    this.intervalSubscription = obs.subscribe(
      (period: number) => {
        this.updatePeriod(period);
      },
      error => {
        NoopService.logger.error(
          `noopFault - Error ( ${JSON.stringify(error)} )`,
        );
      },
    );
  }

  /*
   * Stop the timer interval for NOOP calls and clear it.
   */
  public stop(): void {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
    }
  }

  /**
   * Method will use the delegate to make NOOP API calls.
   */
  public noop(): Observable<number> {
    NoopService.logger.debug(
      `noop( Called every ${msToSeconds(NoopService.NOOP_PERIOD)} seconds. )`,
    );

    return this.noopDelegate.noop();
  }

  public updatePeriod(period: number): void {
    if (typeof period === 'number' && this.period !== period) {
      NoopService.logger.debug(
        `noopResult( Updating frequency to: ${msToSeconds(period)} seconds. )`,
      );

      this.start(period);
    }
  }
}
