import {
  //timer as observableTimer,
  of as observableOf,
  Observable,
  BehaviorSubject,
} from 'rxjs';
import {
  finalize,
  filter,
  map,
  catchError,
  //mergeMap,
  share,
} from 'rxjs/operators';
import { BypassMonitorService } from '../app-monitor/bypass-monitor.service';
import {
  Logger,
  addProvider,
  ConfigService,
  IRelativeUrlSetting,
  IProviderDescriptor,
  IRefreshTracksRequestPayload,
  IAppByPassState,
} from '../index';
import { MediaTimeLine, TuneResponse, IClip } from '../tune/tune.interface';
import { RefreshTracksDelegate } from './refresh-tracks.delegate';
import { MultiTrackList } from '../mediaplayer/multitrackaudioplayer/multi-track-list';
import { TuneDelegate } from '../index';
import { MultiTrackConstants } from '../mediaplayer/multitrackaudioplayer/multi-track.consts';
import { ApiLayerTypes } from '../index';
import * as _ from 'lodash';
import { MediaUtil } from '../index';
import { ClientCodes } from '../index';
import { ApiCodes, ServiceEndpointConstants } from '../service/consts';
import { AppMonitorService } from '../app-monitor';
//import { RefreshTracksModel } from './refresh-tracks.model';
//import { ITuneModel, TuneModel } from '../tune/tune.model';

/**
 * @MODULE:     service-lib
 * @CREATED:    10/31/18
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * RefreshTracksService for refresh the clips.
 */
export class RefreshTracksService {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('RefreshTracksService');

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the refreshedTrackListData
   * and be notified when the refreshedTrackListData data changes.
   * @type {any}
   */
  public mediaTimeLine: Observable<MediaTimeLine>;

  /**
   * The updated clips data that represents the refreshed clips data.
   * @type {any}
   */
  private refreshedTrackListData: MediaTimeLine = null;

  /**
   * subject for delivering updated clips data through the refreshedTracks observable.
   * @type {any}
   */
  private refreshTrackListSubject: BehaviorSubject<MediaTimeLine> = null;

  /**
   * Observable for determining if a refresh track API call is in progress
   */
  public refreshingTracks$: BehaviorSubject<boolean> = new BehaviorSubject<
    boolean
  >(false);

  /**
   * Piece of state that holds the old statuses and ids.
   * If the statuses are all the same, no sense in making
   *  a refresh-tracks call.
   */
  public arrOfOld: any[] = [];

  /**
   * Flag indicates aic is in bypass mode or not.
   */
  private aicByPassMode: boolean = false;

  /**
   * Flag indicated ArtistRadio/Seeded is in bypass mode or not
   */
  private artistRadioByPassMode: boolean = false;

  /**
   * Flag indicated Tune call started or completed
   */
  private newTuneStarted = false;

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(RefreshTracksService, RefreshTracksService, [
      RefreshTracksDelegate,
      ConfigService,
      BypassMonitorService,
      AppMonitorService,
    ]);
  })();

  /**
   * Constructor
   * @param {RefreshTracksDelegate} refreshTracksDelegate is used to send requests to the API
   * @param {ConfigService} configService is used to get configuration settings for the app
   * @param {BypassMonitorService} bypassMonitorService is used to detect API bypass modes
   * @param {AppMonitorService} appMonitorService is used to ignore api 315 errors on seeded refresh track calls
   */
  constructor(
    private refreshTracksDelegate: RefreshTracksDelegate,
    private configService: ConfigService,
    private bypassMonitorService: BypassMonitorService,
    private appMonitorService: AppMonitorService,
  ) {
    // Tell app monitor service Ignore FLTT_SR_SOURCE_ENDED, content errors for seeded refresh track calls (only).
    // These will be retried until they succeed
    appMonitorService.ignoreFault(
      ApiCodes.FLTT_SR_SOURCE_ENDED,
      ServiceEndpointConstants.endpoints.SEEDED_RADIO.V4_REFRESH,
    );
    this.refreshTrackListSubject = new BehaviorSubject(
      this.refreshedTrackListData,
    );
    this.mediaTimeLine = this.refreshTrackListSubject;
    this.bypassMonitorService.bypassErrorState.subscribe(
      (state: IAppByPassState) => {
        this.artistRadioByPassMode = state.ARTIST_RADIO_BYPASS;
        this.aicByPassMode = state.AIC_BYPASS;
      },
    );
  }

  /**
   * Used to refresh track list for additional internet channels
   *
   * @param trackList : current track list that client is playing from
   * @param refreshTracksPayload : metadata about what is playing in order to make the refresh tracks call
   */
  public refreshTracksAIC(
    trackList: MultiTrackList,
    refreshTracksPayload: IRefreshTracksRequestPayload,
  ): Observable<number> {
    if (this.aicByPassMode) {
      return;
    }

    const payload: any = {
      sequencerSessionId: refreshTracksPayload.sequencerSessionId,
      tracksParam: this.prepareTracksParam(trackList.toArray()),
      channelGUID: trackList.getChannelIdentifier(),
      playerType: refreshTracksPayload.playerType,
      contentType: refreshTracksPayload.mediaType,
    };

    const relativeUrls: Array<IRelativeUrlSetting> = this.configService.getRelativeUrlSettings();
    const currentTracks = trackList.toArray();
    const trackIndex =
      currentTracks.length > 0
        ? currentTracks[currentTracks.length - 1].index
        : 0;

    return this.refreshTracksDelegate
      .refreshTracksAIC(payload, relativeUrls, trackIndex)
      .pipe(
        filter(tuneResponse => !!tuneResponse),
        map(tuneResponse => {
          const newMultiTrackList = trackList.appendTracks(
            tuneResponse.channelId,
            tuneResponse.clips.toArray(),
          );

          tuneResponse.clips = newMultiTrackList;
          tuneResponse.cuts = TuneDelegate.normalizeAdditionalChannelCuts(
            newMultiTrackList.toArray(),
            relativeUrls,
          );

          this.refreshedTrackListData = tuneResponse;
          this.refreshTrackListSubject.next(this.refreshedTrackListData);

          return ClientCodes.SUCCESS;
        }),
        catchError(error => {
          RefreshTracksService.logger.error(
            `refreshTracksAIC - Error (${JSON.stringify(error)})`,
          );
          throw error;
        }),
        share(),
      ) as Observable<number>;
  }

  /**
   * Used to refresh track list for seeded/artist/pandora stations
   *
   * @param trackList : current track list that client is playing from
   * @param refreshTracksPayload : metadata about what is playing in order to make the refresh tracks call
   * @param elapsedTime : is reported with track list when call is made
   */
  public refreshTracksSeeded(
    trackList: MultiTrackList,
    refreshTracksPayload: IRefreshTracksRequestPayload,
    elapsedTime: number = 0,
    /*retry: number = 0,*/
  ): Observable<number> {
    if (this.artistRadioByPassMode) {
      return observableOf(ClientCodes.ERROR);
    }

    const payload: IRefreshTracksRequestPayload = {
      stationId: refreshTracksPayload.stationId,
      tracksParam: this.prepareTracksParamSeeded(
        trackList.toArray(),
        elapsedTime,
      ),
      stationFactory: refreshTracksPayload.stationFactory,
      playerType: refreshTracksPayload.playerType,
      isSkipLimitReached: refreshTracksPayload.isSkipLimitReached,
      mediaId: refreshTracksPayload.mediaId,
    };

    if (!payload.stationId || !payload.stationFactory) {
      RefreshTracksService.logger.error(
        `StationId or Station Factory is not having value ${JSON.stringify(
          payload,
        )}`,
      );
      return observableOf(ClientCodes.ERROR);
    }

    const relativeUrls: Array<IRelativeUrlSetting> = this.configService.getRelativeUrlSettings();
    const seededRadioFallbackUrl = this.configService.getSeededRadioBackgroundUrl();

    return this.refreshTracksDelegate.refreshTracksSeeded(payload).pipe(
      filter(response => {
        return !!response;
      }),
      map(response => {
        if (response === ClientCodes.ERROR) {
          return ClientCodes.ERROR;
        }

        const mediaData = _.get(
          response,
          ApiLayerTypes.RESPONSE_TYPE_SEEDED_RADIO,
        ) as any;

        mediaData.mediaType = response.moduleType.toLowerCase();

        const tuneResponse = TuneDelegate.normalizeSeededRadioData(
          mediaData,
          new TuneResponse(),
          relativeUrls,
          seededRadioFallbackUrl,
        );

        const channelIdentifier = tuneResponse.channel
          ? tuneResponse.channel.stationFactory
          : tuneResponse.mediaId;

        const newMultiTrackList = trackList.appendTracks(
          channelIdentifier,
          tuneResponse.clips.toArray(),
        );

        tuneResponse.clips = newMultiTrackList;
        tuneResponse.cuts = TuneDelegate.normalizeAdditionalChannelCuts(
          newMultiTrackList.toArray(),
          relativeUrls,
        );

        this.refreshedTrackListData = tuneResponse;
        this.refreshTrackListSubject.next(this.refreshedTrackListData);

        return ClientCodes.SUCCESS;
      }),
      catchError(error => {
        RefreshTracksService.logger.error(
          `Error ${error} encountered on seeded refresh tracks`,
        );
        throw error;
      }),
      share(),
    ) as Observable<number>;
  }

  /**
   * @param {MultiTrackList} trackList
   * @param {MediaTimeLine} mediaTimeLine
   */
  public preRefreshTracks(
    trackList: MultiTrackList,
    mediaTimeLine: MediaTimeLine,
    elapsedTime?: number,
  ) {
    let result: Observable<any>;

    this.refreshingTracks$.next(true);

    const payload: IRefreshTracksRequestPayload = {
      sequencerSessionId: mediaTimeLine.sequencerSessionId,
      mediaType: mediaTimeLine.mediaType,
      stationId: mediaTimeLine.stationId,
      stationFactory: mediaTimeLine.channel.stationFactory,
      playerType: mediaTimeLine.playerType,
      isSkipLimitReached: false,
      mediaId: mediaTimeLine.mediaId,
    };

    if (MediaUtil.isSeededRadioMediaType(mediaTimeLine.mediaType)) {
      result = this.refreshTracksSeeded(trackList, payload, elapsedTime);
    } else {
      if (trackList) {
        result = this.refreshTracksAIC(trackList, payload);
      } else {
        RefreshTracksService.logger.error('Track listing is undefined.');
        return observableOf(ClientCodes.ERROR);
      }
    }

    result
      .pipe(
        finalize(() => {
          this.refreshingTracks$.next(false);
        }),
      )
      .subscribe();
  }

  /**
   * Sets the flag to be true when tune call initiated and sets false once call is done
   * @param tuneStatusFlag
   */
  public setTuneInProgress(tuneStatusFlag: boolean) {
    this.newTuneStarted = tuneStatusFlag;
  }

  /**
   * Translates the track statuses to strings used
   * in the tracksParam in the refresh tracks call.
   * @param tracks
   */
  private prepareTracksParam(tracks: IClip[]): Array<any> {
    return tracks.map((track: IClip) => {
      const param = {};
      param['assetGuid'] = track.assetGUID;
      switch (track.status) {
        case MultiTrackConstants.PRELOAD:
        case MultiTrackConstants.FUTURE:
          param['playerEventType'] = MultiTrackConstants.PLAYLIST;
          break;

        case MultiTrackConstants.CURRENT:
        case MultiTrackConstants.ENDED:
          param['playerEventType'] = MultiTrackConstants.CONSUME;
          break;
        default:
          param['playerEventType'] = track.status;
          break;
      }

      return param;
    });
  }

  private prepareTracksParamSeeded(tracks: IClip[], elapsedTime?: number) {
    return tracks.map((track: IClip) => {
      const param = {};
      param['index'] = track.index || 0;

      switch (track.status) {
        case MultiTrackConstants.PRELOAD:
        case MultiTrackConstants.FUTURE:
          param['playerEventType'] = MultiTrackConstants.PLAYLIST;
          param['elapsedTime'] = 0;
          break;

        case MultiTrackConstants.CURRENT:
          param['playerEventType'] = MultiTrackConstants.STARTED;
          param['elapsedTime'] = 0;
          break;

        case MultiTrackConstants.ENDED:
          param['elapsedTime'] = Math.floor(track.duration);
          param['playerEventType'] = MultiTrackConstants.ENDED;
          break;

        case MultiTrackConstants.SKIP:
          param['elapsedTime'] = Math.floor(elapsedTime);
          param['playerEventType'] = MultiTrackConstants.SKIP;
          break;

        default:
          param['playerEventType'] = track.status;
          param['elapsedTime'] = 0;
          break;
      }

      return param;
    });
  }

  /**
   * Used to set the new track list after a track played out
   * @param trackId
   * @param mediaTimeLine
   */
  public trackFinished(
    trackId: string,
    mediaTimeLine: MediaTimeLine,
  ): MultiTrackList {
    const multiTrackList = mediaTimeLine.clips.listAfterFinish(trackId);

    this.preRefreshTracks(multiTrackList, mediaTimeLine);

    return multiTrackList;
  }

  /**
   * Used to set the new track list after a track ended up in any error
   * @param trackId
   * @param mediaTimeLine
   */
  public trackFailed(trackId: string, mediaTimeLine: MediaTimeLine): void {
    const multiTrackList = mediaTimeLine.clips
      ? mediaTimeLine.clips.listAfterError(trackId)
      : null;

    this.preRefreshTracks(multiTrackList, mediaTimeLine);
  }

  /**
   * Used to set the new track list after a track skipped
   * @param mediaTimeLine
   */
  public trackSkipped(
    mediaTimeLine: MediaTimeLine,
    elapsedTime?: number,
  ): MultiTrackList {
    const multiTrackList = mediaTimeLine.clips.listAfterSkip();

    this.preRefreshTracks(multiTrackList, mediaTimeLine, elapsedTime);

    return multiTrackList;
  }

  /**
   * Used to set the new track list after a track started and starts playing
   * @param trackId
   * @param mediaTimeLine
   */
  public trackStarted(
    trackId: string,
    mediaTimeLine: MediaTimeLine,
  ): MultiTrackList {
    const multiTrackList = mediaTimeLine.clips.listAfterStarted(trackId);

    if (multiTrackList && multiTrackList.toArray().length === 1) {
      this.preRefreshTracks(multiTrackList, mediaTimeLine);
    }

    return multiTrackList;
  }

  /**
   * Used to set the new trac list after a track resumed .
   * @param mediaTimeLine
   */
  public trackResumed(mediaTimeLine: MediaTimeLine): MultiTrackList {
    const multiTrackList = mediaTimeLine.clips.listAfterResume();

    if (multiTrackList && multiTrackList.toArray().length === 1) {
      this.preRefreshTracks(multiTrackList, mediaTimeLine);
    }

    return multiTrackList;
  }
}
