import { tts } from '@accedo/xdk-extensions-contrib';
import workstation, { ID as WORKSTATION } from '@accedo/xdk-device-workstation';
import vizio, { ID as VIZIO } from '@accedo/xdk-device-vizio-smartcast-contrib';
import { hlsjs } from '@accedo/xdk-players-contrib';
const { html5: html5TtsConfig, vizio: vizioTtsConfig } = tts;

const CONFIG = {
  devices: {
    packages: [
      vizio.removeAllPlayers().addPlayer(hlsjs),
      workstation.removeAllPlayers().addPlayer(hlsjs),
    ],
    details: {
      [VIZIO]: {
        extensions: [vizioTtsConfig],
      },
      [WORKSTATION]: {
        extensions: [html5TtsConfig],
      },
    },
  },
  storages: {
    cookie: {
      name: 'sirius-xm-x1-cloud-cookie',
    },
  },
  logging: {
    level: -1,
  },
};

export default CONFIG;
