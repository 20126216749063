import { combineReducers } from 'redux';
import { channelsReducer } from './reducer/channel-list.reducer';
import { favoriteListReducer } from './reducer/favorite-list.reducer';
import { nowPlayingReducer } from './reducer/now-playing.reducer';
import { recentlyPlayedListReducer } from './reducer/recently-played-list.reducer';
import { apronSegmentReducer } from './reducer/apron-segments.reducer';
import { carouselReducer } from './reducer/carousel.reducer';
import { wallClockReducer } from './reducer/wall-clock.reducer';
import { xdkReducer } from './reducer/xdk.reducer';
import { inactivityTimerReducer } from './reducer/inactivity-timer.reducer';

const rootReducer = combineReducers({
  channelStore: channelsReducer,
  favoriteList: favoriteListReducer,
  nowPlayingStore: nowPlayingReducer,
  recentlyPlayedStore: recentlyPlayedListReducer,
  apronSegmentStore: apronSegmentReducer,
  carouselStore: carouselReducer,
  wallClockStore: wallClockReducer,
  xdkStore: xdkReducer,
  inactivityTimeStore: inactivityTimerReducer,
});

export default rootReducer;
