import { createSelector } from 'reselect';
import { ENowPlayingStatus } from '../../types/now-playing-status.enum';
import { IVideoPlayerData } from '../../types/video-player-data.interface';
import {
  ContentTypes,
  IChannel,
  IDmcaInfo,
  IMediaCut,
  IMediaEpisode,
  IMediaSegment,
  IMediaShow,
  IMediaVideo,
  IOnDemandEpisode,
  IOnDemandShow,
  IPlayhead,
  ISubCategory,
  ISuperCategory,
  IYouJustHeard,
  MediaUtil,
  msToSeconds,
  VideoPlayerConstants,
} from '../../servicelib';

export interface INowPlayingStore {
  albumName: string;
  albumImage: string;
  artistName: string;
  artistInfo: string;
  assetType?: string;
  backgroundImage: string;
  backgroundColor: string;
  channel?: IChannel;
  channelLogoAltText?: string;
  nowPlayingStatus?: ENowPlayingStatus;
  channelLogoUrl?: string;
  channelName: string;
  channelNumber: string;
  cut: IMediaCut;
  dmcaInfo: IDmcaInfo;
  episode: IMediaEpisode;
  onDemandEpisode?: IOnDemandEpisode;
  onDemandShow?: IOnDemandShow;
  mediaId: string;
  mediaType: string;
  show: IMediaShow;
  showMiniPlayer: boolean;
  startTime: number;
  subCategory?: ISubCategory;
  superCategory?: ISuperCategory;
  trackName: string;
  type: string;
  video: IMediaVideo;
  videoPlayerData?: IVideoPlayerData;
  youJustHeard: Array<IYouJustHeard>;
  lastPlayheadTimestamp: number;
  playhead: IPlayhead;
  posterImageUrl?: string;
  stationId?: string;
  hideFromChannelList?: false;
  isMediaPlaying?: boolean;
}

/*export const selectNowPlayingState = createFeatureSelector<INowPlayingStore>(
  'nowPlayingStore',
);*/

export const selectNowPlayingState = createSelector(
  state => (state as any).nowPlayingStore,
  nowPlayingStore => nowPlayingStore,
);

export const getVideoPlayerData = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): IVideoPlayerData => state.videoPlayerData,
);

export const getChannelLogoUrl = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): string => state.channelLogoUrl,
);

export const getChannel = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): IChannel => state.channel,
);

export const getPosterImageUrl = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): string => state.posterImageUrl,
);

export const getChannelLogoAltText = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): string => state.channelLogoAltText,
);

export const getAlbumImage = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): string => state.albumImage,
);

export const getAlbumName = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): string => state.albumName,
);

export const getNowPlayingStatus = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): ENowPlayingStatus => state.nowPlayingStatus,
);

export const getTrackName = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): string => state.trackName || '',
);

export const getArtistName = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): string => state.artistName,
);

export const getShow = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): IMediaShow => state.show,
);

export const getShowBackgroundImage = createSelector(
  getShow,
  (show: IMediaShow) => {
    const showBackgroundImage =
      show &&
      show.images &&
      show.images.find(({ height, width }) => {
        //NOTE: For some reason, the API sends this sizes flipped.
        return height === 1920 && width === 1080;
      });
    return (showBackgroundImage && showBackgroundImage.url) || '';
  },
);

export const getEpisode = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): IMediaEpisode => state.episode,
);

export const getEpisodeName = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): string =>
    state.episode ? state.episode.longTitle : '',
);

export const getShowName = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): string => (state.show ? state.show.longTitle : ''),
);

export const getMediaType = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): string => state.mediaType,
);

export const getMediaId = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): string => state.mediaId,
);

export const getDmca = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): IDmcaInfo => state.dmcaInfo,
);

export const getYouJustHeard = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): IYouJustHeard[] => state.youJustHeard,
);

export const getEpisodeSegments = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): IMediaSegment[] =>
    state.episode ? state.episode.segments || [] : [],
);

export const getEpisodeVideoMarkers = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): IMediaVideo[] =>
    state.episode ? state.episode.videos || [] : [],
);

export const getStartTime = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): number =>
    state.cut &&
    state.cut.times &&
    state.playhead &&
    (state.mediaType === ContentTypes.LIVE_VIDEO ||
      state.mediaType === ContentTypes.LIVE_AUDIO)
      ? msToSeconds(
          state.cut.times.zeroStartTime +
            (state.playhead.currentTime.zuluMilliseconds -
              state.cut.times.zuluStartTime),
        ) || 0
      : state.startTime,
);

export const getIsLiveAudio = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): boolean =>
    state.mediaType === ContentTypes.LIVE_AUDIO,
);

export const getIsAudio = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): boolean =>
    state.mediaType === ContentTypes.LIVE_AUDIO ||
    state.mediaType === ContentTypes.AOD,
);

export const getIsLiveVideo = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): boolean =>
    state.mediaType === ContentTypes.LIVE_VIDEO,
);

export const getIsVideo = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): boolean =>
    state.mediaType === ContentTypes.LIVE_VIDEO ||
    state.mediaType === ContentTypes.VOD,
);

export const getIsAod = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): boolean => state.mediaType === ContentTypes.AOD,
);

export const getIsVod = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): boolean => state.mediaType === ContentTypes.VOD,
);

export const getIsOnDemand = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): boolean =>
    state.mediaType === ContentTypes.AOD ||
    state.mediaType === ContentTypes.VOD ||
    state.mediaType === ContentTypes.PODCAST,
);

export const getIsIrisPodcast = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): boolean =>
    state.mediaType === ContentTypes.PODCAST,
);

export const getIsLive = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): boolean =>
    state.mediaType === ContentTypes.LIVE_AUDIO ||
    state.mediaType === ContentTypes.LIVE_VIDEO,
);

export const getIsAIC = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): boolean =>
    state.mediaType === ContentTypes.ADDITIONAL_CHANNELS,
);

export const getIsSeededRadio = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): boolean =>
    state.mediaType === ContentTypes.SEEDED_RADIO,
);

export const getContentType = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): string => state.mediaType,
);

export const getShowMiniPlayer = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): boolean => state.showMiniPlayer,
);

export const getCurrentVideoMarker = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): IMediaVideo => state.video,
);

export const getIsLiveVideoAvailable = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): boolean =>
    !!state.video &&
    state.video.liveVideoStatus === VideoPlayerConstants.LIVE_VIDEO_ON &&
    MediaUtil.isLiveMediaType(state.mediaType),
);

export const getCurrentVideoCutTimestamp = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): number => state.cut.times.zeroStartTime,
);

export const getIsInterstitial = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): boolean =>
    !!state.cut &&
    !MediaUtil.isSongCutContentType(state.cut) &&
    !MediaUtil.isTalkCutContentType(state.cut),
);

export const getLastPlayheadTimestamp = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): number => state.lastPlayheadTimestamp,
);

export const getPlayhead = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): IPlayhead => state.playhead,
);

export const getCut = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): IMediaCut => state.cut,
);

export const getCurrentEpisodeZuluStartTime = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): number => {
    return state.episode &&
      state.episode.times &&
      state.episode.times.zuluStartTime
      ? state.episode.times.zuluStartTime
      : 0;
  },
);

export const getBackgroundColor = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): string => state.backgroundColor,
);

export const getBackgroundImage = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): string => state.backgroundImage,
);

export const getIsMediaPlaying = createSelector(
  selectNowPlayingState,
  (state: INowPlayingStore): boolean => state.isMediaPlaying,
);
