import { IAction } from './action.interface';
import { IGroupedFavorites } from '../../servicelib';

export const FAVORITES_LOADED: string = '[Favorite-List] Favorites loaded';

export const favoritesLoaded = (payload: IGroupedFavorites): IAction => {
  return {
    type: FAVORITES_LOADED,
    payload: payload,
  };
};

// export type FavoriteListAction = FavoritesLoaded;
