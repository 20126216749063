// TODO RW: Make actual tile type alias once the shape of the tile data is known
import {ISubCategory} from "../channellineup";
import { IBaseCarouselResponse, ICarouselTextType, ICarouselViewAllType } from "./carousel.types";

import {
    ITile,
    IRawTileData,
    INeriticLinkData
} from "./raw.tile.interface";
import {IRawFavoriteCarouselResponse} from "../favorite";
import {IRelativeUrlSetting} from "../config";

export class CarouselTypeConstants
{
    public static HERO          = "hero";
    public static CONTENT       = "content";
    public static SEGMENT       = "segment";
    public static CATEGORY_LIST = "categoryList";
}

export type CarouselType = "hero" | "content" | "segment";

export class CarouselPageParameter
{
    paramName?: string;
    paramValue?: any;
}

export interface ISuperCategoryCarousel
{
    type?: CarouselType;
    id?: string;
    header?: string;
    additionalText?: string;
}

export interface ICarouselData
{
    algorithm?: ICarouselAlgorithm;
    tiles?: ITile[];
    type?: CarouselType;
    guid?: string;
    title?: ICarouselTextType;
    carouselOrientation?: CarouselOrientationType;
    carouselViewAll? : ICarouselViewAllType;
    conditions?: IConditionalInfo;
    screen?: string;
    editable?: IEditable;
}

export interface IEditable
{
    clearAll: boolean;
    modifyModuleType: string;
    remove: boolean;
    reorder: boolean;
}

export interface ICarouselAlgorithm
{
    name?: string;
    parameters?: Array<CarouselPageParameter>;
}

export interface SlickElement extends Element
{
    slick?: Function;
}

export interface ICarouselDataByType
{
    category?: ICarouselData;
    selectors?: Array<ICarouselSelector>;
    expirationTime?: number;
    error?: boolean;
    pageLogo?: IPageLogo;
    pageTextLine1?: string;
    pageTextLine2?: string;
    pageTextLine3?: string;
    pageBackground?: IPageLogo;
    pageBackgroundColor?: string;
    pageAssetInfo?: IPageAssetInfo;
    pageAction?: IPageAction;
    pageTitle?: ICarouselTextType;
    screen?: string;
    zone?: Array<IZoneDataByType>;
}

export interface IZoneDataByType
{
    hero: ICarouselData [];
    content: ICarouselData [];
    selectors?: Array<ICarouselSelector>;
    category?: ICarouselData;
    expirationTime?: number;
    zoneClass?: string;
    zoneId: string;
    zoneGuid?: string;
    zoneTitle?: string;
    zoneLink?: string;
    zoneOrder?: number;
    neriticLinkData?: INeriticLinkData;
    subCategories?: ISubCategory[];
}

export interface IPageLogo
{
    imageAltText: string;
    imageClass: string;
    imageLink: string;
}

export interface IPageAssetInfo
{
    connectTwitter: string;
    connectTwitterLink: string;
    connectTwitterIcon: string;
    connectFacebook: string;
    connectFacebookLink: string;
    connectFacebookIcon: string;
    connectEmail: string;
    connectEmailIcon: string;
    connectPhone: string;
    connectPhoneIcon: string;
    channelName: string;
    channelId: string;
    channelGuid: string;
    channelGUID: string;
    showName: string;
    showGUID: string;
    episodeGUID: string;
    showType: string;
    episodeType: string;
    episodeName: string;
    aodEpisodeCaId: string;
    vodEpisodeGUID: string;
    isIrisPodcast: boolean;
    coldStartDefinition: string;
    userDaysListened: string;
}

export interface IPageAction
{
    primaryAction?: string;
    addToFavorites: string;
    setShowReminder?: string;
}

export interface ICarouselResponse
{
    screen?: string;
    carousels?: Array<any>;
}

export interface ICarousel
{
    carouselTitle?: ICarouselTextType;
    carouselType?: "HERO" | "STANDARD";
    carouselGUID?: string;
    carouselTiles?: Array<IRawTileData>;
    carouselTileEntities?: Array<any>;
}

export interface ICarouselSelector
{
    class: string;
    segments: Array<ICarouselSelectorSegment>;
    title: ICarouselTextType;
}

export interface ICarouselMoreSelector
{
    selectorClass: string;
    selectorSegments: Array<ICarouselSelectorSegment>;
}

export interface ICarouselSelectorSegment
{
    carousels: Array<ICarouselData>;
    groupedCarousels: Array<IGroupedCarousel>;
    title: string;
    key?: string;
    action: any;
    class: string;
    neriticLinkData?: INeriticLinkData;
}

export interface IGroupedCarousel
{
    groupName: ICarouselTextType;
    carousel: ICarouselData;
    screen?: string;
}

export interface IConditionalInfo
{
    channelId?: string;
}

export interface CarouselNormalizer
{
    (a: IRawFavoriteCarouselResponse | IBaseCarouselResponse,
     isLiveVideoEnabled?: boolean,
     urlMap?: Array<IRelativeUrlSetting>): ICarouselDataByType;
}

export type CarouselOrientationType = "grid" | "vertical" | "horizontal";

export class CarouselOrientationTypeConsts
{
    public static VIDEO_PLAYBACK = "video-playback";
    public static GRID           = "grid";
    public static VERTICAL       = "vertical";
    public static HORIZONTAL     = "horizontal";
}
