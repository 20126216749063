import { filter } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { BrowserUtil } from './browser.util';

export class PrivateBrowsingUtil {
  /**
   * Utility function for testing whether the browser is in a private or incognito mode.
   * Most of these tests come from info found here:
   * https://stackoverflow.com/questions/2860879/detecting-if-a-browser-is-using-private-browsing-mode
   **/
  public static checkPrivateBrowsingMode = (_window): Observable<boolean> => {
    const isInPrivateMode = new BehaviorSubject(null);

    if (BrowserUtil.isChrome()) {
      const fs = _window.RequestFileSystem || _window.webkitRequestFileSystem;

      if (typeof fs === 'function') {
        fs(_window.TEMPORARY, 100, privateIsOff, privateIsOn);
      } else {
        privateIsOff();
      }
    } else if (BrowserUtil.isFirefox()) {
      if (_window.indexedDB) {
        try {
          const db = _window.indexedDB.open('test_private_browsing');
          db.onerror = privateIsOn;
          db.onsuccess = privateIsOff;
        } catch (e) {
          privateIsOn();
        }
      } else {
        privateIsOff();
      }
    } else if (BrowserUtil.isEdge()) {
      _window.indexedDB ? privateIsOff() : privateIsOn();
    } else {
      privateIsOff();
    }

    return isInPrivateMode.pipe(filter(value => value !== null));

    /**
     * Emit a true bool to tell subscribers the user is in a private browsing mode.
     */
    function privateIsOn() {
      isInPrivateMode.next(true);
    }

    /**
     * Emit a false bool to tell subscribers the user is NOT in a private browsing mode.
     */
    function privateIsOff() {
      isInPrivateMode.next(false);
    }
  };
}
