const WIDTH_HD = 1280;
const HEIGHT_HD = 720;

export const getRelativeVWPixel = (pixels: number): number => {
  if (!pixels) {
    return 0;
  }

  const width = 1280;
  const fullHDContext = WIDTH_HD * 0.01;
  const currentContext = width * 0.01;
  const vwPx = pixels / fullHDContext;

  return Math.round(vwPx * currentContext);
};

export const getRelativeVHPixel = (pixels: number): number => {
  if (!pixels) {
    return 0;
  }

  const height = 720;
  const fullHDContext = HEIGHT_HD * 0.01;
  const currentContext = height * 0.01;
  const vhPx = pixels / fullHDContext;

  return Math.round(vhPx * currentContext);
};

const getPosition = (pixels: number, context: number) => {
  const vwContext = context * 0.01;

  return pixels / vwContext;
};

export const vw = (pixels: number) => {
  return `${getPosition(pixels, 1280)}vw`;
};

export const vh = (pixels: number) => {
  return `${getPosition(pixels, 720)}vh`;
};
