export interface IFreeTierRawField {
    name : string;
    value?: string;
    type?: string;
    fields?: object[];
    actionNeriticLink?: string;
    url?: string;
}

export interface IFreeTierNeriticAction {
    screen: string;
    flowType: EFreeTierFlow;
    videoUrl: string;
    app: string;
}

export interface IFreeTierButton {
    text: string;
    neriticAction: IFreeTierNeriticAction;
    url?: string;
}

export interface IFreeTierScreenInfo {
    screens: IFreeTierScreen[];
}

export interface IFreeTierScreen
{
    id: string;
    fields: IFreeTierRawField[];
}

export interface IFreeTierOverlayInfo
{
    subscribe? : boolean;
    explore? : boolean;
    accessNow? : boolean;
}

export interface IWelcomeScreenInfo {
    logo: string;
    backgroundImg: string;
    carouselImages: string[];
    subScreenName: string;
    buttonOne: IFreeTierButton;
    buttonTwo: IFreeTierButton;
}

export interface IWelcomeSubScreenInfo {
    accessHeader: string;
    accessBtn: IFreeTierButton;
    legalLinks: IFreeTierLink[] | null;
}

export interface IFreeTierLink {
    name: string;
    value: string;
    url: string;
}

export interface IUpsellBannerSubscribeInfo {
    backgroundImg: string;
    line1: string;
    line2: string;
    subscribeBtn: IFreeTierButton;
}

export interface IUpsellScreenInfo {
    header: string;
    description: string;
    accessCopy: string;
    logIn: IFreeTierButton;
    subscribeStreaming: IFreeTierButton;
}

export interface ISubscribeScreenInfo {
    logo: string;
    backgroundImg: string;
    registerNow: string;
    username: string;
    password: string;
    emailError: string;
    ftNotEligibleError: string;
    forgotPassword: IFreeTierLink;
    checkbox: string;
    subScreenName: string;
    submitBtn: IFreeTierButton;
}

export enum EFreeTierFlow  {
    SUBSCRIBE = "subscribe",
    ACCESS_NOW = "accessnow",
    EXPLORE = "explore"
}
