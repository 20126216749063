export class ContentTypes
{
    public static AOD = "aod";
    public static AIC = "aic";
    public static VOD = "vod";
    public static SHOW = "show";
    public static EPISODE = "episode";
    public static LIVE_SHOW = "live-show";
    public static ADDITIONAL_CHANNELS = "additionalchannels"; // Tune uses
    public static ADDITIONAL_CHANNEL = "additionalchannel"; // Tiles uses
    public static CHANNEL = "channel";
    public static LIVE_AUDIO = "live";
    public static MYMIX_AUDIO = "mysxm";
    public static LIVE_VIDEO = "vlive";
    public static OD_SHOW = "odshow";
    public static RE_BROADCAST= "re-broadcast";
    public static SEEDED_RADIO = "seededradio";
    public static CATEGORY = "category";
    public static PODCAST = "podcast";
}

export class PlayerTypes
{
    public static REMOTE = "remote";
    public static LOCAL = "local";
}
