import { ContextualConstants } from "./contextual.constants";
import { ContextualUrlConstants } from "../service/consts/contextual.url.constants";
import { DeepLinkTypes, IContextualLandingAppInfo } from "./contextual.interface";

export class ContextualUtilities
{
    /**
     * USed to get the deviceType.
     * @param   userAgent
     * @returns device type
     */
    public static getDeviceType = (userAgent: string): string =>
    {
        if ((userAgent.match(/FBAV/i) || userAgent.match(/FBAN/i))
            && userAgent.match(/Android/i))
        {
            // We are inside the Android FB app's browser.
            // Attempting to deepLink w/ webDeepLink will cause infinite reload
            // deepLinking w/ mobileDeepLink will cause an Unknown URL error
            // User will need to be redirected to Google Play Store by clicking Listen Now.
            return ContextualConstants.FB_BROWSER_ANDRIOD;
        }
        else if (userAgent.match(/iPad/i) ||
            userAgent.match(/iPhone/i) ||
            userAgent.match(/iPod/i) ||
            userAgent.match(/Android/i)||
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))
        {
            return ContextualConstants.MOBILE;
        }
        else
        {
            return ContextualConstants.DESKTOP;
        }
    }

    /**
     * returns the action button url.
     * @param userAgent
     * @param region
     * @param queryString
     */
    public static getActionButtonUrl = (userAgent: string, region: string, queryString: string, isFrench: boolean): string =>
    {
        switch (ContextualUtilities.getOperatingSystem(userAgent))
        {
            case ContextualConstants.ANDROID:
            {
                return region === ContextualConstants.REGION_US ? ContextualUrlConstants.urls.ANDROID.US : ContextualUrlConstants.urls.ANDROID.CA;
            }
            case ContextualConstants.IOS:
            {
                if(region === ContextualConstants.REGION_US)
                {
                    const params       = ContextualUtilities.getCleanedSearchString(queryString, true);
                    return ContextualConstants.SIGN_UP_URL_USA + params;
                }

                return isFrench ? ContextualConstants.SIGN_UP_URL_CA_FRENCH : ContextualConstants.SIGN_UP_URL_CA_ENGLISH;
            }
            case ContextualConstants.WINDOWS:
            {
                return region === ContextualConstants.REGION_US ? ContextualUrlConstants.urls.WINDOWS.US : ContextualUrlConstants.urls.WINDOWS.CA;
            }
            default:
            {
                return "";
            }
        }
    }

    /**
     * gets the universal app link
     * @param hostName
     * @param protocol
     * @param queryString
     * @param contentType
     * @param channelName
     * @returns {string}
     */
    public static getUniversalLink(hostName: string,
                                   protocol: string,
                                   queryString: string,
                                   contentType: string,
                                   channelName: string): string
    {
        let universalAppLink      = hostName;
        const originalUrlSegments = hostName.split('.');
        const baseUrlSegments     = originalUrlSegments;
        const region              = baseUrlSegments.splice(-1, 1);
        const baseUrl             = baseUrlSegments.join('.');
        let params                = ContextualUtilities.getCleanedSearchString(queryString);

        switch (baseUrl)
        {
            case ContextualConstants.PRIMARY_PLAYER:
                universalAppLink = ContextualConstants.PRIMARY_LANDING + '.' + region;
                break;
            case ContextualConstants.PRIMARY_LANDING:
                universalAppLink = ContextualConstants.PRIMARY_PLAYER + '.' + region;
                break;
            case ContextualConstants.BETA_PLAYER:
                universalAppLink = ContextualConstants.BETA_LANDING + '.' + region;
                break;
            case ContextualConstants.BETA_LANDING:
                universalAppLink = ContextualConstants.BETA_PLAYER + '.' + region;
                break;
            case ContextualConstants.PRIMARY_DEV_PLAYER:
                universalAppLink = ContextualConstants.PRIMARY_DEV_LANDING + '.' + region;
                break;
            case ContextualConstants.PRIMARY_DEV_LANDING:
                universalAppLink = ContextualConstants.PRIMARY_DEV_PLAYER + '.' + region;
                break;
            default:
                //First, if the domain's second piece isn't "mountain"
                if (hostName.indexOf("player") !== -1
                    && hostName.indexOf("mountain") === -1)
                {
                    originalUrlSegments.splice(1, 0, "mountain");
                    universalAppLink = originalUrlSegments.join(".");
                }
                else if (hostName.indexOf("player") !== -1)
                {
                    universalAppLink = hostName.replace("player", "deeplink");
                }
                else if (hostName.indexOf("deeplink") !== -1)
                {
                    universalAppLink = hostName.replace("deeplink", "player");
                }
                break;
        }

        universalAppLink = protocol + "//" + universalAppLink + '/';
        if (baseUrl === ContextualConstants.PRIMARY_DEV_PLAYER || baseUrl === ContextualConstants.PRIMARY_DEV_LANDING)
        {
            universalAppLink += "deepLink.html/";
        }
        if (contentType && channelName)
        {
            universalAppLink += contentType + '/' + channelName;
        }

        if (!params)
        {
            params = "?goToStore=true&";
        }
        else
        {
            params += "&goToStore=true";
        }

        universalAppLink += params;

        return universalAppLink;
    }

    /**
     * gets the search string
     * @param  queryString - gets from deep link
     * @returns {string}
     */
    public static getCleanedSearchString = (queryString: string, clearInvalidValueParms: boolean = false): string =>
    {
        const vars          = queryString.split("&");
        let newSearchParams = [];
        let searchString    = "";

        for (let i = 0; i < vars.length; i++)
        {
            const pair = vars[ i ].split("=");
            if (pair.length === 2 &&
                pair[ 0 ] !== 'contentType' &&
                pair[ 0 ] !== 'channelName' &&
                pair[ 0 ] !== 'goToStore')
            {
                if(clearInvalidValueParms)
                {
                    const isTextBetweenBraces = pair[ 1 ].match(/[^{\}]+(?=})/g);
                    if(!isTextBetweenBraces)
                    {
                        newSearchParams.push(pair.join('='));
                    }
                }
                else
                {
                    newSearchParams.push(pair.join('='));
                }
            }
        }

        if (newSearchParams.length >= 1)
        {
            searchString = '?' + newSearchParams.join('&');
        }

        return searchString;
    }

    /**
     * used to determines the operating system
     * @param  userAgent
     * @returns operating system
     */
    public static getOperatingSystem = (userAgent: string): string =>
    {
        if (userAgent.match(/iPad|iPhone|iPod/i) ||
            (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))
        {
            return ContextualConstants.IOS;
        }
        else if (userAgent.match(/Android/i))
        {
            return ContextualConstants.ANDROID;

        }
        else if (userAgent.match(/Windows/i))
        {
            return ContextualConstants.WINDOWS;
        }
        else
        {
            return ContextualConstants.UNKNOWN;
        }
    }
    /**
     * returns the IOS version of the Apple device.
     * @param {string} userAgent
     * @returns {number}
     */
    public static getIOSVersion = (userAgent: string): number =>
    {
        if (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
        {
            const iosVersionMatch = navigator.userAgent.match(/Version\D(\d+).(\d+)/i);
            return iosVersionMatch && iosVersionMatch.length > 0  && iosVersionMatch[1] ? parseInt(iosVersionMatch[1], 10) : 13;
        }

        if (!userAgent.match(/iPad/i) &&
            !userAgent.match(/iPhone/i) &&
            !userAgent.match(/iPod/i))
        {
            return null;
        }

        const iosMatch = userAgent.match(/OS (\d+)_(\d+)/i);
        if (!iosMatch || !iosMatch.length || !iosMatch[ 1 ])
        {
            return null;
        }

        return parseInt(iosMatch[ 1 ], 10);
    }

    /**
     * gets the store url based on device and region.
     * @param userAgent - used to determine the operating system
     * @param region - region used to get the url
     * @returns store url
     */
    public static getStoreUrl (userAgent: string, region: string): string
    {
        switch (ContextualUtilities.getOperatingSystem(userAgent))
        {
            case ContextualConstants.ANDROID:
            {
                return region === ContextualConstants.REGION_US ? ContextualUrlConstants.urls.ANDROID.US : ContextualUrlConstants.urls.ANDROID.CA;
            }
            case ContextualConstants.IOS:
            {
                return region === ContextualConstants.REGION_US ? ContextualUrlConstants.urls.IOS.US : ContextualUrlConstants.urls.IOS.CA;
            }
            case ContextualConstants.WINDOWS:
            {
                return region === ContextualConstants.REGION_US ? ContextualUrlConstants.urls.WINDOWS.US : ContextualUrlConstants.urls.WINDOWS.CA;
            }
            default:
            {
                return "";
            }
        }
    }

    /**
     * Gets the query string value based on parameter name
     * @param - queryString - string that need to search
     * @param  - parameterName - used to find param name in search string
     * @returns  - parameter value
     */
    public static getQueryParameterValue(queryString: string, parameterName: string): string
    {
        const vars = queryString.split("&");
        for (let i = 0; i < vars.length; i++)
        {
            const pair = vars[ i ].split("=");
            if (pair[ 0 ] === parameterName)
            {
                return pair[ 1 ];
            }
        }

        return "";
    }

    /**
     * Used to get Contextual APP info. Based on operating system returns the App image src, accessibilty label.
     * NOTE: IOS no need to display app info.
     * @param operatingSystem
     * @returns {IContextualLandingAppInfo}
     */
    public static getContextualAppInfo(operatingSystem: string): IContextualLandingAppInfo
    {
        switch (operatingSystem)
        {
            case ContextualConstants.IOS:
            {
                return {
                    appImageSrc: "../assets/images/appstore-app-icon.svg",
                    gtmId: "downloadApp-iOS",
                    accessibiltyLabel: "landing.accessibility.appleStore"
                };
            }
            case ContextualConstants.ANDROID:
            {
                return {
                    appImageSrc      : "../assets/images/playstore-app-icon.svg",
                    gtmId            : "downloadApp-Android",
                    accessibiltyLabel: "landing.accessibility.googlePlayStore"
                };
            }
            case ContextualConstants.WINDOWS:
            {
                return {
                    appImageSrc      : "../assets/images/win-10-app-icon.svg",
                    gtmId            : "downloadApp-Windows",
                    accessibiltyLabel: "landing.accessibility.windowsStore"
                };
            }
            default:
            {
                return {} as IContextualLandingAppInfo;
            }
        }
    }

    /**
     * Determines if the app is running on a dev server.
     * @param {string} url
     * @returns {boolean}
     */
    public static isDevServer (url: string = ""): boolean
    {
        url = url || "";
        return ContextualUtilities.isDevIntServer(url) || ContextualUtilities.isLocalDevServer(url);
    }

    /**
     * Determines if the app is running on a SXM dev server.
     * @param {string} url
     * @returns {boolean}
     */
    public static isDevQaServer(url: string = ""): boolean
    {
        url = url || "";
        return !ContextualUtilities.isProdServer(url) && url.indexOf("companybeta.siriusxm") === -1;
    }

    /**
     * Determines if the app is running on a SXM dev server.
     * @param {string} url
     * @returns {boolean}
     */
    public static isDevIntServer(url: string = ""): boolean
    {
        url = url || "";
        return !ContextualUtilities.isProdServer(url) && url.indexOf("companybeta.siriusxm") === -1;
    }

    /**
     * Determines if the app is not running on a SXM company beta server.
     * @param {string} url
     * @returns {boolean}
     */
    public static isCompanyBetaServer(url: string = "") : boolean
    {
        url = url || "";
        return url.indexOf("companybeta.siriusxm") > -1;
    }

    /**
     * Determines if the app is not running on a SXM prod server.
     * @param {string} url
     * @returns {boolean}
     */
    public static isProdServer(url: string = ""): boolean
    {
        url = url || "";
        return url.indexOf("player.siriusxm") > -1
            && url.indexOf("playerbeta.siriusxm") > -1;
    }

    /**
     * Determines if the app is running on a local dev server.
     * @param {string} url
     * @returns {boolean}
     */
    public static isLocalDevServer (url: string = ""): boolean
    {
        url = url || "";
        return url.indexOf("local-video") !== -1;
    }

    /**
     * Returns content type.
     * @param contentType
     */
    public static getContentType(contentType: string) : string
    {
        if(contentType === DeepLinkTypes.PANDORA) { return DeepLinkTypes.SEEDED_RADIO; }
        return contentType;
    }
}
