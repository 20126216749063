export type INavMap<Id extends string, PartialNav> = {
  [k in Id]: Partial<XDKNav> &
    PartialNav & {
      id: k;
    };
};

type DirectionalNav = <Id extends string, PartialNav extends Partial<XDKNav>>(
  list: Id[],
  nav: PartialNav,
  loop?: boolean,
) => INavMap<Id, PartialNav>;

const getLinearNav = (
  list: string[],
  nav: Partial<XDKNav>,
  loop: boolean,
  isHorizontal: boolean,
) =>
  list.reduce<INavMap<string, any>>((navMap, navId, i) => {
    const isFirst = i === 0;
    const isLast = i === list.length - 1;
    const nextIndex = isLast ? 0 : i + 1;
    const prevIndex = isFirst ? list.length - 1 : i - 1;

    return {
      ...navMap,
      [navId]: {
        id: navId,
        ...((loop || !isFirst) && {
          [isHorizontal ? 'nextleft' : 'nextup']: list[prevIndex],
        }),
        ...((loop || !isLast) && {
          [isHorizontal ? 'nextright' : 'nextdown']: list[nextIndex],
        }),
        ...nav,
      },
    };
  }, {});

export const getHorizontalNav: DirectionalNav = (list, nav, loop = false) =>
  getLinearNav(list, nav, loop, true);

export const getVerticalNav: DirectionalNav = (list, nav, loop = false) =>
  getLinearNav(list, nav, loop, false);
