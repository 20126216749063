import { of as observableOf, Observable, BehaviorSubject } from 'rxjs';
import { take, map, filter, share, mergeMap, catchError } from 'rxjs/operators';
import * as _ from 'lodash';

import { ConfigDelegate } from './config.delegate';
import {
  IConfig,
  IRelativeUrlConfig,
  IConsumeSettings,
} from './interfaces/config.interface';

import {
  IProviderDescriptor,
  addProvider,
  getCurrentUrlQueryParams,
  Logger,
  ASSET_NAME_CONST,
  IRelativeUrlSetting,
  ApiCodes,
} from '../index';
import { OpenAccessConfigFinder } from './configuration-finders/open-access-config-finder';
import { OpenAccessCopyName } from './interfaces/open-access-config.interface';
import { ConfigFinderService } from './configuration-finders/config-finder.service';
import { UrlConfigFinder } from './configuration-finders/url-config-finder';
import { ConfigConstants } from './config.constants';
import { AssetLoaderService } from '../asset-loader/asset-loader.service';
import { OnBoardingSettings } from './interfaces/onboarding-configuration';
import { IAppConfig } from './interfaces/app-config.interface';
import { AppDownloadUrls } from './interfaces/app-download-urls.interface';
import { IComponentConfig } from './interfaces/config.interface';
import { relativeUrlToAbsoluteUrl } from '../util/utilities';

/**
 * @MODULE:     service-lib
 * @CREATED:    07/11/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * Use a delegate to make an HTTP API call and the stores the data on itself as a service model.
 */
export class ConfigService {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('ConfigService');

  /**
   * subject for delivering configuration through the configuration observable.
   * @type {any}
   */
  public configurationSubject: BehaviorSubject<IConfig> = null;

  /**
   * subject for delivering relativeUrlConfiguration through the relativeUrlConfiguration observable.
   * @type {any}
   */
  private relativeUrlConfigurationSubject: BehaviorSubject<
    IRelativeUrlConfig
  > = null;

  /**
   * Reference to the configuration so other components can leverage it.
   */
  public configurationData: IConfig = null;

  /**
   * The relativeUrlConfiguration that represents the relative url information extracted from configurationData.
   * @type {any}
   */
  private relativeUrlConfigurationData: IRelativeUrlConfig = null;

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the configuration
   * and be notified when the configuration data changes.
   * @type {any}
   */
  public configuration: Observable<IConfig>;

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the relativeUrlConfiguration
   * and be notified when the relativeUrlConfiguration data changes.
   * @type {any}
   */
  public relativeUrlConfiguration: Observable<IRelativeUrlConfig>;

  /**
   * Indicates to use Consume V4 and V2 request.
   */
  public consumeConfiguration: IConsumeSettings = {};

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(ConfigService, ConfigService, [
      ConfigDelegate,
      AssetLoaderService,
      'IAppConfig',
    ]);
  })();

  /**
   * Constructor.
   * @param configDelegate - The delegate that makes the get config HTTP API request.
   * @param assetLoaderService - Used for loading external assets for adobe tag manager functions
   * @param SERVICE_CONFIG - configuration data from the client on how the service ius configured
   */
  constructor(
    private configDelegate: ConfigDelegate,
    private assetLoaderService: AssetLoaderService,
    private SERVICE_CONFIG: IAppConfig,
  ) {
    this.configurationSubject = new BehaviorSubject(this.configurationData);
    this.configuration = this.configurationSubject;

    this.relativeUrlConfigurationSubject = new BehaviorSubject(
      this.relativeUrlConfigurationData,
    );
    this.relativeUrlConfiguration = this.relativeUrlConfigurationSubject.pipe(
      share(),
    );
  }

  /**
   * Method will call the delegate class to retrieve the application configuration,
   * and store the response in model.
   *
   * @returns {Observable<IConfig>}
   */
  public getConfig(): Observable<boolean> {
    ConfigService.logger.debug(`getConfig()`);

    let config = this.configDelegate.getConfig();

    config.subscribe(
      onConfigurationSuccess.bind(this),
      onConfigurationFault.bind(this),
    );

    //TODO - Can we clean this up, and make it more succinct.
    return config.pipe(
      mergeMap((response: IConfig) => {
        if (!response.components) {
          return observableOf(false);
        }

        return observableOf(true);
      }),
      catchError(() => observableOf(false)),
      share(),
    );

    /**
     * On the configuration response available , save it to configuration data.
     * @param response - returned by the delegate.
     * @returns {any}
     */
    function onConfigurationSuccess(response: IConfig) {
      if (response.components) {
        ConfigService.logger.debug(
          `getConfigResult( Contains ${response.components.length} components. )`,
        );
        this.configurationData = response;
        this.normalizeRelativeUrlConfig(response);
        this.setConsumeSettings();
        this.setFreeTierConfig();
        this.configurationSubject.next(this.configurationData);
      }
    }

    /**
     * On the delegate throws exception then fault handler get called. and propagtes error back to client.
     * @param error - returned by the delegate.
     */
    function onConfigurationFault(error: any) {
      ConfigService.logger.error(
        `getConfig - Error (${JSON.stringify(error)})`,
      );
    }
  }

  /**
   * Returns the now playing background image url for the seeded radio.
   */
  public getSeededRadioBackgroundUrl(): string {
    const seededRadioBackgroundSettings = ConfigFinderService.findConfigurationComponent(
      this.configurationData.components,
      ConfigConstants.SEEDED_RADIO_BACKGROUND_URL,
    );
    const seededRadioBackgroundSetting =
      seededRadioBackgroundSettings && seededRadioBackgroundSettings.settings
        ? seededRadioBackgroundSettings.settings.find(
            setting => setting.platform === 'WEB',
          )
        : '';
    return _.get(seededRadioBackgroundSetting, 'urlSettings[0].value', '');
  }

  /**
   * Returns the now playing fallback background image url for the seeded radio.
   */
  public getSeededRadioFallbackUrl(defaultValue: string = ''): string {
    const seededRadioBackgroundSettings = ConfigFinderService.findConfigurationComponent(
      this.configurationData.components,
      ConfigConstants.SEEDED_RADIO_FALLBACK_URL,
    );
    let seededRadioBackgroundSetting =
      seededRadioBackgroundSettings && seededRadioBackgroundSettings.settings
        ? seededRadioBackgroundSettings.settings.find(
            setting => setting.platform === 'WEB',
          )
        : '';
    if (
      !seededRadioBackgroundSetting &&
      seededRadioBackgroundSettings.settings.length > 0
    ) {
      seededRadioBackgroundSetting = seededRadioBackgroundSettings.settings[0];
    }
    let imageUrl = _.get(
      seededRadioBackgroundSetting,
      'urlSettings[0].value',
      defaultValue,
    );
    return relativeUrlToAbsoluteUrl(
      imageUrl,
      this.relativeUrlConfigurationData.settings,
    );
  }

  /**
   * Used to normalize response.settings for relativeUrls into relativeUrlConfigurationData
   * @param {IConfig} response
   */
  private normalizeRelativeUrlConfig(response: IConfig) {
    let relativeUrlConfig = _.find(response.components, {
      name: 'relativeUrls',
    }) as any;
    if (!relativeUrlConfig) {
      return;
    }
    relativeUrlConfig.settings = relativeUrlConfig.settings[0].relativeUrls;
    delete relativeUrlConfig.name;

    this.relativeUrlConfigurationData = relativeUrlConfig;
    this.relativeUrlConfigurationSubject.next(
      this.relativeUrlConfigurationData,
    );
  }

  /**
   * Get the various settings that are related to the onboarding links and flags needed for the various login
   * and welcome screens
   *
   * @param {string} language is the language of the setting we are looking for
   * @returns {OnBoardingSettings}
   */
  public getOnBoardingSettings(language: string): OnBoardingSettings {
    // This is to support Desktop-Windows platform, if we are running in Desktop build (isNative =true),
    // we will use the windows platform config for OnBoarding setting.

    const platform = this.SERVICE_CONFIG.deviceInfo.platform.toLowerCase();

    const settings = {} as OnBoardingSettings;
    language = language.toLowerCase();

    settings.authUrl = this.getUrlConfiguration(
      ConfigConstants.AUTH_URL,
      ConfigConstants.URL,
    );
    settings.customerAgreementUrl = this.getLocalizedUrlConfiguration(
      ConfigConstants.CUSTOMER_AGREEMENT,
      ConfigConstants.URL,
      language,
    );
    settings.faqUrl = this.getLocalizedUrlConfiguration(
      ConfigConstants.FAQ,
      ConfigConstants.URL,
      language,
    );
    settings.feedBackUrl = this.getLocalizedUrlConfiguration(
      ConfigConstants.FEEDBACK,
      ConfigConstants.URL,
      language,
    );
    settings.getStartedUrl = this.getLocalizedUrlConfiguration(
      ConfigConstants.ON_BOARDING,
      ConfigConstants.FLEPZ,
      language,
    );
    settings.passwordRecoveryUrl = this.getLocalizedUrlConfiguration(
      ConfigConstants.ON_BOARDING,
      ConfigConstants.RECOVERY,
      language,
    );
    settings.privacyPolicyUrl = this.getLocalizedUrlConfiguration(
      ConfigConstants.PRIVACY_POLICY,
      ConfigConstants.URL,
      language,
    );

    const forgotLinkSetting = ConfigFinderService.findConfigurationComponentSetting(
      this.configurationData.components,
      ConfigConstants.FORGOT_LINK,
      platform,
    );

    settings.forgotLinkEnabled =
      forgotLinkSetting && forgotLinkSetting.enableForgotLink
        ? forgotLinkSetting.enableForgotLink
        : false;

    const onBoardingSetting = ConfigFinderService.findConfigurationComponentSetting(
      this.configurationData.components,
      ConfigConstants.ON_BOARDING,
      platform,
      language,
    );

    settings.onboardingEnabled = !!(
      onBoardingSetting && onBoardingSetting.disableOnboarding === false
    );
    //TODO vpaindla - hard coded because api is not ready -- US ONLY - API-24044
    let endpoint = this.SERVICE_CONFIG.apiEndpoint.toString();
    settings.dataPrivacyUrl = endpoint.includes('.com')
      ? 'https://www.siriusxm.com/ccparequest_DoNotSellMyInfo'
      : '';

    return settings;
  }

  /**
   * Get the urls for the mobile and native apps for display on the login and welcome screens
   *
   * @returns {AppDownloadUrls}
   */
  public getAppDownloadUrls(): AppDownloadUrls {
    const appDownloadUrls = {} as AppDownloadUrls;

    appDownloadUrls.apple = this.getAppDownloadUrlConfiguration(
      ConfigConstants.APP_IOS,
    );
    appDownloadUrls.android = this.getAppDownloadUrlConfiguration(
      ConfigConstants.APP_ANDROID,
    );
    appDownloadUrls.windows = this.getAppDownloadUrlConfiguration(
      ConfigConstants.APP_WINDOWS,
    );

    return appDownloadUrls;
  }

  public getOpenAccessCopy(copyName: OpenAccessCopyName): string {
    return OpenAccessConfigFinder.getCopy(
      this.configurationData.components,
      copyName,
    );
  }

  public getUrlConfiguration(
    componentName: string,
    settingName: string,
  ): string {
    return UrlConfigFinder.getUrl(
      this.configurationData.components,
      componentName,
      settingName,
      null,
    );
  }

  public getLocalizedUrlConfiguration(
    componentName: string,
    settingName: string,
    locale: string = 'en',
  ): string {
    return UrlConfigFinder.getUrl(
      this.configurationData.components,
      componentName,
      settingName,
      { name: ConfigConstants.LOCALE, match: locale },
    );
  }

  public getAppDownloadUrlConfiguration(platform: string): string {
    return UrlConfigFinder.getUrl(
      this.configurationData.components,
      ConfigConstants.APP_DOWNLOAD_URL,
      ConfigConstants.APP_DOWNLOAD_URL_NAME,
      { name: ConfigConstants.PLATFORM, match: platform },
    );
  }

  public getStringConfiguration(
    componentName: string,
    settingName: string,
  ): string {
    return UrlConfigFinder.getString(
      this.configurationData.components,
      componentName,
      settingName,
    );
  }

  public liveVideoEnabled(): boolean {
    /*
        const params: any =  getCurrentUrlQueryParams();
        const enableLiveVideo: boolean = params && params.enableLiveVideo === "true" ? true : false;

        const liveVideoConfiguration = ConfigFinderService.findConfigurationComponent(this.configurationData.components,
            ConfigConstants.LIVE_VIDEO);
        const liveVideoSettings = liveVideoConfiguration && liveVideoConfiguration.settings? liveVideoConfiguration.settings : [];
        const liveVideoEnabledSetting = liveVideoSettings.filter(setting => setting["enableLiveVideo"] !== undefined);
        if(liveVideoEnabledSetting.length !== 1)
        {
            ConfigService.logger.error("'enableLiveVideo' setting is missing in config response, disabling live video");
            return false;
        }
        return liveVideoEnabledSetting[0].enableLiveVideo || enableLiveVideo;
        */

    return true;
  }

  public getRelativeURL(urlKey: string): string {
    let setting: any;
    if (
      this.relativeUrlConfigurationData &&
      this.relativeUrlConfigurationData.settings
    ) {
      setting = this.relativeUrlConfigurationData.settings.find(
        setting => setting.name === urlKey,
      );
    }
    return setting ? setting.url : '';
  }

  public getRelativeUrlSettings(): Array<IRelativeUrlSetting> {
    return this.relativeUrlConfigurationData &&
      this.relativeUrlConfigurationData.settings
      ? this.relativeUrlConfigurationData.settings
      : [];
  }

  public getAutoplayNextEpisodeDelay(): number {
    const delayAutoplayNextExpisodeConfig = ConfigFinderService.findConfigurationComponent(
      this.configurationData.components,
      ConfigConstants.AUTOPLAY_DELAY,
    );

    const delay: number | null = _.get(
      delayAutoplayNextExpisodeConfig.settings,
      '[0].intSetting.value',
      null,
    );

    if (delay === null) {
      ConfigService.logger.error(
        'delayAutoplayNextEpisode not found or value in unexpected location, defaulting to 10',
      );
      return 10;
    } else {
      return delay;
    }
  }

  public nuDetect$(): Observable<IComponentConfig> {
    return this.configurationSubject.pipe(
      filter(configurationData => !!configurationData),
      map(configurationData => {
        let nuDetect = ConfigFinderService.findConfigurationComponent(
          configurationData.components,
          ConfigConstants.NU_DETECT,
        );

        if (!nuDetect) {
          nuDetect = { settings: [] } as IComponentConfig;
        }

        return nuDetect;
      }),
      take(1),
    );
  }

  /**
   * Return consume settings from configuration as a observable.
   */
  public setConsumeSettings() {
    let consumeSettings = ConfigFinderService.findConfigurationComponent(
      this.configurationData.components,
      ConfigConstants.CONSUME,
    );

    this.consumeConfiguration =
      consumeSettings && consumeSettings.settings[0]
        ? consumeSettings.settings[0]
        : {};
  }

  /**
   * Returns FreeTier Enable status
   */
  private setFreeTierConfig() {
    /**
     *  Intial FreeTierEnable value is specific to Region
     *  Updating FreeTierEnable with config if region allows.
     */
    if (this.SERVICE_CONFIG.isFreeTierEnable) {
      const consumeSettings = ConfigFinderService.findConfigurationComponent(
        this.configurationData.components,
        ConfigConstants.FREE_TIER_FEATURE,
      );

      this.SERVICE_CONFIG.isFreeTierEnable =
        consumeSettings && consumeSettings.settings[0]
          ? consumeSettings.settings[0].availability
          : false;
      if (this.SERVICE_CONFIG.isFreeTierEnable) {
        this.SERVICE_CONFIG.deviceInfo.clientCapabilities.push(
          'in-app-purchase',
        );
      }
    }
  }
}
