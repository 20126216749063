import {
  ServiceFactory,
  FavoriteService,
  CarouselTypeConst,
  FavoriteUpdateItem,
  FavoriteContentTypes,
  FavoriteChangeTypes,
  FavoriteAssetTypes,
  FavoriteContentType,
  FavoriteAssetType,
  FavoriteChangeType,
} from '../servicelib';

export const useFavorite = () => {
  const favoriteService = ServiceFactory.getInstance(
    FavoriteService,
  ) as FavoriteService;

  const addChannelToFavorites = (
    channel,
    show,
    isFavorite,
    isSeededRadio,
    isAIC,
  ) => {
    let tileContentSubType = CarouselTypeConst.CHANNEL_TILE;

    if (isSeededRadio) {
      tileContentSubType = CarouselTypeConst.SEEDED_RADIO;
    } else if (isAIC) {
      tileContentSubType = CarouselTypeConst.ADDITIONAL_CHANNEL;
    }

    const favoriteUpdateItem = createFavoriteUpdateItem(
      isFavorite ? FavoriteChangeTypes.DELETE : FavoriteChangeTypes.ADD,
      CarouselTypeConst.CHANNEL_TILE,
      tileContentSubType,
      channel.channelId,
      channel.channelGuid,
      show.isIrisPodcast ? show.isIrisPodcast : false,
    );

    favoriteService.updateFavorite(favoriteUpdateItem);
  };

  const addShowToFavorites = (show, isFavorite) => {
    const favoriteUpdateItem = createFavoriteUpdateItem(
      isFavorite ? FavoriteChangeTypes.DELETE : FavoriteChangeTypes.ADD,
      CarouselTypeConst.SHOW_TILE,
      CarouselTypeConst.SHOW_TILE,
      show.channelId,
      show.assetGUID,
      show.isIrisPodcast ? show.isIrisPodcast : false,
    );

    favoriteService.updateFavorite(favoriteUpdateItem);
  };

  const addEpisodeToFavorites = (episode, show, isAod, isFavorite) => {
    const channelId = episode && episode.show ? episode.show.channelId : '';
    const assetGuid = determineEpisodeGuid(episode, show, isAod);

    const subContentType = isAod
      ? CarouselTypeConst.AOD_EPISODE_TILE
      : CarouselTypeConst.VOD_EPISODE_TILE;

    const favoriteUpdateItem = createFavoriteUpdateItem(
      isFavorite ? FavoriteChangeTypes.DELETE : FavoriteChangeTypes.ADD,
      CarouselTypeConst.EPISODE_TILE, //asset.contentType,
      subContentType, //asset.subContentType,
      channelId, //asset.channelId,
      assetGuid, //asset.assetGuid,
      show.isIrisPodcast ? show.isIrisPodcast : false,
    );

    favoriteService.updateFavorite(favoriteUpdateItem);
  };

  const createFavoriteUpdateItem = (
    changeType: string,
    tileContentType: string,
    tileContentSubType: string,
    channelId: string,
    assetGuid: string,
    isIrisPodcast: boolean,
    tabSortOrder?: number,
  ): FavoriteUpdateItem => {
    let contentType;
    let assetType;

    switch (tileContentType) {
      case CarouselTypeConst.CHANNEL_TILE:
        contentType = FavoriteContentTypes.LIVE;
        assetType = FavoriteAssetTypes.CHANNEL;

        //For Additional Channel Tile
        if (tileContentSubType === CarouselTypeConst.ADDITIONAL_CHANNEL) {
          contentType = FavoriteContentTypes.ADDITIONAL_CHANNEL;
          assetType = FavoriteAssetTypes.CHANNEL;
        }
        if (tileContentSubType === CarouselTypeConst.SEEDED_RADIO) {
          contentType = FavoriteContentTypes.SEEDED_RADIO;
          assetType = FavoriteAssetTypes.CHANNEL;
        }
        break;
      case CarouselTypeConst.SHOW_TILE:
      case CarouselTypeConst.LIVE_SHOW_TILE:
        contentType = isIrisPodcast
          ? FavoriteContentTypes.SHOW_PODCAST
          : FavoriteContentTypes.SHOW;
        assetType = FavoriteAssetTypes.SHOW;
        break;
      case CarouselTypeConst.EPISODE_TILE:
        contentType = isIrisPodcast
          ? FavoriteContentTypes.PODCAST
          : tileContentSubType === CarouselTypeConst.AOD_EPISODE_TILE
          ? FavoriteContentTypes.AOD
          : FavoriteContentTypes.VOD;
        assetType = FavoriteAssetTypes.EPISODE;

        break;
    }

    return new FavoriteUpdateItem(
      channelId,
      contentType as FavoriteContentType,
      assetType as FavoriteAssetType,
      changeType as FavoriteChangeType,
      assetGuid,
      tabSortOrder,
    );
  };

  const determineEpisodeGuid = (episode, show, isAod) => {
    const accessControlIdentifer =
      (episode &&
        episode.publicationInfo &&
        episode.publicationInfo.accessControlIdentifier) ||
      '';
    const vodEpisodeGuid = episode ? episode.vodEpisodeGUID : '';
    const podcastEpisodeGuid = episode ? episode.episodeGUID : '';

    let assetGuid = isAod ? accessControlIdentifer : vodEpisodeGuid;
    assetGuid = show && show.isIrisPodcast ? podcastEpisodeGuid : assetGuid;

    return assetGuid;
  };

  return {
    addChannelToFavorites,
    addShowToFavorites,
    addEpisodeToFavorites,
    determineEpisodeGuid,
  };
};
