import { createSelector } from 'reselect';
import {
  ICarouselData,
  ICarouselDataByType,
  IGroupedCarousel,
} from '../../servicelib';
import { carouselNameConstants } from '../../consts/carousel.const';

export interface ICarouselStore {
  superCategoryCarousel: ICarouselDataByType;
  subCategoryCarousel: ICarouselDataByType;
  nowPlayingCarousel: ICarouselDataByType;
  nowPlayingUpNextCarousel: ICarouselDataByType;
  searchCarousel: ICarouselDataByType;
  searchLandingCarousels: ICarouselDataByType;
  profileCarousel: ICarouselDataByType;
  selectedContentCarousel: ICarouselData;
  allOnDemandCarousel: ICarouselDataByType;
  allVideoCarousel: ICarouselDataByType;
  allChannelsCarousel: ICarouselDataByType;
  episodeCarousel: ICarouselDataByType;
  showCarousel: ICarouselDataByType;
  recentsCarousel: ICarouselDataByType;
  showReminderCarousel: ICarouselDataByType;
  viewAllCarousel: ICarouselDataByType;
  filterdShowsCarousel: Array<IGroupedCarousel>;
  collectionCarousel: ICarouselDataByType;
  enhancedEdpCarousel: ICarouselDataByType;
  seededStationsCarousel: ICarouselDataByType;
  allPodCastCarousel: ICarouselDataByType;
}

/*export const selectCarouselState = createFeatureSelector<ICarouselStore>(
  'carouselStore',
);*/

export const selectCarouselState = createSelector(
  state => (state as any).carouselStore,
  carouselStore => carouselStore,
);

export const selectSuperCategoryCarousel = createSelector(
  selectCarouselState,
  (state: ICarouselStore) => state.superCategoryCarousel,
);

export const selectSubCategoryCarousel = createSelector(
  selectCarouselState,
  (state: ICarouselStore) => state.subCategoryCarousel,
);

export const selectNowPlayingCarousel = createSelector(
  selectCarouselState,
  (state: ICarouselStore) => state.nowPlayingCarousel,
);

export const selectNowPlayingUpNextCarousel = createSelector(
  selectCarouselState,
  (state: ICarouselStore) => state.nowPlayingUpNextCarousel,
);

export const selectSearchCarousel = createSelector(
  selectCarouselState,
  (state: ICarouselStore) => state.searchCarousel,
);

export const selectSearchLandingCarousel = createSelector(
  selectCarouselState,
  (state: ICarouselStore) => state.searchLandingCarousels,
);

export const selectProfileCarousel = createSelector(
  selectCarouselState,
  (state: ICarouselStore) => state.profileCarousel,
);

export const selectAllOnDemandCarousel = createSelector(
  selectCarouselState,
  (state: ICarouselStore) => state.allOnDemandCarousel,
);

export const selectAllVideoCarousel = createSelector(
  selectCarouselState,
  (state: ICarouselStore) => state.allVideoCarousel,
);

export const selectAllChannelsCarousel = createSelector(
  selectCarouselState,
  (state: ICarouselStore) => state.allChannelsCarousel,
);

export const selectEpisodeCarousel = createSelector(
  selectCarouselState,
  (state: ICarouselStore) => state.episodeCarousel,
);

export const selectShowCarousel = createSelector(
  selectCarouselState,
  (state: ICarouselStore) => state.showCarousel,
);

export const selectRecentsCarousel = createSelector(
  selectCarouselState,
  (state: ICarouselStore) => state.recentsCarousel,
);

export const selectShowReminderCarousel = createSelector(
  selectCarouselState,
  (state: ICarouselStore) => state.showReminderCarousel,
);

export const selectViewAllCarousel = createSelector(
  selectCarouselState,
  (state: ICarouselStore) => state.viewAllCarousel,
);

export const selectFilterdShowsCarousel = createSelector(
  selectCarouselState,
  (state: ICarouselStore) => state.filterdShowsCarousel,
);

export const selectCollectionCarousel = createSelector(
  selectCarouselState,
  (state: ICarouselStore) => state.collectionCarousel,
);

export const selectEnhancedEdpCarousel = createSelector(
  selectCarouselState,
  (state: ICarouselStore) => state.enhancedEdpCarousel,
);

export const selectSeededStationsCarousel = createSelector(
  selectCarouselState,
  (state: ICarouselStore) => state.seededStationsCarousel,
);

export const selectAllPodCastCarousel = createSelector(
  selectCarouselState,
  (state: ICarouselStore) => state.allPodCastCarousel,
);

export const selectNPArtistSelectorSegment = createSelector(
  selectNowPlayingCarousel,
  (nowPlayingCarousel: ICarouselDataByType) => {
    let result = null;

    if (!nowPlayingCarousel || !nowPlayingCarousel.selectors) return result;

    nowPlayingCarousel.selectors.some(selector => {
      return selector.segments.some(segment => {
        if (segment.class === 'artist') {
          result = segment;
          return true;
        }
        return false;
      });
    });

    return result;
  },
);

export const selectRecentsContentCarousel = createSelector(
  selectRecentsCarousel,
  (dataByType: ICarouselDataByType) => {
    if (dataByType && dataByType.zone) {
      const carousel =
        dataByType.zone.length > 0 && dataByType.zone[0].content
          ? dataByType.zone[0].content.find(
              carousel =>
                carousel.title.textValue.toLowerCase() ===
                carouselNameConstants.RECENTLY_PLAYED,
            )
          : {
              tiles: [],
            };

      if (carousel) {
        return Object.assign({}, carousel);
      }
      return null;
    }
  },
);

export const selectOnDemandEpisodeCarousel = createSelector(
  selectEpisodeCarousel,
  (dataByType: ICarouselDataByType) => {
    if (dataByType && dataByType.zone) {
      const carousel =
        dataByType.zone.length > 0 && dataByType.zone[0].content
          ? dataByType.zone[0].content[0]
          : null;

      if (carousel) {
        return Object.assign({}, carousel);
      }
      return null;
    }
  },
);
