import { of as observableOf,  Observable ,  BehaviorSubject ,  Subscription } from 'rxjs';
import { share, catchError, mergeMap } from 'rxjs/operators';
import {
    IAlert,
    INotificationAlert
}                          from "./alert.interface";
import { IHttpHeader }     from "../http/http.provider.response.interceptor";
import {
    IProviderDescriptor,
    addProvider
}                          from "../service";
import { Logger }          from "../logger";
import { AlertDelegate }   from "./alert.delegate";
import { AlertType }       from "./alert.constants";
import { HttpProvider }    from "../http/http.provider";
import { ProfileService }  from "../profile/profile.service";
import { SettingsService } from "../settings/settings.service";

/**
 * @MODULE:     service-lib
 * @CREATED:    02/02/18
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * AlertService to sets the alerts for the user.
 */

export class AlertService
{
    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("AlertService");

    /**
     * Subject used to trigger the alerts observable
     * @type {BehaviorSubject}
     */
    private alertsSubject: BehaviorSubject<IAlert[]> = new BehaviorSubject<IAlert[]>([]);

    /**
     * Observable that can used to get notified when the alerts have changed
     * @type {any}
     */
    public alerts: Observable<IAlert[]> = this.alertsSubject;

    /**
     *
     * @type {Array}
     */
    public alertsList: IAlert[] = [];

    /**
     * Subject used to trigger the notifcation alerts observable
     * @type {BehaviorSubject}
     */
    private notificationAlertsSubject: BehaviorSubject<INotificationAlert[]> = new BehaviorSubject<INotificationAlert[]>([]);

    /**
     * Observable that can used to get notified client when the alerts have received from notfication header
     * @type {any}
     */
    public notificationAlerts: Observable<INotificationAlert[]> = this.notificationAlertsSubject;

    /**
     * The subscription for the "X-SiriusXM-Notification" Header
     */
    private headerSubscription: Subscription;

    /**
     * The Observable for the header
     */
    public httpHeader: Observable<IHttpHeader>;

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(AlertService,
            AlertService,
            [ HttpProvider,
                AlertDelegate,
                ProfileService,
                SettingsService
            ]);
    }();

    /**
     * Constructor
     * @param {httpProvider} HttpProvider
     * @param {alertsDelegate} AlertsDelegate
     */
    constructor(private httpProvider: HttpProvider,
                private alertsDelegate: AlertDelegate,
                private profileService: ProfileService,
                private settingsService: SettingsService)
    {
        this.observeHeader("x-mountain-notification");

        this.profileService.profileData.subscribe((profileData) =>
        {
            if (!profileData || !profileData.alerts)
            {
                return;
            }

            this.alertsList = profileData.alerts;
            this.alertsSubject.next(profileData.alerts);
        });
    }

    /**
     * Creats the alert
     * TODO- Handling content Type
     * @param {string} channelId
     * @param {string} assetGuid
     * @param alertType
     * @returns {Observable<boolean>}
     */
    public createAlert(channelId: string, assetGuid: string, alertType: AlertType): Observable<boolean>
    {
        const response = this.alertsDelegate.createAlert(channelId, assetGuid, alertType);

        this.settingsService.runSubscribeFlow();

        return this.processAlertResponse(response);
    }

    /**
     * Used to get Show alert based on show guid
     * @param {string} showGuid
     * @param alertType
     * @returns {IAlert}
     */
    public getShowAlert(showGuid: string, alertType: AlertType): IAlert
    {
        return this.alertsList.find((alertItem) => alertItem.assetGuid === showGuid && alertItem.alertType === alertType.toString());
    }

    /**
     * Removes the alert
     * @param {string} alertId
     * @param {string} assetGuid
     * @param alertType
     * @returns {Observable<boolean>}
     */
    public removeAlert(alertId: string, assetGuid: string, alertType: AlertType): Observable<boolean>
    {
        let alertIdLocal = alertId;
        if (!alertId)
        {
            const alert  = this.alertsList.find((alertItem) => alertItem.assetGuid === assetGuid && alertItem.alertType === alertType.toString());
            alertIdLocal = alert ? alert.alertId : "";
        }

        if (!alertIdLocal)
        {
            AlertService.logger.debug(`Alert ID is empty ${(assetGuid)}`);
            return observableOf(false);
        }
        const response = this.alertsDelegate.removeAlert(alertIdLocal, assetGuid, alertType);

        return this.processAlertResponse(response);
    }

    /**
     * Removes all the alerts
     * @returns {Observable<boolean>}
     */
    public removeAllAlerts(): Observable<boolean>
    {
        const response = this.alertsDelegate.removeAllAlerts(this.alertsList);
        return this.processAlertResponse(response);
    }

    /**
     * disable the alert
     * @param {string} alertId
     * @returns {Observable<boolean>}
     */
    public muteAlert(alertId: string): Observable<boolean>
    {
        const response = this.alertsDelegate.muteAlert(alertId);
        return this.processAlertResponse(response);
    }

    /**
     * Begin observing a specific header
     *
     * @param headerName - the name of the header to be observed
     */
    public observeHeader(headerName: string): void
    {
        AlertService.logger.debug(`observeHeaders()`);

        if (this.headerSubscription)
        {
            this.headerSubscription.unsubscribe();
            this.headerSubscription = null;
        }

        const headerSuccess = (header: IHttpHeader): void =>
        {
            if (header && header.payload)
            {
                AlertService.logger.warn(`headerSuccess`);

                const notificationAlerts = this.alertsDelegate.normalizeNotificationHeader(header);
                this.notificationAlertsSubject.next(notificationAlerts);
            }
        };

        const headerFault = (fault: any): void =>
        {
            AlertService.logger.warn(`headerFault( ${JSON.stringify(fault)} )`);
        };

        this.httpHeader = this.httpProvider.addHttpHeaderObservable(headerName);

        this.headerSubscription = this.httpHeader.subscribe(
            headerSuccess.bind(this),
            headerFault
        );
    }

    /**
     * process the alert response.
     * @param {Observable<IAlert[]>} response
     * @returns {MonoTypeOperatorFunction<any>}
     */
    private processAlertResponse(response: Observable<IAlert[]>)
    {
        response.subscribe(onAlertSuccess.bind(this), onAlertFault.bind(this));

        //TODO - Can we clean this up, and make it more succinct.
        return response.pipe(mergeMap((response: any) =>
                       {
                           if (!response)
                           {
                               return observableOf(false);
                           }

                           return observableOf(true);

                       }),
                       catchError(() => observableOf(false)),
                       share());

        /**
         *
         * @param {Array<>} alertSuccessResonse
         */
        function onAlertSuccess(alerts: IAlert[])
        {
            this.alertsList = alerts;
            this.alertsSubject.next(alerts);
        }

        /**
         * Once delegate Throws exception then fault handler get called.
         * @param error - error is returned by the delegate
         */
        function onAlertFault(error: Error)
        {
            AlertService.logger.error(`onAlertCreateFault - Error (${JSON.stringify(error)})`);
        }
    }
}
