/**
 * @MODULE:     service-lib
 * @CREATED:    07/27/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *  Interfaces and classes for on demand discover requests to the API.
 */


import { Observable } from "rxjs";
import { IOnDemandShow } from "./ondemand.interfaces";

export interface IOnDemandDiscoveryRequest
{
    superCategoryKey: string;
    subCategoryKey: string;
}

export class OutStandingOnDemandRequest implements IOnDemandDiscoveryRequest
{
    /**
     * super category key that requested to get OD.
     */
    public superCategoryKey : string;

    /**
     * sub category key that requested to get OD.
     */
    public subCategoryKey : string;

    /**
     * request made to get OD for given super and sub category key.
     */
    public request : Observable<Array<IOnDemandShow>>;

    /**
     * Constructor
     * @param {string} superCategoryKey - super category key to request OD
     * @param {string} subCategoryKey - sub category key to request OD
     * @param {Observable<Array<IOnDemandShow>>} request - stored OD outstanding request that associated to super
     * @param {Function} onDone will get called when the request is complete
     * and sub category key and re used if we get duplicate request.
     */
    constructor(superCategoryKey : string,
                subCategoryKey : string,
                request : Observable<Array<IOnDemandShow>>,
                onDone : (request : OutStandingOnDemandRequest) => void)
    {
        this.superCategoryKey = superCategoryKey;
        this.subCategoryKey = subCategoryKey;
        this.request = request;

        /*
         * The following code will clean up the outstanding request by calling the on done function when
         * the request is satisfied.  However, since we do not have control over the observable we are using
         * here, we take 2 different situations into account
         *
         * 1) Observable is async.  We can unsubscribe in the observer
         * 2) Observable is sync.  We must unsubscribe after we register the observer
         */

        //NOTE :  the outstanding requests are removed by calling either (on next| on error|on done) function when request is satisfied
        //Currently there is an issue where the `on error` callback is not triggered even when there is error response

        let async : boolean = true;
        let subscription = request.subscribe(requestOnComplete.bind(this),requestOnComplete.bind(this),requestOnComplete.bind(this));

        if (!async)
        {
            subscription.unsubscribe();
        }

        function requestOnComplete()
        {
            onDone(this);
            if (subscription)
            {
                subscription.unsubscribe();
                async = true;
            }
            else
            {
                async = false;
            }
        }

    }
}
