import { of as observableOf, Observable, BehaviorSubject } from 'rxjs';
import { share, mergeMap, tap, catchError, map } from 'rxjs/operators';
import { IProviderDescriptor, addProvider } from '../service';
import { Logger } from '../logger';

import { ProfileDelegate } from './profile.delegate';
import {
  IPausePointData,
  IProfileAvatar,
  IProfileResponse,
} from './profile.interface';
import { IProfileData } from '../config/interfaces/all-profiles-data.interface';
import { ResumeService } from '../resume/resume.service';
import { AppMonitorService } from '../app-monitor/app-monitor.service';
import { BypassMonitorService } from '../app-monitor/bypass-monitor.service';
import { IAppByPassState } from '../app-monitor/app-monitor.interface';
import { IChannel } from '../channellineup';

/**
 * @MODULE:     service-lib
 * @CREATED:    02/02/18
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * ProfileService to get the recently played data , favorites and the pause points for the user.
 */

export class ProfileService {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('ProfileService');

  /**
   * subject for delivering profileData through the profileData observable.
   * @type {BehaviorSubject<IProfileResponse>}
   */
  private profileDataSubject: BehaviorSubject<
    IProfileResponse
  > = new BehaviorSubject<IProfileResponse>({
    favorites: [],
    recentlyPlayed: [],
    pausePoints: [],
    alerts: [],
  } as IProfileResponse);

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the profile response Data
   * and be notified when the profile response data changes.
   */
  public profileData: Observable<IProfileResponse> = this.profileDataSubject;

  /**
   * Subject used to trigger the pause Points observable
   * @type {BehaviorSubject}
   */
  private pausePointSubject: BehaviorSubject<
    IPausePointData[]
  > = new BehaviorSubject<IPausePointData[]>([]);

  /**
   * Observable that can used to get notified when the pause Points have changed
   * @type {any}
   */
  public pausePoints: Observable<IPausePointData[]> = this.pausePointSubject;

  /**
   * profileAvatarsData represents the all the avatar images available.
   * @type {any}
   */
  private profileAvatarsData: Array<IProfileAvatar> = [];

  /**
   * subject for delivering profileAvatarsData data through profileAvatars Observable.
   * @type {any}
   */
  private profileAvatarsSubject: BehaviorSubject<Array<IProfileAvatar>> = null;

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the profileAvatarsData
   * and be notified when the profileAvatarsData data changes.
   */
  public profileAvatars: Observable<Array<IProfileAvatar>>;

  /**
   * userInfoData represents the name and avatar... info- profileData holds the media detials
   * @type {any}
   */
  private userInfoData: IProfileData = null;

  /**
   * subject for delivering userInfo through userInfo Observable.
   * @type {any}
   */
  private userInfoSubject: BehaviorSubject<IProfileData> = new BehaviorSubject(
    {} as IProfileData,
  );

  /**
   * This is data from the resume call - we get the IprofileData
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the userInfo
   * and be notified when the userInfo data changes.
   */
  public userInfo: Observable<IProfileData>;

  /**
   * Use to store Profile Gup By pass mode. If is in gup by pass mode , We make profile call
   * returns as success makes bootstrap of the app completes and disables some of the feature on UI.
   * @type {boolean}
   */
  private gupByPass: boolean = false;

  /**
   * Subject used to trigger the Seeded Radio Station list observable
   * @type {BehaviorSubject}
   */
  private seededStationSubject: BehaviorSubject<
    IChannel[]
  > = new BehaviorSubject<IChannel[]>([]);

  /**
   * Observable that can used to get notified when the seeded stations have changed
   * @type {any}
   */
  public seededStations: Observable<IChannel[]> = this.seededStationSubject;

  /**
   *
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(ProfileService, ProfileService, [
      ProfileDelegate,
      ResumeService,
      AppMonitorService,
      BypassMonitorService,
    ]);
  })();

  /**
   * Constructor
   * @param {profileDelegate} ProfileDelegate
   */
  constructor(
    private profileDelegate: ProfileDelegate,
    private resumeService: ResumeService,
    private appMonitorService: AppMonitorService,
    private bypassMonitorService: BypassMonitorService,
  ) {
    this.profileAvatarsSubject = new BehaviorSubject(this.profileAvatarsData);
    this.profileAvatars = this.profileAvatarsSubject;
    this.userInfo = this.userInfoSubject;

    this.resumeService.profileData.subscribe((userInfo: IProfileData) => {
      this.userInfoData = userInfo;
      this.userInfoSubject.next(this.userInfoData);
    });

    this.bypassMonitorService.bypassErrorState.subscribe(
      (state: IAppByPassState) => {
        const inByPass = this.gupByPass;
        this.gupByPass = state.GUP_BYPASS2 || state.GUP_BYPASS;
        if (inByPass === true && this.gupByPass === false) {
          this.getProfileData();
        }
      },
    );
  }

  /**
   * used to get the profile data includes favorites, recently played , pause points and alerts
   * @returns {Observable<boolean | {}>}
   */
  public getProfileData(): Observable<boolean | {}> {
    ProfileService.logger.debug('getProfileData )');

    if (this.gupByPass) {
      return observableOf(true);
    }

    const profileResponse: Observable<IProfileResponse> = this.profileDelegate.getProfileData();

    return this.processProfileResponse(profileResponse);
  }

  /**
   * used to get pause points .
   * @returns {Observable<boolean | {}>}
   */
  public getPausePoints(): Observable<boolean | {}> {
    ProfileService.logger.debug('getProfileData )');

    if (this.gupByPass) {
      return observableOf(true);
    }

    const profileResponse: Observable<IProfileResponse> = this.profileDelegate.getProfileData();

    return this.processProfileResponse(profileResponse, true);
  }

  /**
   * used to update the profile name and avatar
   * @returns {Observable<boolean | {}>}
   */
  public updateProfile(
    profileName: string,
    avatarId: string,
  ): Observable<boolean> {
    ProfileService.logger.debug('updateProfile )');

    const data = {
      profilename: profileName,
      avatarid: avatarId,
    };

    return this.profileDelegate.updateProfile(data).pipe(
      tap(
        response => {
          this.userInfoData.profileName = response.profilename;
          this.userInfoData.avatar = response.avatarid;
          this.userInfoSubject.next(this.userInfoData);

          return true;
        },
        error => {
          ProfileService.logger.error(
            'updateProfile - falied ),',
            JSON.stringify(error),
          );
          return false;
        },
      ),
    );
  }

  /**
   * used to get the profile Avatars
   * @returns {Observable<boolean | {}>}
   */
  public getProfileAvatars(): Observable<IProfileAvatar[]> {
    ProfileService.logger.debug('getProfileAvatars )');

    return this.profileDelegate.getProfileAvatars().pipe(
      map(
        avatars => {
          this.profileAvatarsData = avatars || [];
          this.profileAvatarsSubject.next(this.profileAvatarsData);
          return this.profileAvatarsData;
        },
        error => {
          ProfileService.logger.error(
            'getProfileAvatars - falied ),',
            JSON.stringify(error),
          );
          return [];
        },
      ),
    );
  }

  /**
   * returns the user gup Id
   */
  public getGupId(): string {
    return this.userInfoData.gupId;
  }

  /**
   * Used to process the profile response which get from delegate and kicks the profile response observable.
   * @param {Observable<IProfileResponse>} response
   * @param {boolean} processOnlyPausePoints - Used to avoid trigger the profileDataSubject.
   * @returns {Observable<boolean>}
   */
  private processProfileResponse(
    response: Observable<IProfileResponse>,
    processOnlyPausePoints: boolean = false,
  ): Observable<boolean> {
    response.subscribe(
      onProfileDataSuccess.bind(this),
      onProfileDataFault.bind(this),
    );

    //TODO - Can we clean this up, and make it more succinct.
    return response.pipe(
      mergeMap((response: any) => {
        if (!response) {
          return observableOf(false);
        }

        return observableOf(true);
      }),
      catchError((/*error*/) => {
        if (this.gupByPass) {
          ProfileService.logger.debug(
            'Encountered profile gup by pass mode ${error}',
          );
          return observableOf(true);
        }
        return observableOf(false);
      }),
      share(),
    );

    /**
     *
     * @param {Array<>} recentlyPlayedResponse
     */
    function onProfileDataSuccess(profileResponse: IProfileResponse) {
      if (!processOnlyPausePoints) {
        this.profileDataSubject.next(profileResponse);
      }
      if (profileResponse && profileResponse.pausePoints) {
        this.pausePointSubject.next(profileResponse.pausePoints);
      }
    }

    /**
     * Once delegate Throws exception then fault handler get called.
     * @param error - error is returned by the delegate
     */
    function onProfileDataFault(error: Error) {
      ProfileService.logger.error(
        `onProfileDataFault - Error (${JSON.stringify(error)})`,
      );
    }
  }
}
