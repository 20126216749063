import * as _                     from "lodash";
import { BehaviorSubject }        from "rxjs";
import {
    IAssetInfoType,
    IBaseCarousel,
    IBaseCarouselResponse,
    ICarouselConditionType,
    ICarouselEditableType,
    ICarouselTextType,
    ISegmentGroupedCarouselType,
    ISelectorSegmentType,
    ISelectorType,
    ITileActionType,
    ITileImageType,
    ITileType,
    IZoneType
} from "./carousel.types";
import {
    INeriticLinkData,
    ITile
}                                 from "./raw.tile.interface";
import {
    ICarouselData,
    ICarouselDataByType,
    ICarouselSelector,
    ICarouselSelectorSegment,
    IPageAssetInfo,
    IPageLogo,
    CarouselType,
    CarouselTypeConstants,
    CarouselOrientationType,
    IPageAction,
    IEditable,
    IZoneDataByType
} from "./carousel.interface";
import { ActionTypes, ContentTypes } from "../service/types";
import { CarouselTypeConst }      from "./carousel.const";
import { Logger }                 from "../logger";
import { FgImageType }            from "./raw.tile.interface";
import { DateUtil }               from "../util/date.util";
import {
    IconImageType,
    ITileBanner
}                                 from "./raw.tile.interface";
import { neriticActionConstants } from "../service/consts/neritic-action-const";
import { relativeUrlToAbsoluteUrl }   from "../util/utilities";
import { IRelativeUrlSetting }    from "../config";

export class V2CarouselNormalizer
{
    /**
     * Logger.
     */
    private static logger: Logger = Logger.getLogger("V2CarouselNormalizer");

    /**
     * Remove any Carousels that don't have tiles
     *
     * @param response {IV2RawCarouselResponse}
     */
    public static filterCarouselsWithNoTiles(response: IZoneType): IBaseCarousel[]
    {
        const hasCarousels: boolean = (response.carousel && response.carousel.length > 0);
        let carousels: IBaseCarousel[];

        if (hasCarousels)
        {
            carousels = response.carousel || [];
            carousels = carousels.filter((carousel: IBaseCarousel) =>
            {
                //we shouldn't be getting the hidden displayType from the API, but if we do we will hide that carousel
                return (carousel.carouselTiles && carousel.carouselTiles.length > 0 && carousel.carouselDisplayType !=='hidden');
            });
        }

        return carousels;
    }

    /**
     * Remove any Hero Carousels that have LiveVideo tile- if the enableLiveVideo flag is OFF
     * As we don't get LV tiles in content carousel, we are only applying this filter for Hero tiles
     * @param response {IBaseCarouselResponse}
     */
    public static filterCarouselsWithLiveVideo(response: IZoneType): IZoneType
    {
        const hasHeroCarousels: boolean = (response.heroCarousel && response.heroCarousel.length >0);

        if(hasHeroCarousels)
        {
            response.heroCarousel.forEach((carousel:IBaseCarousel)=>
            {
                carousel.carouselTiles = carousel.carouselTiles.filter((tile: ITileType) =>
                {
                    return tile.tileContentSubType !== CarouselTypeConst.LIVE_VIDEO;
                });
            });
        }

        return response;
    }

    private static normalizeZone(zone:IZoneType,isLiveVideoEnabled, screen, relativeUrls: Array<IRelativeUrlSetting>=[]):IZoneDataByType
    {
        if(!isLiveVideoEnabled)
        {
            zone = V2CarouselNormalizer.filterCarouselsWithLiveVideo(zone);
        }

        let carouselsWithEmptyTilesRemoved: IBaseCarousel[] = V2CarouselNormalizer.filterCarouselsWithNoTiles(zone);
        let normalizedZone: IZoneDataByType = { } as IZoneDataByType;

        normalizedZone.hero = zone.heroCarousel.map((carousel: IBaseCarousel) =>
            V2CarouselNormalizer.normalizeCarousel(carousel, "hero", screen, relativeUrls));

        normalizedZone.content = carouselsWithEmptyTilesRemoved
            ? carouselsWithEmptyTilesRemoved.map((carousel: IBaseCarousel) =>
                V2CarouselNormalizer.normalizeCarousel(carousel, "content", screen, relativeUrls))
            : [];

        normalizedZone.expirationTime = Date.parse(zone.expiry);
        normalizedZone.zoneClass = zone.zoneClass;
        normalizedZone.zoneId = zone.zoneId;
        normalizedZone.zoneGuid = zone.zoneGuid;
        normalizedZone.zoneLink = zone.zoneLink;
        normalizedZone.zoneOrder = zone.zoneOrder;
        normalizedZone.zoneTitle = zone.zoneTitle;
        normalizedZone.subCategories = [];

        //prepare a tile action object from the given neritic Link
        let zoneAction:ITileActionType = {
            actionAnalyticsTag: "string",
            actionNeriticLink: zone.zoneLink,
            actionType: "primaryAction"
        };
        normalizedZone.neriticLinkData = zone.zoneLink ? V2CarouselNormalizer.getSelectorSegmentNeriticLinkData(zoneAction) : null;

        return normalizedZone;
    }

    /**
     * Take a Base Carousel Response, and turn it into the interface for carousels that will be sent to the outside
     * world
     *
     * @param response is the V2 carousel response which we want to normalize
     * @param isLiveVideoEnabled
     * @param aicImageDomain
     *
     * isLiveVideoEnabled -> used to filter the LiveVideo hero tiles from the
     */
    public static normalizeCarousels(response: IBaseCarouselResponse
                                     , isLiveVideoEnabled: boolean = false
                                     , relativeUrls: Array<IRelativeUrlSetting>=[]): ICarouselDataByType
    {
        let normalizedCarousels: ICarouselDataByType         = {} as ICarouselDataByType;
        normalizedCarousels.zone=[];

        //Create a zone based on the base(non zoned pages) hero /content carousel response
        response.zone = response.zone ? response.zone : [];
        response.zone.unshift(createZone(response));

        if(response.zone && response.zone.length)
        {
            normalizedCarousels.zone = response.zone.map((zone:IZoneType)=>
            {
                return  V2CarouselNormalizer.normalizeZone(zone,isLiveVideoEnabled, response.screen,relativeUrls);
            });

            normalizedCarousels.zone = normalizedCarousels.zone.filter(zone => zone.hero.length > 0 || zone.content.length > 0);
            //Remove the duplicate zones with zoneGuid
            normalizedCarousels.zone = _.uniqBy(normalizedCarousels.zone, (zone) => zone.zoneGuid);
        }

        normalizedCarousels.expirationTime = Date.parse(response.expiry);

        //If we have Zones, find the first expiry zone
        if(normalizedCarousels.zone && normalizedCarousels.zone.length >0 )
        {
            let firstExpiryZone = normalizedCarousels.zone.reduce((a, b) => (a.expirationTime < b.expirationTime) ? a : b);
            normalizedCarousels.expirationTime = firstExpiryZone.expirationTime && !isNaN(firstExpiryZone.expirationTime)
                ? firstExpiryZone.expirationTime : Date.parse(response.expiry);
        }

        normalizedCarousels = promoteCategoryCarousel(normalizedCarousels);
        normalizedCarousels.selectors = collectSelectors(response);
        normalizedCarousels.pageBackground = normalizePageBackground(response);
        normalizedCarousels.pageBackgroundColor = response.pageBackgroundColor;

        normalizedCarousels.pageLogo = normalizePageLogo(response);
        normalizedCarousels.screen = response.screen;

        const pageTexts: Array<ICarouselTextType> = response.pageText ? response.pageText : [];

        pageTexts.forEach((text : ICarouselTextType) =>
        {
            const setText = (!text.textField) ? DateUtil.localizeDateTime(text.textValue) : text.textField;

            normalizedCarousels.pageTextLine1 = (text.textClass === "line1") ? setText : normalizedCarousels.pageTextLine1;
            normalizedCarousels.pageTextLine2 = (text.textClass === "line2") ? setText : normalizedCarousels.pageTextLine2;
            normalizedCarousels.pageTextLine3 = (text.textClass === "line3") ? setText : normalizedCarousels.pageTextLine3;
        });

        normalizedCarousels.pageAssetInfo =  normalizePageAssetInfo(response);
        normalizedCarousels.pageTitle = normalizePageTitle(response);
        normalizedCarousels.pageAction = normalizePageAction(response);

        return normalizedCarousels;

        function createZone(response: IBaseCarouselResponse):IZoneType
        {
            let zone = {} as IZoneType;
            zone.heroCarousel = response.heroCarousel ? [response.heroCarousel] : [];
            zone.carousel = response.carousel ? response.carousel : [];
            zone.zoneClass='';
            zone.zoneId = `${response.screen}`;
            zone.zoneOrder = 0;
            zone.moreSelector = response.moreSelector;
            zone.zoneGuid = `guid-${response.screen}-zone-0`;
            zone.expiry = response.expiry;
            return zone;
        }

        /**
         * Find the category carousel, and take it out of the content carousel array and "promote" it into the
         * category property
         *
         * @param {ICarouselDataByType} carousels are the carousels to perform the promotion on
         * @returns {ICarouselDataByType} new carousels object with a promoted subcategory carousel (if available)
         */
        function promoteCategoryCarousel(carouselData: ICarouselDataByType) : ICarouselDataByType
        {
            carouselData.zone.forEach(zone=>
            {
                zone.content = zone.content
                                   .reduce((contentCarousels: Array<ICarouselData>,
                                                      carousel: ICarouselData): any =>
                                         {
                                             if (_.get(carousel,
                                                 "algorithm.name",
                                                 "") !== CarouselTypeConstants.CATEGORY_LIST)
                                             { contentCarousels.push(carousel); }
                                             else { carouselData.category = carousel; }
                                             return contentCarousels;
                                         }, []);
            });

            return carouselData;
        }

        /**
         * Find all the different types of selectors that we can have, and pull all of these selectors into a big
         * array of selectors.  Each element in the array will have a selectorClass, that tells you what type of
         * selector you are dealing with.  However, we can still decide to "promote" certain selectors to top level
         * properties if we want in a later normalization stage.
         *
         * The carousels in the selectors will get normalized like all the other carousels
         *
         * @param {IBaseCarouselResponse} response
         * @returns {Array<ICarouselSelector>}
         */
        function collectSelectors(response: IBaseCarouselResponse) : Array<ICarouselSelector>
        {
            const selectors : Array<ICarouselSelector> = [];

            if (response.pageSelector)
            {
                const selector = normalizeSelector(response.pageSelector, response.screen);
                if (selector.class) { selectors.push(selector); }
            }
            if (response.contentToggleSelector)
            {
                const selector = normalizeSelector(response.contentToggleSelector, response.screen);
                if (selector.class) { selectors.push(selector); }
            }
            if (response.moreSelector)
            {
                const selector = normalizeSelector(response.moreSelector, response.screen);
                if (selector.class) { selectors.push(selector); }
            }
            if (response.zone && response.zone.length > 0)
            {
                response.zone.forEach((zone:IZoneType)=>
                {
                    if(zone.moreSelector)
                    {
                        const selector = normalizeSelector(zone.moreSelector, response.screen);
                        if (selector.class) { selectors.push(selector); }
                    }
                });
            }
            return selectors;
        }

        /**
         * Take a raw selector from the API, and create segments from the raw segments in the selector.
         *
         * @param {ISelectorType} selector from the API
         * @returns {ICarouselSelector} normalized selector to be used by the client
         */
        function normalizeSelector(selector : ISelectorType, screen: string) : ICarouselSelector
        {
            const normalizedSelector = {} as ICarouselSelector;
            normalizedSelector.class =  selector.selectorClass;
            normalizedSelector.title =  selector.selectorTitle;
            normalizedSelector.segments = selector.selectorSegment.map((segment) => normalizeSelectorSegment(segment, screen));

            return normalizedSelector;
        }

        /**
         * Take a raw selector segment from the API and return a normalized segment with normalized carousels
         * @param {ISelectorSegmentType} selectorSegment is the raw segment from the api
         * @param {string} screen
         * @returns {ICarouselSelectorSegment} normalized segment with normalized carousels.
         */
        function normalizeSelectorSegment(selectorSegment: ISelectorSegmentType, screen: string) : ICarouselSelectorSegment
        {
            const segment = {} as ICarouselSelectorSegment;

            segment.title     = selectorSegment.segmentTitle;
            segment.key     = selectorSegment.segmentTitle ? selectorSegment.segmentTitle.replace(/\s/g, ''):'';
            segment.action    = selectorSegment.segmentLink;

            if (segment.action && segment.action.actionNeriticLink)
            {
                segment.action.actionNeriticLink = segment.action.actionNeriticLink.replace(/App:createSeeded:/, 'Api:tune:createSeededChannel:');
                segment.neriticLinkData = V2CarouselNormalizer.getSelectorSegmentNeriticLinkData(selectorSegment.segmentLink);
            }
            segment.class     = selectorSegment.segmentClass || '';
            segment.carousels = selectorSegment.segmentCarousel.map((carousel: IBaseCarousel) =>
                V2CarouselNormalizer.normalizeCarousel(carousel, "content", screen, relativeUrls));
            segment.groupedCarousels = selectorSegment.segmentGroupedCarousel.map((groupedCarousel: ISegmentGroupedCarouselType) =>
            {
                let normalizedGroupedCarousel =
                        {
                            groupName: groupedCarousel.groupName ? groupedCarousel.groupName : {
                                textClass: CarouselTypeConst.CAROUSEL_TITLE,
                                textValue: ""
                            },
                            carousel: {}
                        };

                normalizedGroupedCarousel.carousel = V2CarouselNormalizer.normalizeCarousel(groupedCarousel.groupedCarousel, "content"
                    , screen, relativeUrls);

                return normalizedGroupedCarousel;

            });

            return segment;
        }

        /**
         * Used to normalize the page title
         * @param {IV2RawCarouselResponse} response
         * @returns {ICarouselTextType}
         */
        function normalizePageTitle(response: IBaseCarouselResponse) : ICarouselTextType
        {
            if (response.pageTitle)
            {
                return {
                    textClass: response.pageTitle.textClass,
                    textValue  : response.pageTitle.textValue
                };
            }
            else
            {
                return {
                    textClass: "",
                    textValue  : ""
                };
            }
        }

        /**
         * Used to normalize the page logo
         * @param {IV2RawCarouselResponse} response
         * @returns {IPageLogo}
         */
        function normalizePageLogo(response: IBaseCarouselResponse) : IPageLogo
        {
            if (response.pageLogo)
            {
                return {
                    imageAltText: response.pageLogo.imageAltText,
                    imageClass  : response.pageLogo.imageClass,
                    imageLink   : relativeUrlToAbsoluteUrl(response.pageLogo.imageLink, relativeUrls)
                };
            }
        }

        /**
         * Used to normalize the page asset info
         * @param {IV2RawCarouselResponse} response
         * @returns {IPageAssetInfo}
         */
        function normalizePageAssetInfo (response: IBaseCarouselResponse) : IPageAssetInfo
        {
            if (response.pageAssetInfo)
            {
                const pageAssetInfo : Array<IAssetInfoType> = response.pageAssetInfo? response.pageAssetInfo: [];
                const connectTwitter: IAssetInfoType        = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "connectTwitter");
                const connectTwitterLink: IAssetInfoType    = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "connectTwitterLink");
                const connectTwitterIcon: IAssetInfoType    = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "connectTwitterIcon");
                const connectEmail: IAssetInfoType          = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "connectEmail");
                const connectEmailIcon: IAssetInfoType      = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "connectEmailIcon");
                const connectFacebook: IAssetInfoType       = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "connectFacebook");
                const connectFacebookLink: IAssetInfoType   = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "connectFacebookLink");
                const connectFacebookIcon: IAssetInfoType   = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "connectFacebookIcon");
                const connectPhone: IAssetInfoType          = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "connectPhone");
                const connectPhoneIcon: IAssetInfoType      = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "connectPhoneIcon");
                const channelName: IAssetInfoType           = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "channelName");
                const channelId: IAssetInfoType             = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "channelId");
                const channelGuid: IAssetInfoType           = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "channelGuid");
                const channelGUID: IAssetInfoType           = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "channelGUID");
                const showName: IAssetInfoType              = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "showName");
                const showGUID: IAssetInfoType              = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "showGUID");
                const showType: IAssetInfoType              = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "showType");
                const episodeType: IAssetInfoType           = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "episodeType");
                const episodeName: IAssetInfoType           = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "episodeName");
                const vodEpisodeGUID: IAssetInfoType        = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "vodEpisodeGUID");
                const aodEpisodeCaId: IAssetInfoType        = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "aodEpisodeCaId");
                const isIrisPodcast: IAssetInfoType         = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "isPandoraPodcast");
                const episodeGUID: IAssetInfoType           = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "episodeGUID");
                const coldStartDefinition: IAssetInfoType   = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "coldStartDefinition");
                const userDaysListened: IAssetInfoType      = pageAssetInfo.find((assetInfo) => assetInfo.assetInfoKey === "userDaysListened");

                const chanGuid = (channelGuid) ? channelGuid.assetInfoValue : (channelGUID) ? channelGUID.assetInfoValue : "";

                return {
                    connectTwitter        : connectTwitter ? connectTwitter.assetInfoValue : "",
                    connectTwitterLink    : connectTwitterLink ? connectTwitterLink.assetInfoValue : "",
                    connectTwitterIcon    : connectTwitterIcon ? connectTwitterIcon.assetInfoValue : "",
                    connectFacebook       : connectFacebook ? connectFacebook.assetInfoValue : "",
                    connectFacebookLink   : connectFacebookLink ? connectFacebookLink.assetInfoValue : "",
                    connectFacebookIcon   : connectFacebookIcon ? connectFacebookIcon.assetInfoValue : "",
                    connectEmail          : connectEmail ? connectEmail.assetInfoValue : "",
                    connectEmailIcon      : connectEmailIcon ? connectEmailIcon.assetInfoValue : "",
                    connectPhone          : connectPhone ? connectPhone.assetInfoValue : "",
                    connectPhoneIcon      : connectPhoneIcon ? connectPhoneIcon.assetInfoValue : "",
                    channelName           : channelName ? channelName.assetInfoValue : "",
                    channelId             : channelId ? channelId.assetInfoValue : "",
                    channelGuid           : chanGuid,
                    channelGUID           : chanGuid,
                    showName              : showName ? showName.assetInfoValue : "",
                    showGUID              : showGUID ? showGUID.assetInfoValue: "",
                    showType              : showType ? showType.assetInfoValue : "",
                    episodeGUID           : episodeGUID ? episodeGUID.assetInfoValue : "",
                    episodeType           : episodeType ? episodeType.assetInfoValue: "",
                    episodeName           : episodeName ? episodeName.assetInfoValue: "",
                    aodEpisodeCaId        : aodEpisodeCaId ? decodeURIComponent(aodEpisodeCaId.assetInfoValue): "",
                    vodEpisodeGUID        : vodEpisodeGUID ? vodEpisodeGUID.assetInfoValue: "",
                    isIrisPodcast         : isIrisPodcast ? isIrisPodcast.assetInfoValue === "true" : false,
                    coldStartDefinition   : coldStartDefinition ? coldStartDefinition.assetInfoValue: "",
                    userDaysListened   : userDaysListened ? userDaysListened.assetInfoValue: ""
                };
            }
        }

        /**
         * Used to normalize the page action
         * @param {IV2RawCarouselResponse} response
         * @returns {IPageAction}
         */
        function normalizePageAction (response: IBaseCarouselResponse) : IPageAction
        {
            if (response.pageAction)
            {
                const pageAction : Array<ITileActionType>    = response.pageAction? response.pageAction: [];
                const primaryAction: ITileActionType        = pageAction.find((action) => action.actionType === "primaryAction");
                const addToFavorites: ITileActionType       = pageAction.find((action) => action.actionType === "addToFavorites");
                const setShowReminder: ITileActionType      = pageAction.find((action) => action.actionType === "setShowReminder");

                return {
                    primaryAction        : primaryAction ? primaryAction.actionNeriticLink : "",
                    addToFavorites       : addToFavorites ? addToFavorites.actionType : "",
                    setShowReminder      : setShowReminder ? setShowReminder.actionType : ""
                };
            }
        }

        /**
         * Used to normalize the page background
         * @param {IV2RawCarouselResponse} response
         * @returns {IPageLogo}
         */
        function normalizePageBackground(response: IBaseCarouselResponse) : IPageLogo
        {
            if (response.pageBackground)
            {
                return {
                    imageAltText: response.pageBackground.imageAltText,
                    imageClass  : response.pageBackground.imageClass,
                    imageLink   : relativeUrlToAbsoluteUrl(response.pageBackground.imageLink, relativeUrls)
                };
            }
        }
    }

    /**
     * Take V2 carousels, and turn them into the normalized carousels that will be sent ot the outside world
     * @param carousel is the V2 carousel to normalize
     * @param type is the type of carousel we are dealing with
     */
    public static normalizeCarousel(carousel: IBaseCarousel,
                                    type : CarouselType,
                                    screen: string, relativeUrls: Array<IRelativeUrlSetting>=[]): ICarouselData
    {
        let normalizedCarousel: ICarouselData = {
            tiles: [],
            carouselViewAll: {showViewAll: false, viewAllLink: ""},
            title: { textClass: CarouselTypeConst.CAROUSEL_TITLE, textValue: "" }
        } as ICarouselData;

        if (carousel)
        {
            normalizedCarousel.algorithm =
            {
                name : carousel.carouselAlgorithm ? carousel.carouselAlgorithm.algorithmName: "",
                parameters : carousel.carouselAlgorithm ? carousel.carouselAlgorithm.algorithmParameters: []
            };
            normalizedCarousel.screen = screen;
            normalizedCarousel.editable = V2CarouselNormalizer.normalizeEditable(carousel.editable);
            normalizedCarousel.tiles = carousel.carouselTiles
                                     .map((tile : ITileType) =>
                                         V2CarouselNormalizer.normalizeTile(tile, type, relativeUrls, normalizedCarousel.editable ));
            normalizedCarousel.type  = type;
            normalizedCarousel.guid  = carousel.carouselGUID;
            normalizedCarousel.carouselViewAll.showViewAll = (!!carousel.carouselViewAll && !!carousel.carouselViewAll.showViewAll)
                                                             ? carousel.carouselViewAll.showViewAll : false;
            if (normalizedCarousel.carouselViewAll.showViewAll && !!carousel.carouselViewAll.viewAllLink)
            {
                normalizedCarousel.carouselViewAll.viewAllLink = carousel.carouselViewAll.viewAllLink;
            }

            normalizedCarousel.title = (!!carousel.carouselTitle && !!carousel.carouselTitle.textClass && !!carousel.carouselTitle.textValue)
                                       ? carousel.carouselTitle : { textClass: CarouselTypeConst.CAROUSEL_TITLE, textValue: "" };

            normalizedCarousel.carouselOrientation = carousel.carouselOrientation as CarouselOrientationType;

            const carouselConditions : Array<ICarouselConditionType> = _.get(carousel,"carouselConditions",[]) as Array<ICarouselConditionType>;

            const channelId: ICarouselConditionType      = carouselConditions
                          .find((conditionInfo) => conditionInfo.conditionKey === "channelId");

            normalizedCarousel.conditions =
                {
                    channelId : channelId ? channelId.conditionValue : ""
                };
        }

        return normalizedCarousel;
    }

    /**
     * Take the V2 tiles, and convert them into the normalize tile interface that will be sent to the outside world
     * @param tile
     * @param type is the type of carousel the tile belongs to
     * @returns {ITile}
     */
    public static normalizeTile(tile : ITileType,
                                type : CarouselType,
                                relativeUrls: Array<IRelativeUrlSetting>=[],
                                editable: IEditable) : ITile
    {
        let normalizedTile: ITile = { neriticLinkData : [] } as ITile;

        V2CarouselNormalizer.setTileImage(tile, normalizedTile, type, relativeUrls);

        normalizedTile.tileShape                = _.get(tile, "tileMarkup.tileShape", "square");
        normalizedTile.backgroundColor          = _.get(tile, "tileMarkup.backgroundColor", "");
        normalizedTile.isEligibleForLiveUpdates = false;

        V2CarouselNormalizer.setIsTunedTo(tile, normalizedTile);
        V2CarouselNormalizer.setTileText(tile, normalizedTile);
        V2CarouselNormalizer.setTileAssetInfo(tile, normalizedTile);

        normalizedTile.tileBanner = V2CarouselNormalizer.getTileBanner(tile);
        V2CarouselNormalizer.setTileNeriticLinkActions(tile, normalizedTile, editable);

        normalizedTile.tileContentType = V2CarouselNormalizer.determineTileType(tile.tileContentType,
                                                                                normalizedTile.primaryNeriticLink);
        normalizedTile.tileContentTypeFromAPI = tile.tileContentType;
        normalizedTile.tileContentSubType = (tile.tileContentSubType) ?
                                            tile.tileContentSubType.toLowerCase() : "";

        normalizedTile.reminders = { showReminderSet: false, liveVideoReminderSet: false};

        return normalizedTile;
    }

    public static setTileImage(tile: ITileType,
                               normalizedTile: ITile,
                               type: CarouselType,
                               relativeUrls: Array<IRelativeUrlSetting>=[]): void
    {
        const tileImages: Array<ITileImageType> = _.get(tile, "tileMarkup.tileImage", []).map(image =>
        {
            image.imageLink = relativeUrlToAbsoluteUrl(image.imageLink, relativeUrls);
            return image;
        });
        const promoImage: ITileImageType = _.get(tile, "tileMarkup.promoImage", {});
        const bgImage: ITileImageType = V2CarouselNormalizer.getBgImage(tileImages);
        const fgImage: ITileImageType = V2CarouselNormalizer.getFgImage(tileImages);
        const iconImage: ITileImageType = V2CarouselNormalizer.getIconImage(tileImages);

        normalizedTile.bgImageUrl = bgImage ? bgImage.imageLink : "";
        normalizedTile.iconImageUrl = iconImage ? iconImage.imageLink : "";
        normalizedTile.iconImageType = iconImage ? iconImage.imageClass as IconImageType : "";

        if(!bgImage && tile.tileMarkup && tile.tileMarkup.backgroundColor && tile.tileContentSubType === 'seededRadio')
        {
            normalizedTile.bgImageUrl = '../../../assets/images/artist-radio-overlay.svg';
        }
        if (promoImage && promoImage.imageLink)
        {
            normalizedTile.bgImageUrl  = relativeUrlToAbsoluteUrl(promoImage.imageLink, relativeUrls) || "";
            normalizedTile.fgImageType = CarouselTypeConst.PROMO_IMAGE_TYPE as FgImageType;
            normalizedTile.imageAltText = promoImage.imageAltText ? promoImage.imageAltText : "";
        }
        else if (fgImage)
        {
            normalizedTile.fgImageUrl = fgImage.imageLink ? fgImage.imageLink : "";
            switch (fgImage.imageClass)
            {
                case CarouselTypeConst.API_IMAGE_CENTER:
                case CarouselTypeConst.API_IMAGE_LOGO:
                    normalizedTile.fgImageType = (type !== "hero") ?
                        CarouselTypeConst.CLIENT_IMAGE_CENTER as FgImageType :
                        CarouselTypeConst.CLIENT_IMAGE_LEFT_TOP as FgImageType;
                    break;

                case CarouselTypeConst.API_IMAGE_LEFT:
                    normalizedTile.fgImageType = (type !== "hero") ?
                        CarouselTypeConst.CLIENT_IMAGE_CENTER_LEFT as FgImageType :
                        CarouselTypeConst.CLIENT_IMAGE_LEFT_TOP as FgImageType;
                    break;

                default:
                    const defaultType = (type !== "hero") ?
                        CarouselTypeConst.CLIENT_IMAGE_CENTER as FgImageType :
                        CarouselTypeConst.CLIENT_IMAGE_LEFT_TOP as FgImageType;
                    normalizedTile.fgImageType = defaultType;
                    V2CarouselNormalizer.logger.error(`Unknown logo image type detected ${type}, using ${defaultType}`);
                    break;
            }

            normalizedTile.imageAltText = fgImage.imageAltText ? fgImage.imageAltText : "" ;
        }
    }

    public static setTileAssetInfo(tile: ITileType, normalizedTile: ITile): void
    {
        const tileAssets : Array<IAssetInfoType> = _.get(tile,"tileAssetInfo",[]) as Array<IAssetInfoType>;
        const channelId: IAssetInfoType           = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === "channelId");
        const channelGuid: IAssetInfoType         = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === "channelGuid");
        const channelGUID: IAssetInfoType         = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === "channelGUID");
        const aodEpisodecaId: IAssetInfoType      = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === "aodEpisodeCaId");
        const episodeGuid: IAssetInfoType         = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === "episodeGUID");
        const showGuid: IAssetInfoType            = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === "showGUID");
        const vodEpisodeGuid: IAssetInfoType      = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === "vodEpisodeGUID");
        const categoryGuid: IAssetInfoType        = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === "categoryGUID");
        const categoryKey: IAssetInfoType         = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === "categoryKey");
        const recentPlayGuid: IAssetInfoType      = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === "recentPlayGuid");
        const recentPlayAssetGuid: IAssetInfoType = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === "recentPlayAssetGuid");
        const recentPlayType: IAssetInfoType      = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === "recentPlayType");
        const alertGuidAndType: IAssetInfoType    = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === "alertGuidAndType");
        const favAssetGuid: IAssetInfoType        = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === "favAssetGuid");
        const showName: IAssetInfoType            = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === "showName");
        const isLiveVideoEligible: IAssetInfoType = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === "isLiveVideoEligible");
        const channelName: IAssetInfoType         = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === "channelName");
        const sequencerTuneChannelId: IAssetInfoType = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === "sequencerTuneChannelId");
        const stationId: IAssetInfoType = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === "stationId");
        const tabSortOrder: IAssetInfoType    = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === CarouselTypeConst.TAB_SORT_ORDER);
        const create: IAssetInfoType = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === "create");
        const isPlaceholderShow: IAssetInfoType = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === "isPlaceholderShow");
        const hideFromChannelList: IAssetInfoType = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === "hideFromChannelList");
        const chanGuid = (channelGuid) ? channelGuid.assetInfoValue : (channelGUID) ? channelGUID.assetInfoValue : "";
        const isPodcast: IAssetInfoType = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === "isPodcast");
        const isPandoraPodcast: IAssetInfoType = tileAssets.find((assetInfo) => assetInfo.assetInfoKey === "isPandoraPodcast");

        normalizedTile.tileAssetInfo =
            {
                aodEpisodecaId        : aodEpisodecaId ? decodeURIComponent(aodEpisodecaId.assetInfoValue) : "",
                episodeGuid           : episodeGuid ? episodeGuid.assetInfoValue : "",
                channelId             : channelId ? channelId.assetInfoValue : "",
                channelGuid           : chanGuid,
                showGuid              : showGuid ? showGuid.assetInfoValue : "",
                vodEpisodeGuid        : vodEpisodeGuid ? vodEpisodeGuid.assetInfoValue : "",
                categoryGuid          : categoryGuid ? categoryGuid.assetInfoValue : "",
                categoryKey           : categoryKey ? categoryKey.assetInfoValue : "",
                recentPlayGuid        : recentPlayGuid ? recentPlayGuid.assetInfoValue : "",
                recentPlayAssetGuid   : recentPlayAssetGuid ? recentPlayAssetGuid.assetInfoValue : "",
                recentPlayType        : recentPlayType ? recentPlayType.assetInfoValue : "",
                alertGuid             : V2CarouselNormalizer.getTileAlertGuid(alertGuidAndType),
                favAssetGuid          : favAssetGuid ? favAssetGuid.assetInfoValue : "",
                showName              : showName ? showName.assetInfoValue : "",
                isLiveVideoEligible   : isLiveVideoEligible ? isLiveVideoEligible.assetInfoValue === "true" : null,
                channelName           : channelName ? channelName.assetInfoValue : "",
                sequencerTuneChannelId: sequencerTuneChannelId ? sequencerTuneChannelId.assetInfoValue : "",
                tabSortOrder          : tabSortOrder ? Number.parseInt(tabSortOrder.assetInfoValue) : 0,
                create                : create ? create.assetInfoValue === "true" : false,
                isPlaceholderShow     : isPlaceholderShow ? isPlaceholderShow.assetInfoValue === "true" : false,
                stationId             : stationId ? stationId.assetInfoValue : "",
                hideFromChannelList   : hideFromChannelList ? hideFromChannelList.assetInfoValue === "true" : false,
                isPodcast             : isPodcast ? isPodcast.assetInfoValue === "true" : false,
                isPandoraPodcast      : isPandoraPodcast ? isPandoraPodcast.assetInfoValue === "true" : false
            };

        normalizedTile.tileAssets = tileAssets;
        normalizedTile.tabSortOrder = normalizedTile.tileAssetInfo.tabSortOrder;
    }

    public static setIsTunedTo(tile:ITileType, normalizedTile: ITile) : void
    {
        normalizedTile.isTunedTo$ = new BehaviorSubject<boolean>(false);
    }

    public static setTileText(tile: ITileType, normalizedTile: ITile): void
    {
        const tileTexts: Array<ICarouselTextType> = _.get(tile,"tileMarkup.tileText",[]);

        // Set up some reasonable defaults in case we are missing data
        normalizedTile.line1 = "";
        normalizedTile.line2 = "";
        normalizedTile.line3 = "";
        normalizedTile.ariaText = "";
        normalizedTile.longDescription = "";

        normalizedTile.line1$ = new BehaviorSubject<string>(normalizedTile.line1);
        normalizedTile.line2$ = new BehaviorSubject<string>(normalizedTile.line1);
        normalizedTile.line3$ = new BehaviorSubject<string>(normalizedTile.line1);

        tileTexts.forEach((text : ICarouselTextType) =>
        {
            // If text has a field type, we set it to empty as it will be filled in later dynamically
            const setText = (!text.textField) ? DateUtil.localizeDateTime(text.textValue) : "";

            // If text has any of the following field types it is eligible for live updates.
            normalizedTile.isEligibleForLiveUpdates = (text.textField === CarouselTypeConst.ARTIST_TEXT
                || text.textField === CarouselTypeConst.TITLE_TEXT
                || text.textField === CarouselTypeConst.ARTIST_TITLE_TEXT)
                ? true : normalizedTile.isEligibleForLiveUpdates;

            switch(text.textClass)
            {
                case "line1":
                    normalizedTile.line1 = setText;
                    normalizedTile.ariaText = V2CarouselNormalizer.getAriaText(setText);
                    normalizedTile.line1$ = new BehaviorSubject<string>(setText);
                    if (text.textField) { normalizedTile.line1FieldType = text.textField; }
                    break;
                case "line2":
                    normalizedTile.line2 = setText;
                    normalizedTile.line2$ = new BehaviorSubject<string>(setText);
                    if (text.textField) { normalizedTile.line2FieldType = text.textField; }
                    break;
                case "line3":
                    normalizedTile.line3 = setText;
                    (normalizedTile.line3$ as BehaviorSubject<string>).next(setText);
                    if (text.textField) { normalizedTile.line3FieldType = text.textField; }
                    break;
                case "line3-prefix":
                    normalizedTile.line3Prefix = text;
                    break;
                case "longDescription":
                    normalizedTile.longDescription = setText;
                    break;
            }
        });

    }

    public static getAriaText(text): string
    {
        if (text.match(/•/gi))
        {
            const dateText = text.slice(0, text.indexOf("•"));
            let durationText = text.slice(text.indexOf("•"));

            return dateText + curateAriaDuration(durationText);
        }
        else
        {
            return text;
        }

        function curateAriaDuration(duration: string): string
        {
            return duration
                .replace('•', 'duration')
                .replace('m', ' minutes')
                .replace('h', ' hours');
        }
    }

    public static setTileNeriticLinkActions(tile: ITileType,
                                            normalizedTile: ITile,
                                            editable: IEditable)
    {
        const tileActions: Array<ITileActionType> = _.get(tile,"tileMarkup.tileAction",[]);

        tileActions.forEach((action : ITileActionType) =>
        {
            const neriticLink: INeriticLinkData =
                V2CarouselNormalizer.getNeriticLinkData(tile.tileContentType, tile.tileContentSubType, action, editable);

            if (!neriticLink.channelId) { neriticLink.channelId = normalizedTile.tileAssetInfo.channelId; }
            if (!neriticLink.showGuid) { neriticLink.showGuid = normalizedTile.tileAssetInfo.showGuid; }
            if (!neriticLink.contentType) { neriticLink.contentType = tile.tileContentType; }

            // There are action groups that don't apply to web, so ignore those
            if (neriticLink.functionalGroup !== neriticActionConstants.FUNCTIONAL_GROUPS.DOWNLOAD_EPISODE
                && neriticLink.functionalGroup !== neriticActionConstants.FUNCTIONAL_GROUPS.CONDITIONAL_LIVE_VIDEO
               )
            {
                normalizedTile.neriticLinkData.push(neriticLink);
            }

            if (neriticLink.functionalGroup === neriticActionConstants.FUNCTIONAL_GROUPS.PRIMARY_ACTION)
            {
                normalizedTile.primaryNeriticLink = neriticLink;
            }

            if (neriticLink.functionalGroup === neriticActionConstants.FUNCTIONAL_GROUPS.CONDITIONAL_LIVE_VIDEO)
            {
                // LiveVideo tiles are eligible for LiveUpdates, as we want to show/hide LV banner based on the LV markers
                normalizedTile.isEligibleForLiveUpdates = true;
            }
        });
    }

    public static getIconImage(tileImages: Array<ITileImageType>): ITileImageType
    {
        const findImage = (image: ITileImageType): boolean =>
        {
            return image.imageClass === CarouselTypeConst.API_IMAGE_ICON ||
                   image.imageClass === CarouselTypeConst.API_IMAGE_ICON_LEFT ||
                   image.imageClass === CarouselTypeConst.API_IMAGE_ICON_CENTER;
        };

        return tileImages.find(findImage);
    }

    /**
     * Normalizes the actionNeriticLink to NeriticLinkData
     * @param {string} tileContentType
     * @param {string} tileContentSubType
     * @param {ITileActionType} action
     * @returns {INeriticLinkData}
     */
    public static getNeriticLinkData(tileContentType: string    = "",
                                     tileContentSubType: string = "",
                                     action: ITileActionType,
                                     editable: IEditable): INeriticLinkData
    {
        //IF the tileContentType is page, we are getting the neritic action  similar to the selectors on the page level
        // so just use the same neritic normalizer as we do for the selectors
        if (tileContentType === CarouselTypeConst.PAGE_TILE)
        {
            return V2CarouselNormalizer.getSelectorSegmentNeriticLinkData(action);
        }

        if(action.actionNeriticLink)
        {
            action.actionNeriticLink = action.actionNeriticLink.replace(/App:createSeeded:/, 'Api:tune:createSeededChannel:');
        }
        if(tileContentSubType && tileContentSubType.toLowerCase() === ContentTypes.SEEDED_RADIO && editable.remove)
        {
            action.actionType = action.actionType.replace(/addToFavorites/, neriticActionConstants.FUNCTIONAL_GROUPS.REMOVE_SEEDED_STATION);
        }
        const actionSplit    = action.actionNeriticLink ? action.actionNeriticLink.split(":") : [];
        let contentType    = actionSplit.length > 3 ? actionSplit[2] : "";
        const channelId      = actionSplit.length > 3 ? decodeURIComponent(actionSplit[3]) : "";
        const assetGuid      = actionSplit.length >= 5 ? decodeURIComponent(actionSplit[4]) : "";
        const isShow         = tileContentType.toLowerCase() === CarouselTypeConst.SHOW_TILE
                               && tileContentSubType.toLowerCase() !== CarouselTypeConst.LIVE_AUDIO;
        const isCategoryTile = tileContentType.toLowerCase() === CarouselTypeConst.CATEGORY_TILE;
        const categorySplit = isCategoryTile ? actionSplit?.[2]?.split('_'): "";
        const startTime      = actionSplit.length >= 6 ? parseInt(actionSplit[5]) : 0;
        const isCollection   = tileContentType.toLowerCase() === CarouselTypeConst.COLLECTION;
        const urlSplit       = isCollection ? actionSplit?.[1]?.split('?'): "";
        const isEpisodeLiveTile  =  tileContentType.toLowerCase() === CarouselTypeConst.EPISODE_TILE
                                        && tileContentSubType.toLowerCase() === CarouselTypeConst.LIVE_AUDIO;
        contentType = isEpisodeLiveTile && action.actionType === neriticActionConstants.FUNCTIONAL_GROUPS.ADD_TO_FAVORITES
                        ? CarouselTypeConst.CHANNEL_TILE : contentType;
        // For VOD content, API will be sending showGuid as 7th arg
        const showGuid       = actionSplit.length >= 7 ? actionSplit[6]: "";

        return {
            analyticsTag   : action.actionAnalyticsTag,
            functionalGroup: action.actionType,
            linkType       : actionSplit[0],
            actionType     : isCollection && urlSplit.length > 0 ?
                                decodeURIComponent(urlSplit[0]) : decodeURIComponent(actionSplit[1]),
            contentType    : isShow || isCollection ? tileContentType : contentType,
            channelId      : isShow || isCollection ? "" : channelId,
            assetGuid      : isShow || actionSplit.length == 3 ? actionSplit[2] : assetGuid,
            showGuid       : showGuid,
            contentSubType : tileContentSubType,
            startTime      : startTime,
            url            : isCollection && urlSplit.length > 0 ? urlSplit[1] : "",
            categoryKey    : isCategoryTile ? categorySplit?.[1] : ""
        };
    }

    /**
     * Used to normalize the neriticLinkData for getSelectorSegment
     * @param {ITileActionType} action
     * @returns {INeriticLinkData}
     */
    public static getSelectorSegmentNeriticLinkData(action: ITileActionType): INeriticLinkData
    {
        const actionSplit = action.actionNeriticLink.split(":") || [];
        const urlSplit    = actionSplit[1].split("?");
        let contentType   = actionSplit[2];
        // look for any functions and assign them to contentType
        if (urlSplit[1])
        {
            urlSplit[1].split("&").forEach(item =>
            {
                let keyValuePair = item.split("=");
                if (keyValuePair[0] === "function")
                {
                    contentType = keyValuePair[1];
                }
            });
        }
        const neriticLinkData = {
            analyticsTag: action.actionAnalyticsTag,
            functionalGroup: action.actionType,
            linkType: actionSplit[0],
            actionType: actionSplit[1],
            contentType: contentType,
            channelId: "",
            assetGuid: "",
            contentSubType: "selector",
            startTime: 0,
            url: urlSplit[1] ? urlSplit[1] : ""
        };

        // change the contentType to viewAll for  CAROUSEL_ALL selector( incorporated for zoneLinksnon page related)
        if(neriticLinkData.actionType === neriticActionConstants.CAROUSEL_ALL)
        {
            neriticLinkData.assetGuid = actionSplit[2];
            neriticLinkData.contentType= neriticActionConstants.VIEW_ALL;
        }

        // TODO: Remove this once the API fixes the page_name to page-name
        neriticLinkData.url = neriticLinkData.url.replace("page_name", "page-name");
        return neriticLinkData;
    }

    /**
     * Normalizes the tile banner and assign banner colors
     * @param tile
     * @returns {ITileBanner}
     */
    public static getTileBanner(tile: any): ITileBanner
    {
        const banner                = _.get(tile, "tileMarkup.tileBanner");
        let tileBanner: ITileBanner =
                  {
                      bannerClass: "",
                      bannerText : "",
                      bannerColor: "",
                      display: true
                  };
        if (!banner)
        {
            return tileBanner;
        }

        tileBanner.bannerClass = banner.bannerClass;
        tileBanner.bannerText  = banner.bannerText;

        switch (banner.bannerClass)
        {
            case  CarouselTypeConst.UNAVAILABLE_BANNER_CLASS:
            {
                tileBanner.bannerColor = CarouselTypeConst.UNAVAILABLE_BANNER_COLOR;
                return tileBanner;
            }
            case CarouselTypeConst.CUSTOM_BANNER_CLASS :
            {
                tileBanner.bannerColor = CarouselTypeConst.CUSTOM_BANNER_COLOR;
                return tileBanner;
            }
            case CarouselTypeConst.STAFF_PICK_BANNER_CLASS:
            {
                tileBanner.bannerColor = CarouselTypeConst.STAFF_PICK_BANNER_COLOR;
                return tileBanner;
            }
            case CarouselTypeConst.SPORTS_GAME_STATE_BANNER_CLASS:
            {
                tileBanner.bannerColor = CarouselTypeConst.SPORTS_GAME_STATE_BANNER_COLOR;
                return tileBanner;
            }
            case CarouselTypeConst.TRENDING_BANNER_CLASS:
            {
                tileBanner.bannerColor = CarouselTypeConst.TRENDING_BANNER_COLOR;
                return tileBanner;
            }
            case CarouselTypeConst.POPULAR_BANNER_CLASS :
            {
                tileBanner.bannerColor = CarouselTypeConst.POPULAR_BANNER_COLOR;
                return tileBanner;
            }
            case CarouselTypeConst.ON_AIR_NOW_BANNER_CLASS:
            {
                tileBanner.bannerColor = CarouselTypeConst.ON_AIR_NOW_BANNER_COLOR;
                return tileBanner;
            }
            case CarouselTypeConst.ON_WATCH_LIVE_BANNER_CLASS:
            {
                tileBanner.bannerColor = CarouselTypeConst.ON_WATCH_LIVE_BANNER_COLOR;

                const bannerCondition = _.get(tile, 'tileMarkup.tileBanner.bannerCondition', null);
                if(bannerCondition === CarouselTypeConst.LIVE_VIDEO_BANNER_CONDITION)
                {
                    // we will keep default state as hidden, for LV banner tiles which has banner condition,
                    // and will be update in the Live PDT updates
                    tileBanner.display = false;
                }

                return tileBanner;
            }
            case CarouselTypeConst.EXPIRING_SOON_BANNER_CLASS:
            {
                tileBanner.bannerColor = CarouselTypeConst.EXPIRING_SOON_BANNER_COLOR;
                return tileBanner;
            }
            case CarouselTypeConst.SAT_ONLY_BANNER_CLASS:
            {
                tileBanner.bannerColor = CarouselTypeConst.SAT_ONLY_BANNER_COLOR;
                return tileBanner;
            }
            case CarouselTypeConst.NEW_EPISODE_BANNER_CLASS:
            {
                tileBanner.bannerColor = CarouselTypeConst.NEW_EPISODE_BANNER_COLOR;
                return tileBanner;
            }
            case  CarouselTypeConst.NEW_BANNER_CLASS:
            {
                tileBanner.bannerColor = CarouselTypeConst.NEW_BANNER_COLOR;
                return tileBanner;
            }
        }

        return tileBanner;
    }

    /**
     * Determines the fgImage
     * @param tileImages
     * @returns - image that found in the list
     */
    public static getFgImage(tileImages: Array<ITileImageType>): ITileImageType
    {
        const findImage = (image: any): boolean =>
        {
            return image.imageClass === CarouselTypeConst.API_IMAGE_LOGO
                   || image.imageClass === CarouselTypeConst.API_IMAGE_LEFT
                   || image.imageClass === CarouselTypeConst.API_IMAGE_CENTER;
        };

        return tileImages.find(findImage);
    }

    public static getBgImage(tileImages: Array<ITileImageType>): ITileImageType
    {
        const findImage = (image: any): boolean =>
        {
            return image.imageClass === CarouselTypeConst.API_IMAGE_BACKGROUND;
        };

        return tileImages.find(findImage);
    }

    /**
     * Determine what tile type to use.
     *
     * For certain types of "show" and "continue listening tiles", if the neritic link indicates that the tile
     * is tuning a live channel, then we make these tiles types where its obvious that the tile is for a channel
     * tune type action
     *
     * @param {string} type
     * @param {INeriticLinkData} neriticLinkData
     * @returns {string}
     */
    public static determineTileType(type : string, neriticLinkData : INeriticLinkData)
    {
        if (!neriticLinkData) { return type; }

        const isActionLive = neriticLinkData.contentType.toLowerCase() === CarouselTypeConst.LIVE_AUDIO;
        const isActionEpisodeLive = type.toLowerCase() === CarouselTypeConst.EPISODE_TILE
                                        && neriticLinkData.contentSubType.toLowerCase() === CarouselTypeConst.LIVE_AUDIO;
        const isActionTune = neriticLinkData.actionType.toLowerCase() === ActionTypes.TUNE;

        if ((isActionLive === true || isActionEpisodeLive === true) && isActionTune === true)
        {
            switch (type)
            {
                case CarouselTypeConst.SHOW_TILE:
                    return CarouselTypeConst.LIVE_SHOW_TILE;
                case CarouselTypeConst.CONTINUE_LISTENING_TILE:
                    return CarouselTypeConst.LIVE_CONTINUE_LISTENING_TILE;
                case CarouselTypeConst.EPISODE_TILE:
                    return CarouselTypeConst.CHANNEL_TILE;
                default:
                    return type;
            }
        }

        return type;
    }

    public static getTileAlertGuid(alertGuidAndType: IAssetInfoType): string
    {
        if (alertGuidAndType && alertGuidAndType.assetInfoList && alertGuidAndType.assetInfoList[0])
        {
            return alertGuidAndType.assetInfoList[0].assetInfoKey;
        }
        else
        {
            return '';
        }
    }

    /**
     * Normalizes the Editable object
     * @param editable
     */
    public static normalizeEditable (editable: ICarouselEditableType): IEditable
    {
        return {
            clearAll: editable && editable.clearAll ? editable.clearAll === "true" : false,
            modifyModuleType: editable && editable.modifyModuleType ? editable.modifyModuleType : "",
            remove: editable && editable.remove ? editable.remove === "true" : false,
            reorder: editable && editable.reorder ? editable.reorder === "true" : false
        };
    }
}
