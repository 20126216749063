import React, { useState, useEffect } from 'react';
import './marquee.component.scss';

const Marquee = props => {
  const { text, isFocused, setMWidth, mWidth, lightBackground } = props;

  const [content, setContent] = useState(null);
  const [hasOverflow, setHasOverflow] = useState(false);

  const getContent = () => {
    if (!hasOverflow) return props.text;

    const width =
      mWidth || (content && content.scrollWidth ? content.scrollWidth : 0);

    return (
      <span
        style={{
          animationDuration: `${Number(width) / 50}s, ${Number(width) / 25}s`,
          animationDelay: `1s, ${Number(width) / 50 + 1}s`,
          minWidth: width,
        }}
        className="marquee-moving-text"
      >
        {text}
      </span>
    );
  };

  useEffect(() => {
    if (!content) return;

    if (setMWidth) setMWidth(content.scrollWidth);

    setHasOverflow(content.offsetWidth < content.scrollWidth);
  }, [content, text]);

  return (
    <h3
      ref={e => {
        setContent(e);
      }}
      className={`marquee-text-holder ${props.className || ''} ${
        hasOverflow && isFocused ? 'marquee' : ''
      }`}
      style={{color: lightBackground ? 'black' : 'white'}}
    >
      {getContent()}
    </h3>
  );
};

export default Marquee;
