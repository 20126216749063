export class MessagingConsts {
  public static NOTIFICATIONS: string = 'notifications';
  public static FEEDBACK: string = 'feedback';
}

export class MessageTypes {
  public static MARKETING_MESSAGE: string = 'MARKETING';
  public static SHOW_REMINDER: string = 'REMINDER_S';
  public static LIVE_VIDEO_REMINDER: string = 'REMINDER_V';
  public static SUGGESTED_LIVE_VIDEO_REMINDER_HOWARD_STERN: string =
    'REMINDER_SV_HS';
  public static SUGGESTED_SHOW_REMINDER: string = 'REMINDER_SS';
  public static SUGGESTED_LIVE_VIDEO_REMINDER: string = 'REMINDER_SV';
}
