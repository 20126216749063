export class AuthenticationAnalyticsTags
{
    public static AUTH_LOGIN_SUCCESS_TYPE = "auth";
    public static AUTH_LOGIN_SUCCESS_NAME = "AuthCall";
    public static AUTH_LOGIN_SUCCESS_DESC = "Authentication Call";
    public static AUTH_LOGIN_USER_PATH = "AuthenticationCall";
    public static AUTH_LOGIN_UNSUCCESS_TYPE = "auth";
    public static AUTH_LOGIN_UNSUCCESS_NAME = "UnsuccessLogin";
    public static AUTH_LOGIN_UNSUCCESS_DESC = "Unsuccessful Login Event";
    public static AUTH_LOGIN_UNSUCCESS_USER_PATH = "UnsucessfulLogin";
}

export class BufferUnderRunAnalyticsTags
{
    public static BUFFER_UNDER_RUN_NAME = "BufUndRun";
    public static BUFFER_UNDER_RUN_MESSAGE_TYPE = "Underrun Buffer";

}

export class VideoStopAnalyticsTags
{
    public static VIDEO_STOP_NAME = "VidStop";
    public static VIDEO_STOP_MESSAGE_TYPE = "Video Stop";

}

export class TerminalAnalyticsTags
{
    public static TERMINAL_EVENT_TYPE = "unload";
    public static TERMINAL_EVENT_NAME = "TermEvent";
    public static TERMINAL_EVENT_DESC = "Terminal App Event";
    public static TERMINAL_EVENT_USERPATH = "TerminalEvent";
}

export class UserPathPositions
{
    public static END = "end";
    public static START= "Start";
}

export class AnalyticsDefaultValues
{
    public static UNKNOWN = "Unknown";
    public static DEFAULT_GUP_WHEN_UNAVAILABLE = "XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX";
}

    export class ScreenNames
    {
        public static HOME_FORYOU= "/home/foryou";
        public static HOME_FORYOU_SCREEN_NAME = "Browse.For You";

        public static HOME_MUSIC = "/home/music";
        public static HOME_MUSIC_SCREEN_NAME = "Browse.Music";

        public static HOME_SPORTS = "/home/sports";
        public static HOME_SPORTS_SCREEN_NAME = "Browse.Sports";

        public static HOME_NEWS = "/home/news";
        public static HOME_NEWS_SCREEN_NAME = "Browse.News";

        public static HOME_ENTERTAINMENT = "/home/entertainment";
        public static HOME_ENTERTAINMENT_SCREEN_NAME = "Browse.Talk";

        public static HOME_HOWARD = "/home/howard";
        public static HOME_HOWARD_SCREEN_NAME = "Browse.Howard";

        public static FAVORITES_CHANNELS= "/favorites/channels";
        public static FAVORITES_CHANNELS_SCREEN_NAME = "Favorites.Channels";

        public static FAVORITES_SHOWS= "/favorites/shows";
        public static FAVORITES_SHOWS_SCREEN_NAME = "Favorites.Shows";

        public static FAVORITES_EPISODES = "/favorites/episodes";
        public static FAVORITES_EPISODES_SCREEN_NAME = "Favorites.Episodes";

        public static RECENTS = "/recently-played";
        public static RECENTS_SCREEN_NAME = "Recent";

        public static SEARCH = "/query";
        public static SEARCH_LANDING_SCREEN_NAME = "Search.Landing";
        public static SEARCH_RESULT_SCREEN_NAME = "Search.Results";

        public static NOW_PLAYING = "/now-playing";
        public static NOW_PLAYING_SCREEN_NAME = "NowPlaying";

        public static CATEGORY_LISTING = "/category-listing";
        public static CATEGORY_LISTING_SCREEN_NAME = "Category List";

        public static MUSIC_SUPER_CATEGORY = "/music";
        public static SPORTS_SUPER_CATEGORY = "/sports";
        public static NEWS_SUPER_CATEGORY = "/news";
        public static TALK_SUPER_CATEGORY = "/entertainment";

        public static ON_DEMAND_EPISODES = "/on-demand-episodes";
        public static ON_DEMAND_EPISODES_SCREEN_NAME = "OnDemandEpisodes";

        public static VIEW_ALL = "/view-all";
        public static VIEW_ALL_SCREEN_NAME = "View All";

        public static ALL_CHANNELS = "/all-channels";
        public static ALL_CHANNELS_SCREEN_NAME = "All Channels";

        public static APPLICATION_SETTINGS = "/application-settings";
        public static APPLICATION_SETTINGS_SCREEN_NAME = "Settings.ApplicationSettings";

        public static REMINDER_SETTINGS = "/reminder-settings";
        public static REMINDER_SETTINGS_SCREEN_NAME = "Settings.ShowReminderSettings";

        public static MANAGE_SHOW_REMINDERS = "/manage-show-reminders";
        public static MANAGE_SHOW_REMINDERS_SCREEN_NAME = "Show Reminders";

        public static MESSAGING_SETTINGS = "/messaging-settings";
        public static MESSAGING_SETTINGS_SCREEN_NAME = "Settings.MessagingSettings";

        public static MANAGE_PANDORA_STATIONS = "/manage-pandora-stations";
        public static MANAGE_PANDORA_STATIONS_SCREEN_NAME = "ManageArtistRadio";

        public static COLLECTION_CAROUSEL = "/collection-carousel";
        public static COLLECTION_CAROUSEL_SCREEN_NAME = "Collection Carousel";

        public static ENHANCED_CHANNEL_EDP = "edp_channel_enhanced";
        public static ENHANCED_CHANNEL_EDP_SCREEN_NAME = "EnhancedEDP.Channel";

        public static ENHANCED_SHOW_EDP = "edp_show_enhanced";
        public static ENHANCED_SHOW_EDP_SCREEN_NAME = "EnhancedEDP.Show";

        public static SHOW_REMINDER_DETAILS = "/show-reminder-details";
        public static SHOW_REMINDER_DETAILS_SCREEN_NAME = "Show Reminder Details";

        public static CATEGORY_PAGE = "/category-page";
        public static CATEGORY_PAGE_SCREEN_NAME = "Category Page";

        public static ALL_VIDEOS = "/all-video";
        public static ALL_VIDEOS_SCREEN_NAME = "VIDEOS";

        public static PODCASTS = "/all_podcasts";
        public static PODCASTS_SCREEN_NAME = "Podcasts";

        public static LOGIN = "/login";
        public static LOGIN_SCREEN_NAME = "Login";
    }
