import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getFreeTrialCounter } from '../../redux/selector/xdk.store';
import './free-trial-counter-component.scss';
import { useLocation } from 'react-router-dom';
import { appRouteConstants } from '../../routing/app.route.constants';
import { ttsPlayback } from '../../utils/ttsPlayback.util';

let firstTimeVisit = true;

const FreeTrialCounterComponent = props => {
  const timeRemaining = useSelector(getFreeTrialCounter);
  const location = useLocation();

  useEffect(() => {
    let [hours, minutes, seconds] = timeRemaining.split(':');
    hours = Number(hours);
    minutes = Number(minutes);
    seconds = Number(seconds);

    const announceRemainingFreeTierTime = () => {
      if (isNaN(hours)) {
        timeString = timeRemaining;
      } else {
        timeString = `${hours} ${hours === 1 ? 'hour' : 'hours'}, ${minutes} ${
          minutes === 1 ? 'minute' : 'minutes'
        } and ${seconds} ${seconds === 1 ? 'second' : 'seconds'}`;
      }

      ttsPlayback(
        `Your preview expires in ${timeString}. Subscribe to serious XM, go to: siriusxm.com/vizio`,
        true,
      );
    };

    let timeString = '';

    if (
      location.pathname.includes(appRouteConstants.HOME) &&
      timeRemaining &&
      firstTimeVisit
    ) {
      firstTimeVisit = false;
      announceRemainingFreeTierTime();
    } else if (!firstTimeVisit && minutes === 0 && seconds === 0) {
      announceRemainingFreeTierTime();
    }
  }, [location.pathname, timeRemaining]);

  return (
    <div className="free-trial-counter-container">
      <div>Your preview expires in</div>
      <div className="free-trial-expiration-time">
        &nbsp;{timeRemaining}.&nbsp;
      </div>
      <div className="free-trial-subscription-url">
        Subscribe to SiriusXM, go to: siriusxm.com/vizio
      </div>
    </div>
  );
};

export default FreeTrialCounterComponent;
