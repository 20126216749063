export const MENU = {
  CORE_MENU: 'CORE_MENU',
  ZONE_MENU: 'ZONE_MENU',
} as const;

export const FOR_YOU = {
  PAGE: 'FOR_YOU',
};

export const NOW_PLAYING = {
  ID: 'now-playing',
};

export const PODCASTSVIDEO = {
  PAGE: 'PODCASTS_VIDEO',
};

export const ALLCHANNELS = {
  PAGE: 'ALL_CHANNELS',
};

export const CATEGORY = {
  PAGE: 'CATEGORY',
  CONTAINER: 'CATEGORY_CONTAINER',
  GRID: 'GRID',
};

export const COLLECTION = {
  PAGE: 'COLLECTION',
};

export const EPISODELISTING = {
  PAGE: 'EPISODE_LISTING',
};

export const ENHANCEDEDP = {
  PAGE: 'ENHANCED_EDP',
};

export const VIEWALL = {
  PAGE: 'VIEW_ALL',
};

export const FAVORITES = {
  PAGE: 'FAVORITES',
  GRID: 'FAVORITES_GRID',
};

export const RECENT = {
  PAGE: 'RECENT',
  GRID: 'RECENT_GRID',
};

export const SEARCH = {
  PAGE: 'SEARCH',
  KEYBOARD: 'KEYBOARD',
  LANDING_CAROUSEL: 'LANDING_CAROUSEL',
  KEYWORD_BOX: 'KEYWORD_BOX',
  RESULT_LIST: 'RESULT_LIST',
  RESULT_PAGE: 'RESULT_PAGE',
  CONTAINERS: 'CONTAINERS',
};

export const SETTINGS = {
  PAGE: 'SETTINGS',
};

export const CATEGORY_BUTTON_NAV = [
  'category-channels-button',
  'category-shows-button',
];

export const UP_NEXT = {
  TUNE_TO_CHANNEL_NAV: {
    id: 'tune-to-action-button',
    nextright: 'starting-action-button',
  },
  STARTING_IN_NAV: {
    id: 'starting-action-button',
    nextleft: 'tune-to-action-button',
  },
} as const;

export const EDP = {
  INFO_NAV: {
    id: 'edp-section-button-info',
    nextright: 'edp-section-button-help',
    nextdown: 'play-pandora-station-button',
    nextup: 'now-playing',
  },
  HELP_NAV: {
    id: 'edp-section-button-help',
    nextleft: 'edp-section-button-info',
    nextdown: 'sirius-xm-channels-button-help',
    nextup: 'now-playing',
  },
  SIRIUSXM_CHANNELS_NAV: {
    id: 'sirius-xm-channels-button-help',
    nextleft: 'edp-section-button-info',
    nextup: 'edp-section-button-help',
    nextdown: 'pandora-stations-button-help',
  },
  PANDORA_STATIONS_NAV: {
    id: 'pandora-stations-button-help',
    nextleft: 'edp-section-button-info',
    nextup: 'sirius-xm-channels-button-help',
    nextdown: 'video-button-help',
  },
  VIDEO_NAV: {
    id: 'video-button-help',
    nextleft: 'edp-section-button-info',
    nextup: 'pandora-stations-button-help',
    nextdown: 'now-playing',
  },
  PLAY_PANDORA_STATION_NAV: {
    id: 'play-pandora-station-button',
    nextright: 'edp-section-button-help',
    nextup: 'edp-section-button-info',
    nextdown: 'create-pandora-station-button',
  },
  CREATE_PANDORA_STATION_NAV: {
    id: 'create-pandora-station-button',
    nextright: 'edp-section-button-help',
    nextup: 'play-pandora-station-button',
    nextdown: 'add-channel-to-favorites-button',
  },
  ADD_CHANNEL_TO_FAVORITES_NAV: {
    id: 'add-channel-to-favorites-button',
    nextright: 'edp-section-button-help',
    nextup: 'create-pandora-station-button',
    nextdown: 'add-show-to-favorites-button',
  },
  ADD_SHOW_TO_FAVORITES_NAV: {
    id: 'add-show-to-favorites-button',
    nextright: 'edp-section-button-help',
    nextup: 'add-channel-to-favorites-button',
    nextdown: 'add-episode-to-favorites-button',
  },
  ADD_EPISODE_TO_FAVORITES_NAV: {
    id: 'add-episode-to-favorites-button',
    nextright: 'edp-section-button-help',
    nextup: 'add-show-to-favorites-button',
    nextdown: 'restart-show-button',
  },
  RESTART_SHOW_NAV: {
    id: 'restart-show-button',
    nextright: 'edp-section-button-help',
    nextup: 'add-episode-to-favorites-button',
    nextdown: 'go-back-1-hour-button',
  },
  GO_BACK_1_HOUR_NAV: {
    id: 'go-back-1-hour-button',
    nextright: 'edp-section-button-help',
    nextup: 'restart-show-button',
    nextdown: 'go-live-button',
  },
  GO_LIVE_NAV: {
    id: 'go-live-button',
    nextright: 'edp-section-button-help',
    nextup: 'go-back-1-hour-button',
    nextdown: 'view-all-segments-button',
  },
  VIEW_ALL_SEGMENTS_NAV: {
    id: 'view-all-segments-button',
    nextup: 'go-live-button',
    nextright: 'edp-section-button-help',
    nextdown: 'thumbs-down-button',
  },
  THUMBS_DOWN_NAV: {
    id: 'thumbs-down-button',
    nextright: 'thumbs-up-button',
    nextup: 'go-live-button',
    nextdown: 'now-playing',
  },
  THUMBS_UP_NAV: {
    id: 'thumbs-up-button',
    nextright: 'next-track-button',
    nextleft: 'thumbs-down-button',
    nextup: 'go-live-button',
    nextdown: 'now-playing',
  },
  NEXT_TRACK_NAV: {
    id: 'next-track-button',
    nextleft: 'thumbs-up-button',
    nextright: 'edp-section-button-help',
    nextup: 'go-live-button',
    nextdown: 'now-playing',
  },
};
