import { BehaviorSubject } from "rxjs";
import {
    addProvider,
    getQueryParameterValue,
    IProviderDescriptor,
    Logger,
    MediaUtil
} from "../index";
import { ChromecastConst } from "./chromecast.const";
import { ChromecastModel } from "./chromecast.model";
import { ChromecastPlayerConsts } from "../mediaplayer/chromecastplayer/chromecast-player.consts";
import { SessionTransferService } from "../sessiontransfer/session.transfer.service";
import { AppMonitorService } from "../app-monitor/app-monitor.service";
import { AppErrorCodes } from "../service/consts/app.errors";

export class ChromecastMessageBus
{
    /**
     * Internal logger.
     */
    private static logger: Logger                          = Logger.getLogger("ChromecastMessageBus");
    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(ChromecastMessageBus,
            ChromecastMessageBus,
            [
                ChromecastModel,
                SessionTransferService,
                AppMonitorService
            ]);
    }();

    /**
     * R message event
     */
    public message$: BehaviorSubject<string> = new BehaviorSubject("");

    /**
     * Flag indicating if the message bus is active and therefore capable of receiving messages.
     * @type {boolean}
     */
    public isActivated: boolean              = false;
    /**
     * Flag indicating if the Sender should log the Receiver logs.
     * @type {boolean}
     */
    private logReceiver: boolean             = false;
    /**
     * List of callback methods for receiver events. Allows objects to hooked onto and listen to message bus events from the Receiver.
     * @type {any[]}
     */
    private messageReceivers: any[]          = [];

    /**
     * Constructor
     * @param {ChromecastModel} chromecastModel
     * @param {SessionTransferService} sessionTransferService
     * @param {AppMonitorService} appMonitorService
     */
    constructor(private chromecastModel: ChromecastModel,
                private sessionTransferService: SessionTransferService,
                private appMonitorService: AppMonitorService)
    {
        this.logReceiver = getQueryParameterValue("logReceiver") === "true";
        ChromecastMessageBus.logger.debug(`constructor( logReceiver: ${this.logReceiver} )`);

    }

    /**
     * Creates the message bus that allows for communication (via events) between the Sender and Receiver if
     * there's a session.
     */
    public init(): void
    {
        const session = this.chromecastModel.getSession();

        if (session)
        {
            ChromecastMessageBus.logger.debug(`init()`);
            this.chromecastModel.setDeviceDetails();
            session.addMessageListener(
                ChromecastConst.MESSAGE_BUS_NAMESPACE,
                (unusedNamespace, event) => this.receiveMessage(unusedNamespace, event)
            );
        }
        else
        {
            ChromecastMessageBus.logger.warn(`init( Can't create the message bus as there's no session. )`);
        }
    }

    /**
     * Send a message to the Chromecast Receiver using the custom namespace.
     * @param {Object} event - Contains an event type and optional data.
     */
    public sendMessage(event): void
    {
        const session = this.chromecastModel.getSession();

        const sendMessageSuccess = () => ChromecastMessageBus.logger.debug(`sendMessageSuccess()`);
        const sendMessageFault   = (fault) => ChromecastMessageBus.logger.warn(`sendMessageFault( Fault: ${fault} )`);

        if (session !== null)
        {

            // The "ping" message fires quite a bit so we don't want to log it all the time.
            if (event.type !== ChromecastPlayerConsts.CHROMECAST_SENDER_EVENT.PING)
            {
                ChromecastMessageBus.logger.debug(`sendMessage( type = ${event.type} )`);
            }
            session.sendMessage(
                ChromecastConst.MESSAGE_BUS_NAMESPACE,
                event,
                (event) => sendMessageSuccess(),
                (fault) => sendMessageFault(fault)
            );
        }
        else
        {
            ChromecastMessageBus.logger.warn("sendMessage( No session, so can't send. )");
        }
    }

    /**
     * Adds a message receiver callback function that gets executed when a Receiver messages comes to the Sender.
     * @param {Function} callback
     */
    public addMessageReceiver(callback: Function)
    {
        if (callback)
        {
            ChromecastMessageBus.logger.debug(`addMessageReceiver()`);
            this.messageReceivers = this.messageReceivers || [];
            this.messageReceivers.push(callback);
        }
        else
        {
            ChromecastMessageBus.logger.warn(`addMessageReceiver( Can't add message receiver as the callback is falsy: ${callback} )`);
        }
    }

    /**
     * Sends a ping message to the Receiver.
     */
    public ping(): void
    {
        const event = {
            type: ChromecastPlayerConsts.CHROMECAST_SENDER_EVENT.PING,
            data: "sender"
        };

        this.sendMessage(event);
    }

    /**
     * Handles the events from the receiver.
     * @param unusedNamespace
     * @param event
     */
    private receiveMessage(unusedNamespace, event)
    {
        // Parse the JSON event data from the Receiver into a JS object.
        event = JSON.parse(event) || { type: "UNKNOWN" };

        const okToLog = (event) =>
        {
            const type: string = event && event.type ? event.type.toLowerCase() : "";
            return (type !== "log") && (type !== "ping") && (type !== "media_status");
        };

        // NOTE: DO NOT uncomment unless you're debugging the event itself as this fires quite a bit and will
        // gum up your logging console.
        if (okToLog(event))
        {
            ChromecastMessageBus.logger.info(`receiveMessage ` + JSON.stringify(event));
        }

        // Don't listen to messages other than the transfer session complete message from the Receiver until the
        // Chromecast player is ready to go. The transfer session complete message gets a pass, because this is
        // how the web client knows that the Chromecast receiver is now using the correct session.
        if (this.allowMessage(event))
        {
            // Determine the event type and respond accordingly.
            switch (event.type)
            {
                case ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.LOG:
                    if (this.logReceiver)
                    {
                        ChromecastMessageBus.logger.debug(`receiveMessage( RECEIVER: ${event.data.message} )`);
                    }
                    break;

                case ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.PING:

                    // NOTE: Keep this commented out as it fires quite a bit. Only uncomment for quick dev testing.
                    // logger.info("onMessageReceived( Ping received. )");

                    this.ping();
                    break;

                case ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.TRANSFER_SESSION_COMPLETE:
                    ChromecastMessageBus.logger.info("onMessageReceived( Transfer session complete message received. )");

                    if (!this.chromecastModel.isReconnection)
                    {
                        // Once receiver sends the transfer session complete event to sender. Then sender makes the
                        // claim status call which makes all tune calls on sender will go into browse only mode.

                        this.sessionTransferService.castStatusChange(false).subscribe(() =>
                        {
                            this.chromecastModel.triggerSessionTransferred(true);
                            // Normally when we connect to a Chromecast session we want to immediately switch players from the
                            // native web audio player to the Chromecast audio player -- this will not work when  we're reconnecting
                            // to an existing Chromecast session as there's no previous audio player which means we don't really
                            // have a valid player config object. For this reason we hold off on switching to the Chromecast
                            // audio player until the web client native player is ready to go and can provide us with a legit config.
                            this.chromecastModel.chromecastPlayer.switchPlayer(this.chromecastModel.chromecastPlayer);
                            this.chromecastModel.chromecastPlayer.startAudio();
                        }, (error) =>
                        {
                            ChromecastMessageBus.logger.debug(`Failed the status change call error - (${JSON.stringify(error)})`);
                        });
                    }
                    else if (this.chromecastModel.isReconnection)
                    {
                        if(event.data && event.data.tuneResponse)
                        {
                            if(MediaUtil.isMultiTrackAudioMediaType(event.data.mediaType))
                            {
                                this.chromecastModel.currentlyPlayingData =
                                    {
                                        channelId: event.data.channelId,
                                        assetGuid: event.data.assetGuid,
                                        mediaType: event.data.mediaType,
                                        tuneResponse: event.data.tuneResponse
                                                      ? event.data.tuneResponse
                                                      : null
                                    };

                                this.chromecastModel.triggerTracksUpdate(this.chromecastModel.currentlyPlayingData.tuneResponse);
                            }
                        }
                        this.chromecastModel.triggerSessionTransferred(true);
                    }

                    break;

                case ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.SESSION_IN_PROGRESS:

                    if (this.chromecastModel
                        && this.chromecastModel.currentlyPlayingData
                        && event.data.assetGuid === this.chromecastModel.currentlyPlayingData.assetGuid)
                    {
                        this.chromecastModel.triggerSessionTransferred(true);
                        return;
                    }

                    this.chromecastModel.currentlyPlayingData =
                        {
                            channelId: event.data.channelId,
                            assetGuid: event.data.assetGuid,
                            mediaType: event.data.mediaType,
                            tuneResponse: event.data.metaData.tuneResponse
                                          ? event.data.metaData.tuneResponse
                                          : null
                        };
                    this.chromecastModel.triggerTuneChanged({
                        channelId: this.chromecastModel.currentlyPlayingData.channelId,
                        assetGuid: this.chromecastModel.currentlyPlayingData.assetGuid,
                        mediaType: this.chromecastModel.currentlyPlayingData.mediaType
                    });

                    this.chromecastModel.triggerSessionTransferred(true);

                    break;

                // We're not using this event right now, but it's possible that the Chromecast framework breaks the pause event so
                // we've added our own to the custom Receiver code...if it breaks again we'll actually use this event.
                case ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.PAUSE:
                    // NOTE: Don't do anything until we need to...
                    break;

                case ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.STOPPED:
                    break;

                case ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.RECOVER_SESSION:
                    ChromecastMessageBus.logger.info("onMessageReceived( Recover session. )");
                    // Set the flag for a the resume API coming back successful as we need to make another resume request
                    // for the Receiver. Once the resume comes back successfully the Sender will kick off the transfer
                    // session process just as if the Sender is first connecting to the Chromecast Receiver.
                    // eventBus.dispatch(InactivityEvent.RESUME_CHECK, this);

                    break;
                case ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.VOLUME:
                    ChromecastMessageBus.logger.info(`VOLUME:` + JSON.stringify(event));
                    this.chromecastModel.triggerVolumeUpdate(event.data);
                    break;

                case ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.ERROR:
                    ChromecastMessageBus.logger.info(`onMessageReceived( Error: ${event.type} )`);
                    this.handleChromeCastErrorEvent(event.data);
                    break;
            }

            // Pass the event onto any other object's that have subscribed if allowed. The idea is to catch the
            // non-tailable events that should only be used internally in this message bus like "ping" and "log".
            if (this.allowMessagePassthrough(event))
            {
                this.message$.next(event);
                this.messageReceivers.forEach(function (fn)
                {
                    fn(event);
                });
            }
        }
    }

    /**
     * Determines if messages are allowed to be received.
     * @param {Object} event - The event object from the Chromecast Receiver.
     * @returns {Boolean}
     */
    private allowMessage(event): boolean
    {
        const type                          = event.type;
        const isTransferComplete            = type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.TRANSFER_SESSION_COMPLETE;
        const isSessionInProgress           = type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.SESSION_IN_PROGRESS;
        const isUpdateMetaData              = type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.UPDATE_METADATA;
        const isPause                       = type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.PAUSE;
        const isRecoverSession              = type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.RECOVER_SESSION;
        const isError                       = type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.ERROR;
        const isLog                         = type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.LOG;
        const isStopped                     = type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.STOPPED;
        const isMuted                       = type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.VOLUME;
        const isDisconnect                  = type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.SENDER_DISCONNECT;
        const isRecast                      = type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.SENDER_DISCONNECT_RECAST;
        const isAICTuneResponse                = type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.AIC_TUNE_RESPONSE;
        const isMediaSkipped                = type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.MEDIA_SKIPPED;
        const isMediaFinished               = type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.MEDIA_FINISHED;
        const isMediaLoaded                 = type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.MEDIA_LOADED;
        const isRefreshTracksResponse       = type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.REFRESH_TRACKS_RESPONSE;
        const isSeededTuneResponse          = type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.SEEDED_TUNE_RESPONSE;
        const isSeededRefreshTracksResponse = type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.SEEDED_REFRESH_TRACKS_RESPONSE;

        return isTransferComplete
            || isSessionInProgress
            || isUpdateMetaData
            || isPause
            || isRecoverSession
            || isError
            || isLog
            || isStopped
            || isMuted
            || isDisconnect
            || isRecast
            || isAICTuneResponse
            || isMediaSkipped
            || isMediaFinished
            || isMediaLoaded
            || isRefreshTracksResponse
            || isSeededTuneResponse
            || isSeededRefreshTracksResponse;

    }

    /**
     * Determines if messages are allowed to be received.
     * @param {Object} event - The event object from the Chromecast Receiver.
     * @returns {Boolean}
     */
    private allowMessagePassthrough(event): boolean
    {
        const type   = event.type;
        const isPing = (type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.PING);
        const isLog  = (type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.LOG);

        return !isPing && !isLog;
    }

    /**
     * Handles the error events from the receiver.
     * @param {Object} event - Native event coming from the receiver.
     */
    public handleChromeCastErrorEvent(eventData: any = {}): void
    {
        switch (eventData.type)
        {
            case ChromecastPlayerConsts.CHROMECAST_ERRORS.TRANSFER_SESSION_FAILED:
                this.appMonitorService.triggerFaultError({ faultCode: AppErrorCodes.FLTT_CHROME_CAST_SESSION_TRANSFER_ERROR });
                this.chromecastModel.triggerChromcastFatalError();
                break;
            case ChromecastPlayerConsts.CHROMECAST_ERRORS.AOD_PLAYBACK_FAILURE:
            case ChromecastPlayerConsts.CHROMECAST_ERRORS.LIVE_PLAYBACK_FAILURE:
            case ChromecastPlayerConsts.CHROMECAST_ERRORS.AUDIO_PLAYBACK_TIMEOUT:
            case ChromecastPlayerConsts.CHROMECAST_ERRORS.LIVE_FAILOVER_FAILURE:
            case ChromecastPlayerConsts.CHROMECAST_ERRORS.SIMULTANEOUS_LOGIN:
                this.appMonitorService.triggerFaultError({ faultCode: AppErrorCodes.FLTT_CHROME_CAST_GENERIC_ERROR });
                this.chromecastModel.triggerChromcastFatalError();
                break;
            default:
                break;
        }
    }
}
