import { IAction } from '../action/action.interface';
import * as ApronSegmentActions from '../action/apron-segment.action';
import { ApronSegmentAction } from '../action/apron-segment.action';
import { IApronSegmentStore } from '../selector/apron-segment.store';
import { IApronSegment } from '../../servicelib';

const initialState: IApronSegmentStore = {
  segments: [],
  displaySegments: false,
  canExpandSegmentsView: false,
  currentlyPlayingSegment: null,
};

/**
 * The default action in case none is provided. Similar to the `initialState`.
 */
const defaultAction: IAction = {
  type: '',
  payload: null,
};

/**
 * Sets apron segments data on the store.
 */
const setApronSegments: Function = (
  state: IApronSegmentStore,
  payload: IApronSegmentStore,
): IApronSegmentStore => {
  const currentState: IApronSegmentStore = getCurrentState(state);

  currentState.segments = payload.segments;
  currentState.displaySegments = payload.displaySegments;
  currentState.canExpandSegmentsView = payload.canExpandSegmentsView;
  currentState.currentlyPlayingSegment = payload.currentlyPlayingSegment;
  return currentState;
};

/**
 * Sets currently playing segment on the store.
 */
const selectCurrentlyPlayingSegment: Function = (
  state: IApronSegmentStore,
  payload: IApronSegment,
): IApronSegmentStore => {
  const currentState: IApronSegmentStore = getCurrentState(state);

  currentState.currentlyPlayingSegment = payload;
  return currentState;
};

/**
 * Gets the current state of the store as a new object.
 */
const getCurrentState: Function = (
  state: IApronSegmentStore,
): IApronSegmentStore => {
  return Object.assign({}, state);
};

/**
 * Updates the store.
 *
 * @param {IApronSegmentStore} state
 * @param {ApronSegmentAction} action
 * @returns {IApronSegmentStore}
 */
export function apronSegmentReducer(
  state = initialState,
  action: ApronSegmentAction,
): IApronSegmentStore {
  // TypeScript only provides default params for undefined, so if you pass in null they aren't used...this is
  // why we still account for falsy values with out own defaults.
  state = state || initialState;
  action = action || defaultAction;

  switch (action.type) {
    case ApronSegmentActions.SET_APRON_SEGMENTS:
      return setApronSegments(state, action.payload as IApronSegmentStore);

    case ApronSegmentActions.SELECT_CURRENTLY_PLAYING_SEGMENT:
      return selectCurrentlyPlayingSegment(
        state,
        action.payload as IApronSegment,
      );

    default:
      return state;
  }
}
