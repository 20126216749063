import React from 'react';
import { FocusButton } from '@accedo/vdkweb-tv-ui';
import './navigation-menu.component.scss';

const MenuButton = props => {
  const { children, type, isActive = true, ...rest } = props;
  const theme = {
    button: `menu-button menu-button-${type}`,
    buttonFocused: 'menu-button-focused',
    buttonActive: 'menu-button-active',
  };
  return (
    <FocusButton theme={theme} isActive={isActive} {...rest}>
      {children}
    </FocusButton>
  );
};

export default MenuButton;
