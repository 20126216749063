import { ApiCodes } from './api.codes';

export class FaultCode {
    constructor(
        public readonly faultCode: number,
        public readonly priority: number){}
}

/**
 * @description App related error codes meant for use in the UI
*/
export class AppErrorCodes {
    public static readonly FLTT_AUTHENTICATION_REQUIRED: FaultCode                           = new FaultCode(29002, 999999);
    public static readonly FLTT_AUTHENTICATION_REQUIRED_ERROR: FaultCode                     = new FaultCode(29902, 2);
    public static readonly FLTT_HTTP_AUTHENTICATION_FAILURE: FaultCode                       = new FaultCode(29020, 3);
    public static readonly FLTT_LOGOUT: FaultCode                                            = new FaultCode(25005, 3);
    public static readonly FLTT_BAD_USERNAME_PASSWORD_FIRST_3: FaultCode                     = new FaultCode(25009, 8);
    public static readonly FLTT_BAD_USERNAME_PASSWORD_AFTER_3: FaultCode                     = new FaultCode(25010, 3);
    public static readonly FLTT_EXPIRED_PROSPECT_TRIAL_CREDENTIALS: FaultCode                = new FaultCode(25011, 3);
    public static readonly FLTT_ACCOUNT_INACTIVE_ACCOUNT_SUSPENDED: FaultCode                = new FaultCode(25012, 3);
    public static readonly FLTT_ACCOUNT_LOCKOUT: FaultCode                                   = new FaultCode(25014, 3);
    public static readonly FLTT_SR_REQ_FORBIDDEN: FaultCode                                  = new FaultCode(25014, 3);
    public static readonly FLTT_EXPIRED_SUBSCRIPTION: FaultCode                              = new FaultCode(25016, 999999);
    public static readonly FLTT_BUSINESS_CREDENTIALS: FaultCode                              = new FaultCode(25018, 3);
    public static readonly FLTT_INACTIVITY_TIMEOUT: FaultCode                                = new FaultCode(13001, 2);
    public static readonly FLTT_SIMULTANEOUS_LISTEN: FaultCode                               = new FaultCode(13003, 2);
    public static readonly FLTT_SIMULTANEOUS_LISTEN_WEB: FaultCode                           = new FaultCode(13005, 999999);
    public static readonly FLTT_HTTP_TUNE_FAILURE: FaultCode                                 = new FaultCode(29018, 999999);
    public static readonly FLTT_TUNE_NON_RECOVERABLE_FAILURE: FaultCode                      = new FaultCode(9000, 2);
    public static readonly FLTT_API_TUNE_FAILURE: FaultCode                                  = new FaultCode(9999999, 999999); // To Be Defined
    public static readonly FLTT_RESUME_NO_CONTENT_FAILURE: FaultCode                         = new FaultCode(0, 999999); // To Be Defined
    public static readonly FLTT_RESUME_ERROR: FaultCode                                      = new FaultCode(15004, 2);
    public static readonly FLTT_TIMEOUT_TUNE_FAILURE: FaultCode                              = new FaultCode(9999999, 999999); // To Be Defined
    public static readonly FLTT_EXPIRED_AOD_CONTENT: FaultCode                               = new FaultCode(14013, 4);
    public static readonly FLTT_NP_AOD_PAUSE_POINT_UNAVAILABLE: FaultCode                    = new FaultCode(14014, 999999);
    public static readonly FLTT_UNAVAILABLE_CONTENT: FaultCode                               = new FaultCode(30003, 7);
    public static readonly FLTT_UNAVAILABLE_CONTENT_FROM_LOCAL_CACHE: FaultCode              = new FaultCode(30004, 7);
    public static readonly FLTT_UNAVAILABLE_NOW_PLAY_CHANNEL: FaultCode                      = new FaultCode(30005, 4);
    public static readonly FLTT_IAP_FREE_TIER_NOT_ELIGIBLE: FaultCode                        = new FaultCode(31002, 999999);
    public static readonly FLTT_IAP_FREE_TIER_EXPIRED: FaultCode                             = new FaultCode(31003, 999999);
    public static readonly FLTT_CONTENT_NOT_IN_SUBSCRIPTION_PACKAGE: FaultCode               = new FaultCode(40001, 999999);
    public static readonly FLTT_CONTENT_NOT_IN_PACKAGE_BUSINESS: FaultCode                   = new FaultCode(40002, 999999);
    public static readonly FLTT_DEEP_LINK_INVALID: FaultCode                                 = new FaultCode(40003, 999999);
    public static readonly FLTT_STREAMING_GAME_BLACKOUT: FaultCode                           = new FaultCode(29330, 1);
    public static readonly FLTT_NO_STREAMING_ACCESS: FaultCode                               = new FaultCode(29017, 999999);

    public static readonly FLTT_IT_SYSTEMS_DOWN: FaultCode                                   = new FaultCode(28029, 999999);
    public static readonly FLTT_UNAVAILABLE_GUP_DATA: FaultCode                              = new FaultCode(17002, 7);
    public static readonly FLTT_GUP_LEVEL_BYPASS_INITIATION: FaultCode                       = new FaultCode(21007, 5);
    public static readonly FLTT_GUP_ID_UNAVAILABLE_NEW_USER_BYPASS_INITIATION: FaultCode     = new FaultCode(21009, 5);
    public static readonly FLTT_GUP_LEVEL_BYPASS_ALL_SYSTEMS_RESTORED: FaultCode             = new FaultCode(21003, 5);
    public static readonly FLTT_IT_BYPASS: FaultCode                                         = new FaultCode(21018, 999999);
    public static readonly FLTT_IT_BYPASS_ALL_SYSTEMS_RESTORED: FaultCode                    = new FaultCode(21005, 5);
    public static readonly FLTT_VOD_BYPASS: FaultCode                                        = new FaultCode(21019, 999999);
    public static readonly FLTT_VOD_BYPASS_ALL_SYSTEMS_RESTORED: FaultCode                   = new FaultCode(21023, 999999);
    public static readonly FLTT_AOD_UNAVAILABLE_OR_BYPASS: FaultCode                         = new FaultCode(21014, 999999);
    public static readonly FLTT_AOD_BYPASS_ALL_SYSTEMS_RESTORED: FaultCode                   = new FaultCode(9999999, 999999); // To Be Defined
    public static readonly FLTT_SEARCH_UNAVAILABLE_OR_BYPASS: FaultCode                      = new FaultCode(21017, 5);
    public static readonly FLTT_SEARCH_BYPASS_ALL_SYSTEMS_RESTORED: FaultCode                = new FaultCode(9999999, 999999); // To Be Defined
    public static readonly FLTT_CAROUSELS_BYPASS: FaultCode                                  = new FaultCode(21020, 2);
    public static readonly FLTT_CAROUSELS_BYPASS_SYSTEM_RESTORED: FaultCode                  = new FaultCode(21021, 999999);
    public static readonly FLTT_AIC_BYPASS: FaultCode                                        = new FaultCode(21010, 2);
    public static readonly FLTT_AIC_BYPASS_SYSTEM_RESTORED: FaultCode                        = new FaultCode(21011, 999999);
    public static readonly FLTT_ARTIST_RADIO_BYPASS: FaultCode                               = new FaultCode(21024, 2);
    public static readonly FLTT_ARTIST_RADIO_BYPASS_SYSTEM_RESTORED: FaultCode               = new FaultCode(21025, 999999);

    public static readonly FLTT_VERIFYING_LOCATION: FaultCode                                = new FaultCode(22001, 8);
    public static readonly FLTT_NO_VIDEO_SYSTEM_ERROR: FaultCode                             = new FaultCode(9011, 4);
    public static readonly FLTT_VIDEO_RECOVERY_TIMEOUT_SYSTEM_ERROR: FaultCode               = new FaultCode(9012, 4);
    public static readonly FLTT_VIDEO_RECOVERED: FaultCode                                   = new FaultCode(9013, 4);
    public static readonly FLTT_GUP_UPDATE_FAILURE: FaultCode                                = new FaultCode(28023, 7);
    public static readonly FLTT_AOD_UNAVAILABLE: FaultCode                                   = new FaultCode(14020, 4);
    public static readonly FLTT_AOD_IMAGE_DOWNLOAD_FAILED : FaultCode                        = new FaultCode(14019, 999999);
    public static readonly FLTT_GUP_UPDATE_RECENTLY_PLAYED_FAILURE: FaultCode                = new FaultCode(28030, 999999);
    public static readonly FLTT_LOADING_SHOWN_MORE_THAN_5_SECONDS: FaultCode                 = new FaultCode(28021, 999999);
    public static readonly FLTT_SEARCH_FAILURE: FaultCode                                    = new FaultCode(20004, 999999);
    public static readonly FLTT_SESSION_FAILURE: FaultCode                                   = new FaultCode(0, 999999);
    public static readonly FLTT_LOCATION_RESTRICTED_CONTENT: FaultCode                       = new FaultCode(22003, 4);
    public static readonly FLTT_LOCATION_UNDETERMINED: FaultCode                             = new FaultCode(22002, 4);
    public static readonly FLTT_NO_IP_CONNECTION: FaultCode                                  = new FaultCode(2020, 2);
    public static readonly FLTT_RECENTLY_PLAYED_CLEAR_SELECTED: FaultCode                    = new FaultCode(28010, 7);
    public static readonly FLTT_CONTENT_ALERT: FaultCode                                     = new FaultCode(26001, 8);
    public static readonly FLTT_DECODER_FAILURE: FaultCode                                   = new FaultCode(8801, 999999);

    public static readonly FLTT_CHROME_CAST_GENERIC_ERROR: FaultCode                         = new FaultCode(1000, 999999);
    public static readonly FLTT_CHROME_CAST_SESSION_TRANSFER_ERROR: FaultCode                = new FaultCode(1001, 999999);
    public static readonly FLTT_CHROME_CAST_CONTENT_NOT_SUPPORTED_VOD: FaultCode             = new FaultCode(1002, 999999);
    public static readonly FLTT_CHROME_CAST_CONTENT_NOT_SUPPORTED_AIC: FaultCode             = new FaultCode(1003, 999999);
    public static readonly FLTT_CHROME_CAST_CONTENT_NOT_SUPPORTED_PODCAST: FaultCode         = new FaultCode(1004, 999999);

    public static readonly FIRST_FAKE_FAULT                                                  = new FaultCode(90000, 2);
    public static readonly FAUX_LIVE_VIDEO_REMINDER_MODAL: FaultCode                         = AppErrorCodes.FIRST_FAKE_FAULT;
    public static readonly FAUX_UNSUBSCRIBE_TO_SUBSCRIBE_MODAL: FaultCode                    = new FaultCode(90001, 2);
    public static readonly FLTT_INVALID_REQUEST: FaultCode                                   = new FaultCode(30001,999999);
    public static readonly FLTT_SR_STATION_LIMIT_REACHED: FaultCode                          = new FaultCode(324,999999);
    public static readonly FLTT_SR_NO_CLEAN_CONTENT: FaultCode                               = new FaultCode(24014,999999);
    public static readonly FLTT_SR_SOURCE_ENDED: FaultCode                                   = new FaultCode(24010,999999);
    public static readonly FLTT_SR_TUNE_FAILURE: FaultCode                                   = new FaultCode(315,999999);
    public static readonly FLTT_MODULE_ERROR: FaultCode                                      = new FaultCode(28028,999999);
    public static readonly FLTT_SATELLITE_ACCOUNT_ONLY: FaultCode                            = new FaultCode(25027,999999);
    public static readonly FLTT_MULTIPLE_SXIR: FaultCode                                     = new FaultCode(25032,999999);
    public static readonly FLTT_PLAYBACK_FAILURE: FaultCode                                  = new FaultCode(90002,999999);
    public static readonly FLTT_NO_TRACKS_TO_PLAY: FaultCode                                 = new FaultCode(90003,999999);
}

/**
 * @description array of API codes to be used with
 *      apiDelegate.addApiCodeHandler()
*/
export const authLoginCodes: Array<number> = [
    ApiCodes.INVALID_CREDENTIALS,
    ApiCodes.OAC_PASSWORD,
    ApiCodes.SATELLITE_ACCOUNT_ONLY,
    ApiCodes.ACCOUNT_LOCKED,
    ApiCodes.AUTH_REQUIRED,
    ApiCodes.EXPIRED_PROSPECT_TRIAL_ACCOUNT,
    ApiCodes.ACCOUNT_INACTIVE_ACCOUNT_SUSPENDED,
    ApiCodes.EXPIRED_SUBSCRIPTION,
    ApiCodes.BUSINESS_CREDENTIALS,
    ApiCodes.IT_DOWN,
    ApiCodes.MULTIPLE_SXIR,
    ApiCodes.SIMULTANEOUS_LISTEN,
    ApiCodes.SIMULTANEOUS_LISTEN_SAME_DEVICE,
    ApiCodes.ALC_CODE_NOT_FOUND
];

/**
 * @description array of API codes to be used with
 *      apiDelegate.addApiCodeHandler()
 */
export const tuneApiCodes: Array<number> = [
    ApiCodes.AOD_EXPIRED_CONTENT,
    ApiCodes.UNAVAILABLE_GUP_PAUSE_POINT,
    ApiCodes.UNAVAILABLE_CONTENT,
    ApiCodes.UNAVAILABLE_CONTENT_IN_CACHE,
    ApiCodes.CHANNEL_NOT_AVAILABLE,
    ApiCodes.CONTENT_NOT_IN_SUBSCRIPTION_PACKAGE,
    ApiCodes.CONTENT_NOT_IN_PACKAGE_BUSINESS,
    ApiCodes.ARTIST_RADIO_STATION_LIMIT_REACHED,
    ApiCodes.FLTT_SR_SOURCE_ENDED,
    ApiCodes.FLTT_SR_NO_CLEAN_CONTENT,
    ApiCodes.UNAVAILABLE_ONDEMAND_EPISODE,
    ApiCodes.FLTT_STREAMING_GAME_BLACKOUT,
    ApiCodes.FLTT_NO_STREAMING_ACCESS
];

/**
 * @description array of API codes to be used with
 *      apiDelegate.addApiCodeHandler()
 */
export const bypassApiCodes: Array<number> = [
    ApiCodes.GUP_BYPASS,
    ApiCodes.GUP_BYPASS2,
    ApiCodes.AOD_BYPASS,
    ApiCodes.VOD_BYPASS,
    ApiCodes.IT_BYPASS,
    ApiCodes.SEARCH_BYPASS,
    ApiCodes.CAROUSEL_BYPASS,
    ApiCodes.AIC_BYPASS,
    ApiCodes.ARTIST_RADIO_BYPASS
];

export const nonBypassOrAuthApiCodes: Array<number> = [
    ApiCodes.INVALID_COOKIE,
    ApiCodes.SESSION_RETRY,
    ApiCodes.SESSION_RESUME,
    ApiCodes.INVALID_REQUEST,
    ApiCodes.INTERNAL_API_ERROR,
    ApiCodes.GEO_LOCATION_ERROR,
    ApiCodes.MYSXM_CHANNEL_NOT_AVAILABLE,
    ApiCodes.UNAVAILABLE_RESUME_CONTENT,
    ApiCodes.GEO_LOCATION_UNAVAILABLE,
    ApiCodes.MYSXM_SETTINGS_CHANGED,
    ApiCodes.GUP_UNAVAILABLE,
    ApiCodes.REQUEST_TIMEOUT,
    ApiCodes.GATEWAY_TIMEOUT,
    ApiCodes.UNEXPECTED_ERROR,
    ApiCodes.FLTT_MODULE_ERROR,
    ApiCodes.CONSUME_FAILED,
    ApiCodes.GUP_UPDATE_FAILED,
    ApiCodes.GUP_UPDATE_RECENTLY_PLAYED_FAILED,
    ApiCodes.SEARCH_FAILURE
].concat(tuneApiCodes);



export const apiToFault: Map<number, FaultCode> = new Map<number, FaultCode>();

//auth codes
apiToFault.set(ApiCodes.INVALID_CREDENTIALS, AppErrorCodes.FLTT_BAD_USERNAME_PASSWORD_FIRST_3);
apiToFault.set(ApiCodes.MULTIPLE_SXIR, AppErrorCodes.FLTT_MULTIPLE_SXIR);

// this should map to FLTT_LOGIN_WITH_OAC_ONLY_PASSWORD ?
apiToFault.set(ApiCodes.OAC_PASSWORD, AppErrorCodes.FLTT_BAD_USERNAME_PASSWORD_FIRST_3);
apiToFault.set(ApiCodes.SATELLITE_ACCOUNT_ONLY, AppErrorCodes.FLTT_SATELLITE_ACCOUNT_ONLY);
apiToFault.set(ApiCodes.ACCOUNT_LOCKED, AppErrorCodes.FLTT_ACCOUNT_LOCKOUT);
apiToFault.set(ApiCodes.SR_REQ_FORBIDDEN,AppErrorCodes.FLTT_SR_REQ_FORBIDDEN);
apiToFault.set(ApiCodes.AUTH_REQUIRED, AppErrorCodes.FLTT_AUTHENTICATION_REQUIRED);
apiToFault.set(ApiCodes.ALC_CODE_NOT_FOUND, AppErrorCodes.FLTT_AUTHENTICATION_REQUIRED);
apiToFault.set(ApiCodes.EXPIRED_PROSPECT_TRIAL_ACCOUNT, AppErrorCodes.FLTT_EXPIRED_PROSPECT_TRIAL_CREDENTIALS);
apiToFault.set(ApiCodes.ACCOUNT_INACTIVE_ACCOUNT_SUSPENDED, AppErrorCodes.FLTT_ACCOUNT_INACTIVE_ACCOUNT_SUSPENDED);
apiToFault.set(ApiCodes.EXPIRED_SUBSCRIPTION, AppErrorCodes.FLTT_EXPIRED_SUBSCRIPTION);
apiToFault.set(ApiCodes.BUSINESS_CREDENTIALS, AppErrorCodes.FLTT_BUSINESS_CREDENTIALS);
apiToFault.set(ApiCodes.SIMULTANEOUS_LISTEN, AppErrorCodes.FLTT_SIMULTANEOUS_LISTEN);
apiToFault.set(ApiCodes.SIMULTANEOUS_LISTEN_SAME_DEVICE, AppErrorCodes.FLTT_SIMULTANEOUS_LISTEN_WEB);
apiToFault.set(ApiCodes.IT_DOWN, AppErrorCodes.FLTT_IT_SYSTEMS_DOWN);

apiToFault.set(ApiCodes.GUP_UPDATE_RECENTLY_PLAYED_FAILED, AppErrorCodes.FLTT_GUP_UPDATE_RECENTLY_PLAYED_FAILURE);
apiToFault.set(ApiCodes.GEO_LOCATION_ERROR, AppErrorCodes.FLTT_LOCATION_RESTRICTED_CONTENT);
apiToFault.set(ApiCodes.GEO_LOCATION_UNAVAILABLE, AppErrorCodes.FLTT_LOCATION_UNDETERMINED);

//gup fault code
apiToFault.set(ApiCodes.GUP_UPDATE_FAILED, AppErrorCodes.FLTT_GUP_UPDATE_FAILURE);
apiToFault.set(ApiCodes.GUP_UNAVAILABLE, AppErrorCodes.FLTT_UNAVAILABLE_GUP_DATA);

//Tune api codes
apiToFault.set(ApiCodes.AOD_EXPIRED_CONTENT, AppErrorCodes.FLTT_EXPIRED_AOD_CONTENT);
apiToFault.set(ApiCodes.UNAVAILABLE_GUP_PAUSE_POINT, AppErrorCodes.FLTT_NP_AOD_PAUSE_POINT_UNAVAILABLE);
apiToFault.set(ApiCodes.UNAVAILABLE_CONTENT, AppErrorCodes.FLTT_UNAVAILABLE_CONTENT);
apiToFault.set(ApiCodes.UNAVAILABLE_CONTENT_IN_CACHE, AppErrorCodes.FLTT_UNAVAILABLE_CONTENT_FROM_LOCAL_CACHE);
apiToFault.set(ApiCodes.CHANNEL_NOT_AVAILABLE, AppErrorCodes.FLTT_UNAVAILABLE_NOW_PLAY_CHANNEL);
apiToFault.set(ApiCodes.CONTENT_NOT_IN_SUBSCRIPTION_PACKAGE, AppErrorCodes.FLTT_CONTENT_NOT_IN_SUBSCRIPTION_PACKAGE);
apiToFault.set(ApiCodes.CONTENT_NOT_IN_PACKAGE_BUSINESS, AppErrorCodes.FLTT_CONTENT_NOT_IN_PACKAGE_BUSINESS);
apiToFault.set(ApiCodes.UNAVAILABLE_ONDEMAND_EPISODE, AppErrorCodes.FLTT_AOD_UNAVAILABLE);
apiToFault.set(ApiCodes.ARTIST_RADIO_STATION_LIMIT_REACHED, AppErrorCodes.FLTT_SR_STATION_LIMIT_REACHED);
apiToFault.set(ApiCodes.FLTT_STREAMING_GAME_BLACKOUT, AppErrorCodes.FLTT_STREAMING_GAME_BLACKOUT);
apiToFault.set(ApiCodes.FLTT_NO_STREAMING_ACCESS, AppErrorCodes.FLTT_NO_STREAMING_ACCESS);

apiToFault.set(ApiCodes.FLTT_SR_NO_CLEAN_CONTENT, AppErrorCodes.FLTT_SR_NO_CLEAN_CONTENT);
apiToFault.set(ApiCodes.FLTT_SR_SOURCE_ENDED, AppErrorCodes.FLTT_SR_SOURCE_ENDED);
apiToFault.set(ApiCodes.FLTT_MODULE_ERROR, AppErrorCodes.FLTT_MODULE_ERROR);
apiToFault.set(ApiCodes.INVALID_REQUEST, AppErrorCodes.FLTT_INVALID_REQUEST);
apiToFault.set(ApiCodes.UNAVAILABLE_RESUME_CONTENT, AppErrorCodes.FLTT_RESUME_NO_CONTENT_FAILURE);


//bypass api codes
apiToFault.set(ApiCodes.GUP_BYPASS, AppErrorCodes.FLTT_GUP_LEVEL_BYPASS_INITIATION);
apiToFault.set(ApiCodes.GUP_BYPASS2, AppErrorCodes.FLTT_GUP_ID_UNAVAILABLE_NEW_USER_BYPASS_INITIATION);
apiToFault.set(ApiCodes.IT_BYPASS, AppErrorCodes.FLTT_IT_BYPASS);
apiToFault.set(ApiCodes.VOD_BYPASS, AppErrorCodes.FLTT_VOD_BYPASS);
apiToFault.set(ApiCodes.AOD_BYPASS, AppErrorCodes.FLTT_AOD_UNAVAILABLE_OR_BYPASS);
apiToFault.set(ApiCodes.SEARCH_BYPASS, AppErrorCodes.FLTT_SEARCH_UNAVAILABLE_OR_BYPASS);
apiToFault.set(ApiCodes.CAROUSEL_BYPASS, AppErrorCodes.FLTT_CAROUSELS_BYPASS);
apiToFault.set(ApiCodes.AIC_BYPASS, AppErrorCodes.FLTT_AIC_BYPASS);
apiToFault.set(ApiCodes.ARTIST_RADIO_BYPASS, AppErrorCodes.FLTT_ARTIST_RADIO_BYPASS);

// restored bypass
// Removing FLTT_GUP_LEVEL_BYPASS_ALL_SYSTEMS_RESTORED error map, as we dont have to show the dialog
// box for GUP restoration

// apiToFault.set(ApiCodes.GUP_BYPASS, AppErrorCodes.FLTT_GUP_LEVEL_BYPASS_ALL_SYSTEMS_RESTORED);
apiToFault.set(ApiCodes.IT_BYPASS, AppErrorCodes.FLTT_IT_BYPASS_ALL_SYSTEMS_RESTORED);
apiToFault.set(ApiCodes.VOD_BYPASS, AppErrorCodes.FLTT_VOD_BYPASS_ALL_SYSTEMS_RESTORED);
apiToFault.set(ApiCodes.AOD_BYPASS, AppErrorCodes.FLTT_AOD_BYPASS_ALL_SYSTEMS_RESTORED);
apiToFault.set(ApiCodes.SEARCH_BYPASS, AppErrorCodes.FLTT_SEARCH_BYPASS_ALL_SYSTEMS_RESTORED);
apiToFault.set(ApiCodes.CAROUSEL_BYPASS, AppErrorCodes.FLTT_CAROUSELS_BYPASS_SYSTEM_RESTORED);
apiToFault.set(ApiCodes.AIC_BYPASS, AppErrorCodes.FLTT_AIC_BYPASS_SYSTEM_RESTORED);
apiToFault.set(ApiCodes.ARTIST_RADIO_BYPASS, AppErrorCodes.FLTT_ARTIST_RADIO_BYPASS_SYSTEM_RESTORED);


//other?
apiToFault.set(ApiCodes.SEARCH_FAILURE, AppErrorCodes.FLTT_SEARCH_FAILURE);
