import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useObservable } from '../../hooks';
import {
  getIsVideo,
  getIsSeededRadio,
  getIsIrisPodcast,
  getIsAIC,
  getIsLive,
  getIsAod,
  selectNowPlayingState,
} from '../../redux/selector/now-playing.store';
import {
  ServiceFactory,
  MediaPlayerService,
  CurrentlyPlayingService,
  InitializationService,
  InitializationStatusCodes,
  ResumeService,
} from '../../servicelib';
import { AudioPlayerEventMonitor } from '../../servicelib/src/mediaplayer/audio-player.event-monitor';
import { VideoPlayerEventMonitor } from '../../servicelib/src/mediaplayer/videoplayer/video-player.event-monitor';

const HandleAutoplayOnStartup = ({
  AUTOPLAY_STATES,
  setAutoplayOnStartup,
  autoplayOnStartup,
}) => {
  const nowPlayingStore = useSelector(selectNowPlayingState);
  const isVideo = useSelector(getIsVideo);
  const isSeededRadio = useSelector(getIsSeededRadio);
  const isPodcast = useSelector(getIsIrisPodcast);
  const isAIC = useSelector(getIsAIC);
  const isAod = useSelector(getIsAod);
  const isLive = useSelector(getIsLive);

  const mediaPlayerService = ServiceFactory.getInstance(
    MediaPlayerService,
  ) as MediaPlayerService;
  const videoPlayerEventMonitor = ServiceFactory.getInstance(
    VideoPlayerEventMonitor,
  ) as VideoPlayerEventMonitor;
  const audioPlayerEventMonitor = ServiceFactory.getInstance(
    AudioPlayerEventMonitor,
  ) as AudioPlayerEventMonitor;
  const initializationService = ServiceFactory.getInstance(
    InitializationService,
  ) as InitializationService;
  const currentlyPlayingService = ServiceFactory.getInstance(
    CurrentlyPlayingService,
  ) as CurrentlyPlayingService;
  const resumeService = ServiceFactory.getInstance(
    ResumeService,
  ) as ResumeService;

  const initState = useObservable(initializationService.initState);
  const resumeMedia = useObservable(resumeService.resumeMedia);

  useEffect(() => {
    const subscription = initializationService.initState.subscribe(
      initState => {
        if (initState === InitializationStatusCodes.RUNNING) {
          currentlyPlayingService.triggerCurrentlyPlayingData();
        }
      },
    );

    return () => subscription.unsubscribe();
  }, []);

  useEffect(() => {
    if (
      initState === InitializationStatusCodes.RUNNING &&
      resumeMedia &&
      !isVideo &&
      mediaPlayerService.mediaPlayer
    ) {
      if (isLive || isAod) {
        const subscription = mediaPlayerService.mediaPlayer.playbackReady$.subscribe(
          isPlaybackReady => {
            if (isPlaybackReady) {
              currentlyPlayingService.triggerCurrentlyPlayingData();
              if (autoplayOnStartup === AUTOPLAY_STATES.AUTOPLAY) {
                //Autoplay is not restricted on the X1, but it is in Chrome unless the --autoplay-policy=no-user-gesture-required flag is entered
                setTimeout(() => {
                  //Wrapping this call in a timeout to allow it to be resumed after the initial pause that the player does. This is a fix
                  //for when the user logs out and the logs in again while leaving the AUTOPLAY ON STARTUP option set as on.
                  mediaPlayerService.mediaPlayer.resume().subscribe();
                }, 100);
              }

              setAutoplayOnStartup(AUTOPLAY_STATES.COMPLETE);
            }
          },
        );

        return () => subscription.unsubscribe();
      } else if (isSeededRadio) {
        const subscription = audioPlayerEventMonitor.playbackReady$.subscribe(
          isPlaybackReady => {
            if (autoplayOnStartup === AUTOPLAY_STATES.INITIALIZATION) {
              const clip = (resumeMedia as any)?.data?.clipList?.clips[0];
              nowPlayingStore.playhead.id = clip && clip.trackToken;
              currentlyPlayingService.setCurrentPlayingData(
                nowPlayingStore.playhead,
                '',
              );
            }

            //Autoplay for Pandora Stations can't be tested in Chrome. This currently only works on the X1
            mediaPlayerService.mediaPlayer.initiateAutoPlay(
              autoplayOnStartup === AUTOPLAY_STATES.AUTOPLAY,
            );

            setAutoplayOnStartup(AUTOPLAY_STATES.COMPLETE);
          },
        );

        return () => subscription.unsubscribe();
      } else if (isPodcast) {
        const subscription = audioPlayerEventMonitor.playbackReady$.subscribe(
          isPlaybackReady => {
            if (isPlaybackReady) {
              currentlyPlayingService.triggerCurrentlyPlayingData();
              if (autoplayOnStartup === AUTOPLAY_STATES.AUTOPLAY) {
                //Autoplay for Podcasts can't be tested in Chrome. This currently only works on the X1
                mediaPlayerService.mediaPlayer.resume().subscribe();
              }
              setAutoplayOnStartup(AUTOPLAY_STATES.COMPLETE);
            }
          },
        );

        return () => subscription.unsubscribe();
      } else if (isAIC) {
        const subscription = videoPlayerEventMonitor.playbackReady$.subscribe(
          isPlaybackReady => {
            currentlyPlayingService.triggerCurrentlyPlayingData();

            if (autoplayOnStartup === AUTOPLAY_STATES.AUTOPLAY) {
              //Autoplay is not restricted on the X1, but it is in Chrome unless the --autoplay-policy=no-user-gesture-required flag is entered
              mediaPlayerService.mediaPlayer.initiateAutoPlay(
                autoplayOnStartup === AUTOPLAY_STATES.AUTOPLAY,
              );
            }

            setAutoplayOnStartup(AUTOPLAY_STATES.COMPLETE);
          },
        );

        return () => subscription.unsubscribe();
      }
    }
  }, [mediaPlayerService.mediaPlayer, initState, resumeMedia]);

  return <div></div>;
};

export default HandleAutoplayOnStartup;
