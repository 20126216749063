import {
  IProviderDescriptor,
  addProvider,
  Logger,
  HttpProvider,
  IAppConfig,
  ServiceEndpointConstants,
} from '../index';
import { IFreeTierScreenInfo } from './free-tier.interface';

//import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { BehaviorSubject } from 'rxjs';
//import { Observable } from "rxjs/internal/Observable";
import { Observable } from 'rxjs';

/**
 * @MODULE:     service-lib
 * @CREATED:    04/16/2021
 * @COPYRIGHT:  2021 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *      Free Tier Delegate to fetch data from API
 */

export class FreeTierDelegate {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('FreeTierDelegate');

  private screensInfoSubject: BehaviorSubject<
    IFreeTierScreenInfo[]
  > = new BehaviorSubject([]);
  public screensInfo$: Observable<IFreeTierScreenInfo[]>;

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(FreeTierDelegate, FreeTierDelegate, [
      HttpProvider,
      'IAppConfig',
    ]);
  })();

  constructor(private http: HttpProvider, SERVICE_CONFIG: IAppConfig) {
    this.screensInfo$ = this.screensInfoSubject;
  }

  public getFreeTierScreenInfo(): void {
    FreeTierDelegate.logger.debug('getFreeTierScreenInfo()');

    const endPoint =
      ServiceEndpointConstants.endpoints.FREE_TIER.V5_SCREEN_INFO +
      '?screenName=iap_screens&platform=web&isMLR=true';
    if (this.screensInfoSubject.getValue().length == 0) {
      this.http.get(endPoint, null).subscribe(data => {
        this.screensInfoSubject.next(data.screenInfo ? data.screenInfo : []);
      });
    }
  }
}
