import { BehaviorSubject, SubscriptionLike as ISubscription } from "rxjs";
import { filter } from "rxjs/operators";
import { Logger } from "../logger";
import { AppErrorCodes } from "../service/consts";
import { MediaPlayerFactory } from "./media-player.factory";
import { AppMonitorService, ClientFault } from "../app-monitor";
import { ChromecastPlayerService } from "./chromecastplayer";
import { IMediaPlayer } from "./media-player.interface";
import { ChromecastPlayerConsts } from "./chromecastplayer/chromecast-player.consts";
import { MediaUtil } from "./media.util";
import { ICurrentlyPlayingMedia } from "../tune";
import { addProvider, IProviderDescriptor } from "../service";
import { CurrentlyPlayingService } from "../currently-playing";
import { MediaPlayerConstants } from "./media-player.consts";
import { MediaPlayerModel } from "./media-player.model";

export class MediaPlayerService
{
    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(
            MediaPlayerService,
            MediaPlayerService,
            [ MediaPlayerFactory, CurrentlyPlayingService, AppMonitorService, ChromecastPlayerService, MediaPlayerModel]
        );
    }();

    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("MediaPlayerService");

    public mediaPlayer: any = null;
    public mediaPlayerSubscriptions: Array<ISubscription> = [];
    public playheadSubFunctions: Array<Function> = [];
    public isMediaContentPlaying: boolean = true;
    public isContentPlaying = new BehaviorSubject<boolean>(this.isMediaContentPlaying);
    private currentlyPlayingMedia: ICurrentlyPlayingMedia;
    public playerType: string = "audio";

    private pausableFaults = [AppErrorCodes.FLTT_IT_SYSTEMS_DOWN,
        AppErrorCodes.FLTT_SIMULTANEOUS_LISTEN,
        AppErrorCodes.FLTT_STREAMING_GAME_BLACKOUT,
        AppErrorCodes.FLTT_SIMULTANEOUS_LISTEN_WEB];

    constructor(
        private mediaPlayerFactory: MediaPlayerFactory,
        private currentlyPlayingService: CurrentlyPlayingService,
        private appMonitorService: AppMonitorService,
        public chromecastPlayerService: ChromecastPlayerService,
        public mediaPlayerModel : MediaPlayerModel
    )
    {
        this.observeCurrentlyPlayingData();

        this.appMonitorService.faultStream.pipe(filter((fault: ClientFault)  =>
            this.pausableFaults.indexOf(fault.faultCode) > -1
        )).subscribe(() =>
        {
            if(this.mediaPlayer && this.mediaPlayer.mediaId && this.isMediaContentPlaying)
            {
                this.mediaPlayer.pause();
            }
        });

        this.chromecastPlayerService.switchPlayerType$.pipe(
            filter((state: string) => !!state))
            .subscribe((state: string) =>
            {
                this.playerType = state;
                return this.resetMediaPlayer(state);
            });
    }

    public isPlaying(): boolean
    {
        return this.mediaPlayer
            ? this.mediaPlayer.isPlaying()
            : false;
    }

    public isPaused(): boolean
    {
        return this.mediaPlayer
            ? this.mediaPlayer.isPaused()
            : false;
    }

    public isNewMedia({ mediaId = "" }: { mediaId: string }): boolean
    {
        if (mediaId === "") { return false; }
        if (!this.currentlyPlayingMedia) { return true; }

        return this.currentlyPlayingMedia.mediaId !== mediaId;
    }

    public resetMediaPlayer(mediaType: string = ""): IMediaPlayer
    {
        return this.updateNewMediaPlayer(this.retrieveMediaPlayer(mediaType));
    }

    private updatePlayingState(playbackState: string): boolean
    {
        this.mediaPlayerModel.playbackState$.next(playbackState);
        const isPlaying = this.mediaPlayer ? this.mediaPlayer.isPlaying() : false;

        if (playbackState !== MediaPlayerConstants.SEEKING &&
            playbackState !== MediaPlayerConstants.UNDER_FLOWED)
        {
            this.isContentPlaying.next(isPlaying);
        }
        return this.isMediaContentPlaying = isPlaying;
    }

    public retrieveMediaPlayer(mediaType: string = ""): IMediaPlayer
    {
        mediaType = mediaType || this.currentlyPlayingMedia.mediaType;
        return this.mediaPlayerFactory.getMediaPlayer(mediaType);
    }

    private updateNewMediaPlayer(newMediaPlayer: IMediaPlayer): IMediaPlayer
    {
        this.mediaPlayer = newMediaPlayer;
        if(newMediaPlayer.playbackState)
        {
            this.mediaPlayerSubscriptions.forEach((subscription: ISubscription) => subscription.unsubscribe());
            this.mediaPlayerSubscriptions = [];
            this.playheadSubFunctions.forEach((func: Function) =>
            {
                if(!newMediaPlayer.playhead$)
                {
                    return;
                }
                this.mediaPlayerSubscriptions.push(
                    newMediaPlayer.playhead$.subscribe(playhead =>
                    {
                        func(playhead);
                    })
                );
            });

            try
            {
                this.mediaPlayerSubscriptions
                    .push(newMediaPlayer.playbackState
                            .subscribe((playbackState: string) => this.updatePlayingState(playbackState)));

                this.mediaPlayerSubscriptions
                    .push(newMediaPlayer.restart$.pipe( filter(state => !!state))
                            .subscribe((event: boolean) => this.mediaPlayerModel.restart$.next(true)));

                this.mediaPlayerSubscriptions
                    .push(newMediaPlayer.bufferEmpty$.pipe(filter(val => !!val))
                           .subscribe((val: boolean) => this.mediaPlayerModel.bufferUnderFlow$.next(true)));

            }
            catch(e)
            {
                // DO nothing as the `playbackState` wasn't valid yet.
            }
        }

        return this.mediaPlayer;
    }

    private observeCurrentlyPlayingData(): ISubscription
    {
        return this.currentlyPlayingService.currentlyPlayingData.subscribe( (data: ICurrentlyPlayingMedia) =>
        {
            const currentMediaType: string = this.currentlyPlayingMedia && this.currentlyPlayingMedia.mediaType || "";
            const isNewMediaType: boolean = !!data && data.mediaType != "" && currentMediaType !== data.mediaType;
            const isOnDemand: boolean = MediaUtil.isOnDemandMediaType(currentMediaType);

            if (!!data && data.mediaType !== "") { this.currentlyPlayingMedia = data; }

            if(this.playerType === ChromecastPlayerConsts.TYPE) { return; }

            if (isNewMediaType || (isOnDemand && this.isNewMedia(data)))
            {
                this.resetMediaPlayer(data.mediaType);
            }
        });
    }
}
