export class ContextualConstants
{
    //TODO - Get correct urls
    public static PRIMARY_PLAYER         = "player.siriusxm";
    public static PRIMARY_LANDING        = "deeplink.siriusxm";
    public static BETA_PLAYER            = "playerbeta.siriusxm";
    public static BETA_LANDING           = "deeplinkbeta.mountain.siriusxm";
    public static COMPANY_BETA_PLAYER    = "companybeta.siriusxm.com";
    public static PRIMARY_DEV_PLAYER     = "local-dev.siriusxm";
    public static PRIMARY_DEV_LANDING    = "local-landing.siriusxm";
    public static ANDROID                = "android";
    public static IOS                    = "ios";
    public static WINDOWS                = "windows";
    public static UNKNOWN                = "unkown";
    public static CONTENT_TYPE           = "contentType";
    public static CHANNEL_NAME           = "channelName";
    public static GO_TO_STORE            = "goToStore";
    public static DESKTOP                = "desktop";
    public static MOBILE                 = "mobile";
    public static FB_BROWSER_ANDRIOD     = "fbBrowserAndroid";
    public static SIGN_UP_URL_USA        = "https://siriusxm.com/playergetstarted";
    public static SIGN_UP_URL_CA_ENGLISH = "https://www.siriusxm.ca/playergetstarted";
    public static SIGN_UP_URL_CA_FRENCH  = "https://www.siriusxm.ca/commencerencontinu";
    public static REGION_US              = "US";
    public static ALC_CODE               = "ALC";
}
