export class EllipsisStringUtil {
  public static ellipsisString(str: string, ellipsisValue: number): string {
    if (!str) return;

    let newEllipsisValue = ellipsisValue - 3;

    return str.length > newEllipsisValue
      ? str.substr(0, newEllipsisValue).concat('...')
      : str;
  }

  /**
   * formats text of the element and applies clamping based on number of lines provided in options.
   * @param {HTMLElement} element to clamp.
   * @param {object} options for clamping.
   * @returns {object} with before/after clamp state of the text
   */
  public static clamp(element: any, options: any = {}): any {
    let opt = {
        clamp: options.clamp || 2,
        useNativeClamp:
          typeof options.useNativeClamp != 'undefined'
            ? options.useNativeClamp
            : true,
        truncationChar: options.truncationChar || '…',
      },
      sty = element.style,
      originalText = element.innerHTML,
      supportsNativeClamp =
        typeof element.style.webkitLineClamp !== 'undefined',
      clampValue = opt.clamp,
      clampedText = { original: originalText, clamped: originalText };

    if (supportsNativeClamp && opt.useNativeClamp) {
      sty.overflow = 'hidden';
      sty.textOverflow = 'ellipsis';
      sty.webkitBoxOrient = 'vertical';
      sty.display = '-webkit-box';
      sty.webkitLineClamp = clampValue;
      sty.whiteSpace = 'normal';
    } else {
      if (clampValue > 1) {
        sty.whiteSpace = 'normal';
        clampedText = applyEllipsisByJS(
          element,
          opt.truncationChar,
          clampValue,
        );
      }
    }

    function applyEllipsisByJS(elem, truncationChar, numberOfLines) {
      let startLength = 0;
      let endLength = elem.innerHTML.trim().length;
      let theText = elem.innerHTML.trim();
      let finalText = theText;
      let fontSize = parseFloat(
        window.getComputedStyle(elem, null).getPropertyValue('font-size'),
      );

      if (Math.floor(elem.clientHeight / fontSize) - 1 > numberOfLines) {
        do {
          theText = finalText;
          elem.innerHTML = theText + ' WWWW';
          while (Math.floor(elem.clientHeight / fontSize) - 1 > numberOfLines) {
            const newLength = startLength + (endLength - startLength) / 2;
            finalText = theText;

            theText = theText.substring(0, newLength);
            elem.innerHTML = theText + ' WWW';
            endLength = theText.length;
          }
          startLength = endLength;
          endLength = finalText.length;
        } while (endLength > startLength + 1);
        elem.innerHTML = theText + ' ...';
      }
      return elem.innerHTML.trim();
    }

    return clampedText;
  }
}
