import { MediaPlayerConstants } from '../media-player.consts';

export class AudioPlayerConstants extends MediaPlayerConstants {
  public static LIVE = 'playbackType.liveChannel';
  public static AOD = 'playbackType.aodEpisode';
  public static MY = 'playbackType.myChannel';
  public static SEEDED = 'playbackType.seededAudio';
  public static PODCAST = 'playbackType.MP3';

  public static REFRESH_PLAYER_BUFFER = 'refreshPlayerBuffer';
  public static KEY_PATH = '/rest/streaming';

  public static PRIMARY_END_POINT = 'primary';
  public static SECONDARY_END_POINT = 'secondary';

  public static LARGE_SIZE = 'large';
  public static MEDIUM_SIZE = 'medium';
  public static SMALL_SIZE = 'small';

  public static LIVE_PRIMARY_HLS = '%Live_Primary_HLS%';
  public static LIVE_SECONDARY_HLS = '%Live_Secondary_HLS%';
  public static SA_LIVE_PRIMARY_HLS = '%Sa_Live_Primary_HLS%';
  public static SA_SECONDARY_PRIMARY_HLS = '%Sa_Live_Secondary_HLS%';
  public static AOD_PRIMARY_HLS = '%OnDemand_Primary_HLS%';
  public static AOD_SECONDARY_HLS = '%OnDemand_Secondary_HLS%';
  public static AIC_PRIMARY_HLS = '%AIC_Primary_HLS%';

  public static LIVE_PRIMARY_URL = 'Live_Primary_HLS';
  public static LIVE_SECONDARY_URL = 'Live_Secondary_HLS';
  public static SA_LIVE_PRIMARY_URL = 'Sa_Live_Primary_HLS';
  public static SA_LIVE_SECONDARY_URL = 'Sa_Live_Secondary_HLS';
  public static AOD_PRIMARY_URL = 'OnDemand_Primary_HLS';
  public static AOD_SECONDARY_URL = 'OnDemand_Secondary_HLS';
  public static AIC_PRIMARY_URL = 'AIC_Primary_HLS';

  public static TYPE: string = 'Audio';
  public static MP3 = 'MP3';
}
