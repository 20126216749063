import { Observable, BehaviorSubject } from "rxjs";

export const mockV2RawTileData  = {
    tileAssetInfo : [
        {
            assetInfoKey : "channelGUID",
            assetInfoValue : "bbb593ac-ffc2-389b-ac13-179405cfe0b6"
        },
        {
            assetInfoKey: "recentPlayGuid",
            assetInfoValue: "ca1e84d7-55f2-4c44-a954-54e2a01a214f"
        }
    ],
    tileContentStatus : "",
    tileContentType : "",
    tileContentSubType :"",
    tileMarkup : {
        tileAction: [
            {
                actionNeriticLink: "Api:tune:liveAudio:9138",
                actionAnalyticsTag: "",
                actionType: "primaryAction"
            }
        ],
        tileImage: [
            {
                imageAltText : "everest tile channel background",
                imageClass : "background",
                imageLink : "http://pix.com/background.jpg"
            },
            {
                imageAltText : "everest tile channel background",
                imageClass : "logo",
                imageLink : "http://pix.com/logo.jpg"
            }
        ],
        tileText: [
            {
                textClass : "line1",
                textValue : "Ch 4"
            }
        ],
        tileBanner: {
            bannerClass: "",
            bannerText : "New Episode"
        }
    }
};

export const mockNormalizedTile = {
    bgImageUrl: "http://pix.com/background.jpg",
    fgImageUrl: "http://pix.com/logo.jpg",
    isTunedTo: new BehaviorSubject<boolean>(false),
    line1: "Ch 4",
    line1$: Observable.create((observer) => observer.next("Ch 1")),
    line2: "description",
    line2$: Observable.create((observer) => observer.next("description")),
    cutAssetGuid: "",
    neriticLinkData: [{
        analyticsTag: "",
        functionalGroup: "primaryAction",
        linkType: "Api",
        actionType: "tune",
        contentType: "liveAudio",
        channelId: "9138",
        assetGuid: ""
    }],
    primaryNeriticLink: {
        analyticsTag: "",
        functionalGroup: "primaryAction",
        linkType: "Api",
        actionType: "tune",
        contentType: "liveAudio",
        channelId: "9138",
        assetGuid: "DSADS767687687DSADSA1"
    },
    superCategoryInfo: {
        categoryGuid : "",
        isPrimary : false,
        name : "",
        aodShowCount: 0,
        description: "",
        sortOrder: 1,
        url: "",
        key: "Music"
    },
    subCategoryInfo: {
        key: "pop",
        onDemandPullTime: 0,
        description: "",
        aodShowCount: 0,
        sortOrder: 1,
        url: "",
        categoryGuid: "",
        isPrimary: false,
        name: "",
        channelList : [{
            length: 28,
            channelNumber: 9138,
            clientBufferDuration: 0,
            disableRecommendations: false,
            aodShowCount: 0,
            inactivityTimeout: 120,
            isAvailable: false,
            isBizMature: false,
            isFavorite: false,
            isMature: false,
            isMySxm: false,
            isPersonalized: false,
            isPlayByPlay: false,
            mediumDescription: "",
            shortDescription: "",
            siriusChannelNumber: 0,
            sortOrder: 0,
            spanishContent: false,
            streamingName: "",
            url: "",
            xmChannelNumber: 0,
            xmServiceId: 0,
            tuneMethod: "",
            satOnly: false,
            subscribed : false,
            isGeoRestricted: false
        }]
    },
    reminders: {
        showReminderSet: false,
        liveVideoReminderSet: false
    },
    images : [],
    tileBanner : {
        bannerClass: "",
        bannerText: "",
        bannerColor: "",
        display:true
    },
    tileAssets : [
        {
            assetInfoKey : "channelId",
            assetInfoValue : "howardstern101",
            assetInfoList: []
        },
        {
            assetInfoKey : "showGUID",
            assetInfoValue : "DSADS767687687DSADSA",
            assetInfoList: []
        }
    ],
    tileAssetInfo : {
        channelId: "channelId",
        showGuid : "DSADS767687687DSADSA",
        isPandoraPodcast: false
    }
};
