/* eslint-disable */
import {
  forkJoin as observableForkJoin,
  throwError as observableThrowError,
  of as observableOf,
  timer as observableTimer,
  BehaviorSubject,
  Observable,
} from 'rxjs';
import {
  first,
  flatMap,
  map,
  mergeMap,
  skipWhile,
  filter,
  skip,
} from 'rxjs/operators';
import { CarouselService } from '../carousel';
import { ISuperCategory } from '../channellineup';
import { IAppConfig } from '../config/interfaces/app-config.interface';
import {
  IAppByPassState,
  IProviderDescriptor,
  ISession,
  PerformanceUtil,
} from '../index';
import { addProvider } from '../service';
import { AppMonitorService } from '../app-monitor';
import { AuthenticationService } from '../authentication';
import { ChannelLineupService } from '../channellineup';
import { ConfigService } from '../config';
import { InitializationStatusCodes } from './initialization.const';
import { Logger } from '../logger';
import { mockForYouData } from '../channellineup';
import { ResumeService } from '../resume';
import { SearchService } from '../search';
import { SettingsService } from '../settings';
import { BypassMonitorService } from '../app-monitor';
import { ProfileService } from '../profile/profile.service';
import { StorageKeyConstant } from '../storage/storage.constants';
import { StorageService } from '../storage/storage.service';
import { InitializationModel } from './initialization.model';

/**
 * @MODULE:     service-lib
 * @CREATED:    08/16/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *      Kicks off the initialization of the application by calling the following services upon successful
 *      authentication:
 *
 *      1) Subscribe to the authentication service and proceed with app initialization when the user is authenticated.
 *      2) Make the API calls that can be performed in parallel -- this currently everything sans the Resume API
 *     endpoint.
 *      3) Make the sequential API calls after the parallel API calls have successfully completed.
 *
 */

export class InitializationService {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('InitializationService');

  /**
   * The backing variable for the current state of the initialization process.
   * @type {string}
   * @private
   */
  private _state: string = undefined;

  /**
   * This flag is here to monitor the user's status and if they are suddenly unauthenticated we need to make sure to
   * run the initialization API sequence again once they are authenticated.
   * @type {boolean}
   * @private
   */
  private _isAuthenticated: boolean = false;

  /**
   * This flag is here to hold the resume call status.
   */
  private _isResumeSuccessfull: boolean = false;

  /**
   * The default state for the initialization service.
   * @type {string}
   * @private
   */
  private _defaultState: string = InitializationStatusCodes.UNCONFIGURED;

  /**
   * subject for delivering the initialization service state through an observable
   * @type {any}
   */
  private initStateSubject: BehaviorSubject<string>;

  /**
   * Internal setter for the current state of the initialization process.
   * @param {string} value
   * @private
   */
  private set __state(value: string) {
    if (this._state === value) return;

    this._state = value;

    //TODO : vpaindla Need to move this logic to model . Due to hotfix keeping logic simple.
    this.initializationModel.setInitializationState(value);

    if (
      value === InitializationStatusCodes.RUNNING ||
      value === InitializationStatusCodes.RELOADING ||
      value === InitializationStatusCodes.UNAUTHENTICATED ||
      value === InitializationStatusCodes.UNAUTHENTICATED_RESTART ||
      value === InitializationStatusCodes.OPENACCESS
    ) {
      this.initStateSubject.next(value);
    }
  }

  /**
   * Getter for the current state of the initialization process.
   * @returns {string}
   */
  public get state(): string {
    return this._state;
  }

  /**
   * subject for delivering the initialization service state through an observable
   * @type {any}
   */
  public initState: Observable<string>;

  /**
   * This getter/setter (and private backing store) controls which carousel (if any) we want to preload on startup.
   *
   * This can be set by the client to change which carousel is fetched before putting the app into the init state.
   *
   * @type {string}
   * @private
   */
  private _superCategoryToPreCache: string = mockForYouData.key;
  public set superCategoryToPreCache(key: string) {
    if (key) {
      this._superCategoryToPreCache = key;
    }
  }

  public get superCategoryToPreCache() {
    return this._superCategoryToPreCache;
  }

  private appByPassState: IAppByPassState;
  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(InitializationService, InitializationService, [
      AuthenticationService,
      ChannelLineupService,
      CarouselService,
      ConfigService,
      ResumeService,
      SettingsService,
      StorageService,
      ProfileService,
      SearchService,
      AppMonitorService,
      BypassMonitorService,
      InitializationModel,
      'IAppConfig',
    ]);
  })();

  /**
   * Constructor.
   * @param {ConfigService} configService will be used to configure the app on startup
   * @param {AuthenticationService} authenticationService will be used to determine if we are authenticated
   * @param {ChannelLineupService} channelLineupService will be used to load the channel lineup on startup
   * @param {carouselService} carouselService will be used to prefetch the supercategory carousels on startup
   * @param {ResumeService} resumeService will be used to resume the app from where the user left off on startup
   * @param {SettingsService} settingsService will ne used to prefetch the users settings on startup
   * @param {StorageService} storageService is used to get pause points
   * @param {ProfileService} profileService to prefetch the users profile data on startup
   * @param {SearchService} searchService will be used to prefetch the users recent searches on startup
   * @param {SearchService} appMonitorService will be used to monitor app events
   * @param {SearchService} bypassMonitorService will be monitor the app in bypass mode
   */
  constructor(
    private authenticationService: AuthenticationService,
    private channelLineupService: ChannelLineupService,
    private carouselService: CarouselService,
    private configService: ConfigService,
    private resumeService: ResumeService,
    private settingsService: SettingsService,
    private storageService: StorageService,
    private profileService: ProfileService,
    private searchService: SearchService,
    private appMonitorService: AppMonitorService,
    private bypassMonitorService: BypassMonitorService,
    private initializationModel: InitializationModel,
    private SERVICE_CONFIG: IAppConfig,
  ) {
    PerformanceUtil.markMoment(PerformanceUtil.moments.INIT_APP);
    //this.checkIfHttp();

    this.__state = this._defaultState;
    this.initStateSubject = new BehaviorSubject(this.state);
    this.initState = this.initStateSubject;

    this.initStateSubject.next(this.state);

    this.listenToAppByPassState();
    /**
     * Note: only need to ever call config once
     * @type {Observable<boolean>}
     */
    const configObs = this.configService.getConfig();

    this.authenticationService.userSession.subscribe((session: ISession) => {
      if (
        session.authenticated === true &&
        (this.state === InitializationStatusCodes.UNCONFIGURED ||
          this.state === InitializationStatusCodes.UNINITIALIZED ||
          this.state === InitializationStatusCodes.UNAUTHENTICATED)
      ) {
        this.init(configObs);
      } else if (
        session.authenticated === true &&
        session.activeOpenAccessSession === true &&
        this.state === InitializationStatusCodes.OPENACCESS &&
        !this.SERVICE_CONFIG.isFreeTierEnable
      ) {
        this.init(observableOf(true));
      } else if (
        session.authenticated === false &&
        authenticationService.isOpenAccessEligible() === false
      ) {
        this.__state = InitializationStatusCodes.UNAUTHENTICATED;
      }
    });
  }

  /**
   * Determines if the current protocol is HTTP or HTTPS; if HTTP then reroute to HTTPS.
   *
   * NOTE: Ideally this should be performed by the web server itself, but we'll do it here as a quick
   * workaround solution since it's not done there yet and we don't have control over the server.
   *
   * NOTE: We do not want to run this in Karma as we don't want to reload the app for unit tests.
   *
   * TODO: BMR: 03/16/2018: Consider removing this method and it's usage if and when it's implemented on the web server.
   */
  public checkIfHttp(): void {
    const isUnitTests: boolean = !window['__karma__'];

    if (isUnitTests && window.location.protocol !== 'https:') {
      location.href = location.href.replace('http://', 'https://');
    }
  }

  /**
   * Kicks off the initialization process for the application. The process looks like this:
   *
   *      1) Subscribe to the auth service and proceed with app initialization when the user is authenticated.
   *      2) Make a resume call to determine if the app is really authenticated
   *      3) Get app bootstrap data to complete app intiialization
   */
  public async init(config: Observable<Boolean>): Promise<void> {
    InitializationService.logger.debug(`init( State = ${this.state} )`);

    this.__state = this._defaultState;

    const configDone = await config.toPromise();

    if (this.SERVICE_CONFIG.isFreeTierEnable) {
      // FreeTier
      observableOf(configDone)
        .pipe(map(checkAuth.bind(this)), mergeMap(callResume.bind(this)))
        .subscribe(onFTSuccess.bind(this));
    } else {
      //Open Access
      observableOf(configDone)
        .pipe(
          map(checkAuth.bind(this)),
          mergeMap(callResume.bind(this)),
          mergeMap(getBootstrapData.bind(this)),
          mergeMap(getProfileData.bind(this)),
        )
        .subscribe(onSuccess.bind(this), onError.bind(this));
    }

    // To make app ready once user subscribed (openPeriodState = false)
    this.resumeService.onOpenPeriodState$
      .pipe(
        skip(1),
        filter(val => !val),
      )
      .subscribe(isOpenPeriodState => {
        observableOf(true)
          .pipe(
            mergeMap(getBootstrapData.bind(this)),
            mergeMap(getProfileData.bind(this)),
          )
          .subscribe(onSuccess.bind(this), onError.bind(this));
      });

    /**
     * Setting State as Running after resume success only for FreeTier flow.
     */
    function onFTSuccess(resumeResult: boolean) {
      if (!resumeResult && this.authenticationService.isOpenAccessEligible()) {
        this.__state = InitializationStatusCodes.OPENACCESS;
      } else if (resumeResult) {
        this.__state = InitializationStatusCodes.RUNNING;
      }
    }

    /**
     * Do some logging and return an observable of the users session
     * @returns {Observable<ISession>}
     */
    function checkAuth(configResult: boolean): Observable<any> {
      if (configResult === false) {
        InitializationService.logger.error(`Config FAILED `);
        throw 'config failed';
      }

      InitializationService.logger.debug(
        `Subscribing to user session ( State = ${this.state} )`,
      );

      this.__state = InitializationStatusCodes.INITIALIZING;
      return this.authenticationService.userSession;
    }

    /**
     * Call resume of app is authenticated and return the resume observable, return an errored observable if
     * app is not authenticated
     * @returns {any}
     */
    function callResume(): Observable<any> {
      if (
        this.authenticationService.isAuthenticated() ||
        this.SERVICE_CONFIG.isFreeTierEnable
      ) {
        InitializationService.logger.debug(
          `Making resume call( State = ${this.state} )`,
        );

        this.__state = InitializationStatusCodes.RESUMING;

        const pausePoint = this.storageService.getParsedItem(
          StorageKeyConstant.PAUSEPOINT,
        );

        return this.resumeService.resume(pausePoint).pipe(
          flatMap((result: boolean) => {
            if (result !== true) {
              // Here we have a problem.  This problem occurs when the user is sent to the login page, or
              // refreshes the login page.
              //
              // In this case, even though the login screen is displayed, an API resume will still be
              // triggered.  If this resume is slow, then the resume call above will just return the
              // result from the first resume call (which has not returned yet).  This resume will fail,
              // because we are on the login page and thus not authenticated.
              //
              // So, if we detect that resume failed, but we are authenticated, we will trigger another
              // resume on a timer.  This will give the resume call that failed time to be fully handled
              // by the resume service, and the subsequent call to resume will be successful because we
              // are indeed authenticated at this point.
              //
              // This is mostly caused by the fact that the resume service has to be coded carefully so that
              // we do not send out multiple resume requests at once or fire off resumes too quickly.
              // However, those protections on the resume service end up causing us problems here.

              if (this.authenticationService.isAuthenticated() === true) {
                InitializationService.logger.error(
                  `Resume call failed in authenticated state ... retry`,
                );
                return observableTimer(100).pipe(
                  first(),
                  mergeMap((): Observable<boolean> => callResume.bind(this)()),
                );
              }
            }

            return observableOf(result);
          }),
        );
      } else {
        return observableThrowError(false);
      }
    }

    /**
     * Get the data needed to bootstrap the app (in parellel API calls), and then join all the parallel
     * observables into an array of objects from one observable
     *
     * @param resumeResult is true if the resume was successful and false othersise
     * @returns  Observable of an array of booleans, indicating the results from previous calls in the init process
     */
    function getBootstrapData(resumeResult: boolean): Observable<Array<any>> {
      this._isAuthenticated = true;
      this._isResumeSuccessfull = resumeResult;
      InitializationService.logger.debug(
        `Getting data needed to boostrap the app ( State = ${this.state} )`,
      );

      if (resumeResult === true) {
        let observables: Array<Observable<any>> = [
          this.channelLineupService.getChannelLineup(),
          this.settingsService.retrieveSettings(),
          this.searchService.getRecentSearchResults(),
        ];

        return observableForkJoin(observables);
      } else {
        return observableOf([false]);
      }
    }

    /**
     * Get the recently played data
     * @param statusArray contains the status of all the api calls precending this in the app initialize phase
     * @returnsObservable of an array of booleans, indicating the results from previous calls in the init process
     */
    function getProfileData(statusArray: Array<boolean>): Observable<any> {
      const allCallsSuccessful = InitializationService.checkStatusArray(
        statusArray,
      );

      if (allCallsSuccessful === true) {
        return this.profileService.getProfileData().pipe(
          map((result: boolean) => {
            statusArray.push(result);
            return statusArray;
          }),
        );
      } else {
        statusArray.push(false);
        return observableOf(statusArray);
      }
    }

    /**
     * If any initialization steps fail, then log an error and put the service back into the uninitialized state
     * @param error that was encountered trying to initialize the app
     */
    function onError(error: any): any {
      if (this.authenticationService.isAuthenticated()) {
        InitializationService.logger.error(
          `Init failure ${JSON.stringify(error)} in state ${this.state}`,
        );
        this.__state = InitializationStatusCodes.UNINITIALIZED;
      } else if (this.authenticationService.isOpenAccessEligible()) {
        InitializationService.logger.debug('User is Open Access eligible');
        //We need to set this once the user clicks on the try it out button to trigger the OA process
        //This onError function would never be reached on subsequent app boots since the resume call
        //will always return a code 100, confirming that the user is authenticated if they had already
        //started the OA process
        //this.__state = InitializationStatusCodes.OPENACCESS;
        this.__state = InitializationStatusCodes.UNAUTHENTICATED;
      } else if (this._isResumeSuccessfull === true) {
        this.__state = InitializationStatusCodes.UNAUTHENTICATED_RESTART;
      } else {
        this.__state = InitializationStatusCodes.UNAUTHENTICATED;
      }
    }

    /**
     * If all initialization steps are successful, then we can put the initialization service into the RUNNING state
     */
    function onSuccess(apiCallStatus: Array<boolean>) {
      const allCallsSuccessful = InitializationService.checkStatusArray(
        apiCallStatus,
      );

      if (allCallsSuccessful === true) {
        // Now that the app is in the running state, prefetch all the supercategory carousels so that
        // the user can select the supercategories without too much API lag
        this.channelLineupService.channelLineup.superCategories
          .pipe(
            skipWhile(
              (superCategories: Array<ISuperCategory>) =>
                superCategories.length == 0,
            ),
            first(),
          )
          .subscribe((superCategories: Array<ISuperCategory>) => {
            //this.__state = InitializationStatusCodes.RUNNING;
            //InitializationService.logger.debug(`Initialization complete ( State = ${this.state} )`);

            /**
             * NOTE: If we want to prefetch carousels, the following code should be uncommented, and the
             * line above where we set __state should be removed
             *
             * If we do this, we will hit the API with multiple carousel calls.  If the API can handle it
             * the user experience will be better, but if the API cannot handle this then we need to make sure
             * we do not pre-cache.
             *
             * We also don't prefetch carosuels if we are in CAROUSEL_BYPASS mode in app Initialization
             */

            if (this.appByPassState.CAROUSEL_BYPASS) {
              this.__state = InitializationStatusCodes.RUNNING;
              return;
            }

            this.carouselService
              .preFetchCarousels(superCategories, [
                this.superCategoryToPreCache,
              ])
              .pipe(first())
              .subscribe(() => {
                InitializationService.logger.debug(
                  `Precache carousel complete`,
                );
                InitializationService.logger.debug(
                  `Initialization complete ( State = ${this.state} )`,
                );
              });

            this.__state = InitializationStatusCodes.RUNNING;
          });
      } else {
        onError.bind(this)('One or more init calls failed');
      }
      PerformanceUtil.markMoment(PerformanceUtil.moments.APP_INITIALIZED);
    }
  }

  /**
   * Kicks off the initialization process to be in reloading state.
   * @param {Function} reloadFunction - function will be called after we put init service into reloading state.
   */
  public reload(reloadFunction: Function): void {
    this.__state = InitializationStatusCodes.RELOADING;

    reloadFunction();
  }

  /**
   * Check an array of booleans and returns false if there is one or more false's in the array, otherwise returns
   * true.
   *
   * @param apiCallStatus is the array of booleans to check, each boolean represents one of the api calls that need
   *        to be made to intiialize the app.
   * @returns {boolean}
   */
  private static checkStatusArray(apiCallStatus: Array<boolean>): boolean {
    const failedCall = apiCallStatus.find((result: boolean) => {
      return result === false;
    });

    return failedCall === undefined ? true : false;
  }

  /**
   * will hold the app By pass state
   */
  private listenToAppByPassState() {
    this.bypassMonitorService.bypassErrorState.subscribe(appByPassState => {
      this.appByPassState = appByPassState;
    });
  }

  /**
   * Method used to trigger the Open Access authentication process in the InitializationService by setting the
   * OPENACCESS state in it.
   */
  public initiateOpenAccess(): void {
    if (
      this.initializationModel.state ===
        InitializationStatusCodes.UNAUTHENTICATED &&
      this.authenticationService.isOpenAccessEligible()
    ) {
      this.__state = InitializationStatusCodes.OPENACCESS;
    }
  }
}
