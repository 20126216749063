import { Observable } from "rxjs";
import { HttpProvider } from "../http";
import { addProvider, IProviderDescriptor } from "../service";
import { ServiceEndpointConstants } from "../service/consts";
import { Logger } from "../logger";

/**
 * @MODULE:     service-lib
 * @CREATED:    04/22/19
 * @COPYRIGHT:  2019 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *      This delegate is responsible for logging analytics events.
 */
export class SxmAnalyticsDelegate
{
    private static providerDescriptor : IProviderDescriptor = function()
    {
        return addProvider(
            SxmAnalyticsDelegate,
            SxmAnalyticsDelegate,
            [HttpProvider]
        );
    }();

    /**
     * Internal logger.
     * @type {Logger}
     */
    private static logger: Logger = Logger.getLogger("AlertDelegate");

    constructor(private httpProvider: HttpProvider) {}

    /**
     * Post the tag data to the endpoint
     * @param data
     */
    public postAnalyticsCall(data): Observable<any>
    {
        SxmAnalyticsDelegate.logger.debug(data);
        return this.httpProvider.post(ServiceEndpointConstants.endpoints.ANALYTICS.V1_ANALYTICS_REPORT, data);
    }
}
