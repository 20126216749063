/**
 * These client codes used in geolocation and tune service observables to send response codes.
 */
export class ClientCodes
{
    public static SUCCESS: number                                = 0;
    public static CONTENT_NOT_SUPPORTED_ON_REMOTE_PLAYER: number = 100;
    public static ERROR: number                                  = -1;
    public static AIC_IN_BYPASS_MODE                             = 101;
    public static ARTIST_RADIO_IN_BYPASS_MODE                    = 102;
}
