export type DeepLinkType
    = "live"
    | "channel"
    | "aod"
    | "vod"
    | "livevideo"
    | "xtra"
    | "collection"
    | "seededradio"
    | "show"
    | "podcasts"
    | "category"
    | "podcast";

export class DeepLinkTypes
{
    public static LIVE         = "live";
    public static LIVE_VIDEO   = "livevideo";
    public static CHANNEL      = "channel";
    public static AOD          = "aod";
    public static VOD          = "vod";
    public static AIC          = "xtra";
    public static COLLECTION   = "collection";
    public static PANDORA      = "pandora"; // Deeplink Url comes with pandora
    public static SEEDED_RADIO = "seededradio"; // API expects to send seededRadio when deeplink type is Pandora
    public static VIDEOS       = "videos";
    public static SHOW         = "show";
    public static PODCASTS     = "podcasts";
    public static PODCAST      = "podcast";
    public static CATEGORY     = "category";
}

export interface IContextualData
{
    imageUrl: string;
    title: string;
    title2?: string;
    subTitle: string;
    description: string;
    originalAirDate: string;
    expirationDate: string;
    buttonText: string;
    isSpecial: boolean;
    deepLinkType: string;
}

export interface IContextualLandingData
{
    contentType: string;
    channelName: string;
    isIOS: boolean;
    iosVersion: number;
    universalLink: string;
    appDeepLink?: string;
    storeUrl: string;
    operatingSystem: string;
    contextualLandingAppInfo: IContextualLandingAppInfo;
    contextualData?: IContextualData;
    actionButtonText: string;
    actionButtonUrl: string;
}

export interface IContextualLandingAppInfo
{
    appImageSrc: string;
    accessibiltyLabel: string;
    gtmId: string;
}
