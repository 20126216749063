import  {of as observableOf, Observable , BehaviorSubject } from 'rxjs';
import { share, catchError, mergeMap } from 'rxjs/operators';
import * as _                    from 'lodash';
import {
    IProviderDescriptor,
    addProvider,
    ApiCodes
} from "../service";
import {
    ISetting,
    ISettings
}                                from "./globalSettings.interface";
import { SettingsDelegate }      from "./settings.delegate";
import { SettingsConstants }     from "./settings.const";
import { ClientFault }           from "../app-monitor";
import { convertToBoolOrString } from "../util";
import { Logger }                from "../logger";
import { AppErrorCodes }         from "../service/consts";
import { AppMonitorService }     from "../app-monitor";

/**
 * @MODULE:     service-lib
 * @CREATED:    09/18/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *  SettingsService used to get and update Global and discover settings.
 */

export class SettingsService
{
    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("SettingsService");

    /**
     * settings holds the device and global settings
     */
    private settingsList: ISettings = null;

    /**
     * subject for delivering device settings through settings Observable.
     * @type {any}
     */
    private settingsSubject: BehaviorSubject<ISettings> = null;

    /**
     * An observable (hot, subscribe returns most recent item) that can be used to obtain the deviceSettingsList &
     * GlobalSettingsƒList and be notified when the deviceSettingsList data changes.
     */
    public settings: Observable<ISettings>;

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor : IProviderDescriptor = function()
    {
        return addProvider(
            SettingsService,
            SettingsService,
            [SettingsDelegate, AppMonitorService]
        );
    }();

    /**
     * Constructor
     * @param {SettingsDelegate} settingsDelegate
     * @param {AppMonitorService} appMonitorService
     */
    constructor(private settingsDelegate: SettingsDelegate,
                public appMonitorService: AppMonitorService)
    {
        this.settingsSubject = new BehaviorSubject(this.settingsList);
        this.settings = this.settingsSubject;
    }

    /**
     * Used to update the settings. After updated the settings returns the new set of Global & Device settings list.
     * Assign list to the globalSettingsList and deviceSettingsList then kick of the observable.
     * @param {Array<ISetting>} updatedSettings
     * @returns {Observable<Boolean | {}>}
     */
    public updateSettings(updatedSettings: ISettings): Observable<Boolean | {}>
    {
        const response: Observable<ISettings> = this.settingsDelegate.updateSettings(updatedSettings);

        return this.onSettings(response);
    }

    /**
     * Used to get the global and device settings list.
     * Assign list to the globalSettingsList and deviceSettingsList then kick of the observable.
     * @returns {Observable<Boolean | {}>}
     */
    public retrieveSettings(): Observable<Boolean | {}>
    {
        const settings: Observable<ISettings> = this.settingsDelegate.getSettings();

        return this.onSettings(settings);
    }

    /**
     * Gets the device setting value for given key.
     * @param {string} name
     * @returns {string}
     */
    public getDeviceSettingValue(name: string): boolean | string
    {
        if(!this.settingsList) return null;
        return this.getSettingValue(this.settingsList.deviceSettings, name);
    }

    /**
     * Gets the global setting value for given key
     * @param {string} name
     * @returns {boolean}
     */
    public getGlobalSettingValue(name: string): boolean | string
    {
        if(!this.settingsList) return null;
        return this.getSettingValue(this.settingsList.globalSettings, name);
    }

    /**
     * Gets a setting value for given key.
     * @param {Array<ISetting>} settings - List of settings.
     * @param {string} name
     * @returns {string}
     */
    public getSettingValue(settings: Array<ISetting>, name: string): boolean | string
    {
        let result = _.find(settings, findSetting);

        if (result)
        {
          return convertToBoolOrString(result.value);
        }
        else if (this.settingShouldDefaultToOn(name))
        {
          return SettingsConstants.ON;
        }
        else
        {
          return null;
        }

        function findSetting(setting: ISetting): boolean
        {
            return setting.name ? setting.name.toLowerCase() === name.toLowerCase() : false;
        }
    }

    /**
     * Function for checking whether a specific setting should default to 'on'.
     * If a setting has not been changed before it may not show up in the
     * settings list we get from the api. Normally we assume that if it's not in
     * the list then its off. This function is for returns true for specific settings
     * in the case that they are not in the settings list.
     * @param {string} name - setting name
     * @returns {boolean}
     */
    public settingShouldDefaultToOn(name: string): boolean
    {
      return name === SettingsConstants.MINI_PLAY;
    }

    /**
     * Determines if global setting is on based on the current setting for it.
     * @returns {boolean}
     */
    public isGlobalSettingOn(name: string): boolean
    {
        const globalSetting: string = this.getGlobalSettingValue(name) as string;
        return globalSetting ? globalSetting.toLowerCase() === SettingsConstants.ON.toLowerCase() : false;
    }


    /**
     * Determines if device setting is on based on the current setting for it.
     * @returns {boolean}
     */
    public isDeviceSettingOn(name: string): boolean
    {
        const deviceSetting: string = this.getDeviceSettingValue(name) as string;
        return deviceSetting ? deviceSetting.toLowerCase() === SettingsConstants.ON.toLowerCase() : false;
    }

    /**
     * Determines if the tune start setting is on or off.
     * @returns {boolean}
     */
    public isTuneStartOn(): boolean
    {
        return this.isGlobalSettingOn(SettingsConstants.TUNE_START);
    }

    /**
     * Determines if the audio quality is higher.
     * @returns {boolean}
     */
    public isHigherAudioQuality(): boolean
    {
        const audioQuality = this.getAudioQuality();
        if (audioQuality)
        {
            return audioQuality.toLowerCase() === SettingsConstants.AUDIO_QUALITY_HIGH.toLowerCase();
        }
        return false;
    }

    /**
     * Determines if the audio quality is maximum.
     * @returns {boolean}
     */
    public isMaximumAudioQuality(): boolean
    {
        const audioQuality = this.getAudioQuality();
        return audioQuality ? audioQuality.toLowerCase() === SettingsConstants.AUDIO_QUALITY_MAXIMUM.toLowerCase() : false;
    }

    /**
     * Gets the current audio quality setting.
     * @returns {string}
     */
    public getAudioQuality(): string
    {
        return this.getDeviceSettingValue(SettingsConstants.AUDIO_QUALITY) as string;
    }

    /**
     * Determines if the Show Reminder setting is on or off.
     * @returns {boolean}
     */
    public isShowRemindersOn(): boolean
    {
        return this.isGlobalSettingOn(SettingsConstants.NOTIFICATION_SUBSCRIPTION_SHOW_REMINDERS);
    }

    /**
     * Determines if the suggested Show Reminder setting is on or off.
     * @returns {boolean}
     */
    public isSuggestedShowRemindersOn(): boolean
    {
        return this.isGlobalSettingOn(SettingsConstants.NOTIFICATION_SUBSCRIPTION_SUGGESTED_SHOW);
    }

    /**
     * Determines if the thumbs up/down coachmark setting is on or off.
     * @returns {boolean}
     */
    public isCoachmarkFlagOn(coachmark): boolean
    {
        return this.isGlobalSettingOn(SettingsConstants[coachmark]);
    }

    public isPandoraXModalFlagOn(): boolean
    {
        return this.isGlobalSettingOn(SettingsConstants.DISPLAY_PANDORAX_FEATURE_MODAL);
    }

    /**
     * Determines if the suggested live Video Reminder setting is on or off.
     * @returns {boolean}
     */
    public isSuggestedLiveVideoRemindersOn(): boolean
    {
        return this.isGlobalSettingOn(SettingsConstants.NOTIFICATION_SUBSCRIPTION_SUGGESTED_LIVE_VIDEO);
    }

    /**
     * Gets the Show reminder setting.
     * @returns {boolean}
     */
    public isGeneralSettingOn(settingName, defaultValue=false): boolean
    {
        let settingValue:string = this.getSettingValue(this.settingsList.globalSettings, settingName) as string;
        return settingValue ? settingValue.toLowerCase() === SettingsConstants.ON.toLowerCase() : defaultValue;
    }

    /**
     * Sets the global setting value to on or off in global settings list
     * @param {boolean} newBool
     * @param {string} settingName
     * @returns {Observable<boolean | {}>}
     */
    public switchGlobalSettingOnOrOff(newBool: boolean, settingName: string): Observable<boolean | {}>
    {
        const newVal: string = newBool ? SettingsConstants.ON : SettingsConstants.OFF;
        const newArr: ISetting[] = this.updateSettingsArray(newVal, settingName, this.settingsList.globalSettings);
        this.settingsList.globalSettings = newArr;
        return this.updateSettings(this.settingsList);
    }

    /**
     * Sets the global setting value in the global settings list to any string
     * @param {string} value
     * @param {string} settingName
     * @returns {Observable<boolean | {}>}
     */
    public setGlobalSettingValue(value: string, settingName: string): Observable<boolean | {}>
    {
        const newArr: ISetting[] = this.updateSettingsArray(value, settingName, this.settingsList.globalSettings);
        this.settingsList.globalSettings = newArr;
        return this.updateSettings(this.settingsList);
    }

    /**
     * Set the device and global settings data after delegate returns the data and returns the boolean value.
     * @param  response - Observable is returned by the delegate.
     * @returns {Observable<boolean>} - the API is return data successfully then returns true otherwise returns false
     */
    private onSettings(response: Observable<ISettings>): Observable<boolean>
    {
        response.subscribe(onSettingsAvailable.bind(this), onSettingsFault.bind(this));

        //TODO - Can we clean this up, and make it more succinct.
        return response.pipe(mergeMap((response: any) =>
            {
                if (!response)
                {
                    return observableOf(false);
                }

                return observableOf(true);

            }),
            catchError((error) =>
            {
                if(error && error.code === ApiCodes.GUP_BYPASS)
                {
                    return observableOf(true);
                }
                return observableOf(false);
            }),
            share());

        /**
         * Once the settings becomes available, save it to the global and device settings.
         * @param settings -  is returned by the delegate.
         */
        function onSettingsAvailable(settings: ISettings)
        {
            if (!settings)
            {
                SettingsService.logger.error(`onSettingsAvailable - data empty ( ${settings} )`);
            }
            this.settingsList = settings;
            this.settingsSubject.next(this.settingsList);
        }

        /**
         * Once delegate Throws exception then fault handler get called.
         * @param error - error is returned by the delegate
         */
        function onSettingsFault(error: Error)
        {
            SettingsService.logger.error(`onSettingsFault - Error (${JSON.stringify(error)})`);
        }
    }

    /**
     * Checks if show reminder toggle is off.
     * If it is off it presents the user with a popup
     * that can turn it on again.
     */
    public runSubscribeFlow(): void
    {
        if (!this.isGeneralSettingOn(
                SettingsConstants.NOTIFICATION_SUBSCRIPTION_SHOW_REMINDERS,
                true))
        {
            const clientFault = {
                faultCode: AppErrorCodes.FAUX_UNSUBSCRIBE_TO_SUBSCRIBE_MODAL
            } as ClientFault;

            this.appMonitorService.triggerFaultError(clientFault);
        }
    }

    /**
     * This method updates a setting array with a new value.
     * @param {string} value
     * @param {string} name
     * @param {ISetting[]} settingsArr
     */
    private updateSettingsArray(value: string, name: string, settingsArr: ISetting[]): ISetting[]
    {
        let setting: ISetting = settingsArr
            .find(setting => setting.name === name);

        if (setting)
        {
            setting.value = value;
        }
        else
        {
            setting = { name: name, value: value };
            settingsArr.push(setting);
        }
        return settingsArr;
    }
}
