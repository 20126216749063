export class SettingsConstants
{
    public static ON                                             = "on";
    public static OFF                                            = "off";
    public static AUDIO_QUALITY_NORMAL                           = "Normal";
    public static AUDIO_QUALITY_HIGH                             = "High";
    public static AUDIO_QUALITY_MAXIMUM                          = "Maximum";
    public static AUTO_PLAY                                      = "AutoPlay";
    public static AUTO_PLAY_NEXT_EPISODE                         = "AutoPlayNextEpisode";
    public static CROSS_DEVICE_RESUME                            = "TurnOffCrossDeviceResume";
    public static SCREEN_LOCK                                    = "ScreenLockOverride";
    public static TUNE_START                                     = "TuneStart";
    public static KEEP_MENU_OPEN_ON_TUNE                         = "KeepMenuOpenOnTune";
    public static AUDIO_QUALITY                                  = "AudioQuality";
    public static MINI_PLAY                                      = "MiniPlay";
    public static SHOW_MAX_DOWNLOAD_AUDIO_QUALITY_MESSAGE        = "ShowMaxDownloadAudioQualityMessage";
    public static NOTIFICATION_SUBSCRIPTION_SUGGESTED_SHOW       = "NotificationSubscriptionSuggestedShow";
    public static NOTIFICATION_SUBSCRIPTION_SHOW_REMINDERS       = "NotificationSubscriptionShowReminders";
    public static NOTIFICATION_SUBSCRIPTION_SUGGESTED_LIVE_VIDEO = "NotificationSubscriptionSuggestedLiveVideo";
    public static NOTIFICATION_SUBSCRIPTION_CONTENT              = "NotificationSubscriptionContent";
    public static SPORTS_REMINDER                                = "SportsReminders";
    public static NOTIFICATION_SUBSCRIPTION_UPDATES              = "NotificationSubscriptionUpdates";
    public static NOTIFICATION_SUBSCRIPTION_OFFERS               = "NotificationSubscriptionOffers";
    public static DISPLAY_CHROMECAST_OVERLAY                     = "DisplayChromecastOverlay";
    public static DISPLAY_AIC_OVERLAY                            = "DisplayAICOverlay";
    public static SHOW_FOOTER                                    = false;
    public static DISPLAY_DISLIKE_COACHMARKS: string             = "DisplayDislikeCoachmarks";
    public static DISPLAY_LIKE_COACHMARKS: string                = "DisplayLikeCoachmarks";
    public static DISPLAY_LIKE_INSTRUCTIONS: string              = "DisplayLikeInstructions";
    public static DISPLAY_PANDORAX_FEATURE_MODAL: string         = "DisplayPandoraXFeatureModal";
    public static SKIP_DMCA_DICTIONARY: string                   = "SkipDmcaDictionary";
    public static DISPLAY_COLD_START_OVERLAY: string             = "DisplayColdStartOverlay";
    public static USER_DAYS_LISTENED: string                     = "UserDaysListened";
}
