import { BehaviorSubject, Observable } from "rxjs";
import { share } from 'rxjs/operators';
import { addProvider, AppErrorCodes, EError, IProviderDescriptor, Logger } from "../index";
import { VideoPlayerEventTypes } from "../mediaplayer/videoplayer/video-player.event-types";

import { IErrorInformation } from "./error.interface";
import { AudioPlayerEventTypes } from "../mediaplayer/audioplayer/audio-player.event-types";
import { AppMonitorService } from "../app-monitor";

export class ErrorService
{

    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("ErrorService");

    /**
     * Subject to store the data of the error
     * @type {IErrorInformation}
     */
    public errorInformationSubject: BehaviorSubject<IErrorInformation>;
    public errorInformation: Observable<IErrorInformation> = null;

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(ErrorService, ErrorService, [
            AppMonitorService
        ]);
    }();

    /**
     * Constructor.
     */
    constructor(public appMonitorService: AppMonitorService)
    {
        this.errorInformationSubject = new BehaviorSubject({
            isInErrorState: false,
            errorType: null
        });

        this.errorInformation = this.errorInformationSubject.pipe(share());
    }

    /**
     * Handle errors by updating the errorInformationSubject
     * being observed by the client.
     */
    public handleError(_event: any): void
    {
        ErrorService.logger.debug(`handleError( _event.type = ${_event.type} )`);

        let errorType: EError;
        let isInErrorState: boolean;

        switch (_event.type)
        {
            case AudioPlayerEventTypes.RESTART:
            case AudioPlayerEventTypes.BUFFER_EMPTY:
                errorType = EError.RESTART;
                isInErrorState = true;
                break;
            case EError.PLAYBACK_DENIED:
                errorType = EError.PLAYBACK_DENIED;
                isInErrorState = false;
                break;
            case EError.SAFARI_AUTO_PLAY_PAUSE:
                errorType = EError.SAFARI_AUTO_PLAY_PAUSE;
                isInErrorState = false;
                break;
            // Autoplay denied for browsers other than Safari
            case EError.AUTOPLAY_DENIED:
                errorType = EError.AUTOPLAY_DENIED;
                isInErrorState = false;
                break;
            case VideoPlayerEventTypes.BUFFER_EMPTY:
                errorType = EError.BUFFER_EMPTY;
                isInErrorState = true;
                break;
            case VideoPlayerEventTypes.BUFFER_EMPTY_NO_RECOVERY:
                errorType = EError.BUFFER_EMPTY_NO_RECOVERY;
                isInErrorState = true;
                break;
            case AppErrorCodes.FLTT_NO_IP_CONNECTION:
                errorType = EError.NO_IP_CONNECTION;
                isInErrorState = true;
                break;
            case AppErrorCodes.FLTT_DECODER_FAILURE:
                errorType = EError.DECODER_FAILURE;
                isInErrorState = false;
                this.appMonitorService.triggerFaultError({ faultCode: AppErrorCodes.FLTT_DECODER_FAILURE });
                break;
            case AppErrorCodes.FLTT_CONTENT_ALERT:
                errorType = EError.FLTT_CONTENT_ALERT;
                isInErrorState = true;
                break;
            case EError.LIVE_VIDEO_REMINDER:
                errorType = EError.LIVE_VIDEO_REMINDER;
                isInErrorState = true;
                break;
            case AppErrorCodes.FLTT_NO_TRACKS_TO_PLAY:
                errorType = EError.FLTT_NO_TRACKS_TO_PLAY;
                isInErrorState = true;
                break;
            case AppErrorCodes.FLTT_AIC_BYPASS:
                errorType = EError.FLTT_AIC_BYPASS;
                isInErrorState = true;
                break;
            case AppErrorCodes.FLTT_ARTIST_RADIO_BYPASS:
                errorType = EError.FLTT_ARTIST_RADIO_BYPASS;
                isInErrorState = true;
                break;
            case EError.INVALID_TOKEN:
                errorType = EError.INVALID_TOKEN;
                isInErrorState = true;
                break;
            default:
                isInErrorState = true;
                errorType = EError.UNKNOWN;
        }

        this.errorInformationSubject.next({ isInErrorState: isInErrorState, errorType });
    }

    /**
     * Clears the error.
     * @param event
     */
    public clearError(event: any): void
    {
        ErrorService.logger.debug(`clearError( _event.type = ${event.type} )`);

        let errorType: EError;

        switch (event.type)
        {
            case AudioPlayerEventTypes.PLAYBACK_READY:
            case AudioPlayerEventTypes.PLAYHEAD_START:
            case VideoPlayerEventTypes.PLAYBACK_READY:
                errorType = EError.RESTART;
                break;

            case VideoPlayerEventTypes.BUFFER_EMPTY:
                errorType = EError.BUFFER_EMPTY;
                break;

            case AppErrorCodes.FLTT_NO_IP_CONNECTION:
                errorType = EError.NO_IP_CONNECTION;
                break;

            case AppErrorCodes.FLTT_CONTENT_ALERT:
                errorType = EError.FLTT_CONTENT_ALERT;
                break;

            case EError.LIVE_VIDEO_REMINDER:
                errorType = EError.LIVE_VIDEO_REMINDER;
                break;

            // add other error types here
            default:
                errorType = EError.UNKNOWN;
        }

        this.errorInformationSubject.next({ isInErrorState: false, errorType });
    }
}
