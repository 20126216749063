import {
  combineLatest as observableCombineLatest,
  Observable,
  EMPTY,
  of,
} from 'rxjs';
import {
  mergeMap,
  first,
  filter,
  switchMap,
  map,
  distinctUntilChanged,
  take,
} from 'rxjs/operators';

import { IVideoPlayerData } from '../../types/video-player-data.interface';
import {
  CarouselPageParameter,
  ChannelLineupService,
  ContentTypes,
  DmcaService,
  IChannel,
  ICurrentlyPlayingMedia,
  IMediaPlayer,
  IMediaShow,
  IPlayhead,
  Logger,
  MediaPlayerFactory,
  NAME_BACKGROUND,
  TILE_CHANNEL_THUMB_BACKGROUND,
  NAME_COLOR_CHANNEL_LOGO,
  NAME_COLOR_CHANNEL_LOGO_HEIGHT,
  NAME_COLOR_CHANNEL_LOGO_WIDTH,
  NAME_LIST_VIEW_SHOW_LOGO,
  NAME_LIST_VIEW_SHOW_LOGO_HEIGHT,
  NAME_LIST_VIEW_SHOW_LOGO_WIDTH,
  PLATFORM_ANY,
  PLATFORM_WEBEVEREST,
  CurrentlyPlayingService,
  getAlbumNameForCut,
  getAlbumArtForCut,
  getArtistNameForCut,
  IMediaVideo,
  secondsToMs,
  MediaUtil,
  getPosterImageForEpisode,
  MediaPlayerConstants,
  IProviderDescriptor,
  addProvider,
  POSTER_TILE_IMAGE_WIDTH,
  POSTER_TILE_IMAGE_HEIGHT,
} from '../../servicelib';
import {
  ChangeContentType,
  ChangeContentTypePayload,
  //EnterNowPlaying,
  enterNowPlaying,
  EnterNowPlayingPayload,
  INowPlayingActionPayload,
  ISelectVideoPlayerDataActionPayload,
  LeaveNowPlaying,
  LeaveNowPlayingPayload,
  ResetNowPlaying,
  SelectBackgroundColor,
  SelectChannelLogoAltText,
  //SelectChannelLogoUrl,
  selectChannelLogoUrl as selectChannelLogoUrlAction,
  //SelectNowPlaying,
  selectNowPlaying,
  //SelectPlayingChannel,
  selectPlayingChannel,
  SelectVideoPlayerData,
  //UpdatePlayhead,
  updatePlayhead as updatePlayheadAction,
  UpdateVideoMarker,
  //UpdateMediaPlaying,
  updateMediaPlaying as updateMediaPlayingAction,
} from '../../redux/action/now-playing.action';
import { IAppStore, IChannelListStore } from '../../redux/selector';
import {
  getChannel,
  getContentType,
  getIsAod,
  getIsVod,
  getShow,
  getVideoPlayerData,
  INowPlayingStore,
} from '../../redux/selector/now-playing.store';
import { CarouselStoreService } from '../carousel-store/carousel.store.service';
import * as _ from 'lodash';
import store from '../../redux/store';

export class NowPlayingStoreService {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('NowPlayingStoreService');

  /**
   * Now playing data wrapped in observables from the NGRX now playing store. The Observable
   * wrapper will need to be stripped for use in the UI via the async pipe; e.g., "channels | async".
   */
  public nowPlayingStore: Observable<INowPlayingStore>;

  /**
   * List of channels wrapped in observables from the NGRX channels store. The Observable list
   * wrapper will need to be stripped for use in the UI via the async pipe; e.g., "channels | async".
   */
  public channelStore: Observable<IChannelListStore>;

  /**
   * Reference to the guids of {channel, cut, show, episode} of what is currently playing
   */
  private playingGuids: any = {
    channelGuid: '',
    showGuid: '',
    cutGuid: '',
    episodeGuid: '',
    stationFactory: '',
  };

  /**
   * Reference to the current video player data
   */
  public videoPlayerDataSlice: Observable<
    IVideoPlayerData
  >; /*= this.store.select(
    getVideoPlayerData,
  );*/

  /**
   * The currently playing channel.
   */
  public channel$: Observable<IChannel>; //= this.store.select(getChannel);

  /**
   * The currently playing VOD episode.
   */
  public show$: Observable<IMediaShow>; //= this.store.select(getShow);

  /**
   * Indicates if AOD is the current content type.
   */
  public isAod$: Observable<boolean>; //= this.store.select(getIsAod);

  /**
   * Indicates if AOD is the current content type.
   */
  public isVod$: Observable<boolean>; //= this.store.select(getIsVod);

  /**
   * Indicates the current content type.
   */
  public contentType$: Observable<string>; //= this.store.select(getContentType);

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(NowPlayingStoreService, NowPlayingStoreService, [
      //private store: Store<IAppStore>,
      CarouselStoreService,
      CurrentlyPlayingService,
      ChannelLineupService,
      MediaPlayerFactory,
      DmcaService,
    ]);
  })();

  /**
   * Injects AJS controlled services and sets up the NGRX store.
   * @param {Store<IAppStore>} store
   * @param {CarouselStoreService} carouselStoreService
   * @param {CurrentlyPlayingService} currentlyPlayingService
   * @param {ChannelLineupService} channelLineupService
   * @param {MediaPlayerFactory} mediaPlayerFactory
   * @param {DmcaService} dmcaService
   */
  constructor(
    //private store: Store<IAppStore>,
    private carouselStoreService: CarouselStoreService,
    private currentlyPlayingService: CurrentlyPlayingService,
    private channelLineupService: ChannelLineupService,
    private mediaPlayerFactory: MediaPlayerFactory,
    private dmcaService: DmcaService,
  ) {
    // Grab a slice of the store so views can subscribe to the data and populate our UI.
    // this.nowPlayingStore = this.store.select("nowPlayingStore");
    /*
    this.nowPlayingStore = this.store.select(
      (state: IAppStore) => state.nowPlayingStore,
    );
    this.channelStore = this.store.select(
      (state: IAppStore) => state.channelStore,
    );
    */
    // Kickoff the subscription for the now playing data.
    this.subscribeToNowPlayingData();
    this.updatePlayhead();
    this.updateMediaPlayingStatus();

    /*
    this.subscribeToNowPlayingCarouselData();
    this.updateChannelLogo();
    
    
    */
  }

  /**
   *  Update Media Paying Status
   *  If Media is playing the status is true and other status like pause, completed set as false.
   */
  private updateMediaPlayingStatus() {
    this.mediaPlayerFactory.currentMediaPlayer
      .pipe(
        filter(value => !!value),
        switchMap(mediaPlayerService => {
          return mediaPlayerService.playbackState.pipe(
            map(state => {
              return state === MediaPlayerConstants.PLAYING;
            }),
            distinctUntilChanged(),
          );
        }),
      )
      .subscribe((isPlaying: boolean) => {
        //this.store.dispatch(new UpdateMediaPlaying(isPlaying));
        store.dispatch(updateMediaPlayingAction(isPlaying));
      });
  }

  /**
   * Observes the current media player and its playhead observable and attempts to update the playhead value
   * in the store.
   *
   * It's possible that either the media player or playhead is null at both player instantiation and player
   * type switching so we ensure there's some observable stream by using `empty()` for those instances.
   */
  private updatePlayhead(): void {
    this.mediaPlayerFactory.currentMediaPlayer
      .pipe(
        mergeMap((mediaPlayer: IMediaPlayer) =>
          mediaPlayer ? mediaPlayer.playhead$ || EMPTY : EMPTY,
        ),
      )
      .subscribe((playhead: IPlayhead) =>
        //playhead ? this.store.dispatch(new UpdatePlayhead(playhead)) : null,
        playhead ? store.dispatch(updatePlayheadAction(playhead)) : null,
      );
  }

  /**
   * Called when entering the now playing component as it needs the content type and start time for the media player.
   * @param {number} startTime
   * @param {string} mediaType
   */
  public enterNowPlaying(startTime: number, mediaType: string): void {
    startTime = startTime || 0;
    const payload: EnterNowPlayingPayload = {
      mediaType: mediaType,
      startTime: startTime,
    };
    //this.store.dispatch(new EnterNowPlaying(payload));
    store.dispatch(enterNowPlaying(payload));
  }

  /**
   * Called when tune changes.
   * @param {IChannel} channel
   */
  public selectNowPlayingChannel(channel: IChannel): void {
    //this.store.dispatch(new SelectPlayingChannel(channel));
    store.dispatch(selectPlayingChannel(channel));
  }

  /**
   * Called when leaving the now playing component as other components need the content type and start time for the media player.
   * @param {number} startTime
   */
  public leaveNowPlaying(startTime: number): void {
    const payload: LeaveNowPlayingPayload = {
      startTime: startTime,
    };
    //this.store.dispatch(new LeaveNowPlaying(payload));
  }

  /**
   * Changes the contentType
   * @param {string} mediaType
   */
  public changeContentType(mediaType: string): void {
    const payload: ChangeContentTypePayload = {
      mediaType: mediaType,
    };
    //this.store.dispatch(new ChangeContentType(payload));
  }

  /**
   * Called when tune changes.
   */
  public resetNowPlaying(): void {
    //this.store.dispatch(new ResetNowPlaying());
  }

  /**
   * Selects the currently playing video data.
   * @param {ISelectVideoPlayerDataActionPayload} videoPlayerData
   */
  public selectVideoPlayerData(
    videoPlayerData: ISelectVideoPlayerDataActionPayload,
  ): void {
    NowPlayingStoreService.logger.debug(`selectVideoPlayerData()`);
    //this.store.dispatch(new SelectVideoPlayerData(videoPlayerData));
  }

  /**
   * Dispatches the action to set the channel logo URL for the now playing data.
   * @param {string} url
   */
  public selectChannelLogoUrl(url: string): string {
    //this.store.dispatch(new SelectChannelLogoUrl(url));
    //store.dispatch(selectChannelLogoUrlAction(url));
    return url;
  }

  /**
   * Dispatches the action to set the video marker when user moves the  scrub-ball
   * @param {number} timestamp-zeroTimestamp
   */
  public updateVideoMarker(timestamp: number): void {
    this.nowPlayingStore
      .pipe(
        filter(nowPlayingData =>
          MediaUtil.isLiveMediaType(nowPlayingData.mediaType),
        ),
        first(),
      )
      .subscribe((nowPlayingData: INowPlayingStore) => {
        const zuluTimestamp =
          _.get(nowPlayingData, 'episode.times.zuluStartTime', 0) +
          secondsToMs(timestamp);
        const videoMarker: IMediaVideo =
          this.currentlyPlayingService.findLiveVideoForZuluTimestamp(
            zuluTimestamp,
          ) || null;
        //this.store.dispatch(new UpdateVideoMarker(videoMarker));
      });
  }

  /**
   * Dispatches the action to set the channel logo alt text for the now playing data.
   * @param {string} altText
   */
  public selectChannelLogoAltText(altText: string): void {
    //this.store.dispatch(new SelectChannelLogoAltText(altText));
  }

  private updateChannelLogo(): void {
    observableCombineLatest(
      this.channel$,
      this.show$,
      this.isAod$,
      this.isVod$,
      (channel, show, isAod, isVod) => {
        this.setChannelLogoImageSrc(channel, show, isAod, isVod);
        this.setChannelLogoImageAltText(channel, show, isAod, isVod);
      },
    ).subscribe();
  }

  /**
   * Get the channel image from the channel data. Search for a specific size and return it.
   */
  public setChannelLogoImageSrc(
    channel: IChannel,
    show: IMediaShow,
    isAod: boolean,
    isVod: boolean,
  ): string {
    NowPlayingStoreService.logger.debug(`setChannelLogoImageSrc()`);
    const tryShowLogoFirst: boolean = isAod || isVod;
    let odLogosObj: IMediaShow = show;
    let logoImageSrc: string = '';

    const shouldWarn = (logoImage: string) => {
      return !logoImage || logoImage.length === 0;
    };

    if (tryShowLogoFirst && odLogosObj) {
      logoImageSrc = ChannelLineupService.getShowOrEpisodeImage(
        odLogosObj,
        NAME_LIST_VIEW_SHOW_LOGO,
        NAME_LIST_VIEW_SHOW_LOGO_WIDTH,
        NAME_LIST_VIEW_SHOW_LOGO_HEIGHT,
      );

      if (shouldWarn(logoImageSrc) && channel) {
        NowPlayingStoreService.logger.warn(
          `Something went wrong getting show logo`,
        );
        logoImageSrc = ChannelLineupService.getChannelImage(
          channel,
          NAME_COLOR_CHANNEL_LOGO,
          PLATFORM_ANY,
          NAME_COLOR_CHANNEL_LOGO_WIDTH,
          NAME_COLOR_CHANNEL_LOGO_HEIGHT,
        );
      }
    } else if (channel) {
      logoImageSrc = ChannelLineupService.getChannelImage(
        channel,
        NAME_COLOR_CHANNEL_LOGO,
        PLATFORM_ANY,
        //NAME_COLOR_CHANNEL_LOGO_WIDTH,
        //NAME_COLOR_CHANNEL_LOGO_HEIGHT,
      );
    }

    if (shouldWarn(logoImageSrc)) {
      NowPlayingStoreService.logger.warn(
        `Something went wrong getting channel logo`,
      );
      logoImageSrc = logoImageSrc || '';
    }

    if (logoImageSrc) {
      return this.selectChannelLogoUrl(logoImageSrc);
    } else {
      return;
    }
  }

  /**
   * Set the alt text for logo image based on type
   * @returns {string}
   */
  private setChannelLogoImageAltText(
    channel: IChannel,
    show: IMediaShow,
    isAod: boolean,
    isVod: boolean,
  ): void {
    NowPlayingStoreService.logger.debug(`setChannelLogoImageAltText()`);
    let logoImageAltText: string = '';

    if (isAod || isVod) {
      logoImageAltText = show.mediumTitle;
    } else if (channel) {
      logoImageAltText = channel.name;
    }

    logoImageAltText = logoImageAltText || '';

    if (logoImageAltText) {
      this.selectChannelLogoAltText(logoImageAltText);
    }
  }

  /**
   * Dispatches the selected now playing data to the Redux store where there's data available and/or changes.
   */
  private subscribeToNowPlayingData(): void {
    const onNowPlayingDataUpdate = (data: ICurrentlyPlayingMedia) => {
      if (data) {
        const channel =
          this.channelLineupService.findChannelByIdOrGuid(data.channelId) ||
          data.channel ||
          ({} as IChannel);

        const artistName: string = data.cut
          ? getArtistNameForCut(data.cut)
          : '';
        const trackName: string = data.cut ? data.cut.title : '';
        const posterImage: string = data.episode
          ? getPosterImageForEpisode(data.episode)
          : '';
        const nowPlayingData: INowPlayingActionPayload = {
          artistName: artistName,
          channelName: channel.name || '',
          channelNumber: String(channel.channelNumber) || '',
          cut: data.cut,
          trackName: trackName,
          albumName: data.cut ? getAlbumNameForCut(data.cut) : '',
          albumImage: data.cut ? getAlbumArtForCut(data.cut) : '',
          artistInfo: '',
          dmcaInfo: data.dmcaInfo,
          episode: data.episode,
          backgroundImage: ChannelLineupService.getChannelImage(
            channel,
            TILE_CHANNEL_THUMB_BACKGROUND,
            PLATFORM_ANY,
            POSTER_TILE_IMAGE_WIDTH,
            POSTER_TILE_IMAGE_HEIGHT,
          ),
          mediaId: data.mediaId,
          channel: channel,
          mediaType: data.mediaType,
          playhead: data.playhead,
          show: data.show,
          type: data.mediaType,
          youJustHeard: data.youJustHeard,
          video: data.video || null,
          posterImageUrl: posterImage,
          stationId: data.stationId,
          hideFromChannelList:
            data.episode && data.episode.show
              ? data.episode.show.hideFromChannelList
              : false,
          isPodcast:
            data.episode && data.episode.show
              ? data.episode.show.isPodcast
              : false,
        };

        // Update now playing carousel once channel list is ready
        this.channelLineupService.channelLineup.channels
          .pipe(
            filter(channel => channel && channel.length > 0),
            take(1),
          )
          .subscribe(() => {
            this.updateNowPlayingCarousels(data);
          });

        //this.store.dispatch(new SelectNowPlaying(nowPlayingData));
        store.dispatch(selectNowPlaying(nowPlayingData));
        NowPlayingStoreService.logger.debug(
          `onNowPlayingDataUpdate( Artist: ${artistName}, track: ${trackName} )`,
        );
      }
    };

    this.currentlyPlayingService.currentlyPlayingData.subscribe(
      onNowPlayingDataUpdate,
    );
  }

  private subscribeToNowPlayingCarouselData(): void {
    this.carouselStoreService.nowPlayingCarousels
      .pipe(filter(response => !!response))
      .subscribe(nowPlayingCarousel => {
        /*this.store.dispatch(
          new SelectBackgroundColor(nowPlayingCarousel.pageBackgroundColor),
        );*/
      });
  }

  /**
   * @param {ICurrentlyPlayingMedia} data
   * Refresh the Related-carousels when User tunes to
   * 1. Channel, 2.On Demand episode 3. Restricted- eachTime song changes
   */
  private updateNowPlayingCarousels(data: ICurrentlyPlayingMedia) {
    const channel =
      this.channelLineupService.findChannelByIdOrGuid(data.channelId) ||
      data.channel ||
      ({} as IChannel);

    const isNewChannel =
      data.mediaType === ContentTypes.LIVE_AUDIO &&
      this.playingGuids.channelGuid !== channel.channelGuid;
    const isNewAICChannel =
      data.mediaType === ContentTypes.ADDITIONAL_CHANNELS &&
      this.playingGuids.channelGuid !== channel.channelGuid;
    const isNewSeededChannel =
      data.mediaType === ContentTypes.SEEDED_RADIO &&
      this.playingGuids.stationFactory !== channel.stationFactory;
    const isNewEpisode =
      data.episode && this.playingGuids.episodeGuid !== data.episode.assetGUID;

    const assetGuid: string = data && data.cut ? data.cut.assetGUID : '';
    const isNewCut = this.playingGuids.cutGuid !== assetGuid;

    // For DMCA-Restricted channels or AOD - when Songs changes we need to update the content
    const isNewSong =
      this.dmcaService.isRestricted(data) &&
      isNewCut &&
      !isNewEpisode &&
      data.cut &&
      (MediaUtil.isTalkCutContentType(data.cut) ||
        MediaUtil.isSongCutContentType(data.cut));

    if (
      data.cut &&
      (isNewChannel ||
        isNewEpisode ||
        isNewSong ||
        isNewAICChannel ||
        isNewSeededChannel)
    ) {
      this.playingGuids.channelGuid = channel ? channel.channelGuid : '';
      this.playingGuids.showGuid = data.show ? data.show.assetGUID : '';
      this.playingGuids.cutGuid = data.cut ? data.cut.assetGUID : '';
      this.playingGuids.episodeGuid = data.episode
        ? data.episode.assetGUID
        : '';
      this.playingGuids.stationFactory = channel ? channel.stationFactory : '';

      const params: Array<CarouselPageParameter> = [];

      params.push({
        paramName: 'channelGuid',
        paramValue: this.playingGuids.channelGuid,
      });
      params.push({
        paramName: 'showGuid',
        paramValue: this.playingGuids.showGuid,
      });
      params.push({
        paramName: 'cutGuid',
        paramValue: this.playingGuids.cutGuid,
      });
      params.push({
        paramName: 'episodeGuid',
        paramValue: this.playingGuids.episodeGuid,
      });
      params.push({
        paramName: 'cutArtistName',
        paramValue: _.get(data, 'cut.artists[0].name', ''),
      });
      params.push({
        paramName: 'contextGuid',
        paramValue:
          data.mediaType === ContentTypes.SEEDED_RADIO
            ? _.get(data, 'cut.stationFactory', '')
            : _.get(data, 'cut.galaxyAssetId', ''),
      });

      this.carouselStoreService.selectNowPlaying(data, params);
    }
    //Cleanup the upNext carousel in case the now playing media changes.
    if (isNewEpisode) {
      this.carouselStoreService.cleanNowPlayingUpNext();
    }
  }
}
