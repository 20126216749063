import { Subject ,  BehaviorSubject }          from "rxjs";
import {
    IAppByPassState
}                           from "./app-monitor.interface";
import {
    IProviderDescriptor,
    addProvider
}                           from "../service";
import { Logger }           from "../logger";
import {
    bypassApiCodes,
    ApiCode
}                           from "../service/consts";
import { BypassErrorState } from "./bypassErrorState";
import { ApiDelegate }      from "../http/api.delegate";

export class BypassMonitorService
{

    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("BypassMonitorService");

    /**
     * The current byPassState data.
     */
    public appByPassStateData: BypassErrorState = new BypassErrorState();

    public bypassErrorState: BehaviorSubject<IAppByPassState>
        = new BehaviorSubject<IAppByPassState>(this.appByPassStateData.getBypassErrorState());
    public bypassErrors: Subject<ApiCode> = new Subject<ApiCode>();


    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(BypassMonitorService, BypassMonitorService, [
            ApiDelegate
        ]);
    }();

    /**
     * Constructor
     */
    constructor(private apiDelegate : ApiDelegate)
    {
        this.bypassApiCodeHandler();
    }

    /**
     * handle api Codes for any API response irrespective of tune/auth/noop..
     */
    private bypassApiCodeHandler(): void
    {
        bypassApiCodes.forEach((code) =>
        {
            const triggeringCode = code;
            this.apiDelegate.addApiCodeHandler(code,  (codes, messages): void =>
            {
                messages.filter( ({code}) => triggeringCode === code).forEach( ({message, code}) =>
                {
                    this.handleByPassState(code);
                });
            });
        });
    }

    /**
     * handle ByPass state for the below API codes
     * @param apiCode :ApiCode
     */
    handleByPassState(apiCode)
    {
        // Only trigger bypass state if we were not already in bypass state
        if (this.appByPassStateData.checkAndUpdateBypass(apiCode))
        {
            this.bypassErrorState.next(this.appByPassStateData.getBypassErrorState());
            this.bypassErrors.next(apiCode);
        }
    }

    /**
     * handle Noop Message and update the Bypass state
     * @param apiCode :apiCode
     */
    handleNoopMessage(apiCodes : Array<number>)
    {
        const changedBypassStates = this.appByPassStateData.updateBypassFromNoop(
                apiCodes.filter(code => BypassErrorState.isBypassCode(code)));

        changedBypassStates.forEach(changed =>
        {
            if(changed.state)
            {
                this.bypassErrorState.next(this.appByPassStateData.getBypassErrorState());
                this.bypassErrors.next(changed.code);
            }
            else
            {
                this.bypassErrorState.next(this.appByPassStateData.getBypassErrorState());
            }
        });
    }
}
