import { Logger } from '../index';
import { StorageService } from '../storage/storage.service';
import { MediaPlayerFactory } from './media-player.factory';
import { MediaPlayerConstants } from './media-player.consts';
import { MediaUtil } from './media.util';
import { IMediaPlayer } from './media-player.interface';
import { StorageKeyConstant } from '../storage/storage.constants';
import { IProviderDescriptor } from '../service/provider.descriptor.interface';
import { addProvider } from '../index';
import { BehaviorSubject, isObservable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { ChromecastModel } from '../chromecast/chromecast.model';

/**
 * @MODULE:     service-lib
 * @CREATED:    11/15/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * VolumeService for get and set volume
 */
export class VolumeService {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('VolumeService');

  /**
   * Reference to the media player -- can be either audio or video as long as it fulfills this interface.
   */
  public mediaPlayer: IMediaPlayer;

  public volumeChanged$: BehaviorSubject<any> = new BehaviorSubject(null);

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(VolumeService, VolumeService, [
      StorageService,
      MediaPlayerFactory,
      ChromecastModel,
    ]);
  })();

  /**
   * Constructor
   * @param {StorageService} storageService - Used to get / set values to storage
   * @param {MediaPlayerFactory} mediaPlayerFactory - Used to get currently playing media
   */
  constructor(
    private storageService: StorageService,
    private mediaPlayerFactory: MediaPlayerFactory,
    private chromecastModel: ChromecastModel,
  ) {
    this.mediaPlayerFactory.currentMediaPlayer.subscribe(
      (mediaPlayer: IMediaPlayer) => {
        if (mediaPlayer) {
          this.mediaPlayer = mediaPlayer;

          this.initializeVolume(this.mediaPlayer);
        } else {
          VolumeService.logger.warn(
            'Null sent instead of IMediaPlayer in subscribe call',
          );
        }
      },
    );

    this.chromecastModel.volumeChangedFromRemotePlayer$
      .pipe(filter((data: any) => !!data))
      .subscribe(data => {
        this.volumeChanged$.next(data);
      });
  }

  /**
   * Initializes a volume
   * @param {any} aVolumeSetable - a duck type that implements setVolume()
   * This method can initialize a volume on any object that implements setVolume().
   */
  public initializeVolume(aVolumeSetable: any) {
    if (!aVolumeSetable) return;
    if (this.isMuted()) {
      const result = aVolumeSetable.setVolume(0);
      if (isObservable(result)) {
        result.pipe(take(1)).subscribe();
      }
    } else {
      const volume = this.pullNonZeroVolumeFromStorage();
      const result = aVolumeSetable.setVolume(volume);
      if (isObservable(result)) {
        result.pipe(take(1)).subscribe();
      }
    }
  }

  /**
   * Mutes the player. Sets appropriate state.
   * and sets volume to zero on the player.
   */
  public mute(notifyMediaPlayer: boolean): void {
    this.storageService.setItem(StorageKeyConstant.IS_MUTED, 'true');
    if (this.mediaPlayer && notifyMediaPlayer) {
      this.mediaPlayer.mute();
    }
  }

  /**
   * Checks whether or not it is in muted state.
   */
  public isMuted(): boolean {
    const isMuted = this.storageService.getParsedItem(
      StorageKeyConstant.IS_MUTED,
    );
    return isMuted === true;
  }

  /**
   * Pushes a volume to local storage
   * @param {number} volume - volume to be put in local storage.
   * Is never 0 because mute is a different case and you want a positive
   * volume to which to unmute.
   */
  public pushVolumeToStorage(volume: number): void {
    if (
      !MediaUtil.isVolumeValid(volume, MediaPlayerConstants.MAX_VOLUME_VALUE)
    ) {
      volume = MediaPlayerConstants.MID_VOLUME_VALUE;
    }
    if (volume === 0) {
      volume = 1;
    }
    this.storageService.setItem(StorageKeyConstant.IS_MUTED, 'false');
    this.storageService.setItem(StorageKeyConstant.VOLUME, volume);
  }

  /**
   * Pulls a volume from local storage. Always is non-zero.
   * Zero means its muted and you have no value to unmute TO.
   */
  public pullNonZeroVolumeFromStorage(): number {
    let volume = parseInt(
      this.storageService.getItem(StorageKeyConstant.VOLUME),
    );

    if (
      !MediaUtil.isVolumeValid(volume, MediaPlayerConstants.MAX_VOLUME_VALUE)
    ) {
      volume = MediaPlayerConstants.MID_VOLUME_VALUE;
      this.pushVolumeToStorage(volume);
    }
    return volume;
  }

  /**
   * Sets the local volume properties
   * @param {number} volume - volume to be updated
   * @param {boolean} persist - flag indicating whether to put this adjustment
   *                            in local storage.
   */
  public adjustVolume(
    volume: number,
    persist: boolean,
    notifyMediaPlayer: boolean,
  ) {
    if (!this.mediaPlayer) return;
    if (volume === 0) return this.mute(notifyMediaPlayer);
    if (persist) this.pushVolumeToStorage(volume);
    if (notifyMediaPlayer) {
      const result = this.mediaPlayer.setVolume(volume);
      if (isObservable(result)) {
        (result as any).pipe(take(1)).subscribe();
      }
    }
  }

  /**
   * Sets the volume image src based on volume range.
   */
  public getVolumeImageSrc(volume: number, isVideoFullScreen: boolean): string {
    if (volume === 0) {
      // muted
      return !isVideoFullScreen
        ? '../../assets/images/volume/volume-0-blue'
        : '../../assets/images/volume/volume-0-white';
    } else if (volume >= 80) {
      // high volume
      return !isVideoFullScreen
        ? '../../assets/images/volume/volume-5-blue'
        : '../../assets/images/volume/volume-5-white';
    } else if (volume >= 60) {
      // medium-high volume
      return !isVideoFullScreen
        ? '../../assets/images/volume/volume-4-blue'
        : '../../assets/images/volume/volume-4-white';
    } else if (volume >= 40) {
      // medium volume
      return !isVideoFullScreen
        ? '../../assets/images/volume/volume-3-blue'
        : '../../assets/images/volume/volume-3-white';
    } else if (volume >= 20) {
      // medium-low volume
      return !isVideoFullScreen
        ? '../../assets/images/volume/volume-2-blue'
        : '../../assets/images/volume/volume-2-white';
    } else {
      // low volume
      return !isVideoFullScreen
        ? '../../assets/images/volume/volume-1-blue'
        : '../../assets/images/volume/volume-1-white';
    }
  }
}
