import { useRef } from 'react';

function useLatest<Data>(value: Data) {
  const ref = useRef(value);

  ref.current = value;

  return ref;
}

export default useLatest;
