import { Observable } from 'rxjs';
import {
  IProviderDescriptor,
  addProvider,
  ApiLayerTypes,
  IPausePoint,
  ContentTypes,
  IDeepLinkData,
  ServiceEndpointConstants,
  PlayerTypes,
} from '../service';
import { DeepLinkTypes } from '../contexual';
import { IHttpRequestConfig, HttpProvider, ModuleRequest } from '../http';
import { IAppConfig } from '../config';
import { ChromecastModel } from '../chromecast/chromecast.model';

/**
 * @MODULE:     service-lib
 * @CREATED:    07/19/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * Resume Service is used to (1) (re)validate the user's session and (2) find out what the user was last listening to
 * or viewing and go back to that content
 */

export class ResumeDelegate {
  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(ResumeDelegate, ResumeDelegate, [
      HttpProvider,
      'IAppConfig',
      ChromecastModel,
    ]);
  })();

  constructor(
    private http: HttpProvider,
    private SERVICE_CONFIG: IAppConfig,
    private chromecastModel: ChromecastModel,
  ) {}

  public resume(
    pausePointDetails?: IPausePoint,
    openAccess?: boolean,
    deepLink?: IDeepLinkData,
    tuneNeeded: boolean = true,
  ): Observable<any> {
    const config: IHttpRequestConfig = { params: {} };

    if (
      this.chromecastModel.playerType === PlayerTypes.LOCAL &&
      this.SERVICE_CONFIG.adsWizzSupported
    ) {
      config.params.adsEligible = true;
    }

    if (!this.SERVICE_CONFIG.isFreeTierEnable) {
      config.params[ApiLayerTypes.OPEN_ACCESS_TRIAL] =
        openAccess === true ? true : false;
    }
    const pausePointValid = this.verifyPausePointDetails(
      pausePointDetails,
      deepLink,
    );

    if (tuneNeeded === true || deepLink || pausePointValid) {
      if (deepLink) {
        config.params[ApiLayerTypes.DEEP_LINK_ID] = deepLink.id;
        config.params[ApiLayerTypes.CONTENT_TYPE] = this.getDeepLinkType(
          deepLink.type,
        );
      }

      if (pausePointValid) {
        config.params[ApiLayerTypes.CHANNEL_ID] = pausePointDetails.channelId;
        config.params[ApiLayerTypes.CONTENT_TYPE] =
          pausePointDetails.contentType === ContentTypes.ADDITIONAL_CHANNELS
            ? ContentTypes.AIC
            : pausePointDetails.contentType;
        config.params[ApiLayerTypes.TIMESTAMP] = pausePointDetails.timestamp;

        if (
          pausePointDetails.contentType == ContentTypes.AOD ||
          pausePointDetails.contentType == ContentTypes.VOD ||
          pausePointDetails.contentType == ContentTypes.PODCAST
        ) {
          config.params[ApiLayerTypes.CONDITIONAL_ACCESS_ID] =
            pausePointDetails.caId;
        }
      }
    } else {
      config.params[ApiLayerTypes.CONTENT_TYPE] = ApiLayerTypes.NO_CONTENT;
    }

    const request = new ModuleRequest();

    return this.http.postModuleRequest(
      ServiceEndpointConstants.endpoints.RESUME.V2_GET_RESUME,
      request,
      config,
    );
  }

  /**
   * Used to determine if the pause point details to be set to Resume or not.
   * @param {IPausePoint} pausePointDetails
   * @param {IDeepLinkData} deepLink
   * @returns {boolean}
   */
  private verifyPausePointDetails(
    pausePointDetails?: IPausePoint,
    deepLink?: IDeepLinkData,
  ): boolean {
    if (!pausePointDetails) {
      return false;
    }

    if (!deepLink) {
      return true;
    }

    switch (pausePointDetails.contentType) {
      case ContentTypes.LIVE_AUDIO:
      case DeepLinkTypes.LIVE_VIDEO: {
        //NOTE: Reason for regex - When channel name comes from deep link will not have any spaces between strings.
        const channelName = pausePointDetails.channelName
          ? pausePointDetails.channelName.replace(/ +/g, '')
          : '';
        return (
          deepLink.id === channelName &&
          deepLink.type === pausePointDetails.contentType
        );
      }
      case ContentTypes.AOD: {
        // NOTE: Reason for using publicInfoIdentifier - when AOD deep link comes the id will be public info identifier.
        return (
          deepLink.id === pausePointDetails.publicInfoIdentifier.toString() &&
          deepLink.type === pausePointDetails.contentType
        );
      }
      case ContentTypes.VOD: {
        // NOTE: When VOD deep link comes the id will be caId which vodEpisodeGuid.
        return (
          deepLink.id === pausePointDetails.caId.toString() &&
          deepLink.type === pausePointDetails.contentType
        );
      }
    }
  }

  /**
   * Returns the deeplink type value.
   * @param type
   */
  private getDeepLinkType(type: string): string {
    if (type === DeepLinkTypes.AIC) {
      return ContentTypes.AIC;
    }
    if (type === DeepLinkTypes.PANDORA) {
      return ContentTypes.SEEDED_RADIO;
    }
    return type;
  }
}
