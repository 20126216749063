import { of as observableOf, Observable } from 'rxjs';
import { share, catchError, mergeMap } from 'rxjs/operators';
import { RecentlyPlayedDelegate } from './recently-played.delegate';
import { IProviderDescriptor, addProvider, Logger } from '../index';

/**
 * @MODULE:     service-lib
 * @CREATED:    09/13/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * RecentlyPlayedService to get the recently played data for the user.
 */

export class RecentlyPlayedService {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('RecentlyPlayedService');

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(RecentlyPlayedService, RecentlyPlayedService, [
      RecentlyPlayedDelegate,
    ]);
  })();

  /**
   * Uses to remove recent item or all recents.
   * @param {RecentlyPlayedDelegate} recentlyPlayedDelegate
   */
  constructor(private recentlyPlayedDelegate: RecentlyPlayedDelegate) {}

  /**
   * This method used to remove a single recently played item.
   * @param {string} recentPlayId
   * @param {string} recentPlayAssetGuid
   * @param {string} recentPlayType
   * @returns {Observable<boolean>} - return boolean value based on success or failure API call.
   */
  public removeRecentlyPlayedItem(
    recentPlayId: string,
    recentPlayAssetGuid: string,
    recentPlayType: string,
  ): Observable<boolean> {
    if (!recentPlayId || !recentPlayAssetGuid) {
      RecentlyPlayedService.logger.debug(
        "removeRecentlyPlayedItem()-- Failed to remove , couldn't find the RecentlyPlayed id",
      );
      return observableOf(false);
    }

    RecentlyPlayedService.logger.debug('removeRecentlyPlayed )');

    const recentlyPlayedResponse: Observable<any> = this.recentlyPlayedDelegate.removeRecentlyPlayedItem(
      recentPlayId,
      recentPlayAssetGuid,
      recentPlayType,
    );

    return this.discoverRecentlyPlayedResponse(recentlyPlayedResponse);
  }

  /**
   * This method used to remove all recently played items.
   * @returns {Observable<boolean | {}>} - return boolean value based on success or failure API call.
   */
  public removeAllRecentlyPlayed(): Observable<boolean> {
    RecentlyPlayedService.logger.debug('removeAllRecentlyPlayed )');

    const recentlyPlayedResponse: Observable<any> = this.recentlyPlayedDelegate.removeAllRecentlyPlayed();

    return this.discoverRecentlyPlayedResponse(recentlyPlayedResponse);
  }

  /**
   * Set recentlyPlayed Date after response available from API.
   * @param response - returned from the API
   * @returns {Observable<boolean>} - return boolean value based on success or failure API call.
   */
  private discoverRecentlyPlayedResponse(
    response: Observable<any>,
  ): Observable<boolean> {
    response.subscribe(
      onRecentlyPlayedSuccess.bind(this),
      onRecentlyPlayedFault.bind(this),
    );

    //TODO - Can we clean this up, and make it more succinct.
    return response.pipe(
      mergeMap((/*response: any*/) => {
        return observableOf(true);
      }),
      catchError(() => observableOf(false)),
      share(),
    );

    /**
     * Once the recentlyPlayed data becomes available, save it to the recentlyPlayedResponse.
     * @param recentlyPlayedResponse - recentlyPlayedResponse is returned by the delegate.
     */
    function onRecentlyPlayedSuccess(/*response: any*/) {
      RecentlyPlayedService.logger.error('onRecentlyPlayedSuccess');
    }

    /**
     * Once delegate Throws exception then fault handler get called.
     * @param error - error is returned by the delegate
     */
    function onRecentlyPlayedFault(error: Error) {
      RecentlyPlayedService.logger.error(
        `onRecentlyPlayedFault - Error (${JSON.stringify(error)})`,
      );
    }
  }
}
