import {
    IProviderDescriptor,
    addProvider
}                 from "../../service";
import { Logger } from "../../logger";
import { IEvent } from "../event.interface";

/**
 * @MODULE:     service-lib
 * @CREATED:    07/11/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *      This EventDispatcher is a global event bus that handles publishing and subscribing of events.
 *      It should only be used for global notifications and NOT to kick off long processes or service
 *      orchestration -- use the Observable API based services.
 */
export class EventBus
{
    /**
     * Internal logger.
     * @type {Logger}
     */
    private static logger: Logger = Logger.getLogger("EventBus");

    /**
     * Hash of event listener methods mapped to an event type string. Each type can have multiple listeners,
     * so it's an array: this._listeners[type] = [];
     *
     * @type {Object}
     * @private
     */
    private _listeners: Object = null;

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor : IProviderDescriptor = function()
    {
        return addProvider(EventBus,EventBus);
    }();

    /**
     * Constructor.
     */
    constructor() { }

    /**
     * Adds an event listener or callback method to the event bus. Maintains a hash of listeners based on
     * event type.
     *
     * @param type - The string event type linked to an event listener or callback method.
     * @param listener - The event listener method or callback for a given event type.
     */
    public addEventListener(type: string, listener: Function): void
    {
        // TODO: BMR: Consider adding in checks for required params. Didn't add this yet as we don't have a generic error object for the library.
        // if(ObjectUtil.isUndefinedOrNull(type))
        // {
        //     throw new PlayerError(PlayerError.REQUIRED_PARAM, ["type"]);
        // }
        //
        // if(ObjectUtil.isUndefinedOrNull(listener))
        // {
        //     throw new PlayerError(PlayerError.REQUIRED_PARAM, ["listener"]);
        // }

        if (!this._listeners)
        {
            this._listeners = {};
        }

        if (!this._listeners[ type ])
        {
            this._listeners[ type ] = [];
        }

        if (this._listeners[ type ].indexOf(listener) === -1)
        {
            EventBus.logger.debug(`addEventListener( ${type} )`);
            this._listeners[ type ].push(listener);
        }
    }

    /**
     * Removes an event listener or callback method to the event bus.
     *
     * @param type - The string event type linked to an  event listener or callback method.
     * @param listener - The event listener method or callback for a given event type.
     * @return Flag indicating if the event listener was found and removed.
     */
    public removeEventListener(type: string, listener: Function): boolean
    {
        if (!this._listeners)
        {
            return false;
        }

        const listenerArray: Array<Function> = this._listeners[ type ];

        if (Array.isArray(listenerArray))
        {
            const index: number = listenerArray.indexOf(listener);

            if (index !== -1)
            {
                EventBus.logger.debug(`removeEventListener( ${type} )`);

                // Remove the specific listener function from the types hash.
                listenerArray.splice(index, 1);

                // If there's no listener functions left for a given type, remove the type from the hash.
                if (listenerArray.length <= 0)
                {
                    delete this._listeners[ type ];
                }

                return true;
            }
        }

        return false;
    }

    /**
     * Determines if the event type has a matching, expected listener.
     *
     * @param type - The string event type linked to an event listener or callback method.
     * @param listener - The event listener method or callback for a given event type.
     * @returns {Boolean}
     */
    public hasEventListener(type: string, listener: Function): boolean
    {
        if (!this._listeners)
        {
            return false;
        }

        const listeners: Array<Function> = this._listeners[ type ];

        if (!listeners)
        {
            return false;
        }
        else
        {
            const activeListener: Function = this._listeners[ type ].find((item) =>
            {
                return (item === listener);
            });

            return !!activeListener;
        }
    }

    /**
     * @description Dispatches the event object, which essentially invokes any event listener or callback methods
     *     associated with the type.
     *
     * The event object must contain a `type` property
     *
     * @param event - The event object contains a type property at a minimum and any data associated with it.
     */
    public dispatch(event: IEvent): boolean
    {
        // TODO: BMR: Consider adding in checks for required params. Didn't add this yet as we don't have a generic error object for the library.
        // if(ObjectUtil.isUndefinedOrNullOrEmptyString(event.type))
        // {
        //     throw new Error("All events must have a non-empty string `type` property.");
        // }
        EventBus.logger.debug(`dispatch( ${event.type} )`);

        if (!this._listeners)
        {
            return false;
        }

        const listenerArray: Array<Function> = this._listeners[ event.type ];

        if (listenerArray)
        {
            event.target = this;

            const array: Array<Function> = [];
            const length: number = listenerArray.length;

            for (let i: number = 0; i < length; i++)
            {
                array[ i ] = listenerArray[ i ];
            }

            for (let j: number = 0; j < length; j++)
            {
                if (!event.currentTarget)
                {
                    event.currentTarget = this;
                }

                array[ j ].call(this, event);

            }
            return true;
        }
        return false;
    }
}
