import {
    Logger
} from "../index";
import { IrNavClassConstants } from "./ir-nav-class.const";
import { BehaviorSubject, Subject, Subscription, timer as observableTimer } from "rxjs";
import {
    filter,
    take,
    debounceTime,
    skip
} from "rxjs/operators";
import {
    SkipInfoDictionary,
    IDmcaInfoItem,
    SkipInfo
} from "./dmca.interface";
import { IProviderDescriptor } from "../index";
import { addProvider } from "../index";
import { MediaUtil } from "../index";
import { DateUtil } from "../util/date.util";
import { SettingsService } from "../settings/settings.service";
import { SettingsConstants } from "../settings/settings.const";
import { ISettings } from "../index";



export class DmcaService
{
    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("DmcaService");

    public skipInfoDictionary$: BehaviorSubject<SkipInfoDictionary>;

    public skipHappenedSkipInfo$: Subject<SkipInfo>;

    public timerSubscription: Subscription;

    public skipSubscription: Subscription;

    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(DmcaService, DmcaService, [
            SettingsService
        ]);
    }();

    /**
     * Constructor.
     */
    constructor(public settingsService: SettingsService)
    {
        const setup = (dictionary: any, isGupAvailable: boolean = false) =>
        {
            this.skipInfoDictionary$ = new BehaviorSubject<SkipInfoDictionary>(dictionary);

            if (this.skipSubscription) this.skipSubscription.unsubscribe();
            this.skipSubscription = this.skipInfoDictionary$
                .pipe(
                    skip(1),
                    debounceTime(4000)
                )
                .subscribe((dictionary: SkipInfoDictionary) =>
                {
                    if (isGupAvailable)
                    {
                        this.settingsService.setGlobalSettingValue(
                            JSON.stringify(dictionary),
                            SettingsConstants.SKIP_DMCA_DICTIONARY
                        );
                    }
                });

            this.resetSkips();
        };



        this.settingsService.settings
            .pipe(
                filter((iSettings: ISettings) =>
                {
                    return !!(iSettings
                        && iSettings.globalSettings);
                }),
                take(1)
            )
            .subscribe(() =>
            {
                const self = this;
                const skipData: string = this.settingsService
                    .getGlobalSettingValue(SettingsConstants.SKIP_DMCA_DICTIONARY) as string;

                const extantDictionary = JSON.parse(skipData) || {};

                setup(extantDictionary, true);
            });

        setup({});

        this.skipHappenedSkipInfo$ = new Subject<SkipInfo>();
    }

    /**
     * Indicates if the supplied DmcaInfoItem is unrestricted
     * @param {IDmcaInfoItem} item
     * @returns {boolean}
     */
    public isUnrestricted(item: IDmcaInfoItem): boolean
    {
        const touched = this.touchItem(item);
        const toCompare = (touched.dmcaInfo.irNavClass || "").toUpperCase();
        return toCompare !== ""
            && toCompare.indexOf(IrNavClassConstants.PATTERN_UNRESTRICTED) > -1;
    }

    /**
     * Indicates if the supplied IRNavClass is restricted.
     * @param {IDmcaInfoItem} item
     * @returns {boolean}
     */
    public isRestricted(item: IDmcaInfoItem): boolean
    {
        const touched = this.touchItem(item);
        const toCompare = (touched.dmcaInfo.irNavClass || "").toUpperCase();
        return toCompare !== ""
            && toCompare.indexOf(IrNavClassConstants.PATTERN_RESTRICTED) > -1
            && toCompare.indexOf(IrNavClassConstants.PATTERN_UNRESTRICTED) === -1;
    }

    /**
     * Indicates if the supplied IRNavClass is restricted.
     * @param {IDmcaInfoItem} item
     * @returns {boolean}
     */
    public isDisallowed(item: IDmcaInfoItem): boolean
    {
        const touched = this.touchItem(item);
        const toCompare = (touched.dmcaInfo.irNavClass || "").toUpperCase();
        return toCompare !== ""
            && toCompare.indexOf(IrNavClassConstants.PATTERN_DISALLOWED) > -1;
    }


    public getMaxTotalSkips(item: IDmcaInfoItem): number
    {
        // is zero a good default?
        return item.dmcaInfo.maxTotalSkips || 0;
    }


    public getMaxBackSkips(item: IDmcaInfoItem): number
    {
        // is zero a good default?
        return item.dmcaInfo.maxBackSkips || 0;
    }

    public hasSkipsRemaining(item: IDmcaInfoItem): boolean
    {
        const skipInfo: SkipInfo = this.getSkipInfo(item);

        if (!skipInfo) return true;

        const { numSkipsBack, numSkipsForward, maxTotalSkips } = skipInfo;
        return numSkipsForward + numSkipsBack < maxTotalSkips;
    }


    public skipFwdWasSuccessful(item: IDmcaInfoItem)
    {
        item = this.touchItem(item);
        if (!this.hasSkipsRemaining(item))
        {
            return;
        }
        const skipInfo = this.getSkipInfo(item);
        const key = this.getKey(item);
        const dictionary = this.skipInfoDictionary$.getValue();
        if (!skipInfo)
        {
            dictionary[key] = {
                mediaId: item.mediaId,
                mediaType: item.mediaType,
                numSkipsBack: 0,
                numSkipsForward: 1,
                skipTimestamp: new Date().toUTCString(),
                maxTotalSkips: item.dmcaInfo.maxTotalSkips || 6
            };

            if (MediaUtil.isSeededRadioMediaType(item.mediaType))
            {
                dictionary[key].secondaryMediaId = (item as any).stationId;
            }
        }
        else
        {
            dictionary[key] = {
                ...skipInfo,
                numSkipsForward: skipInfo.numSkipsForward + 1,
                skipTimestamp: new Date().toUTCString()
            };
        }
        this.skipInfoDictionary$.next(dictionary);
        this.skipHappenedSkipInfo$.next(dictionary[key]);
        this.resetSkips();
    }


    public skipBackWasSuccessful(item: IDmcaInfoItem)
    {
        item = this.touchItem(item);
        if (!this.hasSkipsRemaining(item))
        {
            return;
        }
        const skipInfo = this.getSkipInfo(item);
        const key = this.getKey(item);
        const dictionary = this.skipInfoDictionary$.getValue();
        if (!skipInfo)
        {
            dictionary[key] = {
                mediaId: item.mediaId,
                mediaType: item.mediaType,
                numSkipsBack: 1,
                numSkipsForward: 0,
                skipTimestamp: new Date().toUTCString(),
                maxTotalSkips: item.dmcaInfo.maxTotalSkips || 6
            };

            if (MediaUtil.isSeededRadioMediaType(item.mediaType))
            {
                dictionary[key].secondaryMediaId = (item as any).stationId;
            }
        }
        else
        {
            dictionary[key] = {
                ...skipInfo,
                numSkipsBack: skipInfo.numSkipsBack + 1,
                skipTimestamp: new Date().toUTCString()
            };
        }
        this.skipInfoDictionary$.next(dictionary);
        this.skipHappenedSkipInfo$.next(dictionary[key]);
        this.resetSkips();
    }


    public getSkipInfo(item: IDmcaInfoItem): SkipInfo
    {
        const key = this.getKey(item);
        if (!key) return null;

        const dictionary = this.skipInfoDictionary$.getValue();
        return dictionary[key];
    }

    /**
     * Algorithm is
     * 1. cancel any timer.
     * 2. look for earliest entry among an skip infos.
     * 3. delete earliest entry out of dictionary only it's an hour later than
     *    earliest entry.
     * 4. calculate dueTime. dueTime is the first arg in rxjs timer()
     * 5. see if dueTime value makes sense
     * 6. set new timer for dueTime
     */
    private resetSkips()
    {
        const now = Date.now();
        // 1.
        if (this.timerSubscription && !this.timerSubscription.closed)
        {
            this.timerSubscription.unsubscribe();
        }

        const dictionary = this.skipInfoDictionary$.getValue();

        // 2.
        const earliestKey = this.findEarliestKey(dictionary);
        const earliestValue: SkipInfo = dictionary[earliestKey];
        const dateStr = earliestValue && earliestValue.skipTimestamp;
        const earliestTime =
            dateStr ? new Date(dateStr).getTime()
                    : now;


        // 3.
        if (now - earliestTime >= DateUtil.MS_IN_HOUR)
        {
            delete dictionary[earliestKey];
            this.skipInfoDictionary$.next(dictionary);
            return this.resetSkips();
        }

        // 4.
        let dueTime = DateUtil.MS_IN_HOUR - (now - earliestTime);
        // dueTime is the first arg in rxjs timer( );

        // 5.
        if (dueTime <= 0)
        {
            // we can't set a timer to 0 or less so recurse.
            return this.resetSkips();
        }

        // 6.
        this.timerSubscription = observableTimer(dueTime)
            .subscribe(() =>
            {
                this.resetSkips();
            });
    }



    public findEarliestKey(dictionary: SkipInfoDictionary)
    {
        let minTime = Date.now() + 999999;   // default val. all that matters is that
                                             // the time is more than now. lol.

        let minKey;

        for (let key in dictionary)
        {
            const currentSkipInfo = dictionary[key];
            const utcStr = currentSkipInfo.skipTimestamp;
            const time = new Date(utcStr).getTime();
            if (time <= minTime)
            {
                minTime = time;
                minKey = key;
            }
        }
        return minKey;
    }


    /**
     * Get a key for the dmca info item
     * @param {IDmcaInfoItem} item
     */
    private getKey(item: IDmcaInfoItem): string
    {
        return item.mediaId;
    }
    /**
     * This method checks if any critical dmca info is missing.
     * If it's missing, it adds a sensible default so the app
     * can at least operate.
     *
     */
    private touchItem(item: IDmcaInfoItem): IDmcaInfoItem
    {
        if (!item.dmcaInfo)
        {
            throw 'critical info not found on dmca info item!';
        }
        return item;
    }
}
