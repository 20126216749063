import { Subject } from "rxjs";
import {
    IProviderDescriptor,
    addProvider
}                  from "../service";
import { IAsset }  from "./asset-loader.interface";

/**
 * @MODULE:     service-lib
 * @CREATED:    07/11/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * Used to load the assets- SCRIPT/LINKS from the API
 */
export class AssetLoaderService
{
    public asset$: Subject<IAsset> = new Subject();

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(AssetLoaderService, AssetLoaderService, []);
    }();

    constructor(){}

    public addInternalAsset(dirPath: string, assetName: string, callback: Function = () => {}): void
    {
        this.asset$.next({
            kind: 'internal',
            name: assetName,
            dirPath: dirPath,
            onLoadCallback: callback
        });
    }

    public addExternalAsset(url: string, assetName: string, callback: Function = () => {}): void
    {
        if (!url) return;
        this.asset$.next({
            kind: 'external',
            name: assetName,
            url: url,
            onLoadCallback: callback
        });
    }
}
