import {
  combineLatest as observableCombineLatest,
  of,
  BehaviorSubject,
  Observable,
  SubscriptionLike as ISubscription,
} from 'rxjs';
import { map, filter, take } from 'rxjs/operators';
import * as _ from 'lodash';
import { Logger } from '../logger';
import {
  ICurrentlyPlayingMedia,
  IMediaCut,
  IMediaEpisode,
  ITime,
  MediaTimeLine,
} from '../index';
import { TuneService } from '../tune/tune.service';
import { IMediaAssetMetadata } from './media-asset-metadata.interface';
import { IMediaPlayer, IMediaTrigger } from './media-player.interface';
import { MediaUtil } from './media.util';
import { IPlayhead } from './playhead.interface';
import { secondsToMs, msToSeconds } from '../util/utilities';
import { ReplayMedia } from './replay-media.interface';
import { MediaPlayerConstants } from './media-player.consts';
import { CurrentlyPlayingService } from '../currently-playing/currently.playing.service';
import { SessionTransferService } from '../sessiontransfer/session.transfer.service';
import { PlayerTypes } from '../service/types/content.types';
import { MediaTimeLineService } from '../media-timeline/media.timeline.service';
import { DateUtil } from '../util/date.util';

/**
 * @MODULE:     service-lib
 * @CREATED:    10/24/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 */
export class MediaPlayer implements IMediaPlayer {
  /**
   * Internal logger.
   */
  private static _logger: Logger = Logger.getLogger('MediaPlayer');

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the current playhead timestamp.
   */
  public playhead$: Observable<IPlayhead> = null;

  /**
   * Stream that emits a flag indicating that the currently loaded media asset has
   * played in its entirety, and a subsequent "play" request has not yet been made.
   */
  public playbackComplete$: BehaviorSubject<boolean> = null;

  /**
   * This observable can be subscribed to from the outside world to get playback state updates
   */
  public playbackState: Observable<string>;

  /**
   * tuneMediaTimeLine saved of from mediaTimeLineService.mediaTimeLine Observable.
   * @type {MediaTimeLine}
   */
  public mediaTimeLine: MediaTimeLine;

  /**
   * Used to trigger changes in playback state that can be subscribed to.
   */
  protected playbackStateSubject: BehaviorSubject<string> = new BehaviorSubject(
    '',
  );

  /**
   * Getter for the flag indicating if the player is current on the client.
   * @returns {boolean}
   */
  public get isCurrent() {
    return this._isCurrent;
  }

  /**
   * Current the player's `mediaType`.
   */
  public mediaType: string = null;

  /**
   * Current the player's `mediaId`.
   */
  public mediaId: string = null;

  /**
   * Current the player's type LOCAL or REMOTE.
   */
  public playerType: string = PlayerTypes.LOCAL;

  public finished() {
    this.playbackStateSubject.next(MediaPlayerConstants.FINISHED);
  }

  /**
   * Supported playback types that the media player can handle.  This array needs to get populated when the media
   * player is specialized.
   */
  protected playbackTypes: Array<string> = [];

  /**
   * Reference to the current playing data.
   */
  protected currentlyPlayingMedia: ICurrentlyPlayingMedia;

  /**
   * Reference to the current cut.
   */
  protected currentCut: IMediaCut;

  /**
   * Reference to the current cut start and end timestamps.
   */
  protected times: ITime;

  /**
   * The last media player's zulu timestamp before we switch to a new media player.
   */
  protected lastMediaPlayerPlayheadZulu: number = 0;

  /**
   * The initial volume of the player before it's created.
   * Value between 0 and 100.
   */
  protected initialVolume: number = NaN;

  /**
   * The last volume of the player before it was muted.
   */
  protected mutedVolume: number = NaN;

  /**
   * Indicates if the player is muted.
   */
  protected isMuted: boolean = false;

  /**
   * The last seek time used. This is primarily used for BI consume reporting for seeking.
   */
  protected lastSeekTime: number = 0;

  /**
   * Cuts from/to saved before a seek, scrub, skip, rewind
   * is attempted.
   * Recorded for BI consume reporting.
   */
  protected jumpActionCuts = {
    from: {} as IMediaCut,
    to: {} as IMediaCut,
  };

  /**
   * Data container that's used to replay by retuning the currently playing content.
   */
  public replayMedia: ReplayMedia = new ReplayMedia();

  /**
   * Backing variable indicating if the player is current on the client.
   * @type {boolean}
   * @private
   */
  protected _isCurrent: boolean = false;

  /**
   * Stores a reference to a subscription
   */
  private timelineSubscription: ISubscription;

  /**
   * Stores a reference to a subscription
   */
  private tuneTimelineSubscription: ISubscription;

  /**
   * Stores a reference to a subscription
   */
  private nowPlayingSubscription: ISubscription;

  public hasWarmedUp: boolean = false;

  /**
   * Constructor.
   * @param {TuneService} tuneService
   * @param {CurrentlyPlayingService} currentlyPlayingService
   * @param {SessionTransferService} sessionTransferService
   * @param {MediaTimeLineService} mediaTimeLineService
   */
  constructor(
    protected tuneService: TuneService,
    protected currentlyPlayingService: CurrentlyPlayingService,
    protected sessionTransferService: SessionTransferService,
    protected mediaTimeLineService: MediaTimeLineService,
  ) {
    this.playbackComplete$ = new BehaviorSubject(false);
    this.timelineSubscription = { unsubscribe: () => {}, closed: false };
    this.nowPlayingSubscription = { unsubscribe: () => {}, closed: false };
    this.tuneTimelineSubscription = { unsubscribe: () => {}, closed: false };

    this.playbackStateSubject.next('');
    this.playbackState = this.playbackStateSubject;

    this.monitorPlaybackComplete();
    this.monitorPlaybackFailure();
  }

  /**
   * Hook operation for the play() method. Concrete implementations must override this method.
   */
  public play(): void {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement play()',
    );
  }

  /**
   * Hook operation for the pause() method. Concrete implementations must override this method.
   */
  public pause(): void {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement pause()',
    );
  }

  /**
   * Hook operation for the resume() method. Concrete implementations must override this method.
   */
  public resume(): void {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement resume()',
    );
  }

  /**
   * Hook operation for the togglePausePlay() method. Concrete implementations must override this method.
   */
  public togglePausePlay(): void {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement togglePausePlay()',
    );
  }

  /**
   * Hook operation for the seek() method. Concrete implementations must override this method.
   */
  public seek(timestamp: number): void {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement seek()',
    );
  }

  /**
   * Hook operation for the stop() method. Concrete implementations must override this method.
   */
  public stop(id?: string): void {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement stop()',
    );
  }

  protected sessionTransfer(): Observable<boolean> {
    /*
        return this.playbackStateSubject.getValue() !== MediaPlayerConstants.PAUSED
            ? this.sessionTransferService.castStatusChange(true).pipe(take(1))
            : of(true);
        */

    //Since Chromecast is not supported in Comcast devices, we can ignore this logic and simply pass an observable with true.
    return of(true);
  }

  /**
   * Hook operation for the getId() method. Concrete implementations must override this method.
   */
  public getId(): string {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement getId()',
    );
  }

  /**
   * Hook operation for the getDuration() method. Concrete implementations must override this method.
   */
  public getDuration(): number {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement getDuration()',
    );
  }

  /**
   * Hook operation for the getPlayheadTime() method. Concrete implementations must override this method.
   */
  public getPlayheadTime(): number {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement getPlayheadTime()',
    );
  }

  /**
   * Hook operation for the getPlayheadTimeInSeconds() method. Concrete implementations must override this method.
   */
  public getPlayheadTimeInSeconds(): number {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement getPlayheadTimeInSeconds()',
    );
  }

  /**
   * Hook operation for the getPlayheadZuluTime() method. Concrete implementations must override this method.
   */
  public getPlayheadZuluTime(): number {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement getPlayheadZuluTime()',
    );
  }

  /**
   * Hook operation for the getPlayheadStartTime() method. Concrete implementations must override this method.
   */
  public getPlayheadStartTime(): number {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement getPlayheadStartTime()',
    );
  }

  /**
   * Hook operation for the getPlayheadStartZuluTime() method. Concrete implementations must override this method.
   */
  public getPlayheadStartZuluTime(): number {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement getPlayheadStartZuluTime()',
    );
  }

  /**
   * Hook operation for the getPlaybackType() method. Concrete implementations must override this method.
   */
  public getPlaybackType(): string {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement getPlaybackType()',
    );
  }

  /**
   * Hook operation for the getState() method. Concrete implementations must override this method.
   */
  public getState(): string {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement getState()',
    );
  }

  /**
   * Hook operation for the getType() method. Concrete implementations must override this method.
   */
  public getType(): string {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement getType()',
    );
  }

  /**
   * Hook operation for the getMediaAssetMetadata() method. Concrete implementations must override this method.
   */
  public getMediaAssetMetadata(): IMediaAssetMetadata {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement getMediaAssetMetadata()',
    );
  }

  /**
   * Hook operation for the destroy() method. Concrete implementations must override this method.
   */
  public destroy() {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement destroy()',
    );
  }

  /**
   * Hook operation for the isPlaying() method. Concrete implementations must override this method.
   */
  public isPlaying(): boolean {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement isPlaying()',
    );
  }

  /**
   * Hook operation for the isPaused() method. Concrete implementations must override this method.
   */
  public isPaused(): boolean {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement isPaused()',
    );
  }

  /**
   * Hook operation for the isStopped method. Concrete implementations must override this method.
   */
  public isStopped(): boolean {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement isStopped()',
    );
  }

  /**
   * Hook operation for the isFinished() method. Concrete implementations must override this method.
   */
  public isFinished(): boolean {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement isFinished()',
    );
  }

  /**
   * Hook operation for the isLive() method. Concrete implementations must override this method.
   */
  public isLive(): boolean {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement isLive()',
    );
  }

  /**
   * get the volume from media player
   * @returns {number}
   */
  public getVolume(): number {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement getVolume()',
    );
  }

  /**
   * Sets the volume to media player
   * @param {number} volume - volume
   */
  public setVolume(volume: number): void {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement setVolume()',
    );
  }

  /**
   * Mutes media player
   */
  public mute(): void {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement public()',
    );
  }

  /**
   * Unmutes media player.
   */
  public unmute(): void {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement unmute()',
    );
  }

  /**
   * Provides all media players access to the current episode's zulu start time.
   */
  public getEpisodeStartTimeZulu(): number {
    return _.get(
      this,
      'currentlyPlayingMedia.episode.times.zuluStartTime',
      0,
    ) as number;
  }

  /**
   * Provides all media players access to the current video's zulu start time.
   */
  public getVideoStartTimeZulu(): number {
    return _.get(
      this,
      'currentlyPlayingMedia.video.times.zuluStartTime',
      0,
    ) as number;
  }

  /**
   * Provides all media players access tom the current episode's zulu start time.
   */
  public getCurrentEpisode(): IMediaEpisode {
    return _.get(
      this,
      'currentlyPlayingService.currentlyPlayingMediaData.episode',
      null,
    ) as IMediaEpisode;
  }

  /**
   * Provides all media players access tom the current episode's zulu end time.
   */
  public getEpisodeEndTimeZulu(): number {
    return _.get(
      this,
      'currentlyPlayingMedia.episode.times.zuluEndTime',
      0,
    ) as number;
  }

  /**
   * Hook operation for the getDurationInSeconds() method. Concrete implementations must override this method.
   */
  public getDurationInSeconds(): number {
    let duration = _.get(
      this,
      'currentlyPlayingMedia.episode.duration',
      0,
    ) as number;
    return duration;
  }

  /**
   * Converts a zero-based second value to a zulu-based millisecond value. This is most commonly used when
   * seeking and the UI provides a zero-based second value.
   *
   * @param seconds
   * @returns {number}
   */
  public convertZeroBasedSecondsToZulu(seconds: number): number {
    return this.getEpisodeStartTimeZulu() + secondsToMs(seconds);
  }

  /**
   * Converts an epoch-based value to a zero-based seconds value.
   * Defaults to using beginning of episode.
   *
   * @param zulu
   * @returns {number}
   */

  public convertZuluToZeroBasedSeconds(zulu: number): number {
    if (!DateUtil.isZulu(zulu)) {
      throw 'invalid argument';
    }
    let diff = zulu - this.getEpisodeStartTimeZulu();
    diff = diff >= 0 ? diff : 0;
    return msToSeconds(diff);
  }

  /**
   * Getter for the last known seek time.
   */
  public getLastSeekTime(): number {
    return this.lastSeekTime;
  }

  /**
   * Returns the current jump action cuts
   */
  public getJumpActionCuts() {
    return this.jumpActionCuts;
  }

  /**
   * Hook operation for the onNewMedia() method. Concrete implementations must override this method.
   */
  protected onNewMedia(
    mediaTimeLine: MediaTimeLine,
    mediaTrigger: IMediaTrigger,
  ) {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement onNewMedia()',
    );
  }

  /**
   * Hook operation for the onNewTuneMedia() method. Concrete implementations must override this method.
   */
  protected onNewTuneMedia(mediaTimeLine: MediaTimeLine) {
    throw new Error(
      'Concrete implementations of the MediaPlayer class must implement onNewMedia()',
    );
  }

  /**
   * Set up subscribers to observables.
   */
  protected setSubscribers(): void {
    this.observeMediaTimeLine();
    this.observeNowPlayingData();
  }

  /**
   * Determines if the player is current by checking its playback types against the current media type.
   * @param {string} mediaType
   * @returns {boolean}
   */
  protected isCurrentPlayer(mediaType: string): boolean {
    return _.includes(this.playbackTypes, mediaType);
  }

  /**
   * Monitors playback completion for the current media.
   *
   * If the completed playback is for On Demand content then set the flag indicating it can be replayed
   * and reset the now playing data so users can retune to the same content.
   */
  protected monitorPlaybackComplete(): void {
    const isPlaybackComplete$: Observable<boolean> = this.playbackComplete$.pipe(
      filter((bool: boolean) => bool === true),
    );

    const currentlyPlayingData$: Observable<ICurrentlyPlayingMedia> = this.currentlyPlayingService.currentlyPlayingData.pipe(
      filter((data: ICurrentlyPlayingMedia) => !!data),
    );

    const mediaTimeLine$: Observable<MediaTimeLine> = this.mediaTimeLineService.mediaTimeLine.pipe(
      filter((data: MediaTimeLine) => !!data),
    );

    observableCombineLatest(
      currentlyPlayingData$,
      mediaTimeLine$,
      isPlaybackComplete$,
      (
        currentlyPlayingData: ICurrentlyPlayingMedia,
        mediaTimeLine: MediaTimeLine,
        isPlaybackComplete: boolean,
      ) => {
        if (MediaUtil.isOnDemandMediaType(this.mediaType)) {
          MediaPlayer._logger.debug(
            `monitorPlaybackComplete( mediaType: ${this.mediaType} )`,
          );

          // Save the currently playing data so we can retune by calling the `now-playing` API again.
          this.replayMedia = {
            currentlyPlaying: currentlyPlayingData,
            mediaTimeLine: mediaTimeLine,
            allowReplayOfSameContent: isPlaybackComplete,
          };
        }
      },
    ).subscribe();
  }

  /**
   * Monitors playback completion for the current media.
   *
   * If the completed playback is for On Demand content then set the flag indicating it can be replayed
   * and reset the now playing data so users can retune to the same content.
   */
  protected monitorPlaybackFailure(): void {}

  /**
   * Provides the media player the ability to retune to the same content that was playing. This is often used
   * when trying to replay the content that just completed playback.
   */
  protected retune(): Observable<string> {
    const allowReplay: boolean =
      this.replayMedia.allowReplayOfSameContent || false;
    const currentlyPlaying: ICurrentlyPlayingMedia = this.replayMedia
      .currentlyPlaying;

    if (allowReplay && currentlyPlaying) {
      const channelId: string = this.replayMedia.currentlyPlaying.channelId;
      const contentType: string = this.replayMedia.currentlyPlaying.mediaType;
      const episodeId: string = this.replayMedia.currentlyPlaying.mediaId;

      MediaPlayer._logger.debug(
        `retune( channelId: ${channelId}, contentType: ${contentType}, episodeId: ${episodeId} )`,
      );
      return this.tuneService
        .retune(this.replayMedia.mediaTimeLine)
        .pipe(map((result: boolean) => result.toString()));
    } else {
      return of('false');
    }
  }

  /**
   * Observe data changes on the media time line. If there's new media, start audio playback.
   */
  private observeMediaTimeLine(): void {
    this.timelineSubscription.unsubscribe();
    this.timelineSubscription = this.mediaTimeLineService.mediaTimeLine.subscribe(
      (mediaTimeLine: MediaTimeLine) => {
        if (mediaTimeLine) {
          const isNewMediaId: boolean = this.mediaId !== mediaTimeLine.mediaId;
          const isNewMediaType: boolean =
            this.mediaType !== mediaTimeLine.mediaType;
          const isNewPlayerType = this.playerType !== mediaTimeLine.playerType;

          if (
            isNewMediaId ||
            isNewMediaType ||
            isNewPlayerType ||
            MediaUtil.isMultiTrackAudioMediaType(mediaTimeLine.mediaType) ||
            this.replayMedia.allowReplayOfSameContent ||
            mediaTimeLine.forceRetune
          ) {
            if (isNewMediaType || isNewMediaId) {
              this.finished();
            }

            this.replayMedia.allowReplayOfSameContent = false;
            this.lastMediaPlayerPlayheadZulu = mediaTimeLine.startTime
              ? mediaTimeLine.startTime
              : 0;

            const mediaType =
              mediaTimeLine.playerType === PlayerTypes.REMOTE
                ? PlayerTypes.REMOTE
                : mediaTimeLine.mediaType;

            // Set the flag indicating if this player is the current one.
            this._isCurrent = this.isCurrentPlayer(mediaType);

            // Save a reference to the new media timeline.
            this.mediaTimeLine = mediaTimeLine; //this._isCurrent ? mediaTimeLine : null;

            // Save the media type so we can determine if there's a new one next time.
            this.mediaType = mediaTimeLine.mediaType;

            // Save the media Id so we can determine if there's a new media or same one.
            this.mediaId = mediaTimeLine.mediaId;

            this.playerType = mediaTimeLine.playerType;

            // Call the hook operation in concrete implementations of the MediaPlayer.
            this.onNewMedia(this.mediaTimeLine, {
              isNewMediaId: isNewMediaId,
              isNewMediaType: isNewMediaType,
              isNewPlayerType: isNewPlayerType,
            });
          }
        }
      },
    );
  }

  /**
   * Saves the currently playing data so they can be inspected later for business intelligence reporting.
   */
  private observeNowPlayingData(): void {
    this.nowPlayingSubscription.unsubscribe();
    this.nowPlayingSubscription = this.currentlyPlayingService.currentlyPlayingData.subscribe(
      (data: ICurrentlyPlayingMedia) => {
        if (!data) return;

        const newCut: IMediaCut = _.get(data, 'cut', null);
        const myPlaybackType: string = getPlaybackType.call(this);

        this.currentCut = newCut;

        this.currentlyPlayingMedia = data;
      },
    );

    function getPlaybackType(): string {
      if (this.tuneMediaTimeLine) {
        return _.find(
          this.playbackTypes,
          (type: string) => type === this.mediaTimeLine.mediaType,
        );
      } else {
        return 'invalidPlaybackType';
      }
    }
  }

  public warmUp(): Observable<string> {
    this.hasWarmedUp = true;
    return of('warmed up');
  }
}
