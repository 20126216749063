import { IAction } from './action.interface';
import { ICarouselDataByType, IGroupedCarousel } from '../../servicelib';

export interface ICarouselActionPayload {
  carousel: ICarouselDataByType;
}

class SelectCarousel implements IAction {
  readonly type = null;
  public payload: ICarouselActionPayload;
  constructor(carousel: ICarouselDataByType) {
    this.payload = { carousel: carousel };
  }
}

export interface IGroupedCarouselActionPayload {
  carousel: IGroupedCarousel[];
}

class SelectGroupedCarousel implements IAction {
  readonly type = null;
  public payload: IGroupedCarouselActionPayload;

  constructor(carousel: IGroupedCarousel[]) {
    this.payload = { carousel: carousel };
  }
}

export class CarouselActions {
  static SELECT_SUPERCATEGORY_CAROUSEL: string =
    '[Supercategory Carousel] select';
  static SELECT_SUBCATEGORY_CAROUSEL: string = '[Subcategory Carousel] select';
  static SELECT_NOWPLAYING_CAROUSEL: string = '[Nowplaying Carousel] select';
  static SELECT_NOWPLAYING_UP_NEXT_CAROUSEL: string =
    '[Nowplaying Up Next Carousel] select';
  static SELECT_PROFILE_CAROUSEL: string = '[Profile Carousel] select';
  static SELECT_SEARCH_CAROUSEL: string = '[Search Carousel] select';
  static SELECT_SEARCH__LANDING_CAROUSEL: string =
    '[Search Landing Carousel] select';
  static SELECT_CONTENT_CAROUSEL: string = '[Content Carousel] select';
  static SELECT_ALL_ON_DEMAND_CAROUSEL: string =
    '[All On demand Carousel] select';
  static SELECT_ALL_VIDEO_CAROUSEL: string = '[All Video Carousel] select';
  static SELECT_ALL_CHANNELS_CAROUSEL: string =
    '[All Channels Carousel] select';
  static SELECT_EPISODE_CAROUSEL: string = '[episode Carousel] select';
  static SELECT_SHOW_CAROUSEL: string = '[show Carousel] select';
  static SELECT_RECENTS_CAROUSEL: string = '[Recents Carousel] select';
  static SELECT_SHOW_REMINDER_CAROUSEL: string =
    '[Show Reminder Carousel] select';
  static SELECT_VIEW_ALL_CAROUSEL: string = '[View All Carousel] select';
  static SELECT_FILTERED_SHOW_CAROUSEL: string =
    '[Filtered Show Carousel] select';
  static SELECT_GROUPED_SHOW_CAROUSEL: string =
    '[Grouped Show Carousel] select';
  static SELECT_COLLECTION_CAROUSEL: string = '[Collection Carousel] select';
  static SELECT_ENHANCED_EDP_CAROUSEL: string =
    '[Enhanced Edp Carousel] select';
  static SELECT_SEEDED_STATIONS_CAROUSEL: string =
    '[Seeded Stations Carousel] select';
  static SELECT_ALL_PODCAST_CAROUSEL: string = '[All Podcast Carousel] select';
}

/*export class SelectSuperCategoryCarousel extends SelectCarousel {
  readonly type = CarouselActions.SELECT_SUPERCATEGORY_CAROUSEL;
}*/

export const selectSuperCategoryCarousel = (
  carousel: ICarouselDataByType,
): IAction => {
  return {
    type: CarouselActions.SELECT_SUPERCATEGORY_CAROUSEL,
    payload: { carousel },
  };
};

/*export class SelectSubCategoryCarousel extends SelectCarousel {
  readonly type = CarouselActions.SELECT_SUBCATEGORY_CAROUSEL;
}*/

export const selectSubCategoryCarousel = (
  carousel: ICarouselDataByType,
): IAction => {
  return {
    type: CarouselActions.SELECT_SUBCATEGORY_CAROUSEL,
    payload: { carousel },
  };
};

/*export class SelectNowPlayingCarousel extends SelectCarousel {
  readonly type = CarouselActions.SELECT_NOWPLAYING_CAROUSEL;
}*/

export const selectNowPlayingCarousel = (
  carousel: ICarouselDataByType,
): IAction => {
  return {
    type: CarouselActions.SELECT_NOWPLAYING_CAROUSEL,
    payload: { carousel },
  };
};

/*
export class SelectNowPlayingUpNextCarousel extends SelectCarousel {
  readonly type = CarouselActions.SELECT_NOWPLAYING_UP_NEXT_CAROUSEL;
}
*/

export const selectNowPlayingUpNextCarousel = (
  carousel: ICarouselDataByType,
): IAction => {
  return {
    type: CarouselActions.SELECT_NOWPLAYING_UP_NEXT_CAROUSEL,
    payload: { carousel },
  };
};

/*
export class SelectSearchLandingCarousel extends SelectCarousel {
  readonly type = CarouselActions.SELECT_SEARCH__LANDING_CAROUSEL;
}
*/

export const selectSearchLandingCarousel = (
  carousel: ICarouselDataByType,
): IAction => {
  return {
    type: CarouselActions.SELECT_SEARCH__LANDING_CAROUSEL,
    payload: { carousel },
  };
};

export const selectSearchCarousel = (
  carousel: ICarouselDataByType,
): IAction => {
  return {
    type: CarouselActions.SELECT_SEARCH_CAROUSEL,
    payload: { carousel },
  };
};

export class SelectProfileCarousel extends SelectCarousel {
  readonly type = CarouselActions.SELECT_PROFILE_CAROUSEL;
}

export class SelectContentCarousel extends SelectCarousel {
  readonly type = CarouselActions.SELECT_CONTENT_CAROUSEL;
}

export class SelectAllOnDemandCarousel extends SelectCarousel {
  readonly type = CarouselActions.SELECT_ALL_ON_DEMAND_CAROUSEL;
}

// export class SelectAllVideoCarousel extends SelectCarousel {
//   readonly type = CarouselActions.SELECT_ALL_VIDEO_CAROUSEL;
// }

export const selectAllVideoCarousel = (
  carousel: ICarouselDataByType,
): IAction => {
  return {
    type: CarouselActions.SELECT_ALL_VIDEO_CAROUSEL,
    payload: { carousel },
  };
};

/*export class SelectAllChannelsCarousel extends SelectCarousel {
  readonly type = CarouselActions.SELECT_ALL_CHANNELS_CAROUSEL;
}*/

export const selectAllChannelsCarousel = (
  carousel: ICarouselDataByType,
): IAction => {
  return {
    type: CarouselActions.SELECT_ALL_CHANNELS_CAROUSEL,
    payload: { carousel },
  };
};

// export class SelectEpisodeCarousel extends SelectCarousel {
//   readonly type = CarouselActions.SELECT_EPISODE_CAROUSEL;
// }

export const selectEpisodeCarousel = (
  carousel: ICarouselDataByType,
): IAction => {
  return {
    type: CarouselActions.SELECT_EPISODE_CAROUSEL,
    payload: { carousel },
  };
};

export class SelectShowCarousel extends SelectCarousel {
  readonly type = CarouselActions.SELECT_SHOW_CAROUSEL;
}

// export class SelectRecentsCarousel extends SelectCarousel {
//   readonly type = CarouselActions.SELECT_RECENTS_CAROUSEL;
// }

export const selectRecentsCarousel = (
  carousel: ICarouselDataByType,
): IAction => {
  return {
    type: CarouselActions.SELECT_RECENTS_CAROUSEL,
    payload: { carousel },
  };
};

export class SelectShowReminderCarousel extends SelectCarousel {
  readonly type = CarouselActions.SELECT_SHOW_REMINDER_CAROUSEL;
}

// export class SelectViewAllCarousel extends SelectCarousel {
//   readonly type = CarouselActions.SELECT_VIEW_ALL_CAROUSEL;
// }

export const selectViewAllCarousel = (
  carousel: ICarouselDataByType,
): IAction => {
  return {
    type: CarouselActions.SELECT_VIEW_ALL_CAROUSEL,
    payload: { carousel },
  };
};

export class SelectFilteredShowCarousel extends SelectCarousel {
  readonly type = CarouselActions.SELECT_FILTERED_SHOW_CAROUSEL;
}

export class SelectGroupedShowCarousel extends SelectGroupedCarousel {
  readonly type = CarouselActions.SELECT_GROUPED_SHOW_CAROUSEL;
}

// export class SelectCollectionCarousel extends SelectCarousel {
//   readonly type = CarouselActions.SELECT_COLLECTION_CAROUSEL;
// }

export const selectCollectionCarousel = (
  carousel: ICarouselDataByType,
): IAction => {
  return {
    type: CarouselActions.SELECT_COLLECTION_CAROUSEL,
    payload: { carousel },
  };
};

// export class SelectEnhancedEdpCarousel extends SelectCarousel {
//   readonly type = CarouselActions.SELECT_ENHANCED_EDP_CAROUSEL;
// }

export const selectEnhancedEdpCarousel = (
  carousel: ICarouselDataByType,
): IAction => {
  return {
    type: CarouselActions.SELECT_ENHANCED_EDP_CAROUSEL,
    payload: { carousel },
  };
};

export class SelectSeededStationsCarousel extends SelectCarousel {
  readonly type = CarouselActions.SELECT_SEEDED_STATIONS_CAROUSEL;
}

// export class SelectAllPodCastCarousel extends SelectCarousel {
//   readonly type = CarouselActions.SELECT_ALL_PODCAST_CAROUSEL;
// }

export const selectAllPodCastCarousel = (
  carousel: ICarouselDataByType,
): IAction => {
  return {
    type: CarouselActions.SELECT_ALL_PODCAST_CAROUSEL,
    payload: { carousel },
  };
};

/*export type CarouselAction =
  | SelectSuperCategoryCarousel
  | SelectSubCategoryCarousel
  | SelectNowPlayingCarousel
  | SelectNowPlayingUpNextCarousel
  | SelectSearchCarousel
  | SelectProfileCarousel
  | SelectContentCarousel
  | SelectAllOnDemandCarousel
  | SelectAllVideoCarousel
  | SelectAllChannelsCarousel
  | SelectEpisodeCarousel
  | SelectShowCarousel
  | SelectRecentsCarousel
  | SelectShowReminderCarousel
  | SelectViewAllCarousel
  | SelectFilteredShowCarousel
  | SelectGroupedShowCarousel
  | SelectCollectionCarousel
  | SelectEnhancedEdpCarousel
  | SelectSeededStationsCarousel
  | SelectAllPodCastCarousel;*/
