export class IrNavClassConstants
{
    // Can skip freely without restriction.
    public static UNRESTRICTED_0 = "UNRESTRICTED_0";
    public static UNRESTRICTED_1 = "UNRESTRICTED_1";

    // Can skip, but with DMCA restrictions in force.
    public static RESTRICTED_2 = "RESTRICTED_2";
    public static RESTRICTED_3 = "RESTRICTED_3";

    // Can not skip whatsoever.
    public static DISALLOWED_4 = "DISALLOWED_4";
    public static DISALLOWED_5 = "DISALLOWED_5";

    // Used in regex matching patterns.
    public static PATTERN_UNRESTRICTED = "UNRESTRICTED";
    public static PATTERN_RESTRICTED = "RESTRICTED";
    public static PATTERN_DISALLOWED = "DISALLOWED";
}
