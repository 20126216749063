import { of as observableOf,  Observable ,  BehaviorSubject } from 'rxjs';
import { first, map, share, catchError, mergeMap } from 'rxjs/operators';
import * as _ from "lodash";

import {
    IOnDemandShowDescription,
    IOnDemandShow,
    IOnDemandEpisode
} from "./ondemand.interfaces";

import { ISubCategory, ISuperCategory } from "./categories.interfaces";

import {
    IOnDemandDiscoveryRequest,
    OutStandingOnDemandRequest
} from "./outstanding.ondemand.request";

import { IChannel } from "./channels.interfaces";
import { IAodEpisode } from "./audio.ondemand.interfaces";
import { IVodEpisode } from "./video.ondemand.interfaces";
import { ChannelLineup } from "./channel.lineup";
import { ChannelLineupDelegate } from "./channel.lineup.delegate";
import {
    IChannelLineup,
    ILiveChannels
} from "./channel.lineup.interface";

import {
    IFavoriteItem,
    IImage,
    IProviderDescriptor,
    addProvider,
    Logger,
    IMediaShow,
    IAppConfig,
    FavoriteAssetTypes,
    FavoriteUpdateItem,
    FavoriteContentType,
    FavoriteAssetType,
    FavoriteChangeTypes,
    FavoriteChangeType,
    ConfigService,
    translateRelativeUrlsForTheList
} from "../index";
import { ApiCodes } from "../service/consts/api.codes";

// necessary to import from file itself in order to prevent error
import { FavoriteService }                         from "../favorite/favorite.service";
import { IMAGE_HEIGHT, IMAGE_WIDTH, PLATFORM_ANY } from "../service/types";
import { HttpProvider }                            from "../http";
import { IHttpHeader }                             from "../http/http.provider.response.interceptor";
import { LiveTime }                                from "../livetime/live-time.interface";
import { AppMonitorService }                       from "../app-monitor";
import { ServiceEndpointConstants }                from "../service/consts";
import { FavoriteModel } from "../favorite/favorite.model";

/**
 * @MODULE:     service-lib
 * @CREATED:    07/27/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *  Channel Lineup list for creating the list of channels
 */
export class ChannelLineupService
{
    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("ChannelLineupService");

    /**
     * Ths first time we pull AOD content in, we want it to come in quickly, but we cannot overload the API, so
     * we need to space out the pulls
     */
    private static INITIAL_AOD_PULL_SPACING_MS = 200;

    /**
     * For subsequent pulls of AOD content (after we have pulled everything once, we can space out the pulls more
     * so that we do not stress out the API or the client itself with unnecessary work.
     */
    private static STEADY_STATE_AOD_PULL_SPACING_MS = 10000;

    /**
     * For subsequent pull of discover AOD content. (after we have pulled everything once, we can space out the pulls more
     * so that we do not stress out the API or the client itself with unnecessary work).
     * @type {number}
     */
    private static ON_DEMAND_CACHE_TIME_MS = (24 * 60 * 60 * 1000); // 24 hours between discover on demand pulls

    /*
     * Interval timer for handling periodic live channel update requests
     */
    private liveInterval: any = 0;

    /*
     * Track whether we have an outstanding periodic AOD discover request in flight
     */
    private aodTimeout: boolean = false;

    /*
     * List of AOD discover requests that are queued up for processing
     */
    private queuedAodRequests: Array<IOnDemandDiscoveryRequest> = [];

    /*
     * List of on demand discover requests that have been sent but no response received yet.
     */
    private outStandingOnDemandRequests: Array<OutStandingOnDemandRequest> = [];

    /*
     * The periodicity of the live channel requests in milliseconds
     */
    private liveIntervalPeriodMs: number = ChannelLineupService.DEFAULT_LIVE_CHANNEL_QUERY_PERIOD_MS;

    /*
     * The default period of the live channel requests in seconds
     */
    public static DEFAULT_LIVE_CHANNEL_QUERY_PERIOD_MS: number = 100000;

    /**
     * Reference to the configuration so other components can leverage it.
     */
    public channelLineup: ChannelLineup = new ChannelLineup();

    /**
     * If the lineup changes this will toggle to true
     */
    public channelLineupChanged : Observable<boolean>;

    private static APP_START_UP: boolean = true;

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor: IProviderDescriptor = function()
    {
        return addProvider(ChannelLineupService, ChannelLineupService,
            [   FavoriteService,
                ChannelLineupDelegate,
                HttpProvider,
                AppMonitorService,
                "IAppConfig",
                ConfigService,
                FavoriteModel]);
    }();

    /**
     * Constructor.
     * @param favoriteService - service that manages favorite channels/shows
     * @param channelLineupDelegate - The delegate that makes the get config HTTP API request.
     * @param httpProvider - used to check for channel change refresh header messages
     * @param appMonitorService - used to make sure UNAVAILABLE_CONTENT errors are ignore on out of band lineup calls
     *                            for app monitoring purposes
     * @param SERVICE_CONFIG - service configuration for the servicelib from the client
     */
    constructor(private favoriteService: FavoriteService,
                private channelLineupDelegate: ChannelLineupDelegate,
                private httpProvider : HttpProvider,
                private appMonitorService : AppMonitorService,
                private SERVICE_CONFIG: IAppConfig,
                private configService: ConfigService,
                private favoriteModel: FavoriteModel)
    {
        // Ignore UNAVAILABLE content errors for the V4 out of band channel list calls
        appMonitorService.ignoreFault(ApiCodes.UNAVAILABLE_CONTENT,
                                      ServiceEndpointConstants.endpoints.CHANNEL.V2_GET_LIST);

        const lineupChangeSubject = new BehaviorSubject(false);
        this.channelLineupChanged = lineupChangeSubject;

        const httpHeaderObs = this.httpProvider.addHttpHeaderObservable("channelchangerefreshflag");

        httpHeaderObs.subscribe((header: IHttpHeader) =>
        {
            if (header) { lineupChangeSubject.next(true); }
        });

        this.favoriteModel
            .favorites$
            .subscribe((favorites: Array<IFavoriteItem>) =>
            {
                // Only perform this work once on app start up.  Once the favorites observable fires the first time,
                // clear out any duplicate favorites where assetGUID was incorrectly set to channelId.
                if(ChannelLineupService.APP_START_UP && favorites.length > 0)
                {
                    this.clearDuplicateFavorites(favorites);
                    ChannelLineupService.APP_START_UP = false;
                }
                this.channelLineup.setFavorites(favorites);
            });
    }

    /**
     * Make an API request to get the channel lineup.  Channel lineup includes all channels, supercategories and
     * categories.  Once the API returns the lineup, then a call to getLiveChannellist will be made to get the
     * information about what is currently playing on each channel.  The response to the first call will then
     * start a timer interval to continue to make period calls to refresh the live channel information.
     *
     * @returns {Observable<boolen>}
     */
    public getChannelLineup(): Observable<boolean>
    {
        const lineup: Observable<IChannelLineup> = this.channelLineupDelegate.getChannelLineup();

        lineup.subscribe({ next: onLineupAvailable.bind(this), error: onLineupFault.bind(this) });

        //TODO - Can we clean this up, and make it more succinct.
        return lineup.pipe(mergeMap((response: any) =>
            {
                return observableOf(true);
            }),
            catchError((error) =>
            {
                if(error && error.code === ApiCodes.GUP_BYPASS)
                {
                    return observableOf(true);
                }
                return observableOf(false);
            }),
            share());

        /**
         * Once the lineup becomes available, save it to channelLineup and then go get the live channel list
         *
         * @param lineup is the channel lineup returned by the delegate.
         */
        function onLineupAvailable(lineup: IChannelLineup)
        {
            this.channelLineup.setSuperCategories(lineup.superCategories);
            this.channelLineup.setChannelLineup(lineup.channels);

            this.SERVICE_CONFIG.defaultSuperCategory = this.channelLineup.getDefaultSuperCategory();
            this.getPeriodicLineupInformation();
        }

        /**
         * Once delegate throws exception then fault handler get called.
         * @param error - returned by the delegate.
         */
        function onLineupFault(error: any)
        {
            ChannelLineupService.logger.error(`onLineupFault - Error (${JSON.stringify(error)})`);
        }
    }

    /**
     * Pull the On Demand catalog for a given channelId.
     * @param channelId is the channel to pull
     * @returns {Observable<Array<IOnDemandShow>>} list of on demand shows just for this channel
     */
    public discoverOnDemandByChannelId(channelId: string): Observable<Array<IOnDemandShow>>
    {
        if (!channelId)
        {
            ChannelLineupService.logger.error(`channelId ${channelId} is bad, cannot get on demand catalog`);
            return observableOf([]).pipe(first());
        }

        const channel         = this.findChannelById(channelId);
        const subCategoryGuid = _.get(channel, "categoryList[0].categoryGuid");
        const subCategory     = this.channelLineup.findSubcategoryByGuid(subCategoryGuid);
        const superCategory   = this.channelLineup.findSuperCategoryForSubcategory(subCategory);

        if(!subCategory || !superCategory)
        {
            return observableOf([]);
        }

        return this.discoverOnDemandByCategoryKey(superCategory.key, subCategory.key).pipe(
                   first(),
                   map((shows: Array<IOnDemandShow>): Array<IOnDemandShow> =>
                   {
                       const showsForThisChannel: Array<IOnDemandShow> = [];

                       subCategory.onDemandShows = shows;

                       shows.forEach((show: IOnDemandShow) =>
                       {
                           show.relatedChannelIds.forEach((relatedChannelId: string) =>
                           {
                               if (relatedChannelId === channelId)
                               { showsForThisChannel.push(show); }
                           });
                       });

                       return showsForThisChannel;
                   }));
    }

    /**
     * Make the API call to discover the AOD shows and episodes available to the client
     * @param superCategoryKey key for the supercategory we want a list of shows/episodes fow
     * @param subCategoryKey key for the subvcategoru we want a list of shows/episodes for
     * @returns {Observable<Array<IOnDemandShow>>}
     */
    public discoverOnDemandByCategoryKey(superCategoryKey: string,
                                         subCategoryKey: string): Observable<Array<IOnDemandShow>>
    {
        const outStandingRequest = this.outStandingOnDemandRequests
                                       .find((current) => findExisting(current, superCategoryKey, subCategoryKey));
        let request;

        if (outStandingRequest)
        { request = outStandingRequest.request; }

        if (!request)
        {
            if (this.makeOnDemandCall(superCategoryKey, subCategoryKey))
            {
                request = this.channelLineupDelegate
                              .discoverOnDemand(superCategoryKey, subCategoryKey).pipe(
                              map((shows: Array<IOnDemandShow>): Array<IOnDemandShow> =>
                              {
                                  if (_.isArray(shows))
                                  {
                                      this.channelLineup.setOnDemandShows(superCategoryKey, subCategoryKey, shows);
                                      return shows;
                                  }

                                  return [] as Array<IOnDemandShow>;
                              }),
                              catchError((error: any): Array<IOnDemandShow> =>
                              {
                                  if (error.code === ApiCodes.UNAVAILABLE_CONTENT)
                                  {
                                      this.channelLineup.setOnDemandShows(superCategoryKey, subCategoryKey, []);
                                  }

                                  ChannelLineupService.logger.error(`discoverAod - Fault Error (${JSON.stringify(error)})`);
                                  return [] as Array<IOnDemandShow>;
                              }),
                              share()); // definitely need this, there are a lot of subscribers waiting on us ....

                this.outStandingOnDemandRequests
                    .push(new OutStandingOnDemandRequest(superCategoryKey, subCategoryKey, request, onDone.bind(this)));
            }
            else
            {
                request = observableOf(this.channelLineup.getOnDemandShows(superCategoryKey, subCategoryKey)).pipe(first());
            }
        }

        return request;

        /**
         * Function to use to find if there is an outstanding request for the superCategory/subCategory on demand
         * episodes
         *
         * @param request is the request to check for a match
         * @param superCategoryKey is the key for the supercategory to find an existign request for
         * @param subCategoryKey is the key for the supercategory to find an existign request for
         * @returns {boolean} true of the request matches, false if not
         */
        function findExisting(request: OutStandingOnDemandRequest,
                              superCategoryKey: string,
                              subCategoryKey: string): boolean
        {
            return (request.superCategoryKey === superCategoryKey && request.subCategoryKey === subCategoryKey);
        }

        /**
         * This function will get called when an outstanding request completes
         * @param request is the request that has completed
         */
        function onDone(request: OutStandingOnDemandRequest)
        {
            const index = this.outStandingOnDemandRequests.findIndex((current) => findExisting(current,
                request.superCategoryKey,
                request.subCategoryKey));

            if (index >= 0)
            {
                this.outStandingOnDemandRequests.splice(index, 1);
            }
        }

    }

    /*
     * Start a timer interval that will periodically make getPeriodicLineupInformation calls to keep the information about what
     * is playing on each channel in the lineup current.
     *
     * @param frequency (optional) specifies the number of seconds in between channel requests.  If not present then the
     * value stored in the liveIntervalPeriod data member is used.
     */
    public startPeriodicLineupRequests(frequency?: number)
    {
        if (this.liveInterval === 0 || (frequency && frequency * 1000 !== this.liveIntervalPeriodMs))
        {
            this.stopPeriodicLineupRequests();

            if (frequency)
            { this.liveIntervalPeriodMs = frequency * 1000; }
            this.liveInterval = setInterval(() => this.getPeriodicLineupInformation(), this.liveIntervalPeriodMs);
        }
    }

    /*
     * Stop the timer interval that periodically refreshes the live channel list.  If the interval is not running then
     * this function does nothing.
     */
    public stopPeriodicLineupRequests()
    {
        clearInterval(this.liveInterval);
        this.liveInterval = 0;
    }

    public setChannelLivePdt(liveTime: LiveTime)
    {
        this.channelLineup.setLivePdt(liveTime);
    }

    /**
     * Attempts to locate a channel image by requested name and dimensions.
     *
     * @param {IChannel} channel
     * @param {string} requestedName
     * @param {string} requestedPlatform
     * @param {number} requestedWidth
     * @param {number} requestedHeight
     * @returns {string}
     */
    public static getChannelImage(channel: IChannel,
                                  requestedName: string,
                                  requestedPlatform : string,
                                  requestedWidth?: number,
                                  requestedHeight?: number): string
    {
        const findImage                        = (item: IImage): boolean =>
        {
            return ((item.name === requestedName
                     && (requestedPlatform === PLATFORM_ANY || item.platform === requestedPlatform))
                    && (item.height >= IMAGE_HEIGHT && item.width >= IMAGE_WIDTH));
        };

        const images: Array<IImage> = channel && channel.imageList ? channel.imageList: [] as Array<IImage>;
        const image                 = images.find(findImage);

        let url = (image) ? image.url : "";

        if (url !== "" && requestedWidth && requestedHeight)
        {
            let urlParams = `width=${requestedWidth}&height=${requestedHeight}&preserveAspect=true`;
            url += url.match(/\?/) ? "&" + urlParams : "?" + urlParams;
        }

        return url;
    }

    /**
     * Attempts to locate a show image by requested name and dimensions.
     * @param {IOnDemandShowDescription} episodeOrShow
     * @param {string} requestedName
     * @param {number} requestedWidth
     * @param {number} requestedHeight
     * @returns {string}
     */
    public static getShowOrEpisodeImage(episodeOrShow: IOnDemandShowDescription | IOnDemandShow | IOnDemandEpisode | IMediaShow,
                                        requestedName: string,
                                        requestedWidth?: number,
                                        requestedHeight?: number): string
    {
        const requestedAlternateHeight: number = 90;
        const requestedAlternateWidth: number  = 90;

        const showLogo = (item: IImage): boolean =>
        {
            return ((item.name === requestedName)
                    && (item.width === requestedWidth || item.width === requestedAlternateWidth)
                    && (item.height === requestedHeight || item.height == requestedAlternateHeight));
        };

        const images: Array<IImage> = episodeOrShow.images ? episodeOrShow.images: [] as Array<IImage>;
        const image: IImage         = images.find(showLogo);

        return image ? image.url: "";
    }

    /**
     * find channel from live channels array that matched the given channelId
     * @param {string} channelId -
     * @param {boolean} translateRelativeUrls -
     * @returns {IChannel}
     */
    public findChannelById (channelId: string, translateRelativeUrls: boolean = true): IChannel
    {
        let channel = this.channelLineup.findChannelById(channelId);
        if (translateRelativeUrls && channel && channel.imageList)
        {
            channel.imageList = translateRelativeUrlsForTheList(channel.imageList, this.configService.getRelativeUrlSettings());
        }
        return channel;
    }

    /**
     * find channel from live channels array that matched the given channelNumber
     * @param {string} channelNumber -
     * @returns {IChannel}
     */
    public findChannelByNumber (channelNumber: string): Array<IChannel>
    {
        let channels = this.channelLineup.findChannelByNumber(parseInt(channelNumber));
        channels     = channels.map(channel =>
        {
            channel.imageList = translateRelativeUrlsForTheList(channel.imageList, this.configService.getRelativeUrlSettings());
            return channel;
        });

        return channels;
    }

    /**
     * find channel from live channels array that matched the given channelName/keyword
     * @param {string} channelName -
     * @returns {Array<IChannel>}
     */
    public findChannelsByName(keyword: string): Array<IChannel>
    {
        let channels =  this.channelLineup.findChannelsByName(keyword);
        channels     = channels.map(channel =>
        {
            channel.imageList = translateRelativeUrlsForTheList(channel.imageList, this.configService.getRelativeUrlSettings());
            return channel;
        });

        return channels;
    }

    /**
     * find channel from live channels array that matched the given channel Guid / channelId
     * @param {string} channelId -
     * @returns {IChannel}
     */
    public findChannelByIdOrGuid(channelIdentifier: string): IChannel
    {
        let channel = this.channelLineup.findChannelByIdOrGuid(channelIdentifier);
        if(channel && channel.imageList)
        {
            channel.imageList = translateRelativeUrlsForTheList(channel.imageList, this.configService.getRelativeUrlSettings());
        }
        return channel;
    }

    /**
     * Get a supercategory object for the given guid
     * @param subCategoryGuid is the guid for the subcategory
     * @returns {ISubCategory}
     */
    public findSupercategoryByGuid(subCategoryGuid: string) : ISuperCategory
    {
        return this.channelLineup.findSuperCategoryBySubcategoryGuid(subCategoryGuid);
    }

    /**
     * Get a supercategory object for the given guid
     * @param subCategoryGuid is the guid for the subcategory
     * @returns {ISubCategory}
     */
    public findSubcategoryByGuid(subCategoryGuid: string) : ISubCategory
    {
        return this.channelLineup.findSubcategoryByGuid(subCategoryGuid);
    }

    /**
     * Find an AOD episode base on the channelId and episodeGuid for the episode
     *
     * @param channelId is the channel the episode belongs to
     * @param episodeGuid is the guid for the episode that we are looking for
     * @returns {Observable<IAodEpisode>} an observable that can be used to get the episode
     */
    public findAodEpisodeByGuid(channelId: string, episodeGuid: string): Observable<IAodEpisode>
    {
        return this.discoverOnDemandByChannelId(channelId).pipe(
                   first(),
                   map((shows: Array<IOnDemandShow>): IAodEpisode =>
                   {
                       let episode = null;

                       shows.find((show: IOnDemandShow): boolean =>
                       {
                           episode = show.aodEpisodes.find((currentEpisode: IAodEpisode): boolean =>
                           {
                               let found = currentEpisode.episodeGuid === episodeGuid;

                               return (found === false) ? (currentEpisode as any).episodeGuid == episodeGuid: found;
                           });

                           return (episode != undefined);
                       });

                       return episode;
                   }));
    }

    /**
     * Find an VOD episode base on the channelId and episodeGuid for the episode
     *
     * @param channelId is the channel the episode belongs to
     * @param episodeGuid is the guid for the episode that we are looking for
     * @returns {Observable<IAodEpisode>} an observable that can be used to get the episode
     */
    public findVodEpisodeByGuid(channelId: string, episodeGuid: string): Observable<IVodEpisode>
    {
        return this.discoverOnDemandByChannelId(channelId).pipe(
                   first(),
                   map((shows: Array<IOnDemandShow>): IVodEpisode =>
                   {
                       let episode = null;

                       shows.find((show: IOnDemandShow): boolean =>
                       {
                           episode = show.vodEpisodes.find((currentEpisode: IVodEpisode): boolean =>
                           {
                               return currentEpisode.episodeGuid === episodeGuid;
                           });

                           return (episode != null);
                       });

                       return episode;
                   }));
    }

    /**
     * Find an On demand show based on the channelId and show guid
     * @param {string} channelId is the channel the show belongs to
     * @param {string} showGuid is the guid for the show that we are looking for
     * @returns {Observable<IOnDemandShow>} an observable that can be used to get the show
     */
    public findShowByGuid(channelId: string, showGuid: string): Observable<IOnDemandShow>
    {
        return this.discoverOnDemandByChannelId(channelId).pipe(
                   first(),
                   map((shows: Array<IOnDemandShow>) => shows.find((show: IOnDemandShow) => show.guid === showGuid)));
    }

    /**
     * Make an API call to get information about what is currently playing on each channel in the lineup, and also
     * start pulling AOD catalog information about all subcategories in the lineup
     *
     * @returns {Observable<ILiveChannels>}
     */
    private getPeriodicLineupInformation(): void
    {
        this.channelLineupDelegate
            .discoverChannelList()
            .subscribe((liveChannels: ILiveChannels) =>
            {
                this.channelLineup.setLiveChannels(liveChannels.channels);
                this.startPeriodicLineupRequests(liveChannels.updateFrequency);
            });
    }

    /**
     * Iterate through the subcategories for the given super category and get and populate the AOD shows into
     * the subcategories.
     * @param superCategoryKey is the key for the supercategory to populate with shows/episodes
     * @param onlyForThisSubCategoryKey is an optional key to use to only get the shows for that particular subcategory
     */
    private getShowsForSuperCategory(superCategoryKey: string, onlyForThisSubCategoryKey?: string)
    {
        this.channelLineup
            .getSubcategoryKeys(superCategoryKey)
            .forEach((subCategoryKey: string) =>
            {
                if (!onlyForThisSubCategoryKey || subCategoryKey === onlyForThisSubCategoryKey)
                {
                    this.queueAodCall(superCategoryKey, subCategoryKey);
                }
            });
    }

    /**
     * Queue up a request to Get AOD content for the given sub category within the given super category.  If there
     * are no active pulls going on, schedule one for the future
     * @param superCategoryKey is the key for the supercategory we want to get AOD shows for
     * @param subCategoryKey is the ket for the subcategory we want to get AOD shows for
     */
    private queueAodCall(superCategoryKey: string, subCategoryKey: string)
    {
        this.queuedAodRequests.push({ superCategoryKey: superCategoryKey, subCategoryKey: subCategoryKey });

        if (this.aodTimeout === false)
        {
            // If we have not yet started the periodic lineup pulls, schedule the aod calls at a faster pace, otherwise
            // we can use the slower pace because we have already pulled the complete aod roster at least once
            let aodTimeout = (this.liveInterval === 0)
                ? ChannelLineupService.INITIAL_AOD_PULL_SPACING_MS
                : ChannelLineupService.STEADY_STATE_AOD_PULL_SPACING_MS;

            this.scheduleAodCall(aodTimeout);
        }
    }

    /**
     * If there is a aod request in the queue, set a timeout for timeout ms in the future then make the aod call.
     * @param timeout is the number of ms in the future to schedule the call for
     */
    private scheduleAodCall(timeout: number)
    {
        let next = this.queuedAodRequests.shift();
        if (next)
        {
            this.aodTimeout = true;

            setTimeout(() =>
            {
                // On success, schedule the next AOD call.  On failure, re-queue up the aod call.
                this.discoverOnDemandByCategoryKey(next.superCategoryKey, next.subCategoryKey)
                    .subscribe(() =>
                        {
                            this.scheduleAodCall(timeout);
                        },
                        () =>
                        {
                            this.aodTimeout = false;
                            this.queueAodCall(next.superCategoryKey, next.subCategoryKey);
                        });
            }, timeout);
        }
        else
        {
            this.aodTimeout = false;
        }
    }

    /**
     * Used to find is OD call is needed or not based on given super, sub category keys.
     * @param {string} superCategoryKey - requested to make OD call
     * @param {string} subCategoryKey - requested to make OD call
     * @returns {boolean} - returns boolean value as true in order to make a OD call other wise returns false
     */
    private makeOnDemandCall(superCategoryKey: string, subCategoryKey: string): boolean
    {
        const lastPullDate = this.channelLineup.getOnDemandUpdateTime(superCategoryKey, subCategoryKey);

        return !lastPullDate || (Date.now() - lastPullDate > ChannelLineupService.ON_DEMAND_CACHE_TIME_MS);
    }

    /**
     * Used to clear out invalid favorites from the user's GUP.  This function will look for favorites where the
     * channelId is the same as the assetGUID.  For these entries, the favorite is deleted and re-added using the
     * proper assetGUID for the favorite.
     *
     * This needs to be done in the channel lineup service because the assetGUID for the channel is required to
     * recreate the favorite.  We cannot create a dependency on the channel lineup service in the favorites
     * service because of circular dependency issues.
     *
     * @param {IFavoriteItem[]} favorites is the users list of favorites as returned from the API
     */
    public clearDuplicateFavorites(favorites: IFavoriteItem[]) : void
    {
        if(favorites.length === 0) { return; }

        const duplicateList =
                  favorites.filter(fav =>
                  {
                      return fav.assetGUID === fav.channelId &&
                          (fav.contentType ===  FavoriteAssetTypes.CHANNEL || fav.contentType === FavoriteAssetTypes.LIVE);
                  });


        const removedList = duplicateList.map((duplicateFavorite) =>
        {
            ChannelLineupService.logger.warn(`duplicate item added to remove list ChannelId ${(duplicateFavorite.channelId)} and
                                                                        ChannelGuid ${(duplicateFavorite.assetGUID)}`);
            return new FavoriteUpdateItem(duplicateFavorite.channelId,
                duplicateFavorite.contentType as FavoriteContentType,
                duplicateFavorite.assetType as FavoriteAssetType,
                FavoriteChangeTypes.DELETE as FavoriteChangeType,
                duplicateFavorite.assetGUID,
                duplicateFavorite.tabSortOrder);
        });

        let addList = duplicateList.map((duplicateFavorite) =>
        {
            const channel = this.findChannelById(duplicateFavorite.channelId);
            const favoriteCorrectDublicateItem = favorites.find((favorite) =>
                                                                            favorite.channelId === duplicateFavorite.channelId &&
                                                                            favorite.contentType === duplicateFavorite.contentType &&
                                                                            favorite.assetType === duplicateFavorite.assetType &&
                                                                            favorite.assetGUID !== favorite.channelId);
            if (channel &&
                channel.channelGuid &&
                !favoriteCorrectDublicateItem)
            {
                ChannelLineupService.logger.warn(`duplicate item added to add list ChannelId ${(duplicateFavorite.channelId)} and
                                                                        ChannelGuid ${(channel.channelGuid)}`);
                return new FavoriteUpdateItem(duplicateFavorite.channelId,
                    duplicateFavorite.contentType as FavoriteContentType,
                    duplicateFavorite.assetType as FavoriteAssetType,
                    FavoriteChangeTypes.ADD as FavoriteChangeType,
                    channel.channelGuid,
                    duplicateFavorite.tabSortOrder);
            }
            else
            {
                ChannelLineupService.logger.warn(`duplicate item not added to add list ChannelId ${(duplicateFavorite.channelId)}`);
            }
        }).filter((favItem) => !!favItem);

        if(removedList.length > 0 )
        {
            this.favoriteService.updateFavorites(removedList, false).pipe(
                mergeMap((result: boolean) =>
                {
                    if (addList.length > 0)
                    {
                        return this.favoriteService.updateFavorites(addList, true);
                    }
                }))
                .subscribe();
        }
    }
}
