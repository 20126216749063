import { Subject } from 'rxjs';
import {
  IProviderDescriptor,
  addProvider,
  ApiCodes,
  authLoginCodes,
  AppErrorCodes,
  apiToFault,
} from '../service';
import { AuthenticationEvent } from '../authentication/authentication.event';
import { Events } from '../event';
import { IAppConfig } from '../config';
import {
  IAppMessage,
  APP_ERROR_STATE_CONST,
  IAppErrorState,
  SimultaneousListenStatus,
} from '../app-monitor/app-monitor.interface';
import { Logger } from '../logger';
import { ApiDelegate } from '../http/api.delegate';

export class SessionMonitorService {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('SessionMonitorService');

  /**
   * subject for delivering session data through the sessionMessage observable.
   */
  public sessionMessageSubject$: Subject<IAppMessage> = new Subject();

  /**
   * subject for delivering API error codes
   */
  public apiCodesSubject$: Subject<Array<number>> = new Subject();

  /**
   * NOTE: we may want to move this to new bypass-monitor service
   */
  public appCodeSubject$: Subject<number> = new Subject();

  private appErrorStateData: IAppErrorState = {
    IS_SIMULTANEOUS_LISTEN: APP_ERROR_STATE_CONST.SIMULTANEOUS_LISTEN_DISABLED,
    IS_IT_DOWN: false,
  };

  private appErrorStateSubject = new Subject<IAppErrorState>();
  public appErrorState = this.appErrorStateSubject.asObservable();

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(SessionMonitorService, SessionMonitorService, [
      ApiDelegate,
      'IAppConfig',
    ]);
  })();

  /**
   * Constructor
   * @param apiDelegate allows the auth service to register callback for when certain API codes are encountered
   * @param SERVICE_CONFIG injected by the client to configure the service layer.
   */
  constructor(
    public apiDelegate: ApiDelegate,
    public SERVICE_CONFIG: IAppConfig,
  ) {
    this.addApiCodeEventBroadcast(ApiCodes.IT_DOWN);
    this.addApiCodeEventBroadcast(ApiCodes.INVALID_COOKIE);
    this.addApiCodeEventBroadcast(ApiCodes.SIMULTANEOUS_LISTEN);

    this.apiCodeHandler();
  }

  /**
   * handle api Codes for authLoginCodes
   */
  private apiCodeHandler() {
    authLoginCodes.forEach(code => {
      const triggeringCode = code;
      this.apiDelegate.addApiCodeHandler(code, (codes, messages): void => {
        this.apiCodesSubject$.next(codes);

        messages
          .filter(({ code }) => triggeringCode === code)
          .forEach(({ message, code }) => {
            const currentCode = apiToFault.get(code);

            const sessionMessage: IAppMessage = {
              type: Events.ERROR_MESSAGING,
              apiCode: code,
              code: currentCode.faultCode,
              message: message,
            };
            switch (code) {
              case ApiCodes.SIMULTANEOUS_LISTEN:
              case ApiCodes.SIMULTANEOUS_LISTEN_SAME_DEVICE:
                if (
                  this.updateSimultaneousListen(
                    APP_ERROR_STATE_CONST.SIMULTANEOUS_LISTEN_ENABLED,
                  )
                ) {
                  this.appCodeSubject$.next(code);
                  this.sessionMessageSubject$.next(sessionMessage);
                }
                break;
              case ApiCodes.IT_DOWN:
                if (this.updateITDown(true)) {
                  this.appCodeSubject$.next(code);
                  this.sessionMessageSubject$.next(sessionMessage);
                }
                break;
              default:
                this.appCodeSubject$.next(code);
                this.sessionMessageSubject$.next(sessionMessage);
                break;
            }
          });
      });
    });
  }

  public updateITDown(itDown: boolean): boolean {
    const previousState = this.appErrorStateData.IS_IT_DOWN;
    this.appErrorStateData.IS_IT_DOWN = itDown;

    if (!previousState && itDown) {
      this.appErrorStateSubject.next(this.appErrorStateData);
      return true;
    }
    return false;
  }

  public updateSimultaneousListen(
    simultaneousListen: SimultaneousListenStatus,
  ): boolean {
    const previousState = this.appErrorStateData.IS_SIMULTANEOUS_LISTEN;
    this.appErrorStateData.IS_SIMULTANEOUS_LISTEN = simultaneousListen;

    if (
      previousState !== APP_ERROR_STATE_CONST.SIMULTANEOUS_LISTEN_ENABLED &&
      simultaneousListen === APP_ERROR_STATE_CONST.SIMULTANEOUS_LISTEN_ENABLED
    ) {
      this.appErrorStateSubject.next(this.appErrorStateData);
      return true;
    }
    return false;
  }

  /**
   * Broadacasts code and event to event bus in api delegate
   * @param code
   */
  public addApiCodeEventBroadcast(code: number): void {
    let event = '';

    switch (code) {
      case ApiCodes.IT_DOWN:
        event = AuthenticationEvent.IT_DOWN.toString();
        break;

      case ApiCodes.INVALID_COOKIE:
        event = AuthenticationEvent.NEVER_AUTHENTICATED.toString();
        break;

      case ApiCodes.SIMULTANEOUS_LISTEN:
        event = AuthenticationEvent.DUPLICATE_LOGIN.toString();
        break;

      //what is this
      // case AppErrorCodes.FLTT_LOGOUT:
      //     event = "User has logged out";
      //     break;
    }

    this.apiDelegate.addApiCodeEventBroadcast(code, event);
  }

  /**
   * Broadcasts user logout event
   */
  public logout(): void {
    //what?
    this.addApiCodeEventBroadcast(AppErrorCodes.FLTT_LOGOUT.faultCode);
  }
}
