import * as _ from 'lodash';
import { IAppStore } from '../../redux/selector/app.store';
import {
  ICarouselStore,
  selectSuperCategoryCarousel,
  selectSubCategoryCarousel,
  selectNowPlayingCarousel,
  selectProfileCarousel,
  selectSearchCarousel,
  selectAllOnDemandCarousel,
  selectAllVideoCarousel,
  selectEpisodeCarousel,
  selectRecentsCarousel,
  selectNowPlayingUpNextCarousel,
  selectShowReminderCarousel,
  selectViewAllCarousel,
  selectShowCarousel,
  selectFilterdShowsCarousel,
  selectCollectionCarousel,
  selectAllChannelsCarousel,
  selectEnhancedEdpCarousel,
  selectSeededStationsCarousel,
  selectSearchLandingCarousel,
  selectAllPodCastCarousel,
} from '../../redux/selector/carousel.store';

import {
  ICarouselDataByType,
  ISubCategory,
  ISuperCategory,
  CarouselService,
  CarouselPageParameter,
  CarouselTypeConst,
  IDmcaInfo,
  IGroupedCarousel,
  ContentTypes,
  getSegmentGroupedCarousel,
  ICurrentlyPlayingMedia,
  IProviderDescriptor,
  addProvider,
} from '../../servicelib';

import { Observable, Subscription } from 'rxjs';

import {
  //SelectSuperCategoryCarousel,
  //SelectSubCategoryCarousel,
  selectSubCategoryCarousel as selectSubCategoryCarouselAction,
  //SelectNowPlayingCarousel,
  selectNowPlayingCarousel as selectNowPlayingCarouselAction,
  SelectProfileCarousel,
  selectSearchCarousel as selectSearchCarouselAction,
  SelectContentCarousel,
  SelectAllOnDemandCarousel,
  //SelectAllVideoCarousel,
  selectAllVideoCarousel as selectAllVideoCarouselAction,
  //SelectEpisodeCarousel,
  selectEpisodeCarousel as selectEpisodeCarouselAction,
  selectRecentsCarousel as selectRecentsCarouselAction,
  //SelectNowPlayingUpNextCarousel,
  selectNowPlayingUpNextCarousel as selectNowPlayingUpNextCarouselAction,
  SelectShowReminderCarousel,
  //SelectViewAllCarousel,
  selectViewAllCarousel as selectViewAllCarouselAction,
  SelectShowCarousel,
  SelectGroupedShowCarousel,
  //SelectCollectionCarousel,
  selectCollectionCarousel as selectCollectionCarouselAction,
  //SelectAllChannelsCarousel,
  selectAllChannelsCarousel as selectAllChannelsCarouselAction,
  //SelectEnhancedEdpCarousel,
  selectEnhancedEdpCarousel as selectEnhancedEdpCarouselAction,
  SelectSeededStationsCarousel,
  selectSearchLandingCarousel as selectSearchLandingCarouselAction,
  //SelectAllPodCastCarousel,
  selectAllPodCastCarousel as selectAllPodCastCarouselAction,
} from '../../redux/action/carousel.action';
import store from '../../redux/store';

interface SelectedCarousel {
  name: string;
  subscription: Subscription;
}

export class CarouselStoreService {
  /**
   * Subscription management.  As carousels change over time, they need to be updated.  If we change the current
   * carousel for a given type, we need to cancel the old subscription and replace it with a new subscription
   */
  private selectedSuperCategory: SelectedCarousel = {
    name: '',
    subscription: null,
  };
  private selectedSubCategory: SelectedCarousel = {
    name: '',
    subscription: null,
  };
  private selectedNowPlaying: SelectedCarousel = {
    name: '',
    subscription: null,
  };
  private selectedNowPlayingUpNext: SelectedCarousel = {
    name: '',
    subscription: null,
  };
  private selectedProfile: SelectedCarousel = { name: '', subscription: null };
  private selectedSearch: SelectedCarousel = { name: '', subscription: null };
  private selectedSearchLanding: SelectedCarousel = {
    name: '',
    subscription: null,
  };
  private selectedAllVideo: SelectedCarousel = { name: '', subscription: null };
  private selectedAllOnDemand: SelectedCarousel = {
    name: '',
    subscription: null,
  };
  private selectedAllChannels: SelectedCarousel = {
    name: '',
    subscription: null,
  };
  private selectedEpisode: SelectedCarousel = { name: '', subscription: null };
  private selectedShow: SelectedCarousel = { name: '', subscription: null };
  private selectedRecents: SelectedCarousel = { name: '', subscription: null };
  private selectedShowReminder: SelectedCarousel = {
    name: '',
    subscription: null,
  };
  private selectedViewAll: SelectedCarousel = { name: '', subscription: null };
  private selectedCollection: SelectedCarousel = {
    name: '',
    subscription: null,
  };
  private selectedEnhancedEdpCarousel: SelectedCarousel = {
    name: '',
    subscription: null,
  };
  private selectedSeededStations: SelectedCarousel = {
    name: '',
    subscription: null,
  };
  private selectedAllPodCast: SelectedCarousel = {
    name: '',
    subscription: null,
  };

  /**
   * Carousel data wrapped in observables from the NGRX carousel store. The Observable
   * wrapper will need to be stripped for use in the UI via the async pipe; e.g., "channels | async".
   */
  public carouselStore: Observable<ICarouselStore>;

  public ERROR_INVALID_CAROUSEL_TYPE: string =
    'Invalid carousel type requested : ';

  /**
   * Observables that can be subscribe to to get the different types of carousels currently available in the store
   */
  public superCategoryCarousels: Observable<ICarouselDataByType>; //= this.store.select(selectSuperCategoryCarousel);
  public subCategoryCarousels: Observable<ICarouselDataByType>; //= this.store.select(selectSubCategoryCarousel);
  public nowPlayingCarousels: Observable<ICarouselDataByType>; //= this.store.select(selectNowPlayingCarousel);
  public nowPlayingUpNextCarousels: Observable<ICarouselDataByType>; //= this.store.select(selectNowPlayingUpNextCarousel);
  public profileCarousels: Observable<
    ICarouselDataByType
  >; /*= this.store.select(
    selectProfileCarousel,
  );*/
  public searchCarousels: Observable<
    ICarouselDataByType
  >; /*= this.store.select(
    selectSearchCarousel,
  );*/
  public searchLandingCarousels: Observable<ICarouselDataByType>; //= this.store.select(selectSearchLandingCarousel);
  public allOnDemandCarousels: Observable<ICarouselDataByType>; //= this.store.select(selectAllOnDemandCarousel);
  public allVideoCarousels: Observable<
    ICarouselDataByType
  >; /*= this.store.select(
    selectAllVideoCarousel,
  );*/
  public allChannelsCarousels: Observable<ICarouselDataByType>; //= this.store.select(selectAllChannelsCarousel);
  public episodesCarousels: Observable<
    ICarouselDataByType
  >; /*= this.store.select(
    selectEpisodeCarousel,
  );*/
  public showsCarousels: Observable<
    ICarouselDataByType
  >; /*= this.store.select(
    selectShowCarousel,
  );*/
  public recentsCarousels: Observable<
    ICarouselDataByType
  >; /*= this.store.select(
    selectRecentsCarousel,
  );*/
  public showReminderCarousels: Observable<ICarouselDataByType>; //= this.store.select(selectShowReminderCarousel);
  public viewAllCarousels: Observable<
    ICarouselDataByType
  >; /*= this.store.select(
    selectViewAllCarousel,
  );*/
  public filteredShowCarousels: Observable<Array<IGroupedCarousel>>; //= this.store.select(selectFilterdShowsCarousel);
  public collectionCarousels: Observable<ICarouselDataByType>; //= this.store.select(selectCollectionCarousel);
  public enhancedEdpCarousels: Observable<ICarouselDataByType>; //= this.store.select(selectEnhancedEdpCarousel);
  public seededStationsCarousels: Observable<ICarouselDataByType>; //= this.store.select(selectSeededStationsCarousel);
  public allPodCastCarousels: Observable<ICarouselDataByType>; //= this.store.select(selectAllPodCastCarousel);

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(CarouselStoreService, CarouselStoreService, [
      //private store: Store<IAppStore>,
      CarouselService,
    ]);
  })();

  /**
   *
   * @param store is the store for the entire application
   * @param carouselService is the service layer carousel retrieval service
   */
  constructor(
    //private store: Store<IAppStore>,
    private carouselService: CarouselService,
  ) {
    // Grab a slice of the store so views can subscribe to the data and populate our UI.
    // this.nowPlayingStore = this.store.select("nowPlayingStore");
    /*this.carouselStore = this.store.select(
      (state: IAppStore) => state.carouselStore,
    );*/
    /**
         * NOTE: If we want to prefetch carousels, the following code should be uncommented
         *
         * If we do this, we will hit the API with multiple carousel calls.  If the API can handle it
         * the user experience will be better, but if the API cannot handle this then we need to make sure
         * we do not pre-cache.

         initializationService.initState
                             .skipWhile((state: string) => state !== InitializationStatusCodes.RUNNING)
                             .pipe(take(1))
                             .subscribe(()=>
                             {
                                 this.selectRecents();
                                 this.selectShowReminders();
                             });
         */
  }

  /**
   * Make sure that the carousels for the given supercategory are selected in the store
   *
   * @param superCategory is the supercategory for which carousels should be selected
   */
  public selectSuperCategory(
    superCategory: ISuperCategory,
  ): Observable<ICarouselDataByType> {
    if (this.selectedSuperCategory.subscription) {
      this.selectedSuperCategory.subscription.unsubscribe();
    }

    this.selectedSuperCategory.name = superCategory.key;

    this.selectedSuperCategory.subscription = (this.carouselService
      .getCarouselsBySuperCategory(superCategory)
      .subscribe((carousels: ICarouselDataByType) => {
        //this.store.dispatch(new SelectSuperCategoryCarousel(carousels));
      }) as any) as Subscription;

    return this.superCategoryCarousels;
  }

  /**
   * Make sure that the carousels for the given subcategory are selected in the store
   *
   * @param subCategory is the subcategory for which carousels should be selected
   */
  public selectSubCategory(
    subCategory: ISubCategory,
    channelId?: string,
  ): Observable<ICarouselDataByType> {
    if (this.selectedSubCategory.subscription) {
      this.selectedSubCategory.subscription.unsubscribe();
    }

    if (this.selectedSubCategory.name !== subCategory.key) {
      //this.store.dispatch(new SelectSubCategoryCarousel(null));
      store.dispatch(selectSubCategoryCarouselAction(null));
    }

    this.selectedSubCategory.name = subCategory.key;

    this.selectedSubCategory.subscription = (this.carouselService
      .getCarouselsBySubCategory(subCategory)
      .subscribe((carousels: ICarouselDataByType) => {
        //this.store.dispatch(new SelectSubCategoryCarousel(carousels));
        store.dispatch(selectSubCategoryCarouselAction(carousels));
        //this.selectChannelForShowCarousel(carousels, channelId);
      }) as any) as Subscription;

    return this.subCategoryCarousels;
  }

  public selectSubCategoryByUrl(
    pageUrl: string,
  ): Observable<ICarouselDataByType> {
    if (this.selectedSubCategory.subscription) {
      this.selectedSubCategory.subscription.unsubscribe();
    }

    let carouselType: string = '';
    let regexGroupInfo = pageUrl.match(/page-name=(.*)$/);
    if (pageUrl && regexGroupInfo && regexGroupInfo[1]) {
      carouselType = regexGroupInfo[1];
    }
    if (this.selectedSubCategory.name !== carouselType) {
      //this.store.dispatch(new SelectSubCategoryCarousel(null));
      store.dispatch(selectSubCategoryCarouselAction(null));
    }

    this.selectedSubCategory.name = carouselType;

    this.selectedSubCategory.subscription = (this.carouselService
      .getCarouselsByPageUrl(carouselType, pageUrl)
      .subscribe((carousels: ICarouselDataByType) => {
        //this.store.dispatch(new SelectSubCategoryCarousel(carousels));
        store.dispatch(selectSubCategoryCarouselAction(carousels));
        this.selectChannelForShowCarousel(carousels);
      }) as any) as Subscription;

    return this.subCategoryCarousels;
  }

  /**
   * Make sure that the carousels for media specified is selected in the store
   *
   * @param data is currently playing media.
   * @param params is an optional list of parameters to further refine the now playing carousels
   */
  public selectNowPlaying(
    data: ICurrentlyPlayingMedia,
    params?: Array<CarouselPageParameter>,
  ): Observable<ICarouselDataByType> {
    const mediaType = data.mediaType;

    if (this.selectedNowPlaying.subscription) {
      this.selectedNowPlaying.subscription.unsubscribe();
    }
    //this.store.dispatch(new SelectNowPlayingCarousel(null));
    store.dispatch(selectNowPlayingCarouselAction(null));

    this.selectedNowPlaying.name =
      mediaType + params[0] ? params[0].paramValue : '';

    this.selectedNowPlaying.subscription = (this.carouselService
      .getNowPlayingCarousel(data, params)
      .subscribe((carousels: ICarouselDataByType) => {
        //this.store.dispatch(new SelectNowPlayingCarousel(carousels));
        store.dispatch(selectNowPlayingCarouselAction(carousels));
      }) as any) as Subscription;

    return this.nowPlayingCarousels;
  }

  /**
   * Make sure that the carousels for nowPlayingUpNext Carousels is selected in the store
   * @param params is the Array of CarouselPageParameter -- showGuid,cutGuid,episodeGuid,channelGuid
   */
  public selectNowPlayingUpNext(
    channelGuid: string,
    showGuid: string,
    episodeGuid: string,
    cutGuid: string,
    contentType: string,
  ): Observable<ICarouselDataByType> {
    if (this.selectedNowPlayingUpNext.subscription) {
      this.selectedNowPlayingUpNext.subscription.unsubscribe();
    }

    const params: Array<CarouselPageParameter> = [];

    params.push({ paramName: 'channelGuid', paramValue: channelGuid });
    params.push({ paramName: 'showGuid', paramValue: showGuid });
    params.push({ paramName: 'cut-guid', paramValue: cutGuid });
    params.push({ paramName: 'episodeGuid', paramValue: episodeGuid });
    params.push({ paramName: 'entities-only', paramValue: 'false' });

    this.selectedNowPlayingUpNext.name =
      contentType === ContentTypes.VOD
        ? CarouselTypeConst.NOW_PLAYING_VOD_UP_NEXT
        : CarouselTypeConst.NOW_PLAYING_AOD_UP_NEXT;

    this.selectedNowPlayingUpNext.subscription = (this.carouselService
      .getCarouselsByPage(this.selectedNowPlayingUpNext.name, params)
      .subscribe((carousels: ICarouselDataByType) =>
        //this.store.dispatch(new SelectNowPlayingUpNextCarousel(carousels)),
        store.dispatch(selectNowPlayingUpNextCarouselAction(carousels)),
      ) as any) as Subscription;

    return this.nowPlayingUpNextCarousels;
  }

  /**
   *  Clean the upNext carousel when the channel/episode/media changes
   */
  public cleanNowPlayingUpNext(): void {
    if (this.selectedNowPlayingUpNext.subscription) {
      this.selectedNowPlayingUpNext.subscription.unsubscribe();
    }
    //this.store.dispatch(new SelectNowPlayingUpNextCarousel(null));
  }

  /**
   * Make sure that the carousels for show Reminder Carousels is selected in the store
   */
  public selectShowReminders(): Observable<ICarouselDataByType> {
    if (this.selectedShowReminder.subscription) {
      this.selectedShowReminder.subscription.unsubscribe();
    }

    this.selectedShowReminder.name = CarouselTypeConst.SHOW_REMINDERS;

    this.selectedShowReminder.subscription = (this.carouselService
      .getCarouselsByPage(CarouselTypeConst.SHOW_REMINDERS)
      .subscribe(
        (carousels: ICarouselDataByType) => ({}),
        //this.store.dispatch(new SelectShowReminderCarousel(carousels)),
      ) as any) as Subscription;

    return this.showReminderCarousels;
  }

  /**
   * Make sure that the user profile carousels are selected on the store
   */
  public selectProfile(): Observable<ICarouselDataByType> {
    if (this.selectedProfile.subscription) {
      this.selectedProfile.subscription.unsubscribe();
    }

    this.selectedProfile.name = CarouselTypeConst.PROFILE;

    this.selectedProfile.subscription = (this.carouselService
      .getCarouselsByPage(CarouselTypeConst.PROFILE)
      .subscribe(
        (carousels: ICarouselDataByType) => ({}),
        //this.store.dispatch(new SelectProfileCarousel(carousels)),
      ) as any) as Subscription;

    return this.profileCarousels;
  }

  /**
   * Make sure that the all on demand carousels are selected on the store
   */
  public selectAllOnDemandCarousels(): Observable<ICarouselDataByType> {
    if (this.selectedAllOnDemand.subscription) {
      this.selectedAllOnDemand.subscription.unsubscribe();
    }

    this.selectedAllOnDemand.name = CarouselTypeConst.ONDEMAND_ALL;

    this.selectedAllOnDemand.subscription = (this.carouselService
      .getAllOnDemandCarousel()
      .subscribe((carousels: ICarouselDataByType) => {
        //this.store.dispatch(new SelectAllOnDemandCarousel(carousels));
      }) as any) as Subscription;

    return this.allOnDemandCarousels;
  }

  /**
   * Make sure that the all Video carousels are selected on the store
   */
  public selectAllVideoCarousels(): Observable<ICarouselDataByType> {
    if (this.selectedAllVideo.subscription) {
      this.selectedAllVideo.subscription.unsubscribe();
    }

    this.selectedAllVideo.name = CarouselTypeConst.VIDEO_ALL;

    this.selectedAllVideo.subscription = (this.carouselService
      .getAllVideoCarousel()
      .subscribe((carousels: ICarouselDataByType) => {
        //this.store.dispatch(new SelectAllVideoCarousel(carousels));
        store.dispatch(selectAllVideoCarouselAction(carousels));
      }) as any) as Subscription;

    return this.allVideoCarousels;
  }

  /**
   * Make sure that the all podcast carousels are selected on the store
   */
  public selectAllPodCastsCarousels(): Observable<ICarouselDataByType> {
    if (this.selectedAllPodCast.subscription) {
      this.selectedAllPodCast.subscription.unsubscribe();
    }

    this.selectedAllPodCast.name = CarouselTypeConst.PODCAST_ALL;

    this.selectedAllPodCast.subscription = (this.carouselService
      .getCarouselsByPage(CarouselTypeConst.PODCAST_ALL)
      .subscribe((carousels: ICarouselDataByType) => {
        //this.store.dispatch(new SelectAllPodCastCarousel(carousels));
        store.dispatch(selectAllPodCastCarouselAction(carousels));
      }) as any) as Subscription;

    return this.allPodCastCarousels;
  }

  /**
   * Make sure that the all channels carousels are selected on the store
   */
  public selectAllChannelsCarousels(): Observable<ICarouselDataByType> {
    if (this.selectedAllChannels.subscription) {
      this.selectedAllChannels.subscription.unsubscribe();
    }

    this.selectedAllChannels.name = CarouselTypeConst.CHANNELS_ALL;

    this.selectedAllChannels.subscription = (this.carouselService
      .getCarouselsByPage(CarouselTypeConst.CHANNELS_ALL)
      .subscribe((carousels: ICarouselDataByType) => {
        //this.store.dispatch(new SelectAllChannelsCarousel(carousels));
        store.dispatch(selectAllChannelsCarouselAction(carousels));
      }) as any) as Subscription;

    return this.allChannelsCarousels;
  }

  /**
   * Make sure that search landing carousels  are selected on the store
   */
  public selectSearchLanding(): Observable<ICarouselDataByType> {
    if (this.selectedSearchLanding.subscription) {
      this.selectedSearchLanding.subscription.unsubscribe();
    }

    this.selectedSearchLanding.name = CarouselTypeConst.SEARCH_LANDING;

    this.selectedSearchLanding.subscription = (this.carouselService
      .getCarouselsByPage(CarouselTypeConst.SEARCH_LANDING)
      .subscribe((carousels: ICarouselDataByType) => {
        store.dispatch(selectSearchLandingCarouselAction(carousels));
      }) as any) as Subscription;

    return this.searchLandingCarousels;
  }

  /**
   * Make sure that search carousels with the given parameters are selected on the store
   *
   * @param params defines the search for which we need carousels
   */
  public selectSearch(
    params: Array<CarouselPageParameter>,
  ): Observable<ICarouselDataByType> {
    if (this.selectedSearch.subscription) {
      this.selectedSearch.subscription.unsubscribe();
    }

    store.dispatch(selectSearchCarouselAction(null));
    this.selectedSearch.name = CarouselTypeConst.SEARCH;

    this.selectedSearch.subscription = (this.carouselService
      .getCarouselsByPage(CarouselTypeConst.SEARCH, params)
      .subscribe((carousels: ICarouselDataByType) => {
        store.dispatch(selectSearchCarouselAction(carousels));
      }) as any) as Subscription;

    return this.searchCarousels;
  }

  /**
   * Dispatches the selected content carousel to the Redux store
   * @param carouselData
   */
  public selectContentCarousel(carouselData: ICarouselDataByType): void {
    //this.store.dispatch(new SelectContentCarousel(carouselData));
  }

  /**
   * Make sure that the episode carousels for the given Show guid are selected in the store
   *
   * @param showGuid is the show for which carousels should be selected
   */
  public selectEpisode(pageUrl: string): Observable<ICarouselDataByType> {
    if (this.selectedEpisode.subscription) {
      this.selectedEpisode.subscription.unsubscribe();
    }

    let carouselType: string = '';
    let regexGroupInfo = pageUrl.match(/page-name=(.*)&/);
    if (pageUrl && regexGroupInfo && regexGroupInfo[1]) {
      carouselType = regexGroupInfo[1];
    }

    this.selectedEpisode.name = carouselType;

    this.selectedEpisode.subscription = (this.carouselService
      .getCarouselsByPageUrl(carouselType, pageUrl)
      .subscribe((carousels: ICarouselDataByType) => {
        //this.store.dispatch(new SelectEpisodeCarousel(carousels));
        store.dispatch(selectEpisodeCarouselAction(carousels));
      }) as any) as Subscription;

    return this.episodesCarousels;
  }

  /**
   * Make sure that the show carousels for the given Show guid are selected in the store
   *
   * @param channelGuid is the channel for which carousels should be selected
   */
  public selectShow(channelGuid: string): Observable<ICarouselDataByType> {
    if (this.selectedShow.subscription) {
      this.selectedShow.subscription.unsubscribe();
    }

    if (this.selectedShow.name !== channelGuid) {
      //this.store.dispatch(new SelectEpisodeCarousel(null));
    }

    this.selectedShow.name = channelGuid;

    this.selectedShow.subscription = (this.carouselService
      .getShowCarouselByChannel(channelGuid)
      .subscribe((carousels: ICarouselDataByType) => {
        //this.store.dispatch(new SelectShowCarousel(carousels));
      }) as any) as Subscription;

    return this.showsCarousels;
  }

  /**
   * Dispatches the recents carousel to the Redux store
   * @param carouselData
   */
  public selectRecents(): Observable<ICarouselDataByType> {
    if (this.selectedRecents.subscription) {
      this.selectedRecents.subscription.unsubscribe();
    }

    store.dispatch(selectRecentsCarouselAction(null));

    this.selectedRecents.name = CarouselTypeConst.RECENTS;

    this.selectedRecents.subscription = (this.carouselService
      .getCarouselsByPage(CarouselTypeConst.RECENTS)
      .subscribe((carousels: ICarouselDataByType) => {
        store.dispatch(selectRecentsCarouselAction(carousels));
      }) as any) as Subscription;

    return this.recentsCarousels;
  }

  /**
   * Dispatches the view all carousel to the Redux store
   * @param {string} carouselGuid - Carousel Guid comes from carousel response.
   * @returns {Observable<ICarouselDataByType>}
   */
  public selectViewAll(carouselGuid: string): Observable<ICarouselDataByType> {
    if (this.selectedViewAll.subscription) {
      this.selectedViewAll.subscription.unsubscribe();
    }

    if (this.selectedViewAll.name !== carouselGuid) {
      //this.store.dispatch(new SelectViewAllCarousel(null));
      store.dispatch(selectViewAllCarouselAction(null));
    }

    this.selectedViewAll.name = carouselGuid;

    this.selectedViewAll.subscription = (this.carouselService
      .getViewAllCarousels(carouselGuid)
      .subscribe((carousels: ICarouselDataByType) => {
        //this.store.dispatch(new SelectViewAllCarousel(carousels));
        store.dispatch(selectViewAllCarouselAction(carousels));
      }) as any) as Subscription;

    return this.viewAllCarousels;
  }

  /**
   * Used to set up the Ondemand shows into carousel store. Category carousel call returns the shows and if
   * channel Id passes to this method then it filters shows which are related to given channel Id.
   * @param {ICarouselDataByType} carousels
   * @param {string} channelId
   */
  private selectChannelForShowCarousel(
    carousels: ICarouselDataByType,
    channelId?: string,
  ) {
    const showList = [];
    const shows: Array<IGroupedCarousel> = getSegmentGroupedCarousel(
      carousels && carousels.selectors ? carousels.selectors : [],
      CarouselTypeConst.CONTENT_TOGGLE_SELECTOR,
      CarouselTypeConst.SEGMENT_ON_DEMAND_CLASS,
    );
    if (channelId) {
      const filteredItem = shows.find(
        groupedCarousel =>
          groupedCarousel.carousel &&
          groupedCarousel.carousel.conditions &&
          groupedCarousel.carousel.conditions.channelId === channelId,
      );
      if (filteredItem) {
        showList.push(filteredItem);
      }
    }

    /*this.store.dispatch(
      new SelectGroupedShowCarousel(channelId ? showList : shows),
    );*/
  }

  /**
   * Dispatches the collection page carousel to the Redux store
   * @param {string} pageUrl contains pageName and other params like contextGuid to get the carousels for
   * @returns {Observable<ICarouselDataByType>}
   */
  public selectCollectionCarousel(
    pageUrl: string,
  ): Observable<ICarouselDataByType> {
    if (this.selectedCollection.subscription) {
      this.selectedCollection.subscription.unsubscribe();
    }

    if (this.selectedCollection.name !== pageUrl) {
      //this.store.dispatch(new SelectCollectionCarousel(null));
      store.dispatch(selectCollectionCarouselAction(null));
    }

    this.selectedCollection.name = pageUrl;

    // TODO vpaindla re think about page name, instead hard coded can we split from url.
    this.selectedCollection.subscription = (this.carouselService
      .getCarouselsByPageUrl(CarouselTypeConst.COLLECTION_DETAILS, pageUrl)
      .subscribe((carousels: ICarouselDataByType) => {
        //this.store.dispatch(new SelectCollectionCarousel(carousels));
        store.dispatch(selectCollectionCarouselAction(carousels));
      }) as any) as Subscription;

    return this.collectionCarousels;
  }

  /**
   * Dispatches the edp page carousel to the Redux store
   * @param {string}  pageUrl contains pageName and other params like channelGuid/showGuid/episodeGuid to get the carousels for
   * @returns {Observable<ICarouselDataByType>}
   */
  public selectEnhancedEdpCarousel(
    pageUrl: string,
  ): Observable<ICarouselDataByType> {
    let carouselType: string = '';

    let regexGroupInfo = pageUrl.match(/page-name=(.*)&/);
    if (regexGroupInfo && regexGroupInfo[1]) {
      carouselType = regexGroupInfo[1];
    }

    if (pageUrl.length === 0 || carouselType.length === 0) {
      throw new Error(this.ERROR_INVALID_CAROUSEL_TYPE + pageUrl);
    }

    if (this.selectedEnhancedEdpCarousel.subscription) {
      this.selectedEnhancedEdpCarousel.subscription.unsubscribe();
    }

    //this.store.dispatch(new SelectEnhancedEdpCarousel(null));
    store.dispatch(selectEnhancedEdpCarouselAction(null));

    this.selectedEnhancedEdpCarousel.name = pageUrl;

    this.selectedEnhancedEdpCarousel.subscription = (this.carouselService
      .getCarouselsByPageUrl(carouselType, pageUrl)
      .subscribe((carousels: ICarouselDataByType) => {
        //this.store.dispatch(new SelectEnhancedEdpCarousel(carousels));
        store.dispatch(selectEnhancedEdpCarouselAction(carousels));
      }) as any) as Subscription;

    return this.enhancedEdpCarousels;
  }

  /**
   * Make sure that the carousels for Seeded stations Carousels is selected in the store
   */
  public selectSeededStations(): Observable<ICarouselDataByType> {
    if (this.selectedSeededStations.subscription) {
      this.selectedSeededStations.subscription.unsubscribe();
    }

    //this.store.dispatch(new SelectSeededStationsCarousel(null));

    this.selectedSeededStations.name = CarouselTypeConst.SEEDED_STATIONS;

    this.selectedSeededStations.subscription = (this.carouselService
      .getCarouselsByPage(CarouselTypeConst.SEEDED_STATIONS)
      .subscribe((carousels: ICarouselDataByType) => {
        //this.store.dispatch(new SelectSeededStationsCarousel(carousels));
      }) as any) as Subscription;

    return this.seededStationsCarousels;
  }
}
function carousels(
  carousels: any,
): import('../../redux/action/action.interface').IAction {
  throw new Error('Function not implemented.');
}
