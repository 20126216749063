import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import moment from 'moment';

import {
  LiveTimeService,
  IMediaCut,
  MediaUtil,
  SkipInfo,
  MediaTimeLineService,
  MediaTimeLine,
  ILiveTime,
  DmcaService,
  IProviderDescriptor,
  addProvider,
} from '../../servicelib';

import {
  INowPlayingStore,
  //selectNowPlayingState,
} from '../../redux/selector/now-playing.store';

interface SkipButtonsState {
  visible: boolean;
  forward: boolean;
  back: boolean;
}

interface MessageData {
  skipsLeft: number;
  skipTimestamp: any;
}

const SKIP_TYPE = {
  FORWARD: 'forward',
  BACKWARD: 'back',
};

const skipButtonsStates: { [prop: string]: SkipButtonsState } = {
  enabled: {
    visible: true,
    forward: true,
    back: true,
  },
  disabled: {
    visible: true,
    forward: false,
    back: false,
  },
  forwardOnly: {
    visible: true,
    forward: true,
    back: false,
  },
  backwardOnly: {
    visible: true,
    forward: false,
    back: true,
  },
  disallowed: {
    visible: false,
    forward: false,
    back: false,
  },
};

export class SkipButtonService {
  public skipButtonsState: SkipButtonsState = skipButtonsStates.disabled;

  public skipType = SKIP_TYPE;

  /**
   * Properties used to evaluate live content
   */
  public liveTime: ILiveTime;

  /**
   * Holds MediaTimeLine from mediaTimeLineService.mediaTimeLine Observable.
   */
  public mediaTimeLine: MediaTimeLine;

  /**
   * The now playing data observable
   */
  //public nowPlayingStore$: Observable<INowPlayingStore> = this.store.select(selectNowPlayingState);

  /**
   * Holds the currently playing media cut.
   */
  public cut: IMediaCut;

  public hasQuietPeriodElapsed: boolean = true;

  public displaySkipMessage$: Subject<any> = new Subject();

  /**
   * Holds a reference to the nowPlayingData store
   */
  //public nowPlayingStore: INowPlayingStore;
  public _nowPlayingStore: any = {};

  public skipData: MessageData = { skipsLeft: 99, skipTimestamp: '' };
  public displayMessage: boolean = false;
  private static SKIP_MESSAGE_THRESHOLD = 5;
  private skipLimitText = 'Skip limit reached until';
  private skipsLeftSingularText = 'skip left';
  private skipsLeftPluralText = 'skips left';

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(SkipButtonService, SkipButtonService, [
      DmcaService,
      LiveTimeService,
      MediaTimeLineService,
    ]);
  })();

  /**
   * Constructor
   * @param {Store<IAppStore>} store
   * @param {DmcaService} dmcaService
   * @param {LiveTimeService} liveTimeService
   */
  constructor(
    //private store: Store<IAppStore>,
    private dmcaService: DmcaService,
    private liveTimeService: LiveTimeService,
    private mediaTimeLineService: MediaTimeLineService,
  ) {
    this.liveTimeService.liveTime$.subscribe(data => {
      this.liveTime = data;
    });

    /*
        this.nowPlayingStore$
            .pipe(filter(nowPlayingStore => !!nowPlayingStore))
            .subscribe((nowPlayingStore: INowPlayingStore) =>
        {
            this.nowPlayingStore = nowPlayingStore;
            this.cut = nowPlayingStore.cut;
            const disabled = this.hasQuietPeriodElapsed ? this.isMultiTrackAndNoNext() : true;
            this.determineButtonState(this.nowPlayingStore, disabled);
        });
        */

    this.displaySkipMessage$.subscribe((data: any) => {
      this.skipData = this.getMessageData(data.skipInfo);

      if (this.skipData.skipsLeft <= SkipButtonService.SKIP_MESSAGE_THRESHOLD) {
        this.displayMessage = data.isVisible;
      }
    });

    this.mediaTimeLineService.mediaTimeLine
      .pipe(filter(mediaTimeLine => !!mediaTimeLine))
      .subscribe((mediaTimeLine: MediaTimeLine) => {
        this.mediaTimeLine = mediaTimeLine;
        const disabled = this.hasQuietPeriodElapsed
          ? this.isMultiTrackAndNoNext()
          : true;
        this.determineButtonState(this.nowPlayingStore, disabled);
      });

    this.dmcaService.skipHappenedSkipInfo$.subscribe((skipInfo: SkipInfo) => {
      this.hasQuietPeriodElapsed = false;
      this.determineButtonState(this.nowPlayingStore, true);
      this.displaySkipMessage$.next({ isVisible: true, skipInfo: skipInfo });

      setTimeout(() => {
        this.hasQuietPeriodElapsed = true;
        const disabled = this.isMultiTrackAndNoNext();
        this.determineButtonState(this.nowPlayingStore, disabled);
        this.displaySkipMessage$.next({ isVisible: false, skipInfo: skipInfo });
      }, 2500);
    });
  }

  //This needs to be constantly updated, as long as the Now Playing screen is open
  set nowPlayingStore(state: INowPlayingStore) {
    this._nowPlayingStore = state;
    this.cut = state.cut;
    const disabled = this.hasQuietPeriodElapsed
      ? this.isMultiTrackAndNoNext()
      : true;
    this.determineButtonState(this.nowPlayingStore, disabled);
  }

  get nowPlayingStore() {
    return this._nowPlayingStore;
  }

  /***
   * Used to determine if skip button should be disabled
   * Returns true if mediaTimeLine is multiTrackAudio and next track is unavailable
   * @returns {boolean}
   */
  private isMultiTrackAndNoNext(): boolean {
    if (
      this.mediaTimeLine &&
      MediaUtil.isMultiTrackAudioMediaType(this.mediaTimeLine.mediaType)
    ) {
      const currentTrack =
        this.mediaTimeLine.clips && this.mediaTimeLine.clips.getCurrentTrack();
      return !currentTrack || !currentTrack.nextTrack;
    }
    return false;
  }

  /***
   * Returns the current track is live or not
   * @returns {boolean}
   */
  public isTrackLive(): boolean {
    return this.cut && this.cut.times && this.cut.times.zuluEndTime
      ? this.cut.times.zuluEndTime >= this.liveTime.zuluMilliseconds
      : false;
  }

  /**
   * Used to determine the state of the buttons after the DMCA store's
   * skip counts have been updated.
   *
   * @private
   * @param {INowPlayingStore} nowPlayingStore
   * @param {boolean} disable
   * @returns {SkipButtonsState}
   * @memberof SkipButtonService
   */
  public determineButtonState(
    nowPlayingStore: INowPlayingStore,
    disabled?: boolean,
  ): void {
    let state: SkipButtonsState;

    const skipInfo = this.dmcaService.getSkipInfo(nowPlayingStore);

    if (disabled) {
      state = skipButtonsStates.disabled;
    } else if (this.dmcaService.isDisallowed(nowPlayingStore)) {
      state = skipButtonsStates.disallowed;
    } else if (
      this.mediaTimeLine &&
      MediaUtil.isPodcastMediaType(this.mediaTimeLine.mediaType)
    ) {
      state = skipButtonsStates.backwardOnly;
    } else if (this.channelHasSkipsRemaining(skipInfo, nowPlayingStore)) {
      state = this.channelHasBackSkipsRemaining(skipInfo, nowPlayingStore)
        ? skipButtonsStates.enabled
        : skipButtonsStates.forwardOnly;
    }

    this.skipButtonsState = state || skipButtonsStates.disabled;
  }

  /**
   * Click handler for player controls skip buttons.
   * Dispatches the correct action to DMCA Store depending on the channel's current skip state.
   *
   * @memberof SkipButtonService
   */
  public onSkip(direction: string): void {
    const isLiveTrackDirectionForward =
      direction === SKIP_TYPE.FORWARD && this.isTrackLive();
    const isInterstitial =
      this.cut &&
      !MediaUtil.isSongCutContentType(this.cut) &&
      !MediaUtil.isTalkCutContentType(this.cut);

    if (
      isLiveTrackDirectionForward ||
      this.dmcaService.isUnrestricted(this.nowPlayingStore) ||
      isInterstitial
    )
      return;

    switch (direction) {
      case SKIP_TYPE.FORWARD:
        this.dmcaService.skipFwdWasSuccessful(this.nowPlayingStore);
        break;
      case SKIP_TYPE.BACKWARD:
        this.dmcaService.skipBackWasSuccessful(this.nowPlayingStore);
        break;
    }
  }

  /**
   * Determines if the channel has skips remaining by ensuring the total of skips forward
   * and the total of skips back does not exceed the channels maximum total of skips.
   *
   * @private
   * @param {SkipInfo} skipInfo
   * @param {INowPlayingStore} nowPlayingStore
   * @returns {boolean}
   * @memberof SkipButtonService
   */
  public channelHasSkipsRemaining(
    skipInfo: SkipInfo,
    nowPlayingStore: INowPlayingStore,
  ): boolean {
    let numSkipsForward, numSkipsBack;
    if (!skipInfo) {
      numSkipsForward = numSkipsBack = 0;
    } else {
      numSkipsForward = skipInfo.numSkipsForward;
      numSkipsBack = skipInfo.numSkipsBack;
    }

    return (
      numSkipsForward + numSkipsBack <
      this.dmcaService.getMaxTotalSkips(nowPlayingStore)
    );
  }

  /**
   * Determines if channel has back skips remaining by ensuring the total number
   * of back skips does not exceed the number of back skips allowed on the channel
   *
   * @param {SkipInfo} skipInfo
   * @param {INowPlayingStore} nowPlayingStore
   * @returns {boolean}
   * @memberof SkipButtonService
   */
  channelHasBackSkipsRemaining(
    skipInfo: SkipInfo,
    nowPlayingStore: INowPlayingStore,
  ): boolean {
    let numSkipsBack;
    if (!skipInfo) {
      numSkipsBack = 0;
    } else {
      numSkipsBack = skipInfo.numSkipsBack;
    }

    return numSkipsBack < this.dmcaService.getMaxBackSkips(nowPlayingStore);
  }

  private getMessageData(skipInfo: SkipInfo): MessageData {
    const {
      numSkipsForward,
      numSkipsBack,
      maxTotalSkips,
      skipTimestamp,
    } = skipInfo;

    return {
      skipTimestamp: moment(skipTimestamp).add(1, 'h'),
      skipsLeft: maxTotalSkips - (numSkipsForward + numSkipsBack),
    };
  }

  public getSkipMessageText(): string {
    const { skipsLeft, skipTimestamp } = this.skipData,
      formattedTime = skipTimestamp.format('LT');

    let messageText: string = '';

    if (skipsLeft > 1) {
      // 2+ skips left
      messageText = `${skipsLeft} ${this.skipsLeftPluralText}`;
    } else if (skipsLeft === 1) {
      // 1 skip left
      messageText = `${skipsLeft} ${this.skipsLeftSingularText}`;
    } else {
      // Skip limit reached until...
      messageText = `${this.skipLimitText} ${formattedTime}`;
    }

    return messageText;
  }
}
