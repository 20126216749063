import {
  IChannel,
  IOnDemandShow,
  IShowImage,
  IRawTileData,
  ITile,
  IInitialTile,
} from '../index';
import moment from 'moment';

export interface IFavoriteItem {
  assetGUID: string;
  assetType: FavoriteAssetType;
  assetName: string;
  contentType: string;
  episodeCount: number;
  channelId: string;
  tabSortOrder?: number;
  showLogos: Array<IShowImage>;
  showImages: Array<IShowImage>;
  guid?: string;
  relatedChannelIds?: string[];
  channel?: IChannel;
  show?: IOnDemandShow;
}

export interface IRawFavoriteItem {
  assetGUID: string;
  assetType: string;
  assetName: string;
  shortDescription?: string;
  longDescription?: string;
  channelId: string;
  contentType: string;
  tabSortOrder?: number;
}

export interface IFavoriteAsset {
  channelId: string;
  assetGuid: string;
  contentType: string;
  subContentType: string;
  isPlaceholderShow?: boolean;
  isFavorite?: boolean;
  title: string;
  isIrisPodcast?: boolean;
}

export class FavoriteUpdateItem {
  assetGUID: string;
  assetType: FavoriteAssetType;
  contentType: FavoriteContentType;
  channelId: string;
  tabSortOrder?: number;
  createdDateTime: string;
  modifiedDateTime: string;
  changeType: FavoriteChangeType;

  constructor(
    channelId: string,
    contentType: FavoriteContentType,
    assetType: FavoriteAssetType,
    changeType: FavoriteChangeType,
    assetGuid: string,
    tabSortOrder?: number,
  ) {
    this.assetType = assetType;
    this.contentType = contentType;
    this.assetGUID = assetGuid;
    this.channelId = channelId;
    this.changeType = changeType;
    this.createdDateTime = moment().toISOString();
    this.modifiedDateTime = moment().toISOString();

    if (changeType === FavoriteChangeTypes.UPDATE) {
      this.tabSortOrder = tabSortOrder;
    }
  }
}

export interface IRawFavoriteCarouselResponse {
  contentToggleSelector: {
    selectorSegment: IRawTileData[];
  };
}

export interface IGroupedFavorites {
  channels: ITile[] | IInitialTile[];
  shows: ITile[] | IInitialTile[];
  episodes: ITile[] | IInitialTile[];
}

export class FavoriteAssetTypes {
  public static LIVE = 'live';
  public static SHOW = 'show';
  public static EPISODE = 'episode';
  public static CHANNEL = 'channel';
}

export class FavoriteContentTypes {
  public static LIVE = 'live';
  public static SHOW = 'show';
  public static AOD = 'aod';
  public static VOD = 'vod';
  public static ADDITIONAL_CHANNEL = 'additionalChannel';
  public static SEEDED_RADIO = 'seededRadio';
  public static SHOW_PODCAST = 'showpodcast';
  public static PODCAST = 'podcast';
}

export class FavoriteChangeTypes {
  public static ADD = 'add';
  public static UPDATE = 'update';
  public static DELETE = 'delete';
}

export type FavoriteAssetType = 'live' | 'channel' | 'show' | 'episode';

export type FavoriteChangeType = 'add' | 'update' | 'delete';

export type FavoriteContentType = 'aod' | 'vod' | 'league' | 'team' | 'live';
