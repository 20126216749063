import {
  ICarouselActionPayload,
  IGroupedCarouselActionPayload,
  CarouselActions,
  //CarouselAction,
} from '../action/carousel.action';

import { ICarouselStore } from '../selector/carousel.store';
import { ICarouselDataByType } from '../../servicelib';
import { IAction } from '../action/action.interface';

/**
 * Th default or initial store model values.
 */
const initialState: ICarouselStore = {
  superCategoryCarousel: null,
  //NOTE: To handle sub category refresh {} as ICarouselDataByType
  subCategoryCarousel: {} as ICarouselDataByType,
  nowPlayingCarousel: null,
  nowPlayingUpNextCarousel: null,
  profileCarousel: null,
  searchCarousel: null,
  searchLandingCarousels: null,
  allOnDemandCarousel: null,
  allVideoCarousel: null,
  allChannelsCarousel: null,
  selectedContentCarousel: null,
  episodeCarousel: {} as ICarouselDataByType,
  showCarousel: {} as ICarouselDataByType,
  recentsCarousel: null,
  showReminderCarousel: null,
  //NOTE: To handle view all refresh {} as ICarouselDataByType
  viewAllCarousel: {} as ICarouselDataByType,
  filterdShowsCarousel: [],
  collectionCarousel: null,
  enhancedEdpCarousel: null,
  seededStationsCarousel: null,
  allPodCastCarousel: null,
};

/**
 * The default action in case none is provided. Similar to the `initialState`.
 */
const defaultAction: IAction = {
  type: '',
  payload: { carousel: null },
};

/**
 * Gets the current state of the store as a new object.
 */
const getCurrentState: Function = (state: ICarouselStore): ICarouselStore => {
  return Object.assign({}, state);
};

/**
 * Return the data store depending on the given action.
 *
 * @param state is the current state of the carousel store
 * @param action is the type of action to be take on the store
 */
export function carouselReducer(
  state: ICarouselStore = initialState,
  action: IAction = defaultAction,
): ICarouselStore {
  // TypeScript only provides default params for undefined, so if you pass in null they aren't used...this is
  // why we still account for falsey values with our own defaults.
  state = state || initialState;
  action = action || defaultAction;

  const currentState = getCurrentState(state);
  const carouselPayload = action.payload as ICarouselActionPayload;

  switch (action.type) {
    case CarouselActions.SELECT_SUPERCATEGORY_CAROUSEL:
      currentState.superCategoryCarousel = carouselPayload.carousel;
      break;

    case CarouselActions.SELECT_SUBCATEGORY_CAROUSEL:
      currentState.subCategoryCarousel = carouselPayload.carousel;
      break;

    case CarouselActions.SELECT_NOWPLAYING_CAROUSEL:
      currentState.nowPlayingCarousel = carouselPayload.carousel;
      break;

    case CarouselActions.SELECT_NOWPLAYING_UP_NEXT_CAROUSEL:
      currentState.nowPlayingUpNextCarousel = carouselPayload.carousel;
      break;

    case CarouselActions.SELECT_SEARCH__LANDING_CAROUSEL:
      currentState.searchLandingCarousels = carouselPayload.carousel;
      break;

    case CarouselActions.SELECT_SEARCH_CAROUSEL:
      currentState.searchCarousel = carouselPayload.carousel;
      break;

    case CarouselActions.SELECT_PROFILE_CAROUSEL:
      currentState.profileCarousel = carouselPayload.carousel;
      break;

    case CarouselActions.SELECT_CONTENT_CAROUSEL:
      currentState.selectedContentCarousel = carouselPayload.carousel;
      break;

    case CarouselActions.SELECT_ALL_ON_DEMAND_CAROUSEL:
      currentState.allOnDemandCarousel = carouselPayload.carousel;
      break;

    case CarouselActions.SELECT_ALL_VIDEO_CAROUSEL:
      currentState.allVideoCarousel = carouselPayload.carousel;
      break;

    case CarouselActions.SELECT_ALL_CHANNELS_CAROUSEL:
      currentState.allChannelsCarousel = carouselPayload.carousel;
      break;

    case CarouselActions.SELECT_EPISODE_CAROUSEL:
      currentState.episodeCarousel = carouselPayload.carousel;
      break;

    case CarouselActions.SELECT_SHOW_CAROUSEL:
      currentState.showCarousel = carouselPayload.carousel;
      break;

    case CarouselActions.SELECT_RECENTS_CAROUSEL:
      currentState.recentsCarousel = Object.assign(
        {},
        carouselPayload.carousel,
      );
      break;

    case CarouselActions.SELECT_SHOW_REMINDER_CAROUSEL:
      currentState.showReminderCarousel = carouselPayload.carousel;
      break;

    case CarouselActions.SELECT_VIEW_ALL_CAROUSEL:
      currentState.viewAllCarousel = carouselPayload.carousel;
      break;

    case CarouselActions.SELECT_GROUPED_SHOW_CAROUSEL: {
      const groupCarouselPayload = action.payload as IGroupedCarouselActionPayload;
      currentState.filterdShowsCarousel = groupCarouselPayload.carousel;
      break;
    }

    case CarouselActions.SELECT_COLLECTION_CAROUSEL:
      currentState.collectionCarousel = carouselPayload.carousel;
      break;

    case CarouselActions.SELECT_ENHANCED_EDP_CAROUSEL:
      currentState.enhancedEdpCarousel = carouselPayload.carousel;
      break;

    case CarouselActions.SELECT_SEEDED_STATIONS_CAROUSEL:
      currentState.seededStationsCarousel =
        carouselPayload && carouselPayload.carousel
          ? { ...carouselPayload.carousel }
          : null;
      break;

    case CarouselActions.SELECT_ALL_PODCAST_CAROUSEL:
      currentState.allPodCastCarousel = carouselPayload.carousel;
      break;
  }

  return currentState;
}
