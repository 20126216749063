/**
 * Used neritic-link related constants to determine neritic link actions
 **/
export const neriticActionConstants = {
    API: "Api",
    APP: "App",
    TUNE: "tune",
    ARCHIVE_VIEW_EPISODES: "ArchiveViewEpisodes",
    VIEW_VOD_EPISODES: "ViewVODEpisodes",
    VIEW_AOD_EPISODES: "ViewAODEpisodes",
    VIEW_ALL: "viewAll",
    CAROUSEL: "carousel",
    CAROUSEL_ALL: "carouselAll",
    LIVE_AUDIO: "liveAudio",
    LIVE_VIDEO: "liveVideo",
    ADDL_CHAN: "addlChan",
    ALL_ADDITIONAL_CHANNELS: "onlyAdditionalChannels",
    ALL_CHANNELS: "channels_all",
    ALL_PODCASTS: "podcasts_all",
    PODCASTS: "podcasts",
    ALL_VIDEOS: "video_landing",
    EDP_SHOW_ENHANCED: "edp_show_enhanced",
    MEGA_STARS: "category_mega_star",
    SEEDED_RADIO: "seededRadio",
    CREATE_SEEDED_RADIO: "createSeededChannel",
    MANAGE_STATIONS: "manageStations",
    SHOW: "show",
    CHANNEL: "channel",
    COLLECTION: "collection",
    PAGE: "page",
    HOME: "Home",
    FAVORITES: "Favorites",
    SETTINGS: "Settings",
    LOG_IN: "LogIn",
    SEARCH: "Search",
    RECENTS: "recents",
    SETTINGS_HELP: "SettingsHelp",
    SETTINGS_LISTENER_SETTINGS: "SettingsListenerSettings",
    SETTINGS_LISTENER_SETTINGS_EDIT_AVATAR: "SettingsListenerSettingsEditAvatar",
    SETTINGS_SYSTEM_SETTINGS: "SettingsSystemSettings",
    SETTINGS_LISTENER_SETTINGS_EDIT_NAME: "SettingsListenerSettingsEditName",
    SETTINGS_LISTENING_HISTORY: "SettingsListeningHistory",
    NOW_PLAYING: "NowPlaying",
    ARCHIVE: "Archive",
    CATEGORY: "category",
    CATEGORIES: "Categories",
    HOWARD_STERN: "howardstern",
    CATEGORY_HOWARD_STERN: "category_howardstern",
    SUPER_CATEGORY_FOR_YOU: "supercategory_for_you",
    FOR_YOU: "for_you",
    SUPER_CATEGORY: "superCategory",
    SUPER_CATEGORY_MUSIC: "supercategory_music",
    SUPER_CATEGORY_SPORTS: "supercategory_sports",
    SUPER_CATEGORY_NEWS: "supercategory_news",
    SUPER_CATEGORY_ENTERTAINMENT: "supercategory_entertainment",
    SUPER_CATEGORY_HOWARD: "supercategory_howardstern",
    SIGN_UP: "SignUp",
    LINEAR_TUNER: "LinearTuner",
    WEB_LINK: "WebLink",
    FUNCTIONAL_GROUPS: {
      PRIMARY_ACTION: "primaryAction",
      ON_DEMAND_EPISODES: "onDemandEpisodes",
      ON_DEMAND_SHOWS: "onDemandShows",
      SET_SHOW_REMINDER: "setShowReminder",
      ADD_SHOW_TO_FAVORITES: "addShowToFavorites",
      DOWNLOAD_EPISODE: "downloadEpisode",
      SET_SHOW_LIVE_VIDEO_REMINDER: "setShowLiveVideoReminder",
      MORE_EPISODES: "moreEpisodes",
      ADD_TO_FAVORITES: "addToFavorites",
      CHANNEL_DETAILS: "channelDetails",
      SHOW_DETAILS: "showDetails",
      EPISODE_DETAILS: "episodeDetails",
      PODCAST_EPISODE_DETAILS: "podcastEpisodeDetails",
      CONDITIONAL_LIVE_VIDEO: "conditionalLiveVideo",
      REMOVE_SEEDED_STATION  :"removeFromStationList"
    }
};
