import React, { useEffect, useState, memo } from 'react';
import { CoreNavigationMenu } from './core-navigation-menu.component';
import { ZoneNavigationMenu } from './zone-navigation-menu.component';
import { useLocation } from 'react-router-dom';
import { useNavigation } from '../../hooks';
import './navigation-menu.component.scss';
import { MENU } from '../../utils/navigationMap';
import {
  checkDisplayZoneMenuWithPath,
  checkIfFreeTrialCounterShouldBeDisplayed,
} from './navigation-menu-utils';
import FreeTrialCounterComponent from '../free-trial-counter/free-trial-counter.component';

const { CORE_MENU, ZONE_MENU } = MENU;

function NavigationMenuComponent(props) {
  const { isCoreMenuOpen, setIsCoreMenuOpen, isOnFreeTrial } = props;
  const location = useLocation();
  const [hideZoneMenu, setHideZoneMenu] = useState(false);
  useNavigation();

  const navMap = {
    CORE_MENU: {
      id: CORE_MENU,
      nextdown: ZONE_MENU,
    },
    ZONE_MENU: {
      id: ZONE_MENU,
      nextup: CORE_MENU,
    },
  };

  useEffect(() => {
    const pathQuery = new URLSearchParams(location.search);
    const comingFromSearch = pathQuery.get('comingFromSearch');
    setHideZoneMenu(comingFromSearch === 'true');
  }, [location]);

  return (
    <>
      <div className="menu-wrapper">
        {isCoreMenuOpen && (
          <CoreNavigationMenu
            nav={{ ...navMap.CORE_MENU }}
            setIsCoreMenuOpen={setIsCoreMenuOpen}
          />
        )}
        {checkDisplayZoneMenuWithPath(location.pathname, hideZoneMenu) && (
          <ZoneNavigationMenu
            nav={{ ...navMap.ZONE_MENU }}
            coreCategory={location.pathname}
            isCoreMenuOpen={isCoreMenuOpen}
            setIsCoreMenuOpen={setIsCoreMenuOpen}
          />
        )}
        {isOnFreeTrial &&
          !checkIfFreeTrialCounterShouldBeDisplayed(location.pathname) && (
            <FreeTrialCounterComponent />
          )}
      </div>
      <div className="menu-block-placeholder">
        {isCoreMenuOpen && <div className="core-menu-block-placeholder" />}
        {isOnFreeTrial &&
          !checkIfFreeTrialCounterShouldBeDisplayed(location.pathname) && (
            <div className="free-trial-counter-block-placeholder" />
          )}
        <div
          className={
            checkDisplayZoneMenuWithPath(location.pathname, hideZoneMenu)
              ? 'zone-menu-block-placeholder'
              : 'zone-menu-none-placeholder'
          }
        />
      </div>
    </>
  );
}

export const NavigationMenu = memo(NavigationMenuComponent);
