import { Observable ,  interval ,  Subscription } from "rxjs";
import { ServiceEndpointConstants } from "../service/consts/service.endpoint.constants";

import {
    addProvider,
    IProviderDescriptor
} from "../index";

export class ConnectivityDelegate
{
    private static providerDescriptor : IProviderDescriptor = function()
    {
        return addProvider(
            ConnectivityDelegate,
            ConnectivityDelegate,
            []
        );
    }();

    public observable: Observable<number>;
    public subscription: Subscription;
    public httpProvider: any;

    constructor()
    {
        this.observable = interval(3000);
    }

    public startRequesting()
    {
        if (!this.subscription)
        {
            this.subscription = this.observable.subscribe(() => this.issueRequest());
        }
    }

    public stopRequesting()
    {
        if (this.subscription)
        {
            this.subscription.unsubscribe();
        }
        this.subscription = null;
    }

    public issueRequest()
    {
        this.httpProvider.get(
            ServiceEndpointConstants.NETWORK_CONNECTIVITY,
            null,
            { isRaw: true, retryCount: 0, dataProperties: [] })
            .subscribe();
    }

    public setHttpProvider(httpProvider)
    {
        this.httpProvider = httpProvider;
    }
}
