export const mockForYouData = {
    categoryGuid: "foryou",
    name: "For You",
    description: "",
    sortOrder: 1,
    url: "http://www.siriusxm.com/foryou",
    key: "foryou",
    isPrimary: false,
    images: { images : [] },
    satId: "2",
    categories: { categories: [] },
    categoryList: [],
    aodShowCount: 93
};
