import xdk from '@accedo/xdk-core';
import { tts } from '@accedo/xdk-extensions-contrib';
const { ID: TTS } = tts;

export const ttsPlayback = async (playbackText = '', isCancel = false) => {
  try {
    if (playbackText === '' && !playbackText) return;
    const tts = await xdk.extensionManager.getExtension(TTS);
    if (!isCancel) {
      tts.cancel();
    }
    tts.speak(
      playbackText
        .replace(/\//g, ' slash ')
        .replace(/Ch\s/g, 'channel ')
        .replace(/SiriusXM/gi, 'serious xm')
        .replace('document well', '')
        .replace('document web', '')
        .replace('Clear Search History', 'Clear Search History Button')
        .replace('undefined', ''),
    );
  } catch (e) {
    console.error(e);
  }
};

export const cancelTtsPlayback = async () => {
  const tts = await xdk.extensionManager.getExtension(TTS);
  if (tts.isSpeaking()) {
    tts.cancel();
  }
};
