import { IAction } from './action.interface';

export const UDPATE_WALL_CLOCK = '[Wall-Clock] updateWallClock';

export class updateWallClock implements IAction {
  readonly type = UDPATE_WALL_CLOCK;

  constructor(public payload: Date) {}
}

export type WallClockAction = updateWallClock;
