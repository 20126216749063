import { ContentTypes } from "../service/types/content.types";
import { CarouselTypeConst } from "../carousel/carousel.const";
import {IBaseCarouselResponse} from "./carousel.types";

/**
 * @todo
 *      V2Carousel Schema has changed again
 *      we will need to update some field names in this file
 *      at some point
 * Currently the newest updated Schema is present ONLY on dev.intever3
 * So use caution about which endpoint used when doing manual testing
 * @see https://github.siriusxm.com/WebClientTeam/web-client/pull/750/files
 */

export const mockV2RawCarouselData = {
    screen : "for_you",
    carouselTitle: {
        textValue: "On Air Now",
        textClass: "title"
    },
    carouselDisplayType: "short",
    carouselOrientation: "horizontal",
    carouselAlgorithm: {
        algorithmName: "on air now",
        algorithmParameters: [
            {
                paramKey: "superCategory",
                paramValue: "music"
            }
        ]
    },
    carouselGUID: "on air now",
    carouselViewAll: {
        showViewAll: false
    },
    editable:
        {
            clearAll: "false",
            modifyModuleType: "",
            remove: "false",
            reorder: "false"
        },
    carouselTiles: [
        {
            tileContentType: CarouselTypeConst.CHANNEL_TILE,
            tileContentSubType : "",
            tileAssetInfo: [
                {
                    assetInfoKey: "channelGUID",
                    assetInfoValue: "d3253c66-e1e1-331b-02e6-71580c33791b"
                }
            ],
            tileContentStatus: "",
            tileMarkup: {
                tileImage: [
                    {
                        imageClass: "background",
                        imageLink: "http://pri.art.uat.streaming.siriusxm.com/images/chan/8f/a980a4-87d8-5d8b-4198-52db1c45501e.jpg",
                        imageAltText: "everest tile channel background"
                    },
                    {
                        imageClass: "logo",
                        imageLink: "http://pri.art.uat.streaming.siriusxm.com/images/chan/78/b8dcc9-a79d-35f0-7c65-4905c372ae2d.png",
                        imageAltText: "color channel logo (on dark)"
                    }
                ],
                tileText: [
                    {
                        textValue: "Ch 61",
                        textClass: "line1"
                    },
                    {
                        textValue: "2000s Country Hits",
                        textClass: "line2"
                    }
                ],
                tileAction: [
                    {
                        actionNeriticLink: "Api:tune:liveAudio:9340",
                        actionAnalyticsTag: "",
                        actionType: "primaryAction"
                    }
                ],
                tileBanner: {
                    bannerClass: "",
                    bannerText : "New Episode"
                }
            }
        }
    ],
    carouselTileEntities: [],
    carouselConditions: []
};

export const mockNormalizedCarousel = {
    tiles: [
        {
            neriticLinkData: [
                {
                    analyticsTag: "",
                    functionalGroup: "primaryAction",
                    linkType: "Api",
                    actionType: "tune",
                    contentType: "liveAudio",
                    channelId: "9340",
                    assetGuid: ""
                }
            ],
            bgImageUrl: "http://pri.art.uat.streaming.siriusxm.com/images/chan/8f/a980a4-87d8-5d8b-4198-52db1c45501e.jpg",
            fgImageUrl: "http://pri.art.uat.streaming.siriusxm.com/images/chan/78/b8dcc9-a79d-35f0-7c65-4905c372ae2d.png",
            fgImageType: "left-top",
            line1: "Ch 61",
            line2: "2000s Country Hits",
            primaryNeriticLink: {
                analyticsTag: "",
                functionalGroup: "primaryAction",
                linkType: "Api",
                actionType: "tune",
                contentType: "liveAudio",
                channelId: "9340",
                assetGuid: ""
            },
            tileGuid: "d3253c66-e1e1-331b-02e6-71580c33791b",
            tileContentType: CarouselTypeConst.CHANNEL_TILE,
            tileContentSubType : "",
            tileAssetInfo: {
                recentPlayGuid: "61d673ff-0aa4-42dc-8512-c47cc0915547"
            }
        },
        {
            neriticLinkData: [
                {
                    analyticsTag: "",
                    functionalGroup: "primaryAction",
                    linkType: "Api",
                    actionType: "tune",
                    contentType: "liveAudio",
                    channelId: "8370",
                    assetGuid: ""
                }
            ],
            bgImageUrl: "http://pri.art.uat.streaming.siriusxm.com/images/chan/2f/61dad0-9848-18f7-4687-d6d78f173c30.jpg",
            fgImageUrl: "http://pri.art.uat.streaming.siriusxm.com/images/chan/52/36d2bc-2123-ea29-0b50-380668f865c2.png",
            fgImageType: "left-top",
            line1: "Ch 22",
            line2: "Pearl Jam Radio",
            primaryNeriticLink: {
                analyticsTag: "",
                functionalGroup: "primaryAction",
                linkType: "Api",
                actionType: "tune",
                contentType: "liveAudio",
                channelId: "8370",
                assetGuid: ""
            },
            tileGuid: "cdc9bee8-9317-37fa-ec8e-464096670c60",
            tileContentType: CarouselTypeConst.CHANNEL_TILE,
            tileContentSubType : "",
            tileAssetInfo: {
                recentPlayGuid: "cd401d85-3e07-4fec-a68a-0d3d4da7c618"
            }
        },
        {
            neriticLinkData: [
                {
                    analyticsTag: "",
                    functionalGroup: "primaryAction",
                    linkType: "Api",
                    actionType: "tune",
                    contentType: "liveAudio",
                    channelId: "thepulse",
                    assetGuid: ""
                }
            ],
            bgImageUrl: "http://pri.art.uat.streaming.siriusxm.com/images/chan/c9/ee5d98-e9a2-1bae-ddcb-bb9015e01e15.jpg",
            fgImageUrl: "http://pri.art.uat.streaming.siriusxm.com/images/chan/78/e24e15-6c43-7e1e-6d56-b12d10f58c92.png",
            fgImageType: "left-top",
            line1: "Ch 15",
            line2: "The Pulse",
            primaryNeriticLink: {
                analyticsTag: "",
                functionalGroup: "primaryAction",
                linkType: "Api",
                actionType: "tune",
                contentType: "liveAudio",
                channelId: "thepulse",
                assetGuid: ""
            },
            tileGuid: "9e8d6f72-0b59-85cf-a222-b18d38acdc0f",
            tileContentType: CarouselTypeConst.CHANNEL_TILE,
            tileContentSubType : "",
            tileAssetInfo: {
                recentPlayGuid: "9e52a05d-aa68-4921-9384-58478aaaf4cf"
            }
        }
    ],
    type: "content",
    guid: "on air now",
    title: {
        textValue: "On Air Now",
        textClass: "title"
    }
};

export const mockV2NormalizedCarousels = {
    zone:[
        {
            hero: {
                tiles: [
                    {
                        neriticLinkData: [
                            {
                                analyticsTag: "",
                                functionalGroup: "primaryAction",
                                linkType: "Api",
                                actionType: "tune",
                                contentType: ContentTypes.VOD,
                                channelId: "howardstern100",
                                assetGuid: "b0c6b833-b88e-4715-8134-db454340f3ca"
                            }
                        ],
                        bgImageUrl: "http://pri.art.uat.streaming.siriusxm.com/images/chan/ec/a4a364-0f9d-6318-334b-503f1b8e9c24.jpg",
                        fgImageUrl: "http://pri.art.uat.streaming.siriusxm.com/images/chan/d5/7998af-ac29-b9ea-367d-dc4ee3bf8c55.png",
                        fgImageType: "left-top",
                        line1: "2m · 12/8/17",
                        line2: "Gary the Conqueror combats the phone flu",
                        primaryNeriticLink: {
                            analyticsTag: "",
                            functionalGroup: "primaryAction",
                            linkType: "Api",
                            actionType: "tune",
                            contentType: ContentTypes.VOD,
                            channelId: "howardstern100",
                            assetGuid: "b0c6b833-b88e-4715-8134-db454340f3ca"
                        },
                        tileGuid: "b0c6b833-b88e-4715-8134-db454340f3ca",
                        tileContentType: ContentTypes.VOD,
                        tileContentSubType : ""
                    }
                ],
                type: "hero",
                guid: "hero",
                title: {
                    textClass: "",
                    textValue: ""
                }
            },
            content: [
                {
                    tiles: [
                        {
                            neriticLinkData: [
                                {
                                    analyticsTag: "",
                                    functionalGroup: "primaryAction",
                                    linkType: "Api",
                                    actionType: "tune",
                                    contentType: ContentTypes.AOD,
                                    channelId: "howardstern101",
                                    assetGuid: "1b39df25-29d9-49ee-be93-98689b2feaee"
                                }
                            ],
                            bgImageUrl: "http://pri.art.uat.streaming.siriusxm.com/images/chan/22/f73be1-a0b4-07ce-9ba8-f4266bb84e04.jpg",
                            fgImageUrl: "http://pri.art.uat.streaming.siriusxm.com/images/chan/7b/578528-4d95-772c-7f00-fbb9670ba90b.png",
                            fgImageType: "left-top",
                            line1: "2h 43m · Thursday",
                            line2: "Yoda, Mad Dog, Juggalos & More!",
                            primaryNeriticLink: {
                                analyticsTag: "",
                                functionalGroup: "primaryAction",
                                linkType: "Api",
                                actionType: "tune",
                                contentType: ContentTypes.AOD,
                                channelId: "howardstern101",
                                assetGuid: "1b39df25-29d9-49ee-be93-98689b2feaee"
                            },
                            tileGuid: "4c8c34a3-2e25-df31-c21d-27714187e343",
                            tileContentType: "episode",
                            tileContentSubType : ""
                        }
                    ],
                    type: "content",
                    guid: "catch up",
                    title: {
                        textClass: "",
                        textValue: ""
                    }
                }
            ]
        }
    ],
    "expirationTime": 1516991106038
};

export const mockV2RawCarouselResponse:IBaseCarouselResponse = {
    screen: "for_you",
    carousel: [
        {
            carouselTitle: {
                textClass : "",
                textValue : ""
            },
            carouselDisplayType: "short",
            carouselOrientation: "horizontal",
            carouselAlgorithm: {
                algorithmName: "curated",
                algorithmParameters: [
                    {
                        paramKey: "pageName",
                        paramValue: "for_you"
                    },
                    {
                        paramKey: "name",
                        paramValue: "SiriusXM Essentials"
                    }
                ]
            },
            carouselGUID: "catch up",
            carouselViewAll: {
                showViewAll: false
            },
            carouselTiles: [
                {
                    tileContentType: "episode",
                    tileContentSubType : "",
                    tileAssetInfo: [
                        {
                            assetInfoKey: "aodEpisodeCaId",
                            assetInfoValue: "4c8c34a3-2e25-df31-c21d-27714187e343"
                        }
                    ],
                    tileContentStatus: "",
                    tileMarkup: {
                        tileImage: [
                            {
                                imageClass: "background",
                                imageLink: "http://pri.art.uat.streaming.siriusxm.com/images/chan/22/f73be1-a0b4-07ce-9ba8-f4266bb84e04.jpg",
                                imageAltText: "everest tile channel background"
                            },
                            {
                                imageClass: "logo",
                                imageLink: "http://pri.art.uat.streaming.siriusxm.com/images/chan/7b/578528-4d95-772c-7f00-fbb9670ba90b.png",
                                imageAltText: "show name"
                            }
                        ],
                        tileText: [
                            {
                                textValue: "2h 43m · Thursday",
                                textClass: "line1"
                            },
                            {
                                textValue: "Yoda, Mad Dog, Juggalos & More!",
                                textClass: "line2"
                            }
                        ],
                        tileAction: [
                            {
                                actionNeriticLink: "Api:tune:aod:howardstern101:1b39df25-29d9-49ee-be93-98689b2feaee",
                                actionAnalyticsTag: "",
                                actionType: "primaryAction"
                            }
                        ],
                        tileBanner: {
                            bannerClass: CarouselTypeConst.UNAVAILABLE_BANNER_CLASS,
                            bannerText: "New Episode"
                        }
                    }
                }
            ],
            carouselTileEntities: [],
            carouselConditions: [],
            editable:
                {
                    clearAll: "false",
                    modifyModuleType: "",
                    remove: "false",
                    reorder: "false"
                }
        }
    ],
    expiry: "2018-01-26T18:25:06.038Z",
    pageSelector: {
        selectorClass: "pageSelector",
        selectorSegment: [
            {
                segmentTitle: "ForYou",
                segmentLink: {
                    actionNeriticLink: "App:carousel:for_you",
                    actionAnalyticsTag: "string",
                    actionType: "primaryAction"
                },
                segmentClass: '',
                segmentCarousel: [],
                segmentGroupedCarousel: []
            }
        ],
        selectorTitle: {
            textValue: "",
            textClass: ""
        }
    },
    heroCarousel: {
        carouselTitle: {
            textClass : "",
            textValue : ""
        },
        carouselDisplayType: "hero",
        carouselOrientation: "horizontal",
        carouselAlgorithm: {
            algorithmName: "curated",
            algorithmParameters: [
                {
                    paramKey: "pageName",
                    paramValue: "for_you"
                },
                {
                    paramKey: "name",
                    paramValue: "SiriusXM Essentials"
                }
            ]
        },
        carouselGUID: "hero",
        carouselViewAll: {
            showViewAll: false
        },
        carouselTiles: [
            {
                tileContentType: ContentTypes.VOD,
                tileContentSubType : "",
                tileAssetInfo: [
                {
                    assetInfoKey: "vodEpisodeGUID",
                    assetInfoValue: "b0c6b833-b88e-4715-8134-db454340f3ca"
                }
                ],
                tileContentStatus: "",
                tileMarkup: {
                tileImage: [
                    {
                        imageClass: "background",
                        imageLink: "http://pri.art.uat.streaming.siriusxm.com/images/chan/ec/a4a364-0f9d-6318-334b-503f1b8e9c24.jpg",
                        imageAltText: "poster"
                    },
                    {
                        imageClass: "logo",
                        imageLink: "http://pri.art.uat.streaming.siriusxm.com/images/chan/d5/7998af-ac29-b9ea-367d-dc4ee3bf8c55.png",
                        imageAltText: "show name"
                    }
                ],
                tileText: [
                    {
                    textValue: "2m · 12/8/17",
                    textClass: "line1"
                    },
                    {
                        textValue: "Gary the Conqueror combats the phone flu",
                        textClass: "line2"
                    },
                    {
                        textValue: "Yoda, Mad Dog, Juggalos & More!",
                        textClass: "longDescription"
                    },
                    {
                        textValue: "10 NEW • ",
                        textStyle: "bold",
                        textColor: "#2BA7FF",
                        textClass: "line3-prefix"
                    }
                ],
                tileAction: [
                    {
                        actionNeriticLink: "Api:tune:vod:howardstern100:b0c6b833-b88e-4715-8134-db454340f3ca",
                        actionAnalyticsTag: "",
                        actionType: "primaryAction"
                    }
                ],
                tileBanner: {
                    bannerClass: CarouselTypeConst.UNAVAILABLE_BANNER_CLASS,
                    bannerText : "New Episode"
                }}
            }
        ],
        carouselTileEntities: [],
        carouselConditions: [],
        editable:
            {
                clearAll: "false",
                modifyModuleType: "",
                remove: "false",
                reorder: "false"
            }
    },
    moreSelector: {
        selectorClass: "moreSelector",
        selectorTitle: {
            textValue: "More from SiriusXM",
            textClass: "title"
        },
        selectorSegment: [
            {
                segmentTitle: "Channels",
                segmentClass: 'artist',
                segmentLink: {
                    actionNeriticLink: "App:carousel:channels_all",
                    actionAnalyticsTag: "string",
                    actionType: "primaryAction"
                },
                segmentCarousel: [],
                segmentGroupedCarousel: []
            }
        ]
    }
};
