export class AlertConstants
{
    public static ALERT_NOTIFICATION_TYPE          = "type";
    public static ALERT_NOTIFICATION_ASSET_GUID    = "assetGUID";
    public static ALERT_NOTIFICATION_EPISODE_GUID  = "episodeGUID";
    public static ALERT_NOTIFICATION_EPISODE_START = "episodeStartDateTime";
    public static ALERT_NOTIFICATION_EPISODE_END   = "episodeEndDateTime";
    public static ALERT_NOTIFICATION_CHANNEL       = "channel";
    public static ALERT_NOTIFICATION_TITLE         = "title";
    public static ALERT_NOTIFICATION_CHANNEL_NAME  = "channelName";

    public static ALERT_EMERGENCY_EJECT            = "emergencyEject";
}

export enum AlertType {
    SHOW = 1,
    ON_DEMAND_EPISODE = 2,
    ON_DEMAND_ABOUT_TO_EXPIRE = 3,
    REBROADCAST_LIVE_CHANNEL = 4,
    LIVE_VIDEO_START = 5
}