import { IBaseCategory, IChannel, ISuperCategory } from '../../servicelib';

import { IAction } from './action.interface';
import { ISelectedCategory } from '../selector/channel-list.store';

// CRUD Actions
export const LOAD_SUPER_CATEGORIES: string =
  '[Channel-List] loadSuperCategories';
export const LOAD_LIVE_CHANNELS: string = '[Channel-List] loadLiveChannels';
export const SELECT_SUPER_CATEGORY: string =
  '[Channel-List] selectSuperCategory';
export const SELECT_CATEGORY: string = '[Channel-List] selectCategory';
export const SELECT_CHANNEL: string = '[Channel-List] selectChannel';
export const SELECT_CHANNEL_FROM_CONTEXT_MENU: string =
  '[Channel-List] selectChannelFromContextMenu';
export const SELECT_CHANNEL_FOR_ONDEMAND_LIST: string =
  '[Channel-List] selectChannelForOnDemandList';

// Filter Actions
export const FILTER_CHANNELS_BY_NAME: string =
  '[Channel-List] filterChannelsByName';
export const FILTER_CHANNELS_BY_NUMBER: string =
  '[Channel-List] filterChannelsByNumber';
export const FILTER_CHANNELS_BY_SHOW_NAME: string =
  '[Channel-List] filterChannelsByShowName';
export const SHOW_ALL_CHANNELS: string = '[Channel-List] showAllChannels';
export const NGRX_STORE_INIT: string = '@ngrx/store/init';

// Sort Actions
export const SORT_CHANNELS_BY_NUMBER: string =
  '[Channel-List] sortChannelsByNumber';

/*export class LoadSuperCategories implements IAction {
  readonly type = LOAD_SUPER_CATEGORIES;

  constructor(public payload: Array<ISuperCategory>) {}
}*/

export const loadSuperCategories = (
  payload: Array<ISuperCategory>,
): IAction => {
  return {
    type: LOAD_SUPER_CATEGORIES,
    payload,
  };
};

/*export class SelectSuperCategory implements IAction {
  readonly type = SELECT_SUPER_CATEGORY;

  constructor(public payload: IBaseCategory) {}
}*/

export const selectSuperCategory = (payload: IBaseCategory): IAction => {
  return {
    type: SELECT_SUPER_CATEGORY,
    payload,
  };
};

export class SelectCategory implements IAction {
  readonly type = SELECT_CATEGORY;

  constructor(public payload: ISelectedCategory) {}
}

/*
export class SelectChannel implements IAction {
  readonly type = SELECT_CHANNEL;

  constructor(public payload: IChannel) {}
}
*/

export const selectChannel = (payload: IChannel): IAction => {
  return {
    type: SELECT_CHANNEL,
    payload,
  };
};

export class FilterChannelsByName implements IAction {
  readonly type = FILTER_CHANNELS_BY_NAME;

  constructor(public payload: string) {}
}

export class FilterChannelsByNumber implements IAction {
  readonly type = FILTER_CHANNELS_BY_NUMBER;

  constructor(public payload: string | number) {}
}

export class FilterChannelsByShowName implements IAction {
  readonly type = FILTER_CHANNELS_BY_SHOW_NAME;

  constructor(public payload: string | number) {}
}

export class SortChannelsByNumber implements IAction {
  readonly type = SORT_CHANNELS_BY_NUMBER;

  constructor(public payload: string = '') {}
}

export class ShowAllChannels implements IAction {
  readonly type = SHOW_ALL_CHANNELS;

  constructor(public payload: string) {}
}

export class LoadLiveChannels implements IAction {
  readonly type = LOAD_LIVE_CHANNELS;

  constructor(public payload: Array<IChannel>) {}
}

export class SelectChannelFromContextMenu implements IAction {
  readonly type = SELECT_CHANNEL_FROM_CONTEXT_MENU;

  constructor(public payload: IChannel) {}
}

export class SelectChannelForOnDemandList implements IAction {
  readonly type = SELECT_CHANNEL_FOR_ONDEMAND_LIST;
  constructor(public payload: IChannel) {}
}

/*export type ChannelListAction =
  | LoadSuperCategories
  | SelectSuperCategory
  | SelectCategory
  | SelectChannel
  | FilterChannelsByName
  | FilterChannelsByNumber
  | FilterChannelsByShowName
  | SortChannelsByNumber
  | ShowAllChannels
  | LoadLiveChannels
  | SelectChannelFromContextMenu
  | SelectChannelForOnDemandList;*/
