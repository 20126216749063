import { mergeMap, map, share } from 'rxjs/operators';
import { Observable }                 from "rxjs";
import * as _                         from "lodash";
import { IHttpRequestConfig }         from "../http/types/http.types";
import { IAlert, INotificationAlert } from "./alert.interface";
import {
    IProviderDescriptor,
    addProvider,
    ServiceEndpointConstants
}                                     from "../service";
import { HttpProvider }               from "../http";
import { Logger }                     from "../logger";
import {
    ModuleArea,
    ModuleAreaRequest
}                                     from "../http/types/module.list.request";
import { AlertConstants, AlertType }  from "./alert.constants";


/**
 * @MODULE:     service-lib
 * @CREATED:    03/02/18
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *      This delegate is responsible for making calls to update alerts.
 */
export class AlertDelegate
{
    /**
     * Internal logger.
     * @type {Logger}
     */
    private static logger: Logger = Logger.getLogger("AlertDelegate");

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(AlertDelegate, AlertDelegate, [ HttpProvider ]);
    }();

    /**
     * Constructor
     * @param  http - Used to make API calls
     */
    constructor(private http: HttpProvider) {}

    //TODO - Temporary Testing
    public getAlerts(): Observable<any>
    {
        AlertDelegate.logger.debug("getProfileData()");

        const params = {
            "gup-type"       : "alerts",
            format           : "json",
            "result-template": "", // http request interceptor will fill this in
            time             : "" // http request interceptor will fill this in
        };

        return this.http.get(ServiceEndpointConstants.endpoints.PROFILE.V2_GET_PROFILE_GUP_COLLATION, null, { params }).pipe(
                   map((response) =>
                   {
                       return response;
                   }),
                   share());
    }

    /**
     * Make API call to create new alert
     * @param {string} channelId
     * @param {string} assetGuid
     * @param {number} alertType
     * @returns {Observable<IAlert[]>}  An observable that can be subscribed to to get the
     * results of the API call.
     */
    public createAlert(channelId: string, assetGuid: string, alertType: number): Observable<IAlert[]>
    {
        AlertDelegate.logger.debug("updateProfile()");

        let request = {
            profileUpdateRequest: {
                gupAlertsCreateRequests: [
                    {
                        "alert": {
                            "active"    : true,
                            "alertType" : alertType,
                            "assetGUID" : assetGuid,
                            "deviceId"  : "",
                            "legacyId1" : channelId,
                            "legacyId2" : channelId,
                            "locationId": "0"
                        }
                    }
                ]
            }
        };

        const areaRequest = new ModuleAreaRequest("Profile", "GUPCollation", request);

        return this.http.postModuleAreaRequest(ServiceEndpointConstants.endpoints.PROFILE.V4_CREATE_ALERT, areaRequest, null).pipe(
                   map((response) =>
                   {
                       return this.normalizeAlerts(response);
                   }),
                   share());
    }

    /**
     * Make API call to remove the alert
     * TODO - API not working need to retest
     * @param {string} alertId
     * @param {string} assetGuid
     * @returns {Observable<IAlert[]>} An observable that can be subscribed to to get the
     * results of the API call.
     */
    public removeAlert(alertId: string, assetGuid: string, alertType: AlertType): Observable<IAlert[]>
    {
        AlertDelegate.logger.debug("removeAlert()");

        let request = {
            profileUpdateRequest: {
                gupAlertsUpdateRequests: [
                    {
                        "alert": {
                            "alertType" : alertType.toString(),
                            "active"    : false,
                            "alertId"   : alertId,
                            "assetGUID" : assetGuid,
                            "deviceId"  : "",
                            "locationId": "0"
                        }
                    }
                ]
            }
        };

        const areaRequest = new ModuleAreaRequest("Profile", "GUPCollation", request);

        return this.http.postModuleAreaRequest(ServiceEndpointConstants.endpoints.PROFILE.V4_REMOVE_ALERT, areaRequest, null).pipe(
                   map((response) =>
                   {
                       return this.normalizeAlerts(response);
                   }),
                   share());
    }

    /**
     * Remove all alerts
     * @param {IAlert[]} alerts
     * @returns {Observable<IAlert[]>}
     */
    public removeAllAlerts(alerts: IAlert[]): Observable<IAlert[]>
    {
        AlertDelegate.logger.debug("removeAlert()");

        let areaRequests = new Array<ModuleArea>();

        //TODO Vpaindla - Refactor this code once API supports remove all
        alerts.forEach(alert =>
        {
            const request =
                {
                    profileUpdateRequest: {
                        gupAlertsUpdateRequests: [
                            {
                                "alert":
                                    {
                                        "alertType" : +alert.alertType || 1,
                                        "active"    : false,
                                        "alertId"   : alert.alertId,
                                        "assetGUID" : alert.assetGuid,
                                        "deviceId"  : "",
                                        "locationId": "0"
                                    }
                            }
                        ]
                    }
                };

            areaRequests.push(new ModuleAreaRequest("Profile", "GUPCollation", request));
        });

        return this.http.postModuleAreaRequests(ServiceEndpointConstants.endpoints.PROFILE.V4_REMOVE_ALERT, areaRequests, null).pipe(
            map((response) =>
            {
                return this.normalizeAlerts(response);
            }),
            share());
    }

    /**
     * Makes API call to disable the alert.
     * @param {string} alertId
     * @returns {Observable<IAlert[]>}
     */
    public muteAlert(alertId: string): Observable<any>
    {
        AlertDelegate.logger.debug("muteAlert()");

        const config: IHttpRequestConfig = {
            params: {
                "ID"             : alertId,
                "result-template": "html5", // will be filled in by the request interceptor based on the runtime config
                "app-region"     : "" // will be filled in by the request interceptor based on the runtime config
            }
        };

        return this.http.get(ServiceEndpointConstants.endpoints.PROFILE.V4_MUTE_ALERTS, config, null).pipe(
                   mergeMap((response) =>
                   {
                       // TODO - Normalize process.
                       return response;
                   }),
                   share());
    }

    /**
     * Used to normalize the alert notification header.
     * @param response
     * @returns {Array<INotificationAlert>}
     */
    public normalizeNotificationHeader(response: any): Array<INotificationAlert>
    {
        if (!!response.payload) { response = response.payload; }

        if (!response || !response.replace) return [];

        response                         = response.replace(/\[/g, "");
        response                         = response.replace(/\]/g, "");
        response                         = response.replace(/\'/g, "");
        const notificationList: string[] = response.split("~");

        let notificationAlerts: INotificationAlert[] = [];

        notificationList.forEach((notificationAlert) =>
        {
            if (!notificationAlert || notificationAlert.indexOf(AlertConstants.ALERT_EMERGENCY_EJECT) > 0)
            {
                return;
            }

            const notificationItemList = notificationAlert.split(",");

            let normalizedAlert: INotificationAlert = {} as INotificationAlert;

            notificationItemList.forEach(notificationItem =>
            {
                const notificationAlert = notificationItem.split("=");
                const key               = notificationAlert[ 0 ];
                const value             = notificationAlert[ 1 ];

                switch (key)
                {
                    case AlertConstants.ALERT_NOTIFICATION_TYPE:
                    {
                        normalizedAlert.alertType = value ? value : "";
                        break;
                    }
                    case AlertConstants.ALERT_NOTIFICATION_ASSET_GUID:
                    {
                        normalizedAlert.assetGuid = value ? value : "";
                        break;
                    }
                    case AlertConstants.ALERT_NOTIFICATION_EPISODE_GUID:
                    {
                        normalizedAlert.episodeGuid = value ? value : "";
                        break;
                    }
                    case AlertConstants.ALERT_NOTIFICATION_EPISODE_START:
                    {
                        normalizedAlert.episodeStartDateTime = value ? value : "";
                        break;
                    }
                    case AlertConstants.ALERT_NOTIFICATION_EPISODE_END:
                    {
                        normalizedAlert.episodeEndDateTime = value ? value : "";
                        break;
                    }
                    case AlertConstants.ALERT_NOTIFICATION_CHANNEL:
                    {
                        normalizedAlert.channelNumber = value ? value : "";
                        break;
                    }
                    case AlertConstants.ALERT_NOTIFICATION_TITLE:
                    {
                        const title = value ? decodeURI(value).replace( /[^a-zA-Z0-9-'. ]/g, " ") : " ";
                        normalizedAlert.title = title ? title : "";
                        break;
                    }
                    case AlertConstants.ALERT_NOTIFICATION_CHANNEL_NAME:
                    {
                        const channelName = value ? decodeURI(value).replace( /[^a-zA-Z0-9-'. ]/g, " ") : " ";
                        normalizedAlert.channelName = channelName;
                        break;
                    }
                }
            });

            if(!normalizedAlert || !normalizedAlert.alertType)
            {
                return;
            }
            notificationAlerts.push(normalizedAlert);
        });

        return notificationAlerts;
    }

    /**
     * Normalizes the alerts
     * @param response
     * @returns {any}
     */
    private normalizeAlerts(response: any): IAlert[]
    {
        const alerts = _.get(response, "moduleDetails.alertList.alerts", []) as Array<any>;

        return alerts.map((alertItem) =>
        {
            if (alertItem.consumedAlertList && alertItem.consumedAlertList.length > 0)
            {
                alertItem.consumedAlerts = alertItem.consumedAlertList[ 0 ].consumedAlerts;
            }
            alertItem.assetGuid = alertItem.assetGUID;
            delete  alertItem.consumedAlerts;

            return alertItem;
        });
    }
}
