// TODO: BMR: Clean this up. Make the string enum creator function reusable. Comment this properly.

/** Utility function to create a K:V from a list of strings */
function strEnum<T extends string>(o: Array<T>): { [K in T]: K } {
  return o.reduce((res, key) => {
    res[key] = key;
    return res;
  }, Object.create({}));
}

/**
 * Sample create a string enum
 */

/** Create a K:V */
export const displayViewType = strEnum([
  'channels',
  'ondemand',
  'podcasts',
  'episodeView',
  'video',
  'show',
  'shows', //ondemand changed to shows
  'audio',
]);

/** Create a Type */
export type displayViewType = keyof typeof displayViewType;
