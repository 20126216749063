import { share, map } from 'rxjs/operators';
import * as _                from "lodash";
import { Observable }        from "rxjs";
import {
    IImage,
    IShowImage
}                            from "../service/types/image.interface";
import {
    IChannelLineup,
    ILiveChannels
}                            from "./channel.lineup.interface";
import {
    IAodEpisode,
    IAodEpisodeDuration,
    IAodEpisodeAirDate
}                            from "./audio.ondemand.interfaces";
import {
    ISuperCategory,
    ISubCategory
}                            from "./categories.interfaces";
import { IVodEpisode }       from "./video.ondemand.interfaces";
import { IAppConfig }        from "../config/interfaces/app-config.interface";
import { IChannel }          from "./channels.interfaces";
import {
    IProviderDescriptor,
    addProvider
}                            from "../service";
import {
    IHttpRequestConfig,
    HttpProvider
}                            from "../http";
import { Logger }            from "../logger";
import { ModuleAreaRequest } from "../http";
import {
    DiscoverAssetsConsts,
    DiscoverChannelListConsts,
    DiscoverOnDemandConsts,
    ServiceEndpointConstants
}                            from "../service/consts";
import { ContentTypes }      from "../service/types";
import { IOnDemandShow }     from "./ondemand.interfaces";

/**
 * @MODULE:     service-lib
 * @CREATED:    07/27/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *      This delegate is responsible for making the channel lineup call and returning the Channels data.
 */
export class ChannelLineupDelegate
{
    /**
     * Internal logger.
     * @type {Logger}
     */
    private static logger: Logger = Logger.getLogger("ChannelLineupDelegate");

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor : IProviderDescriptor = function()
    {
        return addProvider( ChannelLineupDelegate, ChannelLineupDelegate, [ HttpProvider, "IAppConfig" ]);
    }();

    public static XTRA_CHANNEL = "sequencer";
    public static LIVE_CHANNEL = "standard";

    /**
     * Constructor
     * @param http - Used to make API calls
     * @param SERVICE_CONFIG contains runtime configuration parameters, including the resultTemplate for the app
     */
    constructor(private http: HttpProvider,
                private SERVICE_CONFIG: IAppConfig) {}

    /**
     * Make the HTTP API call to get the application Channels and return it as a known domain model.
     * @returns {Observable<any>}  an observable that can be subscribed to to get the results of the API call
     */
    public getChannelLineup(): Observable<IChannelLineup>
    {
        ChannelLineupDelegate.logger.debug("getChannellineup()");

        const request     = { resultTemplate: "responsive" };
        const areaRequest = new ModuleAreaRequest("Discovery", "ChannelListing", request);
        const config      = { params: { "type": 2 } };

        return this.http.postModuleAreaRequest(ServiceEndpointConstants.endpoints.CHANNEL.V4_COMMON_GET, areaRequest, config).pipe(
                   map((response) => ChannelLineupDelegate.normalizeLineup(response.contentData.channelListing)),
                   share());
    }

    /**
     * Make the HTTP API call to get the list of live channels and the content that is currently playing on each
     * live channel.
     * @returns {Observable<T>} an observable that can be subscribed to to get the results of the API call
     */
    public discoverChannelList(): Observable<ILiveChannels>
    {
        const params = _.cloneDeep(DiscoverChannelListConsts.PARAMS);

        const config: IHttpRequestConfig = { params: params };

        return this.http.get(ServiceEndpointConstants.endpoints.CHANNEL.V2_GET_LIST, null, config).pipe(
                   map((response) =>
                   {
                       return {
                           updateFrequency: response.updateFrequency,
                           channels       : response.moduleDetails.liveChannelResponse.liveChannelResponses
                       };
                   }),
                   share());
    }

    /**
     * Make an HTTP API call to get the list of on demand shows for a given subcategory within a given supercategory
     * @param superCategory the name of the supercategory to make the API call for
     * @param subCategory the name of the subcategory to make the API call for
     * @returns the list of shows that belong to the given supercategory->subcategory
     */
    public discoverOnDemand(superCategory: string, subCategory: string): Observable<Array<IOnDemandShow>>
    {
        const params = _.cloneDeep(DiscoverOnDemandConsts.V4_PARAMS);

        params[ DiscoverOnDemandConsts.DISCOVER_ONDEMAND_SUPERCATEGORY ] = superCategory;
        params[ DiscoverOnDemandConsts.DISCOVER_ONDEMAND_SUBCATEGORY ]   = subCategory;

        const config: IHttpRequestConfig = { params: params };

        return this.http.get(ServiceEndpointConstants.endpoints.ON_DEMAND.V4_GET_SHOWS_EPISODES, null, config).pipe(
                   map((response: any) : Array<IOnDemandShow> =>
                   {
                       const aodShows = _.get(response, "contentData.aodShows[0].shows") as Array<IOnDemandShow>;
                       const vodShows = _.get(response, "contentData.vodShows[0].shows") as Array<IOnDemandShow>;
                       const platform = this.SERVICE_CONFIG.resultTemplate;

                       return normalizeOnDemand(aodShows,vodShows,platform);
                   }),
                   share());

        /**
         * Take an array of on demand shows with audio, and a similiar array with video, and combine them into
         * a single array that has shows with a combination of aod and vod episodes.
         *
         * @param aodShows array of shows with audio on demand episodes in them
         * @param vodShows array of shows with video on demand episodes in them
         * @param platform string used in the normalization functions for on demand
         * @returns {Array<IOnDemandShow>} array of shows with audio, video or both type of episodes
         */
        function normalizeOnDemand(aodShows : Array<IOnDemandShow>,
                                   vodShows : Array<IOnDemandShow>,
                                   platform : string) : Array<IOnDemandShow>
        {
            aodShows = Array.isArray(aodShows) ? aodShows : [];
            vodShows = Array.isArray(vodShows) ? vodShows : [];

            aodShows.forEach((show:IOnDemandShow) =>
                ChannelLineupDelegate.normalizeAudioShow(show, platform));

            vodShows.forEach((show:IOnDemandShow) =>
                ChannelLineupDelegate.normalizeVideoShow(show));

            return ChannelLineupDelegate.combineAodAndVod(aodShows as Array<IOnDemandShow>,
                vodShows as Array<IOnDemandShow>);
        }
    }

    /**
     * Make an HTTP call to discover aodEpisodes available for a given showGuid
     * @param show is the AOD show to discover assets for
     * @returns {Observable<T>}
     */
    public discoverOnDemandAssets(show: IOnDemandShow): Observable<IOnDemandShow>
    {
        const params = _.cloneDeep(DiscoverAssetsConsts.PARAMS);

        params.assetType = DiscoverAssetsConsts.DISCOVER_ONDEMAND_SHOW;
        params.assetGUID = show.guid;

        const config: IHttpRequestConfig = { params: params };

        return this.http.get(ServiceEndpointConstants.endpoints.EDP.V4_GET_EDP_DETAILS, null, config).pipe(
                   map((response: any) =>
                   {
                       if (response.contentData.vodEpisodes)
                       {
                           show.vodEpisodes = response.contentData.vodEpisodes.map((episode: any) =>
                               ChannelLineupDelegate.normalizeVodEpisode(episode as IVodEpisode, show));
                       }

                       return show;
                   }),
                   share());
    }

    /**
     * Fixup the data structures in the lineup to get rid of redundancies, flatten objects and get better naming
     * conventions
     * @param lineup is the channel lineup
     * @returns {any} normalized lineup
     */
    public static normalizeLineup(lineup: any)
    {
        lineup.superCategories = ChannelLineupDelegate.normalizeSuperCategories(lineup.superCategories);
        lineup.channels.forEach((channel: IChannel) => ChannelLineupDelegate.normalizeChannel(channel));
        return lineup;
    }

    /**
     * Channels that come back from the API have certain redundancies that get taken care of by this function
     * @param channel to normalize
     * @returns {Array<IChannel>}
     */
    public static normalizeChannel(channel: IChannel): IChannel
    {
        channel.channelNumber       = parseInt(String(channel.channelNumber));
        channel.xmChannelNumber     = parseInt(String(channel.xmChannelNumber));
        channel.siriusChannelNumber = parseInt(String(channel.siriusChannelNumber));
        channel.imageList           = channel.images.images as Array<IImage>;
        channel.categoryList        = channel.categories.categories;
        channel.type                = (channel.tuneMethod === ChannelLineupDelegate.XTRA_CHANNEL)
                                      ? ContentTypes.ADDITIONAL_CHANNELS : ContentTypes.LIVE_AUDIO;
        channel.shows               = channel.shows || [];
        channel.isGeoRestricted = channel.geoRestrictions == 1 ? true : false;

        const images = {};

        if (channel.imageList)
        {
            channel.imageList.forEach((image : IImage) =>
            {
                let propertyName = image.name.replace(/\(|\)/g,"");
                propertyName = propertyName.replace(/ (\w)/g,(match : string) =>
                {
                    return match.replace(" ","").toUpperCase();
                });

                if (!images[propertyName] || (image.height > images[propertyName].height))
                {
                    images[propertyName] = image;
                }
            });
        }

        delete channel.geoRestrictions;
        delete channel.categories;
        delete channel.images;

        channel.artwork = images as any;

        return channel;
    }

    /**
     * Super categories and categories that come back from the API have certain redundancies that get taken care of
     * by this function.
     * @param superCategories
     * @returns {Array<ISuperCategory>}
     */
    public static normalizeSuperCategories(superCategories: Array<ISuperCategory>): Array<ISuperCategory>
    {
        superCategories.forEach((superCategory: ISuperCategory, index: number) =>
        {
            superCategory.imageList    = (superCategory.images) ? superCategory.images.images : [];
            superCategory.categoryList = ChannelLineupDelegate.normalizeSubCategories(superCategory.categories.categories);

            delete superCategory.images;
            delete superCategory.categories;

            superCategory.categoryList.forEach((subCategory: ISubCategory) =>
                ChannelLineupDelegate.normalizeDuplicateSubCategories(subCategory, superCategories, index + 1));

        });

        return superCategories;
    }

    /**
     * Sub categories that come back from the API have certain redundancies that get taken care of by this function.
     * @param subCategories
     * @returns {Array<ISubCategory>}
     */
    public static normalizeSubCategories(subCategories: Array<ISubCategory>): Array<ISubCategory>
    {
        if (subCategories)
        {
            subCategories.forEach((subCategory: ISubCategory) =>
            {
                if (!subCategory.imageList)
                {
                    subCategory.imageList = _.get(subCategory,"images.images",[]);
                    delete subCategory.images;
                }
                if (!subCategory.channelList)
                {
                    subCategory.channelList = _.get(subCategory,"channels.channels",[]) as Array<IChannel>;
                    delete subCategory.channels;
                }
            });

            return subCategories;
        }

        return [] as Array<ISubCategory>;
    }

    /**
     * Multiple supercategories can contain the "same" subcategory.  This means we get duplicate subcategories
     * in the api response.  Here, we replace the dups with the first occurrence of the given subcategory.  This
     * way, when we update the live channel data for that subcategory all the dups will get updated as well.
     * @param subCategory is the subcategory that we want to find duplicates for
     * @param superCategories is the list of supercategories available
     * @param searchFrom indicates which supercategory to start searching for duplicates from
     */
    public static normalizeDuplicateSubCategories(subCategory: ISubCategory,
                                                  superCategories: Array<ISuperCategory>,
                                                  searchFrom: number)
    {
        superCategories.forEach(replaceDuplicateSubCategories);

        function replaceDuplicateSubCategories(superCategory: ISuperCategory, index: number)
        {
            if (index < searchFrom)
            {
                return;
            }

            let foundSubIndex: number =
                    _.findIndex(superCategory.categories.categories,
                        (cat: ISubCategory) => cat.categoryGuid === subCategory.categoryGuid);

            if (foundSubIndex >= 0)
            {
                superCategory.categories.categories[ foundSubIndex ] = subCategory;
            }
        }
    }

    /**
     * The API delivers shows with some strange formatting that requires unnecessary
     * property dereferences to get to the relevant data.  This fixes that
     * @param show is the show as delivered, will be normalized nto a IOnDemandShow
     */
    public static normalizeShow(show: any): IOnDemandShow
    {
        show.episodes                = (show.episodes.length >= 1) ? show.episodes[ 0 ].episodes : [];
        show.episodes                = (show.episodes) ? show.episodes : [];

        show.showDescription.images  = show.showDescription.creativeArts;
        show.showDescription.shortId = (show.showDescription.legacyIds)
                                       ? show.showDescription.legacyIds.shortId : "";
        show.type = ContentTypes.SHOW;

        const images = {};

        if (show.showDescription.images)
        {
            show.showDescription.images.forEach((image : IShowImage) =>
            {
                let propertyName = image.name.replace(/\(|\)/g,"");
                propertyName = propertyName.replace(/ (\w)/g,(match : string) =>
                {
                    return match.replace(" ","").toUpperCase();
                });

                if (!images[propertyName] || (image.height > images[propertyName].height))
                {
                    images[propertyName] = image;
                }
            });
        }

        show.artwork = images;

        delete show.showDescription.creativeArts;
        delete show.showDescription.legacyIds;

        return show;
    }

    /**
     * The API delivers shows with some strange formatting that requires unnecessary
     * property dereferences to get to the relevant data.  This fixes that
     * @param show is the show as delivered, will be normalized nto a IOnDemandShow
     * @param platform is the platform we are running on.
     */
    public static normalizeAudioShow(show: any, platform: string): IOnDemandShow
    {
        show = ChannelLineupDelegate.normalizeShow(show);

        show.aodEpisodes = show.episodes.map((episode) => ChannelLineupDelegate.normalizeAodEpisode(episode,
                                                                                                    platform,
                                                                                                    show));
        delete show.episodes;

        // Merge properties from the show description object into the show itself
        Object.keys(show.showDescription).forEach((key: string) => show[ key ] = show.showDescription[ key ]);

        //TODO : Remove guid from the IOnDemandShow once we use assetGUID completely
        show.assetGUID               = show.guid;

        // Sorting episodes by originalAirDate
        show.aodEpisodes = this.sortEpisodes(show.aodEpisodes);
        return show;
    }

    /**
     * The API delivers shows with some strange formatting that requires unnecessary
     * property dereferences to get to the relevant data.  This fixes that
     * @param show is the show as delivered, will be normalized nto a IOnDemandShow
     */
    public static normalizeVideoShow(show: any): IOnDemandShow
    {
        show = ChannelLineupDelegate.normalizeShow(show);

        show.vodEpisodes = show.episodes;
        show.vodEpisodes.forEach((episode) => ChannelLineupDelegate.normalizeVodEpisode(episode, show));

        delete show.episodes;

        // Merge properties from the show description object into the show itself
        Object.keys(show.showDescription).forEach((key: string) => show[ key ] = show.showDescription[ key ]);

        //TODO : Remove guid from the IOnDemandShow once we use assetGUID completely
        show.assetGUID               = show.guid;

        // Sorting episodes by originalAirDate
        show.vodEpisodes = this.sortEpisodes(show.vodEpisodes);

        return show;
    }

    /**
     * Take a list of shows with video on demand content, and
     *
     * 1) If a show with the same guid exists on the list of shows with audio on demand content, add the video episodes
     *    to that show.
     *
     *    Since we are creating a single list of shows that have aod, vod, or both types of episodes,
     *    we need to change the vod episodes to point to the aodShow, because those episodes now belong to the aodShow
     *
     * 2) If a show with the same giud does not exist on the list of shows with video on demand content, then add the
     *    show with video on demand content to the list of shows with audio on demand content.
     *
     * Return the list of shows with audio on demand content to the caller.  This will become a unified list of shows
     * with audio on demand content, or video on demand content, or both.
     *
     * @param aodShows is a list of shows that have audio on demand content
     * @param vodShows is a list of shows that have video on demand content
     * @returns {Array<IOnDemandShow>} a list of shows that heve either audio or video on demand or both.
     */
    public static combineAodAndVod(aodShows : Array<IOnDemandShow>,
                                   vodShows : Array<IOnDemandShow>) : Array<IOnDemandShow>
    {
        vodShows.forEach((vodShow : IOnDemandShow) =>
        {
            const aodShow:IOnDemandShow = aodShows.find((show:IOnDemandShow) => show.guid === vodShow.guid);

            if ( aodShow )
            {
                aodShow.vodEpisodes = vodShow.vodEpisodes;
                aodShow.vodEpisodes.forEach((vodEpisode: IVodEpisode) =>
                {
                    // This episode now belongs to the "aod" version of the show, so make sure that is reflected in the
                    // episode
                    vodEpisode.show = aodShow;
                });
            }
            else
            {
                aodShows.push(vodShow);
            }
        });

        // Make sure all shows have an aodEpisodes and vodEpisodes array.  Will be empty if there are no episodes
        // of the given type available
        aodShows.forEach((show : IOnDemandShow) =>
        {
            if ( !show.aodEpisodes )
            { show.aodEpisodes = [] as Array<IAodEpisode>; }
            if ( !show.vodEpisodes )
            { show.vodEpisodes = [] as Array<IVodEpisode>; }
        });

        return aodShows;
    }

    /**
     * Perform normalization of the AOD aodEpisode data
     * @param episode is the aodEpisode to normalize
     * @param platform is the platform we are running on
     * @param show is the show the aodEpisode belongs to
     */
    public static normalizeAodEpisode(episode: any, platform: string, show: any): IAodEpisode
    {
        episode.type           = ContentTypes.AOD;
        episode.show           = show;
        episode.contentUrlList = episode.contentUrlList.contentUrls; // make this flatter
        episode.assetGuid      = episode.episodeGUID;
        episode.episodeGuid    = episode.aodEpisodeGuid;
        episode.favoriteEpisodeCAId = episode.publicationInfo.accessControlIdentifier;

        // Don't need to delete this one since it's a straight replace of the same prop.
        episode.images         = _.get(episode, "images.images", null);

        episode.originalAirDate = episode.originalAirDate ? episode.originalAirDate : "";

        ChannelLineupDelegate.normalizeEpisodeTimes(episode);

        delete episode.aodEpisodeGuid;
        delete episode.episodeGUID;

        return episode;
    }

    /**
     * Perform normalization of the VOD aodEpisode data
     * @param episode is the aodEpisode to normalize
     * @param show is the show that the VOD aodEpisode belongs to
     */
    public static normalizeVodEpisode(episode: any, show: IOnDemandShow): IVodEpisode
    {
        episode.type        = ContentTypes.VOD;
        episode.hosts       = episode.humanHostList;
        episode.topics      = episode.humanTopicList;
        episode.episodeGuid = episode.vodEpisodeGUID;

        // Don't need to delete this one since it's a straight replace of the same prop.
        episode.images      = _.get(episode, "images.images", null);

        episode.originalAirDate = episode.originalAirDate ? episode.originalAirDate : "";

        delete episode.humanHostList;
        delete episode.humanTopicList;
        delete episode.vodEpisodeGUID;
        delete episode.episodeGUID;

        episode.hostList  = episode.hosts ? episode.hosts.split(",") : [];
        episode.topicList = episode.topics ? episode.topics.split(",") : [];

        episode.show = show;

        return episode as IVodEpisode;
    }

    /**
     * Normalize the aodEpisodes duration and airdate information to be more useful for UI code
     * @param episode is the aodEpisode to normalize
     */
    public static normalizeEpisodeTimes(episode: IAodEpisode)
    {
        if (episode.duration)
        {
            const numbers = episode.duration.match(/\d+/g);

            const days           = parseInt(numbers[ 0 ]);
            const hours          = parseInt(numbers[ 1 ]);
            const minutes        = parseInt(numbers[ 2 ]);
            const seconds        = parseInt(numbers[ 3 ]);
            const milliseconds   = parseInt(numbers[ 4 ]);
            let duration: number = (days * 86400) + (hours * 3600) + (minutes * 60) + seconds;

            episode.episodeLength = {
                days        : days,
                hours       : hours,
                minutes     : minutes,
                seconds     : seconds,
                milliseconds: milliseconds,
                totalSeconds: duration
            } as IAodEpisodeDuration;
        }

        const airDate = new Date(episode.originalAirDate);

        episode.airDate = {
            year        : airDate.getFullYear(),
            day         : airDate.getDate(),
            dayofWeek   : airDate.getDay(),
            hour        : airDate.getHours(),
            minutes     : airDate.getMinutes(),
            seconds     : airDate.getSeconds(),
            milliseconds: airDate.getMilliseconds()
        } as IAodEpisodeAirDate;
    }

    /**
     * sort both AOD/VOD Episodes based on originalAirDate
     * @param episodes is arrays of aodEpisodes/vodEpisodes
     */
    public static sortEpisodes(episodes: Array<IAodEpisode | IVodEpisode>):Array<IAodEpisode | IVodEpisode>
    {
        return _.orderBy(episodes,'originalAirDate', ['desc']);
    }
}
