export class ConfigConstants
{
    public static AUTH_URL = "AuthUrl";
    public static ON_BOARDING = "OnBoarding";
    public static FAQ = "FAQURL";
    public static FEEDBACK = "FeedbackUrl";
    public static FORGOT_LINK = "ForgotLink";
    public static FLEPZ = "FLEPZ";
    public static RECOVERY = "Recovery";
    public static RESET_PASSWORD = "resetPassword";
    public static OPEN_ACCESS = "OpenAccessParameters";
    public static PRIVACY_POLICY = "PrivacyPolicy";
    public static RESET_PASSWORD_CONFIG_NAME = "URL";
    public static RESET_PASSWORD_CONFIG_NAME_US = "allPartners";
    public static GET_STARTED = "getStarted";
    public static URL = "URL";
    public static PRIVACY_POLICY_CONFIG_NAME = "URL";
    public static CUSTOMER_AGREEMENT_CONFIG_NAME = "URL";
    public static CUSTOMER_AGREEMENT = "CustomerAgreement";
    public static LIVE_VIDEO = "LiveVideo";
    public static APP_DOWNLOAD_URL = "AppDownloadUrl";
    public static PLATFORM = "platform";
    public static APP_DOWNLOAD_URL_NAME = "URL";
    public static APP_IOS = "IOS";
    public static APP_WINDOWS = "WINDOWS";
    public static APP_ANDROID = "ANDROID";
    public static LOCALE = "locale";
    public static AUTOPLAY_DELAY = "delayAutoplayNextEpisode";
    public static NU_DETECT = "Nudetect";
    public static SEEDED_RADIO_BACKGROUND_URL = "SeededRadioBackgroundUrl";
    public static SEEDED_RADIO_FALLBACK_URL = "SeededRadioFallbackUrl";
    public static CONSUME = "Consume";
    public static USER_DAYS_LISTENED_DEFAULT_THRESHOLD = 5;
    public static FREE_TIER_FEATURE = "freeTierFeature";
}
