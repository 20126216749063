import { AppMonitorService } from "../app-monitor/app-monitor.service";
import { BypassMonitorService } from "../app-monitor/bypass-monitor.service";

import { of as observableOf,  BehaviorSubject, Observable } from 'rxjs';
import { switchMap, share, catchError, mergeMap, flatMap, filter, map } from 'rxjs/operators';

import {
    IProviderDescriptor,
    IResumeMedia,
    addProvider,
    LiveTime,
    ContentTypes,
    Logger,
    ServiceEndpointConstants,
    TuneResponse,
    HTTP_NETWORK_ERROR,
    HTTP_ERROR_MESSAGE,
    findMediaItemByTimestamp,
    VideoPlayerConstants,
    LIVE_VIDEO_DEEPLINK_TYPE,
    ConfigService,
    ClientCodes,
    DeepLinkTypes,
    IRelativeUrlSetting,
    IAppByPassState,
    ApiCodes,
    IAppConfig,
    AudioPlayerConstants
} from "../index";
import { MediaUtil }          from "../mediaplayer/media.util";

//NOTE:  ResumeService has to be imported directly from its file and not "../index"
import { ResumeService } from "../resume/resume.service";
import { TuneDelegate } from "./tune.delegate";
import {
    IMediaEndPoint,
    IMediaVideo,
    MediaTimeLine, TunePayload
} from "./tune.interface";
import { StorageService } from "../storage/storage.service";
import { StorageKeyConstant } from "../storage/storage.constants";
import { AppErrorCodes } from "../service/consts/app.errors";
import { ChannelLineupService } from "../channellineup";
import { GeolocationService } from "../geolocation";
import { SessionTransferService } from "../sessiontransfer/session.transfer.service";
import { PlayerTypes } from "../service/types/content.types";
import { ChromecastModel } from "../chromecast/chromecast.model";
import { TuneModel } from "./tune.model";
import {MediaPlayerModel} from "../mediaplayer/media-player.model";

interface TuneAudioFunction
{
    tune(payload: TunePayload);
}

/**
 * @MODULE:     service-lib
 * @CREATED:    09/13/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * TuneService for tuning Live channel, AOD Channel and Does the periodical calls to do update the tune the
 * live chanel. Listen to the resume service response and updates the tuning Live and AOD.
 */
export class TuneService implements TuneAudioFunction
{
    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("TuneService");

    /**
     * An observable (hot, subscribe returns most recent item) that can be used to obtain the MediaTimeLine
     * and be notified when the MediaTimeLine data changes.
     * @type {any}
     */
    public tuneMediaTimeLine: Observable<MediaTimeLine>;

    /**
     * The media time line that represents the current now playing timeLine for live/AOD.
     * @type {any}
     */
    private tuneMediaTimeLineData: MediaTimeLine = null;

    /**
     * subject for delivering media timeLine through the MediaTimeLine observable.
     * @type {any}
     */
    private tuneMediaTimeLineSubject: BehaviorSubject<MediaTimeLine> = null;

    /*
     * Interval timer for handling periodic tune live channel update requests
     */
    private tuneLiveInterval: any = 0;

    /*
     * The default period of the tune live channel requests in milliseconds
     */
    public static DEFAULT_LIVE_UPDATE_TUNE_QUERY_PERIOD_MS: number = 100000;

    /*
     * The periodicity of the tune live channel requests in milliseconds
     */
    private liveUpdateTuneIntervalPeriodMs: number = TuneService.DEFAULT_LIVE_UPDATE_TUNE_QUERY_PERIOD_MS;

    /**
     * Contains live time value in both the zero-based and zulu-based milliseconds and seconds.
     */
    private liveTime: LiveTime = null;

    /**
     * Contains the player type is local or remote
     */
    private playerType: string = PlayerTypes.LOCAL;

    /**
     * Flag indicates aic is in bypass mode or not.
     */
    private aicByPassMode: boolean = false;

    /**
     * Flag indicated ArtistRadio/Seeded is in bypass mode or not
     */
    private artistRadioByPassMode: boolean = true;

    /**
     * Behavior Subject to set adsWizz status for every tune call
     * @type {any}
     */
    public enableAdsWizz$ = new BehaviorSubject(false);

    /**
     * Boolean to tell media player to retune to same channel, as Audio player considers
     * retune as noop, this property needs to reset to false.
     * @type {Boolean}
     */
    public forceRetune: boolean = false;

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(TuneService, TuneService,
            [ ResumeService,
              ChannelLineupService,
              TuneDelegate,
              StorageService,
              GeolocationService,
              AppMonitorService,
              ConfigService,
              SessionTransferService,
              ChromecastModel,
              BypassMonitorService,
              TuneModel,
              "IAppConfig",
              MediaPlayerModel
            ]);
    }();

    /**
     * Constructor - It also does listen to the resume service response , once the data changed in resume service,
     * Observable trigger and notifies under TuneService.
     * @param resumeService gives us access to the media timeline from the resume service
     * @param channelLineupService gives us access to the channel lineup
     * @param {TuneDelegate} nowPlayingDelegate is used to make now playing API calls
     * @param {StorageService} storageService
     * @param {GeolocationService} geolocationService geolocationService determines if current location is authorized
     *     for playback
     * @param {AppMonitorService} appMonitorService
     * @param {ConfigService} configService
     * @param {SessionTransferService} sessionTransferService
     * @param {chromecastModel} ChromecastModel
     */
    constructor(private resumeService: ResumeService,
                private channelLineupService: ChannelLineupService,
                private nowPlayingDelegate: TuneDelegate,
                private storageService: StorageService,
                private geolocationService: GeolocationService,
                private appMonitorService: AppMonitorService,
                private configService: ConfigService,
                private sessionTransferService: SessionTransferService,
                private chromecastModel: ChromecastModel,
                private bypassMonitorService: BypassMonitorService,
                private tuneModel: TuneModel,
                private SERVICE_CONFIG: IAppConfig,
                private mediaPlayerModel: MediaPlayerModel)
    {
        if(this.SERVICE_CONFIG.adsWizzSupported)
        {
            this.enableAdsWizz$.next(true);
        }
        this.mediaPlayerModel.bufferUnderFlow$.pipe(
            filter((val: boolean)  => !!val)
        ).subscribe(() =>
        {
            // if audio player dispatches audio_underflow event and if the current channel we are tuned to is
            // adsEligible, we tune to the same channel disabling the adsEligible flag.
           if(this.enableAdsWizz$.getValue() && MediaUtil.isLiveMediaType(this.tuneMediaTimeLineData.mediaType))
            {
                this.enableAdsWizz$.next(false);
                this.forceRetune = true;
                this.retune();
            }
        });

        this.mediaPlayerModel.restart$.pipe(
            filter(state => !!state)
        ).subscribe(state =>
        {
            // if audio player dispatches restart event for any reason (can be chunk http error) and
            // if the current channel we are tuned to is adsEligible, we tune to the same channel disabling the adsEligible flag.
            if(this.enableAdsWizz$.getValue())
            {
                this.enableAdsWizz$.next(false);
                this.forceRetune = true;
            }
            this.retune();
        });

        // Tell app monitor service Ignore FLTT_SR_SOURCE_ENDED, content errors for seeded refresh track tune calls
        // (only).These will be retried up to three times before we declare failure
        appMonitorService.ignoreFault(ApiCodes.FLTT_SR_SOURCE_ENDED,
                                      ServiceEndpointConstants.endpoints.SEEDED_RADIO.V4_TUNE);

        this.tuneMediaTimeLineSubject = new BehaviorSubject(this.tuneMediaTimeLineData);
        this.tuneMediaTimeLine    = this.tuneMediaTimeLineSubject;

        resumeService.resumeMedia.pipe(
                     filter((resumeMedia: IResumeMedia) => !!resumeMedia && !!resumeMedia.type),
                     switchMap(resumeMedia =>
                        {
                         if(resumeMedia.type.toLowerCase() !== ContentTypes.SEEDED_RADIO)
                         {
                            return this.geolocationService.checkContent(
                                resumeMedia.data.channelId ?  resumeMedia.data.channelId : '',
                                resumeMedia.type.toLowerCase() === ContentTypes.LIVE_AUDIO ? '' : resumeMedia.data.accessControlIdentifier,
                                true,
                                resumeMedia.type.toLowerCase() === ContentTypes.LIVE_AUDIO
                                    || resumeMedia.type.toLowerCase() === ContentTypes.LIVE_VIDEO
                                ).pipe(
                                map(contentIsAllowed =>
                                {
                                    return [ contentIsAllowed, resumeMedia ];
                                }));
                        }
                        else
                        {
                            return observableOf([ ClientCodes.SUCCESS, resumeMedia ]);
                        }
                    }))
                     .subscribe(([ contentIsAllowed, resumeMedia ]: [ number, IResumeMedia ]) =>
                     {
                         this.setMediaData(resumeMedia);

                         this.tuneModel.currentMetaData = {
                             mediaType: this.tuneMediaTimeLineData.mediaType,
                             mediaId: this.tuneMediaTimeLineData.mediaId,
                             channelId: this.tuneMediaTimeLineData.channel ?
                                        this.tuneMediaTimeLineData.channel.channelId :
                                        this.tuneMediaTimeLineData.channelId,
                             streamingAdsId: this.tuneMediaTimeLineData.streamingAdsId
                         };

                         // If the media is live audio then we need to kickoff the periodic updates.
                         // and the content is not geo restricted
                         if (contentIsAllowed === ClientCodes.SUCCESS
                             && resumeMedia.type.toLowerCase() === ContentTypes.LIVE_AUDIO.toLowerCase())
                         {
                             this.startPeriodicLiveUpdateRequests(resumeMedia.updateFrequency, resumeMedia.data.channelId,
                                                                  this.tuneMediaTimeLineData.streamingAdsId);
                         }
                     });

        this.bypassMonitorService.bypassErrorState.subscribe((state : IAppByPassState) =>
        {
             this.artistRadioByPassMode = state.ARTIST_RADIO_BYPASS;
             this.aicByPassMode = state.AIC_BYPASS;
        });
    }

    /**
     * Retune whatever is currently playing.  This function will bypass all the internal checks that prevent us from
     * tuning content that is already tuned.  It will look at the currentlyPlayingMediaData object and determine what
     * was last tuned successfully, and will retune that content.  As long as the retunes fail, it will keep trying
     * again and again.
     *
     * This is intended as a mechanism to recover from media playback loss.  The retune, when successful will
     * reestablish the media time-line and can trigger media playback to start up again.
     *
     * @returns {Observable<boolean>}
     */
    public retune(mediaTimeLine?: MediaTimeLine): Observable<boolean>
    {
        const oldMediaTimelineData = this.tuneMediaTimeLineData || mediaTimeLine;

        if (oldMediaTimelineData)
        {
            const channelId: string = oldMediaTimelineData.channelId;
            const mediaType: string = oldMediaTimelineData.mediaType;
            const mediaId: string   = oldMediaTimelineData.mediaId;
            const isLive: boolean   = MediaUtil.isLiveMediaType(mediaType);
            const episodeGuid       = !isLive
                ? mediaId
                : undefined;

            this.tuneMediaTimeLineData = null;

            TuneService.logger.debug(`retune( channelId: ${channelId}, contentType: ${mediaType}, episodeGuid: ${episodeGuid} )`);

            const payLoad: TunePayload =
                      {
                          channelId : channelId,
                          contentType: mediaType,
                          episodeIdentifier: episodeGuid,
                          isPandoraPodcast: MediaUtil.isPodcastMediaType(mediaType)
                      };
            const obs = this.tune(payLoad, true).pipe(
                            mergeMap((result: number) =>
                            {
                                if (result !== ClientCodes.SUCCESS && !this.tuneMediaTimeLineData)
                                {
                                    TuneService.logger.error("Retune failed ... retry");
                                    this.tuneMediaTimeLineData = oldMediaTimelineData;
                                }

                                return observableOf(true);
                            }));

            obs.subscribe((result: boolean) =>
            {
                TuneService.logger.debug(`retune( Content has ${result ? "been" : "not been"} retuned. )`);
            });
        }

        return observableOf(false);
    }

    /**
     * Tune content.  Figures out what we are tuning (live audio, audio on demand, video on demand), and performs
     * geolocation if necessary, and then tunes the requested content
     *
     * @param {TunePayload} payload - tune payload for tuning.
     *
     * @returns Observable that resolves to either true or false, with true meaning the tune call was successful
     */
    public tune(payload: TunePayload, fromRetune = false): Observable<number>
    {
        if(!this.isPlayingContentSupported(payload.contentType))
        {
            return observableOf(ClientCodes.CONTENT_NOT_SUPPORTED_ON_REMOTE_PLAYER);
        }

        if(this.isArtistRadioOnByPass(payload.contentType))
        {
            return observableOf(ClientCodes.ARTIST_RADIO_IN_BYPASS_MODE);
        }

        if(this.isAICOnByPassMode(payload.contentType))
        {
            return observableOf(ClientCodes.AIC_IN_BYPASS_MODE);
        }

        //Delete the Pause points when we tune
        this.storageService.removeItem(StorageKeyConstant.PAUSEPOINT);

        const channelId = payload.channelId;
        const showGuid = payload.showGuid;
        const cutAssetGuid = payload.cutAssetGuid;
        const contentType = payload.contentType;
        const episodeIdentifier = payload.episodeIdentifier || null;
        const startTime = payload.startTime || 0;
        const isPandoraPodcast = payload.isPandoraPodcast;

        const isAdditionalChannelTune = contentType === ContentTypes.ADDITIONAL_CHANNELS;
        const isSeededRadioTune = contentType === ContentTypes.SEEDED_RADIO;
        const channel = !isAdditionalChannelTune ? this.channelLineupService.findChannelById(channelId) : null;

        if (channel || isAdditionalChannelTune || isSeededRadioTune || isPandoraPodcast)
        {
            TuneService.logger.debug(`tune( channelId: ${channelId}, contentType: ${contentType}, episodeIdentifier: ${episodeIdentifier} )`);

            let assetGuid = (!episodeIdentifier && !isAdditionalChannelTune && !isSeededRadioTune) ? channel.channelGuid : episodeIdentifier;
            let tuneLive  = (!episodeIdentifier && !isAdditionalChannelTune && !isSeededRadioTune) ? true : false;
            let tuneCall;
            switch(contentType)
            {
                case ContentTypes.ADDITIONAL_CHANNELS:
                    tuneCall =  this.nowPlayingDelegate.tuneAdditionalChannels.bind(this.nowPlayingDelegate);
                    break;
                case ContentTypes.SEEDED_RADIO:
                    tuneCall =  this.nowPlayingDelegate.tuneSeededRadio.bind(this.nowPlayingDelegate);
                    break;
                case ContentTypes.PODCAST:    // Content type for Iris Podcast on Tile is AOD but media type is podcast
                case ContentTypes.AOD:
                    tuneCall = isPandoraPodcast ? this.nowPlayingDelegate.tuneIrisPodcast.bind(this.nowPlayingDelegate)
                                                : this.nowPlayingDelegate.tuneAOD.bind(this.nowPlayingDelegate);
                    break;
                case ContentTypes.VOD:
                    tuneCall = this.nowPlayingDelegate.tuneVOD.bind(this.nowPlayingDelegate);
                    break;
                default:
                    if(!fromRetune && !this.enableAdsWizz$.getValue() && this.SERVICE_CONFIG.adsWizzSupported)
                    {
                        this.enableAdsWizz$.next(true);
                    }
                    tuneCall = this.nowPlayingDelegate.tuneLive.bind(this.nowPlayingDelegate);
                    break;
            }

            return this.sessionTransferService.castStatusChange(true).pipe(
                       flatMap(() =>
                       {
                           const checkGeolocation = channel ? channel.isGeoRestricted : false;
                           return this.geolocationService.checkContent(channelId,
                                                                       assetGuid,
                                                                       checkGeolocation,
                                                                       tuneLive,
                                                                       contentType);
                       }),
                       flatMap((responseCode: number) =>
                       {
                           return responseCode === ClientCodes.SUCCESS
                               ? this.makeTuneCall(channelId,
                                                   assetGuid,
                                                   showGuid,
                                                   tuneCall,
                                                   tuneLive,
                                                   contentType,
                                                   startTime,
                                                   cutAssetGuid,
                                                   fromRetune,
                                                   isPandoraPodcast)
                               : observableOf(responseCode);
                       }),
                       share()); // needs to be hot so the above call chain is only ever called once regardless of
                                 // how many subscribers are listening to the tune response
        }
        else
        {
            TuneService.logger.error(
                `tune( Invalid content select for playback. channelId: ${channelId},
                                                            contentType: ${contentType},
                                                            episodeIdentifier: ${episodeIdentifier}`
            );
            return observableOf(ClientCodes.ERROR);
        }
    }

    /**
     * Used to detect is content supported on Remote player or not.
     * Now AIC is not supported feature so added as part of this.
     * @param contentType
     */
    public isPlayingContentSupported(contentType?: string, playerType?: string): boolean
    {
        const playerTypeValue = playerType ? playerType : this.playerType;

        if (playerTypeValue === PlayerTypes.LOCAL || !this.tuneMediaTimeLineData)
        { return true; }

        const mediaType = contentType ? contentType : this.tuneMediaTimeLineData.mediaType;

        if ((MediaUtil.isVideoMediaType(mediaType) && !this.chromecastModel.isDeviceVideoSupported))
        {
            return false;
        }

        return true;
    }

    /**
     * Determines the Artist radio content type is in bypass mode or not
     * @param contentType
     */
    public isArtistRadioOnByPass(contentType?: string): boolean
    {
        const mediaType = contentType ? contentType : this.tuneMediaTimeLineData.mediaType;

        return MediaUtil.isSeededRadioMediaType(mediaType) && this.artistRadioByPassMode;
    }

    /**
     * Determines the AIC content type is in bypass mode or not
     * @param contentType
     */
    public isAICOnByPassMode(contentType?: string) : boolean
    {
        const mediaType = contentType ? contentType : this.tuneMediaTimeLineData.mediaType;

        return MediaUtil.isAICMediaType(mediaType) && this.aicByPassMode;
    }

    /**
     * returns the media type
     */
    public getMediaType() : string
    {
         return this.tuneMediaTimeLineData ? this.tuneMediaTimeLineData.mediaType : "";
    }

    /**
     * returns the player type
     */
    public getPlayerType() : string
    {
        return this.playerType;
    }

    /**
     * This method called when we do periodic live channel refreshes.  This is needed so that we can keep the
     * markers for live channels up to date with the actual live content.
     *
     * @param {string} channelId - channelId used to make API Call.
     * @returns {Observable<boolean | {}>} - return boolean value based on success or failure API call.
     */
    public updateLive(channelId: string, streamingAdsId?: string): Observable<any>
    {
        const updateResponse = this.nowPlayingDelegate.updateLive(channelId, streamingAdsId, this.enableAdsWizz$.getValue()).pipe(
                                   map((tuneResponse: TuneResponse) =>
                                   {
                                       return this.mediaResponseHandler(tuneResponse, true, this.tuneMediaTimeLineData.mediaType);
                                   }),
                                    catchError((error: any) =>
                                    {
                                        // if the updateLive call gets audio failover 800 we should retune to same channel by setting adsEligible
                                        // flag to false.
                                        if(error.code === ApiCodes.ADSWIZZ_FAILOVER)
                                        {
                                            this.enableAdsWizz$.next(false);
                                            this.forceRetune = true;
                                            return this.retune();
                                        }
                                        return  observableOf(ClientCodes.ERROR);
                                    }));

        updateResponse.subscribe((code: number) =>
        {
            TuneService.logger.debug(`update live status = ${code === ClientCodes.SUCCESS ? "SUCCESS" : "FAILED"}`);
        });

        return updateResponse;
    }

    /**
     * Generic tune function for live, aod and vod.  Takes care of all the housekeeping for tune calls
     *
     * @param channelId of the content to tune
     * @param assetGuid of the content to tune (same as channelId for live tunes)
     * @param tuneCall is the function call to use for tuning
     * @param tuneLive is true if this is a live channel tune.  Default value is false
     * @param contentType
     * @param startTime is start time of the playback.
     */
    private makeTuneCall(channelId: string,
                         assetGuid: string,
                         showGuid: string,
                         tuneCall: (channelId: string, assetGuid: string, showGuid?: string, enableAdsWizz?: boolean,
                                            cutAssetGuid?: string, fromRetune?: boolean) => Observable<TuneResponse>,
                         tuneLive = false,
                         contentType: string,
                         startTime: number,
                         cutAssetGuid: string,
                         fromRetune: boolean,
                         isPodcast: boolean = false): Observable<any>
    {
        if (contentType === ContentTypes.LIVE_VIDEO)
        {
            startTime = startTime ? startTime : this.liveTime.zuluMilliseconds;
        }

        const mediaId = (tuneLive === true || contentType === ContentTypes.ADDITIONAL_CHANNELS
            || contentType === ContentTypes.SEEDED_RADIO) ? channelId : assetGuid;

        const isNewMedia = !this.tuneMediaTimeLineData || this.tuneMediaTimeLineData.mediaId !== mediaId;

        if ((channelId || isPodcast) && isNewMedia)
        {
            TuneService.logger.debug(`tune ( ${channelId} ${assetGuid} )`);

            const liveChannel = (tuneLive === true) ? channelId : undefined;

            const tuneResult = tuneCall(channelId, assetGuid, showGuid, this.enableAdsWizz$.getValue(), cutAssetGuid, fromRetune).pipe(
                map((tuneResponse: TuneResponse) => this.tuneResponseHandler(tuneResponse, liveChannel, contentType, startTime)),
                catchError((error: any): Observable<number> =>
                {
                    // if the tune call gets audio failover 800 we should retune to same channel by setting adsEligible
                    // flag to false.
                    if(error.code === ApiCodes.ADSWIZZ_FAILOVER)
                    {
                        this.enableAdsWizz$.next(false);
                        return this.tune({
                            channelId : channelId,
                            contentType : contentType
                        }, true);
                    }

                    // Update App Monitor if the error is HTTP
                    if(error.message === HTTP_ERROR_MESSAGE || error.message === HTTP_NETWORK_ERROR)
                    {
                        this.appMonitorService.triggerFaultError({ faultCode: AppErrorCodes.FLTT_TUNE_NON_RECOVERABLE_FAILURE });
                        this.stopPeriodicLiveUpdateRequests();
                    }
                    else if (error.code === ApiCodes.FLTT_SR_SOURCE_ENDED
                             && error.url.indexOf(ServiceEndpointConstants.endpoints.SEEDED_RADIO.V4_TUNE) >= 0)
                    {
                        this.appMonitorService.triggerFaultApiError(error.code,error.url);
                    }

                    if (error.message)
                    {
                        TuneService.logger.error(`makeTuneCall ${error.url} Fault - ${error.message}`);
                        return observableOf(ClientCodes.ERROR);
                    }

                    if (error.url === ServiceEndpointConstants.endpoints.NOW_PLAYING.V2_LIVE
                        || error.url === ServiceEndpointConstants.endpoints.NOW_PLAYING.V2_AOD
                        || error.url === ServiceEndpointConstants.endpoints.NOW_PLAYING.V4_VOD)
                    {
                        TuneService.logger.error(`makeTuneCall ${error.url} Fault - Error (${JSON.stringify(error)})`);
                        return observableOf(ClientCodes.ERROR);
                    }

                    return observableOf(ClientCodes.SUCCESS); // on demand call failed, tune call was OK
                }),
                share());

            // This is important.  Even if calling code does not subscribe to the tuneCall, this will make sure that
            // the request is subscribed to so that the tune response handler will be run.
            tuneResult.subscribe((code: number) =>
            {
                TuneService.logger.debug(`makeTuneCall( status = ${code === ClientCodes.SUCCESS ? "SUCCESS" : "FAILED"} )`);
            });

            return tuneResult;
        }
        else
        {
            if (channelId && this.tuneMediaTimeLineData.mediaId === mediaId)
            {
                TuneService.logger.debug(`makeTuneCall ( already tuned to ${channelId} ${assetGuid} )`);

                //start at livePoint when contentType is liveVideo
                if(contentType === ContentTypes.LIVE_VIDEO)
                {
                    //To make sure that when we are tuning to LiveVideo we have valid video marker at livePoint
                    const videoMarker:IMediaVideo =
                              findMediaItemByTimestamp( this.liveTime.zuluMilliseconds,this.tuneMediaTimeLineData.videos) as IMediaVideo;

                    if(videoMarker && videoMarker.liveVideoStatus === VideoPlayerConstants.LIVE_VIDEO_ON)
                    {
                        this.tuneMediaTimeLineData.mediaType = contentType;
                        this.tuneMediaTimeLineData.startTime = startTime;
                        this.tuneMediaTimeLineSubject.next(this.tuneMediaTimeLineData);
                    }
                }
                return observableOf(ClientCodes.SUCCESS);
            }
            TuneService.logger.warn(`makeTuneCall ( Need a valid channel ID! )`);
            return observableOf(ClientCodes.ERROR);
        }
    }

    /**
     * Generic tune response handler. Takes care of periodic updates if necessary
     *
     * @param {TuneResponse} is the response for the tuneLive, tuneAod, tuneVod or tune AIC call
     * @param {string} channelId is an optional parameter specifying the channelId that periodic channel updates
     * @param {string} contentType is live/aod/vod/additionalChannels
     * @param {number} startTime is start time of the playback
     * @returns {boolean}
     */
    private tuneResponseHandler(tuneResponse: TuneResponse, channelId?: string, contentType?: string, startTime?: number): number
    {
        if (tuneResponse)
        {
            this.stopPeriodicLiveUpdateRequests();
            if (channelId)
            { this.startPeriodicLiveUpdateRequests(tuneResponse.updateFrequency, channelId, tuneResponse.streamingAdsId); }
            return this.mediaResponseHandler(tuneResponse,false, contentType, startTime);
        }

        return ClientCodes.ERROR;
    }

    /**
     * Generic media response handler.  Sets up the media time line and the currently playing data
     * @param tuneResponse
     * @param update is true if we are updating the media timeline, in which case we preserve the media endpoints
     *     already in existence on the media timeline
     * @param {string} contentType is live/aod/vod/additionalChannels
     * @param {number} startTime is start time of the playback
     */
    private mediaResponseHandler(tuneResponse: TuneResponse, update = false, contentType: string, startTime?: number): number
    {
        this.setMediaTimeLine(tuneResponse, update, contentType, startTime);
        return ClientCodes.SUCCESS;
    }

    /**
     * Used to normalize media data based on content type is AOD or Live. Sets normalized data to media time line
     * and scrub times and kick of Observables.
     * @param {IResumeMedia} resumeMedia
     */
    private setMediaData(resumeMedia: IResumeMedia)
    {
        TuneService.logger.debug(`setMediaData( ${resumeMedia.type} )`);

        resumeMedia.data.mediaType = resumeMedia.type.toLowerCase();
        resumeMedia.data.updateFrequency = resumeMedia.updateFrequency;
        resumeMedia.data.wallClockRenderTime = resumeMedia.wallClockRenderTime;
        const urlMaps: Array<IRelativeUrlSetting> = this.configService.getRelativeUrlSettings();
        const seededRadioFallbackUrl = this.configService.getSeededRadioBackgroundUrl();
        let tuneResponse = TuneDelegate.normalizeMediaData(resumeMedia.data, this.configService.liveVideoEnabled(), urlMaps, seededRadioFallbackUrl);

        tuneResponse.isDataComeFromResume = true;
        tuneResponse.isDataComeFromResumeWithDeepLink = resumeMedia.isDeepLinkResume
                                                       && resumeMedia.deepLinkContentType !== DeepLinkTypes.COLLECTION;
        tuneResponse.playerType = PlayerTypes.LOCAL;

        let startTime: number = null;
        if(resumeMedia.deepLinkContentType === LIVE_VIDEO_DEEPLINK_TYPE
           && tuneResponse.videos
           && tuneResponse.videos.length)
        {
            tuneResponse.mediaType = ContentTypes.LIVE_VIDEO;
            startTime = this.liveTime.zuluMilliseconds;
        }
        else if(tuneResponse.mediaType === ContentTypes.PODCAST)
        {
            startTime = tuneResponse.startTime;
        }

        const mediaHasChanged = this.tuneMediaTimeLineData
            && ((tuneResponse.mediaType !== this.tuneMediaTimeLineData.mediaType)
                && (tuneResponse.mediaId !== this.tuneMediaTimeLineData.mediaId));

        if (!this.tuneMediaTimeLineData || mediaHasChanged === true)
        {
            this.setMediaTimeLine(tuneResponse, false, tuneResponse.mediaType, startTime);
        }
    }

    /**
     * Start a timer interval that will periodically make update tune live calls to keep the information about what
     * is playing on the channel.
     * @param frequency - specifies the number of seconds in between tune live channel requests.
     * @param channelId - channelId used send value to do update tune live.
     */
    public startPeriodicLiveUpdateRequests(frequency: number, channelId: string, streamingAdsId?: string)
    {
        if (this.tuneLiveInterval === 0 || (frequency && frequency * 1000 !== this.liveUpdateTuneIntervalPeriodMs))
        {
            TuneService.logger.debug(`startPeriodicLiveUpdateRequests( ${channelId} )`);
            this.stopPeriodicLiveUpdateRequests();

            if (frequency)
            {
                this.liveUpdateTuneIntervalPeriodMs = frequency * 1000;
            }
            this.tuneLiveInterval =
                setInterval(() => this.updateLive(channelId, streamingAdsId), this.liveUpdateTuneIntervalPeriodMs);
        }
    }

    /*
     * Stop the timer interval that periodically refreshes the update tune live channel.
     * If the interval is not running then this function does nothing.
     */
    public stopPeriodicLiveUpdateRequests()
    {
        clearInterval(this.tuneLiveInterval);
        this.tuneLiveInterval = 0;
    }

    /**
     * This method used to set media time line object.
     * kick off the observables for tuneMediaTimeLine.
     * @param {tuneMediaTimeLine} mediaTimeLine
     * @param {boolean} update, if true, indicates we are updating the media time line, in which case we preserve
     *         the media endpoints from the previous call to update the time line
     * @param {contentType} contentType
     * @param {startTime} startTime
     */
    private setMediaTimeLine(mediaTimeLine: MediaTimeLine, update = false, contentType: string, startTime?: number): void
    {
        const cutsLength: number = mediaTimeLine.cuts.length;

        if (cutsLength <= 0)
        {
            TuneService.logger.debug(`Cuts Returned empty - MediaId ${ mediaTimeLine.mediaId}`);
        }

        if(!update)
        {
            mediaTimeLine.forceRetune = this.forceRetune;
            this.forceRetune = false;
        }

        if (update === true && this.tuneMediaTimeLineData)
        {
            mediaTimeLine.mediaEndPoints = this.tuneMediaTimeLineData.mediaEndPoints;
            mediaTimeLine.mediaType      = this.tuneMediaTimeLineData.mediaType;
        }

        if (contentType && contentType === ContentTypes.LIVE_VIDEO)
        {
            mediaTimeLine.mediaType = ContentTypes.LIVE_VIDEO;
        }

        startTime = mediaTimeLine.startTime ? mediaTimeLine.startTime : startTime;

        if(startTime && startTime > 0)
        {
            mediaTimeLine.mediaEndPoints
                         .forEach((endPoint: IMediaEndPoint) =>
                         {
                             endPoint.position.isoTimestamp = new Date( startTime ).toISOString();
                             endPoint.position.zuluTimestamp = startTime;
                         });
        }

        this.tuneMediaTimeLineData = mediaTimeLine;
        this.tuneMediaTimeLineData.startTime = startTime;
        this.tuneMediaTimeLineSubject.next(this.tuneMediaTimeLineData);
    }

    /**
     * sets the live time
     * @param {LiveTime} liveTime
     */
    public setLiveTime(liveTime: LiveTime): void
    {
        this.liveTime = liveTime;
    }

    /**
     * sets the player type
     * @param {string} playerType
     */
    public setPlayerType(playerType: string): void
    {
        this.playerType = playerType;
        if (playerType === PlayerTypes.REMOTE &&
            this.enableAdsWizz$.getValue() &&
            MediaUtil.isLiveMediaType(this.tuneMediaTimeLineData.mediaType))
        {
            this.updateLive(this.tuneMediaTimeLineData.channelId);
        }
    }

    /** Need this method because refresh-tracks service
     * might make a tune-line refresh tracks call for a pausepoint reasons on seeded.
     * We need to inform the local state of this service that the media id has changed.
     */
    public updateMediaId(mediaId: string): void
    {
        if (this.tuneMediaTimeLineData)
        {
            this.tuneMediaTimeLineData.mediaId = mediaId;
        }
    }

    public clearTuneMediaTimeLineData() {
        this.tuneMediaTimeLineData = null;
    }
}
