import { Logger } from '../logger';
import { addProvider, IProviderDescriptor } from '../service';
import { BehaviorSubject, Observable } from 'rxjs';
import { ISession } from './session.interface';

export class AuthenticationModel {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('TuneModel');

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(AuthenticationModel, AuthenticationModel, []);
  })();

  private authenticatingSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    false,
  );

  public authenticating$: Observable<boolean> = this.authenticatingSubject;

  private session: ISession;

  /*private userSessionSubject: BehaviorSubject<ISession> = new BehaviorSubject(
    this.session,
  );*/
  private userSessionSubject: BehaviorSubject<ISession>;

  //public userSession$: Observable<ISession> = this.userSessionSubject;
  public userSession$: Observable<ISession>;

  private authenticatedSubject: BehaviorSubject<boolean> = new BehaviorSubject(
    null,
  );

  public authenticated$: Observable<boolean> = this.authenticatedSubject;

  /**
   * Constructor
   */
  constructor() {
    AuthenticationModel.logger.debug('constructor()');
  }

  public setAuthenticatingValue(authenticating: boolean): void {
    this.authenticatingSubject.next(authenticating);
  }

  public set userSession(session: ISession) {
    this.session = session;
    this.userSessionSubject = new BehaviorSubject(this.session);
    this.userSession$ = this.userSessionSubject;
    this.userSessionSubject.next(this.session);
  }

  public get userSession() {
    return this.session;
  }

  public set authenticated(authenticate: boolean) {
    this.authenticatedSubject.next(authenticate);
  }
}
