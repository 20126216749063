import { TileShapeType } from "./raw.tile.interface";

export class CarouselTypeConst
{
    public static SEARCH: string                 = "search";
    public static SEARCH_LANDING: string         = "search_landing_zoned";
    public static PROFILE: string                = "profile";
    public static RECENTS: string                = "recents";
    public static FOR_YOU: string                = "for_you";
    public static SHOW_TILE                      = "show";
    public static AOD_SHOW_TILE                  = "aod";
    public static VOD_SHOW_TILE                  = "vod";
    public static LIVE                           = "live";
    public static LIVE_AUDIO                     = "liveaudio";
    public static LIVE_VIDEO                     = "liveVideo";
    public static LIVE_SHOW_TILE                 = "live-show";
    public static BUFFERED_SHOW_TILE             = "buffered-show";
    public static FUTURE_SHOW_TILE               = "future-show";
    public static BUFFER_AUDIO_TYPE              = "bufferaudio";
    public static FUTURE_AUDIO_TYPE              = "upcomingaudio";
    public static AOD_EPISODE_TILE               = "aod";
    public static VOD_EPISODE_TILE               = "vod";
    public static ARTIST_TEXT                    = "artist";
    public static TITLE_TEXT                     = "title";
    public static ARTIST_TITLE_TEXT              = "artist-title";
    public static CHANNEL_TILE                   = "channel";
    public static ADDITIONAL_CHANNEL             = "additionalchannel";
    public static NOW_PLAYING                    = "nowplaying";
    public static EPISODE_TILE                   = "episode";
    public static CONTINUE_LISTENING_TILE        = "continue-listening";
    public static LIVE_CONTINUE_LISTENING_TILE   = "live-continue-listening";
    public static SEEDED_RADIO                   = "seededradio";
    public static CATEGORY_TILE                  = "category";
    public static SUPER_CATEGORY_TILE            = "superCategory";
    public static COLLECTION                     = "collection";
    public static COLLECTION_DETAILS             = "collection_details";
    public static CHANNELS_ALL                   = "channels_all";
    public static NOW_PLAYING_LIVE_RESTRICTED    = "np_live_restricted";
    public static NOW_PLAYING_AOD_RESTRICTED     = "np_aod_restricted";
    public static NOW_PLAYING_VOD_RESTRICTED     = "np_vod_restricted";
    public static NOW_PLAYING_AIC_RESTRICTED     = "np_aic_restricted";
    public static NOW_PLAYING_AR_RESTRICTED      = "np_artistradio_restricted";
    public static NOW_PLAYING_LIVE_UN_RESTRICTED = "np_live_unrestricted";
    public static NOW_PLAYING_AOD_UN_RESTRICTED  = "np_aod_unrestricted";
    public static NOW_PLAYING_VOD_UN_RESTRICTED  = "np_vod_unrestricted";
    public static NOW_PLAYING_AIC_UN_RESTRICTED  = "np_aic_unrestricted";
    public static NOW_PLAYING_AR_UN_RESTRICTED   = "np_artistradio_unrestricted";
    public static NOW_PLAYING_LIVE_DISALLOWED    = "np_live_disallowed";
    public static NOW_PLAYING_AOD_DISALLOWED     = "np_aod_disallowed";
    public static NOW_PLAYING_VOD_DISALLOWED     = "np_vod_disallowed";
    public static NOW_PLAYING_AIC_DISALLOWED     = "np_aic_disallowed";
    public static NOW_PLAYING_AR_DISALLOWED      = "np_artistradio_disallowed";
    public static NOW_PLAYING_VOD_UP_NEXT        = "np_vod_upnext";
    public static NOW_PLAYING_AOD_UP_NEXT        = "np_aod_upnext";
    public static API_IMAGE_LOGO                 = "logo";
    public static API_IMAGE_LEFT                 = "logo-left";
    public static API_IMAGE_CENTER               = "logo-center";
    public static API_IMAGE_BACKGROUND           = "background";
    public static API_IMAGE_ICON                 = "icon";
    public static API_IMAGE_ICON_LEFT            = "icon-left";
    public static API_IMAGE_ICON_CENTER          = "icon-center";
    public static CLIENT_IMAGE_CENTER            = "center";
    public static CLIENT_IMAGE_LEFT_TOP          = "left-top";
    public static CLIENT_IMAGE_CENTER_LEFT       = "center-left";
    public static CAROUSEL_TITLE                 = "title";
    public static ENHANCED_CHANNEL_EDP           = "edp_channel_enhanced";
    public static ENHANCED_SHOW_EDP              = "edp_show_enhanced";
    public static ENHANCED_EPISODE_EDP           = "edp_episode_enhanced";
    public static ENHANCED_VOD_EPISODE_EDP       = "edp_vodepisodes";
    public static AUTOMATED_CAROUSEL             = "automated";
    public static CURATED_CAROUSEL               = "curated";
    public static SEEDED_CAROUSEL                = "seeded";
    public static ANY_CAROUSEL                   = "any";
    public static HORIZONTAL_CAROUSEL            = "horizontal";
    public static VERTICAL_CAROUSEL              = "vertical";
    public static GRID_CAROUSEL                  = "grid";
    public static HERO_CAROUSEL                  = "hero";
    public static SHORT_CAROUSEL                 = "short";
    public static LIST_CAROUSEL                  = "list";
    public static LIST_CAR_CAROUSEL              = "list-car";
    public static SHOWS_EDP                      = "edp_shows";
    public static TILE_AVAILABLE                 = "available";
    public static TILE_UNAVAILABLE               = "unavailable";
    public static EPISODE_EDP                    = "edp_episodes";
    public static ONDEMAND_ALL                   = "ondemand_all";
    public static VIDEO_ALL                      = "video_landing";
    public static PODCAST_ALL                    = "podcasts_all";
    public static FAVORITES                      = "favorites_everest";
    public static SEEDED_STATIONS                = "seededStations";
    public static CONTENT_TOGGLE_SELECTOR        = "contentToggleSelector";
    public static TAB_SORT_ORDER                 = "tabSortOrder";
    public static SEGMENT_SHOWS                  = "shows";
    public static SEGMENT_LATEST_AUDIO           = "latest audio";
    public static SEGMENT_LATEST_VIDEO           = "latest videos";
    public static SEGMENT_CHANNELS               = "channels";
    public static SEGMENT_ON_DEMAND_CLASS        = "aod";
    public static SEGMENT_ON_DEMAND              = "on demand";
    public static SEGMENT_ON_DEMAND_SHOWS        = "on demand shows";
    public static SEGMENT_ON_DEMAND_SHOWS_CLASS  = "odshows";
    public static SEGMENT_ON_DEMAND_EPISODES     = "on demand episodes";
    public static SEGMENT_ENHANCED_ON_DEMAND_CLASS = "odepisodes";
    public static SEGMENT_ADDITIONAL_EPISODES    = "additional episodes";
    public static SEGMENT_CHANNEL_SCHEDULE       = "channel schedule";
    public static SEGMENT_CHANNEL_SCHEDULE_CLASS = "channelschedule";
    public static SEGMENT_SHOW_SCHEDULE          = "show schedule";
    public static SEGMENT_SHOW_SCHEDULE_CLASS    = "showschedule";
    public static SHOW_REMINDERS                 = "notification_settings";
    public static PROMO_IMAGE_TYPE               = "promo";
    public static FUNCTIONAL_GROUP_ADD_FAVORITES = "addToFavorites";
    public static VIEW_ALL_TILE                  = "viewAll";
    public static PAGE_TILE                      = "page";
    public static UNAVAILABLE_BANNER_CLASS       = "unavailable";
    public static CUSTOM_BANNER_CLASS            = "custom";
    public static STAFF_PICK_BANNER_CLASS        = "staffPick";
    public static SPORTS_GAME_STATE_BANNER_CLASS = "sportsGameState";
    public static TRENDING_BANNER_CLASS          = "trending";
    public static POPULAR_BANNER_CLASS           = "popular";
    public static ON_AIR_NOW_BANNER_CLASS        = "onAirNow";
    public static ON_WATCH_LIVE_BANNER_CLASS     = "liveVideo";
    public static EXPIRING_SOON_BANNER_CLASS     = "expiringSoon";
    public static SAT_ONLY_BANNER_CLASS          = "satOnly";
    public static NEW_EPISODE_BANNER_CLASS       = "newEpisode";
    public static NEW_BANNER_CLASS               = "new";
    public static LIVE_VIDEO_BANNER_CONDITION    = "LiveVideoOn";
    public static SQUARE_TILE_SHAPE : TileShapeType = "square";
    public static PODCAST_EPISODE_TILE           = "podcast";

    public static UNAVAILABLE_BANNER_COLOR       = "#3a4049";
    public static CUSTOM_BANNER_COLOR            = "#2ba7ff";
    public static STAFF_PICK_BANNER_COLOR        = "#2ba7ff";
    public static SPORTS_GAME_STATE_BANNER_COLOR = "#2ba7ff";
    public static TRENDING_BANNER_COLOR          = "#2ba7ff";
    public static POPULAR_BANNER_COLOR           = "#2ba7ff";
    public static ON_AIR_NOW_BANNER_COLOR        = "#265f88";
    public static ON_WATCH_LIVE_BANNER_COLOR     = "#2ba7ff";
    public static EXPIRING_SOON_BANNER_COLOR     = "#e23623";
    public static SAT_ONLY_BANNER_COLOR          = "#3a4049";
    public static NEW_EPISODE_BANNER_COLOR       = "#2ba7ff";
    public static NEW_BANNER_COLOR               = "#2ba7ff";
}
