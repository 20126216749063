import { IComponentConfig, IConfig }                    from "../interfaces/config.interface";
import { StringSetting, StringSettingItem, UrlSetting } from "../interfaces/settings-item.interface";
import { UrlSettingsItem }                              from "../";
import { ConfigConstants }                              from "../config.constants";

export class ConfigFinderService
{
    public static findConfigurationComponent(components : Array<IComponentConfig>,
                                             componentName: string): IComponentConfig
    {
        return (components) ? components.find((component: IComponentConfig) => component.name === componentName)
                            : null;
    }

    public static findConfigurationComponentSetting(components : Array<IComponentConfig>,
                                                    componentName : string,
                                                    platform : string,
                                                    locale? : string)
    {
        platform = platform.toLowerCase();
        if (locale) { locale = locale.toLowerCase(); }

        const component         = ConfigFinderService.findConfigurationComponent(components,componentName);
        const componentSettings = (component && Array.isArray(component.settings)) ? component.settings : [];

        return componentSettings.find((setting) => setting.platform
                                                   && setting.platform.toLowerCase() === platform
                                                   && (locale === undefined
                                                       || (setting.locale && setting.locale.toLowerCase() === locale)));
    }

    public static findStringSetting({ settings }: IComponentConfig, settingName: string): StringSetting
    {
        return settings.find(({ stringSetting }: StringSettingItem) => stringSetting.name === settingName).stringSetting;
    }

    public static findUrlSetting({ settings  }: IComponentConfig, settingName: string, filter: { name:string, match :string}): UrlSetting
    {
        let urlSetting: UrlSetting = { name: '', value: '', type: '' };
        let filteredSettings: Array<any> = (settings) ? settings : [];

        if (settings && filter)
        {
            const urlSetting = settings.filter( (setting: UrlSettingsItem) =>
            {
                return setting[filter.name] === filter.match;
            });

            if (Array.isArray(urlSetting)) { filteredSettings = urlSetting; }
        }

        filteredSettings.forEach((setting: UrlSettingsItem) =>
        {
            urlSetting = setting.urlSettings.find((urlSetting: UrlSetting) => urlSetting.name === settingName);
        });

        return urlSetting;
    }
}
