import * as _         from "lodash";
import { Observable } from "rxjs";
import { map, share } from 'rxjs/operators';
import {
    IProviderDescriptor,
    addProvider,
    ServiceEndpointConstants
}                     from "../service";
import { IAppConfig } from "../config";
import {
    HttpProvider
}                     from "../http";
import { Logger }     from "../logger";
import {
    ModuleAreaRequest,
    ModuleAreaWithSubtype,
    ModuleArea,
    ModuleAreaRequestWithSubtype
}                     from "../http";
import {
    ISettings,
    ISetting
}                     from "./globalSettings.interface";


/**
 * @MODULE:     service-lib
 * @CREATED:    09/18/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *  SettingsDelegate used to Make API Calls to get and update Global and discover settings.
 */

export class SettingsDelegate
{
    /**
     * Internal logger.
     * @type {Logger}
     */
    private static logger: Logger = Logger.getLogger("SettingsDelegate");

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor : IProviderDescriptor = function()
    {
        return addProvider(SettingsDelegate, SettingsDelegate, [HttpProvider, 'IAppConfig']);
    }();

    /**
     * Constructor
     * @param http is used to make calls with standardized request and response interception capabilities
     * @param SERVICE_CONFIG contains the runtime application configuration
     */
    constructor(private http: HttpProvider,
                private SERVICE_CONFIG: IAppConfig) {}

    /**
     * Used to make API call to get global and device settings list.
     * @returns {Observable<ISettings>}- an observable that can be subscribed to to get the
     * results of the API call.
     */
    public getSettings(): Observable<ISettings>
    {
        const request = {
            "profileGetRequest": {}
        };

        let areaRequests = new Array<ModuleArea>();
        areaRequests.push(new ModuleAreaRequest("Profile", "GUPCollation", request));
        areaRequests.push(new ModuleAreaWithSubtype("Profile", "GUPCollation", "DeviceSettings"));

        return this.settings(ServiceEndpointConstants.endpoints.ME_SETTINGS.V2_GET_SETTINGS_RESULT, areaRequests);
    }

    /**
     * Used to Make api call to update the settings.
     * @param updatedSettings - settings that are to be updated.
     * @returns {Observable<Array<ISetting>>} -an observable that can be subscribed to to get the
     * results of the API call.
     */
    public updateSettings(settings: ISettings): Observable<ISettings>
    {
        const globalUpdateRequest = {
            "profileUpdateRequest": {
                "gupGlobalSettingsUpdateRequests": [
                    {
                        "globalSettingList": {
                            "globalSettings": this.getGlobalSettingListForRequest(settings.globalSettings)
                        }
                    }
                ]
            }
        };

        const deviceUpdateRequest = {
            "profileUpdateRequest": {
                "gupDeviceSettingsUpdateRequests": [
                    {
                        "deviceSettingList": {
                            "deviceSettings": this.getDeviceSettingListForRequest(settings.deviceSettings)
                        }
                    }
                ]
            }
        };

        let areaRequests = new Array<ModuleArea>();
        areaRequests.push(new ModuleAreaRequest("Profile", "GUPCollation", globalUpdateRequest));
        areaRequests.push(new ModuleAreaRequestWithSubtype("Profile", "GUPCollation", "DeviceSettings", deviceUpdateRequest));

        return this.settings(ServiceEndpointConstants.endpoints.ME_SETTINGS.V2_UPDATE_SETTINGS_RESULT, areaRequests);
    }

    /**
     * Makes the API call based on End point and returns the global and device settings.
     * @param endPoint - endPoint Url
     * @param areaRequests - requests can passed to API.
     * @returns {Observable<ISettings>}- an observable that can be subscribed to to get the
     * results of the API call.
     */
    private settings(endPoint: string, areaRequests: Array<ModuleArea>): Observable<ISettings>
    {
        return this.http.postModuleAreaRequests(endPoint, areaRequests, null)
                   .pipe(map((response) =>
                   {
                       let globalSettings;
                       let deviceSettings;
                       if (endPoint === ServiceEndpointConstants.endpoints.ME_SETTINGS.V2_UPDATE_SETTINGS_RESULT)
                       {
                           globalSettings = _.get(response, "globalSettingList.globalSettings", []);
                           deviceSettings = _.get(response, "deviceSettingList.deviceSettings", []);
                       }
                       else
                       {
                           globalSettings = _.get(response[0], "globalSettingList.globalSettings", []);
                           deviceSettings = _.get(response[1], "deviceSettingList.deviceSettings", []);
                       }

                       return {
                           globalSettings: this.normalizeGlobalSettings(globalSettings),
                           deviceSettings: this.normalizeDeviceSettings(deviceSettings)
                       } as ISettings;

                   }),
                   share());
    }

    /**
     * Used to normalize the global settings.
     * @param apiGlobalSettings
     * @returns {ISetting[]}
     */
    private normalizeGlobalSettings(apiGlobalSettings: any): ISetting[]
    {
        return apiGlobalSettings.map(setting =>
        {
            return {
                name: setting.settingName,
                value: setting.settingValue
            };
        });
    }

    /**
     * Used to normalize the device settings.
     * @param apiDeviceSetting
     * @returns {ISetting[]}
     */
    private normalizeDeviceSettings(apiDeviceSettings: any): ISetting[]
    {
        return apiDeviceSettings.map(setting =>
        {
            return {
                name: setting.name,
                value: setting.value
            };
        });
    }

    /**
     * get the device settings list that can pass to the update settings API.
     * @param deviceSettings - device settings that are to be updated
     * @returns {any}
     */
    private getDeviceSettingListForRequest(deviceSettings: any): any
    {
        return deviceSettings.map(setting =>
        {
            return {
                settingName: setting.name,
                settingValue: setting.value,
                deviceId: this.SERVICE_CONFIG.deviceInfo.clientDeviceId
            };
        });
    }

    /**
     * get the global settings list that can pass to the update settings API.
     * @param globalSettings
     * @returns {any}
     */
    private getGlobalSettingListForRequest(globalSettings: any): any
    {
        return globalSettings.map(setting =>
        {
            return {
                settingName: setting.name,
                settingValue: setting.value
            };
        });
    }
}
