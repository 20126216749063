import * as _ from 'lodash';
import {
  ContextualConstants,
  IAppConfig,
  IDeviceInfo,
  IHttpRequestConfig,
  IProviderDescriptor,
} from '../index';
import { addProvider } from '../service';
import { Logger } from '../logger';
import { StorageService } from '../storage';
import { ServiceEndpointConstants } from '../service/consts';
import { ContextualUtilities } from '../../../servicelib/src/contexual/contextual.utilities';

/**
 * @MODULE:     service-lib
 * @CREATED:    07/24/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * http.provider.request.interceptor provides a request interceptor that will add in SiriusXM common API protocol
 * elements to a request
 */

/**
 * RequestInterceptor class will set up the baseURL for outgoing requests, and then add the SiriusXM API protocol
 * elements needed for gets and posts.
 */
export class RequestInterceptor {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('RequestInterceptor');

  /**
   * Holds the unique ID to used to set header "X-Mountain-PlayerId"
   * which should be same on all API requests originating from a tab
   */
  private uniquePlayerId: string = `${Math.random()}-${new Date().getTime()}`;

  /**
   * Holds the language version used to set "Accept-Language" header.
   */
  private acceptLanguage: string = '';

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(RequestInterceptor, RequestInterceptor, [
      'IAppConfig',
      StorageService,
    ]);
  })();

  /**
   * Constructor
   * @param SERVICE_CONFIG is the service layer configuration that provides certain configuration parameters that
   * are needed when formulating API requests
   */
  constructor(
    private SERVICE_CONFIG: IAppConfig,
    private storageService: StorageService,
  ) {
    this.onRequest = this.onRequest.bind(this); // important in case onRequest is used as a callback
  }

  /**
   * Figure out what type of http method we are dealing with, and then forward to the correct function for handling
   * that method type. Sets defaults on the HTTP config object if they're not set.
   *
   * @param config : config object that defines the parameters for the request
   * @returns {IHttpRequestConfig}
   */
  public onRequest(config: IHttpRequestConfig): IHttpRequestConfig {
    let timeFound: boolean = false;

    // Set Accept-Language header
    this.setAcceptLanguage();
    config.headers['Accept-Language'] = this.acceptLanguage;

    /**
     * If there is an ALC parameter for Seamless App Login in the initial url used to load the app,
     * we need to add the request header "x-sxm-alc-code". This will bypass any OA display or login
     * requirement.
     */
    const alcCode = ContextualUtilities.getQueryParameterValue(
      this.SERVICE_CONFIG.contextualInfo.queryString,
      ContextualConstants.ALC_CODE,
    );

    if (
      alcCode &&
      config.url.indexOf(
        ServiceEndpointConstants.endpoints.RESUME.V2_GET_RESUME,
      ) !== -1
    ) {
      config.headers['x-sxm-alc-code'] = alcCode;
    }

    /**
     * NOTE : the logout call is special.  We do not want to put a player id header on that call because
     * there are cases where we refresh the browser to the login route (ot the user has a bookmark), and we
     * do NOT want the API to trigger a 203 in the attenmpt to log out!
     */
    if (
      config.url.indexOf(
        ServiceEndpointConstants.endpoints.AUTHENTICATION.V2_LOGOUT,
      ) < 0
    ) {
      config.headers['X-Mountain-PlayerId'] = this.uniquePlayerId;
    }

    // NOTE: Only uncomment for deep debugging as this is called quite a bit.
    // RequestInterceptor.logger.debug(`onRequest( ${config.url} )`);

    config.baseURL = this.SERVICE_CONFIG.apiEndpoint;

    // Add cache busting support.
    if (!config.params) {
      config.params = {};
    }

    // There are certain URL params that are used in API calls that need to be filled in with data from the
    // service config or data that is computed at runtime.  Handle this below
    Object.keys(config.params).forEach(param => {
      if (config.params[param] !== '') {
        return; // hands off if the param is not empty;
      }

      switch (param) {
        case 'app-region':
          config.params[param] = this.SERVICE_CONFIG.deviceInfo.appRegion;
          break;

        case 'result-template':
          config.params[param] = this.SERVICE_CONFIG.resultTemplate;
          break;

        case 'time':
          config.params[param] = new Date().getTime();
          timeFound = true;
          break;
      }
    });

    if (timeFound === false) {
      config.params.cacheBuster = new Date().getTime();
    }

    // Make sure the response type is defaulted to JSON.
    if (!config.responseType) {
      config.responseType = 'json';
    }

    // If the API response type is raw string/text,responseType should be text.
    if (config.isRaw) {
      config.responseType = 'text';
    }

    const modulesPath: string = 'data.ModuleList.modules';
    const modules = _.get(config, modulesPath, null) as Array<any>;

    if (modules != null) {
      modules.forEach((module: any) => {
        const deviceInfoPath = 'moduleRequest.deviceInfo';
        const deviceInfo = _.get(module, deviceInfoPath, null) as IDeviceInfo;
        if (deviceInfo) {
          deviceInfo.clientDeviceId = this.SERVICE_CONFIG.deviceInfo.clientDeviceId;
        }
      });
    }

    config.withCredentials = true;

    return config;
  }

  /**
   * Determine which language version to send via Accept-Language header.
   * @returns string
   */
  private setAcceptLanguage() {
    let storedLanguage = this.storageService.getItem('language');
    let region = this.SERVICE_CONFIG.deviceInfo.appRegion;
    let englishLanguageVersion = region === 'US' ? 'en-US' : 'en-CA';

    this.acceptLanguage =
      storedLanguage === 'fr' ? 'fr-CA' : englishLanguageVersion;
  }
}
