import { IProviderDescriptor, addProvider, Logger } from '../../../';

import {
  IBitrateHistoryRecord,
  IStreamObject,
} from './bitrate-history.interface';

export class BitrateHistoryLogService {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('BitrateHistoryLogService');

  /**
   * Stores a collection of bitrate change events.
   * Each record includes a timestamp and description of the hls video stream.
   */
  private bitrateHistoryRecords: Array<IBitrateHistoryRecord> = [];

  /**
   * The maximum number of records to keep.  Business requirements dictate storing
   * at least sixty seconds of history.  Ten shoud be enough, since changes in video
   * video bitrate don't happen more than a few times per minute under normal conditions.
   */
  private MAX_RECORD_QTY: number = 10;

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(BitrateHistoryLogService, BitrateHistoryLogService);
  })();

  constructor() {}

  /**
   * Add a new record to the front of bitrate history array via 'unshift'.
   * If we've reached our maximum history length, remove the oldest record via 'pop'.
   */
  public addRecord(streamObject: IStreamObject): void {
    BitrateHistoryLogService.logger.debug(
      `addRecord( streamObject: ${JSON.stringify(streamObject)} )`,
    );

    const unixTimestamp: number = new Date().getTime() / 1000;

    // Insert a record to our log of bit rate history.
    this.bitrateHistoryRecords.unshift({ unixTimestamp, streamObject });

    // Only keep the last n records of bitrate history.
    if (this.bitrateHistoryRecords.length > this.MAX_RECORD_QTY) {
      this.bitrateHistoryRecords.pop();
    }
  }

  /**
   * Log all of the bitrate change history we have to the console.
   * This method should be called on video player error, per business requirements.
   */
  public printRecords(): void {
    const qtyOfRecords: number = this.bitrateHistoryRecords.length;

    BitrateHistoryLogService.logger.debug(
      `printRecords( qtyOfRecords = ${qtyOfRecords} )`,
    );

    // Print the table of records.
    BitrateHistoryLogService.logger.debug(
      this._makePrettyTableString(/*this.bitrateHistoryRecords*/),
    );
  }

  /**
   * Take a collection of bitrate history records and return a table-like string.
   * Pure function.
   *
   * Example:
   *  0m 23 seconds ago | 1920 x 1080 resolution | 4067 kbps
   *  3m 54 seconds ago |  416 x  234 resolution |  225 kbps
   * 10m 04 seconds ago |  960 x  540 resolution | 1285 kbps
   */
  private _makePrettyTableString(): /*bitrateHistoryRecords: Array<IBitrateHistoryRecord>,*/
  string {
    let logString: string = '';

    this.bitrateHistoryRecords.forEach(
      (historyRecord: IBitrateHistoryRecord) => {
        logString += `\n${_makePrettyRow(historyRecord)}`;
      },
    );

    return logString;

    /**
     * Make a pretty-printed row for our table of records.  Pure function.
     */
    function _makePrettyRow(historyRecord: IBitrateHistoryRecord): string {
      return [
        makePrettyTime(historyRecord.unixTimestamp),
        makePrettyResolution(
          historyRecord.streamObject.width,
          historyRecord.streamObject.height,
        ),
        makePrettyBitrate(historyRecord.streamObject.bitrate),
      ].join(' ');

      function makePrettyTime(unixTimestamp: number): string {
        const now: number = new Date().getTime() / 1000;

        const secondsAgo: number = Math.round(now - unixTimestamp);
        const minutesAgo: number = Math.floor(secondsAgo / 60);

        const minutesPad: string = minutesAgo < 10 ? ' ' : '';
        const secondsPad: string = secondsAgo % 60 < 10 ? '0' : '';

        return `${minutesPad}${minutesAgo}m ${secondsPad}${secondsAgo %
          60} seconds ago |`;
      }

      function makePrettyResolution(width: number, height: number): string {
        const widthPad: string = width < 1000 ? ' ' : '';
        const heightPad: string = height < 1000 ? ' ' : '';

        return `${widthPad}${width} x ${heightPad}${height} resolution |`;
      }

      function makePrettyBitrate(bitsPerSecond: number): string {
        // Convert bits per second to kilobits per second.
        const kbps: number = Math.round(bitsPerSecond / 1000);
        const bitratePad: string = kbps < 1000 ? ' ' : '';

        return `${bitratePad}${kbps} kbps`;
      }
    }
  }
}
