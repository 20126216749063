import {
    IAlbumImage,
    IImage
}                                              from "../index";

import {
    IAodEpisode,
    IChannel,
    IOnDemandShow,
    IVodEpisode
} from "../channellineup";


import { ISuperCategory, ISubCategory } from "../channellineup/categories.interfaces";
import { Observable, BehaviorSubject } from "rxjs";
import { IAssetInfoType, ICarouselTextType } from "./carousel.types";

export interface IRawTileActionBase
{
    actionAnalyticsTag : string;
    actionNeriticLink : string;
}

export interface IRawTileAction extends IRawTileActionBase
{
    actionAnalyticsTag : string;
    actionNeriticLink : string;
    actionPadding : number;
}

export interface IRawTileImage
{
    imageCreativeArt? : IAlbumImage;
    imageLayer : number;
    imageRenderPosition : ITilePosition;
    imageRenderSize : ITileSize;
}

export interface IRawTileData
{
    tileAction : IRawTileAction;
    tileAssetGuid : string;
    tileBackgrounds : Array<ITileBackground>;
    tileContentType : string;
    tileGuid : string;
    tileImages : Array<IRawTileImage>;
    tileTexts : Array<ITileText>;
    tileRefreshTime : number;
    tileSize : ITileSize;
    tileType : string;
}

export interface ITilePosition
{
    left : number;
    top : number;
}

export interface ITileSize
{
    width: number;
    height: number;
}

export interface ITileBackground
{
    color : string;
    layer : number;
    position : ITilePosition;
    shape : string;
    size : ITileSize;
}

export interface ITileImage
{
    artwork : IImage;
    layer : number;
    renderPosition : ITilePosition;
    renderSize : ITileSize;
}

export type FgImageType = "center" | "center-left" | "center-right" | "left-top" | "promo" | "";

export type IconImageType = "icon-left" | "icon-center" | "";

export interface ITileText
{
    textValue: string;
    textClass: string;
    textField: string;
}

export interface ITile
{
    bgImageUrl: string;
    backgroundColor?: string;
    fgImageUrl: string;
    iconImageUrl?: string;
    iconImageType?: IconImageType;
    imageAltText?: string;
    fgImageType?: FgImageType;
    tileShape?: TileShapeType;
    isFavorite?: boolean;
    reminders: ITileReminders;
    line1: string;
    line1$ : Observable<string>;
    line1FieldType? : string;
    line2: string;
    line2$ :Observable<string>;
    line2FieldType? : string;
    line3?: string;
    line3$? : Observable<string>;
    line3FieldType? : string;
    line3Prefix?: ICarouselTextType;
    ariaText?: string;
    longDescription?: string;
    isEligibleForLiveUpdates? : boolean;
    nowArtist?: string;
    nowTitle?: string;
    action?: {};
    assetGuid?: string;
    background?: ITileBackground;
    type?: string;
    tabSortOrder? : number;
    tileContentType?: string;
    tileContentTypeFromAPI?: string;
    tileContentSubType?: string;
    tileGuid?: string;
    primaryNeriticLink? : INeriticLinkData;
    neriticLinkData?:  Array<INeriticLinkData>;
    images : Array<ITileImage>;
    percentConsumed? : number;
    channelInfo? : IChannel;
    channelNumber? : string;
    subtext? : string;
    tileBanner? : ITileBanner;
    tileAssetInfo? : ITileAssetInfo;
    tileAssets : Array<IAssetInfoType>;
    superCategoryInfo? : ISuperCategory;
    subCategoryInfo? : ISubCategory;
    isTunedTo$?: BehaviorSubject<boolean>;
    cutAssetGuid?: string;
}

export interface IInitialTile
{
    defaultFav: boolean;
}

export interface ITileReminders
{
    showReminderSet: boolean;
    liveVideoReminderSet: boolean;
}

export interface INeriticLinkData
{
    analyticsTag?: string;
    functionalGroup?: string;
    url?: string;
    linkType?: string;
    actionType?: string;
    contentType?: string;
    channelId?: string;
    assetGuid?: string;
    showGuid?: string;
    categoryKey? : string;
    contentSubType?: string;
    startTime?: number;
    optionalAction?: string;
}

export class OptionalNeriticAction
{
    public static CHECK_SHOW_FOR_VIDEO_REMINDER = "checkShowForVideoReminder";
}

export interface ITileAssetInfo
{
    aodEpisodecaId?: string;
    channelId: string;
    channelGuid?: string;
    showGuid?: string;
    episodeGuid? : string;
    vodEpisodeGuid?: string;
    categoryGuid?: string;
    categoryKey?: string;
    recentPlayGuid?: string;
    recentPlayAssetGuid?: string;
    recentPlayType?: string;
    alertGuid? : string;
    favAssetGuid?: string;
    isLiveVideoEligible?: boolean;
    isPlaceholderShow?: boolean;
    showName?: string;
    sequencerTuneChannelId?: string;
    channelName?: string;
    tabSortOrder? : number;
    stationId? : string;
    create? : boolean; /*create flag used to indicate client needs to show create pandora modal*/
    hideFromChannelList? : boolean;
    isPodcast? : boolean;
    isPandoraPodcast?: boolean;
}

export interface ITileBanner
{
    bannerClass: string;
    bannerText: string;
    bannerColor: string;
    display: boolean; // whether to display the banner or not
}

export type TileShapeType = "square" | "rectangle";
