import { of as observableOf,  Observable } from 'rxjs';
import { map, share, first, filter } from 'rxjs/operators';
import { addProvider, ContentTypes, IProviderDescriptor } from "../service";
import { ChromecastPlayerConsts } from "../mediaplayer/chromecastplayer/chromecast-player.consts";
import { ChromecastMessageBus } from "../chromecast/chromecast.message-bus";
import { ApiDelegate, IHttpResponse } from "../http";
import { IClip, TuneResponse } from "../tune";
import { ChromecastModel } from "./chromecast.model";
import { Logger } from "../logger";
import { IRefreshTracksRequestPayload } from "../refresh-tracks";

/**
 * Tune Chromecast service - when Sender is on casting mode,
 * Sender sends messages to R to tune to AIC and to get refresh tracks.
 */
export class TuneChromecastService
{
    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("TuneChromecastService");

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(TuneChromecastService, TuneChromecastService,
            [ ChromecastMessageBus, ChromecastModel ]);
    }();

    /**
     * Constructor
     * @param chromecastMessageBus - can interact with chrome cast receiver by sending and receiving messages
     */
    constructor(private chromecastMessageBus: ChromecastMessageBus,
                private chromecastModel: ChromecastModel)
    {

    }

    /**
     * Tune to Xtra Channel when Sender on casting mode
     * @param channelGuid - channelGuid is tune AIC channel
     */
    public tuneAdditionalChannels(channelGuid: string): Observable<TuneResponse>
    {
        if(this.chromecastModel.isReconnection
            && this.chromecastModel.currentlyPlayingData
            && this.chromecastModel.currentlyPlayingData.tuneResponse
            && this.chromecastModel.currentlyPlayingData.channelId === channelGuid)
        {
            const response = ApiDelegate.getResponseData(
                { data: this.chromecastModel.currentlyPlayingData.tuneResponse.body } as IHttpResponse);
            return observableOf(this.normalizeTracks(response));
        }

        this.chromecastModel.triggerAICTune({
            channelId  : channelGuid,
            assetGuid  : channelGuid,
            mediaType  : ContentTypes.ADDITIONAL_CHANNELS,
            sequencerId: "",
            tracks     : []
        });
        return this.chromecastMessageBus.message$.pipe(
                   filter((event: any) => event.type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.AIC_TUNE_RESPONSE),
                   map((apiResponse: any) =>
                   {
                       TuneChromecastService.logger.debug("tuneAdditionalChannels Response Received" + channelGuid);
                       let response = ApiDelegate.getResponseData({ data: apiResponse.data.body } as IHttpResponse);
                       return this.normalizeTracks(response);
                   }),
                   first(),
                   share());
    }

    /**
     * Used to get the refresh track list when user in casting mode.
     * @param tracks
     */
    public refreshTracks(tracks: Array<any>): Observable<TuneResponse>
    {
        const event = {
            type: ChromecastPlayerConsts.CHROMECAST_SENDER_EVENT.REFRESH_TRACKS,
            data: {
                tracks: tracks
            }
        };

        this.chromecastMessageBus.sendMessage(event);

        return this.chromecastMessageBus.message$.pipe(
                   filter((event: any) => event.type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.REFRESH_TRACKS_RESPONSE),
                   map((apiResponse: any) =>
                   {
                       let response = ApiDelegate.getResponseData({ data: apiResponse.data.body } as IHttpResponse);
                       return this.normalizeTracks(response);
                   }),
                   first(),
                   share());
    }

    /**
     * Tune to Seeded Radio
     * @param sourceId
     */
    public tuneSeededRadio(sourceId: string, stationId: string = "", skipLimitReached: boolean = false ): Observable<TuneResponse>
    {
        if(this.chromecastModel.isReconnection
            && this.chromecastModel.currentlyPlayingData
            && this.chromecastModel.currentlyPlayingData.tuneResponse
            && this.chromecastModel.currentlyPlayingData.channelId === sourceId)
        {
            const response = ApiDelegate.getResponseData(
                { data: this.chromecastModel.currentlyPlayingData.tuneResponse.body } as IHttpResponse);
            return observableOf(this.normalizeSeededTracks(response));
        }

        this.chromecastModel.triggerAICTune({
            channelId  : sourceId,
            assetGuid  : sourceId,
            mediaType  : ContentTypes.SEEDED_RADIO,
            sequencerId: "",
            tracks     : [],
            stationId  : stationId,
            skipLimitReached: skipLimitReached
        });

        return this.chromecastMessageBus.message$.pipe(
                   filter((event: any) => event.type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.SEEDED_TUNE_RESPONSE),
                   map((apiResponse: any) =>
                   {
                       TuneChromecastService.logger.debug("tuneSeededRadio Response Received" + sourceId);
                       let response = ApiDelegate.getResponseData({ data: apiResponse.data.body } as IHttpResponse);
                       return this.normalizeSeededTracks(response);
                   }),
                   first(),
                   share());
    }

    /**
     * Used to get the refresh track list when user in casting mode.
     * @param tracks
     */
    public refreshTracksSeeded (payload: IRefreshTracksRequestPayload): Observable<TuneResponse>
    {
        if(payload.isSkipLimitReached)
        {
            return this.tuneSeededRadio(payload.stationFactory, payload.stationId,  payload.isSkipLimitReached);
        }

        const event = {
            type: ChromecastPlayerConsts.CHROMECAST_SENDER_EVENT.SEEDED_REFRESH_TRACKS,
            data: {
                tracks: payload.tracksParam
            }
        };

        this.chromecastMessageBus.sendMessage(event);

        return this.chromecastMessageBus.message$.pipe(
                   filter((event: any) => event.type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.SEEDED_REFRESH_TRACKS_RESPONSE),
                   map((apiResponse: any) =>
                   {
                       let response = ApiDelegate.getResponseData({ data: apiResponse.data.body } as IHttpResponse);
                       return this.normalizeSeededTracks(response);
                   }),
                   first(),
                   share());
    }

    /**
     * Receiver Sends trackStatus in each clip, app uses status. This method map trackstatus to back to status.
     * @param response
     */
    private normalizeTracks(response: any) : any
    {
        response.additionalChannelData.clipList.clips = response.additionalChannelData.clipList.clips.map((clip) =>
        {
            clip.status = clip.trackStatus;
            return clip;
        });

        return response;
    }

    /**
     * Receiver Sends trackStatus in each clip, app uses status. This method map trackstatus to back to status.
     * @param response
     */
    private normalizeSeededTracks(response: any) : any
    {
        response.seededRadioData.clipList.clips = response.seededRadioData.clipList.clips.map((clip) =>
        {
            clip.status = clip.trackStatus;
            return clip;
        });

        return response;
    }
}
