import { BehaviorSubject } from 'rxjs';
import {
  injectAppConfig,
  injectAudioPlayer,
  injectVideoPlayer,
  Logger,
  IProviderDescriptor,
} from '../servicelib';

import { getWebServiceConfig } from './service.config.web';
class AppConfig {
  constructor() {
    const webServiceConfig = getWebServiceConfig();
    Object.keys(webServiceConfig).forEach(property => {
      this[property] = webServiceConfig[property];
    });
  }
}

async function loadModule() {
  const { default: AudioPlayer } = await import(
    '../web-audio-player/bin/web-audio-player'
  );

  const { default: WebVideoPlayer } = await import(
    '../web-video-player/dist/web-video-player'
  );
  const videoPlayer = new (WebVideoPlayer as any)();

  const audioPlayer = new AudioPlayer({
    logger: Logger.getLogger('sxm-audio-player'),
  });
  const audioPlayerProvider: IProviderDescriptor = {
    provide: audioPlayer,
    useClass: audioPlayer,
    singleton: true,
    deps: [],
  };

  const videoPlayerProvider: IProviderDescriptor = {
    provide: videoPlayer,
    useClass: videoPlayer,
    singleton: true,
    deps: [],
  };

  const serviceConfigProvider: IProviderDescriptor = {
    provide: AppConfig,
    useClass: AppConfig,
    singleton: true,
    deps: [],
  };
  injectAppConfig(serviceConfigProvider);
  injectAudioPlayer(audioPlayerProvider);
  injectVideoPlayer(videoPlayerProvider);
  SXMServiceLayerModule.proceedToInjection$.next(true);
}
export class SXMServiceLayerModule {
  public static proceedToInjection$: BehaviorSubject<
    boolean
  > = new BehaviorSubject<boolean>(false);

  public static async injectAppConfig() {
    //Must only be done at app startup
    await loadModule();
  }
}
