export class SeededStationUpdateItem {
  stationId: string;
  stationFactory: string;
  changeType: string;

  constructor(stationId: string, stationFactory: string) {
    this.stationId = stationId;
    this.stationFactory = stationFactory;
    this.changeType = 'delete';
  }
}

export interface IRemoveSeededStationResponse {
  stationId: string;
  removeAllSeededStations: boolean;
}
