export class ChromecastConst
{
    /**
     * Delay in milliseconds between Chromecast framework load intervals.
     * @type {Number}
     */
    public static LOAD_CAST_INTERVAL_DELAY: number    = 10;
    /**
     * The URL for the Chromecast JavaScript framework.
     * @type {String}
     */
    public static CHROMECAST_SENDER_FRAMEWORK: string = "https://www.gstatic.com/cv/js/sender/v1/cast_sender.js?loadCastFramework=1";

    /**
     * Unique namespace that allows for messaging between the Sender and Receiver and vice versa.
     * @type {String}
     */
    public static MESSAGE_BUS_NAMESPACE: string = "urn:x-cast:siriusxm-chromecast";
    /**
     * Represents a module's ready state.
     * @type {string}
     */
    public static MODULE_READY: string          = "moduleReady";
    /**
     * Flag indicating if chromecasting is supported in this client.
     * @type {boolean}
     */
    public static IS_CASTING_AVAILABLE: boolean = false;
    /**
     * Shortcut variable to the casting framework.
     * NOTE: This cannot be setup until the framework has been loaded.
     * @type {*}
     */
    public static CF: any                       = null;
    /**
     * Shortcut variable to the low-level casting framework.
     * NOTE: This cannot be setup until the framework has been loaded.
     * @type {null}
     */
    public static CC: any                       = null;

    /**
     * Represents connecting message to mininowplaying bar.
     * @type {string}
     */
    public static CASTING_CONNECTING: string = "casting.connectingTo";

    /**
     * Represents session ended message to mininowplaying bar.
     * @type {string}
     */
    public static CASTING_NONE: string = "casting.castingNone";

    /**
     * Represents casting connected to message to mininowplaying bar.
     * @type {string}
     */
    public static CASTING_CONNECTED: string = "casting.castingTo";

    /**
     * Constant for Type of player.
     * @type {string}
     */
    public static CHROMECAST: string = "Chromecast";

    /**
     * Hard code value send the sender name to R.
     * @type {string}
     */
    public static SENDER: string = "web";

}
