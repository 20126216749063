import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import 'core-js';
import 'regenerator-runtime/runtime';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import './index.scss';
import './styles/fonts.scss';
import App from './App';
import { ProvideAuth } from './auth/provide-auth';
import store from './redux/store';

import reportWebVitals from './reportWebVitals';
import smoothscroll from 'smoothscroll-polyfill';
import { Kochava } from 'kochava';

smoothscroll.polyfill();

const KochavaSetup = () => {
  const kochava = Kochava.createForReact();
  kochava.startWithAppGuid('kovizio-hbopxyt');
  return kochava;
};

ReactDOM.render(
  <Provider store={store}>
    <ProvideAuth>
      <BrowserRouter>
        <React.StrictMode>
          <App kochava={KochavaSetup()} />
        </React.StrictMode>
      </BrowserRouter>
    </ProvideAuth>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
