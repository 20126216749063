import { AlternateLoginModuleRequest } from '../http';

export class StandardAlternateAuth extends AlternateLoginModuleRequest {
  public standardAuth: {
    regCode: string;
  };

  constructor(regCode: string) {
    super();

    this.standardAuth = {
      regCode: regCode,
    };
  }
}
