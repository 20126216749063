import React from 'react';
const SplashComponent = React.lazy(() =>
  import('./pages/splash/splash-page.component'),
);
const WelcomeComponent = React.lazy(() =>
  import('./pages/welcome/welcome-page.component'),
);
const LoginComponent = React.lazy(() =>
  import('./pages/login/login-page.component'),
);
const PrivacyPolicyComponent = React.lazy(() =>
  import('./pages/privacy-policy/privacy-policy.component'),
);
const ActivateDeviceComponent = React.lazy(() =>
  import('./pages/activate-device/activate-device.component'),
);
const LoginConfirmationComponent = React.lazy(() =>
  import('./pages/login/login-confirmation.component'),
);
const OpenAccessConfirmationComponent = React.lazy(() =>
  import('./pages/open-access/open-access-confirmation.component'),
);
const OpenAccessExpiredComponent = React.lazy(() =>
  import('./pages/open-access/open-access-expired.component'),
);
const NowPlayingComponent = React.lazy(() =>
  import('./pages/now-playing/now-playing.component'),
);
const HomeComponent = React.lazy(() =>
  import('./pages/home-page/home-page.component'),
);
const FavoritesComponent = React.lazy(() =>
  import('./pages/favorites-page/favorites-page.component'),
);
const RecentComponent = React.lazy(() =>
  import('./pages/recent-page/recent-page.component'),
);
const SearchComponent = React.lazy(() =>
  import('./pages/search-page/search-page.component'),
);
const SearchResultComponent = React.lazy(() =>
  import('./pages/search-page/search-result-page.component'),
);
const PodcastsVideosComponent = React.lazy(() =>
  import('./pages/podcasts-videos/podcasts-videos-page.component'),
);
const EpisodeComponent = React.lazy(() =>
  import('./pages/episode-listing-page/episode-listing.component'),
);
const EnhancedComponent = React.lazy(() =>
  import('./pages/enhanced-edp-page/enhanced-edp-page.component'),
);
const CollectionComponent = React.lazy(() =>
  import('./pages/collection-page/collection.component'),
);
const CategoryComponent = React.lazy(() =>
  import('./pages/category-page/category.component'),
);
const ViewAllPageComponent = React.lazy(() =>
  import('./pages/view-all-page.tsx/view-all.component'),
);
const AllChannelsComponent = React.lazy(() =>
  import('./pages/all-channels-page/all-channels.component'),
);
const SettingsComponent = React.lazy(() =>
  import('./pages/settings-page/settings-page.component'),
);
const Loading = () => (
  <div style={{ backgroundColor: 'transparent', color: 'transparent' }}>
    Loading...
  </div>
);

export {
  SplashComponent,
  WelcomeComponent,
  SearchComponent,
  RecentComponent,
  HomeComponent,
  NowPlayingComponent,
  OpenAccessExpiredComponent,
  OpenAccessConfirmationComponent,
  LoginComponent,
  LoginConfirmationComponent,
  FavoritesComponent,
  SearchResultComponent,
  PodcastsVideosComponent,
  EpisodeComponent,
  EnhancedComponent,
  CategoryComponent,
  CollectionComponent,
  ViewAllPageComponent,
  AllChannelsComponent,
  SettingsComponent,
  Loading,
  PrivacyPolicyComponent,
  ActivateDeviceComponent,
};
