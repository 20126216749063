// Polyfills for XDK4
import 'core-js';
import 'regenerator-runtime/runtime';

// XDK4 Configuration for this app
import config from './config/xdk.config';

// XDK4 Common Config Store
import xdkConfigStore from '@accedo/xdk-config';

xdkConfigStore.load(config);