/**
 * Error types.
 */
export enum EError {
    RESTART,
    UNKNOWN,
    GEORESTRICTED_CONTENT,
    GEOLOCATION_UNDETERMINED,
    GUP_ID_UNAVAILABLE_NEW_USER_BYPASS_INITIATION,
    GUP_LEVEL_BYPASS_INITIATION,
    SAFARI_AUTO_PLAY_PAUSE,
    BUFFER_EMPTY,
    BUFFER_EMPTY_NO_RECOVERY,
    NO_IP_CONNECTION,
    FLTT_CONTENT_ALERT,
    LIVE_VIDEO_REMINDER,
    DECODER_FAILURE,
    FLTT_NO_TRACKS_TO_PLAY,
    FLTT_AIC_BYPASS,
    FLTT_ARTIST_RADIO_BYPASS,
    PLAYBACK_DENIED,
    AUTOPLAY_DENIED,
    INVALID_TOKEN
}
