import {
    IProviderDescriptor,
    IHttpRequestConfig,
    addProvider,
    Logger,
    ServiceEndpointConstants
} from "../index";
import { Observable } from "rxjs";
import { HttpProvider } from "../http/http.provider";

export class SessionTransferDelegate
{
    /**
     * Internal logger.
     * @type {Logger}
     */
    private static logger: Logger = Logger.getLogger("ChromecastServiceApiDelegate");

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(SessionTransferDelegate, SessionTransferDelegate, [ HttpProvider]);
    }();

    /**
     * Constructor
     * @param http - The http object is used to make HTTP API requests.
     */
    constructor(private http: HttpProvider) {}

    public castStatusChange(claimStatus: boolean): Observable<any>
    {
        const config : IHttpRequestConfig = {
            params : {
                claim : claimStatus
            }
        } as IHttpRequestConfig;

        HttpProvider.disableRetryForSessionRecovery(ServiceEndpointConstants.endpoints.CHROMECAST.V4_CAST_STATUS);

        return this.http.get(ServiceEndpointConstants.endpoints.CHROMECAST.V4_CAST_STATUS, null, config);
    }

    public transferSenderSession(): Observable<any>
    {
        return this.http.get(ServiceEndpointConstants.endpoints.CHROMECAST.V4_TRANSFER_SESSION, null, null);
    }
}
