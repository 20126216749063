import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

import { Logger } from '../logger/logger';
import { addProvider } from '../service/providerDescriptors';
import { StorageService } from '../storage/storage.service';
import { ChannelLineupService } from '../channellineup/channel.lineup.service';

import {
  ICurrentlyPlayingMedia,
  IMediaCut,
  IMediaEpisode,
  IMediaItem,
  IMediaShow,
  IMediaVideo,
  IYouJustHeard,
  TunePayload,
  MediaTimeLine,
} from '../tune';
import { MediaUtil } from '../mediaplayer/media.util';
import { CarouselUtil } from '../carousel/carousel.util';
import { VideoPlayerConstants } from '../mediaplayer/videoplayer/video-player.consts';

import { IPausePoint } from '../service/types/pause.point';
import { StorageKeyConstant } from '../storage/storage.constants';
import { DateUtil } from '../util/date.util';

import {
  findMediaItemByTimestamp,
  getArtistNameForCut,
} from '../util/tune.util';
import { IProviderDescriptor } from '../service/provider.descriptor.interface';
import { ContentTypes } from '../service/types';
import { getYouJustHeard } from './you-just-heard-util';
import { IMediaEndPoint } from '../tune/tune.interface';
import {
  AudioPlayerConstants,
  MediaPlayerConstants,
  IPlayhead,
  DeepLinkTypes,
  IDmcaInfo,
  ConfigService,
  translateRelativeUrlsForTheList,
  translateRelativeUrlsForAlbumArt,
  AppMonitorService,
  AppErrorCodes,
  ApiCodes,
} from '../index';
import { MediaTimeLineService } from '../media-timeline/media.timeline.service';
import { IPlayheadTime, Playhead } from '../mediaplayer/playhead.interface';
import { IChannel } from '../channellineup/channels.interfaces';
import { ITile } from '../carousel/raw.tile.interface';

/**
 * @MODULE:     service-lib
 * @CREATED:    06/19/18
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 */
export class CurrentlyPlayingService {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('CurrentlyPlayingService');

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the currentlyPlayingMedia
   * and be notified when the currentlyPlayingMedia data changes.
   * @type {any}
   */
  public currentlyPlayingData: Observable<ICurrentlyPlayingMedia> = null;

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the currentlyPlayingMedia
   * and be notified when the currentlyPlayingMedia data changes.
   * IMPORTANT this one gets updated every tick of the media player.
   * @type {any}
   */
  public currentlyPlayingDataOnTick: BehaviorSubject<
    ICurrentlyPlayingMedia
  > = null;

  /**
   * The Currently playing Media, tracks the playback for every playhead report (tick)
   * @type {any}
   */
  private currentlyPlayingMediaDataOnTick: ICurrentlyPlayingMedia = {} as ICurrentlyPlayingMedia;

  /**
   * The Currently playing Media , represents the current Now playing data for live/AOD.
   * @type {any}
   */
  private currentlyPlayingMediaData: ICurrentlyPlayingMedia = null;

  /**
   * subject for delivering current playing media through the currentlyPlayingMedia observable.
   * @type {any}
   */
  private currentlyPlayingMediaSubject: BehaviorSubject<
    ICurrentlyPlayingMedia
  > = null;

  /**
   * stores the media data
   */
  private mediaTimeLine: MediaTimeLine;

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(CurrentlyPlayingService, CurrentlyPlayingService, [
      ChannelLineupService,
      StorageService,
      MediaTimeLineService,
      AppMonitorService,
      ConfigService,
    ]);
  })();

  /**
   * Constructor
   * @param {ChannelLineupService} channelLineupService
   * @param {StorageService} storageService
   * @param {MediaTimeLineService} mediaTimeLineService
   */
  constructor(
    private channelLineupService: ChannelLineupService,
    private storageService: StorageService,
    private mediaTimeLineService: MediaTimeLineService,
    private appMonitorService: AppMonitorService,
    private configService: ConfigService,
  ) {
    this.currentlyPlayingMediaSubject = new BehaviorSubject(
      this.currentlyPlayingMediaData,
    );
    this.currentlyPlayingData = this.currentlyPlayingMediaSubject;

    this.currentlyPlayingDataOnTick = new BehaviorSubject(
      this.currentlyPlayingMediaData,
    );

    this.mediaTimeLineService.mediaTimeLine.subscribe(data => {
      this.mediaTimeLine = data;
      if (data) {
        this.setCurrentlyPlayingMediaData(data);
      }
    });
  }

  /**
   * Sets the currently playing data by locating the current metadata by the current audio player timestamp.
   *
   * @param {IPlayhead} playhead
   * @param {string} contentType
   */
  public setCurrentPlayingData(playhead: IPlayhead, contentType: string): void {
    if (!this.mediaTimeLine) {
      return;
    } // nothing to do yet,

    /*
        if((MediaUtil.isAudioMediaType(this.mediaTimeLine.mediaType) && contentType === VideoPlayerConstants.TYPE)
            || (MediaUtil.isVideoMediaType(this.mediaTimeLine.mediaType) && contentType === AudioPlayerConstants.TYPE))
        {
            CurrentlyPlayingService.logger.debug(
                `MediaTimeLine switched to other mediaType
                but the old mediaPlayer still emitting plaback : ${ playhead.currentTime.zuluMilliseconds }`
            );
            return;
        }
        */

    if (!playhead || !playhead.startTime || !playhead.currentTime) {
      playhead = new Playhead();
    }

    const episodes = this.mediaTimeLine.episodes || [];
    const cuts = this.mediaTimeLine.cuts || [];
    const videos = this.mediaTimeLine.videos || [];
    const mediaId: string = this.mediaTimeLine.mediaId;
    const channelId: string = this.mediaTimeLine.channelId;
    const stationId: string = this.mediaTimeLine.stationId;
    const channel: IChannel = this.mediaTimeLine.channel;
    const mediaType: string = this.mediaTimeLine.mediaType;
    const video: IMediaVideo = findMediaItemByTimestamp(
      playhead.currentTime.zuluMilliseconds,
      videos,
      false,
    ) as IMediaVideo;
    const relativeUrls = this.configService.getRelativeUrlSettings();
    let cut: IMediaCut = this.findCut(playhead, cuts, mediaType);
    const episode: IMediaEpisode = getEpisode(
      playhead.currentTime.zuluMilliseconds,
      episodes,
      relativeUrls,
    );
    const show: IMediaShow = (episode && episode.show) || ({} as IMediaShow);
    const firstCut: IMediaCut = _.first(cuts);

    this.currentlyPlayingMediaDataOnTick = {
      firstCut: firstCut,
      cut: cut,
      episode: episode,
      mediaType: mediaType,
      mediaId: mediaId,
      channelId: channelId,
      show: show,
      video: video,
      cutEntryTimestamp: playhead.currentTime.zuluMilliseconds, // NOTE: CAN be used for general purpose monitor of playhead
      dmcaInfo: {} as IDmcaInfo,
    };

    this.currentlyPlayingDataOnTick.next(this.currentlyPlayingMediaDataOnTick);

    const isLiveVideoStopped: boolean = video
      ? video.liveVideoStatus ===
        VideoPlayerConstants.LIVE_VIDEO_OFF.toLowerCase()
      : true;
    if (MediaUtil.isVideoMediaTypeLive(mediaType) && isLiveVideoStopped) {
      CurrentlyPlayingService.logger.debug(
        `Switching back to Live Audio as the Live video marker indicates it's off for timestamp: ${playhead.currentTime.zuluMilliseconds}`,
      );
      this.mediaTimeLineService.switchPlayback(this.getCurrentlyPlayingTime());
    }

    // Update the pause points
    this.updatePausePoints(playhead.currentTime);

    /**
     * liveGame will be true, if the live game is blackout for streaming only package.
     */
    if (cut && cut.liveGame) {
      this.appMonitorService.triggerFaultError({
        apiCode: ApiCodes.FLTT_STREAMING_GAME_BLACKOUT,
        faultCode: AppErrorCodes.FLTT_STREAMING_GAME_BLACKOUT,
      });
    }

    // It's highly possible that there's no matching cut by timestamp, so we don't bother updating it; e.g.,
    // only update the currently playing data when there's an actual change and a cut for the current timestamp.
    if (
      isNewCut(this.currentlyPlayingMediaData, cut) ||
      isNewEpisode(this.currentlyPlayingMediaData, episode) ||
      isNewPlaceholderEpisode(this.currentlyPlayingMediaData, episode) ||
      isNewEpisodeWithoutCut(this.currentlyPlayingMediaData, cut, episode) ||
      this.currentlyPlayingMediaData.mediaType !== mediaType ||
      isNewVideoMarker(this.currentlyPlayingMediaData, video)
    ) {
      let youJustHeard: Array<IYouJustHeard> = [];
      if (this.currentlyPlayingMediaData) {
        youJustHeard = getYouJustHeard(
          this.currentlyPlayingMediaData.youJustHeard,
          this.currentlyPlayingMediaData.cut,
        );
      }

      if (cut) {
        cut.lastPlaybackTimestamp = playhead.currentTime.zuluMilliseconds;
      }

      this.currentlyPlayingMediaData = this.createCurrentlyPlayingMedia(
        mediaType,
        mediaId,
        channelId,
        stationId,
        channel,
        playhead.currentTime.zuluMilliseconds, // NOTE: only tracks cut changes, cannot be used for general purpose monitor of playhead
        cut,
        firstCut,
        show,
        episode,
        video,
        youJustHeard,
      );

      const artist: string = getArtistNameForCut(cut);

      // We wrap this in a try/catch as `new Date(input)` throws errors if the input isn't valid
      // and at times the API provides bad timestamp data.
      let isoDateLogMsg = '';
      try {
        const isoDate = DateUtil.convertDateToISO8601Format1(
          new Date(playhead.currentTime.zuluMilliseconds),
        );
        isoDateLogMsg = isoDate ? `Date = ${isoDate}, ` : '';
      } catch (error) {}

      CurrentlyPlayingService.logger
        .debug(`setCurrentlyPlayingData( timestamp = ${playhead.currentTime.zuluMilliseconds},
             ${isoDateLogMsg} artist = ${artist} )`);

      this.currentlyPlayingMediaSubject.next(this.currentlyPlayingMediaData);
    } else if (
      this.currentlyPlayingMediaData &&
      this.currentlyPlayingMediaData.cut
    ) {
      // If we have not experienced a cut change, add the last playback timestamp to the current cut is available.
      // This will be used in the you just heard logic to determine if we have fully played a cut or left the
      // cut in the middle.  Only cuts that have fully played out are reported in YJH
      this.currentlyPlayingMediaData.cut.lastPlaybackTimestamp =
        playhead.currentTime.zuluMilliseconds;
    }

    function isNewCut(
      currentlyPlayingMediaData: ICurrentlyPlayingMedia,
      cut: IMediaCut,
    ): boolean {
      if (!cut || !cut.assetGUID) {
        return false;
      }
      return (
        cut &&
        cut.assetGUID !==
          _.get(currentlyPlayingMediaData, 'cut.assetGUID', null)
      );
    }

    function isNewEpisode(
      currentlyPlayingMediaData: ICurrentlyPlayingMedia,
      episode: IMediaEpisode,
    ): boolean {
      if (!episode || !episode.assetGUID) {
        return false;
      }
      return (
        episode &&
        episode.assetGUID !==
          _.get(currentlyPlayingMediaData, 'episode.assetGUID', null)
      );
    }

    function isNewVideoMarker(
      currentlyPlayingMediaData: ICurrentlyPlayingMedia,
      video: IMediaVideo,
    ): boolean {
      if (!video || !video.assetGUID) {
        return false;
      }
      return (
        video &&
        video.assetGUID !==
          _.get(currentlyPlayingMediaData, 'video.assetGUID', null)
      );
    }

    function isNewPlaceholderEpisode(
      currentlyPlayingMediaData: ICurrentlyPlayingMedia,
      episode: IMediaEpisode,
    ): boolean {
      if (!episode || !episode.show) {
        return false;
      }
      return (
        episode &&
        episode.show.isPlaceholderShow &&
        !_.isEqual(
          episode.times,
          _.get(currentlyPlayingMediaData, 'episode.times', null),
        )
      );
    }

    function isNewEpisodeWithoutCut(
      currentlyPlayingMediaData: ICurrentlyPlayingMedia,
      cut: IMediaCut,
      episode: IMediaEpisode,
    ): boolean {
      if (!cut && !episode) {
        return false;
      }
      return (
        !cut &&
        episode.assetGUID !==
          _.get(currentlyPlayingMediaData, 'episode.assetGUID', null)
      );
    }

    function getEpisode(
      timestamp: number,
      episodes: Array<IMediaEpisode>,
      relativeUrls,
    ): IMediaEpisode {
      let episode: IMediaEpisode = (findMediaItemByTimestamp(
        timestamp,
        episodes,
      ) || {}) as IMediaEpisode;
      if (episode && episode.images) {
        episode.images = translateRelativeUrlsForTheList(
          episode.images,
          relativeUrls,
        );
      }
      if (episode && episode.show && episode.show.images) {
        episode.show.images = translateRelativeUrlsForTheList(
          episode.show.images,
          relativeUrls,
        );
      }
      return episode;
    }
  }

  /**
   * Update the pause points,and then use those pause points with the resume service
   * when reloading the app this allows clients to start playback from exactly where they left off..
   */
  private updatePausePoints(currentTime: IPlayheadTime) {
    let pausePoints = {} as IPausePoint;
    pausePoints.channelId = this.mediaTimeLine.channelId;
    pausePoints.contentType = this.mediaTimeLine.mediaType;
    pausePoints.timestamp = currentTime.zuluMilliseconds;
    pausePoints.caId = this.mediaTimeLine.mediaId;
    pausePoints.publicInfoIdentifier = null;
    const channel = this.channelLineupService.findChannelById(
      pausePoints.channelId,
    );
    pausePoints.channelName = channel ? channel.name : '';

    switch (this.mediaTimeLine.mediaType) {
      case ContentTypes.LIVE_AUDIO:
      case ContentTypes.VOD:
      case ContentTypes.ADDITIONAL_CHANNELS: {
        this.storageService.setItem(
          StorageKeyConstant.PAUSEPOINT,
          JSON.stringify(pausePoints),
        );
        break;
      }
      case ContentTypes.LIVE_VIDEO: {
        pausePoints.contentType = DeepLinkTypes.LIVE_VIDEO;
        this.storageService.setItem(
          StorageKeyConstant.PAUSEPOINT,
          JSON.stringify(pausePoints),
        );
        break;
      }
      case ContentTypes.AOD: {
        pausePoints.publicInfoIdentifier = _.get(
          this.mediaTimeLine,
          'episodes[0].publicationInfo.identifier',
        ) as string;
        this.storageService.setItem(
          StorageKeyConstant.PAUSEPOINT,
          JSON.stringify(pausePoints),
        );
        break;
      }
      case ContentTypes.PODCAST: {
        pausePoints.timestamp =
          currentTime.seconds && !isNaN(currentTime.seconds)
            ? Math.floor(currentTime.seconds)
            : 0;
        pausePoints.publicInfoIdentifier = _.get(
          this.mediaTimeLine,
          'episodes[0].episodeGUID',
        ) as string;
        this.storageService.setItem(
          StorageKeyConstant.PAUSEPOINT,
          JSON.stringify(pausePoints),
        );
        break;
      }
      case ContentTypes.SEEDED_RADIO: {
        pausePoints.channelId = this.mediaTimeLine.channel.stationFactory;
        this.storageService.setItem(
          StorageKeyConstant.PAUSEPOINT,
          JSON.stringify(pausePoints),
        );
        break;
      }
    }
  }

  /**
   * Determines if the requested timestamp has available live video.
   * @param {number} timestamp
   * @returns {boolean}
   */
  public findLiveVideoForZuluTimestamp(timestamp: number): IMediaVideo {
    const videos: IMediaItem[] =
      this.mediaTimeLine && this.mediaTimeLine.videos
        ? this.mediaTimeLine.videos
        : [];
    let video: IMediaVideo = findMediaItemByTimestamp(
      timestamp,
      videos,
      false,
    ) as IMediaVideo;
    video =
      video &&
      video.liveVideoStatus === VideoPlayerConstants.LIVE_VIDEO_ON.toLowerCase()
        ? video
        : null;
    return video;
  }

  /**
   * Returns the currently playing time.
   * @returns {number}
   */
  public getCurrentlyPlayingTime(): number {
    const time: number = _.get(
      this,
      'currentlyPlayingMediaDataOnTick.cutEntryTimestamp',
      0,
    ) as number;
    return time || 0;
  }

  /**
   * Accessor to the live video asset GUID for the now playing data.
   * @returns {number}
   */
  public getLiveVideoAssetGuid(): string {
    const unknown: string = 'Unknown Asset GUID';
    const assetGUID: string = _.get(
      this,
      'currentlyPlayingMediaData.video.assetGUID',
      unknown,
    ) as string;
    return assetGUID || unknown;
  }

  /**
   * Accessor to the currently playing cut's asset GUID.
   * @returns {string}
   */
  public getAssetGuidForCurrentCut(): string {
    return _.get(this, 'currentlyPlayingMediaData.cut.assetGUID', '') as string;
  }

  /**
   * Accessor to the duration for the now playing data.
   * @returns {number}
   */
  public getDuration(): number {
    const duration: number = _.get(
      this,
      'currentlyPlayingMediaData.duration',
      0,
    ) as number;
    return duration || 0;
  }

  /**
   * Accessor to the duration for the now playing data.
   * @returns {number}
   */
  public getCurrentEpisodeZuluStartTime(): number {
    return _.get(
      this,
      'currentlyPlayingMediaData.episode.times.zuluStartTime',
      0,
    ) as number;
  }

  /**
   * Creates a currently playing media data model.
   *
   * @param {string} mediaType with be the type of playback, live or on demand for audio or video
   * @param {string} mediaId will be the channelIdentifier for live, or the assetGuid for the episode for on demand
   * @param {string} channelId will be the same as mediaId for live, and the channel for the episode for on demand
   * @param {IChannel} channel
   * @param {number} timestamp is the latest media player timestamp
   * @param {IMediaCut} cut is an (optional) cut that is currently playing
   * @param {IMediaCut} firstCut is an (optional) first cut for the playback timeline of the content
   * @param {IMediaShow} show is an (optional) show that the content belongs to
   * @param {IMediaEpisode} episode is an (optional) episode that the content belongs to
   * @param {IMediaItem} video is an (optional) video that is available for playback
   * @param {Array<IYouJustHeard>} youJustHeard array of cuts that have been listened to in the past
   * @param {inactivityTimeOut} inactivityTimeOut value
   * @returns {ICurrentlyPlayingMedia}
   */
  private createCurrentlyPlayingMedia(
    mediaType: string,
    mediaId: string,
    channelId: string,
    stationId: string,
    channel: IChannel,
    timestamp: number = 0,
    cut: IMediaCut = null,
    firstCut: IMediaCut = null,
    show: IMediaShow = null,
    episode: IMediaEpisode = null,
    video: IMediaVideo = null,
    youJustHeard: Array<IYouJustHeard> = [],
    inactivityTimeOut: number = 0,
  ): ICurrentlyPlayingMedia {
    const duration: number =
      (episode && episode.duration) || (cut && cut.duration) || NaN;

    const dmcaInfo =
      (episode && episode.dmcaInfo) ||
      (channel && channel.dmcaInfo) ||
      ({} as IDmcaInfo);

    return {
      cut: cut,
      firstCut: firstCut,
      episode: episode,
      cutEntryTimestamp: timestamp,
      mediaType: mediaType,
      mediaId: mediaId,
      channelId: channelId,
      stationId: stationId,
      show: show ? show : ({} as IMediaShow),
      duration: duration * 1000,
      dmcaInfo: dmcaInfo,
      video: video,
      inactivityTimeOut: inactivityTimeOut,
      youJustHeard: youJustHeard,
      channel: channel,
    };
  }

  /**
   * sets the currently playing media data
   * @param mediaTimeLine - tuneMediaTimeLine
   * NOTE: Reason for setting affinity value under cut:  when affinity value updates it updated on mediatimeline,
   * if we always read Cut from currentlyPlayingMediaData which does not have latest affinity value thats why updating
   * affinity value from media time line into currentlyPlayingMediaData.
   */
  private setCurrentlyPlayingMediaData(mediaTimeLine: MediaTimeLine) {
    const currentPlayingMediaId = this.currentlyPlayingMediaData
      ? this.currentlyPlayingMediaData.mediaId
      : '';
    if (
      this.currentlyPlayingMediaData &&
      currentPlayingMediaId === mediaTimeLine.mediaId
    ) {
      let {
        firstCut,
        cut,
        episode,
        show,
        video,
        youJustHeard,
      } = this.currentlyPlayingMediaData;

      if (
        cut &&
        cut.affinity &&
        MediaUtil.isSeededRadioMediaType(mediaTimeLine.mediaType)
      ) {
        const cutFromTimeLine = mediaTimeLine.cuts.find(
          cutLocal => cutLocal.assetGUID === cut.assetGUID,
        );
        cut.affinity = cutFromTimeLine
          ? cutFromTimeLine.affinity
          : cut.affinity;
      }

      this.currentlyPlayingMediaData = this.createCurrentlyPlayingMedia(
        mediaTimeLine.mediaType,
        mediaTimeLine.mediaId,
        mediaTimeLine.channelId,
        mediaTimeLine.stationId,
        this.mediaTimeLine.channel,
        0,
        cut,
        firstCut,
        show,
        episode,
        video,
        youJustHeard,
        mediaTimeLine.inactivityTimeOut,
      );
    } else {
      this.currentlyPlayingMediaData = this.createCurrentlyPlayingMedia(
        mediaTimeLine.mediaType,
        mediaTimeLine.mediaId,
        mediaTimeLine.channelId,
        mediaTimeLine.stationId,
        mediaTimeLine.channel,
      );
      this.currentlyPlayingMediaData.inactivityTimeOut =
        mediaTimeLine.inactivityTimeOut;
    }

    this.currentlyPlayingMediaSubject.next(this.currentlyPlayingMediaData);
  }

  /**
   * Resets the now playing data. This also allows for a tune to the same content that was previously played, which
   * is helpful when On Demand content was played to completion but the user wants to replay it from the beginning.
   *
   * Consider using this directly in the `retune()` method.
   */
  public reset(): void {
    this.currentlyPlayingMediaData = null;
    this.currentlyPlayingMediaSubject.next(this.currentlyPlayingMediaData);
  }

  /**
   * This flag used to trigger currently playing observable . when we tune to new content reset the NP store, if Tune failed
   * we have to set the data back to store .
   */
  public triggerCurrentlyPlayingData(): void {
    this.currentlyPlayingMediaSubject.next(this.currentlyPlayingMediaData);
  }

  /**
   * Returns true if currently "tuned" to payload content.
   * Does not tell you anything about the state of the media player
   *
   * @param {TunePayload} payload
   */
  public isTunedTo(payload: TunePayload): boolean {
    if (!this.currentlyPlayingMediaData) return false;
    if (MediaUtil.isOnDemandMediaType(payload.contentType)) {
      return (
        payload.episodeIdentifier === this.currentlyPlayingMediaData.mediaId
      );
    }
    return payload.channelId === this.currentlyPlayingMediaData.mediaId;
  }

  /**
   * finds the cut based on different criteria.
   * Depending on which type of media is playing.
   */
  public findCut(playhead: IPlayhead, cuts, mediaType): IMediaCut {
    let cut = null;
    if (MediaUtil.isMultiTrackAudioMediaType(mediaType)) {
      cut = cuts.find(cut => cut.assetGUID === playhead.id) as IMediaCut;
    } else {
      cut = findMediaItemByTimestamp(
        playhead.currentTime.zuluMilliseconds,
        cuts,
      ) as IMediaCut;
    }

    if (cut && cut.album && cut.album.creativeArts) {
      const relativeUrls = this.configService.getRelativeUrlSettings();
      cut.album.creativeArts = translateRelativeUrlsForAlbumArt(
        cut.album.creativeArts,
        relativeUrls,
      );
    }
    return cut;
  }

  /**
   * Determines if assetGUID corresponds
   * to the cut directly before the currently playing cut.
   * @returns {boolean}
   */
  public isAssetGUIDPreviousCut(assetGUID: string): boolean {
    if (!this.currentlyPlayingMediaData || !this.currentlyPlayingMediaData.cut)
      return false;

    const cuts = this.mediaTimeLine.cuts || [];

    const argumentCutIndex = cuts.findIndex(item => {
      return item.assetGUID === assetGUID;
    });

    if (argumentCutIndex === -1 || !cuts[argumentCutIndex + 1]) return false;

    return (
      cuts[argumentCutIndex + 1].assetGUID ===
      this.currentlyPlayingMediaData.cut.assetGUID
    );
  }

  /**
   * Returns true if tile is "tuned" to tile content.
   * @param {ITile} tile
   */
  public isTileTuned(tile: ITile): boolean {
    if (!tile || !this.currentlyPlayingMediaData) {
      return false;
    }

    const contentType = CarouselUtil.getContentType(tile);
    const tileAssetInfo = tile.tileAssetInfo;

    switch (contentType) {
      case ContentTypes.CHANNEL:
      case ContentTypes.LIVE_SHOW:
        return this.isTunedToChannel(tileAssetInfo.channelId);

      case ContentTypes.PODCAST:
      case ContentTypes.AOD:
        const episodeGuid = tileAssetInfo.aodEpisodecaId
          ? tileAssetInfo.aodEpisodecaId
          : tileAssetInfo.episodeGuid;
        return this.isTunedToAodEpisode(episodeGuid);

      case ContentTypes.VOD:
        const vodGuid = tileAssetInfo.vodEpisodeGuid
          ? tileAssetInfo.vodEpisodeGuid
          : tileAssetInfo.episodeGuid;
        return this.isTunedToVodEpisode(vodGuid);

      case ContentTypes.SHOW:
        return this.isTunedToShow(tileAssetInfo.showGuid);

      case ContentTypes.ADDITIONAL_CHANNEL:
        return this.isTunedAdditionalChannel(tileAssetInfo.channelGuid);

      case ContentTypes.SEEDED_RADIO:
        return this.isTunedSeededRadioChannel(tileAssetInfo.channelGuid);
    }
    return true;
  }

  /**
   * determines whether provided content/show guid is being played
   * @returns {boolean}
   */
  private isTunedToShow(showGuid): boolean {
    return (
      this.currentlyPlayingMediaData &&
      this.currentlyPlayingMediaData.show &&
      this.currentlyPlayingMediaData.show.assetGUID &&
      this.currentlyPlayingMediaData.show.assetGUID === showGuid
    );
  }

  /**
   * determines whether provided content/aod guid is being played
   * @returns {boolean}
   */
  private isTunedToAodEpisode(aodGuid: string): boolean {
    return (
      aodGuid === this.currentlyPlayingMediaData.mediaId &&
      (this.currentlyPlayingMediaData.mediaType === ContentTypes.AOD ||
        this.currentlyPlayingMediaData.mediaType === ContentTypes.PODCAST)
    );
  }

  /**
   * determines whether provided content/vod guid is being played
   * @returns {boolean}
   */
  private isTunedToVodEpisode(vodGuid: string): boolean {
    return (
      vodGuid === this.currentlyPlayingMediaData.mediaId &&
      this.currentlyPlayingMediaData.mediaType === ContentTypes.VOD
    );
  }

  /**
   * determines whether provided channel is being played
   * @returns {boolean}
   */
  private isTunedToChannel(channelId: string): boolean {
    return (
      channelId === this.currentlyPlayingMediaData.mediaId &&
      this.currentlyPlayingMediaData.mediaType === ContentTypes.LIVE_AUDIO
    );
  }

  /**
   * determines whether provided channel guid is being played
   * @param {string} channelGuid
   * @returns {boolean}
   */
  private isTunedAdditionalChannel(channelGuid: string): boolean {
    return (
      channelGuid === this.currentlyPlayingMediaData.mediaId &&
      this.currentlyPlayingMediaData.mediaType ===
        ContentTypes.ADDITIONAL_CHANNELS
    );
  }

  /**
   * determines whether provided station id is being played
   * @param {string} stationId
   * @returns {boolean}
   */
  private isTunedSeededRadioChannel(stationId: string): boolean {
    return (
      stationId === this.currentlyPlayingMediaData.mediaId &&
      this.currentlyPlayingMediaData.mediaType === ContentTypes.SEEDED_RADIO
    );
  }
}
