import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import {
  ApiCodes,
  ApiDelegate,
  AuthenticationService,
  FreeTierService,
  ResumeService,
  ServiceFactory,
} from '../../servicelib';
import {
  updateFreeTrialCounter,
  showPodcastsMenuOption,
  showVideosMenuOption,
} from '../../redux/action/xdk.action';
import { catchError } from 'rxjs';
import { useHistory } from 'react-router-dom';
import { appRouteConstants } from '../../routing/app.route.constants';
import { terminateFreeTrial as terminateFreeTrialUtil } from '../../utils/free-trial-expiration.util';

const DAY = 86400000; //in ms
const HOUR = 3600000; //in ms;
const MINUTE = 60000; //in ms;
const SECOND = 1000; //in ms;

const countdown = { id: undefined };

const FreeTrialTracker = ({
  setIsOnFreeTrial,
  isOnFreeTrial,
  setIsCoreMenuOpen,
  username,
}) => {
  const freeTierService = ServiceFactory.getInstance(
    FreeTierService,
  ) as FreeTierService;
  const history = useHistory();
  const dispatch = useDispatch();

  const terminateFreeTrial = (
    codes: Array<number>,
    apiMessages: Array<any>,
    response: any,
  ) => {
    const authenticationService = ServiceFactory.getInstance(
      AuthenticationService,
    ) as AuthenticationService;

    authenticationService.logout().subscribe({
      next: () => {
        setIsCoreMenuOpen(true);
        dispatch(showPodcastsMenuOption(false));
        dispatch(showVideosMenuOption(false));
        terminateFreeTrialUtil();
        history.replace(appRouteConstants.AUTH.OPEN_ACCESS_EXPIRED);
      },
    });
  };

  const checkIfFreeTrialhasExpired = () => {
    const resumeService = ServiceFactory.getInstance(
      ResumeService,
    ) as ResumeService;

    //Calling the resume API at this point should send back as a response a 122, meaning that the free trial has expired.
    //The callback for API code 122 is defined above, and will execute the terminateFreeTrial method when detected.
    resumeService
      .resume()
      .pipe(catchError((err, caught) => caught))
      .subscribe();
  };

  useEffect(() => {
    let subscription;

    if (username) {
      const isFreeTrialEnabled = freeTierService.getIsFreeTrialEnabled();

      const apiDelegate = ServiceFactory.getInstance(
        ApiDelegate,
      ) as ApiDelegate;
      subscription = apiDelegate.addApiCodeHandler(
        ApiCodes.FREE_TIER_EXPIRED,
        terminateFreeTrial,
      );

      setIsOnFreeTrial(isFreeTrialEnabled);
    } else {
      clearInterval(countdown.id);
      subscription?.removeCodeHandler();
      setPreviewEndTime(null);
      setIsOnFreeTrial(false);
      countdown.id = undefined;
    }

    return () => {
      subscription?.removeCodeHandler();
    };
  }, [username]);

  const [previewEndTime, setPreviewEndTime] = useState(null);
  useEffect(() => {
    if (isOnFreeTrial) {
      const subscription = freeTierService.previewEndTime$.subscribe(
        previewEndTime => {
          setPreviewEndTime(previewEndTime);
        },
      );

      return () => {
        subscription.unsubscribe();
      };
    }
  }, [isOnFreeTrial]);

  useEffect(() => {
    if (previewEndTime) {
      countdown.id = setInterval(() => {
        const now = moment();
        const expirationDate = moment(previewEndTime);

        const timeRemaining = expirationDate.diff(now);
        let timeRemainingString = '';

        if (timeRemaining >= DAY) {
          const timeRemainingInDays = Math.trunc(timeRemaining / DAY);
          timeRemainingString = `${timeRemainingInDays} day${
            timeRemainingInDays > 1 ? 's' : ''
          }`;
        } else if (timeRemaining >= 0) {
          const hours = Math.trunc(timeRemaining / HOUR);
          const minutes = Math.trunc((timeRemaining % HOUR) / MINUTE);
          const seconds = Math.trunc(
            ((timeRemaining % HOUR) % MINUTE) / SECOND,
          );

          timeRemainingString = `${hours
            .toString()
            .padStart(2, '0')}:${minutes
            .toString()
            .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        } else {
          timeRemainingString = '00:00:00';
          checkIfFreeTrialhasExpired();
          clearInterval(countdown.id);
        }
        dispatch(updateFreeTrialCounter(timeRemainingString));
      }, SECOND);
    }
  }, [previewEndTime]);

  return <div className="free-trial-tracker"></div>;
};

export default FreeTrialTracker;
