import React, { memo, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import isEqual from 'react-fast-compare';
import { focusManager } from '@accedo/vdkweb-navigation';

import styles from './swimlane.module.scss';
import useIsMounted from '../../hooks/useIsMounted';
import useLatest from '../../hooks/useLatest';
import FocusDiv from '../focus-div/FocusDiv';
import { useDispatch, useSelector } from 'react-redux';
import { saveLastFocusedTileId } from '../../redux/action/xdk.action';
import { getLastFocusedTileId } from '../../redux/selector/xdk.store';
import { ttsPlayback } from '../../utils/ttsPlayback.util';

const SwimlaneItemWrapper = <ItemDataType,>({
  index,
  onItemFocus,
  className,
  component: Component,
  nav,
  swimlaneId,
  pageId,
  focusText,
  displayText,
  isWrapperFocus,
  ...props
}: SwimlaneItemWrapperProps<ItemDataType>) => {
  const isMounted = useIsMounted();
  const [clicked, setClicked] = useState(0);
  const [isFocused, setIsFocused] = useState(
    focusManager.getCurrentFocus() === nav.id,
  );
  const lastClickedValueRef = useLatest(clicked);
  const lastFocusedTile = useSelector(getLastFocusedTileId);
  const dispatch = useDispatch();

  const onFocus = useCallback(() => {
    ttsPlayback(
      ` ${focusText?.tileBanner?.bannerText || ''}. ${
        isWrapperFocus.current ? `${displayText}. ` : ''
      } ${
        focusText?.imageAltText === focusText?.line1
          ? ''
          : focusText?.imageAltText || ''
      }  ${focusText?.line1 || ''} ${focusText?.line2 ||
        ''} ${focusText?.line3 || ''} ${focusText?.text || ''}.`,
    );
    isWrapperFocus.current = 0;
    setIsFocused(true);
    onItemFocus(index);
  }, []);

  const onBlur = useCallback(() => {
    if (isMounted()) {
      setIsFocused(false);
    }
  }, []);

  const onClick = useCallback(() => {
    if (isMounted()) {
      dispatch(
        saveLastFocusedTileId({
          ...lastFocusedTile,
          [pageId]: {
            id: nav.id,
            scrollY: window.scrollY,
          },
        }),
      );
      setClicked(lastClickedValueRef.current + 1);
    }
  }, []);

  // Enter if an item initial render is focused
  useEffect(() => {
    if (isFocused) {
      ttsPlayback(
        `${displayText} ${
          focusText?.imageAltText === focusText?.line1
            ? ''
            : focusText?.imageAltText || ''
        } ${focusText?.line1 || ''} ${focusText?.line2 ||
          ''} ${focusText?.line3 || ''} ${focusText?.text || ''}`,
      );
      onItemFocus?.(index);
    }
  }, []);

  return (
    <FocusDiv
      nav={nav}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onClick}
      className={classNames(styles.itemWrapper, className)}
    >
      <Component
        isFocused={isFocused}
        navId={nav.id}
        clicked={clicked}
        swimlaneId={swimlaneId}
        index={index}
        {...props}
      />
    </FocusDiv>
  );
};

export default memo(SwimlaneItemWrapper, isEqual);
