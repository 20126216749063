import { TrackStatus } from '../../tune/tune.interface';

export class MultiTrackConstants {
  public static ENDED: TrackStatus = 'ENDED';
  public static SKIP: TrackStatus = 'SKIP';
  public static ERROR: TrackStatus = 'ERROR';
  public static CURRENT: TrackStatus = 'CURRENT';
  public static FUTURE: TrackStatus = 'FUTURE';
  public static PRELOAD: TrackStatus = 'PRELOAD';
  public static PLAYLIST: string = 'PLAYLIST';
  public static STARTED: string = 'STARTED';
  public static CONSUME: string = 'CONSUME';
}
