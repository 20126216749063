import { combineLatest as observableCombineLatest, BehaviorSubject, Observable } from 'rxjs';
import { first, filter, take, combineLatest } from 'rxjs/operators';
import * as _ from "lodash";
import { IAppConfig } from "../config";
import {
    addProvider,
    IProviderDescriptor,
    Logger,
    ApiDelegate,
    ApiCodes,
    AuthenticationService,
    AppMonitorService,
    AppErrorCodes,
    PlayerTypes,
    MediaUtil
} from "../index";
import { ChromecastConst } from "./chromecast.const";
import { ChromecastModel } from "./chromecast.model";
import { ChromecastUtil } from "./chromecast.util";
import { ChromecastPlayerService } from "../mediaplayer/chromecastplayer/chromecast-player.service";
import { ChromecastPlayerConsts } from "../mediaplayer/chromecastplayer/chromecast-player.consts";
import { ChromecastMessageBus } from "./chromecast.message-bus";
import { ResumeService } from "../resume/resume.service";
import { CurrentlyPlayingService }                                           from "../currently-playing/currently.playing.service";
import { ICurrentlyPlayingMedia, TunePayload } from "../tune/tune.interface";
import { IProfileData }                                                      from "../config/interfaces/all-profiles-data.interface";
import { ChromecastTransferSessionReceiverData, IChromecastData, IMetaData } from "./chromecast.interface";
import { SessionTransferService }                                            from "../sessiontransfer/session.transfer.service";
import { TuneService }                                                       from "../tune/tune.service";
import { InitializationService, InitializationStatusCodes }                  from "../initialization";
import { ContentTypes } from "../service/types/content.types";

export class ChromecastService
{
    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("ChromecastService");

    private _castContext: any = null;

    public get castContext(): any
    {
        return this._castContext;
    }

    /**
     * Indicates the casting state.
     */
    private castState$: BehaviorSubject<string> = new BehaviorSubject("");

    /**
     * Stores the cast state
     * @type {string}
     */
    private castState: string = "";

    /**
     * Indicates the casting session's state.
     */
    private castSessionState$: BehaviorSubject<string> = new BehaviorSubject("");

    /**
     * Stores the cast session state
     * @type {string}
     */
    private castSessionState: string = ChromecastPlayerConsts.STATE.UNKNOWN;

    /**
     * Indicates the casting state.
     */
    public state$: BehaviorSubject<string> = new BehaviorSubject(this.castSessionState);

    /*
     * flag used to indicate whether chromecast player should auto play the content after casting
     */
    public autoplay$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    /**
     * subject for delivering device settings through settings Observable.
     * @type {any}
     */
    private chromecastInfoSubject: BehaviorSubject<IChromecastData> = new BehaviorSubject(null);

    /**
     * chromecast info to populate the toast message
     */
    private chromecastData: IChromecastData;

    /**
     * An observable (hot, subscribe returns most recent item) that can be used to obtain the deviceSettingsList &
     * GlobalSettingsList and be notified when the deviceSettingsList data changes.
     */
    public chromecastInfo: Observable<IChromecastData> = this.chromecastInfoSubject;

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(ChromecastService,
            ChromecastService,
            [
                "IAppConfig",
                ApiDelegate,
                ChromecastModel,
                SessionTransferService,
                AuthenticationService,
                ChromecastPlayerService,
                ChromecastMessageBus,
                ResumeService,
                CurrentlyPlayingService,
                TuneService,
                InitializationService,
                AppMonitorService
            ]);
    }();
    /**
     * Reference to the setInterval() return when checking the load of the Chromecast framework.
     * This needs to be cleared once the framework is loaded.
     * @type {*}
     */
    private loadCastInterval: number                       = null;
    /**
     * Placeholder for the callback method that kicks off the app's use of Chromecast.
     * @type {Function}
     */
    private chromecastInitCallback: Function               = null;

     /**
     * Constructor
     * @param appConfig SERVICE_CONFIG
     * @param apiDelegate
     * @param chromecastModel
     * @param sessionTransferService
     * @param authenticationService
     * @param chromecastPlayerService
     * @param chromecastMessageBus
     * @param resumeService
     * @param currentlyPlayingService
     * @param tuneService
     * @param initializationService
     * @param appMonitorService
     */
    constructor(private appConfig: IAppConfig,
                private apiDelegate: ApiDelegate,
                private chromecastModel: ChromecastModel,
                private sessionTransferService: SessionTransferService,
                private authenticationService: AuthenticationService,
                private chromecastPlayerService: ChromecastPlayerService,
                private chromecastMessageBus: ChromecastMessageBus,
                private resumeService: ResumeService,
                private currentlyPlayingService: CurrentlyPlayingService,
                private tuneService: TuneService,
                private initializationService : InitializationService,
                private appMonitorService: AppMonitorService)
    {
        this.state$.next((appConfig.deviceInfo.isChromeBrowser === false)
                             ? ChromecastPlayerConsts.STATE.DISCONNECTED  // not in Chrome, always disconnected
                             : ChromecastPlayerConsts.STATE.UNKNOWN); // in Chrome, don't know state yet

        this.initChromecast();

        this.chromecastModel.state$            = this.state$;
        this.chromecastModel.castSessionState$ = this.castSessionState$;
        this.chromecastModel.castState$        = this.castState$;

        apiDelegate.addApiCodeHandler(ApiCodes.SESSION_IN_CASTING_MODE, () =>
        {
            const status = this.castState;
            if (status === ChromecastConst.CF.SessionState.SESSION_RESUMED ||
                status === ChromecastConst.CF.CastState.CONNECTED ||
                this.isConnected())
            {
                return;
            }
            ChromecastService.logger.error(`Api ${ApiCodes.SESSION_IN_CASTING_MODE} encountered
            and Casting not in progress , casting state ${status}, trigger cast call to enable tuning`);
            this.sessionTransferService.castStatusChange(true);
        });

        this.setSubscribers();
    }

    /**
     * Returns boolean value to detect chorme cast is connected or not
     */
    public isChromeCastConnected(): boolean
    {
        const status = this.castState;
        return (status.length > 0 && ChromecastConst.CF &&
            (status === ChromecastConst.CF.SessionState.SESSION_RESUMED || status === ChromecastConst.CF.CastState.CONNECTED));
    }

    /**
     * Stops both the casting audio player and casting session.
     */
    public stopAudioAndCasting(): void
    {
        ChromecastService.logger.debug(`stopAudioAndCasting()`);

        this.chromecastPlayerService.stop();
        this.stopCasting();
    }

    /**
     * sends the message to R to update the affinity value
     * @param affinityToUpdate
     */
    public updateAffinity (affinityToUpdate: number): void
    {
        const event = {
            type: ChromecastPlayerConsts.CHROMECAST_SENDER_EVENT.SEEDED_AFFINITY,
            data: { affinity: affinityToUpdate }
        };

        this.chromecastMessageBus.sendMessage(event);
    }

    /**
     * Stops the casting session if it exists.
     */
    public stopCasting(): void
    {
        const session = this.chromecastModel.getSession();
        if (session)
        {
            ChromecastService.logger.debug(`stopCasting()`);

            // Set a flag that we're in  the act of disconnecting from the cast session.
            this.chromecastModel.isDisconnecting = true;

            // End the session and pass 'true' to indicate that receiver application should be stopped.
            session.endSession(true);
        }
    }

    /**
     * Creates the chromecast context And Add event listeners to state and session changes
     */
    public createChromecastContext(): void
    {
        const appId = ChromecastUtil.getAppId(this.appConfig.deviceInfo.appRegion);

        ChromecastService.logger.debug(`init( App ID = ${appId} )`);

        // Container for the cast context config / options.
        const options: any = {

            // Set the receiver application ID to your own (created in the Google Cast Developer Console
            // https://cast.google.com/publish), or optionally use the chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID.
            receiverApplicationId: appId,

            // Auto join policy can be one of the following three:
            // ORIGIN_SCOPED - Auto connect from same appId and page origin
            // TAB_AND_ORIGIN_SCOPED - Auto connect from same appId, page origin, and tab
            // PAGE_SCOPED - No auto connect
            autoJoinPolicy: ChromecastConst.CC.AutoJoinPolicy.ORIGIN_SCOPED
        };

        // Create the cast context and listen for changes to state and session.
        const castContext = ChromecastConst.CF.CastContext.getInstance();
        castContext.setOptions(options);
        castContext.addEventListener(ChromecastConst.CF.CastContextEventType.CAST_STATE_CHANGED, onCastContextStateChange.bind(this));
        castContext.addEventListener(ChromecastConst.CF.CastContextEventType.SESSION_STATE_CHANGED, onCastContextSessionChange.bind(this));

        // Save the object to model so other modules can access it.
        this._castContext = castContext;


        /**
         * Handles the Chromecast state events when connecting and disconnecting to the cast device.
         *
         * If we're attempting to connect then we want to connect then we want to disable the UI.
         * Once connected (or we can't connected) we'll enable the UI again.
         *
         * @param {cast.framework.CastStateEventData} event - Contains details about the cast connection state.
         */
        function onCastContextStateChange(event: any): void
        {
            ChromecastService.logger.debug(`onCastContextStateChange( ${event.castState} )`);
            this.castState = event.castState;
            this.castState$.next(this.castState);

            switch (event.castState)
            {
                case ChromecastConst.CF.CastState.CONNECTING:
                    break;

                case ChromecastConst.CF.CastState.NO_DEVICES_AVAILABLE:
                case ChromecastConst.CF.CastState.NOT_CONNECTED:
                    break;
                case ChromecastConst.CF.CastState.SESSION_RESUMED:
                    ChromecastService.logger.debug("Session_Resumed");
                    break;

                case ChromecastConst.CF.CastState.CONNECTED:
                    this.setCastData(ChromecastConst.CASTING_CONNECTING, this.chromecastModel.deviceFriendlyName);
                    ChromecastService.logger.debug(
                        `onCastContextStateChange( Transfer the session after the resume API call is successful and we have a remote player. )`
                    );
                    this.initiateSessionTransfer();
                    break;
            }
        }

        /**
         * Handles the Chromecast session events: starting, ending, resuming.
         *
         * If we reconnected to an existing session, aka resumed the cast session, then we'll set a flag
         * to indicate that we've reconnected. This is used to help setup the UI when resuming playback
         * with an existing session.
         *
         * @param {cast.framework.SessionStateEventData} event - Contains details about the cast session state.
         */
        function onCastContextSessionChange(event: any): void
        {
            ChromecastService.logger.debug(`onCastContextSessionChange( ${String(event.sessionState)} )`);

            this.castSessionState = event.sessionState;
            this.castSessionState$.next(this.castSessionState);

            switch (event.sessionState)
            {
                case ChromecastConst.CF.SessionState.SESSION_RESUMED:
                    this.chromecastModel.isReconnection = true;
                    this.chromecastPlayerService.switchPlayer(this.chromecastPlayerService);
                    break;

                case ChromecastConst.CF.SessionState.SESSION_ENDED:
                    this.setCastData(ChromecastConst.CASTING_NONE, "");
                    this.chromecastModel.isDisconnecting = false;
                    this.chromecastModel.isReconnection = false;
                    break;

                case ChromecastConst.CF.SessionState.SESSION_START_FAILED:
                    this.chromecastModel.isReconnection = false;
                    break;
            }
        }
    }

    /**
     * Creates the remote player nad remote controller.
     */
    public createRemotePlayerAndController(): void
    {
        // Create the remote player and controller.
        if (!this.chromecastModel.remotePlayer)
        {
            ChromecastService.logger.debug(`createRemotePlayerAndController( Create remote player. )`);
            this.chromecastModel.remotePlayer = new ChromecastConst.CF.RemotePlayer();
        }

        if (!this.chromecastModel.remotePlayerController)
        {
            ChromecastService.logger.debug(`createRemotePlayerAndController( Create remote player controller. )`);
            const remotePlayerController = new ChromecastConst.CF.RemotePlayerController(this.chromecastModel.remotePlayer);

            remotePlayerController.addEventListener(
                ChromecastConst.CF.RemotePlayerEventType.IS_CONNECTED_CHANGED, onRemotePlayerConnectionChange.bind(this));

            remotePlayerController.addEventListener(
                ChromecastConst.CF.RemotePlayerEventType.PLAYER_STATE_CHANGED, onRemotePlayerStateChange.bind(this));

            remotePlayerController.addEventListener(
                ChromecastConst.CF.RemotePlayerEventType.IS_PAUSED_CHANGED, onRemotePlayerPauseChange.bind(this));

            // Save the object to model so other modules can access it.
            this.chromecastModel.remotePlayerController = remotePlayerController;
        }

        if (this.chromecastModel.remotePlayer && this.chromecastModel.remotePlayerController)
        {
            this.state$.next(ChromecastConst.MODULE_READY);
        }

        function onRemotePlayerPauseChange(event: any): void
        {
            this.chromecastModel.chromecastPlayer.setState(event.value);
        }

        function onRemotePlayerStateChange(event : any) : void
        {
            ChromecastService.logger.info(`onRemotePlayerStateChange( ${event} )`);

            const paused = (event.value === ChromecastPlayerConsts.PAUSED || event.value === ChromecastPlayerConsts.IDLE) ? true : false;

            this.chromecastModel.chromecastPlayer.setState(paused);
        }

        /**
         * Handles the connection changes to the Chromecast remote audio player. If connected, then make sure
         * the current audio player is the remote. If disconnected, then make sure the current audio player
         * is the local web client's -- the latter is done by passing falsy for the switch() call.
         */
        function onRemotePlayerConnectionChange(event: any): void
        {
            // If we're in the middle of disconnecting -- usually due to trying to play MySXM -- don't bother
            // going any further.
            if (!this.chromecastModel.isDisconnecting)
            {
                if (ChromecastConst.CF && event.value)
                {
                    ChromecastService.logger.info("onRemotePlayerConnectionChange( Remote player connected! )");
                    this.createRemotePlayerAndController();
                    this.chromecastMessageBus.init();
                    this.state$.next(ChromecastPlayerConsts.STATE.CONNECTED);
                }
                else
                {
                    ChromecastService.logger.info("onRemotePlayerConnectionChange( Remote player disconnected! )");
                    this.chromecastPlayerService.switchPlayer(null);
                    this.state$.next(ChromecastPlayerConsts.STATE.DISCONNECTED);
                }
            }
        }
    }

    /**
     * Accessor that indicates if the Sender is connected to the Chromecast remote player.
     * @returns {boolean}
     */
    public isConnected(): boolean
    {
        return !!this.chromecastModel.remotePlayer.isConnected;
    }

    /**
     * Accessor that indicates if the Sender is paused to the Chromecast remote player.
     * @returns {boolean}
     */
    public isPaused(): boolean
    {
        return !!this.chromecastModel.remotePlayer.isPaused;
    }

    /**
     * Checks the content is supported or not if so then Makes the session transfer call.
     */
    private initiateSessionTransfer(): void
    {
        if (!this.tuneService.isPlayingContentSupported(null, PlayerTypes.REMOTE))
        {
            const mediaType = this.tuneService.getMediaType();
            const faultCode = MediaUtil.isVideoMediaType(mediaType)
                              ? AppErrorCodes.FLTT_CHROME_CAST_CONTENT_NOT_SUPPORTED_VOD
                              : AppErrorCodes.FLTT_CHROME_CAST_CONTENT_NOT_SUPPORTED_AIC;

            this.appMonitorService.triggerFaultError(
                {
                    faultCode: faultCode,
                    metaData :
                        {
                            description: this.chromecastModel.deviceFriendlyName
                        }
                });
            this.chromecastModel.triggerChromcastFatalError();
        }
        else
        {
            this.initializationService
                .initState.pipe(
                filter((state) => state === InitializationStatusCodes.RUNNING),
                take(1))
                .subscribe(() => { this.sessionTransferService.transferSession(); });
        }
    }

    /**
     * Get the chromecast casting status messages to the player component.
     */
    private setCastData(castStatusMessage, deviceName: string): void
    {
        if (!castStatusMessage || (this.chromecastData &&
                this.chromecastData.castStatusMessage &&
                this.chromecastData.castStatusMessage === castStatusMessage)) return;

        this.chromecastData = {
            deviceName       : deviceName,
            castStatusMessage: castStatusMessage
        };

        this.chromecastInfoSubject.next(this.chromecastData);
    }

    /**
     * Sets the Subscribers
     */
    private setSubscribers(): void
    {
        this.observeSessionState();
        this.observeTransferSessionToken();
        this.observeAutoPlay();
        this.observeMessageBus();
        this.observeTuneChanged();
    }

    /**
     * Observes the session state
     * Triggers tuneOut when session state is exited
     */
    private observeSessionState(): void
    {
        ChromecastService.logger.debug(`observeSessionState()`);

        this.authenticationService.userSession.pipe(
            combineLatest(this.chromecastModel.chromcastFatalError$))
            .subscribe(([ session, fatalError ]) =>
            {
                if (this.isChromeCastConnected() && (!session.authenticated || fatalError))
                {
                    this.stopAudioAndCasting();
                }
                if (fatalError)
                {
                    this.chromecastModel.resetChromcastFatalError();
                }
            });
    }

    /**
     * Observes the Transfer session token observable. once data comes sends the message to R.
     */
    private observeTransferSessionToken(): void
    {
        this.sessionTransferService.transferSessionToken.pipe(
            filter((token: string) => !!token))
            .subscribe((token) => this.transferReceiverSession(token));
    }

    /**
     * Observes the auto play value
     */
    private observeAutoPlay(): void
    {
        this.autoplay$.subscribe((autoPlay) => this.chromecastModel.autoPlay = autoPlay);
    }

    /**
     * Observes the message from messagebus
     */
    private observeMessageBus(): void
    {
        this.chromecastMessageBus.message$.subscribe((event: any) =>
        {
            if (event.type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.TRANSFER_SESSION_COMPLETE)
            {
                this.setCastData(ChromecastConst.CASTING_CONNECTED, this.chromecastModel.deviceFriendlyName);
            }
            else if(event.type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.SENDER_DISCONNECT)
            {
                this.stopAudioAndCasting();
            }
            else if(event.type === ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.SENDER_DISCONNECT_RECAST)
            {
                this.initiateSessionTransfer();
            }
        });
    }

    /**
     * Observes the tune changed observable
     */
    private observeTuneChanged(): void
    {
        this.chromecastModel.tuneChanged$.pipe(
            filter(metadata => !!metadata.channelId))
            .subscribe((metaData: IMetaData) =>
            {
                const assetGuid =  metaData.mediaType === ContentTypes.AOD || metaData.mediaType === ContentTypes.VOD ? metaData.assetGuid : null;
                const payLoad: TunePayload = {
                    channelId: metaData.channelId,
                    contentType: metaData.mediaType,
                    episodeIdentifier : assetGuid
                };
                this.tuneService.tune(payLoad).subscribe();
            });
    }

    /**
     * Performs the Receiver's transfer session API call by sending a message to the Receiver to do so.
     *
     * @param {String} url - The session transfer API call's URL for the Receiver.
     * @return {Promise}
     */
    private transferReceiverSession(url: string): void
    {
        if (!url)
        {
            ChromecastService.logger.debug(`transferReceiverSession message not sent due to url not defined( url = ${url} )`);
        }

        ChromecastService.logger.debug(`transferReceiverSession( url = ${url} )`);

        const isValidCurrentlyPlayingData = (data: ICurrentlyPlayingMedia) =>
        {
            return this.chromecastModel.isReconnection || (!!data && !!data.mediaId && !!data.mediaType && !!data.mediaId);
        };

        const isValidProfileData = (data: IProfileData) =>
        {
            return !!data  && !!data.gupId;
        };

        const currentlyPlayingData$: Observable<ICurrentlyPlayingMedia> = this.currentlyPlayingService.currentlyPlayingData.pipe(
                                                                              filter(isValidCurrentlyPlayingData.bind(this)));

        const profileData$: Observable<IProfileData> = this.resumeService.profileData.pipe(
                                                           filter(isValidProfileData));

        const getGupId = (data): string => _.get(data, "gupId") as string;

        observableCombineLatest(
            currentlyPlayingData$,
            profileData$,
            (currentlyPlayingData, profileData) =>
            {
                const channelId = currentlyPlayingData ? currentlyPlayingData.channelId: "";
                const data: ChromecastTransferSessionReceiverData = {

                    // Old from K2
                    transferSessionUrl: url,
                    apiEnvironment    : ChromecastUtil.getApiEnv(this.appConfig),
                    deviceInfo        : this.appConfig.deviceInfo,
                    playbackType      : currentlyPlayingData ? currentlyPlayingData.mediaType : "",

                    // NEW for Everest
                    gupId    : getGupId(profileData),
                    channelId:  currentlyPlayingData && MediaUtil.isSeededRadioMediaType(currentlyPlayingData.mediaType) ?
                                currentlyPlayingData.channel.stationFactory : channelId,
                    assetGuid: currentlyPlayingData ? currentlyPlayingData.mediaId : "",
                    mediaType: currentlyPlayingData ? currentlyPlayingData.mediaType : ""
                };
                const event                                       = {
                    type: ChromecastPlayerConsts.CHROMECAST_SENDER_EVENT.TRANSFER_SESSION,
                    data: data
                };

                ChromecastService.logger.debug(`transferReceiverSession( Sending data: transferSessionUrl = ${url} )`);
                ChromecastService.logger.debug(`transferReceiverSession( Sending data: apiEnvironment = ${data.apiEnvironment} )`);
                ChromecastService.logger.debug(`transferReceiverSession( Sending data: playbackType = ${data.playbackType} )`);
                ChromecastService.logger.debug(`transferReceiverSession( Sending data: gupId = ${data.gupId} )`);
                ChromecastService.logger.debug(`transferReceiverSession( Sending data: channelId = ${data.channelId} )`);
                ChromecastService.logger.debug(`transferReceiverSession( Sending data: assetGuid = ${data.assetGuid} )`);
                this.chromecastMessageBus.sendMessage(event);
            }).pipe(
                  first())
                  .subscribe();
    }

    /**
     * Called by the the AJS Module for Chromecast in the run phase. If the browser is Chrome then
     * we'll load the Chromecast JavaScript Framework. Once loaded we'll kickoff the app's usage of it,
     * otherwise it'll save the callback method so we can invoke it once the framework is ready to rock.
     *
     */
    private initChromecast()
    {
        // DO NOT load the Chromecast Framework unless the browser is Chrome.
        if (this.appConfig.deviceInfo.isChromeBrowser)
        {
            ChromecastService.logger.debug(`initChromecast( Load the Chromecast Framework. )`);

            // Save a reference to the actual Chromecast initialization method inside the app.
            this.chromecastInitCallback = this.onChromecastInit;

            // Load the Chromecast Sender Framework.
            this.loadJS(ChromecastConst.CHROMECAST_SENDER_FRAMEWORK);

            // Start checking for the Chromecast frameworks load...
            this.loadCastInterval = window.setInterval(() => this.checkChromecastLoaded(), ChromecastConst.LOAD_CAST_INTERVAL_DELAY);
        }
        else
        {
            ChromecastService.logger.debug(`initChromecast( DO NOT Load the Chromecast Framework as the browser isn't Chrome. )`);
        }
    }

    /**
     * Entry point for kicking off Chromecasting. Creates the cast context and remote players after getting
     * the A-OK from the global Chromecast API that casting is in fact supported for this browser.
     *
     * This method can be accessed at different times based on the loading conditions of the Chromecast
     * framework and AngularJS, so we make sure it's only called once.
     */
    private onChromecastInit(): void
    {
        ChromecastService.logger.debug(`onChromecastInit()`);

        // Create the context and player manager.
        this.createChromecastContext();
        this.createRemotePlayerAndController();

        this.chromecastModel.chromecastPlayer = this.chromecastPlayerService;
        this.chromecastPlayerService.setRemotePlayerAndController(this.chromecastModel.remotePlayer, this.chromecastModel.remotePlayerController);
    }

    /**
     * Dynamically load a JavaScript file.
     * @param {String} url - The URL to the JS file.
     */
    private loadJS(url: string): void
    {
        ChromecastService.logger.debug(`loadJS( ${url} )`);
        const jsElm = document.createElement("script");
        jsElm.type  = "application/javascript";
        jsElm.src   = url;
        document.body.appendChild(jsElm);
    }

    /**
     * Checks to see chormecast framework loaded or not. If loaded then it invokes the chromcast api.
     */
    private checkChromecastLoaded(): void
    {
        const isFrameworkReady = _.get(window, [
            "cast",
            "framework"
        ], false);
        const isCastReady      = _.get(window, [
            "chrome",
            "cast"
        ], false);
        const isAvailable      = _.get(window, [
            "chrome",
            "cast",
            "isAvailable"
        ], false);
        if (isFrameworkReady && isCastReady && isAvailable)
        {
            ChromecastService.logger.debug(`checkChromecastLoaded( Loaded! )`);
            clearInterval(this.loadCastInterval);
            this.initializeCastApi();
        }
        else
        {
            // NOTE: this thing fires literally 100 times a second. It massively slows down
            //the entire app -- please don't commit this uncommented.
            // ChromecastService.logger.info("checkChromecastLoaded( NOT Loaded yet... )");
        }
    }

    /**
     * Initializes the cast api.
     */
    private initializeCastApi(): void
    {
        ChromecastService.logger.debug(`initializeCastApi()`);
        ChromecastConst.IS_CASTING_AVAILABLE = true;
        ChromecastConst.CF                   = (window[ "cast" ]) ? window[ "cast" ].framework : null;
        ChromecastConst.CC                   = (window[ "chrome" ]) ? window[ "chrome" ].cast : null;

        if (this.chromecastInitCallback && ChromecastConst.CF && ChromecastConst.CC)
        {
            this.chromecastInitCallback();
        }
        else
        {
            ChromecastService.logger.warn("initializeCastApi( Chromecast Framework not available or no init method provided. )");
        }
    }
}
