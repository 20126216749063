import { ENowPlayingStatus } from '../../types/now-playing-status.enum';
import {
  ContentTypes,
  IChannel,
  IDmcaInfo,
  IMediaCut,
  IMediaEpisode,
  IMediaShow,
  IMediaVideo,
  IPlayhead,
} from '../../servicelib';
import { IAction } from '../action/action.interface';
import * as NowPlayingActions from '../action/now-playing.action';
import {
  ChangeContentTypePayload,
  EnterNowPlayingPayload,
  INowPlayingActionPayload,
  INowPlayingRouteActionPayload,
  ISelectVideoPlayerDataActionPayload,
  //NowPlayingAction,
} from '../action/now-playing.action';
import { INowPlayingStore } from '../selector/now-playing.store';

/**
 * Th default or initial store model values.
 */
const initialState: INowPlayingStore = {
  albumName: '',
  albumImage: '',
  artistName: '',
  artistInfo: '',
  backgroundImage: '',
  backgroundColor: '',
  channelName: '',
  channelNumber: '',
  cut: {} as IMediaCut,
  dmcaInfo: {
    irNavClass: '',
  } as IDmcaInfo,
  episode: {
    times: {
      isoEndTime: '',
      isoStartTime: '',
    },
  } as IMediaEpisode,
  mediaId: '',
  mediaType: '',
  nowPlayingStatus: ENowPlayingStatus.ENTERING_FIRST_TIME,
  show: {} as IMediaShow,
  showMiniPlayer: false,
  startTime: 0,
  trackName: '',
  type: '',
  videoPlayerData: {
    mediaAssetMetadata: {
      apronSegments: [],
      episodeTitle: '',
      seriesName: '',
      mediaId: '',
    },
    playheadTime: 0,
    vodEpisode: {} as IMediaEpisode,
  },
  youJustHeard: [],
  video: null,
  lastPlayheadTimestamp: 0,
  playhead: null,
  posterImageUrl: '',
  stationId: '',
  hideFromChannelList: false,
};

/**
 * The default action in case none is provided. Similar to the `initialState`.
 */
const defaultAction: IAction = {
  type: '',
  payload: null,
};

/**
 * Sets the currently playing data on the store.
 */
const selectNowPlaying: Function = (
  state: INowPlayingStore,
  nowPlayingPayload: INowPlayingActionPayload,
): INowPlayingStore => {
  const currentState = getCurrentState(state);

  currentState.albumName = nowPlayingPayload.albumName;
  currentState.albumImage = nowPlayingPayload.albumImage;
  currentState.artistName = nowPlayingPayload.artistName;
  currentState.artistInfo = nowPlayingPayload.artistInfo;
  currentState.backgroundImage = nowPlayingPayload.backgroundImage;
  currentState.channel = nowPlayingPayload.channel;
  currentState.channelName = nowPlayingPayload.channelName;
  currentState.channelNumber = nowPlayingPayload.channelNumber;
  currentState.cut = nowPlayingPayload.cut;
  currentState.dmcaInfo = nowPlayingPayload.dmcaInfo;
  currentState.episode = nowPlayingPayload.episode;
  currentState.mediaId = nowPlayingPayload.mediaId;
  currentState.mediaType = nowPlayingPayload.mediaType;
  currentState.show = nowPlayingPayload.show;
  currentState.trackName = nowPlayingPayload.trackName;
  currentState.type = nowPlayingPayload.type;
  currentState.youJustHeard = nowPlayingPayload.youJustHeard;
  currentState.video = nowPlayingPayload.video;
  currentState.posterImageUrl = nowPlayingPayload.posterImageUrl;
  currentState.stationId = nowPlayingPayload.stationId;
  currentState.hideFromChannelList = nowPlayingPayload.hideFromChannelList;

  return currentState;
};

/**
 *
 */
const selectNowPlayingRoute: Function = (
  state: INowPlayingStore,
  nowPlayingRoutePayload: INowPlayingRouteActionPayload,
): INowPlayingStore => {
  const currentState = getCurrentState(state);

  currentState.assetType = nowPlayingRoutePayload.assetType;
  currentState.channel = nowPlayingRoutePayload.channel;
  currentState.onDemandEpisode = nowPlayingRoutePayload.onDemandEpisode;
  currentState.onDemandShow = nowPlayingRoutePayload.onDemandShow;
  currentState.subCategory = nowPlayingRoutePayload.subCategory;
  currentState.superCategory = nowPlayingRoutePayload.superCategory;

  return currentState;
};

/**
 * Sets the currently playing channel logo's URL on the store.
 */
const selectChannelLogoUrl: Function = (
  state: INowPlayingStore,
  payload: string,
): INowPlayingStore => {
  const currentState = getCurrentState(state);

  currentState.channelLogoUrl = payload;
  return currentState;
};

/**
 * Sets the currently playing channel logo's alt text on the store.
 */
const selectChannelLogoAltText: Function = (
  state: INowPlayingStore,
  payload: string,
): INowPlayingStore => {
  const currentState = getCurrentState(state);

  currentState.channelLogoAltText = payload;
  return currentState;
};

/**
 * Sets the currently playing channel on the store.
 */
const selectPlayingChannel: Function = (
  state: INowPlayingStore,
  payload: IChannel,
): INowPlayingStore => {
  return {
    ...state,
    channel: payload,
  };
};

/**
 * Sets the relevant data on the store to create a video player component
 */
const selectVideoPlayerData: Function = (
  state: INowPlayingStore,
  nowPlayingStatusPayload: ISelectVideoPlayerDataActionPayload,
): INowPlayingStore => {
  const currentState = getCurrentState(state);

  currentState.videoPlayerData = nowPlayingStatusPayload.videoPlayerData;
  return currentState;
};

/**
 * Shows the mini player on the player controls and hide the player control's PDT data.
 */
const showMiniPlayer: Function = (
  state: INowPlayingStore,
  payload: boolean,
): INowPlayingStore => {
  return {
    ...state,
    showMiniPlayer: payload,
  };
};

/**
 * Hides both the mini player on the player controls and the player control's PDT data.
 */
const hideMiniPlayerAndPdt: Function = (
  state: INowPlayingStore,
  payload: boolean,
): INowPlayingStore => {
  return {
    ...state,
    showMiniPlayer: false,
  };
};

/**
 * Sets the start time to continue playback when coming from the mini player as well as the content type
 * and whether or not to show the mini-player and player controls PDT.
 */
const enterNowPlaying: Function = (
  state: INowPlayingStore,
  payload: EnterNowPlayingPayload,
): INowPlayingStore => {
  return {
    ...state,
    startTime: payload.startTime,
    mediaType: payload.mediaType,
    showMiniPlayer: true,
  };
};

/**
 * Fires when leaving the now playing route and saves the start time to continue playback when coming from the mini
 * player as well as the content type and whether or not to show the mini-player and player controls PDT.
 */
const leavingNowPlaying: Function = (
  state: INowPlayingStore,
  payload: EnterNowPlayingPayload,
): INowPlayingStore => {
  return {
    ...state,
    startTime: payload.startTime,
    showMiniPlayer: state.mediaType === ContentTypes.VOD,
  };
};

/**
 * Fires when leaving the now playing route and saves the start time to continue playback when coming from the mini
 * player as well as the content type and whether or not to show the mini-player and player controls PDT.
 */
const changeContentType: Function = (
  state: INowPlayingStore,
  payload: ChangeContentTypePayload,
): INowPlayingStore => {
  return {
    ...state,
    mediaType: payload.mediaType,
    showMiniPlayer: state.mediaType === ContentTypes.VOD,
  };
};

/**
 * Fires when leaving the now playing route and saves the start time to continue playback when coming from the mini
 * player as well as the content type and whether or not to show the mini-player and player controls PDT.
 */
const updateLastPlayhead: Function = (
  state: INowPlayingStore,
  payload: number,
): INowPlayingStore => {
  return {
    ...state,
    lastPlayheadTimestamp: payload,
  };
};

/**
 * Updates the playhead value for the current player.
 */
const updatePlayhead: Function = (
  state: INowPlayingStore,
  payload: IPlayhead,
): INowPlayingStore => {
  return {
    ...state,
    playhead: payload,
  };
};

/**
 * Updates the Video Marker for the current player when the scrubball drags.
 */
const updateVideoMarker: Function = (
  state: INowPlayingStore,
  payload: IMediaVideo,
): INowPlayingStore => {
  return {
    ...state,
    video: payload,
  };
};

/**
 * Gets the current state of the store as a new object.
 */
const getCurrentState: Function = (
  state: INowPlayingStore,
): INowPlayingStore => {
  return Object.assign({}, state);
};

/**
 * Sets the now playing page background color.
 */
const selectBackgroundColor: Function = (
  state: INowPlayingStore,
  payload: string,
): INowPlayingStore => {
  return {
    ...state,
    backgroundColor: payload,
  };
};

/**
 * Sets the now playing page background color.
 */
const updateMediaPlaying: Function = (
  state: INowPlayingStore,
  payload: boolean,
): INowPlayingStore => {
  return {
    ...state,
    isMediaPlaying: payload,
  };
};

/**
 * Fires when tune changes and clears data from store.
 * @param {INowPlayingStore} state
 * @returns {INowPlayingStore}
 */
const resetNowPlaying: Function = (
  state: INowPlayingStore,
): INowPlayingStore => {
  const currentState = getCurrentState(state);

  currentState.albumName = '';
  currentState.albumImage = '';
  currentState.artistName = '';
  currentState.artistInfo = '';
  currentState.backgroundImage = '';
  currentState.backgroundColor = '';
  currentState.channelName = '';
  currentState.channelNumber = '';
  currentState.channel = {};
  currentState.mediaType = '';
  currentState.show = {};
  currentState.trackName = '';
  currentState.type = '';
  currentState.youJustHeard = [];
  currentState.episode = {};
  currentState.isVod = false;
  currentState.isAod = false;
  currentState.stationId = '';
  currentState.hideFromChannelList = false;
  return currentState;
};

/**
 * Return the data store depending on the given action.
 */
export function nowPlayingReducer(
  state: INowPlayingStore = initialState,
  action: IAction = defaultAction,
): INowPlayingStore {
  // TypeScript only provides default params for undefined, so if you pass in null they aren't used...this is
  // why we still account for falsy values with out own defaults.
  state = state || initialState;
  action = action || defaultAction;

  switch (action.type) {
    case NowPlayingActions.SELECT_NOW_PLAYING:
      return selectNowPlaying(
        state,
        action.payload as INowPlayingActionPayload,
      );

    case NowPlayingActions.SELECT_NOW_PLAYING_ROUTE:
      return selectNowPlayingRoute(
        state,
        action.payload as INowPlayingRouteActionPayload,
      );

    case NowPlayingActions.SELECT_CHANNEL_LOGO_URL:
      return selectChannelLogoUrl(state, action.payload as string);

    case NowPlayingActions.SELECT_CHANNEL_LOGO_ALT_TEXT:
      return selectChannelLogoAltText(state, action.payload as string);

    case NowPlayingActions.SELECT_PLAYING_CHANNEL:
      return selectPlayingChannel(state, action.payload as IChannel);

    case NowPlayingActions.SELECT_VIDEO_PLAYER_DATA:
      return selectVideoPlayerData(
        state,
        action.payload as ISelectVideoPlayerDataActionPayload,
      );

    case NowPlayingActions.SHOW_MINI_PLAYER:
      return showMiniPlayer(state, action.payload as boolean);

    case NowPlayingActions.HIDE_MINI_PLAYER_AND_PDT:
      return hideMiniPlayerAndPdt(state, action.payload as boolean);

    case NowPlayingActions.ENTER_NOW_PLAYING:
      return enterNowPlaying(state, action.payload as EnterNowPlayingPayload);

    case NowPlayingActions.LEAVE_NOW_PLAYING:
      return leavingNowPlaying(state, action.payload as EnterNowPlayingPayload);

    case NowPlayingActions.RESET_NOW_PLAYING:
      return resetNowPlaying(state);

    case NowPlayingActions.CHANGE_CONTENT_TYPE:
      return changeContentType(
        state,
        action.payload as ChangeContentTypePayload,
      );

    case NowPlayingActions.UPDATE_LAST_PLAYHEAD:
      return updateLastPlayhead(state, action.payload as number);

    case NowPlayingActions.UPDATE_PLAYHEAD:
      return updatePlayhead(state, action.payload as IPlayhead);

    case NowPlayingActions.UPDATE_VIDEO_MARKER:
      return updateVideoMarker(state, action.payload as IMediaVideo);

    case NowPlayingActions.SELECT_BACKGROUND_COLOR:
      return selectBackgroundColor(state, action.payload as string);

    case NowPlayingActions.UPDATE_MEDIA_PLAYING:
      return updateMediaPlaying(state, action.payload as boolean);

    default:
      return state;
  }
}
