import { IApronSegment } from '../../servicelib';
import { IApronSegmentStore } from '../selector/apron-segment.store';
import { IAction } from './action.interface';

export const SET_APRON_SEGMENTS: string = '[ApronSegments] set';
export const SELECT_CURRENTLY_PLAYING_SEGMENT: string =
  '[ApronSegments] select currently playing';

export class SetApronSegments implements IAction {
  readonly type = SET_APRON_SEGMENTS;

  constructor(public payload: IApronSegmentStore) {}
}

export class SelectCurrentlyPlayingSegment implements IAction {
  readonly type = SELECT_CURRENTLY_PLAYING_SEGMENT;

  constructor(public payload: IApronSegment) {}
}

export type ApronSegmentAction =
  | SetApronSegments
  | SelectCurrentlyPlayingSegment;
