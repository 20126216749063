export class ConsumeActionConsts
{
    public static PASSIVE: string = "passive";
    public static PAUSE: string = "pause";
    public static PASSIVE_PAUSE: string = "pauseA";
    public static ACTIVE: string = "active";
    public static ACTIVE_PAUSE: string = "pauseP";
    public static FORWARD: string = "fwd";
    public static BACK: string = "back";
    public static RESTART: string = "startNow";
    public static SCRUB: string = "scrub";
    public static TUNE_START: string = "tuneStart";
    public static START_NOW: string = "startNow";
    public static TUNE_IN: string = "tuneIn";
    public static LIVE: string = "live";
    public static RESUME: string = "resume";
    public static TUNE_OUT: string = "tuneOut";
    public static ERROR: string = "error";
    public static UNKNOWN: string = "unknown";
}
