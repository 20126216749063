import { BehaviorSubject, Subject } from 'rxjs';
import { BitrateHistoryLogService } from './bitrate-history/bitrate-history-log.service';
import { secondsToMs } from '../../util/utilities';
import { IPlayhead, Playhead } from '../playhead.interface';
import { Logger } from '../../logger/logger';
import { CurrentlyPlayingService } from '../../currently-playing/currently.playing.service';
import { PlayheadTimestampService } from '../playhead-timestamp.service';
import { IProviderDescriptor } from '../../service/provider.descriptor.interface';
import { addProvider } from '../../service/providerDescriptors';
import WebVideoPlayer from '../../../../web-video-player/dist/web-video-player';
import { VideoPlayerConstants } from './video-player.consts';

/**
 * @MODULE:     service-lib
 * @CREATED:    11/29/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * Monitors the video player's events and acts accordingly.
 */
export class VideoPlayerEventMonitor {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('VideoPlayerEventMonitor');

  /**
   * Stream indicating that the video player has been created.
   */
  public playerCreated$: BehaviorSubject<any> = null;

  /**
   * Stream indicating that the video player is ready for playback -- you can now call player.play().
   */
  public playbackReady$: BehaviorSubject<any> = null;

  /**
   * Stream indicating that initial playback has started.
   */
  public initialPlayStarting$: BehaviorSubject<boolean> = null;

  /**
   * Stream indicating that initial playback is playing (fired after initialPlayStarting$).
   */
  public initialPlay$: BehaviorSubject<boolean> = null;

  /**
   * Stream indicating that video player is buffering.
   */
  public loadStart$: Subject<string> = null;

  /**
   * Stream indicating when video player has emitted a seeked event.
   */
  public seeked$: Subject<string> = null;

  /**
   * Stream indicating the volume was changed.
   */
  public volumeChanged$: BehaviorSubject<number> = null;

  /**
   * Stream indicating that the playing event has been triggered
   */
  public playing$: Subject<string> = new Subject();

  /**
   * Stream that emits a flag indicating that the currently loaded video asset has
   * played in its entirety, and a subsequent "play" request has not yet been made.
   */
  public playbackComplete$: BehaviorSubject<boolean> = null;

  /**
   * Stream that emits a paused event when pause is fired.
   */
  public paused$: Subject<string> = new Subject<string>();

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the current playhead
   * timestamp.
   */
  public playhead$: BehaviorSubject<IPlayhead> = null;

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the current seconds the video
   * player has buffered.
   */
  public bufferTime$: BehaviorSubject<number> = null;

  /**
   * An observable (hot, subscribe returns most recent item) that returns most recent asset
   * when asset is changed.
   */
  public assetChanged$: BehaviorSubject<any> = null;

  /**
   * An observable that emits when the video player
   * begins to stall.
   */
  public stalled$: Subject<string> = null;

  /**
   * this subject emits when autoplay is blocked.
   */
  public autoplayBlocked$: Subject<string> = null;

  /**
   * The offset needed to convert the zero based playhead times into zulu based (unix) times
   */
  public playheadOffset: number = 0;

  /**
   * Reference to the native hls video player implementation.
   */
  private videoPlayer;

  /* Absolute time in milliseconds from the HLS live fragment currently playing */
  public programDateTime: number = 0;

  /* Raw program date timestamp */
  public rawProgramDateTime: string = '';

  /* HLS fragment offset, in seconds */
  public start: number = 0;

  public firstProgramDateTime: number = 0;

  /* Number that represents how far the live stream is currently positioned, the 'live edge', zero based */
  public currentLiveDuration: number = 17993.142945;

  /* Stream that emits any HLS errors fired  */
  public playbackError$: Subject<any> = new Subject();

  /* Amount of consecutive times a fragment has has failed loading */
  public fragmentErrorRetries: number = 0;

  /* Stores the level of the last successfully loaded fragment */
  public lastLoadedFragmentLevel: number = -1;

  /* Flag that indicates if the primary stream can't be used anymore */
  public failOverToSecondaryStream: boolean = false;

  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(VideoPlayerEventMonitor, VideoPlayerEventMonitor, [
      CurrentlyPlayingService,
      PlayheadTimestampService,
      BitrateHistoryLogService,
    ]);
  })();
  webVideoPlayer: any;

  /**
   * Constructor.
   * @param {BitrateHistoryLogService} bitrateHistoryLogService
   */
  constructor(private bitrateHistoryLogService: BitrateHistoryLogService) {
    this.playerCreated$ = new BehaviorSubject(null);
    this.playbackReady$ = new BehaviorSubject(null);
    this.initialPlayStarting$ = new BehaviorSubject(false);
    this.initialPlay$ = new BehaviorSubject(false);
    this.loadStart$ = new Subject();
    this.seeked$ = new Subject();
    this.volumeChanged$ = new BehaviorSubject(NaN);
    this.playbackComplete$ = new BehaviorSubject(false);
    this.assetChanged$ = new BehaviorSubject(null);
    this.bufferTime$ = new BehaviorSubject(0);
    this.autoplayBlocked$ = new Subject();
    this.stalled$ = new Subject();

    // Create a stream of playhead timestamps.
    this.playhead$ = new BehaviorSubject(new Playhead());
    this.webVideoPlayer = null;
  }

  videoTest = async () =>
    await import('../../../../web-video-player/dist/web-video-player');

  /**
   * Sets up listeners for audio player events.
   */
  public async startMonitor(videoPlayer) {
    this.webVideoPlayer = (await this.videoTest()).default;
    VideoPlayerEventMonitor.logger.debug('startMonitor()');
    console.warn(videoPlayer);
    this.videoPlayer = videoPlayer;

    this.listenForPlayerCreated();
    this.listenForPlaybackReady();
    this.listenForPlaybackComplete();
    this.listenForPlaying();
    this.listenForPaused();
    this.listenForSeeked();
    this.listenForLoadStart();
    this.listenForPlayhead();
    this.listenForStalled();
    this.listenForAutoplayBlocked();
  }

  /**
     * TODO
     * A player was created. This is the first event that is sent after player creation.
     This event provides the opportunity for any other modules to perform their own initialization.
     The handler is called with the query string parameters.
     The DOM has been created at this point, and plugins may make changes or additions to the DOM.

     The element id.
     The object containing the player page level parameters.
     The object containing the player persistent settings.
     The embed code.
     The timestamp when the player was created.
     The player url.
     */
  public listenForPlayerCreated(): void {
    this.videoPlayer.subscribe(
      this.webVideoPlayer.EVENTS.PLAYER_CREATED,
      () => {
        VideoPlayerEventMonitor.logger.debug(
          'listenForPlayerCreated( PLAYER_CREATED )',
        );
        this.playerCreated$.next(this.videoPlayer);
      },
    );
  }

  /**
     * TODO
     * The player has indicated that it is in a playback-ready state. All preparations are complete, and the player is
     ready to receive playback commands such as play, seek, and so on. The default UI shows the Play button, displaying
     the non-clickable spinner before this point.

     The time from player creation to when it is in the playback ready state.
     */
  private listenForPlaybackReady(): void {
    this.videoPlayer.subscribe(this.webVideoPlayer.EVENTS.PLAYBACK_READY, (
      a /*b, c*/,
    ) => {
      const autoPlayPolicy = !!a;
      VideoPlayerEventMonitor.logger.debug('onPlaybackReady( PLAYBACK_READY )');
      this.playbackReady$.next({ willAutoplay: autoPlayPolicy });

      this.firstProgramDateTime = 0;
      this.currentLiveDuration = 17993.142945;
      this.listeForFragmentLoaded();
    });
  }

  /* Saves the time metada from each fragment that loads to help determine the correct playhead position */
  private listeForFragmentLoaded(): void {
    this.videoPlayer.player.hls.on('hlsFragLoaded', (event, { frag }) => {
      //Clears the error flags and counters, since we know that no event error was triggered if this event gets triggered
      this.fragmentErrorRetries = 0;
      this.lastLoadedFragmentLevel = frag.level;

      if (!this.firstProgramDateTime) {
        //This is used only to get the timestamp of the first fragment loaded that in theory will play, so that the metadata and playhead stored in the
        //store have an accurate start point. Otherwise, the consume service will complain as the playhead will originally not be in epoch format.
        this.firstProgramDateTime = frag.programDateTime;
        this.programDateTime = frag.programDateTime;
        this.rawProgramDateTime = frag.rawProgramDateTime;
      }
    });

    this.videoPlayer.player.hls.on('hlsFragChanged', (event, { frag }) => {
      //This event will trigger as the playhead starts playing a new fragment. Loaded fragments might not necessarily are being played, so
      //we know for sure that the fragment provided by this event is the 10 second audio chunk currently playing.
      this.programDateTime = frag.programDateTime;
      this.rawProgramDateTime = frag.rawProgramDateTime;
      this.start = frag.start;
    });
  }

  /**
   * Listen for the video player to be in the playing state
   */
  private listenForPlaying() {
    this.videoPlayer.subscribe(this.webVideoPlayer.EVENTS.PLAYING, () => {
      VideoPlayerEventMonitor.logger.debug('listenForPlay( PLAYING )');
      this.playing$.next(this.webVideoPlayer.EVENTS.PLAYING);
    });
  }

  /**
   * TODO
   * The currently loaded video asset has played in its entirety.
   * The handler is called with the arguments that were passed.
   */
  private listenForPlaybackComplete(): void {
    this.videoPlayer.subscribe(
      this.webVideoPlayer.EVENTS.PLAYBACK_COMPLETE,
      () => {
        VideoPlayerEventMonitor.logger.debug(
          'listenForPlaybackComplete( PLAYBACK_COMPLETE )',
        );

        this.playbackComplete$.next(true);
        this.playbackComplete$.next(false);
      },
    );
  }

  /**
   * TODO
   * The player was paused. If a PAUSE event is fired by the Ad Manager, the "pauseForAdPlayback" parameter is
   * included as an argument.
   */
  private listenForPaused(): void {
    this.videoPlayer.subscribe(this.webVideoPlayer.EVENTS.PAUSED, () => {
      VideoPlayerEventMonitor.logger.debug('listenForPaused( PAUSED )');
      this.paused$.next(this.webVideoPlayer.EVENTS.PAUSED);
    });
  }

  /**
   * The player has finished seeking the main video to the requested position. The handler is called with the
   * following arguments: The current time of the video after seeking.
   * NOTE: that is a cool feature that could be used maybe.
   */
  private listenForSeeked(): void {
    this.videoPlayer.subscribe(this.webVideoPlayer.EVENTS.SEEKED, () => {
      VideoPlayerEventMonitor.logger.debug('listenForSeeked( SEEKED )');
      this.seeked$.next(VideoPlayerConstants.SEEKED);
    });
  }

  /**
   * TODO
   */
  private listenForLoadStart(): void {
    this.videoPlayer.subscribe(this.webVideoPlayer.EVENTS.LOAD_START, () => {
      VideoPlayerEventMonitor.logger.debug('listenForBuffering( BUFFERING )');
      this.loadStart$.next(VideoPlayerConstants.LOAD_START);
    });
  }

  private listenForStalled(): void {
    this.videoPlayer.subscribe(this.webVideoPlayer.EVENTS.STALLED, () => {
      VideoPlayerEventMonitor.logger.warn('listenForStalled( )');
      this.stalled$.next(this.webVideoPlayer.EVENTS.STALLED);
    });
  }

  /**
   * Listen for playhead timestamp changes.
   *
   * The playhead time changed. The handler is called with the following arguments:
   *
   *  - The current time.
   *  - The duration.
   *  - The buffer time.
   *  - The seek range.
   *  - The id of the video (as defined by the module that controls it).
   */
  private listenForPlayhead(): void {
    // TODO: BMR: 19/23/ 2017: I don't love that the service now knows this is an hls video player. Consider moving to client side.
    this.videoPlayer.subscribe(
      this.webVideoPlayer.EVENTS.PLAYHEAD_TIME_CHANGED,
      data => {
        const playhead = new Playhead();

        playhead.currentTime.milliseconds = secondsToMs(data.playhead);
        playhead.currentTime.seconds = data.playhead;

        //Going back to the beginning of 5 hours on a stream causes the duration of the stream to increase in about 400s out of the blue.
        //This causes problems when returning to the live point, so if there's a sudden abrupt change we ignore the newest duration and
        //keep the lowest, previous one as the stream's true duration
        if (data.duration - this.currentLiveDuration < 100) {
          this.currentLiveDuration = data.duration;
        }

        //If it's the first load of the live stream, the LARGE variant (for v4 variant streams) will have a duration of 17993.142945. As it loads more data
        //the duration should change, indicating that the user has started playback. This avoids the VideoPlayerService's
        //internal state to go from PAUSED to PLAY when the audio hasn't started playing just yet.
        //This fix is only intended for X1 devices, as this does not happen on Chrome.
        if (data.duration !== 17993.142945 && !Number.isNaN(data.duration)) {
          this.playhead$.next(playhead);
        }
      },
    );
  }

  private listenForAutoplayBlocked(): void {
    this.videoPlayer.subscribe(
      this.webVideoPlayer.EVENTS.AUTOPLAY_BLOCKED,
      msg => {
        this.autoplayBlocked$.next(msg);
      },
    );
  }

  public listeForPlaybackErrors(): void {
    this.videoPlayer.player.hls.on('hlsError', (eventName, errorData) => {
      this.playbackError$.next(errorData);
    });
  }
}
