import { Observable } from "rxjs";
import { map, share } from "rxjs/operators";
import { FavoriteUpdateItem } from "./favorite.interface";

import
{
    IProviderDescriptor,
    addProvider,
    HttpProvider,
    Logger,
    ModuleAreaDetailRequest,
    ServiceEndpointConstants
} from "../index";

/**
 * @MODULE:     service-lib
 * @CREATED:    07/27/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *      This delegate is responsible for making calls to get favorite channel list, Favorite Shows And Episodes.
 *      And also responsible to add/remove favorite on channel or show or an aodEpisode.
 */
export class FavoriteDelegate
{
    /**
     * Internal logger.
     * @type {Logger}
     */
    private static logger: Logger = Logger.getLogger("FavoriteDelegate");

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor : IProviderDescriptor = function()
    {
        return addProvider(FavoriteDelegate, FavoriteDelegate, [ HttpProvider ]);
    }();

    /**
     * Constructor
     * @param  http - Used to make API calls
     */
    constructor(private http: HttpProvider) {}

    /**
     * Make API call to update favorite item.
     * @param {FavoriteUpdateItem} favoriteItem that to be udpated
     * @returns {Observable<any>} An observable that can be subscribed to to get the
     * results of the API call.
     */
    public updateFavorite(favorite: FavoriteUpdateItem): Observable<Array<any>>
    {
       return this.updateFavorites([favorite]);
    }

    public updateFavorites(favorites: Array<FavoriteUpdateItem>) : Observable<Array<any>>
    {
        const request = {
            favoritesList: {
                favorites: favorites
            }
        };

        const areaRequest = new ModuleAreaDetailRequest("Profile", "Favorites", request);

        return this.http.postModuleAreaRequest(ServiceEndpointConstants.endpoints.FAVORITES.V4_UPDATE_PRESETS, areaRequest, null)
                   .pipe(map(({ favoritesList: { favorites = [] } }) =>
                   {
                       return favorites;
                   }),
                   share());
    }
}
