/* eslint-disable */
import { BehaviorSubject, Subject } from 'rxjs';

import {
  AppErrorCodes,
  AudioPlayerConstants,
  EError,
  Logger,
  MediaUtil,
  msToSeconds,
} from '../index';
import { IPlayhead, Playhead } from './playhead.interface';
import { ErrorService } from '../error';
import { AudioPlayerEventTypes } from './audioplayer/audio-player.event-types';
import { IAudioPlayerPlayheadEvent } from './audioplayer/audio-player.event.interface';
import { NoopService } from '../noop/index';
import { CurrentlyPlayingService } from '../currently-playing/currently.playing.service';
import { TuneService } from '../tune/tune.service';
import { PlayheadTimestampService } from './playhead-timestamp.service';
import { IProviderDescriptor } from '../index';
import { addProvider } from '../index';

/**
 * @MODULE:     service-lib
 * @CREATED:    10/02/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * Monitors the audio player's events and acts accordingly.
 */
export class AudioPlayerEventMonitor {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('AudioPlayerEventMonitor');

  /**
   * Indicates when the video player is ready for playback.
   */
  public playbackReady$: BehaviorSubject<any> = null;

  /**
   * Indicates when the Audio player is underflowing.
   */
  public bufferEmpty$: BehaviorSubject<any> = null;

  /**
   * Indicates when the Audio player needs to restart.
   */
  public restart$: BehaviorSubject<any> = null;

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the current playhead timestamp.
   */
  public playhead$: BehaviorSubject<IPlayhead> = null;

  /**
   * Stream that emits a flag indicating that the currently loaded media asset has
   * played in its entirety, and a subsequent "play" request has not yet been made.
   */
  public playbackComplete$: BehaviorSubject<boolean> = null;

  /**
   * Stream that emits an audioplayer event indicating that a track has failed.
   */
  public trackFailed$: Subject<IAudioPlayerPlayheadEvent> = new Subject();

  /**
   * Contains permutations of the playhead value in both the zero-based and zulu-based milliseconds and seconds.
   */
  private playhead: IPlayhead = new Playhead();

  /**
   * The duration of the audio content.
   */
  private duration: number = 0;

  /**
   * The start time for the current episode. This is used to calculate the zero-based playhead value by
   * subtracting the episode's zuku start time from the current playhead zulu time.
   */
  private episodeZuluStartTime: number = 0;

  /**
   * The aic guid of the track that you want shown in the pdt when you resume.
   * Two could be loading.
   */
  public firstAicGuid: string = '';

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(AudioPlayerEventMonitor, AudioPlayerEventMonitor, [
      'IAudioPlayer',
      ErrorService,
      CurrentlyPlayingService,
      PlayheadTimestampService,
      NoopService,
      TuneService,
    ]);
  })();

  /**
   * Constructor.
   * @param audioPlayer
   * @param {ErrorService} errorService
   * @param {CurrentlyPlayingService} currentlyPlayingService
   * @param {PlayheadTimestampService} playheadTimestampService
   * @param {NoopService} noopService
   * @param {TuneService} tuneService
   */
  constructor(
    private audioPlayer,
    private errorService: ErrorService,
    private currentlyPlayingService: CurrentlyPlayingService,
    private playheadTimestampService: PlayheadTimestampService,
    private noopService: NoopService,
    private tuneService: TuneService,
  ) {
    // Create a streams from player events.
    this.playhead$ = new BehaviorSubject(this.playhead);
    this.playbackReady$ = new BehaviorSubject(null);
    this.bufferEmpty$ = new BehaviorSubject(false);
    this.restart$ = new BehaviorSubject(false);
    this.playbackComplete$ = new BehaviorSubject(false);

    // Start listening to audio player events.
    this.setAudioPlayerListeners();
  }

  /**
   * Getter accessor for the playhead start value, aka the very first playhead value set when tuning.
   * @returns {number}
   */
  public getPlayheadStart(): number {
    return this.playhead.startTime.zuluMilliseconds;
  }

  /**
   * Getter accessor for the playhead value.
   * @returns {number}
   */
  public getPlayheadTime(): number {
    return this.playhead.currentTime.zuluMilliseconds;
  }

  /**
   * Getter accessor for the duration value.
   * @returns {number}
   */
  public getDuration(): number {
    return this.duration;
  }

  /**
   * Handles when no more tracks to play .
   * @param mediaType
   * @param aicByPassMode
   * @param artistRadioByPassMode
   */
  public handleTracksExhaustedEvent(
    mediaType: string,
    aicByPassMode: boolean,
    artistRadioByPassMode: boolean,
  ): void {
    if (MediaUtil.isAICMediaType(mediaType) && aicByPassMode) {
      this.errorService.handleError({ type: AppErrorCodes.FLTT_AIC_BYPASS });
      return;
    }

    if (MediaUtil.isSeededRadioMediaType(mediaType) && artistRadioByPassMode) {
      this.errorService.handleError({
        type: AppErrorCodes.FLTT_ARTIST_RADIO_BYPASS,
      });
      return;
    }

    this.errorService.handleError({
      type: AppErrorCodes.FLTT_NO_TRACKS_TO_PLAY,
    });
  }

  /**
   * Sets up listeners for audio player events.
   */
  private setAudioPlayerListeners(): void {
    AudioPlayerEventMonitor.logger.debug('setAudioPlayerListeners()');

    this.listenForAudioPlayerEvents();
  }

  /**
   * Listens for the playhead event and uses the zulu timestamp to set the current now playing data.
   * Publish a new playhead timestamp to the playhead stream.
   */
  private listenForAudioPlayerEvents(): void {
    AudioPlayerEventMonitor.logger.debug('listenForPlayhead()');

    this.audioPlayer.addEventListener(
      AudioPlayerEventTypes.PLAYBACK_READY,
      onPlaybackReady.bind(this),
    );
    this.audioPlayer.addEventListener(
      AudioPlayerEventTypes.PLAYHEAD_START,
      onPlaybackStart.bind(this),
    );
    this.audioPlayer.addEventListener(
      AudioPlayerEventTypes.PLAYHEAD,
      onPlayhead.bind(this),
    );
    this.audioPlayer.addEventListener(
      AudioPlayerEventTypes.RESTART,
      onRestart.bind(this),
    );
    this.audioPlayer.addEventListener(
      AudioPlayerEventTypes.TRACK_FAILED,
      onTrackFailed.bind(this),
    );
    this.audioPlayer.addEventListener(
      AudioPlayerEventTypes.BUFFER_EMPTY,
      onBufferEmpty.bind(this),
    );
    this.audioPlayer.addEventListener(
      AudioPlayerEventTypes.TOKEN_INVALID,
      onTokenInvalid.bind(this),
    );
    this.audioPlayer.addEventListener(
      AudioPlayerEventTypes.BEHIND_PLAYLIST,
      onBehindPlaylist.bind(this),
    );
    this.audioPlayer.addEventListener(
      AudioPlayerEventTypes.FINISHED,
      onFinished.bind(this),
    );
    this.audioPlayer.addEventListener(
      AudioPlayerEventTypes.CONNECTIVITY_FAILED,
      onConnectivityFailed.bind(this),
    );
    this.audioPlayer.addEventListener(
      AudioPlayerEventTypes.DECODER_FAILURE,
      onDecoderFailure.bind(this),
    );
    this.audioPlayer.addEventListener(
      AudioPlayerEventTypes.CROSSFADE_POINT_REACHED,
      onCrossfadePointReached.bind(this),
    );
    this.audioPlayer.addEventListener(
      AudioPlayerEventTypes.PLAYBACK_DENIED,
      onPlaybackDenied.bind(this),
    );

    function onPlaybackReady(event: IAudioPlayerPlayheadEvent): void {
      AudioPlayerEventMonitor.logger.debug(`onPlaybackReady( ${event} )`);
      this.playbackReady$.next(event);
      this.playbackComplete$.next(false);
      onPlayback(this, event);
    }

    function onPlaybackStart(event: IAudioPlayerPlayheadEvent): void {
      AudioPlayerEventMonitor.logger.debug(`onPlaybackStart( ${event} )`);
      onPlayback(this, event);
    }

    function onPlayhead(event: IAudioPlayerPlayheadEvent): void {
      AudioPlayerEventMonitor.logger.debug(`onPlayhead( ${event} )`);
      usePlayhead(this, event, true);
    }

    function onPlayback(
      self: AudioPlayerEventMonitor,
      event: IAudioPlayerPlayheadEvent,
    ): void {
      usePlayhead(self, event, true);
      self.errorService.clearError(event);
    }

    function onRestart(event: IAudioPlayerPlayheadEvent): void {
      AudioPlayerEventMonitor.logger.debug(`onRestart( ${event} )`);
      this.errorService.handleError(event);
      this.restart$.next(true);
    }

    function onTrackFailed(event: IAudioPlayerPlayheadEvent): void {
      AudioPlayerEventMonitor.logger.debug(`onTrackFailed( ${event} )`);
      this.trackFailed$.next(event);
    }

    function onBufferEmpty(event: IAudioPlayerPlayheadEvent): void {
      AudioPlayerEventMonitor.logger.debug(`onBufferEmpty( ${event} )`);

      this.bufferEmpty$.next(true);
      this.noopService.noop().subscribe();
    }

    function onTokenInvalid(event: IAudioPlayerPlayheadEvent): void {
      AudioPlayerEventMonitor.logger.debug(`onTokenInvalid( ${event} )`);
      this.errorService.handleError({ type: EError.INVALID_TOKEN });
      this.noopService.noop().subscribe();
    }

    function onBehindPlaylist() {
      AudioPlayerEventMonitor.logger.debug('onBehindPlaylist()');
    }

    function onConnectivityFailed() {
      AudioPlayerEventMonitor.logger.debug('onConnectivityFailed()');
      this.errorService.handleError({
        type: AppErrorCodes.FLTT_NO_IP_CONNECTION,
      });
    }

    function onDecoderFailure() {
      AudioPlayerEventMonitor.logger.debug('onDecoderError)');
      this.errorService.handleError({
        type: AppErrorCodes.FLTT_DECODER_FAILURE,
      });
    }

    function onCrossfadePointReached() {
      AudioPlayerEventMonitor.logger.debug('onCrossfadePointReached');
    }

    /**
     * Handles the error event when autoplay blocks an audio stream from starting.
     */
    function onPlaybackDenied() {
      AudioPlayerEventMonitor.logger.error(
        "onPlaybackDenied, autoplay won't work, only user click will unblocks the audio",
      );
      this.errorService.handleError({ type: EError.PLAYBACK_DENIED });
    }

    function onFinished(event: IAudioPlayerPlayheadEvent) {
      const finishedPlayheadZulu: number =
        this.episodeZuluStartTime + this.duration;
      AudioPlayerEventMonitor.logger.debug(
        `onFinished( ${finishedPlayheadZulu} )`,
      );
      this.playbackReady$.next(null);
      this.playbackComplete$.next(true);
      this.playbackComplete$.next(false);
      this.updatePlayhead(finishedPlayheadZulu, null, event);
    }

    function usePlayhead(
      scope: AudioPlayerEventMonitor,
      event: IAudioPlayerPlayheadEvent,
      setPlayheadStart: boolean = false,
    ): void {
      scope.duration = event.duration;

      const isValidStartTime: boolean =
        setPlayheadStart && event.playheadZulu >= 0;
      const playheadStartZulu: number = isValidStartTime
        ? event.playheadZulu
        : NaN;
      if (!isNaN(playheadStartZulu)) {
        scope.updatePlayhead(event.playheadZulu, playheadStartZulu, event);
      }
    }
  }

  /**
   * Updates the playhead value for the player and broadcasts this change to any listening modules.
   * @param {number} playheadZulu
   * @param {number} playheadStartZulu
   * @param {IAudioPlayerPlayheadEvent} event
   */
  private updatePlayhead(
    playheadZulu: number,
    playheadStartZulu: number = null,
    event?: IAudioPlayerPlayheadEvent,
  ): void {
    if (!this.playhead) {
      this.playhead = new Playhead();
    }

    if (event) {
      this.playhead.id = event.id;
      this.playhead.type = event.type;
    }

    if (playheadStartZulu) {
      this.playhead.startTime.milliseconds = 0;
      this.playhead.startTime.seconds = 0;
      this.playhead.startTime.zuluMilliseconds = playheadStartZulu;
      this.playhead.startTime.zuluSeconds = msToSeconds(playheadStartZulu);
    }

    this.playhead.currentTime.zuluMilliseconds = playheadZulu;
    this.playhead.currentTime.zuluSeconds = msToSeconds(playheadZulu);

    if (this.isNonZeroPlaybackReadyEvent(event)) {
      /*
            Do not send playback ready to cps
            if it is aic
            and not the first track.
            */
      this.playhead.isBackground = true;
    } else if (this.isTransitionalEvent(event)) {
      /*
            Do not give transitional events to the currently playing service.
            A transitional playhead is a playhead from a track whose crossFade point
            has been reached.
            The next track is already playing and emitting playheads that are not
            transitional, so those will take care of themselves.
            */
      this.playhead.isBackground = true;
    } else {
      this.currentlyPlayingService.setCurrentPlayingData(
        this.playhead,
        AudioPlayerConstants.TYPE,
      );
      this.playhead.isBackground = false;
    }

    this.episodeZuluStartTime = this.currentlyPlayingService.getCurrentEpisodeZuluStartTime();

    const playheadMs: number = this.convertPlayheadZuluToZeroBase(playheadZulu);
    this.playhead.currentTime.milliseconds = playheadMs;
    this.playhead.currentTime.seconds = msToSeconds(playheadMs);

    this.playhead$.next(this.playhead);
    this.playheadTimestampService.playhead.next(this.playhead);
  }

  /**
   * Converts
   * @param {number} playheadZulu
   * @returns {number}
   */
  private convertPlayheadZuluToZeroBase(playheadZulu: number): number {
    const result: number = playheadZulu - this.episodeZuluStartTime;
    return result >= 0 ? result : 0;
  }

  /**
   * Determines if the event is for a track that has already reached the
   *  crossFade point and another track has already started.
   */
  public isTransitionalEvent(event: IAudioPlayerPlayheadEvent): boolean {
    const songHasPlayedALittleMs = 2000;

    return (
      event &&
      (event.playbackType === AudioPlayerConstants.MY ||
        event.playbackType === AudioPlayerConstants.SEEDED) &&
      this.currentlyPlayingService.isAssetGUIDPreviousCut(event.id) &&
      this.playhead.currentTime.zuluMilliseconds > songHasPlayedALittleMs
    );
  }

  /**
   * Determines if the event is a playback ready that is emitted
   * for a track other than the first track.
   */
  public isNonZeroPlaybackReadyEvent(
    event: IAudioPlayerPlayheadEvent,
  ): boolean {
    return (
      event &&
      event.type === AudioPlayerEventTypes.PLAYBACK_READY &&
      (event.playbackType === AudioPlayerConstants.MY ||
        event.playbackType === AudioPlayerConstants.SEEDED) &&
      event.id !== this.firstAicGuid
    );
  }

  /**
   * Determines if a playhead is a playhead start event.
   * @returns {boolean}
   */
  public isPlayheadStart(playhead: IPlayhead): boolean {
    return playhead && playhead.type === AudioPlayerEventTypes.PLAYHEAD_START;
  }
}
