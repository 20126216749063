import {
  ServiceFactory,
  MediaPlayerService,
  TuneService,
  StorageService,
  CarouselService,
} from '../servicelib';

import {
  zoneCategory,
  originalDiscoverZoneButtons,
} from '../components/navigation-menu/navigation-menu-utils';

export const terminateFreeTrial = () => {
  const mediaPlayerService = ServiceFactory.getInstance(
    MediaPlayerService,
  ) as MediaPlayerService;
  const tuneService = ServiceFactory.getInstance(TuneService) as TuneService;
  const storageService = ServiceFactory.getInstance(
    StorageService,
  ) as StorageService;
  const carouselService = ServiceFactory.getInstance(
    CarouselService,
  ) as CarouselService;

  if (mediaPlayerService.mediaPlayer) {
    mediaPlayerService.mediaPlayer.stop();
    mediaPlayerService.mediaPlayer.mediaId = null;
    mediaPlayerService.mediaPlayer.mediaType = null;
  }

  tuneService.clearTuneMediaTimeLineData();
  storageService.clearAll();
  carouselService.clearAllCarouselCache();
  zoneCategory.discoverZone.buttons = originalDiscoverZoneButtons;
};
