import { addProvider, EFreeTierFlow, IProviderDescriptor, Logger } from "../index";

import { kochavaAnalyticsConstants } from "./kochava-analytics.constants";
import { KochavaAnalyticsService } from '../analytics/kochava-analytics.service';

export class FreeTierKochavaService {

    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("FreeTierKochavaService");

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(FreeTierKochavaService, FreeTierKochavaService,
            [
                KochavaAnalyticsService
            ]);
    }();

    constructor(private kochavaService: KochavaAnalyticsService){}

    public logAction(actionName: string, flow: EFreeTierFlow, error = null): void
    {
        switch(actionName)
        {
            case 'LoginSuccess': {
                this.loginSuccess(flow);
                break;
            }
            case 'LoginFailure' : {
                this.loginFailure(flow,error);
                break;
            }
        }
    }

    private loginSuccess(flow: EFreeTierFlow): void
    {
        if(flow === EFreeTierFlow.SUBSCRIBE)
        {
            this.kochavaService.recordAction(
                kochavaAnalyticsConstants.SCREEN_NAMES.CREDENTIALS,
                kochavaAnalyticsConstants.FLOWS.SUBSCRIBE,
                kochavaAnalyticsConstants.EVENT_TYPES.RESPONSE,
                kochavaAnalyticsConstants.EVENT_ACTIONS.SUCCESS,
                kochavaAnalyticsConstants.EVENT_NAMES.SUBSCRIBE_CREDENTIALS_SUCCESS);
        }
        else if(flow === EFreeTierFlow.EXPLORE)
        {
            this.kochavaService.recordAction(
                kochavaAnalyticsConstants.SCREEN_NAMES.CREDENTIALS,
                kochavaAnalyticsConstants.FLOWS.EXPLORE,
                kochavaAnalyticsConstants.EVENT_TYPES.RESPONSE,
                kochavaAnalyticsConstants.EVENT_ACTIONS.SUCCESS,
                kochavaAnalyticsConstants.EVENT_NAMES.EXPLORE_CREDENTIALS_SUCCESS);
        }
    }

    private loginFailure(flow:EFreeTierFlow, error)
    {
        // Default Flow and Event name is for ACCESSNOW
        let flowType = kochavaAnalyticsConstants.FLOWS.SIGN_IN_SIGN_UP;
        let eventName = kochavaAnalyticsConstants.EVENT_NAMES.SIGNINSIGNUP_CREDENTIALS_ERROR;

        if(flow == EFreeTierFlow.SUBSCRIBE)
        {
            flowType = kochavaAnalyticsConstants.FLOWS.SUBSCRIBE;
            eventName = kochavaAnalyticsConstants.EVENT_NAMES.SUBSCRIBE_CREDENTIALS_ERROR;
        }
        else if(flow == EFreeTierFlow.EXPLORE)
        {
            flowType = kochavaAnalyticsConstants.FLOWS.EXPLORE;
            eventName = kochavaAnalyticsConstants.EVENT_NAMES.EXPLORE_CREDENTIALS_ERROR;
        }

        this.kochavaService.recordAction(
            kochavaAnalyticsConstants.SCREEN_NAMES.CREDENTIALS,
            flowType,
            kochavaAnalyticsConstants.EVENT_TYPES.RESPONSE,
            kochavaAnalyticsConstants.EVENT_ACTIONS.ERROR,
            eventName,
            error
        );
    }
}
