import { map, share } from 'rxjs/operators';
import { Observable } from 'rxjs';

import {
  IAutoCompleteResult,
  IChannelSearchResults,
  IEpisodeSearchResults,
  IRecentSearchResult,
  ISearchResults,
  IShowSearchResults,
} from './search.interface';

import {
  IProviderDescriptor,
  addProvider,
  HttpProvider,
  Logger,
  ModuleArea,
  ModuleAreaWithSubtype,
  ModuleAreaRequestWithSubtype,
  ServiceEndpointConstants,
  SearchConsts,
} from '../index';
import * as _ from 'lodash';

/**
 * @MODULE:     service-lib
 * @CREATED:    09/18/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * SearchDelegate used to Make API Calls to get the recently played data.
 */

export class SearchDelegate {
  /**
   * Internal Logger
   */
  private static logger: Logger = Logger.getLogger('SearchDelegate');

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(SearchDelegate, SearchDelegate, [HttpProvider]);
  })();

  /**
   * Constructor
   * @param http - Used to make API calls
   */
  constructor(private http: HttpProvider) {}

  /**
   * Make the HTTP API call to get the Recently played items.
   * @returns {Observable<Array<IRecentSearchResult>>}  an observable that can be subscribed to to get the results of the API call
   */
  public getRecentSearchResults(): Observable<Array<IRecentSearchResult>> {
    SearchDelegate.logger.debug('getRecentSearchResults()');

    const areaRequests = new Array<ModuleArea>();
    areaRequests.push(
      new ModuleAreaWithSubtype(
        SearchConsts.MODULE_AREA,
        null,
        SearchConsts.RECENT_SUBTYPE,
      ),
    );

    return this.http
      .postModuleAreaRequests(
        ServiceEndpointConstants.endpoints.SEARCH.V2_GET_RECENT_SEARCH_LIST,
        areaRequests,
        null,
      )
      .pipe(
        map(response => {
          const result = response.profileData.searchList.searches
            ? response.profileData.searchList.searches
            : [];
          return this.normalizeRecentSearchResults(result);
        }),
        share(),
      );
  }

  /**
   * Make the HTTP API call to get the Recently played items.
   * @returns {Observable<ISearchResults>}  an observable that can be subscribed to to get the results of the API call
   */
  public getSearchResults(keyword): Observable<ISearchResults> {
    SearchDelegate.logger.debug('getSearchResults()');

    const request = {
      searchRequest: {
        searchString: keyword,
      },
    };
    const areaRequests = new Array<ModuleArea>();
    areaRequests.push(
      new ModuleAreaRequestWithSubtype(
        SearchConsts.MODULE_AREA,
        null,
        SearchConsts.SEARCH_RESULTS_SUBTYPE,
        request,
      ),
    );

    return this.http
      .postModuleAreaRequests(
        ServiceEndpointConstants.endpoints.SEARCH.V2_GET_SEARCH_RESULT,
        areaRequests,
        null,
      )
      .pipe(
        map(response => {
          const result = _.get(
            response,
            'sxmSearch[0].searchResultList.searchResult',
            [],
          );
          return this.normalizeSearchResults(result);
        }),
        share(),
      );
  }

  /**
   * Make the HTTP API call to get the Recently played items.
   * @returns {Observable<Array<IAutoCompleteResult>>}  an observable that can be subscribed to to get the results of the API call
   */
  public getAutoCompleteSearchResults(
    keyword,
  ): Observable<Array<IAutoCompleteResult>> {
    SearchDelegate.logger.debug('getAutoCompleteSearchResults()');

    const request = {
      searchRequest: {
        searchString: keyword,
      },
    };
    const areaRequests = new Array<ModuleArea>();
    areaRequests.push(
      new ModuleAreaRequestWithSubtype(
        SearchConsts.MODULE_AREA,
        null,
        SearchConsts.AUTO_SUGGEST_SUBTYPE,
        request,
      ),
    );

    return this.http
      .postModuleAreaRequests(
        ServiceEndpointConstants.endpoints.SEARCH.V2_GET_AUTO_POPULATE,
        areaRequests,
        null,
      )
      .pipe(
        map(response => {
          const result = _.get(
            response,
            'sxmSearch[0].searchResultList',
            [],
          );

          result.splice(SearchConsts.MAX_SUGGESTED_RESULTS);

          return result;
        }),
        share(),
      );
  }

  /**
   * Used to normalize theSearchResult.
   * @param searchResults
   * @returns {Array<ISearchResults>}
   */
  private normalizeSearchResults(searchResults: any): ISearchResults {
    const episodes: Array<IEpisodeSearchResults> = [];
    const shows: Array<IShowSearchResults> = [];
    const channels: Array<IChannelSearchResults> = [];

    let channelItem: IChannelSearchResults,
      episodeItem: IEpisodeSearchResults,
      showItem: IShowSearchResults;

    searchResults.forEach(searchResultItem => {
      channelItem = _.get(
        searchResultItem,
        'searchAsset.channel',
      ) as IChannelSearchResults;
      episodeItem = _.get(
        searchResultItem,
        'searchAsset.episode',
      ) as IEpisodeSearchResults;
      showItem = _.get(
        searchResultItem,
        'searchAsset.show',
      ) as IShowSearchResults;

      if (showItem) shows.push(showItem);
      if (channelItem) channels.push(channelItem);
      if (episodeItem) episodes.push(episodeItem);
    });

    const resultItem = {
      episodes: episodes,
      shows: shows,
      channels: channels,
    };

    return resultItem;
  }

  /**
   * Normalize recentlySearchList by converting date time strings in the response should to Javascript Date objects
   * @param recentlySearchList - List of recently Searched data from API
   * @returns Array<IRecentSearchResult>
   */
  private normalizeRecentSearchResults(
    recentlySearchList,
  ): Array<IRecentSearchResult> {
    return recentlySearchList.map(recentlySearchItem => {
      recentlySearchItem.searchDateTime = new Date(
        recentlySearchItem.searchDateTime,
      );
      return recentlySearchItem;
    });
  }
}
