import { of as observableOf,  Observable } from 'rxjs';
import { share, take, catchError, map } from 'rxjs/operators';
import {
    IProviderDescriptor,
    addProvider
}                         from "../service";
import { Logger }         from "./logger";
import { LogDelegate }    from "./log-delegate";
import { ProfileService } from "../profile";
import { saveAs }         from 'file-saver';

/**
 * @MODULE:     service-lib
 * @CREATED:    04/03/19
 * @COPYRIGHT:  2019 Sirius XM Radio Inc.
 * @VERSION:    3.22
 *
 * @DESCRIPTION:
 *
 * LogService to allow sending client logs to the API.
 */
export class LogService
{
    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("LogService");

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor : IProviderDescriptor = function()
    {
        return addProvider(
            LogService,
            LogService,
            [LogDelegate,ProfileService]
        );
    }();

    /**
     * Constructor
     * @param logDelegate is the API delegate for making the API call
     * @param profileService is where we get the user gupID for sending logs to the API
     */
    constructor(private logDelegate: LogDelegate,
                private profileService : ProfileService) {}

    /**
     * Get the log history from the logger, the gupid from the profileService and send those to the API to
     * be stored and accessed later for debugging purposes
     *
     * @param saveLocal is true if the logs should also be "downloaded" locally.  On browsers this will make a log.txt
     *        file available in the users downloads
     */
    public sendLogs(saveLocal : boolean): Observable<boolean>
    {
        var logs  = Logger.logHistory;
        var gupId = this.profileService.getGupId();

        if (saveLocal === true)
        {
            try
            {

                var logString = logs.reduce((accum : string, current : string) => accum + '\n' + current, "");

                var blob = new Blob([ logString ], { type : "text/plain;charset=utf-8" });

                saveAs(blob, "log.txt");
            }
            catch (error)
            { LogService.logger.error(`Could not download logs! ${ error }`); }
        }

        const obs = this.logDelegate.sendLogs(logs,gupId).pipe(
                        map(() => true),
                        catchError(() => observableOf(false)),
                        take(1), // only one value ever emitted
                        share()); // only one call made no matter how many subscribers there are

        obs.subscribe(() => {} ); // make sure there is at least one subscriber so the API call is made

        return obs;
    }
}
