import * as _ from 'lodash';
import { Logger } from '../logger/logger';
import { HttpProvider } from '../http/http.provider';
import { IProviderDescriptor } from '../service/provider.descriptor.interface';
import { addProvider } from '../service/providerDescriptors';
import { Observable, of, timer } from 'rxjs';
import { map, flatMap, filter, take, catchError, share } from 'rxjs/operators';
import { NowPlayingConsts } from '../service/consts/api.request.consts';
import { ServiceEndpointConstants } from '../service/consts/service.endpoint.constants';
import { ApiLayerTypes } from '../service/consts/api.types';
import { TuneResponse } from '../tune/tune.interface';
import { TuneDelegate } from '../tune/tune.delegate';
import { ModuleListRequest } from '../http/types/module.list.request';
import { IRefreshTracksRequestPayload } from './refresh.tracks.interface';
import { RefreshTracksAICModuleRequest } from '../http/types/module.list.request';
import { TuneChromecastService } from '../chromecast/tune.chromecast.service';
import { PlayerTypes } from '../service/types';
import { IRelativeUrlSetting } from '../config';
import { SeededRadioModuleRequest } from '../http/types/module.list.request';
import { RefreshTracksModel } from './refresh-tracks.model';
import { ITuneModel, TuneModel } from '../tune/tune.model';
import { ApiCodes, ClientCodes } from '../service/consts';
//import { testObservable } from "../test/test.util";

/**
 * @MODULE:     service-lib
 * @CREATED:    10/31/18
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * RefreshTracksDelegate used to Make API Calls to refresh clips.
 */

export class RefreshTracksDelegate {
  /**
   * Internal logger.
   * @type {Logger}
   */
  private static logger: Logger = Logger.getLogger('RefreshTracksDelegate');

  private mediaId: string = '';

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(RefreshTracksDelegate, RefreshTracksDelegate, [
      HttpProvider,
      TuneChromecastService,
      RefreshTracksModel,
      TuneModel,
    ]);
  })();

  public static SEEDED_RETRIES_ALLOWED = 10;

  /**
   * Constructor
   * @param http - Used to make API calls
   * @param tuneChromecastService is the chromecast service for tuning a receiver
   * @param refreshTracksModel is the model for tracking the state of the refresh tracks service
   * @param tuneModel is the model for tracking the state of the tune service
   */
  constructor(
    private http: HttpProvider,
    private tuneChromecastService: TuneChromecastService,
    private refreshTracksModel: RefreshTracksModel,
    private tuneModel: TuneModel,
  ) {
    this.tuneModel.tuneModel$.subscribe((tuneModel: ITuneModel) => {
      if (
        tuneModel.tuneState === 'IDLE' &&
        tuneModel.tuneStatus === 'SUCCESS' &&
        tuneModel.currentMetaData
      ) {
        this.refreshTracksModel.currentMetaData = {
          mediaId: tuneModel.currentMetaData.mediaId,
          channelId: tuneModel.currentMetaData.channelId,
          mediaType: tuneModel.currentMetaData.mediaType,
        };
      }
    });
  }

  /**
   * Makes the api call to get updated clip list
   * @param {any} payload
   * @returns {Observable<any>}
   */
  public refreshTracksAIC(
    payload: any,
    relativeUrls: Array<IRelativeUrlSetting> = [],
    indexToStart: number = 0,
  ): Observable<any> {
    const moduleRequest = new RefreshTracksAICModuleRequest();

    moduleRequest.type = NowPlayingConsts.ADDITIONALCHANNELREFRESH.type;
    moduleRequest.sessionId = payload.sequencerSessionId;
    moduleRequest.channelGuid = payload.channelGUID;
    moduleRequest.tracks = payload.tracksParam;

    const moduleListRequest = new ModuleListRequest();

    moduleListRequest.addModuleRequest(moduleRequest);

    const call =
      payload.playerType === PlayerTypes.REMOTE
        ? this.tuneChromecastService.refreshTracks(payload.tracksParam)
        : this.http.post(
            ServiceEndpointConstants.endpoints.ADDITIONAL_CHANNELS.V4_REFRESH,
            moduleListRequest,
          );
    return call.pipe(map(handleResponse.bind(this)));

    function handleResponse(response: any): TuneResponse {
      const mediaData = _.get(response, ApiLayerTypes.RESPONSE_TYPE_AIC) as any;
      mediaData.mediaType = response.moduleType.toLowerCase();
      mediaData.updateFrequency = response.updateFrequency;

      const tuneResponse = new TuneResponse();
      tuneResponse.liveCuePoint = undefined;
      tuneResponse.channelId = mediaData.channelId;
      tuneResponse.mediaType = mediaData.mediaType;

      return TuneDelegate.normalizeAdditionalChannelData(
        mediaData,
        tuneResponse,
        relativeUrls,
        indexToStart + 1,
      );
    }
  }

  /**
   * Makes the api call to get updated clip list
   * @param {IRefreshTracksRequestPayload} payload
   * @returns {Observable<any>}
   */
  public refreshTracksSeeded(
    payload: IRefreshTracksRequestPayload,
    retry: number = 0,
  ): Observable<any> {
    const mediaId =
      this.refreshTracksModel && this.refreshTracksModel.currentMetaData
        ? this.refreshTracksModel.currentMetaData.mediaId
        : '';

    return this.tuneModel.tuneModel$.pipe(
      filter(tuneModel => {
        // Only perform a refresh tracks call if the tune model is in the IDLE state.  This will
        // prevent CDN access token cookies in tune responses from conflicting with CDN access tokens
        // from refresh tracks responses.
        return tuneModel.tuneState === 'IDLE';
      }),
      take(1),
      flatMap(tuneModel => {
        // If the media ID has changed from before we checked to see if the tuneState was tuned, this
        // means that something new was tuned.  Fail the request because it was made for old content
        if (mediaId && tuneModel.currentMetaData.mediaId !== mediaId) {
          return of(ClientCodes.ERROR);
        }

        return this.makeSeededRefreshTracksCall(payload).pipe(
          filter(response => !!response),
          map(response => this.handleSeededRefreshTracksResponse(response)),
          catchError(error =>
            this.handleSeededRefreshTracksError(error, retry, payload),
          ),
        );
      }),
      share(),
    );
  }

  /**
   * Make the actual call to get more tracks.  This will either be sent over http to the API, or it will be sent
   * to a Chromecast receiver if we are currently casting.
   *
   * @param payload is the specifics for the call to be made
   * @return an Observable that will trigger with the TuneResponse when a response has been received
   */
  private makeSeededRefreshTracksCall(
    payload: IRefreshTracksRequestPayload,
  ): Observable<TuneResponse> {
    RefreshTracksDelegate.logger.debug('refreshTracks()');

    const moduleRequest = new SeededRadioModuleRequest();

    moduleRequest.type = NowPlayingConsts.SEEDEDRADIOSPARAMS.type;
    moduleRequest.stationFactory = payload.stationFactory;
    moduleRequest.stationId = payload.stationId;
    moduleRequest.tracks = payload.tracksParam;

    const moduleListRequest = new ModuleListRequest();

    moduleListRequest.addModuleRequest(moduleRequest);

    this.refreshTracksModel.refreshState = 'REFRESHING';

    return payload.playerType === PlayerTypes.REMOTE
      ? this.tuneChromecastService.refreshTracksSeeded(payload)
      : this.http.post(
          ServiceEndpointConstants.endpoints.SEEDED_RADIO.V4_REFRESH,
          moduleListRequest,
        );
  }

  /**
   * Handle the response for a new track.  This method will update the refresh tracks model to indicate that we are
   * back in the IDLE state.
   *
   * @param response is the response received for the refresh tracks request
   */
  private handleSeededRefreshTracksResponse(response) {
    this.refreshTracksModel.refreshModelData = {
      refreshState: 'IDLE',
      refreshStatus: 'SUCCESS',
      currentMetaData: this.refreshTracksModel.currentMetaData,
    };

    return response;
  }

  /**
   * Handle seeded refresh track failures.  This method will update the refresh tracks model to indicate that we are
   * back in the IDLE state AND it will check and see if we need to schedule a retry to make another attempt to get
   * tracks
   *
   * @param error is the error that happened when trying to get more tracks
   * @param retry is the retry number that failed
   * @param payload is the original payload for the request that was made
   */
  private handleSeededRefreshTracksError(error, retry, payload) {
    this.refreshTracksModel.refreshModelData = {
      refreshState: 'IDLE',
      refreshStatus: 'FAILURE',
      currentMetaData: this.refreshTracksModel.currentMetaData,
    };

    if (
      retry < RefreshTracksDelegate.SEEDED_RETRIES_ALLOWED &&
      (error.code === ApiCodes.FLTT_SR_SOURCE_ENDED ||
        error.code === ApiCodes.UNEXPECTED_ERROR)
    ) {
      RefreshTracksDelegate.logger.warn(
        `Refresh tracks ${error} encountered ... retry`,
      );
      this.mediaId = payload.mediaId;
      return timer(1000).pipe(
        flatMap(() => this.refreshTracksSeeded(payload, retry + 1)),
      );
    }

    return of(ClientCodes.ERROR);
  }
}
