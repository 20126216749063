export class FreeTierConstants {

    public static PAGE_NAME = {
        WELCOME_SCREEN: "iap_welcome_screen",
        FOOTER_WELCOME_SCREEN: "iap_footer_welcome_screen",
        SUBSCRIBE_SCREEN: "iap_subscribe_screen",
        ACCESS_NOW: "iap_accessNow_screen",
        FOOTER_SUBSCRIBE: "iap_footer_subscribe_flow",
        FOOTER_ACCESS_NOW: "iap_footer_accessNow_flow",
        UPSELL_BANNER_SUBSCRIBE: "iap_upsell_banner_subscribe",
        UPSELL_BANNER_TRY: "iap_upsell_banner_try",
        STARTED_PREMIER: "iap_get_started_premier",
        UPSELL_SCREEN: "error_122",
        USER_ALREADY_SUBSCRIBED: "error_121"
    };

    public static WELCOME_CAROUSEL = {
        FALLBACK_IMAGE: "../../../assets/images/welcome-carousel-fallback.jpeg"
    };
}
