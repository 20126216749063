import { share } from 'rxjs/operators';
import { Observable }                       from "rxjs";
import { HttpProvider, IHttpRequestConfig } from "../http";
import {
    IProviderDescriptor,
    addProvider
}                                           from "../service";
import { ServiceEndpointConstants }         from "../service/consts";
import { ApiDelegate }                      from "../http";

ApiDelegate.addNoResponseDataException(ServiceEndpointConstants.endpoints.LOG.V1_LOG_UPLOAD,
                                       ServiceEndpointConstants.endpoints.LOG.V1_LOG_UPLOAD);

/**
 * @MODULE:     service-lib
 * @CREATED:    04/22/19
 * @COPYRIGHT:  2019 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * This delegate is responsible for sending client logs to the API
 */
export class LogDelegate
{
    private static providerDescriptor : IProviderDescriptor = function()
    {
        return addProvider(
            LogDelegate,
            LogDelegate,
            [HttpProvider]
        );
    }();

    constructor(private httpProvider: HttpProvider) {}

    /**
     * Post the log data to the API
     * @param logs is an array of log entries to send to the API
     * @param, gupId is the gupId for the user account the logs are for
     */
    public sendLogs(logs : Array<string>,gupId : string): Observable<any>
    {
        const config: IHttpRequestConfig = { params: { gupId : gupId} };
        const data = {};

        logs.forEach((logEntry : string,index) => { data["line" + index] = logEntry; });

        return this.httpProvider.post(ServiceEndpointConstants.endpoints.LOG.V1_LOG_UPLOAD,data,config).pipe(
                   share());
    }
}
