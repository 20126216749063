export class ContextualUrlConstants
{
    //TODO - Need to get new urls for the everest app. Placeholders right now.
    public static urls = {
        IOS: {
            US: "https://itunes.apple.com/us/app/siriusxm-radio-music-talk/id317951436?mt=8#",
            CA: "https://itunes.apple.com/ca/app/siriusxm-canada/id563954164?mt=8#",
            USK2: "https://itunes.apple.com/us/app/siriusxm-radio-music-talk/id317951436?mt=8#",
            CAK2: "https://itunes.apple.com/ca/app/siriusxm-canada/id563954164?mt=8#"
        },
        ANDROID: {
            US: "https://play.google.com/store/apps/details?id=com.sirius",
            CA: "https://play.google.com/store/apps/details?id=com.siriusca"
        },
        WINDOWS: {
            US: "https://www.microsoft.com/en-us/store/p/siriusxm/9nblggh67pjk",
            CA: "https://www.microsoft.com/store/p/siriusxm-canada/9nblggh52qfq"
        }
    };
}
