import {
  of as observableOf,
  Observable,
  Subscription,
  BehaviorSubject,
  Subject,
} from 'rxjs';
import {
  addProvider,
  HttpProvider,
  IProviderDescriptor,
  Logger,
  ServiceEndpointConstants,
} from '../index';

import { INotification, INotificationFeedback } from './notification';

import { IHttpHeader } from '../http/http.provider.response.interceptor';
import { MessagingDelegate } from './messaging.delegate';
import moment from 'moment';
import * as _ from 'lodash';
import { IButton } from '../index';

export class MessagingService {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('MessagingService');

  /**
   * Pending marketing Messages will be stored here.
   */
  private marketingMsgs: INotification[] = [];

  /**
   * The subscription for the "X-SiriusXM-Notification" Header
   */
  private headerSubscription: Subscription;

  /**
   * The Observable for the header
   */
  public httpHeader: Observable<IHttpHeader>;

  /**
   * Behavior Subject for sending INotifications
   */
  private notificationSubject: BehaviorSubject<INotification>;

  /**
   * Observable of INotifications for client to listen to
   */
  public notification: Observable<INotification>;

  /**
   * cache of notification Guids as we receive the header
   */
  public headerGuidCache: Array<string> = [];

  /**
   * current notification guid
   */
  public currentNotificationGuid: string;

  /**
   * A subject that emits show reminders as INotifications
   */
  public showRemindersSubject: Subject<INotification> = new Subject();

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(MessagingService, MessagingService, [
      HttpProvider,
      MessagingDelegate,
    ]);
  })();

  constructor(
    private httpProvider: HttpProvider,
    private messagingDelegate: MessagingDelegate,
  ) {
    this.notificationSubject = new BehaviorSubject(null);
    this.notification = this.notificationSubject;

    this.observeHeader('x-siriusxm-notification');
  }

  /**
   * Begin observing a specific header
   *
   * @param headerName - the name of the header to be observed
   */
  public observeHeader(headerName: string): void {
    MessagingService.logger.debug('observeHeaders()');

    if (this.headerSubscription) {
      this.headerSubscription.unsubscribe();
      this.headerSubscription = null;
    }

    const headerSuccess = (header: IHttpHeader): void => {
      if (header) {
        const url: string = this.getNotificationURL(header);
        const guid: string = this.getNotificationGuidFromUrl(url);

        if (this.headerGuidCache.indexOf(guid) === -1) {
          this.headerGuidCache.push(guid);
          this.currentNotificationGuid = guid;
          this.messagingDelegate.requestNotification(url).subscribe(
            (notifications: INotification[]): void => {
              notifications
                .filter(
                  notification =>
                    !notification.isMarketingMessage &&
                    !this.isExpired(notification),
                )
                .forEach(showReminder =>
                  this.showRemindersSubject.next(showReminder),
                );

              this.marketingMsgs = this.marketingMsgs.concat(
                notifications.filter(
                  notification => notification.isMarketingMessage,
                ),
              );

              this.marketingMsgs.sort((a, b) => {
                return a.priority - b.priority;
              });
              this.emitMarketingMessage();
            },
            (fault: any): void => {
              MessagingService.logger.error(
                `notification/get error ${JSON.stringify(fault)}`,
              );

              const failedNotificationGuid: string = this.headerGuidCache.find(
                (element): boolean => {
                  return element === this.currentNotificationGuid;
                },
              );

              if (failedNotificationGuid) {
                this.headerGuidCache = this.headerGuidCache.filter(
                  (guid: string): boolean => {
                    return guid !== this.currentNotificationGuid;
                  },
                );
              }
            },
            (): void => {
              this.currentNotificationGuid = null;
            },
          );
        }
      }
    };

    const headerFault = (fault: any): void => {
      MessagingService.logger.warn(`headerFault( ${JSON.stringify(fault)} )`);
    };

    this.httpHeader = this.httpProvider.addHttpHeaderObservable(headerName);

    this.headerSubscription = this.httpHeader.subscribe(
      headerSuccess.bind(this),
      headerFault,
    );
  }

  /**
   * Parse the payload of the header for the URL to send the notification request
   *
   * @param {IHttpHeader} header
   * @returns {string} the URL to send the request to
   */
  public getNotificationURL(header: IHttpHeader): string {
    const payloadArray: Array<string> = header.payload.split(';');
    const urlSection: string = payloadArray.find(
      item => item.indexOf('URL') !== -1,
    );
    const url: string = decodeURIComponent(urlSection.split('=')[1]);
    /* returns first url if there are multiple else return the only 1 url from the headers*/
    return urlSection
      ? url.match(/,/)
        ? url.slice(0, url.indexOf(','))
        : url
      : ServiceEndpointConstants.endpoints.NOTIFICATION.V3_GET_NOTIFICATION;
  }

  /**
   * Parse the notification/get url for the Guid
   */
  public getNotificationGuidFromUrl(url: string): string {
    const searchParam: string = 'guid=';
    const urlSplit: Array<string> = url.split('?');
    let guid: string = '',
      queryString: string = '';

    if (urlSplit.length === 1) return '';

    queryString = urlSplit[1];

    guid = queryString.slice(
      queryString.indexOf(searchParam) + searchParam.length,
    );
    return guid;
  }

  /**
   * @description emits the next single marketing message that the client requires
   */
  public emitMarketingMessage(): void {
    if (this.marketingMsgs.length > 0) {
      const marketingMsg = this.marketingMsgs.shift();
      if (this.isExpired(marketingMsg)) {
        this.emitMarketingMessage(); // recurse
      } else {
        this.notificationSubject.next(marketingMsg);
      }
    }
  }

  /**
   * @description returns a boolean whether or not the INotification
   * has expired already.
   */
  public isExpired(notification: INotification): boolean {
    const now = moment();
    const expirationDate = moment(notification.expirationDate);
    return expirationDate.isBefore(now);
  }

  /**
   * Use the delegate to send confirm request
   */
  public sendConfirm(currentNotification: INotification): Observable<any> {
    if (!currentNotification.confirmationUrl) return observableOf(false);
    return this.messagingDelegate.sendConfirm(currentNotification);
  }

  /**
   * Use the delegate to send feedback request
   */
  public sendFeedback(
    notification: INotification,
    button: IButton,
  ): Observable<any> {
    if (!notification.notificationKey.guid) return observableOf(false);
    return this.messagingDelegate.sendFeedback({
      metricEventCode: _.get(button, 'metricEventCode', ''),
      leadKeyId: _.get(notification, 'leadKeyId', ''),
      guid: _.get(notification, 'notificationKey.guid', ''),
      inApp: _.get(notification, 'inAppOnly', true),
      push: false,
      timeStamp: moment().toISOString(),
    } as INotificationFeedback);
  }

  /**
   *  Retrieve the specified IButton from INotification based on a button key.
   */
  public getButtonFromNotification(
    notification: INotification,
    buttonKey: string,
  ): IButton {
    return _.get(notification, `buttons.${buttonKey}`, {} as IButton);
  }
}
