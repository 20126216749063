import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
  addProvider,
  ContentTypes,
  IProviderDescriptor,
  Logger,
  MediaTimeLine,
  //TuneService,
} from '../index';
import { AudioPlayerService } from './audioplayer';
import { IMediaPlayer } from './media-player.interface';
import { MediaUtil } from './media.util';
import { PlayheadTimestampService } from './playhead-timestamp.service';
import { VideoPlayerService } from './videoplayer';
import { ChromecastPlayerService } from './chromecastplayer/chromecast-player.service';
import { ChromecastPlayerConsts } from './chromecastplayer/chromecast-player.consts';
import { MediaTimeLineService } from '../media-timeline/media.timeline.service';
import { MultiTrackAudioPlayerService } from './multitrackaudioplayer';
import { MultiTrackVideoPlayerService } from './multitrackaudioplayer';

/**
 * @MODULE:     service-lib
 * @CREATED:    10/12/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * Creational factory (albeit without a static method) to acquire a requested media player by media type.
 */

export class MediaPlayerFactory {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('MediaPlayerFactory');

  /**
   * subject for delivering current media player through the currentMediaPlayer observable.
   * @type {any}
   */
  private mediaPlayerSubject: BehaviorSubject<
    IMediaPlayer
  > = new BehaviorSubject(null);

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the current media
   * player and be notified when the media player changes.
   */
  public currentMediaPlayer: Observable<IMediaPlayer> = this.mediaPlayerSubject;

  /**
   * Default Player type
   * @type {string}
   */
  public playerType: string = 'audio';

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(MediaPlayerFactory, MediaPlayerFactory, [
      AudioPlayerService,
      MultiTrackAudioPlayerService,
      MultiTrackVideoPlayerService,
      VideoPlayerService,
      PlayheadTimestampService,
      MediaTimeLineService,
      ChromecastPlayerService,
    ]);
  })();

  /**
   * Constructor.
   */
  constructor(
    private audioPlayerService: AudioPlayerService,
    private multiTrackAudioPlayerService: MultiTrackAudioPlayerService,
    private multiTrackVideoPlayerService: MultiTrackVideoPlayerService,
    private videoPlayerService: VideoPlayerService,
    private playheadTimestampService: PlayheadTimestampService,
    private mediaTimeLineService: MediaTimeLineService,
    private chromecastPlayerService: ChromecastPlayerService,
  ) {
    this.observeChromecastPlayerType();
    this.observeMediaTimeLine();
  }

  /**
   * Returns the media player service for the given type of media requested.
   */
  public getMediaPlayer(mediaType: string): IMediaPlayer {
    // Default the media type to an empty string.
    mediaType = (mediaType || '').toLowerCase();

    switch (mediaType) {
      case ContentTypes.VOD.toLowerCase():
      case ContentTypes.LIVE_VIDEO.toLowerCase():
        MediaPlayerFactory.logger.debug(
          `getMediaPlayer( Video: ${mediaType.toUpperCase()} )`,
        );
        return this.videoPlayerService;

      case ChromecastPlayerConsts.TYPE.toLowerCase():
        MediaPlayerFactory.logger.debug(
          `getMediaPlayer( Chromecast: ${mediaType.toUpperCase()} )`,
        );
        return this.chromecastPlayerService;

      case ContentTypes.ADDITIONAL_CHANNELS.toLocaleLowerCase():
        MediaPlayerFactory.logger.debug(
          `getMediaPlayer( Multitrack Video: ${mediaType.toUpperCase()} )`,
        );
        //TODO: Need to find a way to clean this from within the service
        return this.multiTrackVideoPlayerService;

      case ContentTypes.SEEDED_RADIO:
        MediaPlayerFactory.logger.debug(
          `getMediaPlayer( Multitrack Audio: ${mediaType.toUpperCase()} )`,
        );
        return this.multiTrackAudioPlayerService;

      case ContentTypes.PODCAST.toLowerCase():
        MediaPlayerFactory.logger.debug(
          `getMediaPlayer( Audio: ${mediaType.toUpperCase()} )`,
        );
        return this.audioPlayerService;

      case ContentTypes.LIVE_AUDIO.toLowerCase():
      case ContentTypes.AOD.toLowerCase():
      default:
        MediaPlayerFactory.logger.debug(
          `getMediaPlayer( Video (for audio): ${mediaType.toUpperCase()} )`,
        );
        return this.videoPlayerService;
    }
  }

  /**
   * Observe data changes on the media time line. If there's a new media type update media type
   */
  private observeMediaTimeLine(): void {
    MediaPlayerFactory.logger.debug(`observeMediaTimeLine()`);

    let mediaType: string = '';

    this.mediaTimeLineService.mediaTimeLine.subscribe(
      onMediaTimeLineSuccess.bind(this),
      onMediaTimeLineFault.bind(this),
    );

    /**
     * If there's new media call then set mediaType.
     * @param {MediaTimeLine} mediaTimeLine
     */
    function onMediaTimeLineSuccess(mediaTimeLine: MediaTimeLine) {
      if (mediaTimeLine && this.playerType !== ChromecastPlayerConsts.TYPE) {
        const isNewMediaType: boolean = mediaType !== mediaTimeLine.mediaType;
        if (isNewMediaType) {
          if (
            MediaUtil.isVideoMediaTypeLive(mediaTimeLine.mediaType) ||
            MediaUtil.isVideoMediaTypeLive(mediaType)
          ) {
            this.playheadTimestampService.playhead
              .first()
              .subscribe(playhead => {
                this.audioPlayerService.audioPlayerEventMonitor.playhead$.next(
                  playhead,
                );
                this.videoPlayerService.videoPlayerEventMonitor.playhead$.next(
                  playhead,
                );
              });
          }
          const mediaPlayer = this.getMediaPlayer(mediaTimeLine.mediaType);
          this.mediaPlayerSubject.next(mediaPlayer);
          mediaType = mediaTimeLine.mediaType;
        }
      }
    }

    /**
     * If observable throws a fault then logs the error message.
     * @param error
     */
    function onMediaTimeLineFault(error: any) {
      MediaPlayerFactory.logger.warn(
        `onMediaTimeLineFault( ${error} for media time line observable. )`,
      );
    }
  }

  /**
   * Observes the chromecast player type.
   */
  private observeChromecastPlayerType() {
    this.chromecastPlayerService.switchPlayerType$
      .pipe(filter((state: string) => !!state))
      .subscribe((state: string) => {
        this.playerType = state;
        const mediaPlayer = this.getMediaPlayer(state);
        this.mediaPlayerSubject.next(mediaPlayer);
      });
  }
}
