import { Logger } from "../logger";

let trapped                = false;
let originalXMLHttpRequest = XMLHttpRequest;
const logger : Logger      = Logger.getLogger("HttpProviderInterceptor");

class XHRTrap
{
    constructor(public name : string,
                public checkUrl : (url : string) => boolean,
                public afterOpen : (request : XMLHttpRequest,
                                    method : string,
                                    url : string,
                                    flag : boolean) => void,
                public onSend : (request : XMLHttpRequest,
                                 method : string,
                                 url : string,
                                 flag : boolean) => boolean,
                public onAfterSend : (request : XMLHttpRequest,
                                      method : string,
                                      url : string,
                                      body : any) => void,
                public onResponse :  (request : XMLHttpRequest,
                                      method : string,
                                      url : string,
                                      ev: Event) => Event) {}
}


let trapArray : XHRTrap[] = [];

/**
 *
 * @param {string} name of the trap
 * @param {(url: string) => boolean} checkUrl function returns true if url is for this trap, false otherwise
 * @param {(request: XMLHttpRequest, method: string, url: string, flag: boolean) => void} afterOpen callback
 * @param {(request: XMLHttpRequest, method: string, url: string, body: any) => boolean} onSend callback
 * @param {(request: XMLHttpRequest, method: string, url: string, body: any) => void} onAfterSend callback
 * @param {(request: XMLHttpRequest, method: string, url: string, ev: Event) => Event} onResponse callback
 */
export function trapXHR(name : string,
                        checkUrl : (url : string) => boolean,
                        afterOpen? : (request : XMLHttpRequest,
                                      method : string,
                                      url : string,
                                      flag : boolean) => void,
                        onSend? : (request : XMLHttpRequest,
                                   method : string,
                                   url : string,
                                   body : any) => boolean,
                        onAfterSend? : (request : XMLHttpRequest,
                                        method : string,
                                        url : string,
                                        body : any) => void,
                        onResponse? : (request : XMLHttpRequest,
                                       method : string,
                                       url : string,
                                       ev : Event) => Event)
{
    if (trapArray.find((trap : XHRTrap) => trap.name == name))
    {
        throw `Already have a trap named ${name}`;
    }

    if (!afterOpen) { afterOpen = () => {}; }
    if (!onSend) { onSend = () => true; }
    if (!onAfterSend) { onAfterSend = () => {}; }
    if (!onResponse)
    {
        onResponse = (request : XMLHttpRequest,
                                     method : string,
                                     url : string,
                                     ev: Event) : Event => ev;
    }

    const trap = new XHRTrap(name,checkUrl,afterOpen,onSend,onAfterSend,onResponse);

    if (trapArray.length === 0) { trapXHRRequests();}

    trapArray.push(trap);
}

/**
 * Remove a trap by name.  All future XHR calls will no longer use the given trap
 * @param {string} name of the trap that was given when the trap was created
 */
export function untrapXHR(name : string)
{
    let foundIndex = -1;

    trapArray.find((trap : XHRTrap, index : number) =>
                   {
                       if (trap.name == name)
                       {
                           foundIndex = index;
                           return true;
                       }

                       return false;
                   });

    if (foundIndex >= 0)
    {
        trapArray.splice(foundIndex,1);
    }
    else
    {
        throw `no trap handler for ${name} found`;
    }

    if (trapArray.length === 0) { untrapXHRRequests(); }
}


/**
 * This function will trap All XHR requests, and will hook up a generic trap mechanism that will enable the trapXHR
 * function (above) to work
 */
function trapXHRRequests()
{
    if (trapped === false)
    {
        originalXMLHttpRequest = XMLHttpRequest;

        XMLHttpRequest = function ()
        {
            const xhr     = new originalXMLHttpRequest();
            let _httpOpen = xhr.open.bind(xhr);
            let _httpSend = xhr.send.bind(xhr);
            let _onLoad   = null;

            let _url    = null;
            let _method = null;

            xhr.open = function (method, url, flag)
            {
                const ret = _httpOpen(method, url, flag ? flag : true);

                afterOpen(this, method, url, flag);

                _url    = url;
                _method = method;

                return ret;
            }.bind(xhr);

            xhr.send = function (body : any)
            {
                const shouldSend : boolean = onSend(this, _method, _url, body);

                _onLoad = this.onload;

                this.onload = (ev : Event) =>
                {
                    ev = onResponse(this, _method, _url, ev);

                    if (!!_onLoad  && shouldSend === true) { return _onLoad(this,ev); }
                };

                if (shouldSend)
                {
                    const result = _httpSend(body);
                    onAfterSend(this, _method, _url, body);
                    return result;
                }
                else
                {
                    logger.debug(`trapXHRRequests( Do not send: ${_url} )`);
                }

            }.bind(xhr);

            return xhr;
        } as any;

        trapped = true;
    }

    /**
     * This is a generic after open callback Function will search through the available traps and find the
     * first trap that matches the request.  If a matching trap is found the afterOpen callback for that trap
     * will be called
     *
     * @param {XMLHttpRequest} request that was just opened
     * @param {string} method for the request
     * @param {string} url for the request
     * @param {boolean} flag for the request
     */
    function afterOpen(request : XMLHttpRequest,
                       method : string,
                       url : string,
                       flag : boolean) : void
    {
        const trap = trapArray.find((trap : XHRTrap) =>
                                    {
                                        return trap.checkUrl(url);
                                    });

        if (!!trap)
        { trap.afterOpen(request, method, url, flag); }
    }

    /**
     * This is a generic on sendcallback Function will search through the available traps and find the
     * first trap that matches the request.  If a matching trap is found the onsend callback for that trap
     * will be called and the results from that call returned.
     *
     * If there is no valid trap the function returns true;
     *
     * @param {XMLHttpRequest} request that was just opened
     * @param {string} method for the request
     * @param {string} url for the request
     * @param {boolean} flag for the request
     *
     * @returns true if there is no trap for this request or the results from calling the traps onSend function if there
     *          is a trap for the request
     */
    function onSend(request : XMLHttpRequest,
                    method : string,
                    url : string,
                    body : any) : boolean
    {
        const trap = trapArray.find((trap : XHRTrap) =>
                                    {
                                        return trap.checkUrl(url);
                                    });

        if (!!trap)
        { return trap.onSend(request, method, url, body); }

        return true;
    }

    /**
     * This is a generic after send callback Function will search through the available traps and find the
     * first trap that matches the request.  If a matching trap is found the afterSend callback for that trap
     * will be called
     *
     * @param {XMLHttpRequest} request that was just opened
     * @param {string} method for the request
     * @param {string} url for the request
     * @param {boolean} flag for the request
     */
    function onAfterSend(request : XMLHttpRequest,
                         method : string,
                         url : string,
                         body : any) : void
    {
        const trap = trapArray.find((trap : XHRTrap) =>
                                    {
                                        return trap.checkUrl(url);
                                    });

        if (!!trap)
        { trap.onAfterSend(request, method, url, body); }
    }

    /**
     * This is a generic on response callback Function will search through the available traps and find the
     * first trap that matches the request.  If a matching trap is found the onResponse callback for that trap
     * will be called and the return value from that call returned
     *
     * If there is no valid trap the function just returns the event
     *
     * @param {XMLHttpRequest} request that was just opened
     * @param {string} method for the request
     * @param {string} url for the request
     * @param {Event} ev is the response to the request
     */
    function onResponse(request : XMLHttpRequest,
                        method : string,
                        url : string,
                        ev : Event) : Event
    {
        const trap = trapArray.find((trap : XHRTrap) =>
                                    {
                                        return trap.checkUrl(url);
                                    });

        if (!!trap)
        { return trap.onResponse(request, method, url, ev); }

        return ev;
    }
}


/**
 * The function will set the XMLHttpRequest constructor back to its normal value
 */
function untrapXHRRequests()
{
    if (trapped === true)
    {
        logger.debug(`untrapXHRRequests()`);
        XMLHttpRequest = originalXMLHttpRequest;
        trapped = false;
    }
}


