import {
    bypassApiCodes,
    ApiCodes,
    ApiCode
}                          from "../service/consts";
import { IAppByPassState } from "./app-monitor.interface";

export class BypassErrorState implements IAppByPassState
{
    bypassState: Map<ApiCode, boolean> = new Map();

    public static isBypassCode(code: ApiCode): boolean
    {
        return bypassApiCodes.indexOf(code) > -1;
    }

    constructor()
    {
        this.resetState();
    }

    public resetState(): void
    {
        this.bypassState[ApiCodes.GUP_BYPASS] = false;
        this.bypassState[ApiCodes.GUP_BYPASS2] = false;
        this.bypassState[ApiCodes.IT_BYPASS] = false;
        this.bypassState[ApiCodes.VOD_BYPASS] = false;
        this.bypassState[ApiCodes.AOD_BYPASS] = false;
        this.bypassState[ApiCodes.SEARCH_BYPASS] = false;
        this.bypassState[ApiCodes.CAROUSEL_BYPASS] = false;
        this.bypassState[ApiCodes.AIC_BYPASS] = false;
        this.bypassState[ApiCodes.ARTIST_RADIO_BYPASS] = false;
    }

    public checkAndUpdateBypass(bypassCode: ApiCode): boolean
    {
        if (!BypassErrorState.isBypassCode(bypassCode)) return false;

        const bypassStarted = !this.bypassState[bypassCode];
        this.bypassState[bypassCode] = true;
        return bypassStarted;
    }

    public updateBypassFromNoop(apiCodes: ApiCode[]): {code: ApiCode, state: boolean}[]
    {
        let changed: {code: ApiCode, state: boolean}[] = [];
        bypassApiCodes.forEach(bypassCode =>
        {
            const currentState = this.bypassState[bypassCode];
           if (apiCodes.indexOf(bypassCode) > -1 && !currentState)
           {
                this.bypassState[bypassCode] = true;
               changed.push({code: bypassCode, state: true});
           }
           else if(!(apiCodes.indexOf(bypassCode) > -1) && currentState)
           {
               this.bypassState[bypassCode] = false;
                changed.push({code: bypassCode, state: false});
           }
        });

        return changed;
    }

    public getBypassErrorState(): IAppByPassState
    {
        return {
            GUP_BYPASS: this.GUP_BYPASS,
            GUP_BYPASS2: this.GUP_BYPASS2,
            IT_BYPASS: this.IT_BYPASS,
            VOD_BYPASS: this.VOD_BYPASS,
            AOD_BYPASS: this.AOD_BYPASS,
            CAROUSEL_BYPASS: this.CAROUSEL_BYPASS,
            SEARCH_BYPASS: this.SEARCH_BYPASS,
            AIC_BYPASS: this.AIC_BYPASS,
            ARTIST_RADIO_BYPASS: this.ARTIST_RADIO_BYPASS
        };
    }

    get GUP_BYPASS(): boolean
    {
        return this.bypassState[ApiCodes.GUP_BYPASS];
    }
    get GUP_BYPASS2(): boolean
    {
        return this.bypassState[ApiCodes.GUP_BYPASS2];
    }

    get IT_BYPASS(): boolean
    {
        return this.bypassState[ApiCodes.IT_BYPASS];
    }

    get VOD_BYPASS(): boolean
    {
        return this.bypassState[ApiCodes.VOD_BYPASS];
    }

    get AOD_BYPASS(): boolean
    {
        return this.bypassState[ApiCodes.AOD_BYPASS];
    }

    get SEARCH_BYPASS(): boolean
    {
        return this.bypassState[ApiCodes.SEARCH_BYPASS];
    }

    get CAROUSEL_BYPASS(): boolean
    {
        return this.bypassState[ApiCodes.CAROUSEL_BYPASS];
    }

    get AIC_BYPASS(): boolean
    {
        return this.bypassState[ApiCodes.AIC_BYPASS];
    }

    get ARTIST_RADIO_BYPASS(): boolean
    {
        return this.bypassState[ApiCodes.ARTIST_RADIO_BYPASS];
    }
}
