import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

import {
  IProviderDescriptor,
  addProvider,
  HttpProvider,
  Logger,
  ServiceEndpointConstants,
  secondsToMs,
} from '../index';

/**
 * @MODULE:     service-lib
 * @CREATED:    09/25/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * Used to ping the API when audio or video playback has started playing to ensure delivery of fresh CDN access tokens to the players.
 */

export class NoopDelegate {
  /**
   * Internal logger.
   * @type {Logger}
   */
  private static logger: Logger = Logger.getLogger('NoopDelegate');

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(NoopDelegate, NoopDelegate, [HttpProvider]);
  })();

  /**
   * Constructor
   * @param {HttpProvider} http
   */
  constructor(private http: HttpProvider) {}

  /**
   * Used to make API call for token refreshes. It really just pings the API to keep the client fresh.
   * @returns {Observable<number>} - An observable that can be subscribed to to get the
   * results of the API call.
   */
  public noop(): Observable<number> {
    NoopDelegate.logger.debug('noop()');

    const url: string = ServiceEndpointConstants.endpoints.CHANNEL.V2_NOOP;

    return this.http.get(url).pipe(
      map(response => {
        const updateFrequency: number = secondsToMs(response.updateFrequency);
        NoopDelegate.logger.debug(
          `noopResult( updateFrequency = ${updateFrequency} milliseconds )`,
        );
        return updateFrequency;
      }),
    );
  }
}
