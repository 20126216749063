import { ConfigConstants } from "../config.constants";
import { OpenAccessCopyName } from "../interfaces/open-access-config.interface";
import { ConfigFinderService } from "./config-finder.service";
import { IComponentConfig } from "../interfaces/config.interface";

export class OpenAccessConfigFinder
{
    private static findOpenAccessComponent(components : Array<IComponentConfig>): IComponentConfig
    {
        return ConfigFinderService.findConfigurationComponent(components, ConfigConstants.OPEN_ACCESS);
    }

    public static getCopy(components : Array<IComponentConfig>,copyName: OpenAccessCopyName): string
    {
        return ConfigFinderService.findStringSetting(this.findOpenAccessComponent(components), copyName).value;
    }
}
