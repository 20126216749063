import { VideoPlayerConstants } from './video-player.consts';

export class VideoPlayerUtil {
  /**
   * Constructor.
   * Empty as this is a static utility class.
   */
  constructor() {}

  /**
   * Creates a valid HLS video URL based on the domain provided by the API's config call and the URLs from the
   * `now-playing` API response.
   * @param {string} url
   * @param {string} domain
   * @param {string} sxmGuid
   * @returns {string}
   */

  public static mapUrl(url: string, domain: string, sxmGuid: string): string {
    domain = domain || VideoPlayerConstants.DEFAULT_URL_DOMAIN;
    sxmGuid = sxmGuid || VideoPlayerConstants.DEFAULT_SXM_GUID;
    url = url.replace(VideoPlayerConstants.LIVE_PRIMARY_HLS, domain);
    url = url.replace(VideoPlayerConstants.SXM_GUID_TOKEN, sxmGuid);

    return url;
  }

  public static mapUrlForAudio(
    url: string,
    domains: string,
    sxmGuid: string,
  ): string {
    let keyToReplace = '';
    let domain = '';

    Object.keys(domains).find(key => {
      if (url.indexOf(key) !== -1) {
        keyToReplace = key;
        domain = domains[key];
        return true;
      }
    });

    sxmGuid = sxmGuid || VideoPlayerConstants.DEFAULT_SXM_GUID;
    url = url.replace(keyToReplace, domain);
    url = url.replace(VideoPlayerConstants.SXM_GUID_TOKEN, sxmGuid);

    return url;
  }
}
