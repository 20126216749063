import * as _                from "lodash";
import { INetworkError }     from "./types/http.types";
import {
    IApiMessage,
    IModuleListResponse
}                            from "./types/module.list.response";
import { IQueryStringParam } from "./types/query.string.param.interface";
import {
    IHttpResponse,
    IHttpRequestConfig
}                            from "./types/http.types";
import {
    IProviderDescriptor,
    addProvider
}                            from "../service";
import { Logger }            from "../logger/logger";
import { ApiCodes }          from "../service/consts";
import { EventBus }          from "../event/service";

/**
 * @MODULE:     service-lib
 * @CREATED:    07/19/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * api.delegate.ts provides functions handling common tasks for API responses
 */

/**
 * Represents a single API message (from the messages array) in an API response.
 */
export class ApiMessage implements IApiMessage
{
    /**
     * Construct a new API message with the given parameters.
     * @param code is the API code for the message
     * @param message is the API string for the message
     */
    constructor(public code: number, public message: string)
    {
        this.code = code;
        this.message = message;
    }
}

/**
 * Type for API code handler callbacks.  These callbacks can be registered for callback when an certain API
 * code is encountered by the APIDelegate
 *
 * @param code is the api code/s that triggered the handler callback
 * @param apiMessage is the original api message/s that triggered the callback
 * @param response is the raw http response object from the http service that triggered the callback
 */
export type ApiCodeHandler = (codes: Array<number>, apiMessages: Array<ApiMessage>, response: IHttpResponse) => void;

/**
 * Subscription type that can be used for removing API code handlers.  Gives a removeApiCodeHandler method that
 * will remove the handler that the subscription was created for
 */
export class ApiCodeHandlerSubscription
{
    constructor(private codes: string,
                private handler: ApiCodeHandler,
                private apiDelegate: ApiDelegate) {}

    public removeCodeHandler()
    {
        removeApiCodeHandler.call(this.apiDelegate, this.codes, this.handler);
    }
}

/**
 * API delegate provides functionality around dealing with API responses
 */
export class ApiDelegate
{
    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("ApiDelegate");

    /**
     * API error for a malformed response.
     */
    public static MALFORMED_API_RESPONSE = { code: ApiCodes.NO_API_RESPONSE, message: "Empty API response " };

    /**
     * Custom error message for API responses that contain no modules / data.
     * @type {string}
     */
    public static ERROR_NO_DATA: string = "No responses modules/data.";

    /**
     * An object that contains a API code or a multiple API codes as a key and a list of call back functions to be
     * called when the API codes are received from the API.
     * @type {Array}
     */
    private ERROR_TABLE: object = {};

    /**
     * Hashmap of API endpoint URLs that allow now data in the response.
     * @type {Map<string, string>}
     */
    private static NO_RESPONSE_DATA_EXCEPTIONS: Map<string, string> = new Map();

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor : IProviderDescriptor = function()
    {
        return addProvider(ApiDelegate,ApiDelegate,[EventBus]);
    }();

    /**
     * @param eventBus is the global event bus for the app
     */
    constructor(private eventBus: EventBus) {}

    /**
     * Get the messages from an API response and deal with any handler callbacks or events that need to be sent out.
     * based on those messages
     * @param response is the http response that contains the API response
     * @param eventBus to use to send out any neccesary events based on the API codes received in the response
     */
    public checkApiResponse(response: IHttpResponse)
    {
        const messages: Array<IApiMessage> = ApiDelegate.getMessages(response);

        let ok = false;

        messages.forEach((message) =>
        {
            if (ok === false)
            {
                ok = (message.code === ApiCodes.API_SUCCESS_CODE);
            }

            const apiMessage = new ApiMessage(message.code, message.message);
        });

        this.handleMessages(messages, response);

        if (ok === false)
        {
            throw ApiDelegate.createModuleResponseError(response);
        }
    }

    /**
     * Add an API code handler callback to be executed whenever an API response message with the given code is received.
     * @param code is the API code/s that will trigger the callback to be called
     * @param handler is the handler function to be called when the given API code is encountered
     * @returns subscription object that can be used to remove the  handler
     */
    public addApiCodeHandler(code: number | Array<number>, handler: ApiCodeHandler, priority: boolean = false): ApiCodeHandlerSubscription
    {
        const key = (Array.isArray(code) ? code : [ code ]).sort().join(";"),
              handlerArray = this.ERROR_TABLE[key] || [];

        if(priority === true) { handlerArray.unshift(handler); }
        else { handlerArray.push(handler); }

        this.ERROR_TABLE[key] = handlerArray;


        return new ApiCodeHandlerSubscription(key, handler, this);
    }

    /**
     * Allows adding a reporter function that will get called when a specific endpoint gets a response.  APi codes,
     * messages and the raw response will be given to the reporter.
     *
     * This is an escape hatch in case delegates cannot get enough information about a response to a particular
     * endpoint.  They can add a handler in here to get the api codes and the raw response.
     *
     * @param {string} endpoint
     * @param {ApiCodeHandler} handler
     */
    public addApiEndpointReporter(endpoint : string, handler: ApiCodeHandler)
    {
        this.ERROR_TABLE[endpoint] = handler;
    }

    /**
     *
     * @param {ApiCodeHandler} handler
     */
    public addApiErrorHandler(handler: ApiCodeHandler)
    {
        const key          = "error";
        const handlerArray = this.ERROR_TABLE[ key ] || [];

        this.ERROR_TABLE[key] = handlerArray.concat(handler);
    }

    /**
     * An an event that should be broadcast onto the event bus when a specific API code is received.
     * @param code is the API code that will trigger the event to be sent
     * @param event is the event bus event to send when the given API code is encountered
     * @returns subscription object that can be used to remove the event broadcast handler
     */
    public addApiCodeEventBroadcast(code: number, event: string): any
    {
        return this.addApiCodeHandler(code, () =>
        {
            this.eventBus.dispatch({ type: event });
        });
    }

    /**
     * Get the list of messages that comes back with an API call.
     * @param response is the HTTP response that the api response is contained in
     * @returns {Array<IApiMessage>} an array of API messages
     */
    public static getMessages(response: IHttpResponse): Array<IApiMessage>
    {
        const path: string = "data.ModuleListResponse.messages";
        const messages = _.get(response, path, [ ApiDelegate.MALFORMED_API_RESPONSE ]) as Array<IApiMessage>;

        if(!messages && ApiDelegate.NO_RESPONSE_DATA_EXCEPTIONS.has(response.config.url))
        {
            return [{code : 100, message : "success"}];
        }

        return messages;
    }

    /**
     * Attempts to perform response data minding on behalf of the requesting delegate using the list of
     * data property names provided. If none are found it'll simply return the base response data.
     *
     * @param response is the http response to get the response data from
     * @returns {any}
     */
    public static getResponseData(response: IHttpResponse): any
    {
        const responseModulesPath: string = "data.ModuleListResponse.moduleList.modules";
        const responseModules: Array<IModuleListResponse> = _.get(response, responseModulesPath, []) as Array<IModuleListResponse>;

        // Grab the real data from the API response.
        if (responseModules.length > 1)
        {
            return responseModules.map(flattenResponse);
        }
        else if (responseModules.length === 1)
        {
            return flattenResponse(responseModules[ 0 ]);
        }
        else
        {
            // Only throw an error if the current URL isn't an exception to no data in the response.
            const url: string = response.config.url;
            if(!ApiDelegate.NO_RESPONSE_DATA_EXCEPTIONS.has(url))
            {
                throw ApiDelegate.createNoDataResponseError(response, ApiDelegate.ERROR_NO_DATA);
            }

        }

        function flattenResponse(item: IModuleListResponse)
        {
            let response = {};

            if (item)
            {
                Object.keys(item).forEach((property: string) =>
                {
                    if (property !== "moduleResponse")
                    {
                        response[ property ] = item[ property ];
                    }
                });

                if (item.moduleResponse)
                {
                    Object.keys(item.moduleResponse).forEach((property: any) =>
                    {
                        response[ property ] = item.moduleResponse[ property ];
                    });
                }
            }

            return response;
        }
    }

    /**
     * Creates an API URL and replaces any query string parameter tokens with values. The expected token
     * must have brackets around it like:
     *
     * "modules/get/configuration?result-template={result-template}&app-region={app-region}" .
     *
     * @param url - The URL with query string tokens.
     * @param params - The list of query string parameters to replace.
     * @returns {string}
     */
    public static replaceQueryStringParams(url: string, params?: Array<IQueryStringParam>)
    {
        if (params)
        {
            params.map((item) =>
            {
                url = url.replace(`{${item.name}}`, String(item.value));
            });
        }

        return url;
    }

    /**
     * Adds a request URL to the hash of no data response exceptions.
     * @param {string} url
     * @param {string} value
     */
    public static addNoResponseDataException(url: string, value: string)
    {
        ApiDelegate.NO_RESPONSE_DATA_EXCEPTIONS.set(url, value);
    }

    /**
     * Removes a request URL to the hash of no data response exceptions.
     * @param {string} url
     */
    public static removeNoResponseDataException(url: string)
    {
        ApiDelegate.NO_RESPONSE_DATA_EXCEPTIONS.delete(url);
    }

    /**
     * Takes a given api message, and will call any handlers for the API code/s that have been registered for callback.
     *
     * It accomplishes this by first getting all the handlers in the error table that are registered with a single API
     * code.
     *
     * Then it gets all the handlers that are registered to multiple API Codes. { '107;201': [fns] } who are registered
     * to a subset of the actual message codes received.
     *
     * It places all these callbacks in an array and then calls each one.
     * @param message is the API message to check handlers for
     * @param response is the response that contains the message
     */
    private handleMessages(messages: Array<ApiMessage>, response: IHttpResponse)
    {
        const messageCodes = messages.map(({ code }) => code);

        const codeHandler = this.ERROR_TABLE[response.config.url];

        if (codeHandler) { codeHandler(messageCodes,messages,response); }

        const handlers: Array<ApiCodeHandler> = messageCodes
            .reduce(getHandlersForSingleCodes.bind(this), [])
            .concat(getHandlersForMultipleCodes(messageCodes, this.ERROR_TABLE));



        //THIS IS PASSING ALL THE CODES RATHER THAN JUST THE ONE THAT REALTED TO THE FUNCTION CALL
        handlers.forEach(handler => handler(messageCodes, messages, response));

        const errorHandlers: Array<ApiCodeHandler> = this.ERROR_TABLE[ "error" ];
        if (messageCodes[ 0 ] !== ApiCodes.API_SUCCESS_CODE && errorHandlers && errorHandlers.length > 0)
        {
            errorHandlers.forEach(handler => handler(messageCodes, messages, response));
        }
    }

    /**
     * Creates an API response error object with details like the failed API endpoint URL, messages, and status code.
     *
     * @param {IHttpResponse} response
     * @param {string} detailedMessage
     * @returns {Object}
     */
    private static createNoDataResponseError(response: IHttpResponse, detailedMessage: string = ""): INetworkError
    {
        const messages: Array<IApiMessage> = ApiDelegate.getMessages(response);
        const config: IHttpRequestConfig = response.config;

        return {
            message: messages[ 0 ].message,
            code: messages[ 0 ].code,
            url: config.url,
            detailedMessage: detailedMessage
        };
    }

    /**
     * Creates an error message with the Modules attached
     * @param {IHttpResponse} response
     * @param {string} detailedMessage
     * @returns {INetworkError}
     */
    private static createModuleResponseError(response: IHttpResponse, detailedMessage: string = ""): INetworkError
    {
        const messages: Array<IApiMessage> = ApiDelegate.getMessages(response);
        const config: IHttpRequestConfig = response.config;
        const modules = ApiDelegate.getResponseData(response);

        return {
            message: messages[ 0 ].message,
            code: messages[ 0 ].code,
            url: config.url,
            detailedMessage: detailedMessage,
            modules: modules
        };
    }
}

/**
 * Builds an array of API code handlers from the error table that are registered to a single code.
 *
 * @param {any} finalArray
 * @param {any} code
 * @returns {ApiCodeHandler[]}
 */
function getHandlersForSingleCodes(finalArray, code): ApiCodeHandler[]
{
    return finalArray.concat(this.ERROR_TABLE[code] || []);
}

/**
 * Gets all the handlers that are registered to a subset of the codes that were received.
 *
 * That is if the codes received are 100, 201, 107 anything registered to any combination of those
 * will be returned in an array.
 *
 * @param {number[]} messageCodes
 * @param {any} errorTable
 * @returns {ApiCodeHandler[]}
 */
function getHandlersForMultipleCodes(messageCodes: number[], errorTable): ApiCodeHandler[]
{
    return Object.keys(errorTable)
            .filter(isMultiCode)
            .filter(registeredCodesWereReceived(messageCodes))
            .reduce((handlerArray, codes) => handlerArray.concat(errorTable[codes]), []);
}

/**
 * Predicate for filter function to filter out multicodes from the registered codes.
 * Multicodes are stored as a semicolon separated list.
 *
 * @param {string} code
 * @returns {boolean}
 */
function isMultiCode(code: string): boolean
{
    return code.includes(";");
}

/**
 * Function that generates a predicate function to return an array of multicodes
 * that are a subset of the codes we recieved from the API.
 *
 * @param {any} receivedCodes
 * @returns {(code: string) => boolean}
 */
function registeredCodesWereReceived(receivedCodes): (code: string) => boolean
{
    return (code) => (
        code.split(";")
            .map(Number)
            .every(code => receivedCodes.indexOf(code) !== -1)
    );
}

/**
 * Remove a callback handler given code and the handler.
 * @param code is the API code that would trigger the callback handler
 * @param handler is the handler to remove
 */
function removeApiCodeHandler(codes: string, handler: ApiCodeHandler)
{
    const handlerArray = this.ERROR_TABLE[codes],
            handlerIndex = handlerArray.indexOf(handler);

    if (!handlerArray.length || !handlerIndex) return;

    let newHandlerArray = [
        ...handlerArray.slice(0, handlerIndex),
        ...handlerArray.slice(handlerIndex + 1)
    ];

    if (newHandlerArray.length === 0) delete this.ERROR_TABLE[codes];
    else this.ERROR_TABLE[codes] =  newHandlerArray;
}
