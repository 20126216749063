/*  This is a blue toast that appears in the top
 *  right hand corner of the app
 */
import { IButtons, INotification } from './notification';
import { MessageTypes } from './messaging.consts';
import { INotificationKey } from './notification';

export class ShowReminder implements INotification {
  public messageType: string;
  public outOfAppText: string;
  public buttons: IButtons;
  public isMarketingMessage: boolean;
  public channelId?: string;
  public titleText?: string;
  public confirmationUrl?: string;
  public notificationKey?: INotificationKey;
  public leadKeyId?: string;
  public expirationDate?: string;

  constructor(data) {
    this.buttons = {
      primaryButton: {
        label: data.buttons.primaryButton.label || 'Listen Now',
        neriticLink: data.buttons.primaryButton.neriticLink || '',
        metricEventCode: data.buttons.primaryButton.metricEventCode || '',
      },
    };
    this.notificationKey = { guid: data.notificationKey.guid || '' };
    this.leadKeyId = data.leadKeyId || '';
    this.messageType = data.messageType || MessageTypes.SHOW_REMINDER;
    this.outOfAppText = data.outOfAppText || '';
    this.isMarketingMessage = false;
    this.channelId = data.channelId;
    this.titleText = data.titleText || '';
    this.confirmationUrl = data.confirmationUrl || '';
    this.expirationDate = data.expirationDate || '';
  }
}
