
import { of as observableOf, Observable, BehaviorSubject } from 'rxjs';
import { share, catchError, mergeMap } from 'rxjs/operators';
import * as _ from "lodash";
import {
    addProvider,
    IProviderDescriptor,
    Logger
} from "../index";
import { SessionTransferDelegate } from "./session.transfer.delegate";
import { ApiDelegate } from "../http/api.delegate";
import { ApiCodes } from "../service/consts/api.codes";
import { ChromecastModel } from "../chromecast/chromecast.model";
import { ChromecastPlayerConsts } from "../mediaplayer/chromecastplayer/chromecast-player.consts";

export class SessionTransferService
{
    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("SessionTransferService");

    /**
     * Transfer session token observable
     * @type {BehaviorSubject<string>}
     */
    public transferSessionToken: BehaviorSubject<string> = new BehaviorSubject<string>("");
    public sessionClaimed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    private get claimStatus() { return this.sessionClaimed.getValue(); }
    private set claimStatus(status : boolean ) { this.sessionClaimed.next(status); }

    private callInProgress: boolean = false;
    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(SessionTransferService,
            SessionTransferService,
            [
                SessionTransferDelegate,
                ApiDelegate,
                ChromecastModel
            ]);
    }();

    /**
     * Constructor
     * @param {SessionTransferDelegate} sessionTransferDelegate
     * @param {ApiDelegate} apiDelegate
     */
    constructor(private sessionTransferDelegate: SessionTransferDelegate,
                private apiDelegate: ApiDelegate,
                private chromecastModel: ChromecastModel)
    {
        apiDelegate.addApiCodeHandler(ApiCodes.SESSION_IN_CASTING_MODE, () =>
        {
            this.claimStatus = false;
        }, true);
    }

    /**
     * Cast status
     * @param {boolean} claimStatus
     * @returns {Observable<any>}
     */
    public castStatusChange(claimStatus: boolean): Observable<any>
    {
        const isConnected = this.chromecastModel.state$.getValue() === ChromecastPlayerConsts.STATE.CONNECTED;

        if (claimStatus === this.claimStatus)
        {
            return observableOf(true);
        }

        if(claimStatus && isConnected)
        {
            return observableOf(true);
        }

        const response = this.sessionTransferDelegate.castStatusChange(claimStatus);

        response.subscribe(successCallback.bind(this), successCallback.bind(this));



        function successCallback(data) : void
        {
            this.claimStatus = claimStatus;
            SessionTransferService.logger.debug("castStatusChange - success");
        }

        return response.pipe(mergeMap(() => observableOf(true)),
                       catchError(() => observableOf(true)),
                       share());

    }

    /**
     * Since the Sender and Receiver need to both be authenticated to the same API, we need them to share the session.
     * This is accomplished with a new API endpoint that's specific to this situation and keeps the dual logins
     * from triggering simultaneous login errors on the server.
     *
     * In order for this to work, the Sender must first hit this API endpoint to kick things off; the response from
     * this request is a "transfer session URL" that the Receiver must also use to so we're good to go on both sides.
     * Once the sender (here), has the URL in hand it sends it to the Receiver via a load request (and stuffing
     * the URL into the metadata).
     *
     * Bottom line, this kicks off the transfer session process for Chromecast by first calling the Senders transfer
     * session API and then calling the Receiver's transfer session API.
     *
     * NOTE: MySXM is not currently supported when casting, so we'll immediately stop the casting session if that's
     * the current playback type.
     *
     * @return {Promise}
     */
    public transferSession(): void
    {
        SessionTransferService.logger.debug("transferSession()");
        const transferSessionResponse = this.sessionTransferDelegate.transferSenderSession();
        transferSessionResponse
            .subscribe((response: any) =>
            {
                const token = _.get(response,
                    "authenticationData.transferToken",
                    "");
                this.transferSessionToken.next(token);
            }, (error =>
            {
                SessionTransferService.logger.debug(`transferSession( error = ${error} )`);
            }));

    }
}
