export class KeyboardEventKeyTypes
{
    public static SPACEBAR: string = " ";
    public static ARROW_RIGHT: string = "ArrowRight";
    public static ARROW_LEFT: string = "ArrowLeft";
    public static ARROW_RIGHT_IE: string = "Right";
    public static ARROW_LEFT_IE: string = "Left";
    public static ARROW_UP: string = "ArrowUp";
    public static ARROW_DOWN: string = "ArrowDown";
    public static ARROW_UP_IE: string = "Up";
    public static ARROW_DOWN_IE: string = "Down";
    public static ENTER: string = "Enter";
    public static TAB: string = "Tab";
    public static ESC: string = "Esc";  // IE/Edge specific value
    public static ESCAPE: string = "Escape";
}
