import { of, Observable, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MediaPlayer } from '../media-player';
import {
  addProvider,
  AffinityService,
  IClip,
  IProviderDescriptor,
  Logger,
  MediaTimeLine,
  MediaUtil,
} from '../../index';
import { TuneService } from '../../tune/tune.service';
import { CurrentlyPlayingService } from '../../currently-playing/currently.playing.service';
import { IAppConfig } from '../../config/interfaces/app-config.interface';
import { ChromecastModel } from '../../chromecast/chromecast.model';
import { ChromecastPlayerConfig } from './chromecast-player.config';
import { ChromecastPlayerConsts } from './chromecast-player.consts';
import { IMediaAssetMetadata } from '../media-asset-metadata.interface';
import { ChromecastConst } from '../../chromecast/chromecast.const';
import { secondsToMs } from '../../util/utilities';
import { ChromecastUtil } from '../../chromecast/chromecast.util';
import { PlayheadTimestampService } from '../playhead-timestamp.service';
import { ChromecastMessageBus } from '../../chromecast/chromecast.message-bus';
import { ChromecastPlayerEventMonitor } from './chromecast-player.event-monitor';
import { MediaPlayerConstants } from '../media-player.consts';
import { SessionTransferService } from '../../sessiontransfer/session.transfer.service';
import { PlayerTypes } from '../../service/types/content.types';
import { IMetaData } from '../../chromecast/chromecast.interface';
import { MediaTimeLineService } from '../../media-timeline/media.timeline.service';
import {
  IMultiTrackMediaPlayer,
  //IMediaTrigger,
} from '../media-player.interface';
import { Playhead } from '../playhead.interface';
import { MultiTrackConstants } from '../multitrackaudioplayer/multi-track.consts';
import { RefreshTracksService } from '../../refresh-tracks/refresh-tracks.service';

export class ChromecastPlayerService extends MediaPlayer
  implements IMultiTrackMediaPlayer {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('ChromecastPlayerService');

  /**
   * The player's configuration.
   * @type {null}
   */
  private configuration: ChromecastPlayerConfig = null;

  /**
   * The player's state is an internal property that can only be set by the player itself.
   * It has an external getter, but no setter.
   * @type {string}
   */
  private state: string = ChromecastPlayerConsts.IDLE;

  /**
   * Reference to the Chromecast remote player. This must be set by the outside controlling
   * service that uses this player.
   * @type {cast.framework.RemotePlayer}
   */
  private remotePlayer = null;
  /**
   * Reference to the Chromecast remote player controller. This must be set by the outside controlling
   * service that uses this player.
   * @type {cast.framework.RemotePlayerController}
   */
  private remotePlayerController = null;

  /**
   * Monitors the events from the audio player.
   */
  private chromecastPlayerEventMonitor: ChromecastPlayerEventMonitor = null;

  /**
   * Used to switch player between chromecast and audio player
   * @type {BehaviorSubject<string>}
   */
  public switchPlayerType$: BehaviorSubject<string> = new BehaviorSubject('');

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(ChromecastPlayerService, ChromecastPlayerService, [
      'IAppConfig',
      TuneService,
      CurrentlyPlayingService,
      ChromecastModel,
      PlayheadTimestampService,
      ChromecastMessageBus,
      SessionTransferService,
      MediaTimeLineService,
      RefreshTracksService,
    ]);
  })();

  constructor(
    private appConfig: IAppConfig,
    protected tuneService: TuneService,
    protected currentlyPlayingService: CurrentlyPlayingService,
    private chromecastModel: ChromecastModel,
    private playheadTimestampService: PlayheadTimestampService,
    private chromecastMessageBus: ChromecastMessageBus,
    sessionTransferService: SessionTransferService,
    protected mediaTimeLineService: MediaTimeLineService,
    protected refreshTracksService: RefreshTracksService,
  ) {
    super(
      tuneService,
      currentlyPlayingService,
      sessionTransferService,
      mediaTimeLineService,
    );
    this.playbackTypes = [PlayerTypes.REMOTE];
    this.chromecastPlayerEventMonitor = new ChromecastPlayerEventMonitor(
      this.playheadTimestampService,
      this.chromecastModel,
      this.chromecastMessageBus,
      this.currentlyPlayingService,
    );

    this.playhead$ = this.chromecastPlayerEventMonitor.playhead$;

    this.playhead$
      .pipe(
        filter((playhead: Playhead) => {
          return (
            playhead.type ===
              ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.MEDIA_FINISHED ||
            playhead.type ===
              ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.MEDIA_LOADED ||
            playhead.type ===
              ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.MEDIA_SKIPPED
          );
        }),
      )
      .subscribe(playhead => {
        if (
          playhead.type ===
          ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.MEDIA_FINISHED
        ) {
          ChromecastPlayerService.logger.debug('MEDIA_FINISHED' + playhead.id);
          this.refreshTracksService.trackFinished(
            playhead.id,
            this.mediaTimeLine,
          );
        } else if (
          playhead.type ===
          ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.MEDIA_SKIPPED
        ) {
          ChromecastPlayerService.logger.debug('MEDIA_SKIPPED' + playhead.id);
          this.refreshTracksService.trackSkipped(this.mediaTimeLine);
        } else if (
          playhead.type ===
          ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.MEDIA_LOADED
        ) {
          ChromecastPlayerService.logger.debug('MEDIA_LOADED' + playhead.id);
          this.refreshTracksService.trackStarted(
            playhead.id,
            this.mediaTimeLine,
          );
        }
      });
    // Note: Do this last, because it could cause observables to trigger that may be dependent on the object
    // being completely set up
    this.setSubscribers();
  }

  /**
   * Sets the remote player and controller.
   * @param player
   * @param playerController
   */
  public setRemotePlayerAndController(player, playerController): void {
    if (!player || !playerController) {
      ChromecastPlayerService.logger.warn(
        'setRemotePlayerAndController( The remote player and controller cannot be falsy!!! )',
      );
    }
    this.remotePlayer = player;
    this.remotePlayerController = playerController;
  }

  /**
   * Switches the audio player's to/from the sender's to/from receiver's.
   *
   * @param {ChromecastPlayer|null} [chromecastPlayer] - Either the ChromecastPlayer or null.
   */
  public switchPlayer(chromecastPlayer: ChromecastPlayerService) {
    this.configuration = this.configuration
      ? this.configuration
      : new ChromecastPlayerConfig();
    const config: ChromecastPlayerConfig = this.configuration;
    const isChromecastPlayer: boolean = !!chromecastPlayer;

    if (isChromecastPlayer) {
      ChromecastPlayerService.logger.debug('switchPlayer()');

      // Update the config's start time to that of the last known playhead timestamp so the cast player
      // starts at the last known time of the web client.
      config.startTime = this.chromecastModel.playheadZulu;

      // TODO - vpaindla - we need to store the value in one place due to DI issues had to go this way but need to
      // rethink about this.
      this.chromecastModel.playerType = PlayerTypes.REMOTE;
      this.chromecastModel.loadInProgress = false;
      this.mediaTimeLineService.setRemotePlayerType(PlayerTypes.REMOTE);

      this.switchPlayerType$.next(ChromecastPlayerConsts.TYPE);
      this.state = this.chromecastModel.isReconnection
        ? ChromecastPlayerConsts.PLAYING
        : this.state;
    } else if (!isChromecastPlayer) {
      ChromecastPlayerService.logger.debug(
        'switchPlayer( Switching back to audio player. )',
      );
      this.chromecastModel.playerType = PlayerTypes.LOCAL;
      this.mediaTimeLineService.setRemotePlayerType(PlayerTypes.LOCAL);
      this.resetConfig();
      this.switchPlayerType$.next(config.metaData.mediaType);
    } else {
      ChromecastPlayerService.logger.debug(
        'switchPlayer( DO NOT switch as requested player type is already current. )',
      );
    }
  }

  /**
   * Plays the media player's playback with given starttime
   * @param {number} startTime
   */
  public play(startTime: number = 0): Observable<string> {
    const playSuccess = () => {
      this.state = this.remotePlayer.isPaused
        ? ChromecastPlayerConsts.PAUSED
        : ChromecastPlayerConsts.PLAYING;
      (this.playbackState as BehaviorSubject<string>).next(this.state);
      ChromecastPlayerService.logger.debug(
        `playSuccess( startTime: ${startTime}, playbackState: ${this.state} )`,
      );
    };
    const playFault = error =>
      ChromecastPlayerService.logger.debug(`playFault( startTime: ${error} )`);

    this.loadMedia(startTime).then(playSuccess, playFault);
    return of(this.state);
  }

  /**
   * Starts the audio play back.
   */
  public startAudio(): void {
    this.play();
  }

  /**
   * Pauses the media player's playback.
   */
  public pause(): Observable<string> {
    ChromecastPlayerService.logger.debug('pause()');
    this.remotePlayerController.playOrPause();
    this.state = ChromecastPlayerConsts.PAUSED;
    (this.playbackState as BehaviorSubject<string>).next(
      ChromecastPlayerConsts.PAUSED,
    );
    return of(this.state);
  }

  /**
   * Resumes the media player's playback.
   */
  public resume(): Observable<string> {
    if (this.state === ChromecastPlayerConsts.IDLE) {
      ChromecastPlayerService.logger.debug(
        'resume( Idle state, so callign play(). )',
      );
      return this.play();
    } else if (this.state !== ChromecastPlayerConsts.PLAYING) {
      ChromecastPlayerService.logger.debug('resume()');
      this.remotePlayerController.playOrPause();
      this.state = ChromecastPlayerConsts.PLAYING;
      (this.playbackState as BehaviorSubject<string>).next(
        ChromecastPlayerConsts.PLAYING,
      );
      return of(this.state);
    } else {
      return of(this.state);
    }
  }

  /**
   * togles between play and pause
   */
  public togglePausePlay(): Observable<string> {
    const isPaused: boolean = this.state === ChromecastPlayerConsts.PAUSED;
    const isStopped: boolean = this.state === ChromecastPlayerConsts.STOPPED;
    const isIdle: boolean = this.state === ChromecastPlayerConsts.IDLE;
    const isUnknown: boolean = !!this.state === false;
    const shouldPlay: boolean = isStopped || isIdle || isUnknown;

    if (shouldPlay) {
      ChromecastPlayerService.logger.debug('togglePausePlay( Play )');
      return this.play();
    } else if (isPaused) {
      ChromecastPlayerService.logger.debug('togglePausePlay( Resume )');
      return this.resume();
    } else {
      ChromecastPlayerService.logger.debug('togglePausePlay( Pause )');
      return this.pause();
    }
  }

  /**
   * sets the player state based on event triggered by caf
   * @param {{field: string; value: any}} event
   */
  public setState(paused: boolean): void {
    if (this.state === ChromecastPlayerConsts.IDLE) {
      return;
    }
    this.state = paused
      ? ChromecastPlayerConsts.PAUSED
      : ChromecastPlayerConsts.PLAYING;
    (this.playbackState as BehaviorSubject<string>).next(this.state);
  }

  /**
   * Seeks audio playback.
   * @param {number} startTime
   */
  public seek(startTime: number): Observable<string> {
    ChromecastPlayerService.logger.debug(`seek( time: ${startTime} )`);
    this.configuration.startTime = startTime;
    this.remotePlayer.currentTime = startTime;
    this.remotePlayerController.seek();
    return of('seek');
  }

  /**
   * Used to play the next track When user skip the track.
   */
  public skip(): Observable<string> {
    const event = {
      type: ChromecastPlayerConsts.CHROMECAST_SENDER_EVENT.SKIP,
      data: {
        direction: 'skip',
      },
    };

    this.chromecastMessageBus.sendMessage(event);

    return of('skip');
  }

  /**
   * Used to rewind the track when user rewind the track
   */
  public rewind(): Observable<string> {
    const event = {
      type: ChromecastPlayerConsts.CHROMECAST_SENDER_EVENT.SKIP,
      data: {
        direction: 'rewind',
      },
    };

    this.chromecastMessageBus.sendMessage(event);

    return of('rewind');
  }

  /**
   * Stops audio playback.
   *
   * NOTE: When the native web client audio player is changing channels or tracks stop() is
   * called on it and that's a good thing; however, for Chromecast we only want to stop
   * when we're logging out as the native Chromecast audio player handles changing media
   * via it's loadMedia() method for us and calling stop can have adverse affects.
   */
  public stop(/*id?: string | number*/): void {
    ChromecastPlayerService.logger.debug('stop()');
    this.remotePlayerController.stop();
    this.state = ChromecastPlayerConsts.STOPPED;
    (this.playbackState as BehaviorSubject<string>).next(
      ChromecastPlayerConsts.STOPPED,
    );
  }

  public destroy(): void {
    ChromecastPlayerService.logger.debug('destroy()');
  }

  public getType(): string {
    return ChromecastPlayerConsts.TYPE;
  }

  public getId(): string {
    return 'BMR TODO ID';
  }

  /**
   * returns the duration
   * @returns {number}
   */
  public getDuration(): number {
    return this.chromecastModel.playheadDurationSeconds;
  }

  /**
   * returns the duration in seconds
   * @returns {number}
   */
  public getDurationInSeconds(): number {
    return this.getDuration();
  }

  /**
   * Converts a zero-based second value to a zulu-based millisecond value. This is most commonly used when
   * seeking and the UI provides a zero-based second value.
   *
   * @param seconds
   * @returns {number}
   */
  public convertZeroBasedSecondsToZulu(seconds: number): number {
    return this.chromecastModel.playheadStartZulu + secondsToMs(seconds);
  }

  /**
   * returns playhead zulu time
   * @returns {number}
   */
  public getPlayheadZuluTime(): number {
    return this.chromecastModel.playheadZulu;
  }

  /**
   * returns playhead start zulutime
   * @returns {number}
   */
  public getPlayheadStartZuluTime(): number {
    return this.chromecastModel.playheadStartZulu;
  }

  public getLastSeekTime(): number {
    return 0;
  }

  public getVolume(): number {
    return 0;
  }

  /**
   * sets the volume
   * @param {number} volume
   */
  public setVolume(volume: number): void {
    if (
      this.chromecastModel.remotePlayer &&
      this.chromecastModel.remotePlayerController
    ) {
      // volume should be in double data type
      this.chromecastModel.remotePlayer.volumeLevel =
        volume / MediaPlayerConstants.MAX_VOLUME_VALUE;
      this.chromecastModel.remotePlayerController.setVolumeLevel();
    }
  }

  /**
   * mute or unmute volume
   */
  public mute(): void {
    if (
      this.chromecastModel.remotePlayer &&
      this.chromecastModel.remotePlayerController
    ) {
      this.chromecastModel.remotePlayerController.muteOrUnmute();
    }
  }

  public unmute(): void {
    ChromecastPlayerService.logger.debug('unmute()');
  }

  public getPlaybackType(): string {
    return 'BMR TODO PlaybackType';
  }

  public getState(): string {
    return this.state;
  }

  public getMediaAssetMetadata(): IMediaAssetMetadata {
    return {} as IMediaAssetMetadata;
  }

  public isPlaying(): boolean {
    return this.state === ChromecastPlayerConsts.PLAYING;
  }

  public isPaused(): boolean {
    return this.state === ChromecastPlayerConsts.PAUSED;
  }

  public isStopped(): boolean {
    return this.state === ChromecastPlayerConsts.STOPPED;
  }

  public isIdle(): boolean {
    return this.state === ChromecastPlayerConsts.IDLE;
  }

  public isFinished(): boolean {
    // return this.state === CHROMECAST_PLAYER_STATE.FINISHED;
    return false;
  }

  public isLive(): boolean {
    // return this.state === CHROMECAST_PLAYER_STATE.FINISHED;
    return true;
  }

  /**
   * resets config
   */
  public resetConfig() {
    this.configuration = null;
    this.state = ChromecastPlayerConsts.IDLE;
  }

  /**
   * On New media
   * @param {MediaTimeLine} mediaTimeLine
   * @param {IMediaTrigger} mediaTrigger
   */
  protected onNewMedia(
    mediaTimeLine: MediaTimeLine,
    /*mediaTrigger: IMediaTrigger,*/
  ) {
    if (!mediaTimeLine || mediaTimeLine.playerType === PlayerTypes.LOCAL)
      return;

    if (!this.configuration) {
      this.configuration = new ChromecastPlayerConfig();
    }

    ChromecastPlayerService.logger.debug(
      `onNewMedia( channelId: ${mediaTimeLine.channelId} )`,
    );

    this.configuration.metaData = {
      channelId: MediaUtil.isSeededRadioMediaType(mediaTimeLine.mediaType)
        ? mediaTimeLine.channel.stationFactory
        : mediaTimeLine.channelId,
      assetGuid: mediaTimeLine.mediaId,
      mediaType: mediaTimeLine.mediaType,
      sequencerId: mediaTimeLine.sequencerSessionId,
      stationId: mediaTimeLine.stationId,
      tracks: mediaTimeLine.clips ? mediaTimeLine.clips.toArray() : [],
    };

    if (this.isStopped() || this.isIdle()) {
      return;
    }

    if (
      !mediaTimeLine.isDataComeFromResume &&
      this.chromecastModel.currentlyPlayingData.assetGuid !==
        mediaTimeLine.mediaId
    ) {
      let startTime = 0;
      if (
        MediaUtil.isLiveMediaType(mediaTimeLine.mediaType) ||
        MediaUtil.isVideoMediaType(mediaTimeLine.mediaType)
      ) {
        const mediaEndPoint =
          mediaTimeLine.mediaEndPoints.length > 0
            ? mediaTimeLine.mediaEndPoints[0]
            : null;

        startTime =
          mediaEndPoint &&
          mediaEndPoint.position &&
          mediaEndPoint.position.zuluTimestamp
            ? mediaEndPoint.position.zuluTimestamp
            : 0;
      } else if (MediaUtil.isAudioMediaTypeOnDemand(mediaTimeLine.mediaType)) {
        const mediaEndPoint =
          mediaTimeLine.mediaEndPoints.length > 0
            ? mediaTimeLine.mediaEndPoints[0]
            : null;
        const mediaFirstChunk =
          mediaEndPoint && mediaEndPoint.mediaFirstChunks.length > 0
            ? mediaEndPoint.mediaFirstChunks[0]
            : null;
        startTime = mediaFirstChunk ? mediaFirstChunk.zuluTimestamp : 0;
      }
      this.chromecastModel.autoPlay = true;
      this.play(startTime);
    }
  }

  /**
   * sets the subscribers
   */
  protected setSubscribers(): void {
    ChromecastPlayerService.logger.debug('setSubscribers()');
    super.setSubscribers();
    this.subscribeAiCTune();
  }

  protected subscribeAiCTune() {
    this.chromecastModel.tuneAIC$.subscribe(metaData => {
      this.configuration.metaData = {
        channelId: metaData.channelId,
        assetGuid: metaData.assetGuid,
        mediaType: metaData.mediaType,
        sequencerId: metaData.sequencerId,
        tracks: metaData.tracks,
        skipLimitReached: metaData.skipLimitReached,
        stationId: metaData.stationId,
      };

      this.chromecastModel.autoPlay = true;
      this.play();
    });
  }

  /**
   * Kicks off the audio playback process by passing the Chromecast Receiver the next URL to load, which
   * should be an API call like resume or similar. Once this call resolves audio should start to play.
   *
   */
  private loadMedia(startTime: number = 0): Promise<any> {
    const metaData: IMetaData = this.getMetadataToLoad();

    if (!metaData || this.chromecastModel.loadInProgress) {
      ChromecastPlayerService.logger.debug(
        `loadMedia Not loaded ( metaData: ${metaData} )`,
      );
      return new Promise<any>(resolve => {
        resolve(true);
      });
    }

    const currentMediaPlayheadTime =
      this.playheadTimestampService.playhead &&
      this.playheadTimestampService.playhead.getValue() &&
      this.playheadTimestampService.playhead.getValue().currentTime &&
      this.playheadTimestampService.playhead.getValue().currentTime
        .zuluMilliseconds
        ? this.playheadTimestampService.playhead.getValue().currentTime
            .zuluMilliseconds
        : 0;

    const playheadStartTime: number = startTime
      ? startTime
      : currentMediaPlayheadTime;

    ChromecastPlayerService.logger.debug(
      `loadMedia( startTime: ${startTime} )`,
    );

    this.chromecastModel.currentlyPlayingData = {
      channelId: metaData.channelId,
      assetGuid: metaData.assetGuid,
      mediaType: metaData.mediaType,
      sequencerId: metaData.sequencerId,
      tracks: metaData.tracks,
      stationId: metaData.stationId,
      skipLimitReached: metaData.skipLimitReached,
    };

    this.chromecastModel.loadInProgress = true;
    const session = this.chromecastModel.getSession();
    const mediaInfo = new ChromecastConst.CC.media.MediaInfo(null);
    mediaInfo.metadata = new ChromecastConst.CC.media.GenericMediaMetadata();
    mediaInfo.metadata.metadataType =
      ChromecastConst.CC.media.MetadataType.GENERIC;
    mediaInfo.metadata.apiEnvironment = ChromecastUtil.getApiEnv(
      this.appConfig,
    );
    mediaInfo.metadata.startTime = playheadStartTime;
    mediaInfo.metadata.channelId = metaData.channelId;
    mediaInfo.metadata.mediaType = metaData.mediaType;
    mediaInfo.metadata.assetGuid = metaData.assetGuid;
    mediaInfo.metadata.sequencerId = metaData.sequencerId;
    mediaInfo.metadata.stationId = metaData.stationId;
    mediaInfo.metadata.usePausePoint = metaData.skipLimitReached;
    mediaInfo.metadata.tracks = JSON.stringify(metaData.tracks);
    mediaInfo.metadata.senderName = ChromecastConst.SENDER;
    mediaInfo.autoplay = this.chromecastModel.autoPlay;
    ChromecastPlayerService.logger.debug(
      `loadMedia( CC autoplay: ${mediaInfo.autoplay} )`,
    );

    // NOTE: This is not currently used as the seamless audio issue was solved with a custom implementation
    // on the Receiver side of the house, but we're leaving it here for the moment for context and in
    // event that Google says this is the correct way to seek.
    // mediaInfo.metadata.timeIntoPlaylist = timeIntoPlaylist;

    // Makes sure to add the start time to the load request so the Chromecast audio player
    // starts audio from the last known timestamp.
    const request = new ChromecastConst.CC.media.LoadRequest(mediaInfo);

    // NOTE: We're no longer using the native Chromecast API to play audio from our last known timestamp.
    // The Receiver now uses a custom implementation that relies on the `startTime` as a zulu timestamp
    // (aka the last played timestamp on the web client) passed in via the LoadMedia's metadata above.
    // It should not pass in this time via the LoadRequests's `currentTime` property that's commented
    // out below, but we'll keep it here now for posterity so other devs have full context.
    // request.currentTime = timeIntoPlaylist;

    const loadMediaSuccess = () => {
      ChromecastPlayerService.logger.debug(
        `loadMediaSuccess( startTime: ${startTime} )`,
      );
      this.chromecastModel.loadInProgress = false;
    };
    const loadMediaFault = error => {
      ChromecastPlayerService.logger.debug(
        `loadMediaFault( startTime: ${error} )`,
      );
      this.chromecastModel.loadInProgress = false;
      throw error;
    };

    return session.loadMedia(request).then(loadMediaSuccess, loadMediaFault);
  }

  /**
   * Gets the meta data for the load request
   * @returns {IMetaData}
   */
  private getMetadataToLoad(): IMetaData {
    const channelIdFromMediaTimeLine =
      this.mediaTimeLine && this.mediaTimeLine.channelId
        ? this.mediaTimeLine.channelId
        : '';

    const channelId =
      this.configuration &&
      this.configuration.metaData &&
      this.configuration.metaData.channelId
        ? this.configuration.metaData.channelId
        : channelIdFromMediaTimeLine;

    const mediaTypeFromMediaTimeLine =
      this.mediaTimeLine && this.mediaTimeLine.mediaType
        ? this.mediaTimeLine.mediaType
        : '';

    const mediaType =
      this.configuration &&
      this.configuration.metaData &&
      this.configuration.metaData.mediaType
        ? this.configuration.metaData.mediaType
        : mediaTypeFromMediaTimeLine;

    const mediaIdFromMediaTimeLine =
      this.mediaTimeLine && this.mediaTimeLine.mediaId
        ? this.mediaTimeLine.mediaId
        : '';

    const assetGuid =
      this.configuration &&
      this.configuration.metaData &&
      this.configuration.metaData.assetGuid
        ? this.configuration.metaData.assetGuid
        : mediaIdFromMediaTimeLine;

    const sequencerId =
      this.configuration &&
      this.configuration.metaData &&
      this.configuration.metaData.sequencerId
        ? this.configuration.metaData.sequencerId
        : '';

    const stationId =
      this.configuration &&
      this.configuration.metaData &&
      this.configuration.metaData.stationId
        ? this.configuration.metaData.stationId
        : '';

    const skipLimitReached =
      this.configuration &&
      this.configuration.metaData &&
      this.configuration.metaData.skipLimitReached
        ? this.configuration.metaData.skipLimitReached
        : false;

    if (
      (!channelId && !MediaUtil.isPodcastMediaType(mediaType)) ||
      !mediaType ||
      !assetGuid
    )
      return null;

    const clips =
      this.configuration &&
      this.configuration.metaData &&
      this.configuration.metaData.tracks
        ? this.configuration.metaData.tracks
        : [];

    const clipsAltered = clips.map((clip: IClip) => {
      return {
        albumName: clip.albumName,
        artistName: clip.artistName,
        assetType: clip.assetType,
        category: clip.category,
        clipImageUrl: clip.clipImageUrl,
        consumptionInfo: clip.consumptionInfo,
        duration: clip.duration,
        title: clip.title,
        trackStatus:
          clip.status === MultiTrackConstants.PRELOAD
            ? MultiTrackConstants.FUTURE
            : clip.status,
        assetGuid: clip.assetGUID, // used for AIC
        contentUrlList: clip.contentUrlList,
        trackToken: clip.assetGUID, // Used for seeded radio
        index: clip.index,
        affinity: clip.affinity
          ? AffinityService.getAffinityMappingValue(clip.affinity)
          : 0,
      };
    });

    return {
      channelId: channelId,
      mediaType: mediaType,
      assetGuid: assetGuid,
      sequencerId: sequencerId,
      tracks: clipsAltered,
      stationId: stationId,
      skipLimitReached: skipLimitReached,
    };
  }
}
