import { share, map } from 'rxjs/operators';
import {
    IProviderDescriptor,
    addProvider
} from "../service";
import {
    IHttpRequestConfig,
    HttpProvider
}                                   from "../http";
import { Logger }                   from "../logger";
import { ServiceEndpointConstants } from "../service/consts";
import {
    AIC_IMAGE_HEIGHT,
    AIC_IMAGE_WIDTH
}                                   from "../service/types";
import {
    DeepLinkType,
    DeepLinkTypes,
    IContextualData
}                                   from "./contextual.interface";
import { Observable }               from "rxjs";
import * as _                       from "lodash";
import {
    IMAGE,
    NAME_COLOR_CHANNEL_LOGO,
    LIVE_IMAGE_WIDTH,
    LIVE_IMAGE_HEIGHT,
    DEFAULT_HEIGHT,
    DEFAULT_WIDTH,
    NAME_LIST_VIEW_SHOW_LOGO
}                                   from "../service/types/image.interface";
import { DateUtil }                 from "../util/date.util";

/**
 * @MODULE:     service-lib
 * @CREATED:    01/10/18
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *      This delegate is responsible for making the API get Login PDT call and returning a known
 *      client domain model representing the PDT data.
 */
export class ContextualDelegate
{
    /**
     * Internal logger.
     * @type {Logger}
     */
    private static logger: Logger = Logger.getLogger("ContextualDelegate");

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(ContextualDelegate, ContextualDelegate, [ HttpProvider ]);
    }();

    /**
     * Constructor
     * @param http - The http object is used to make HTTP API requests.
     */
    constructor(private http: HttpProvider) {}

    /**
     * Makes the HTTP call to get deeplink data for a given deepLinkId and deepLinkType
     * @param {string} deepLinkId
     * @param {DeepLinkType} deepLinkType
     * @returns {Observable<IContextualData>}
     */
    public getContextualPDT(deepLinkId: string, deepLinkType: DeepLinkType): Observable<IContextualData>
    {
        const config: IHttpRequestConfig = {
            params: {
                "app-region"   : "",
                "deepLinkId"   : deepLinkId.replace(/%20/g, " "),
                "deepLink-type": deepLinkType,
                "result-template" : "web"
            }
        };

        return this.http.get(ServiceEndpointConstants.endpoints.DEEPLINK.V2_DEEPLINK, null, config).pipe(
                   map((response) =>
                   {
                       switch (deepLinkType)
                       {
                           case DeepLinkTypes.CHANNEL:
                           case DeepLinkTypes.AIC:
                           {
                               const channelResponse =
                                         _.get(response, "contentData.channelListing.channels") as Array<any>;
                               return normalizeDeepLinkChannelData(channelResponse, deepLinkType);
                           }
                           case DeepLinkTypes.LIVE_VIDEO:
                           case DeepLinkTypes.LIVE:
                           {
                               const liveDataResponse =
                                         _.get(response, "moduleDetails.liveChannelResponse.liveChannelResponses") as Array<any>;
                               return normalizeDeepLinkLiveData(liveDataResponse, deepLinkType);
                           }
                           case DeepLinkTypes.AOD:
                           case DeepLinkTypes.PODCAST:
                           case DeepLinkTypes.VOD:
                           {
                               const odResponse =
                                         _.get(response, "contentData") as any;
                               return normalizeDeepLinkOdData(odResponse, deepLinkType);
                           }
                           case DeepLinkTypes.COLLECTION:
                           case DeepLinkTypes.VIDEOS:
                           case DeepLinkTypes.SHOW:
                           case DeepLinkTypes.PODCASTS:
                           {
                               const collectionResponse =
                                         _.get(response, "heroCarousel") as any;
                               return normalizeCarouselData(collectionResponse, deepLinkType);
                           }
                           case DeepLinkTypes.CATEGORY:
                           {
                               const categoryResponse = response && response.carousel && response.carousel.length > 0
                                                        ? response.carousel[0] : null;
                               return normalizeCarouselData(categoryResponse, deepLinkType);
                           }
                           case DeepLinkTypes.CATEGORY:
                           {
                               const collectionResponse =
                                         _.get(response, "heroCarousel") as any;
                               return normalizeCarouselData(response.carousel[0], deepLinkType);
                           }
                           case DeepLinkTypes.SEEDED_RADIO:
                           {
                               const seededChannelResponse =  _.get(response,
                                   "sxmSearch[0].searchResultList[0].searchAsset.seededChannel") as any;
                               return normalizeDeepLinkSeededChannelData(seededChannelResponse, deepLinkType);
                           }


                           default:
                           {
                               throw(`Unknown deepLink Type`);
                           }
                       }

                   }),
                   share());

        /**
         * Normalizes the deep link live type response
         * @param liveDataResponse
         * @returns {IContextualData}
         */
        function normalizeDeepLinkLiveData(liveDataResponse: any, deepLinkType: string): IContextualData
        {
            if (!liveDataResponse)
            {
                return {
                    imageUrl       : "",
                    title          : "",
                    subTitle       : "",
                    description    : "",
                    originalAirDate: "",
                    expirationDate : "",
                    buttonText     : "",
                    isSpecial      : false,
                    deepLinkType   : deepLinkType
                };
            }
            const liveData = liveDataResponse[ 0 ];

            const markerLists    = _.get(liveData, "markerLists");
            const cutMarkers     = Array.isArray(markerLists) ? _.get(markerLists[ 0 ], "markers") : null;
            const episodeMarkers = Array.isArray(markerLists) ? _.get(markerLists[ 1 ], "markers") : null;
            const liveVideoMarkers = Array.isArray(markerLists) ? _.get(markerLists[ 2 ], "markers") : null;
            const cut            = Array.isArray(cutMarkers) ? _.get(cutMarkers[ 0 ], "cut") : null;
            const episode        = Array.isArray(episodeMarkers) ? _.get(episodeMarkers[ 0 ], "episode") : null;
            const liveVideoImage = Array.isArray(liveVideoMarkers) ? _.get(liveVideoMarkers[0], "video.images.images[0].url") : null;
            const images         = _.get(liveData, "channel.images.images");
            const artistName     = cut && Array.isArray(cut.artists) ? cut.artists[ 0 ].name : "";
            const cutTitle       = cut && cut.title ? cut.title : "";
            const subTitle       = episode && episode.show && episode.show.longTitle ? episode.show.longTitle : "";

            const liveVideoRequested = deepLinkType === DeepLinkTypes.LIVE_VIDEO;
            const haveLiveVideoImage = !!liveVideoImage;


            return {
                imageUrl       : liveVideoRequested && haveLiveVideoImage ?
                                    liveVideoImage :
                                     getImageUrl(images, NAME_COLOR_CHANNEL_LOGO, LIVE_IMAGE_WIDTH, LIVE_IMAGE_HEIGHT),
                title          : artistName && cutTitle ? artistName + " - " + cutTitle : "",
                subTitle       : subTitle,
                description    : "",
                originalAirDate: "",
                expirationDate : "",
                buttonText     : "landing.listenNow",
                isSpecial      : false,
                deepLinkType   : liveVideoRequested && haveLiveVideoImage ? DeepLinkTypes.LIVE_VIDEO : DeepLinkTypes.LIVE
            };
        }

        /**
         * Normalizes the Deep link channel response data
         * @param channelDataResponse
         * @returns {IContextualData}
         */
        function normalizeDeepLinkChannelData(channelDataResponse: any, deepLinkType: DeepLinkType): IContextualData
        {
            if (!channelDataResponse)
            {
                return {
                    imageUrl       : "",
                    title          : "",
                    subTitle       : "",
                    description    : "",
                    originalAirDate: "",
                    expirationDate : "",
                    buttonText     : "",
                    isSpecial      : false,
                    deepLinkType   : deepLinkType
                };
            }

            const channelData = channelDataResponse[ 0 ];
            const images      = _.get(channelData, "images.images");

            return {
                imageUrl       : getImageUrl(images, NAME_COLOR_CHANNEL_LOGO, AIC_IMAGE_WIDTH, AIC_IMAGE_HEIGHT),
                title          : channelData.name ? channelData.name : "",
                subTitle       : "",
                description    : channelData.shortDescription ? channelData.shortDescription : "",
                originalAirDate: "",
                expirationDate : "",
                buttonText     : "landing.viewNow",
                isSpecial      : false,
                deepLinkType   : deepLinkType
            };
        }

        /**
         * Normalizes the Deep link seeded channel response data
         * @param channelDataResponse
         * @returns {IContextualData}
         */
        function normalizeDeepLinkSeededChannelData(seededChannelDataResponse: any, deepLinkType: DeepLinkType): IContextualData
        {
            if (!seededChannelDataResponse)
            {
                return {
                    imageUrl       : "",
                    title          : "",
                    subTitle       : "",
                    description    : "",
                    originalAirDate: "",
                    expirationDate : "",
                    buttonText     : "",
                    isSpecial      : false,
                    deepLinkType   : deepLinkType
                };
            }

            return {
                imageUrl       : seededChannelDataResponse.imageURL,
                title          : seededChannelDataResponse.stationName ? seededChannelDataResponse.stationName : "",
                subTitle       : seededChannelDataResponse.pandoraText ? seededChannelDataResponse.pandoraText : "",
                description    : "",
                originalAirDate: "",
                expirationDate : "",
                buttonText     : "landing.viewNow",
                isSpecial      : false,
                deepLinkType   : deepLinkType
            };
        }

        /**
         * Normalizes the Deep link Aod/Vod Data
         * @param odDataResponse
         * @param deepLinkType - can be aod/vod
         * @returns {IContextualData}
         */
        function normalizeDeepLinkOdData(odDataResponse: any, deepLinkType: DeepLinkType): IContextualData
        {
            if (!odDataResponse)
            {
                return {
                    imageUrl       : "",
                    title          : "",
                    subTitle       : "",
                    description    : "",
                    originalAirDate: "",
                    expirationDate : "",
                    buttonText     : "",
                    isSpecial      : false,
                    deepLinkType   : deepLinkType
                };
            }


            const aodShows                = getOdShows(odDataResponse, deepLinkType);
            const shows                   = Array.isArray(aodShows) ? _.get(aodShows[ 0 ], "shows") : null;
            const episode                 = getOdEpisode(odDataResponse, deepLinkType);
            const show                    = Array.isArray(shows) ? shows[ 0 ] : null;
            const originalAirDate         = episode && episode.originalAirDate ? DateUtil.convertToDate(episode.originalAirDate) : null;
            const expirationDate          = episode && episode.publicationInfo ? DateUtil.convertToDate(episode.publicationInfo.expiryDate) : null;
            const images                  = _.get(show, "showDescription.creativeArts");
            const episodeLongTitle        = episode && episode.longTitle ? episode.longTitle : "";
            const episodeMediumTitle      = episode && episode.mediumTitle ? episode.mediumTitle : "";
            const episodeLongDescription  = episode && episode.longDescription ? episode.longDescription : "";
            const episodeShortDescription = episode && episode.shortDescription ? episode.shortDescription : "";
            const showLongTitle           = show && show.showDescription && show.showDescription.longTitle ? show.showDescription.longTitle : "";
            const showMediumTitle         = show && show.showDescription && show.showDescription.mediumTitle ? show.showDescription.mediumTitle : "";
            const image                   = getImageUrl(images, NAME_LIST_VIEW_SHOW_LOGO);
            return {
                imageUrl       : image,
                title          : episodeLongTitle || episodeMediumTitle,
                subTitle       : showLongTitle || showMediumTitle,
                description    : episodeLongDescription || episodeShortDescription,
                originalAirDate: originalAirDate ? DateUtil.convertDateToISO8601Format1(originalAirDate) : "",
                expirationDate : expirationDate ? DateUtil.convertDateToISO8601Format1(expirationDate) : "",
                buttonText     : deepLinkType === DeepLinkTypes.AOD ? "landing.listenNow" : "landing.watchNow",
                isSpecial      : episode ? episode.special : false,
                deepLinkType   : deepLinkType
            };
        }

        /**
         * Normalizes the collection deep link response.
         * @param carouselResponse
         * @param deepLinkType
         */
        function normalizeCarouselData(carouselResponse: any, deepLinkType: DeepLinkType): IContextualData
        {
            if (!carouselResponse ||
                (carouselResponse.carouselTiles && carouselResponse.carouselTiles.length === 0))
            {
                return {
                    imageUrl       : "",
                    title          : "",
                    subTitle       : "",
                    description    : "",
                    originalAirDate: "",
                    expirationDate : "",
                    buttonText     : "",
                    isSpecial      : false,
                    deepLinkType   : deepLinkType
                };
            }

            const tile = carouselResponse.carouselTiles[0];
            const tileMarkup = tile.tileMarkup;
            const tileImage = tileMarkup.tileImage.find((image) => image.imageClass === "logo");
            const tileImageUrl = tileImage ? tileImage.imageLink : "";
            const imageUrl = tileMarkup.promoImage && tileMarkup.promoImage.imageLink
                             ? tileMarkup.promoImage.imageLink
                             : tileImageUrl;
            const line1 = tileMarkup.tileText && tileMarkup.tileText.length > 0
                          ? tileMarkup.tileText[0].textValue : "";

            const line2 = tileMarkup.tileText && tileMarkup.tileText.length > 1
                             ? tileMarkup.tileText[1].textValue : "";
            return {
                imageUrl       : imageUrl,
                title          : line1,
                subTitle       : line2,
                description    : "",
                originalAirDate: "",
                expirationDate : "",
                buttonText     : "landing.viewNow",
                isSpecial      : false,
                deepLinkType   : deepLinkType
            };
        }

        /**
         * Gets the AOD or VOD Episodes from response based on deep Link type
         * @param odResponse - response from API
         * @param {DeepLinkType} deepLinkType
         * @returns {Array<any>}
         */
        function getOdEpisode(odResponse: any, deepLinkType: DeepLinkType): any
        {
            if (deepLinkType === DeepLinkTypes.AOD || DeepLinkTypes.PODCAST)
            {
                const aodEpisodes = _.get(odResponse, "aodEpisodes");
                const episodes    = Array.isArray(aodEpisodes) ? _.get(aodEpisodes[ 0 ], "episodes") : null;
                return Array.isArray(episodes) ? episodes[ 0 ] : null;
            }
            else if (deepLinkType === DeepLinkTypes.VOD)
            {
                const vodEpisodes = _.get(odResponse, "vodEpisodes");
                return Array.isArray(vodEpisodes) ? vodEpisodes[ 0 ] : null;
            }

            return [];
        }

        /**
         * Gets the AOD or VOD Shows from response based on deep Link type
         * @param odResponse - response from API
         * @param {DeepLinkType} deepLinkType
         * @returns {Array<any>}
         */
        function getOdShows(odResponse: any, deepLinkType:DeepLinkType) : Array<any>
        {
            if(deepLinkType === DeepLinkTypes.AOD)
            {
                return  _.get(odResponse, "aodShows");
            }
            else if(deepLinkType === DeepLinkTypes.VOD)
            {
                return  _.get(odResponse, "vodShows");
            }
            return [];
        }

        /**
         * Returns the Image url based on requested name, width and height
         * @param {Array<any>} imageList
         * @param {string} requestedName
         * @param {number} requestedWidth
         * @param {number} requestedHeight
         * @returns {string}
         */
        function getImageUrl(imageList: Array<any>,
                             requestedName: string,
                             requestedWidth: number  = DEFAULT_WIDTH,
                             requestedHeight: number = DEFAULT_HEIGHT): string
        {
            const defaultImageName = IMAGE;

            const findImage = (item: any): boolean =>
            {
                return ((item.name === requestedName || item.name === defaultImageName));
            };

            const images: Array<any> = imageList ? imageList : [];
            const image: any   = images.find(findImage);
            return image ? image.relativeUrl +"?width=" + requestedWidth + "&height=" + requestedHeight + "preserveAspect=true" : "";
        }
    }
}
