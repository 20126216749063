import {
  of,
  from,
  throwError as observableThrowError,
  Observable,
  BehaviorSubject,
  Subscription,
} from 'rxjs';
import { mergeMap, filter, take, concat, first } from 'rxjs/operators';

import {
  Logger,
  MediaUtil,
  MediaTimeLine,
  InitializationStatusCodes,
  ContentTypes,
  IProviderDescriptor,
  MediaPlayerConstants,
  AudioPlayerConstants,
  IClip,
  AppErrorCodes,
  AppMonitorService,
  PlayerTypes,
  MultiTrackConstants,
  BypassMonitorService,
  IAppByPassState,
} from '../../index';

import { IAudioPlayer } from '../audioplayer/audio-player.interface';
import { InitializationService } from '../../initialization';
import { TuneService } from '../../tune/tune.service';
import { CurrentlyPlayingService } from '../../currently-playing/currently.playing.service';
import { NoopService } from '../../noop';
import { ChromecastService } from '../../chromecast';
import { SessionTransferService } from '../../sessiontransfer';
import { MediaTimeLineService } from '../../media-timeline';
import { addProvider } from '../../service';
import { ChromecastPlayerConsts } from '../chromecastplayer/chromecast-player.consts';
import { MediaPlayer } from '../media-player';
import { MultiTrackAudioPlayerConfigFactory } from './multi-track-audio-player.config.factory';
import { PlayerConfig } from '../audioplayer/audio-player.config';
import { Playhead, IPlayhead } from '../playhead.interface';
import { MultiTrackList } from './multi-track-list';
import { AudioPlayerEventMonitor } from '../audio-player.event-monitor';
import { AudioPlayerEventTypes } from '../audioplayer/audio-player.event-types';
import { msToSeconds } from '../../util/utilities';
import {
  IMultiTrackMediaPlayer,
  IMediaTrigger,
} from '../media-player.interface';
import { PlayheadTimestampService } from '../playhead-timestamp.service';
import { IAppConfig } from '../../config/interfaces/app-config.interface';
import { RefreshTracksService } from '../../refresh-tracks/refresh-tracks.service';
import {
  IVideoPlayer,
  VideoPlayerConstants,
  IStartVideo,
} from '../videoplayer';
import { PLAYER_CONFIG as playerConfig } from '../videoplayer//video-player.config';
import { VideoPlayerEventMonitor } from '../videoplayer/video-player.event-monitor';

/**
 * @MODULE:     service-lib
 * @CREATED:    10/31/18
 * @COPYRIGHT:  2018 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * MultiTrackVideoPlayerService used to play the multi track audio.
 */
export class MultiTrackVideoPlayerService extends MediaPlayer
  implements IMultiTrackMediaPlayer {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger(
    'MultiTrackVideoPlayerService',
  );

  /**
   * currentPlaybackState
   */
  private currentPlaybackState: string = VideoPlayerConstants.STOPPED;

  /**
   * Used to store the play type
   */
  private playerTypeLocal: string = PlayerTypes.LOCAL;

  /**
   * Flag indicates aic is in bypass mode or not.
   */
  private aicByPassMode: boolean = false;

  /**
   * Flag indicated ArtistRadio/Seeded is in bypass mode or not
   */
  private artistRadioByPassMode: boolean = false;

  private settingNextTrack = new BehaviorSubject<boolean>(false);

  /* Keeps track of the current song being played on the additional channel playlist */
  public firstAicGuid: string = '';
  public currentTrack: IClip;
  public nextTrack: IClip;

  public playheadSubscription: Subscription = null;

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(
      MultiTrackVideoPlayerService,
      MultiTrackVideoPlayerService,
      [
        'IVideoPlayer',
        'IAppConfig',
        InitializationService,
        TuneService,
        CurrentlyPlayingService,
        PlayheadTimestampService,
        NoopService,
        MultiTrackAudioPlayerConfigFactory,
        ChromecastService,
        AppMonitorService,
        SessionTransferService,
        MediaTimeLineService,
        RefreshTracksService,
        //AudioPlayerEventMonitor,
        VideoPlayerEventMonitor,
        BypassMonitorService,
      ],
    );
  })();

  /**
   * Constructor
   * @param videoPlayer
   * @param SERVICE_CONFIG is the injected configuration object from the client application
   * @param initializationService
   * @param tuneService
   * @param currentlyPlayingService
   * @param playheadTimestampService
   * @param noopService
   * @param multiTrackAudioPlayerConfigFactory
   * @param chromecastService
   * @param sessionTransferService
   * @param mediaTimeLineService
   * @param refreshTracksService
   * @param audioPlayerEventMonitor
   */
  constructor(
    private videoPlayer: IVideoPlayer,
    private SERVICE_CONFIG: IAppConfig,
    private initializationService: InitializationService,
    protected tuneService: TuneService,
    protected currentlyPlayingService: CurrentlyPlayingService,
    protected playheadTimestampService: PlayheadTimestampService,
    private noopService: NoopService,
    private multiTrackAudioPlayerConfigFactory: MultiTrackAudioPlayerConfigFactory,
    private chromecastService: ChromecastService,
    private appMonitorService: AppMonitorService,
    protected sessionTransferService: SessionTransferService,
    protected mediaTimeLineService: MediaTimeLineService,
    protected refreshTracksService: RefreshTracksService,
    //private audioPlayerEventMonitor: AudioPlayerEventMonitor,
    private videoPlayerEventMonitor: VideoPlayerEventMonitor,
    private bypassMonitorService: BypassMonitorService,
  ) {
    super(
      tuneService,
      currentlyPlayingService,
      sessionTransferService,
      mediaTimeLineService,
    );
    this.playbackTypes = [ContentTypes.ADDITIONAL_CHANNELS];

    super.setSubscribers();

    this.playbackState.subscribe(state => {
      this.currentPlaybackState = state;
    });

    this.playbackComplete$ = this.videoPlayerEventMonitor.playbackComplete$;

    //TODO: Find and equivalent event for video player for when it finishes playback
    this.playbackComplete$.subscribe(isPlaybackComplete => {
      if (
        isPlaybackComplete &&
        this.mediaTimeLine.mediaType === ContentTypes.ADDITIONAL_CHANNELS
      ) {
        this.onFinished(this.currentTrack);
      }
    });

    /* //TODO: Find and equivalent event for video player
    this.audioPlayerEventMonitor.trackFailed$.subscribe(event => {
      this.onTrackFailed(event);
    });
    */

    this.bypassMonitorService.bypassErrorState.subscribe(
      (state: IAppByPassState) => {
        this.artistRadioByPassMode = state.ARTIST_RADIO_BYPASS;
        this.aicByPassMode = state.AIC_BYPASS;
      },
    );
  }

  public subscribeToPlayhead(): void {
    this.playhead$ = this.videoPlayerEventMonitor.playhead$;
    this.playheadSubscription = this.playhead$.subscribe(
      (playhead: IPlayhead) => {
        if (
          !this.currentTrack &&
          this.mediaTimeLine &&
          this.mediaTimeLine.clips
        ) {
          this.currentTrack = this.mediaTimeLine.clips.firstTrack();
        }

        playhead.id = this.currentTrack && this.currentTrack.assetGUID;
        this.playheadTimestampService.playhead.next(playhead);

        this.currentlyPlayingService.setCurrentPlayingData(
          playhead,
          VideoPlayerConstants.TYPE,
        );
      },
      err => {
        console.log(err);
      },
    );
  }

  /**
   * Gets the duration for the currently playing audio content in seconds.
   * @returns {number}
   */
  public getDurationInSeconds(): number {
    return msToSeconds(this.getDuration());
  }

  /**
   * Gets the duration for the currently playing audio content in seconds.
   * @returns {number}
   */
  public getDuration(): number {
    return this.currentlyPlayingService.getDuration();
  }

  /**
   * Used to stop Audio for given mediaId
   * @param {string} id
   */
  public stop(id?: string | number): any {
    id = id ? id : -1;

    MultiTrackVideoPlayerService.logger.debug(`stopping ${id}`);

    return new Promise((resolve, reject) => {
      try {
        this.videoPlayer.stop();
        resolve(stopSuccess.bind(this)());
      } catch (error) {
        reject(stopFault.bind(this)());
      }
    });

    /*
    return this.videoPlayer
      .stop(id)
      .then(stopSuccess.bind(this))
      .catch(stopFault.bind(this));
      */

    function stopSuccess() {
      MultiTrackVideoPlayerService.logger.debug(`stopping ${id} SUCCESS`);
      this.playbackStateSubject.next(VideoPlayerConstants.STOPPED);
    }

    function stopFault(fault) {
      MultiTrackVideoPlayerService.logger.error(`stopping ${id} FAILED`);
      throw fault;
    }
  }

  /**
   * Returns a unique id for the multitrack player.
   * Used by consume service.
   * @returns {string}
   */
  public getId(): string {
    return 'multi-track-a';
  }

  // TODO: CWC there is some duplicate code here with the AudioPlayer Service.  I am tyring to track the duplciation
  // so we can create a AudioPlayer class with the duplicate code that the Single Track and Multi Track audio players
  // then then inherit from
  public setVolume(volume: number): void {
    if (this.videoPlayer) {
      const value: number = volume / MediaPlayerConstants.MAX_VOLUME_VALUE;
      MultiTrackVideoPlayerService.logger.debug(`setVolume( ${value} )`);
      this.videoPlayer.setVolume(value);
    }
  }

  /**
   * Indicates if the player is playing for UI purposes.
   * Might have just finished a track. But you are still playing
   * So state is finished. to make consume happy.
   *
   * @returns {boolean}
   */
  public isPlaying(): boolean {
    return this.currentPlaybackState === VideoPlayerConstants.PLAYING;
  }

  /**
   * Indicates if the player is paused.
   * @returns {boolean}
   */
  public isPaused(): boolean {
    return this.currentPlaybackState === VideoPlayerConstants.PAUSED;
  }

  /**
   * Used to Pause or Resume based on playback state.
   */
  public togglePausePlay(): Observable<any> {
    switch (this.currentPlaybackState) {
      case VideoPlayerConstants.PLAYING:
        return this.pause();
      case VideoPlayerConstants.PAUSED:
        return this.resume();
      case VideoPlayerConstants.FINISHED:
        this.playbackStateSubject.next(VideoPlayerConstants.PLAYING);
        this.startAudio(this.mediaTimeLine);
        return of(true);
      //return this.play();
      case VideoPlayerConstants.IDLE: {
        return this.rewind();
      }
      default:
        return observableThrowError('error') as any;
    }
  }

  public play(): Observable<any> {
    this.videoPlayer.play();
    return of(true);
  }

  /**
   * loadAsset is the kick-off method for tuning to an aic channel.
   * @returns {Observable<string>}
   */
  public loadAsset(): Observable<any> {
    const sessionTransferObservable = this.sessionTransfer();
    sessionTransferObservable.subscribe(() => {
      if (!this.mediaTimeLine) {
        throw 'wrong media player';
      }

      if (!this.mediaTimeLine.clips) {
        throw 'I do not have a track list';
      }

      const currentTrack =
        this.currentTrack || this.mediaTimeLine.clips.firstTrack();

      const config: PlayerConfig = this.multiTrackAudioPlayerConfigFactory.getConfig(
        this.mediaTimeLine,
        currentTrack,
      );

      this.playbackStateSubject.next(VideoPlayerConstants.PLAY);
      this.hasWarmedUp = true;

      this.videoPlayerEventMonitor.playbackReady$
        .pipe(
          filter(isPlaybackReady => !!isPlaybackReady),
          take(1),
        )
        .subscribe(() => {
          this.playbackStateSubject.next(VideoPlayerConstants.PLAYING);
        });

      const asset: IStartVideo = {
        url: config.primaryAudioURL,
        autoPlay: false,
        startTime: 0,
        type: 'application/x-mpegURL',
      };
      this.videoPlayer.loadAsset(asset, playerConfig);

      this.videoPlayerEventMonitor.playerCreated$.subscribe(isPlayerCreated => {
        this.videoPlayer.play();
        playSuccess.bind(this)();
      });
      return of(true);
      /*
      return this.videoPlayer.play().subscribe({
        next: playSuccess.bind(this),
        error: playFault.bind(this),
      });
      */

      function playSuccess(result) {
        this.refreshTracksService.trackStarted(
          currentTrack.assetGUID,
          this.mediaTimeLine,
        );
        const nextTrack = currentTrack.nextTrack;

        if (nextTrack) {
          //this.preload(nextTrack);
          this.setNextTrack(nextTrack.previousTrack, nextTrack);
          //this.enableCrossfade(true);
          //this.play();
        }

        return result;
      }

      function playFault(fault) {
        this.handleFault(fault);
      }
    });
    return sessionTransferObservable;
  }

  public handleFault(fault: any): void {
    this.appMonitorService.triggerFaultError({
      faultCode: AppErrorCodes.FLTT_PLAYBACK_FAILURE,
    });
    throw fault;
  }

  /**
   * Used to preload the audio player.
   * @param track - track to preload
   */
  /*
  public preload(track: IClip): Observable<any> {
    const sessionTransferObservable = this.sessionTransfer();
    sessionTransferObservable.subscribe(() => {
      if (this.currentPlaybackState !== AudioPlayerConstants.PLAYING) {
        this.playbackStateSubject.next(AudioPlayerConstants.PRELOADING);
      }

      const config: PlayerConfig = this.multiTrackAudioPlayerConfigFactory.getConfig(
        this.mediaTimeLine,
        track,
      );

      const url = config.primaryAudioURL;

      return from(
        this.videoPlayer
          .preload(url, config)
          .then(preloadSuccess.bind(this))
          .catch(preloadFault.bind(this)),
      );

      function preloadSuccess(result: any) {
        this.enableCrossfade(true);
        track.status = 'PRELOAD';
        // If we are not in the playing state, set the playback state to paused.  If we are in the playing state,
        // then the user hit play while we were preloading, and the play request put us in the playing state, in
        // which case we do not want to change the state to paused because we are actually playing.
        if (this.currentPlaybackState !== AudioPlayerConstants.PLAYING) {
          this.playbackStateSubject.next(AudioPlayerConstants.PAUSED);
        }
        return result;
      }

      function preloadFault(fault) {
        this.handleFault(fault);
      }
    });
    return sessionTransferObservable;
  }
  */

  /**
   * Used to resume the audio player.
   */
  public resume(): Observable<any> {
    const sessionTransferObs = this.sessionTransfer();
    sessionTransferObs.subscribe(() => {
      this.hasWarmedUp = true;
      this.videoPlayer.resume();
      resumeSuccess.bind(this)();
      return of(true);

      /*
      const promise = this.videoPlayer
        .resume()
        .then(resumeSuccess.bind(this))
        .catch(resumeFault.bind(this));

      return from(promise);
      */

      return this.videoPlayer.resume().subscribe({
        next: resumeSuccess.bind(this),
        error: resumeFault.bind(this),
      });

      function resumeSuccess() {
        const multiTrackList = this.refreshTracksService.trackResumed(
          this.mediaTimeLine,
        );
        this.mediaTimeLine.clips = multiTrackList;

        this.playbackStateSubject.next(VideoPlayerConstants.PLAYING);
      }

      function resumeFault(fault) {
        throw fault;
      }
    });

    return sessionTransferObs;
  }

  /**
   * Used to set the next track on additional channels.
   * @param currentTrack - current track
   * @param nextTrack - next future track
   */
  public setNextTrack(currentTrack: IClip, nextTrack: IClip) {
    const sessionTransferObservable = this.sessionTransfer();
    sessionTransferObservable.subscribe(() => {
      this.settingNextTrack.next(true);
      this.currentTrack = currentTrack;
      this.nextTrack = nextTrack;
      success.bind(this)();
      /*
      return this.loadAsset().subscribe({
        next: success.bind(this),
        error: fault.bind(this),
      });
      */

      function success(result: any) {
        this.settingNextTrack.next(false);
        return result;
      }

      function fault(fault) {
        this.settingNextTrack.next(false);
        throw fault;
      }
    });
    return sessionTransferObservable;
  }

  /**
   * Used to seek the audio player. TODO: Vpaindla - do we need this for mutli track?
   * @param timestamp
   */
  public seek(/*timestamp: number*/): Observable<any> {
    this.playbackStateSubject.next(VideoPlayerConstants.PLAYING);
    return of(VideoPlayerConstants.PLAYING);
  }

  /**
   * Used Skips the track.
   * Get the next future track from the currently playing track.
   */
  public skip(): Observable<any> {
    const sessionTransferObs = this.sessionTransfer();
    sessionTransferObs.subscribe(() => {
      const elapsedTime = this.videoPlayerEventMonitor.playhead$.value
        .currentTime.seconds;
      const currentTrack = this.mediaTimeLine.clips.getCurrentTrack();
      const clipList = this.mediaTimeLine.clips;

      if (!currentTrack || !currentTrack.nextTrack) {
        if (
          clipList.isPristine() ||
          this.refreshTracksService.refreshingTracks$.getValue()
        ) {
          return of(false);
        } else {
          /*
          this.audioPlayerEventMonitor.handleTracksExhaustedEvent(
            this.mediaTimeLine.mediaType,
            this.aicByPassMode,
            this.artistRadioByPassMode,
          );
          */
          console.error('There are no tracks left to play', currentTrack);

          return of(false);
        }
      }

      const config: PlayerConfig = this.multiTrackAudioPlayerConfigFactory.getConfig(
        this.mediaTimeLine,
        currentTrack.nextTrack,
      );

      this.jumpActionCuts = {
        from: this.mediaTimeLine.cuts.find(
          cut => cut.assetGUID === currentTrack.assetGUID,
        ),
        to: this.mediaTimeLine.cuts.find(
          cut => cut.assetGUID === currentTrack.nextTrack.assetGUID,
        ),
      };

      const stateAtTimeOfSkip =
        this.currentPlaybackState === VideoPlayerConstants.PAUSED
          ? VideoPlayerConstants.PLAYING
          : this.currentPlaybackState;
      this.playbackStateSubject.next(VideoPlayerConstants.SEEKING);

      //this.stop();
      this.setNextTrack(currentTrack.nextTrack, currentTrack.nextTrack);

      return this.loadAsset().subscribe({
        next: skipSuccess.bind(this),
        error: skipFault.bind(this),
      });

      /*
      const promise = this.stop(currentTrack.assetGUID).finally(() => {
        return this.videoPlayer
          .skip(config.primaryAudioURL, config)
          .then(skipSuccess.bind(this))
          .catch(skipFault.bind(this));
      });
      

      const obs = from(promise);

      return this.enableCrossfade(false).pipe(concat(obs));
      */

      function skipSuccess() {
        this.playbackStateSubject.next(stateAtTimeOfSkip);

        const multiTrackList: MultiTrackList = this.refreshTracksService.trackSkipped(
          this.mediaTimeLine,
          elapsedTime,
        );

        /*
        const track = multiTrackList.nextForPreload();

        if (track) {
          //this.preload(track);
          this.setNextTrack(track.previousTrack, track);
        }
        */
      }

      function skipFault(fault) {
        throw fault;
      }
    });

    return sessionTransferObs;
  }

  /**
   * Used to rewind the track. Gets the currently playing track and seek to the beginning of the track
   */
  public rewind(): Observable<any> {
    const sessionTransferObs = this.sessionTransfer();
    sessionTransferObs.subscribe(() => {
      const currentTrack = this.mediaTimeLine.clips.getCurrentTrack();
      const config: PlayerConfig = this.multiTrackAudioPlayerConfigFactory.getConfig(
        this.mediaTimeLine,
        currentTrack,
      );

      const cut = this.mediaTimeLine.cuts.find(
        cut => cut.assetGUID === currentTrack.assetGUID,
      );
      this.jumpActionCuts = { from: cut, to: cut };

      const stateAtTimeOfRewind =
        this.currentPlaybackState === VideoPlayerConstants.IDLE
          ? VideoPlayerConstants.PLAYING
          : this.currentPlaybackState;
      this.playbackStateSubject.next(VideoPlayerConstants.SEEKING);

      this.videoPlayer.seek(0);
      rewindSuccess.bind(this)();
      return of(true);

      /*
      const promise = this.videoPlayer
        .seek(config.primaryAudioURL, config)
        .then(rewindSuccess.bind(this))
        .catch(rewindFault.bind(this));

      return from(promise);
      */

      function rewindSuccess() {
        this.refreshTracksService.trackStarted(
          currentTrack.assetGUID,
          this.mediaTimeLine,
        );
        this.playbackStateSubject.next(stateAtTimeOfRewind);

        // NOTE: Checking to see if next track is not preloaded . This can happen with chromecast.
        if (
          currentTrack.nextTrack &&
          currentTrack.nextTrack.status !== MultiTrackConstants.PRELOAD
        ) {
          const track = currentTrack.nextTrack;
          //this.preload(track);
          this.setNextTrack(track.previousTrack, track);
        }
      }

      function rewindFault(fault) {
        throw fault;
      }
    });

    return sessionTransferObs;
  }

  /**
   * Used to pause the audio player.
   */
  public pause(): Observable<any> {
    this.videoPlayer.pause();
    pauseSuccess.bind(this)();
    return of(true);

    function pauseSuccess() {
      this.playbackStateSubject.next(VideoPlayerConstants.PAUSED);
      return VideoPlayerConstants.PAUSED;
    }

    function pauseFault(fault) {
      this.playbackStateSubject.next(this.currentPlaybackState);
      throw fault;
    }
  }

  /**
   * Used to turn crossfade on and off.
   * @param bool {boolean} - turns crossfade on if true, off if false.
   */
  /*
  public enableCrossfade(bool: boolean): Observable<any> {
    if (
      this.SERVICE_CONFIG.deviceInfo.player ===
      MediaPlayerConstants.HTML5_PLAYER
    ) {
      const promise = this.videoPlayer.enableCrossfade(bool);

      return from(promise);
    }

    this.videoPlayer.enableCrossfade(false);

    return of(false);
  }
  */

  /**
   * Getter for the UNIX-based/Epoch playhead timestamp in milliseconds.
   * @returns {number}
   */
  public getPlayheadZuluTime(): number {
    return 0;
  }

  /**
   * Returns the playhead start zulu endtime
   */
  public getPlayheadStartZuluTime(): number {
    return 0;
  }

  /**
   * returns boolean whether or not the media type is live
   * NOT whether we are playing at the live point.
   */
  public isLive(): boolean {
    return false;
  }

  /**
   * This returns the type of playback that this media player uses.
   * @returns {string}
   */
  public getPlaybackType(): string {
    return AudioPlayerConstants.MY;
  }

  /**
   * Indicates the player volume
   * @returns {number}
   */
  public getVolume(): number {
    const volume = this.videoPlayer.getVolume();

    return MediaUtil.isVolumeValid(volume, 1)
      ? volume * MediaPlayerConstants.MAX_VOLUME_VALUE
      : NaN;
  }

  /**
   * Mutes the volume of the audio player.
   */
  public mute(): void {
    if (this.videoPlayer) {
      this.isMuted = true;
      this.mutedVolume = this.getVolume();
      this.setVolume(0);
    }
  }

  /**
   * Once tuneMediaTimeLine available, updates the Media end point urls and starts the Audio.
   * @param mediaTimeLine
   * @param mediaTrigger
   */
  protected onNewMedia(
    mediaTimeLine: MediaTimeLine,
    mediaTrigger: IMediaTrigger,
  ) {
    if (this.isCurrentPlayer(mediaTimeLine.mediaType)) {
      if (mediaTimeLine.playerType === PlayerTypes.REMOTE) {
        if (
          this.currentPlaybackState === VideoPlayerConstants.STOPPED ||
          this.currentPlaybackState === VideoPlayerConstants.IDLE
        ) {
          return;
        }
        this.chromecastService.autoplay$.next(this.isPlaying());
        //this.videoPlayer.pause();
        this.currentPlaybackState = VideoPlayerConstants.IDLE;
        this.playerTypeLocal = mediaTimeLine.playerType;
      } /*if (
        this.playerTypeLocal === PlayerTypes.REMOTE &&
      mediaTimeLine.playerType === PlayerTypes.LOCAL
      ) {
        this.playheadTimestampService.playhead
          .pipe(first())
          .subscribe(playhead => {
            this.audioPlayerEventMonitor.playhead$.next(playhead);
            this.playerTypeLocal = PlayerTypes.LOCAL;
          });
      } else */ else {
        if (this.currentPlaybackState === VideoPlayerConstants.IDLE) {
          return;
        }
        MultiTrackVideoPlayerService.logger.debug(
          `onMediaTimeLine( ID: ${mediaTimeLine.mediaId} )`,
        );

        const isMultitrackAudio: boolean = MediaUtil.isMultiTrackAudioMediaType(
          mediaTimeLine.mediaType,
        );
        const currentMediaType: string = this.mediaType;
        const startAudio: boolean = isMultitrackAudio;
        const stopAudio: boolean =
          !!currentMediaType && mediaTrigger.isNewMediaId;

        this.currentTrack = null;
        this.nextTrack = null;

        if (stopAudio) {
          this.subscribeToPlayhead();
          this.startAudio(mediaTimeLine);
          /*
          this.stop(-1).finally(() => {
            this.startAudio(mediaTimeLine);
          });
          */
        } else if (startAudio) {
          this.subscribeToPlayhead();
          this.startAudio(mediaTimeLine);
        } else {
          this.mediaTimeLine = null;
        }
      }
    } else {
      /*if (!MediaUtil.isAudioMediaType(this.mediaType)) {
        //this.stop(-1);
      }*/

      if (
        this.mediaType !== 'additionalchannels' &&
        this.playheadSubscription
      ) {
        this.playheadSubscription.unsubscribe();
      }
    }
  }

  /**
   * starts the audio playback
   * @param mediaTimeLine
   */
  private startAudio(mediaTimeLine: MediaTimeLine): void {
    this.initializationService.initState
      .pipe(
        // wait for app to be RUNNING
        filter(initState => initState === InitializationStatusCodes.RUNNING),
        mergeMap(() => this.sessionTransferService.sessionClaimed),
        // wait for app to own the user session
        filter(sessionClaimed => sessionClaimed === true),
        mergeMap(() => this.chromecastService.state$),
        // wait until Chromecast is NOT connected
        filter(
          castState => castState !== ChromecastPlayerConsts.STATE.CONNECTED,
        ),
        // wait until Chromecast is NOT connected
        mergeMap(() => this.playbackState),
        // wait until player is not trying to play or seek to something before playing something new
        // This prevents multiple audio playback issues (like reported in WEBEVEREST-3267)
        filter(
          state =>
            state !== MediaPlayerConstants.PRELOADING &&
            state !== MediaPlayerConstants.PLAY &&
            state !== MediaPlayerConstants.SEEKING,
        ),
        mergeMap(() => this.settingNextTrack),
        // wait until player is not trying to play or seek to something before playing something new
        // This prevents multiple audio playback issues (like reported in WEBEVEREST-3267)
        filter(settingNextTrack => settingNextTrack === false),
        take(1),
      )
      .subscribe(() => {
        if (mediaTimeLine.clips.isPristine()) {
          const firstTrack = mediaTimeLine.clips.firstTrack();

          //this.audioPlayerEventMonitor.firstAicGuid = firstTrack.assetGUID;
          this.firstAicGuid = firstTrack.assetGUID;

          const config = this.multiTrackAudioPlayerConfigFactory.getConfig(
            mediaTimeLine,
            firstTrack,
          );

          // Kickoff the Noop service to ensure
          // fresh CDN access tokens as soon as
          // the audio starts playback.
          this.noopService.start();

          // - if config.autoplay is true, then play();
          // - if no tracks are loaded. you might be recovering from an error.
          // because after an error we clean the state. blank slate.
          // therefore, if player is in the playing state...then play().
          if (
            config.autoPlay ||
            this.currentPlaybackState === MediaPlayerConstants.PLAYING
          ) {
            //this.play();
            this.loadAsset();
          } else {
            //this.preload(firstTrack);

            if (firstTrack.nextTrack) {
              //this.preload(firstTrack.nextTrack);
              this.setNextTrack(firstTrack, firstTrack.nextTrack);
            }
          }
        } else if (!mediaTimeLine.clips.isPreloading()) {
          const track = mediaTimeLine.clips.nextForPreload();
          if (track) {
            //this.preload(track);
            this.setNextTrack(track.previousTrack, track);
          }
        }
      });
  }

  /**
   * Used set the next item to preload after track playback finished
   * @param playhead
   */
  private onFinished(currentTrack: IClip) {
    const finishedTrack = this.mediaTimeLine.clips.findTrackByGuid(
      currentTrack.assetGUID,
    );
    if (!finishedTrack.nextTrack) {
      /*
      this.audioPlayerEventMonitor.handleTracksExhaustedEvent(
        this.mediaTimeLine.mediaType,
        this.aicByPassMode,
        this.artistRadioByPassMode,
      );
      */
      console.error('There are no more tracks to play', currentTrack);
      return;
    }
    const listAfterFinishedList = this.refreshTracksService.trackFinished(
      currentTrack.assetGUID,
      this.mediaTimeLine,
    );
    const trackForPreload = listAfterFinishedList.nextForPreload();
    if (trackForPreload) {
      //this.preload(trackForPreload);
      this.setNextTrack(trackForPreload.previousTrack, trackForPreload);
      this.loadAsset();
    }
  }

  private onTrackFailed(event) {
    this.refreshTracksService.trackFailed(event.id, this.mediaTimeLine);
  }

  public warmUp(): Observable<string> {
    if (this.hasWarmedUp) return of('warmed up');
    this.hasWarmedUp = true;
    this.videoPlayer.warmUp();
    return of('warmed up');
  }

  public initiateAutoPlay(): void {
    if (this.currentPlaybackState === VideoPlayerConstants.FINISHED) {
      this.playbackStateSubject.next(VideoPlayerConstants.PLAYING);
      this.startAudio(this.mediaTimeLine);
    }
  }

  public isFinished(): boolean {
    return this.currentPlaybackState === VideoPlayerConstants.FINISHED;
  }
}
