import { of as observableOf, Observable } from 'rxjs';
import { mergeMap, share } from 'rxjs/operators';
import * as _ from 'lodash';
import {
  IProviderDescriptor,
  addProvider,
  ServiceEndpointConstants,
  NAME_COLOR_CHANNEL_LOGO,
  PLATFORM_ANY,
  IMAGE_WIDTH,
  IMAGE_HEIGHT,
} from '../service';
import { HttpProvider } from '../http';
import { Logger } from '../logger';
import { ProfileConsts } from '../service/consts/api.request.consts';
import { IRecentlyPlayed } from '../recently-played/recently-played.interface';
import {
  IPausePointData,
  IProfileAvatar,
  IProfileResponse,
} from './profile.interface';
import { ModuleAreaRequest } from '../http/types/module.list.request';
import { IAlert } from '../alerts/alert.interface';
import { IChannel } from '../channellineup';

/**
 * @MODULE:     service-lib
 * @CREATED:    03/02/18
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * This delegate is responsible for making calls to get recently played , Favorites and pause points.
 */
export class ProfileDelegate {
  /**
   * Internal logger.
   * @type {Logger}
   */
  private static logger: Logger = Logger.getLogger('ProfileDelegate');

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(ProfileDelegate, ProfileDelegate, [HttpProvider]);
  })();

  /**
   * Constructor
   * @param  http - Used to make API calls
   */
  constructor(private http: HttpProvider) {}

  /**
   * Make API call to get recently played, favorites and pause points
   * @returns {Observable<any | any>} An observable that can be subscribed to to get the
   * results of the API call.
   */
  public getProfileData(): Observable<IProfileResponse> {
    const params = _.cloneDeep(ProfileConsts.PARAMS);

    return this.http
      .get(
        ServiceEndpointConstants.endpoints.PROFILE.V4_GET_PROFILE_GUP_COLLATION,
        null,
        { params },
      )
      .pipe(
        mergeMap(response => {
          return observableOf({
            favorites: this.normalizeFavorites(response),
            recentlyPlayed: this.normalizeRecentlyPlayed(response),
            pausePoints: this.normalizePausePoints(response),
            alerts: this.normalizeAlerts(response),
          });
        }),
        share(),
      );
  }

  /**
   * Make API call to update Profile Name and avatar
   * @returns {Observable<any | any>} An observable that can be subscribed to to get the
   * results of the API call.
   */
  public updateProfile(payload): Observable<any> {
    ProfileDelegate.logger.debug('updateProfile()');

    const request = {
      updateProfileRequest: payload,
    };
    const areaRequest = new ModuleAreaRequest(
      'Profiles',
      'updateProfileRequest',
      request,
    );

    return this.http.postModuleAreaRequest(
      ServiceEndpointConstants.endpoints.PROFILE.V3_UPDATE_PROFILE,
      areaRequest,
      null,
    );
  }

  /**
   * Make API call to get profile avatars
   * @returns {Observable<IProfileAvatar[]} An observable that can be subscribed to to get the
   * results of the API call.
   */
  public getProfileAvatars(): Observable<IProfileAvatar[]> {
    ProfileDelegate.logger.debug('getProfileAvatars()');

    return this.http
      .get(
        ServiceEndpointConstants.endpoints.PROFILE.V3_GET_PROFILE_AVATARS,
        null,
        null,
      )
      .pipe(
        mergeMap(response => {
          const avatars = _.get(response, 'avatarImages.images', []) as Array<
            any
          >;
          return observableOf(avatars);
        }),
        share(),
      );
  }

  /**
   *
   * @param response
   * @returns {any}
   */
  private normalizeFavorites(response: any) {
    if (Array.isArray(response)) {
      response = response.find(
        element =>
          element.moduleArea &&
          element.moduleArea === ProfileConsts.PROFILE_AREA,
      );
    }

    const favorites = _.get(
      response,
      'moduleDetails.favoritesList.favorites',
    ) as Array<any>;

    return favorites ? favorites : [];
  }

  /**
   * Normalizes the recently played data
   * @param response
   * @returns {Array<IRecentlyPlayed>}
   */
  private normalizeRecentlyPlayed(response: any): Array<IRecentlyPlayed> {
    const recentlyPlayedList = _.get(
      response,
      'recentlyPlayedData.recentlyPlayeds',
    ) as Array<any>;

    if (!recentlyPlayedList) {
      return [];
    }

    return recentlyPlayedList.map(recentlyPlayedItem => {
      recentlyPlayedItem.startDateTime = recentlyPlayedItem.startDateTime
        ? new Date(recentlyPlayedItem.startDateTime)
        : null;

      recentlyPlayedItem.startStreamDateTime = recentlyPlayedItem.startStreamDateTime
        ? new Date(recentlyPlayedItem.startStreamDateTime)
        : null;

      //recentlyPlayedItem.startStreamTime = recentlyPlayedItem.startStreamTime;

      recentlyPlayedItem.endDateTime = recentlyPlayedItem.endDateTime
        ? new Date(recentlyPlayedItem.endDateTime)
        : null;

      recentlyPlayedItem.endStreamDateTime = recentlyPlayedItem.endStreamDateTime
        ? new Date(recentlyPlayedItem.endStreamDateTime)
        : null;

      //recentlyPlayedItem.endStreamTime = recentlyPlayedItem.endStreamTime;

      recentlyPlayedItem.assetGuid = recentlyPlayedItem.assetGUID;

      return recentlyPlayedItem;
    });
  }

  /**
   * Normalizes the pause points
   * @param response
   * @returns {Array<IPausePointData>}
   */
  private normalizePausePoints(response: any): Array<IPausePointData> {
    const pausePointList = response.pausePointTypes || [];

    return pausePointList.map((pausePointItem: any) => {
      pausePointItem.startDateTime = pausePointItem.startDateTime
        ? new Date(pausePointItem.startDateTime)
        : null;
      pausePointItem.endDateTime = pausePointItem.endDateTime
        ? new Date(pausePointItem.endDateTime)
        : null;
      //pausePointItem.startStreamTime = pausePointItem.startStreamTime;
      //pausePointItem.endStreamTime = pausePointItem.endStreamTime;
      pausePointItem.assetGuid = pausePointItem.assetGUID;
      pausePointItem.percentConsumed = pausePointItem.aodPercentConsumed;

      delete pausePointItem.aodPercentConsumed;
      delete pausePointItem.assetGUID;

      return pausePointItem;
    });
  }

  /**
   * Normalizes the alerts.
   * @param response
   * @returns {IAlert[]}
   */
  private normalizeAlerts(response: any): IAlert[] {
    if (Array.isArray(response)) {
      response = response.find(
        element =>
          element.moduleArea &&
          element.moduleArea === ProfileConsts.PROFILE_AREA,
      );
    }

    const alerts = _.get(
      response,
      'moduleDetails.alertList.alerts',
      [],
    ) as Array<any>;

    return alerts.map(alertItem => {
      if (
        alertItem.consumedAlertList &&
        alertItem.consumedAlertList.length > 0
      ) {
        alertItem.consumedAlerts =
          alertItem.consumedAlertList[0].consumedAlerts;
      }
      alertItem.assetGuid = alertItem.assetGUID;
      delete alertItem.consumedAlerts;

      return alertItem;
    });
  }

  /**
   * Normalizes the seeded station list
   * @param response
   * @returns {Array<IPausePointData>}
   */
  private normalizeSeededStationList(response: any): Array<IChannel> {
    const seededStations =
      response.seededRadioData && response.seededRadioData.stations
        ? response.seededRadioData.stations
        : [];

    return seededStations.map((station: any) => {
      return {
        imageList: [
          {
            name: NAME_COLOR_CHANNEL_LOGO,
            platform: PLATFORM_ANY,
            url: station.channelImageUrl, //TODO replace relative url
            width: IMAGE_WIDTH,
            height: IMAGE_HEIGHT,
          },
        ],
        name: station.stationName,
        channelId: station.stationFactory,
        stationFactory: station.stationFactory,
      } as IChannel;
    });
  }
}
