/**
 * @MODULE:     service-lib
 * @CREATED:    10/02/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *  Defines event types emitting from the audio player.
 */
export class AudioPlayerEventTypes {
  /**
   * Constant for the player event representing the player's state as ready.
   * @const {String}
   */
  public static PLAYBACK_READY: string = 'playbackReady';

  /**
   * Constant for the player event representing the player's state as playback started.
   * @const {String}
   */
  public static PLAYHEAD_START: string = 'playheadStart';

  /**
   * Constant for the player event representing the player's constant heartbeat via the playhead.
   * @const {String}
   */
  public static PLAYHEAD: string = 'playhead';

  /**
   * Constant representing the preloading player event.
   * @const {String}
   */
  public static PRELOADING: string = 'preloading';

  /**
   * Constant representing the preloading complete player event.
   * @const {String}
   */
  public static PRELOADING_COMPLETE: string = 'preloadingComplete';

  /**
   * Constant representing the bit rate changee player event.
   * @const {String}
   */
  public static BIT_RATE_CHANGE: string = 'bitRateChange';

  /**
   * Constant for the player event dispatched when it's failing over.
   * @const {String}
   */
  public static FAILOVER: string = 'failover';

  /**
   * Constant for the player event dispatched when the token is invalid.
   * @const {String}
   */
  public static TOKEN_INVALID: string = 'tokenInvalid';

  /**
   * Constant for the player event dispatched when a key failed to load.
   * @const {String}
   */
  public static KEY_FAILED: string = 'keyFailed';

  /**
   * Constant for the player event dispatched when there are no tracks to play for My.
   * @const {String}
   */
  public static NO_TRACKS: string = 'noTracks';

  /**
   * Constant for the player event dispatched when there are no tracks to play for My.
   * @const {String}
   */
  public static TRACK_FAILED: string = 'trackFailed';

  /**
   * Constant for the player event dispatched when there's no connectivity.
   * @const {String}
   */
  public static CONNECTIVITY_FAILED: string = 'connectivityFailed';

  /**
   * Constant for the player event dispatched when there's a failure
   * in the decoder.
   * @const {String}
   */
  public static DECODER_FAILURE: string = 'decoderFailure';

  /**
   * Constant for the player event dispatched when it's restarted.
   * @const {String}
   */
  public static RESTART: string = 'restart';

  /**
   * Constant for the player event dispatched when audio buffer runs dry.
   * @const {String}
   */
  public static BUFFER_EMPTY: string = 'bufferEmpty';

  /**
   * Constant for the player event dispatched when audio buffer runs dry.
   * @const {String}
   */
  public static BEHIND_PLAYLIST: string = 'behindPlaylist';

  /**
   * Constant for the player event dispatched when it's finished.
   * @const {String}
   */
  public static FINISHED: string = 'finished';

  /**
   * Constant for the player event dispatched when it's finished.
   * @const {String}
   */

  public static CROSSFADE_POINT_REACHED: string = 'crossfadePointReached';

  /**
   * Constant for the player event dispatched when the player goes to suspended state due to
   * autoPlay is disabled by the browser (safari/chrome/firefox) mainly safari(autoPlay issues)
   * @const {String}
   */
  public static PLAYBACK_DENIED = 'NotAllowedError';
}
