import { DateUtilConst } from './index';
import moment from 'moment';
/**
 * @MODULE:     service-lib
 * @CREATED:    10/27/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * Static class containing common date utility methods.
 */
export class DateUtil {
  /**
   * Date object representing time from epoch or unix time.
   */
  public static JAN_1_1980: Date = new Date(1980, 0, 1);

  /**
   * Value representing time from epoch or unix time in milliseconds.
   */
  public static JAN_1_1980_IN_MS: number = DateUtil.JAN_1_1980.getTime();

  /**
   * Regex to find a string based ISO date.
   */
  public static ISO_TIME_SEARCH_VALUE: RegExp = /(.*)([+-]{1})([0-9]{2})([0-9]{2})/g;

  /**
   * Values to replace the ISO date.
   */
  public static ISO_TIME_REPLACE_VALUE: string = '$1$2$3:$4';

  public static MS_IN_SECOND = 1000;

  public static MS_IN_MINUTE = DateUtil.MS_IN_SECOND * 60;

  public static MS_IN_HOUR = DateUtil.MS_IN_MINUTE * 60;

  /**
   * Values representing the days of the week
   */
  public static DAYS_OF_THE_WEEK: string[] = [
    DateUtilConst.SUNDAY,
    DateUtilConst.MONDAY,
    DateUtilConst.TUESDAY,
    DateUtilConst.WEDNESDAY,
    DateUtilConst.THURSDAY,
    DateUtilConst.FRIDAY,
    DateUtilConst.SATURDAY,
  ];

  /**
   * Determines if a target timestamp in milliseconds is a zulu based value; basically the
   * target value needs to be a millisecond value greater than or equal the time from epoch value.
   * @param {number} value
   * @returns {boolean}
   */
  public static isZulu(value: number) {
    return value && value >= DateUtil.JAN_1_1980_IN_MS;
  }

  /**
   * Converts the ISO date string to time from epoch in milliseconds.
   * @param {string} date
   * @returns {number}
   */
  public static convertIsoToTimeFromEpoch(date: string): number {
    if (!date || date.length === 0) {
      return 0;
    }

    const replacedString = date.replace(
      DateUtil.ISO_TIME_SEARCH_VALUE,
      DateUtil.ISO_TIME_REPLACE_VALUE,
    );
    let dateToReturn = Date.parse(replacedString);
    return dateToReturn;
  }

  /**
   * Converts the string to Date.
   * @param {string} date
   * @returns {Date}
   */
  public static convertToDate(date: string): Date {
    // Safari browser not supporting the time getting from API. Below is the fix to handle it.
    const replacedString = date.replace(
      DateUtil.ISO_TIME_SEARCH_VALUE,
      DateUtil.ISO_TIME_REPLACE_VALUE,
    );
    return new Date(replacedString);
  }

  /**
   * Convert a date to ISO8601 string
   * Format is YYYY-MM-DDTHH:mm:ss.SSSZ
   * @param {Date} date
   * @returns {string}
   */
  public static convertDateToISO8601Format1(date: Date): string {
    const aMoment: any = moment.utc(date);
    return aMoment.toISOString();
  }

  /**
   * Convert a date to ISO8601 string
   * Format is YYYY-MM-DDTHH:mm:ss.SSSZZ
   * @param {Date} date
   * @returns {string}
   */
  public static convertDateToISO8601Format2(date: Date): string {
    const aMoment: any = moment.utc(date);
    return aMoment.format('YYYY-MM-DDTHH:mm:ss.SSSZZ');
  }

  /**
   * gets epoch date/time representation in UTC
   * @returns {Date} converted date from epoch
   */
  public static getEpochDateInUTC(): Date {
    return new Date(0);
  }

  /**
   * gets epoch date/time representation in UTC
   * @returns {Date} converted date from epoch
   */
  public static nowInISO8601(): string {
    return this.convertDateToISO8601Format1(new Date());
  }

  /**
   * parses the number as epoch date/time
   * @param {Date} epoch
   * @returns {Date} converted from epoch
   */
  public static parseEpochDate(epoch: number): Date {
    let date: Date = DateUtil.getEpochDateInUTC();
    date.setUTCSeconds(epoch);
    return date;
  }

  /**
   * gets the day of the week from a date
   * @param {Date} date : a date
   * @returns {string} day of the week : e.g. Monday, Tuesday...
   */
  public static getDayOfTheWeek(date: Date): string {
    return DateUtil.DAYS_OF_THE_WEEK[date.getDay()];
  }

  /**
   * determine if the given date is current day
   * @param {Date} date : a date
   * @returns {boolean} if date provided is current date
   */
  public static isCurrentDate(date: Date): boolean {
    return DateUtil.isSameDay(date, new Date());
  }

  /**
   * determine if the given date is tomorrow
   * @param {Date} date : a date
   * @return {boolean} if date provided is tomorrow
   */
  public static isTomorrow(date: Date): boolean {
    let dateTomorrow: Date = new Date();
    dateTomorrow.setDate(dateTomorrow.getDate() + 1);
    return DateUtil.isSameDay(date, dateTomorrow);
  }

  /**
   * determine if the given date was yesterday
   * @param {Date} date : a date
   * @return {boolean} if date provided was yesterday
   */
  public static isYesterday(date: Date): boolean {
    let dateYesterday: Date = new Date();
    dateYesterday.setDate(dateYesterday.getDate() - 1);
    return DateUtil.isSameDay(date, dateYesterday);
  }

  /**
   * determine if the given two dates are same days while ignoring the time
   * @param {Date} date1 : a date
   * @param {Date} date2 : a date
   * @return {boolean} if date provided was yesterday
   */
  public static isSameDay(date1: Date, date2: Date): boolean {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  /**
   * it takes an string with the format : [LOCAL_SHORT_DAY | LOCAL_SHORT_TIME | LOCAL_SHORT_DATE] 1519693200 duration
   * e.g. "{{LOCAL_SHORT_DATE:1519693200}}" · 43m, or
   * "{{LOCAL_SHORT_DAY:1522090800}} {{LOCAL_SHORT_TIME:1522090800}} - {{LOCAL_SHORT_TIME:1522101600}}" for time ranges
   * date/Time is in epoch or unix time (seconds)
   * @param  {string} item
   * @returns string with formatted date and time and duration(if provided as part of input).
   */
  public static localizeDateTime(item: string): string {
    if (!item || item.length === 0) {
      return item || '';
    }

    return item.replace(/\{\{[A-Z_]*:[\d\.]*\}\}/g, match => {
      let data = match.match(/\{\{(.*):(.*)\}\}(.*)?/);
      data.shift();
      return DateUtil.parseDateTime(data);
    });
  }

  /**
   * Takes an array that contains a timestamp and a description
   * @param {Array} data
   * @returns string with formatted date and time and duration
   */
  public static parseDateTime(data: string[]) {
    let parsedDate = DateUtil.parseEpochDate(+data[1]),
      description = data[0];

    switch (description) {
      case DateUtilConst.LOCAL_SHORT_DAY: {
        return DateUtil.getLocalShortDay(parsedDate);
      }
      case DateUtilConst.LOCAL_SHORT_TIME: {
        return moment(parsedDate).format('h:mm a');
      }
      case DateUtilConst.LOCAL_SHORT_DATE: {
        return DateUtil.getLocalShortDate(parsedDate);
      }
      default: {
        return '';
      }
    }
  }

  /**
   * Attempts to parse any item that could be interpreted as a date
   * using the moment.js engine.
   * @param unknown
   * @returns {Date}
   */
  public static parseUnknownFormatToDate(unknown: any): Date {
    const aMoment: any = moment(unknown);
    return aMoment.toDate();
  }

  /**
   * gets the localized day or [Today|Tomorrow|Yesterday] from Date object
   * @param {Date} date
   * @returns {string} localized day
   */
  public static getLocalShortDay(date: Date): string {
    let localShortDay: string;
    if (DateUtil.isCurrentDate(date)) {
      localShortDay = DateUtilConst.TODAY;
    } else if (DateUtil.isYesterday(date)) {
      localShortDay = DateUtilConst.YESTERDAY;
    } else if (DateUtil.isTomorrow(date)) {
      localShortDay = DateUtilConst.TOMORROW;
    } else {
      localShortDay = DateUtil.getDayOfTheWeek(date);
    }
    return localShortDay;
  }

  /**
   * gets the localized short date or Today or Day of the week if the date provided is past 6 days
   * @param {Date} date
   * @returns {string} short date
   */
  public static getLocalShortDate(date: Date): string {
    let localShortDate: string = '',
      dateToday: Date = new Date();
    if (DateUtil.isCurrentDate(date)) {
      localShortDate = DateUtilConst.TODAY;
    } else {
      let daysDiff: number = DateUtil.getDaysBetweenDates(date, dateToday);
      if (daysDiff < 0 && daysDiff >= -6) {
        localShortDate = DateUtil.getDayOfTheWeek(date);
      } else {
        localShortDate = moment(date).format('ll');
      }
    }
    return localShortDate;
  }

  /**
   * gets the number of days between two dates provided as input
   * @param {Date} date1
   * @param {Date} date2
   * @returns {number}
   */
  public static getDaysBetweenDates(date1: Date, date2: Date): number {
    let msInOneDay: number = 1000 * 60 * 60 * 24;
    let msInDate1: number = date1.getTime();
    let msInDate2: number = date2.getTime();
    let msDifference: number = msInDate1 - msInDate2;

    return +(msDifference / msInOneDay).toFixed(3);
  }
}
