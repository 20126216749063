export interface ILiveTime
{
    /**
     * The zulu-based (from Epoch) time in milliseconds.
     */
    zuluMilliseconds: number;

    /**
     * Careful! This is a value seconds but it is NOT zero-based.
     * The zulu-based (from Epoch) time in seconds.
     */
    zuluSeconds: number;
}

export class LiveTime implements ILiveTime
{
    /**
     * The zulu-based (time from Epoch) playhead time from the start of an episode in milliseconds.
     */
    zuluMilliseconds: number;

    /**
     * The zulu-based (time from Epoch) playhead time from the start of an episode in seconds.
     */
    zuluSeconds: number;

    /**
     * Constructor.
     */
    constructor()
    {
        this.zuluMilliseconds = 0;
        this.zuluSeconds = 0;
    }
}
