import { ContentTypes, ITileAssetInfo, CarouselTypeConst } from '../servicelib';

export class tileAssetUtil {
  public static getAssetGuid(
    contentType: string,
    subContentType: string,
    tileAssetInfo: ITileAssetInfo,
  ): string {
    switch (contentType) {
      case CarouselTypeConst.CHANNEL_TILE:
        return tileAssetInfo.channelGuid;
      case CarouselTypeConst.SHOW_TILE:
      case CarouselTypeConst.LIVE_SHOW_TILE:
        return tileAssetInfo.showGuid;
      case CarouselTypeConst.EPISODE_TILE:
        return subContentType === ContentTypes.AOD
          ? tileAssetInfo.aodEpisodecaId
          : tileAssetInfo.vodEpisodeGuid;
    }
  }

  /**
   * Returns true if the tile is a irisPodcast
   * @param {ITile} tile
   * @return {boolean}
   */
  public static isIrisPodcastTile(tileAssetInfo: ITileAssetInfo): boolean {
    return tileAssetInfo.isPandoraPodcast;
  }
}
