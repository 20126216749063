import * as _ from 'lodash';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter, share } from 'rxjs/operators';
import { IHttpRequestConfig, IHttpResponse } from './types/http.types';
import { IAppConfig } from '../config';
import { IProviderDescriptor, addProvider } from '../service';
import { ApiLayerTypes, CookiesNames } from '../service/consts';
import { ApiDelegate } from './api.delegate';
import { HttpDelegate, IHttpHeaderDelegate } from './http.delegate';
import { EventBus } from '../event/service';
import { HttpUtilities } from '../util';
import { Logger } from '../logger';
import { StorageService } from '../storage';
import { DateUtil } from '../util/date.util';

export interface ICdnAccessToken {
  akamai: string;
  limeLight: string;
}

export interface IHttpHeader {
  name: string;
  payload: any;
}

/**
 * @MODULE:     service-lib
 * @CREATED:    07/24/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * http.provider.response.interceptor provides a response interceptor that will parse a SiriusXM API response and
 * determine what actions need to be taken based on the received response
 */

/**
 * ResponseInterceptor class will use the Http and API delegates to handle API responses and return a normalized
 * response to clients
 */
export class ResponseInterceptor implements IHttpHeaderDelegate {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('ResponseInterceptor');

  /**
   * cdnAccessTokenData used to stored the akamain, limeLight CDN access tokens. Initially defaults values to be
   * empty.
   */
  private cdnAccessTokenData: ICdnAccessToken = {
    akamai: null,
    limeLight: null,
  };

  /**
   * subject for delivering CDN Access Tokens through the cdnAccessTokens observable.
   * @type {any}
   */
  private cdnAccessTokenSubject: BehaviorSubject<ICdnAccessToken> = null;

  /**
   * subject for delivering CDN Access Tokens through the cdnAccessTokens observable.
   * @type {any}
   */
  private httpHeaderSubject: BehaviorSubject<IHttpHeader> = null;

  /**
   * An observable (hot, subscribe returns wall clock date synced with API
   * @type {any}
   */
  public wallClock: Observable<number> = null;

  /**
   * subject for delivering wall clock time through the wallClock observable
   * @type {any}
   */
  private wallClockSubject: BehaviorSubject<number> = null;

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the cdnAccessTokenData
   * and be notified when the cdnAccessTokenData data changes.
   * @type {any}
   */
  public cdnAccessTokens: Observable<ICdnAccessToken> = null;

  /**
   * An observable (hot, subscribe returns most recent http header that was encountered by the
   * @type {any}
   */
  public httpHeaders: Observable<IHttpHeader> = null;

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(ResponseInterceptor, ResponseInterceptor, [
      ApiDelegate,
      StorageService,
      EventBus,
      'IAppConfig',
    ]);
  })();

  /**
   * @param apiDelegate provides methods for dealing with api responses
   * @param storageService allows storing/retrieving key/value pairs from local storage
   * @param eventBus to be used to broadcast events within the service layer
   * @param SERVICE_CONFIG is the service layer configuration that provides certain configuration parameters that
   * are needed when formulating API requests
   **/
  constructor(
    private apiDelegate: ApiDelegate,
    private storageService: StorageService,
    private eventBus: EventBus,
    private SERVICE_CONFIG: IAppConfig,
  ) {
    this.onResponse = this.onResponse.bind(this);
    this.cdnAccessTokenSubject = new BehaviorSubject(this.cdnAccessTokenData);
    this.cdnAccessTokens = this.cdnAccessTokenSubject.pipe(share());
    this.httpHeaderSubject = new BehaviorSubject(null);
    this.httpHeaders = this.httpHeaderSubject.pipe(share());
    this.wallClockSubject = new BehaviorSubject(null);
    this.wallClock = this.wallClockSubject.pipe(
      filter((wallClockZulu: number) => wallClockZulu !== null),
      share(),
    );
  }

  /**
   * Take an http response and check the http and API response codes.  Then get the actual response from the
   * api protocol and bass back to the caller
   * @param response is the HTTP response that was received from the API
   * @returns {any}
   */
  public onResponse(response: IHttpResponse) {
    // NOTE: Only uncomment for deep debugging as this is called quite a bit.
    // ResponseInterceptor.logger.debug(`onResponse( ${response.config.url} )`);

    HttpDelegate.checkHTTPResponse(response);
    HttpDelegate.checkHTTPHeaders(response, this);

    let config = (response.config as any) as IHttpRequestConfig;
    const isRaw = config ? config.isRaw === true : false;

    if (isRaw === false) {
      try {
        this.apiDelegate.checkApiResponse(response);
      } catch (error) {
        // If there are modules in the error, we still need to capture generic data from the error response
        if (error.modules) {
          this.getGenericModuleData(error.modules);
        }

        // Rethrow the error and let the caller decide to do with it.
        throw error;
      }

      const modules = ApiDelegate.getResponseData(response);
      this.getGenericModuleData(modules);
      this.setCdnAccessTokens();

      return modules;
    }

    return response;
  }

  /**
   * Handle a specific http header based on its name and contents.
   * @param headerName is the name of the header to handle
   * @param header is the data from the header to handler
   */
  public handleHttpHeader(headerName: string, header: any) {
    this.httpHeaderSubject.next({
      name: headerName,
      payload: header,
    } as IHttpHeader);
  }

  /**
   * Trigger the wall clock observable from a valid wallclock in an API response
   * @param {ZuluTimestamp} date
   */
  private updateWallClockFromApi(wallClockZulu: number) {
    if (wallClockZulu === null) {
      return;
    }

    this.wallClockSubject.next(wallClockZulu);
  }

  /**
   * Used to set CDN access token data. Values reads from cookies and if values are different with existing ones then
   * values gets updated and observable kick off for change.
   */
  private setCdnAccessTokens() {
    const akamaiToken = HttpUtilities.getCookieBody(CookiesNames.AKAMAI_TOKEN);
    const limeLightToken = HttpUtilities.getCookieBody(
      CookiesNames.LIME_LIGHT_TOKEN,
    );

    if (akamaiToken || limeLightToken) {
      let changed = false;

      if (akamaiToken !== '' && akamaiToken != this.cdnAccessTokenData.akamai) {
        ResponseInterceptor.logger.debug(`setCdnAccessTokens( Akamai )`);
        changed = true;
        this.cdnAccessTokenData.akamai = akamaiToken;
      }
      if (
        limeLightToken !== '' &&
        limeLightToken != this.cdnAccessTokenData.limeLight
      ) {
        ResponseInterceptor.logger.debug(`setCdnAccessTokens( Lime Light )`);
        changed = true;
        this.cdnAccessTokenData.limeLight = limeLightToken;
      }

      if (changed === true) {
        this.cdnAccessTokenSubject.next(this.cdnAccessTokenData);
      }
    }
  }

  /**
   * API responses often have generic data that needs to be kept up to date in the client.  This method will
   * examine the modules from an API response and take care of that, regardless of what the API call was and
   * what the response is.
   *
   * @param modules is either an array of modules or a modules object (we can get either or).
   */
  private getGenericModuleData(modules) {
    if (modules instanceof Array) {
      modules.forEach((module: any) => {
        getClientDeviceIdFromModule(
          module,
          this.storageService,
          this.SERVICE_CONFIG,
        );
        this.updateWallClockFromApi(getWallClockRenderTimeFromModule(module));
      });
    } else if (modules) {
      if (modules.clientConfiguration) {
        this.SERVICE_CONFIG.clientConfiguration = modules.clientConfiguration;
      }
      if (modules.getAllProfilesData) {
        this.SERVICE_CONFIG.allProfilesData = modules.getAllProfilesData;
      }

      getClientDeviceIdFromModule(
        modules,
        this.storageService,
        this.SERVICE_CONFIG,
      );
      this.updateWallClockFromApi(getWallClockRenderTimeFromModule(modules));
    }

    /**
     * There are API calls that return the clientConfiguration object, and may change the clientDeviceId that
     * is used to uniquely identify the client.  This deviceID is assigned to web client's by the API, so we need
     * to persist that deviceID to local storage *and* set it in the SERVICEP_CONFIG object so it will be used
     * where needed for all further API communication
     * @param module is the module object from an API response
     * @param storageService allows us to persist key/value pairs to local storage
     * @param SERVICE_CONFIG is the object used to configure the service layer
     */
    function getClientDeviceIdFromModule(
      module: any,
      storageService: StorageService,
      SERVICE_CONFIG: IAppConfig,
    ) {
      const clientDeviceId: string = _.get(
        module,
        'clientConfiguration.clientDeviceId',
        null,
      );

      if (clientDeviceId) {
        storageService.setItem(ApiLayerTypes.CLIENT_DEVICE_ID, clientDeviceId);

        SERVICE_CONFIG.deviceInfo.clientDeviceId = clientDeviceId;
      }
    }

    /**
     * Get the wallClockRenderTime property from the API response and return a Date object based on that time.
     * If the property does not exist return null
     *
     * @param module s the module object from an API response
     * @returns {ZuluTimestamp} date object initialized with wall clock from API or null if there is no API wall
     *     clock
     */
    function getWallClockRenderTimeFromModule(module: any): number {
      // Only update the wallClockTime from Discover PDT call, as there might be a problem with microservices
      // in the backend not having synced clocks, so we will stick with one mico-service (Discover PDT call)
      // that will keep our liveTime in check.
      // Tested this with both cases and found that dealing with single service gives us better result
      if (
        module &&
        module.wallClockRenderTime &&
        module.moduleArea === 'Discovery'
      ) {
        let crossBrowserValidTimeString = DateUtil.convertToDate(
          module.wallClockRenderTime,
        );
        return new Date(crossBrowserValidTimeString).getTime();
      }
      return null;
    }
  }
}
