export class DiscoverChannelListConsts {
  public static PARAMS = {
    type: 2,
    'batch-mode': true,
    format: 'json',
    'request-option': 'discover-channel-list-withpdt',
    'result-template': '', // http request interceptor will fill this in
    time: '', // http request interceptor will fill this in
  };
}

export class DiscoverAssetsConsts {
  public static DISCOVER_ONDEMAND_SHOW = 'show';

  public static PARAMS = {
    assetGUID: '',
    assetType: '',
    'result-template': 'web',
    time: '', // http request interceptor will fill this in
  };
}

export class DiscoverOnDemandConsts {
  public static DISCOVER_ONDEMAND_SUPERCATEGORY = 'category';
  public static DISCOVER_ONDEMAND_SUBCATEGORY = 'genre';

  public static V2_PARAMS = {
    type: 2,
    category: '',
    count: 10000,
    genre: '',
    page: 1,
    'request-option': 'discover-aod-shows',
    'result-template': '', // http request interceptor will fill this in
    time: '', // http request interceptor will fill this in
  };

  public static V4_PARAMS = {
    type: 2,
    category: '',
    count: 10000,
    genre: '',
    page: 1,
    'request-option': 'discover-od-shows',
    'result-template': '', // http request interceptor will fill this in
    time: '', // http request interceptor will fill this in
  };
}

export class NowPlayingConsts {
  public static LIVEPARAMS = {
    channelId: '',
    adsEligible: false,
    hls_output_mode: 'custom',
    fbSXMBroadcast: false,
    marker_mode: 'all_separate_cue_points', //"all_combined_cue_points"all_separate_cue_points
    ccRequestType: 'AUDIO_VIDEO',
    'result-template': '', // http request interceptor will fill this in,
    time: '',
    assetGuid: '',
    timestamp: null,
  };

  public static LIVEUPDATEPARAMS = {
    channelId: '',
    adsEligible: false,
    hls_output_mode: 'none',
    fbSXMBroadcast: false,
    marker_mode: 'all_separate_cue_points', //"all_combined_cue_points"all_separate_cue_points
    ccRequestType: 'AUDIO_VIDEO',
    'result-template': '', // http request interceptor will fill this in
    time: '',
  };

  public static LIVEUPDATEPARAMS_WITH_ADS = {
    channelId: '',
    adsEligible: false,
    hls_output_mode: 'none',
    fbSXMBroadcast: false,
    marker_mode: 'all_separate_cue_points',
    ccRequestType: 'AUDIO_VIDEO',
    'result-template': '',
    'with-ads': '', //streamingAdsId
    time: '',
  };

  public static IRISPODCASTPARAM = {
    episodeId: '',
    download: 'false',
  };

  public static AODPARAMS = {
    caId: '',
    channelId: '',
    hls_output_mode: 'custom',
    marker_mode: 'all_combined_cue_points', //"all_combined_cue_points"all_separate_cue_points
    ccRequestType: 'AUDIO_VIDEO',
    'result-template': '', // http request interceptor will fill this in
    time: '',
  };

  public static VODPARAMS = {
    assetGUID: '',
    showGUID: '',
    channelId: '',
    marker_mode: 'all_separate_cue_points',
  };

  public static ADDITIONALCHANNELSPARAMS = {
    channelGuid: '',
  };

  public static SEEDEDRADIOSPARAMS = {
    sourceId: '',
    type: 'REFRESH',
  };

  public static ADDITIONALCHANNELREFRESH = {
    type: 'REFRESH',
    channelGuid: '',
    sessionId: '',
    tracks: [],
  };
}

export class RecentlyPlayedConsts {
  public static PARAMS = {
    'gup-type': 'recent-plays',
    format: 'json',
    'result-template': 'web', // http request interceptor will fill this in
    time: '', // http request interceptor will fill this in
  };

  public static REMOVE_REQUEST_PAYLOAD = {
    assetGUID: '',
    recentPlayId: '',
    recentPlayType: '',
    incognito: 'true',
  };

  public static REMOVE_ALL_REQUEST_PAYLOAD = {
    incognito: 'true',
  };

  public static MODULE_AREA: string = 'Profile';
  public static DELETE_MODULE_TYPE: string = 'GUPCollation';
}

export class SearchConsts {
  public static MODULE_AREA: string = 'Search';
  public static MAX_SUGGESTED_RESULTS: number = 5;

  public static RECENT_SUBTYPE: string = 'History';
  public static AUTO_SUGGEST_SUBTYPE: string = 'AutoSuggest';
  public static SEARCH_RESULTS_SUBTYPE: string = 'Snippet';
}

export class ConsumeConsts {
  public static MODULE_AREA: string = 'NowPlayingTimelineFeed';
  public static MODULE_TYPE_LIVE: string = 'Live';
  public static MODULE_TYPE_VLIVE: string = 'LiveVideo';
  public static MODULE_TYPE_AOD: string = 'AOD';
  public static MODULE_TYPE_VOD: string = 'VOD';
  public static MODULE_TYPE_AIC: string = 'aic';
  public static MODULE_TYPE_SEEDED: string = 'seededRadio';
  public static MODULE_TYPE_PODCAST: string = 'podcast';
}

export class CarouselConsts {
  public static SEARCH_PARAM: string = 'search-term';
  public static PAGE_NAME: string = 'page-name';
  public static CHANNEL: string = 'channel';
  public static EPISODE: string = 'episodeGuid';
  public static HERO_CAROUSEL = 'hero';
  public static CONTENT_CAROUSEL = 'content';
  public static STANDARD_CAROUSEL = 'standard';
  public static RECENTLY_PLAYED_CAROUSEL = 'recently-played';
  public static VOD_EPISODE_GUID = 'vodEpisodeGuid';

  public static PARAMS = {
    'page-name': '',
    'result-template': 'everest|tenfoot',
  };

  public static WEB_PARAMS = {
    'page-name': '',
    'result-template': 'everest|web',
  };
}

export class ProfileConsts {
  public static PROFILE_AREA = 'Profile';

  public static PARAMS = {
    'gup-type': 'all-w-favorites',
    format: 'json',
    'result-template': '', // http request interceptor will fill this in
    time: '', // http request interceptor will fill this in
  };

  public static SEEDED_PARAMS = {
    'gup-type': 'seededStations',
    format: 'json',
    'result-template': '', // http request interceptor will fill this in
    time: '', // http request interceptor will fill this in
    gupId: '',
  };
}
