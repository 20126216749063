import { ContentTypes, MediaTimeLine } from '../index';
import { IMediaCut } from '../tune/tune.interface';
import { ApiLayerTypes } from '../service/consts/api.types';

export class MediaUtil {
  /**
   *  Determines if the requested media type is live audio.
   */
  public static isAudioMediaTypeLive(mediaType: string): boolean {
    mediaType = mediaType || '';
    const mt: string = mediaType.toLowerCase();
    return mt === ContentTypes.LIVE_AUDIO.toLowerCase();
  }

  /**
   *  Determines if the requested media type is on demand audio.
   */
  public static isAudioMediaTypeOnDemand(mediaType: string): boolean {
    mediaType = mediaType || '';
    const mt: string = mediaType.toLowerCase();
    return mt === ContentTypes.AOD.toLowerCase();
  }

  /**
   *  Determines if the requested media type is audio.
   */
  public static isAudioMediaType(mediaType: string): boolean {
    return (
      MediaUtil.isAudioMediaTypeLive(mediaType) ||
      MediaUtil.isAudioMediaTypeOnDemand(mediaType) ||
      MediaUtil.isMultiTrackAudioMediaType(mediaType) ||
      MediaUtil.isPodcastMediaType(mediaType)
    );
  }

  /**
   * Determines if the requested media type is podcast audio.
   */
  public static isPodcastMediaType(mediaType: string): boolean {
    mediaType = mediaType || '';
    const mt: string = mediaType.toLowerCase();
    return mt === ContentTypes.PODCAST.toLowerCase();
  }

  /**
   *  Determines if the requested media type is multi track audio.
   */
  public static isMultiTrackAudioMediaType(mediaType: string): boolean {
    mediaType = mediaType || '';
    const mt: string = mediaType.toLowerCase();
    return (
      mt === ContentTypes.ADDITIONAL_CHANNEL.toLowerCase() ||
      mt === ContentTypes.ADDITIONAL_CHANNELS.toLowerCase() ||
      mt === ContentTypes.SEEDED_RADIO.toLowerCase()
    );
  }

  /**
   *  Determines if the requested media type is seeded Radio.
   */
  public static isSeededRadioMediaType(mediaType: string): boolean {
    mediaType = mediaType || '';
    const mt: string = mediaType.toLowerCase();
    return mt === ContentTypes.SEEDED_RADIO.toLowerCase();
  }

  /**
   *  Determines if the requested media type is AIC.
   */
  public static isAICMediaType(mediaType: string): boolean {
    mediaType = mediaType || '';
    const mt: string = mediaType.toLowerCase();
    return (
      mt === ContentTypes.ADDITIONAL_CHANNEL.toLowerCase() ||
      mt === ContentTypes.ADDITIONAL_CHANNELS.toLowerCase()
    );
  }

  /**
   *  Determines if the requested media type is live video.
   */
  public static isVideoMediaTypeLive(mediaType: string): boolean {
    mediaType = mediaType || '';
    const mt: string = mediaType.toLowerCase();
    return mt === ContentTypes.LIVE_VIDEO.toLowerCase();
  }

  /**
   *  Determines if the requested media type is on demand audio.
   */
  public static isVideoMediaTypeOnDemand(mediaType: string): boolean {
    mediaType = mediaType || '';
    const mt: string = mediaType.toLowerCase();
    return mt === ContentTypes.VOD.toLowerCase();
  }

  /**
   *  Determines if the requested media type is video.
   */
  public static isVideoMediaType(mediaType: string): boolean {
    return (
      MediaUtil.isVideoMediaTypeLive(mediaType) ||
      MediaUtil.isVideoMediaTypeOnDemand(mediaType)
    );
  }

  /**
   *  Determines if the requested media type is on demand.
   */
  public static isOnDemandMediaType(mediaType: string): boolean {
    return (
      MediaUtil.isAudioMediaTypeOnDemand(mediaType) ||
      MediaUtil.isVideoMediaTypeOnDemand(mediaType) ||
      MediaUtil.isPodcastMediaType(mediaType)
    );
  }

  /**
   *  Determines if the requested media type is live.
   */
  public static isLiveMediaType(mediaType: string): boolean {
    return (
      MediaUtil.isAudioMediaTypeLive(mediaType) ||
      MediaUtil.isVideoMediaTypeLive(mediaType)
    );
  }

  /**
   * TODO: BMR: Move to VolumeService
   * Determines if the volume is valid or not
   * @param {number} volume - volume that to be validated
   * @param {number} max - max value
   * @returns {boolean} - returns true or value based on volume fits in limit.
   */
  public static isVolumeValid(volume: number, max: number = 1): boolean {
    return volume >= 0 && volume <= max;
  }

  public static isSongCutContentType(cut: IMediaCut): boolean {
    //if (!cut) return;
    if (!(cut && cut.contentType)) return;

    return (
      cut.contentType.toLowerCase() === ApiLayerTypes.CUT_CONTENT_TYPE_SONG
    );
  }

  public static isTalkCutContentType(cut: IMediaCut): boolean {
    //if (!cut) return;
    if (!(cut && cut.contentType)) return;

    return (
      cut.contentType.toLowerCase() === ApiLayerTypes.CUT_CONTENT_TYPE_TALK
    );
  }

  /**
   * Return Asset Id of the audio/video.
   * @param mediaTimeLine
   */
  public static getAssetId(mediaTimeLine: MediaTimeLine) {
    if (!mediaTimeLine) return '';

    const mediaType =
      mediaTimeLine && mediaTimeLine.mediaType ? mediaTimeLine.mediaType : '';
    let assetId = '';

    switch (mediaType) {
      case ContentTypes.LIVE_AUDIO:
      case ContentTypes.ADDITIONAL_CHANNELS:
        assetId = mediaTimeLine.channelId ? mediaTimeLine.channelId : '';
        break;

      case ContentTypes.SEEDED_RADIO:
        assetId = mediaTimeLine.mediaId ? mediaTimeLine.mediaId : '';
        break;

      case ContentTypes.AOD:
      case ContentTypes.PODCAST:
      case ContentTypes.VOD:
      default:
        assetId =
          mediaTimeLine.episodes &&
          mediaTimeLine.episodes.length > 0 &&
          mediaTimeLine.episodes[0]
            ? mediaTimeLine.episodes[0].assetGUID
            : '';
        break;
    }

    return assetId;
  }
}
