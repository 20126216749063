
export class SxmAnalyticsDecodeConstants
{
    /*
        Can decode the numeric values from the attribute name by looking up the
        attribute by name and using the array index.
    */
    public static ATTRIBUTE_DECODE_LIST = [
        { name: "", code: 0 },
        { name: "TagShortName", code: 1 },
        { name: "UserPath", code: 2 },
        { name: "Screen", code: 3 },
        { name: "PageFrame", code: 4 },
        { name: "CarouselName", code: 5 },
        { name: "Toast", code: 6 },
        { name: "Modal", code: 7 },
        { name: "Overlay", code: 8 },
        { name: "Shim", code: 9 },
        { name: "Edp", code: 10 },
        { name: "CarouselPosition", code: 11 },
        { name: "ElementType", code: 12 },
        { name: "ButtonName", code: 13 },
        { name: "LinkName", code: 14 },
        { name: "Text", code: 15 },
        { name: "ElementPosition", code: 16 },
        { name: "Action", code: 17 },
        { name: "ActionSource", code: 18 },
        { name: "InputType", code: 19 },
        { name: "ContentSource", code: 20 },
        { name: "TileType", code: 21 },
        { name: "AssetType", code: 22 },
        { name: "ContentType", code: 23 },
        { name: "StreamingType", code: 24 },
        { name: "ChannelGuid", code: 25 },
        { name: "ShowGuid", code: 26 },
        { name: "CategoryGuid", code: 27 },
        { name: "EpisodeGuid", code: 28 },
        { name: "AodEpisodeGuid", code: 29 },
        { name: "VodEpisodeGuid", code: 30 },
        { name: "LiveEventGuid", code: 31 },
        { name: "TeamGuid", code: 32 },
        { name: "LeagueGuid", code: 33 },
        { name: "PlayingStatus", code: 34 },
        { name: "NpTileType", code: 35 },
        { name: "NpAssetType", code: 36 },
        { name: "NpContentType", code: 37 },
        { name: "NpStreamingType", code: 38 },
        { name: "NpChannelGuid", code: 39 },
        { name: "NpShowGuid", code: 40 },
        { name: "NpCategoryGuid", code: 41 },
        { name: "NpEpisodeGuid", code: 42 },
        { name: "NpAodEpisodeGuid", code: 43 },
        { name: "NpVodEpisodeGuid", code: 44 },
        { name: "NpLiveEventGuid", code: 45 },
        { name: "NpTeamGuid", code: 46 },
        { name: "NpLeagueGuid", code: 47 },
        { name: "NpStatus", code: 48 },
        { name: "BannerInd", code: 49 },
        { name: "Orientation", code: 50 },
        { name: "ConsumedInd", code: 51 },
        { name: "GupId", code: 52 },
        { name: "MarketingId", code: 53 },
        { name: "DeviceID", code: 54 },
        { name: "Platform", code: 55 },
        { name: "Application", code: 56 },
        { name: "UiVersion", code: 57 },
        { name: "DeviceType", code: 58 },
        { name: "DeviceOS", code: 59 },
        { name: "AppCountry", code: 60 },
        { name: "Language", code: 61 },
        { name: "ChannelLineupID", code: 62 },
        { name: "SubscriptionLevel", code: 63 },
        { name: "UserProfile", code: 64 },
        { name: "SessionId", code: 65 },
        { name: "HitId", code: 66 },
        { name: "ActionTime", code: 67 },
        { name: "SchemaVersion", code: 68 },
        { name: "SchemaClass", code: 69 },
        { name: "CclVersion", code: 70 },
        { name: "SeekTo", code: 71 },
        { name: "SeekFrom", code: 72 },
        { name: "CurrentPosition", code: 73 },
        { name: "FindingMethod", code: 74 },
        { name: "SearchTerm", code: 75 },
        { name: "NumSearchResults", code: 76 },
        { name: "DirectTuneEntry", code: 77 },
        { name: "NewFavoriteIndex", code: 78 },
        { name: "ErrorNumber", code: 79 },
        { name: "LeadKeyId", code: 80 },
        { name: "MessageType", code: 81 },
        { name: "MetricEventCode", code: 82 },
        { name: "PushKeyId", code: 83 },
        { name: "MessageExpDate", code: 84 },
        { name: "PerfVal1", code: 85 },
        { name: "PerfVal2", code: 86 },
        { name: "PerfVal3", code: 87 },
        { name: "PerfVal4", code: 88 },
        { name: "PerfVal5", code: 89 },
        { name: "PerfVal6", code: 90 },
        { name: "NullSearch", code: 91 },
        { name: "DownloadCount", code: 92 },
        { name: "ShareCount", code: 93 },
        { name: "BuyCount", code: 94 },
        { name: "Coachmark", code: 95 },
        { name: "ZoneName", code: 96 },
        { name: "ZonePosition", code: 97 },
        { name: "CollectionGuid", code: 98 },
        { name: "NpCollectionGuid", code: 99 },
        { name: "MetricEventCode", code: 100 },
        { name: "PageName", code: 101 },
        { nsme: "UserDaysListened", code: 102}
    ];
}
