import { of as observableOf,  Observable } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import {
    addProvider,
    Logger,
    IProviderDescriptor,
    AffinityType,
    ApiCodes,
    PlayerTypes,
    AffinityConstMapping
} from "../index";
import { AffinityConstants } from "./affinity.consts";
import { AffinityDelegate }     from "./affinity.delegate";
import { MediaTimeLineService } from "../media-timeline/media.timeline.service";
import * as _ from "lodash";
import { IApiMessage } from "../http/types/module.list.response";
import { ChromecastService } from "../chromecast/chromecast.service";

/**
 * @MODULE:     service-lib
 * @CREATED:    01/17/19
 * @COPYRIGHT:  2019 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * This service is responsible for making calls to update the thumbs up/down feedback for the currently playing track.
 */
export class AffinityService
{
    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("AffinityService");

    private static providerDescriptor : IProviderDescriptor = function()
    {
        return addProvider(
            AffinityService,
            AffinityService,
            [AffinityDelegate, MediaTimeLineService, ChromecastService]
        );
    }();

    constructor(private affinityDelegate: AffinityDelegate,
                private mediaTimeLineService : MediaTimeLineService,
                private chromecastService: ChromecastService) {}

    /**
     * allows to make API call with `LIKE` affinity, for current playing track
     *
     * @returns Observable{any} response from API
     */
    public thumbsUpCurrentPlayingTrack() : Observable<any>
    {
        return this.updateAffinity(AffinityConstants.LIKE as AffinityType);
    }
    /**
     * allows to make API call with `DISLIKE` affinity, for current playing track
     *
     * @returns Observable{any} response from API
     */
    public thumbsDownCurrentPlayingTrack() : Observable<any>
    {
        return this.updateAffinity(AffinityConstants.DISLIKE as AffinityType);
    }
    /**
     * allows to make API call with `NEUTRAL` affinity, for current playing track
     *
     * @returns Observable{any} response from API
     */
    public neutralCurrentPlayingTrack() : Observable<any>
    {
        return this.updateAffinity(AffinityConstants.NEUTRAL as AffinityType);
    }
    /**
     * makes API call with affinity
     *
     * @param playerEventType affinities [LIKE | DISLIKE | NEUTRAL]
     * @returns Observable{any} response from API
     */
    public updateAffinity(playerEventType: AffinityType, elapsedTime? : number) : Observable<any>
    {
        let currentTrack = this.mediaTimeLineService.getCurrentPlayingTrack();
        if(!currentTrack || !currentTrack.assetGUID)
        {
            AffinityService.logger.error(`not found current or preloaded track `);
            return observableOf(false);
        }
        let requestPayload = {
            stationFactory : this.mediaTimeLineService.getCurrentPlayingStationFactory(),
            stationId : this.mediaTimeLineService.getCurrentPlayingStationId(),
            tracks : [{
                index : _.get(currentTrack, "index", 0),
                playerEventType : playerEventType,
                trackToken : _.get(currentTrack, "assetGUID", ""),
                elapsedTime : elapsedTime || 0
            }]
        };

        return this.affinityDelegate.updateAffinity(requestPayload).pipe(
                   mergeMap(onSuccess.bind(this)),
                   catchError(onFault.bind(this))) as Observable<boolean>;

        function onSuccess(response: any): Observable<boolean>
        {
            let returnValue = false;
            if (response)
            {
                AffinityService.logger.debug(`onSuccess()`);
                const path: string = "ModuleListResponse.messages";
                const parsedResponse = _.get(response, path, []) as Array<IApiMessage>;
                returnValue = parsedResponse.some(message => message.code === ApiCodes.API_SUCCESS_CODE);
                this.mediaTimeLineService.updateTrackAffinity(playerEventType, currentTrack.assetGUID);

                if (this.mediaTimeLineService.getPlayerType() === PlayerTypes.REMOTE)
                {
                    this.chromecastService.updateAffinity(AffinityService.getAffinityMappingValue(playerEventType));
                }
            }
            return observableOf(returnValue);
        }

        function onFault(error: any): Observable<boolean>
        {
            AffinityService.logger.error(`onFault - Error (${JSON.stringify(error)})`);
            return observableOf(false);
        }
    }

    /**
     * Converts AffinityType to 0, -1 and 1
     * @param playerEventType
     */
    public static getAffinityMappingValue (playerEventType: AffinityType): number
    {
        switch (playerEventType)
        {
            case AffinityConstants.NEUTRAL:
                return AffinityConstMapping.NEUTRAL;
            case AffinityConstants.LIKE:
                return AffinityConstMapping.LIKE;
            case AffinityConstants.DISLIKE:
                return AffinityConstMapping.DISLIKE;
            default:
                return AffinityConstMapping.NEUTRAL;
        }
    }
}
