import {
  combineLatest as observableCombineLatest,
  BehaviorSubject,
  Observable,
  SubscriptionLike as ISubscription,
} from 'rxjs';
import { filter, distinctUntilChanged } from 'rxjs/operators';
import { addProvider, IProviderDescriptor } from '../service';
import { MediaPlayerService } from './media-player.service';
import { ILiveTime, LiveTimeService } from '../livetime';
import { CurrentlyPlayingService } from '../currently-playing';
import { PlayheadTimestampService } from './playhead-timestamp.service';
import { IPlayhead } from './playhead.interface';
import { ICurrentlyPlayingMedia, IMediaCut, IMediaSegment } from '../tune';
import { MediaUtil } from './media.util';
import { msToSeconds } from '../util';

export class MediaTimestampService {
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(MediaTimestampService, MediaTimestampService, [
      LiveTimeService,
      MediaPlayerService,
      CurrentlyPlayingService,
      PlayheadTimestampService,
    ]);
  })();

  public livePointTimestamp: number = 0;
  public livePointTimestampZulu: number = 0;
  public durationTimestamp: number = 0;
  public playheadTimestamp: number = 0; // zero-based seconds.
  public playhead: IPlayhead = { currentTime: {} } as IPlayhead;
  public isPlayheadBehindLive: boolean = false;
  public segmentMarkers: IMediaSegment[] = [];
  public cutMarkers: IMediaCut[] = [];
  public isDraggingScrubBall: boolean = false;
  private currentlyPlayingData: ICurrentlyPlayingMedia;

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the current live timestamp in seconds.
   */
  public liveTime$: BehaviorSubject<number>;

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the current playhead timestamp in seconds.
   */
  public playheadTimestamp$: BehaviorSubject<number>;

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the current duration timestamp in seconds.
   */
  public durationTimestamp$: BehaviorSubject<number>;

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the current duration timestamp in seconds.
   */
  public isPlayheadBehindLive$: BehaviorSubject<boolean>;

  /**
   * The media type stream.
   */
  private mediaTypeSource: BehaviorSubject<string> = new BehaviorSubject<
    string
  >(null);
  public readonly mediaType$: Observable<
    string
  > = this.mediaTypeSource.asObservable();

  /**
   * The current playing episode zulu start time
   */
  private currentEpisodeZuluStartTimeSource: BehaviorSubject<
    number
  > = new BehaviorSubject<number>(0);
  public readonly currentEpisodeZuluStartTime$: Observable<
    number
  > = this.currentEpisodeZuluStartTimeSource.asObservable();

  /**
   * Constructor
   * @param {LiveTimeService} liveTimeService
   * @param {MediaPlayerService} mediaPlayerService
   * @param {PlayheadTimestampService} playheadTimestampService
   */
  constructor(
    private liveTimeService: LiveTimeService,
    private mediaPlayerService: MediaPlayerService,
    private currentlyPlayingService: CurrentlyPlayingService,
    private playheadTimestampService: PlayheadTimestampService,
  ) {
    // Create streams to observe.
    this.liveTime$ = new BehaviorSubject(this.livePointTimestamp);
    this.playheadTimestamp$ = new BehaviorSubject(this.playheadTimestamp);
    this.durationTimestamp$ = new BehaviorSubject(this.durationTimestamp);
    this.isPlayheadBehindLive$ = new BehaviorSubject(this.isPlayheadBehindLive);

    this.updateCanGotoLive();
    this.observeCurrentlyPlaying();
    this.observeLiveTime();
    this.observePlayheadTime();
  }

  public getDurationTimestamp(): number {
    if (this.mediaPlayerService.mediaPlayer === null) {
      return 0;
    }

    this.durationTimestamp =
      this.mediaPlayerService.mediaPlayer.getDurationInSeconds() || 0;
    this.durationTimestamp$.next(this.durationTimestamp);
    return this.durationTimestamp;
  }

  public resetDurationTimestamp(): number {
    this.durationTimestamp$.next(0);
    return (this.durationTimestamp = 0);
  }

  public resetLiveTimestamp(): number {
    this.liveTime$.next(0);
    return (this.livePointTimestamp = 0);
  }

  public resetPlayheadTimestamp(): number {
    this.playheadTimestamp$.next(0);
    return (this.playheadTimestamp = 0);
  }

  public resetSegmentMarkers() {
    return (this.segmentMarkers = []);
  }

  public resetCutMarkers() {
    return (this.cutMarkers = []);
  }

  public resetTimestamps(): void {
    this.resetDurationTimestamp();
    this.resetLiveTimestamp();
    this.resetPlayheadTimestamp();
    this.resetSegmentMarkers();
    this.resetCutMarkers();
  }

  public observePlayheadTime() {
    this.playheadTimestampService.playhead.subscribe(
      this.tickPlayheadObservable.bind(this),
    );
  }

  public tickPlayheadObservable(playhead: IPlayhead) {
    if (playhead) {
      if (this.isDraggingScrubBall) return; // while dragging you don't want playheads
      // from media players updating.

      if (!this.playhead.isBackground) {
        // one example of background playheads
        // is tracks that are fading out.
        this.playhead = playhead;
        this.setPlayheadTimestamp(playhead.currentTime.seconds);
      }
    }
  }

  public setPlayheadTimestamp(timestamp) {
    this.playheadTimestamp$.next(timestamp);
    this.playheadTimestamp = timestamp;
  }

  /**
   * Sets the cuts state.
   * Filters out cuts with negative durations or those that are not songs.
   */
  private setCuts(data: ICurrentlyPlayingMedia): void {
    if (!data || !data.episode || !data.episode.cuts) return;

    const cuts = data.episode.cuts;

    this.cutMarkers = cuts.filter((cut: IMediaCut) => {
      return (
        MediaUtil.isSongCutContentType(cut) ||
        MediaUtil.isTalkCutContentType(cut)
      );
    });
  }

  /**
   * Sets the segments state.
   * Filters segments with negative durations.
   * Builds zero start times using durations.
   */
  private setSegments(data: ICurrentlyPlayingMedia): void {
    if (!data || !data.episode || !data.episode.segments) return;

    this.segmentMarkers = data.episode.segments;
  }

  public observeLiveTime(): void {
    observableCombineLatest(
      this.currentlyPlayingService.currentlyPlayingData,
      this.liveTimeService.liveTime$,
      this.currentEpisodeZuluStartTime$,
      (
        currentlyPlaying: ICurrentlyPlayingMedia,
        liveTime: ILiveTime,
        currentEpisodeZuluStartTime: number,
      ) => {
        const durationTimestamp = this.getDurationTimestamp();
        let zeroBasedLiveSec: number = 0;

        if (
          !!currentlyPlaying &&
          MediaUtil.isLiveMediaType(currentlyPlaying.mediaType)
        ) {
          const zeroBasedLiveMs =
            liveTime.zuluMilliseconds - currentEpisodeZuluStartTime;
          zeroBasedLiveSec = msToSeconds(zeroBasedLiveMs);
        } else {
          zeroBasedLiveSec = durationTimestamp;
        }

        this.livePointTimestamp = zeroBasedLiveSec;
        this.liveTime$.next(this.livePointTimestamp);
        this.livePointTimestampZulu = liveTime.zuluMilliseconds;
        return this.livePointTimestamp;
      },
    ).subscribe();
  }

  private observeCurrentlyPlaying(): ISubscription {
    return this.currentlyPlayingService.currentlyPlayingData.subscribe(
      (data: ICurrentlyPlayingMedia) => {
        this.currentlyPlayingData = data;
        if (!!data && this.mediaPlayerService.isNewMedia(data)) {
          this.resetTimestamps();
        }
        this.setSegments(data);
        this.setCuts(data);
        this.currentEpisodeZuluStartTimeSource.next(
          !!data &&
            data.episode &&
            data.episode.times &&
            data.episode.times.zuluStartTime
            ? data.episode.times.zuluStartTime
            : 0,
        );
        this.mediaTypeSource.next(!!data ? data.mediaType : null);
      },
    );
  }

  /**
   * Determines if the playhead timestamp is behind the live time.
   *
   * We'll use 11 seconds since 10 seconds is about the duration of one audio chunk.
   */
  private updateCanGotoLive(): void {
    observableCombineLatest([
      this.liveTime$,
      this.playheadTimestamp$,
      this.mediaType$,
    ])
      .pipe(
        filter(thoseWithAnyFalsy),
        distinctUntilChanged(excludePlayheadEmit),
      )
      .subscribe(update.bind(this));

    function thoseWithAnyFalsy(result: any[]): boolean {
      return !!(result[0] && result[1] && result[2]);
    }

    function excludePlayheadEmit(p, q): boolean {
      return (
        p[1] !== q[1] && p[0] === q[0] // exclude value where playhead changed
      ); // but live time did not change.
    }

    function update(data: any[]): void {
      const mediaType: string = data[2] as string;
      const isLiveMediaType: boolean = MediaUtil.isLiveMediaType(mediaType);
      let isBehindLive: boolean = true;

      if (isLiveMediaType) {
        const liveTime: number = data[0] as number;
        const playhead: number = data[1] as number;
        isBehindLive = liveTime - playhead > 11;
      }

      this.isPlayheadBehindLive = isBehindLive;
      this.isPlayheadBehindLive$.next(this.isPlayheadBehindLive);
    }
  }

  /**
   * Returns an list of IMediaSegments
   */
  public getSegmentMarkers(): IMediaSegment[] {
    return this.segmentMarkers;
  }

  /**
   * Returns a list of IMediaCuts
   */
  public getCutMarkers(): IMediaCut[] {
    return this.cutMarkers;
  }
}
