export class DateUtilConst
{
    public static LOCAL_SHORT_DAY                = "LOCAL_SHORT_DAY";
    public static LOCAL_SHORT_TIME               = "LOCAL_SHORT_TIME";
    public static LOCAL_SHORT_DATE               = "LOCAL_SHORT_DATE";
    public static TODAY                          = "Today";
    public static YESTERDAY                      = "Yesterday";
    public static TOMORROW                       = "Tomorrow";
    public static SUNDAY                         = "Sunday";
    public static MONDAY                         = "Monday";
    public static TUESDAY                        = "Tuesday";
    public static WEDNESDAY                      = "Wednesday";
    public static THURSDAY                       = "Thursday";
    public static FRIDAY                         = "Friday";
    public static SATURDAY                       = "Saturday";

    public static DURATION                       = "duration";
    public static MINUTES                        = "minutes";
    public static HOURS                          = "hours";
}
