import { INeriticLinkData }      from "../carousel";
import { neriticActionConstants} from "../service/consts/neritic-action-const";

const TYPE_INDEX: number               = 0;
const ACTION_INDEX: number             = 1;
const CONTENT_INDEX: number            = 2;
const EPISODES_ASSET_INDEX: number     = 2;
const CHANNEL_ID_ARCHIVE_INDEX: number = 2;
const URL_INDEX: number                = 3;
const CHANNEL_INDEX: number            = 3;
const ASSET_INDEX: number              = 4;

const urlRegex: RegExp = /(.+\.com|.+\.net|.+\.ca|.+\.en|.+\.us)/;

export function parseNeriticLink(linkText: string): INeriticLinkData
{
    const links: Array<string>        = linkText.split(":");

    let timestamp: number,
        timestampSplit: Array<string> = linkText.split("::"),
        timestampSection: string;

    if (timestampSplit.length > 1)
    {
        timestampSection = timestampSplit[1];
        timestamp = parseInt(timestampSection);
    }

    if (links.length < 2) { return null; }

    /**
     * @todo the Post MVP types MAY not start with "App" or "Api"
     * may need to update this for those specific ones (more case statements)
     * i.e.
     *      Archive:{channelGuid}
     *      ArchiveViewEpisodes:{showGuid}
     *      ViewAODEpisodes:{showGuid}
     *      ViewVODEpisodes:{showGuid}
     */
    switch (links[TYPE_INDEX])
    {
        case neriticActionConstants.API:
            return parseApiNeriticLink(links, (timestamp >= 0) ? timestamp : undefined);
        case neriticActionConstants.APP:
            return parseAppNeriticLink(links);
        default:
            return null;
    }
}

function parseApiNeriticLink(links : Array<string>, timestamp? : number) : INeriticLinkData
{
    const neriticLinkData = {} as INeriticLinkData;

    neriticLinkData.linkType = neriticActionConstants.API;
    neriticLinkData.actionType = links[ACTION_INDEX];
    neriticLinkData.contentType = links[CONTENT_INDEX];
    neriticLinkData.channelId = decodeURIComponent(links[CHANNEL_INDEX]);
    neriticLinkData.assetGuid = decodeURIComponent(links[ASSET_INDEX]);
    neriticLinkData.startTime = (timestamp) ? timestamp : 0;

    return neriticLinkData;
}

function parseAppNeriticLink(links : Array<string>) : INeriticLinkData
{
    const neriticLinkData: INeriticLinkData = {};
    let url: string;

    /**
     * check for the odd Neritic Action Types (Post MVP)
     *
     * App:Archive:{channelGuid}
     * App:ArchiveViewEpisodes:{showGuid}
     * App:ViewAODEpisodes:{showGuid}
     * App:ViewVODEpisodes:{showGuid}
     */
    switch (links[ACTION_INDEX]) {
        case neriticActionConstants.ARCHIVE_VIEW_EPISODES:
        case neriticActionConstants.VIEW_AOD_EPISODES:
        case neriticActionConstants.VIEW_VOD_EPISODES:
            return parseOnDemandShowNeriticLink(links);
        case neriticActionConstants.ARCHIVE:
            return parseOnDemandChannelNeriticLink(links);
        default:
            break;
    }

    neriticLinkData.linkType = neriticActionConstants.APP;
    neriticLinkData.actionType = links[ACTION_INDEX];
    neriticLinkData.contentType = links[CONTENT_INDEX];

    let hasURL = links.filter((item) =>
    {
        return urlRegex.test(item);
    });

    if (hasURL.length > 0)
    {
        url = links[URL_INDEX];
        neriticLinkData.url = `${neriticLinkData.contentType}:${url}`;
    }

    return neriticLinkData;
}

function parseOnDemandShowNeriticLink(links: Array<string>): INeriticLinkData
{
    const neriticLinkData: INeriticLinkData = {} as INeriticLinkData;

    neriticLinkData.linkType = neriticActionConstants.APP;
    neriticLinkData.contentType = neriticActionConstants.SHOW;
    neriticLinkData.actionType = links[ACTION_INDEX];
    neriticLinkData.assetGuid = links[EPISODES_ASSET_INDEX];

    return neriticLinkData;

}

function parseOnDemandChannelNeriticLink(links: Array<string>): INeriticLinkData
{
    const neriticLinkData: INeriticLinkData = {} as INeriticLinkData;

    neriticLinkData.linkType = neriticActionConstants.APP;
    neriticLinkData.contentType = neriticActionConstants.CHANNEL;
    neriticLinkData.actionType = links[ACTION_INDEX];
    neriticLinkData.assetGuid = links[CHANNEL_ID_ARCHIVE_INDEX];

    return neriticLinkData;

}
