import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import {
  IProviderDescriptor,
  addProvider,
  RecentlyPlayedConsts,
  HttpProvider,
  Logger,
  ServiceEndpointConstants,
} from '../index';
import {
  ModuleArea,
  ModuleAreaRequest,
} from '../http/types/module.list.request';

/**
 * @MODULE:     service-lib
 * @CREATED:    09/18/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * RecentlyPlayedDelegate used to Make API Calls to get the recently played data.
 */

export class RecentlyPlayedDelegate {
  /**
   * Internal Logger
   */
  private static logger: Logger = Logger.getLogger('RecentlyPlayedDelegate');

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(RecentlyPlayedDelegate, RecentlyPlayedDelegate, [
      HttpProvider,
    ]);
  })();

  /**
   * Constructor
   * @param http - Used to make API calls
   * @param channelLineupService is used to fill out the recently played data with channel lineup data
   */
  constructor(private http: HttpProvider) {}

  /**
   * Make the HTTP API call to remove a single Recently played item.
   * @returns {Observable<any>}  an observable that can be subscribed to to get the results of the API call
   */
  public removeRecentlyPlayedItem(
    recentPlayId: string,
    recentPlayAssetGuid: string,
    recentPlayType: string,
  ): Observable<Array<any>> {
    RecentlyPlayedDelegate.logger.debug(
      `removeRecentlyPlayedItem()-- Id : ${recentPlayId}`,
    );

    const params = _.cloneDeep(RecentlyPlayedConsts.REMOVE_REQUEST_PAYLOAD);
    params.recentPlayId = recentPlayId;
    params.assetGUID = recentPlayAssetGuid;
    params.recentPlayType = recentPlayType;
    const request = {
      profileUpdateRequest: {
        gupRecentPlaysUpdateRequests: [
          {
            recentPlay: params,
          },
        ],
      },
    };

    return this.removeRecentlyPlayed(request);
  }

  /**
   * Make the HTTP API call to remove all Recently played items.
   * @returns {Observable<any>}  an observable that can be subscribed to to get the results of the API call
   */
  public removeAllRecentlyPlayed(): Observable<any> {
    RecentlyPlayedDelegate.logger.debug(
      'removeAllRecentlyPlayed()-- Remove all items',
    );

    const request = {
      profileUpdateRequest: {
        gupRecentPlaysUpdateRequests: [
          {
            recentPlay: RecentlyPlayedConsts.REMOVE_ALL_REQUEST_PAYLOAD,
          },
        ],
      },
    };

    return this.removeRecentlyPlayed(request);
  }

  /**
   * Make the HTTP API call to remove a single Recently played item.
   * @returns {Observable<any>}  an observable that can be subscribed to to get the results of the API call
   */
  private removeRecentlyPlayed(request: any): Observable<any> {
    RecentlyPlayedDelegate.logger.debug('Remove RecentlyPlayed()');

    const areaRequests = new Array<ModuleArea>();
    areaRequests.push(
      new ModuleAreaRequest(
        RecentlyPlayedConsts.MODULE_AREA,
        RecentlyPlayedConsts.DELETE_MODULE_TYPE,
        request,
      ),
    );

    return this.http
      .postModuleAreaRequests(
        ServiceEndpointConstants.endpoints.RECENTLY_PLAYED
          .V4_REMOVE_RECENTLY_PLAYED,
        areaRequests,
        null,
      )
      .pipe(share());
  }
}
