import { FavoriteAssetTypes,
         IFavoriteItem,
         IGroupedFavorites,
         IRawFavoriteItem
} from "./favorite.interface";
import { ITile } from "../carousel/raw.tile.interface";
import { CarouselUtil } from "../carousel/carousel.util";

/**
 * @MODULE:     serviceLib
 * @CREATED:    11/08/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *     FavoriteServiceUtil is having utility methods for favorites
 */
export class FavoriteServiceUtil
{
    /**
     * Simple method that indicates if a Favorite item is LIVE type.
     *
     * @param {string} type - Requested asset type.
     * @return {boolean} Flag indicating if favorite tile Live.
     */
    public static isFavoriteTileLive(type: string): boolean
    {
        return (type === FavoriteAssetTypes.LIVE);
    }

    /**
     * Simple method that indicates if a Favorite item is EPISODE type.
     *
     * @param {string} type - Requested asset type.
     * @return {boolean} Flag indicating if favorite tile Episode.
     */
    public static isFavoriteTileAodEpisode(type: string): boolean
    {
        return (type === FavoriteAssetTypes.EPISODE);
    }

    /**
     * Simple method that indicates if a Favorite item is SHOW type.
     * @param {string} type - Requested asset type.
     * @returns {boolean} - Flag indicating if favorite tile SHOW.
     */
    public static isFavoriteTileAodShow(type: string): boolean
    {
        return (type === FavoriteAssetTypes.SHOW);
    }

    /**
     * convert grouped favorites(show[], episode[], channel[]) into single array of favorite items
     * @param IGroupedFavorites
     * @Return IRawFavoriteItem[]
     */
    public static unGroupFavoriteItems(groupedFavorites: IGroupedFavorites)
    {
        let defaultFavoriteItems: IRawFavoriteItem[] = [];

        Object.keys(groupedFavorites).forEach(segment =>
        {
            groupedFavorites[segment].reduce(generateFavoriteItem,defaultFavoriteItems as IRawFavoriteItem[]);
        });

        return defaultFavoriteItems;

        function generateFavoriteItem(favoriteItems: IRawFavoriteItem[], tile: ITile )
        {
            let favoriteItem: IRawFavoriteItem = {
                assetGUID: tile.tileAssetInfo.favAssetGuid,
                assetType: tile.tileContentType,
                assetName: tile.line1,
                channelId: tile.tileAssetInfo.channelId,
                contentType: CarouselUtil.getContentType(tile),
                tabSortOrder: tile.tileAssetInfo.tabSortOrder
            };

            favoriteItems.push(favoriteItem);

            return favoriteItems;
        }
    }

    /**
     * Normalize a favorite item by returning empty showLogos and showImages if those properties are not present
     * @returns {{assetGUID: any, assetType: any, channelId: any, showImages: Array, showLogos: Array}}
     */
    public static normalizeFavorites({
                                          assetGUID,
                                          assetType,
                                          assetName,
                                          contentType,
                                          episodeCount,
                                          channelId,
                                          tabSortOrder,
                                          showLogos = [],
                                          showImages = []
                                      }): IFavoriteItem
    {
        return {
            assetGUID,
            assetType,
            assetName,
            contentType,
            episodeCount,
            channelId,
            tabSortOrder,
            showImages,
            showLogos
        };
    }
}
