
import { of as observableOf, Observable } from 'rxjs';
import { share, catchError, mergeMap } from 'rxjs/operators';
import { FavoriteDelegate } from "./favorite.delegate";
import {
    IFavoriteItem,
    FavoriteUpdateItem
} from "./favorite.interface";

import {
    IProviderDescriptor,
    addProvider,
    Logger
} from "../index";
import { FavoriteModel } from "./favorite.model";
import { FavoriteServiceUtil} from "./favorite.service.util";

/**
 * @MODULE:     service-lib
 * @CREATED:    08/30/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *      Favorite Service used to get Favorite list and add/remove favorites
 */
export class FavoriteService
{
    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("FavoriteService");


    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(FavoriteService, FavoriteService,
            [
                FavoriteDelegate,
                FavoriteModel
            ]);
    }();

    /**
     * Constructor.
     * @param favoriteDelegate - The delegate that makes the get config HTTP API request.
     */
    constructor(private favoriteDelegate: FavoriteDelegate,
                private favoriteModel: FavoriteModel){}

    /**
     * Return the favorite
     * @param {string} assetGuid
     * @returns {IFavoriteItem}
     */
    public getFavorite(assetGuid: string): IFavoriteItem
    {
        return this.favoriteModel.getFavorite(assetGuid);
    }

    /**
     * Returns the favorite for the given channel.
     * @param {string} channelId
     * @returns {IFavoriteItem}
     */
    public getFavoriteForChannel(channelId: string): IFavoriteItem
    {
        return this.favoriteModel.getFavoriteForChannel(channelId);
    }

    /**
     * Update a favorite from the favorite list
     * @param favorite is the item to update
     * @returns {Observable<boolean>}
     */
    public updateFavorite(favorite: FavoriteUpdateItem): Observable<boolean>
    {
        const response: Observable<Array<any>> = this.favoriteDelegate.updateFavorite(favorite);

        return this.processFavorites(response);
    }

    /**
     * Used to set favorites after response available from API.
     * @param favorites - returned from API
     * @param triggerFavorites - optional(default is false) to notify the subscription
     * @returns {Observable<boolean>} - returns boolean value based on success of failure of API call.
     */
    private processFavorites(favorites: Observable<Array<any>>, triggerFavorites: boolean = true): Observable<boolean>
    {
        favorites.subscribe(onFavoritesAvailable.bind(this), onFavoritesFault.bind(this));

        //TODO - Can we clean this up, and make it more succinct.
        return favorites.pipe(mergeMap((response: any) => !response ? observableOf(false) : observableOf(true)),
            catchError(() => observableOf(false)),
            share());

        /**
         * Once the favorites available, save it to the favorites and kicks of observable.
         * @param favorites - returned by the delegate
         */
        function onFavoritesAvailable(favorites: Array<any>)
        {
            const favs = favorites.map(FavoriteServiceUtil.normalizeFavorites);
            this.favoriteModel.setFavorites(favs, triggerFavorites);

        }

        /**
         * Once delegate throws exception then fault handler get called.
         * @param error - returned by the delegate
         */
        function onFavoritesFault(error: Error)
        {
            FavoriteService.logger.error(`processFavorites - Error (${JSON.stringify(error)})`);
        }
    }

    /**
     * Method that updates a favorites array
     * @param {Array<FavoriteUpdateItem>} favorites
     * @param triggerFavorites - optional(default is false) to notify the subscription
     * @returns {Observable<boolean>}
     */
    public updateFavorites(favorites: Array<FavoriteUpdateItem>, triggerFavorites: boolean = true): Observable<boolean>
    {
        const response: Observable<any> = this.favoriteDelegate
                                              .updateFavorites(favorites);
        return this.processFavorites(response, triggerFavorites);
    }
}
