import { IAppConfig }    from "../config/interfaces/app-config.interface";
import {
    getQueryParameterValue,
    Logger
}                        from "../index";
import { ApiLayerTypes } from "../service/consts";

export class ChromecastUtil
{
    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("ChromecastUtil");

    constructor() {}

    /**
     * Determines if a URL is valid.
     * @param {String} url - The URL under scrutiny.
     * @returns {Boolean}
     */
    public static isValidUrl(url: string): boolean
    {
        return url && ((url.indexOf("http") !== -1) || (url.indexOf("https") !== -1));
    }

    /**
     * Gets the Application ID for Chromecast by either grabbing the developer entered one in the URL query string
     * or by using the default, hardcoded production one.
     * @returns {string}
     */
    public static getAppId(region : string = ApiLayerTypes.REGION_US): string
    {
        let chromecastAppId: string = getQueryParameterValue("chromecastAppId");
        if (chromecastAppId)
        {
            ChromecastUtil.logger.debug(
                `getAppId( App ID = ${chromecastAppId}. Using ID provided in query string param \`chromecastAppId\`. )`
            );
            return chromecastAppId;
        }
        else
        {
            chromecastAppId = (region === ApiLayerTypes.REGION_CA) ? "936D2C8C" : "4156CAC9";
            ChromecastUtil.logger.debug(
                `getAppId( App ID = ${chromecastAppId}. Using default, hardcoded value in the app and not a runtime provided value. )`
            );
            return chromecastAppId;
        }
    }

    /**
     * The API endpoint's base URL.
     *
     * NOTE: If we're using local dev then this valuer is hardcoded as we don't know the API endpoint used
     * for the local API proxy server.
     *
     * @returns {string}
     */
    public static getApiEnv(appConfig: IAppConfig): string
    {
        let api: string = getQueryParameterValue("api");
        api = api ? api : appConfig.domainName;

        // TODO: BMR: 07/12/2018: Come back to this first guard for the Chromecast API value.
        // if (!api) {
        //     var domain = BootStrapUtils.getDomainPath();
        //     ChromecastUtil.API_ENV = domain.replace("https://","");
        // }
        if (api.indexOf("local-dev") !== -1)
        {
            api = (appConfig.deviceInfo.appRegion === ApiLayerTypes.REGION_CA)
                ? "api-k2qa4.mountain.siriusxm.ca" : "api-k2qa4.mountain.siriusxm.com";
            ChromecastUtil.logger.warn(`getApiEnv( API_ENV = ${api}. Using default, hardcoded value in the app and not a runtime provided value. )`);
            return api;
        }

        ChromecastUtil.logger.debug(`getApiEnv( API_ENV = ${api}. Using API provided in query string param "api". )`);
        return api;
    }

    // TODO: BMR: Implement show or hide Chromecast loader.
    public static showOrHideLoader(bool: boolean): void
    {
        ChromecastUtil.logger.debug(`showOrHideLoader( ${bool} )`);
    }
}
