import { findProvider } from './providerDescriptors';
import { IProviderDescriptor } from './provider.descriptor.interface';
import { Logger } from '../logger';

/**
 * Internal interface used to store the singleton for a specific class
 */
class Instance {
  constructor(public classConstructor: Function, public instance: object) {}
}

/**
 * @MODULE:     service-lib
 * @CREATED:    01/11/19
 * @COPYRIGHT:  2019 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * Factory Service for constructing singletons for other services with proper dependency injection
 */
export class ServiceFactory {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('ServiceFactory');

  /**
   * Array of service instances that are available.  If a singleton is in the array it has already been created
   * and can be used without creating a new instance
   */
  private static services: Instance[] = [];

  /**
   * Function for getting a singleton instance of a specific class
   *
   * @param {Function} classConstructor is the constructor for the class
   * @returns {Object} the singleton for the class
   */
  public static getInstance(classConstructor: Function): Object {
    try {
      return ServiceFactory.findOrCreateInstance(classConstructor);
    } catch (e) {
      const error = `Circular dependency found creating class ${classConstructor.name}`;
      ServiceFactory.logger.error(error);
    }

    return undefined;
  }

  /**
   * Function that will search for the given class singleton and either create a new singleton with all
   * dependencies satisfied or return an existing singleton
   *
   * @param {Function} classConstructor is the constructor for the class
   * @returns {Object} the singleton for the class
   */
  private static findOrCreateInstance(classConstructor: Function): Object {
    const provider = findProvider(classConstructor);
    if (!provider) {
      ServiceFactory.logger.error(
        `Could not find ${classConstructor.name} for singleton creation`,
      );
      return undefined;
    }

    let isSingleton =
      provider.singleton === undefined ||
      provider.singleton === null ||
      provider.singleton === true;
    let instance: Instance = undefined;

    if (isSingleton === true) {
      instance = ServiceFactory.findInstance(classConstructor);
      if (!!instance) {
        return instance.instance;
      }
    }

    if ((classConstructor as any).newed === true) {
      const error = `Circular dependency found creating ${classConstructor.name}`;
      throw error;
    }

    (classConstructor as any).newed = true;

    let dependencies: Object[];

    try {
      dependencies = ServiceFactory.getDependencies(provider);
    } catch (e) {
      const error = `Circular dependency found creating dependencies for ${classConstructor.name}`;
      throw error;
    }

    instance = new (classConstructor as any)(...dependencies);

    if (isSingleton === true) {
      ServiceFactory.services.push({
        classConstructor: classConstructor,
        instance: instance,
      });
    } else {
      (classConstructor as any).newed = false;
    } // a must if we are not dealing with singletons

    return instance;
  }

  /**
   * Search the array of instances for a given class singleton
   *
   * @param {Function} classConstructor is the constructor for the singleton we are looking for
   * @returns {Instance | undefined} if the singleton is found it will be returned, otherwise undefined
   *          is returned
   */
  private static findInstance(classConstructor: Function): Instance {
    return ServiceFactory.services.find(
      (singleton: Instance) => singleton.classConstructor === classConstructor,
    );
  }

  /**
   * Gat an array of objects with the dependencies for the given provider record
   *
   * @param {IProviderDescriptor} provider is the record describing the provider that we want dependencies for
   * @returns {[Object]} array containing the dependencies, empty if there are no dependencies
   */
  private static getDependencies(provider: IProviderDescriptor): Object[] {
    const dependencies: Object[] = [];
    let undefinedDepIndex = -1;

    for (let i = 0; i < provider.deps.length; i++) {
      const dependency = provider.deps[i];
      try {
        if (provider.deps[i] === undefined) {
          undefinedDepIndex = i;
        } else if (provider.deps[i] instanceof Function) {
          dependencies.push(ServiceFactory.findOrCreateInstance(dependency));
        } else {
          dependencies.push(provider.deps[i]);
        }
      } catch (e) {
        const error = `Circular dependency found creating dependency ${dependency.name} for ${provider.useClass.name}`;
        ServiceFactory.logger.error(error);
        throw error;
      }
    }

    if (undefinedDepIndex >= 0) {
      const error =
        `undefined dependency (probably circular) found creating dependency ` +
        `index ${undefinedDepIndex} for ${provider.useClass.name}`;
      ServiceFactory.logger.error(error);
      throw error;
    }

    return dependencies;
  }
}
