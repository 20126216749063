import {
  AlertService,
  AlertType,
  AppErrorCodes,
  EError,
  ErrorService,
  IAlert,
  Logger,
  IProviderDescriptor,
  addProvider,
} from '../../servicelib';
import { CarouselStoreService } from '../carousel-store/carousel.store.service';
import { ToastService } from '../toast/toast.service';
import { Observable } from 'rxjs';

export class AlertClientService {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('AlertClientService');

  /**
   * Observable that can used to get notified when the alerts have changed
   * @type {any}
   */
  public alerts: Observable<IAlert[]>;

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(AlertClientService, AlertClientService, [
      AlertService,
      CarouselStoreService,
      ToastService,
      ErrorService,
    ]);
  })();

  /**
   * Constructor
   * @param {AlertService} alertService
   * @param {CarouselStoreService} carouselStoreService
   * @param toastService
   * @param errorService
   */
  constructor(
    private alertService: AlertService,
    private carouselStoreService: CarouselStoreService,
    private toastService: ToastService,
    private errorService: ErrorService,
  ) {
    this.alerts = (this.alertService.alerts as any) as Observable<IAlert[]>;
  }

  /**
   * Create a new alert
   * @param {string} channelId
   * @param {string} assetGuid
   * @param {AlertType} alertType
   */
  public createAlert(
    channelId: string,
    assetGuid: string,
    alertType: AlertType,
  ): Observable<boolean> {
    const response = this.alertService.createAlert(
      channelId,
      assetGuid,
      alertType,
    );

    response.subscribe((response: boolean) => {
      if (!response) {
        return;
      }

      let error: any = { type: AppErrorCodes.FLTT_CONTENT_ALERT };

      if (alertType === AlertType.LIVE_VIDEO_START) {
        error = { type: EError.LIVE_VIDEO_REMINDER };
      }

      this.errorService.handleError(error);

      this.carouselStoreService.selectShowReminders();
    });

    return (response as any) as Observable<boolean>;
  }

  /**
   * Remove an alert
   * @param {string} alertId
   * @param {string} assetGuid
   * @param alertType
   */
  public removeAlert(
    alertId: string,
    assetGuid: string,
    alertType: AlertType,
  ): Observable<boolean> {
    const response = this.alertService.removeAlert(
      alertId,
      assetGuid,
      alertType,
    );

    response.subscribe((response: boolean) => {
      if (!response) {
        return;
      }

      let translationPath = 'reminders.removeShowReminderSuccess';
      if (alertType === AlertType.LIVE_VIDEO_START) {
        translationPath = 'reminders.removeLiveVideoReminderSuccess';
      }
      this.launchToast(translationPath);
      this.carouselStoreService.selectShowReminders();
    });

    return (response as any) as Observable<boolean>;
  }

  /**
   * Remove all alerts
   */
  public removeAllAlerts(): void {
    this.alertService.removeAllAlerts().subscribe((response: boolean) => {
      if (!response) {
        return;
      }
      this.carouselStoreService.selectShowReminders();
    });
  }

  /**
   * Mute an alert
   * @param {string} alertId
   */
  public muteAlert(alertId: string): void {
    this.alertService.muteAlert(alertId).subscribe((response: boolean) => {
      if (!response) {
        return;
      }

      this.carouselStoreService.selectShowReminders();
    });
  }

  /**
   * Used to get an alert based on showGuid
   * @param {string} showGuid
   * @returns {IAlert}
   */
  public getAlert(showGuid: string, alertType: AlertType): IAlert {
    return this.alertService.getShowAlert(showGuid, alertType);
  }

  /**
   * Used to launch toast message when alert created/removed
   * @param {string} message
   */
  private launchToast(message: string): void {
    this.toastService.open({
      messagePath: message,
      hasCloseButton: true,
      closeToastTime: 10000,
      isAltColor: true,
    });
  }
}
