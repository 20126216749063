import { Subject, Observable } from 'rxjs';
import { IProviderDescriptor, addProvider, Logger } from '../index';

import { KochavaAnalyticsService } from '../analytics/kochava-analytics.service';

import { VideoPlayerService } from '../mediaplayer/videoplayer/video-player.service';
import { IStartVideo } from '../mediaplayer/videoplayer/video-player.interface';

import { kochavaAnalyticsConstants } from './kochava-analytics.constants';

/**
 * @MODULE:     service-lib
 * @CREATED:    04/16/2021
 * @COPYRIGHT:  2021 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *      Explore Service to handle the Free Tier Explore features.
 */

export class ExploreService {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('ExploreService');

  private onReelCloseSubject = new Subject<boolean>();
  public onReelClose$: Observable<boolean> = null;
  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(ExploreService, ExploreService, [
      VideoPlayerService,
      KochavaAnalyticsService,
    ]);
  })();

  constructor(
    private videoPlayerService: VideoPlayerService,
    private kochavaService: KochavaAnalyticsService,
  ) {
    this.onReelClose$ = this.onReelCloseSubject;
    this.videoPlayerService.ftPlaybackComplete$.subscribe(() => {
      this.onTeaseReelClose();
    });
  }

  /**
   * Loading the FT Teaser
   */
  public initiateTeaser(videoUrl: string) {
    this.videoPlayerService.stop();

    const asset: IStartVideo = {
      url: videoUrl,
      autoPlay: true,
      startTime: 0,
      type: 'video/mp4',
    };

    videoUrl ? this.loadAsset(asset) : this.onTeaseReelClose();
  }

  private loadAsset(startVideo: IStartVideo): void {
    if (this.videoPlayerService.videoPlayer) {
      // log the kochava event
      this.kochavaService.recordPageView(
        kochavaAnalyticsConstants.SCREEN_NAMES.REEL,
        kochavaAnalyticsConstants.FLOWS.EXPLORE,
        kochavaAnalyticsConstants.EVENT_TYPES.LOAD,
        kochavaAnalyticsConstants.EVENT_ACTIONS.LOAD,
        kochavaAnalyticsConstants.EVENT_NAMES.EXPLORE_REEL_LOAD,
      );
      this.videoPlayerService.loadAsset(startVideo, true);
    }
  }

  public onTeaseReelClose() {
    // log the kochava event
    this.kochavaService.recordAction(
      kochavaAnalyticsConstants.SCREEN_NAMES.REEL,
      kochavaAnalyticsConstants.FLOWS.EXPLORE,
      kochavaAnalyticsConstants.EVENT_TYPES.CLICK,
      kochavaAnalyticsConstants.EVENT_ACTIONS.CLOSE,
      kochavaAnalyticsConstants.EVENT_NAMES.EXPLORE_REEL_CLOSE,
    );
    this.videoPlayerService.pause();
    this.videoPlayerService.stop(/*-1*/);
    this.onReelCloseSubject.next(true);
  }
}
