/**
 * @MODULE:     service-lib
 * @CREATED:    07/19/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *  AuthenticationConstants are constants which can be reused in application 
 */
export class AuthenticationConstants
{
    public static LOGIN_ATTEMPTS_ALLOWED: number = 3;
    public static BAD_USER_CREDENTIALS: string= "Bad username/password";
    public static INVALID_CREDENTIALS: string = "authenticationService.login::Invalid Login Credentials";
    public static INVALID_AUTH_RESPONSE: string = "authenticationService.login::Invalid Authentication Response";
    public static LOGOUT_LAG: number = 800; // Add lag to logout call such that consume is successful
    public static SERVER_FAILURE: string= "Server failure";
    public static NUDETECT_PLACEMENT: string = 'Login';
    public static NUDETECT_PLACEMENT_PAGE: string = '1';
    public static NUDETECT_REPORTING_SEGMENT: string = 'EverestWeb';
}


export interface NuDetectPayload {
    ndSpmd?: any;       // when passing to API use this
    ndPayload?: any;       // internally use this (because API property is akward with a - in the name)
    ndSessionId?: string;
    ndPlacement?: string;
    ndPlacementPage?: string;
    ndReportingSegment?: string;
}

export interface IScreenFlow {
    name: string;
}
