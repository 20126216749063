import { ResumeService } from "../resume";
import { addProvider, IProviderDescriptor } from "../service";

export class KochavaAnalyticsService{

    private gupId;

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(KochavaAnalyticsService,
            KochavaAnalyticsService, [
                ResumeService
            ]);
    }();

    constructor(private resumeService: ResumeService)
    {
        resumeService.profileData.subscribe(data =>
        {
            this.gupId = data.gupId;
        });
    }

    recordPageView(
        screen: string,
        flow: string,
        type: string,
        action: string,
        name: string
    )
    {
        // @ts-ignore
        window.kochava.page(screen, {
            flow: flow,
            type: type,
            action: action,
            eventName: name
        }, ()=>{});
    }

    recordAction(
        screen: string,
        flow: string,
        type: string,
        action: string,
        name: string,
        meta?: string
    )
    {
        // @ts-ignore
        window.kochava.activity(name, {
            screen: screen,
            flow: flow,
            type: type,
            action: action,
            meta: meta
        }, ()=>{});
    }
}
