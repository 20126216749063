import { SxmAnalyticsDelegate } from './sxm-analytics.delegate';
import { IAppConfig } from '../config/interfaces/app-config.interface';
import { Logger } from '../logger';
import { addProvider, IProviderDescriptor } from '../service';
import {
  EAction,
  EActionSource,
  EAppCountry,
  EApplication,
  EBannerInd,
  EConsumedInd,
  EContentSource,
  EContentType,
  EElementType,
  EFindingMethod,
  EInputType,
  ELanguage,
  EModal,
  EOrientation,
  EPageFrame,
  EPlatform,
  EStreamingType,
  ESubscriptionLevel,
  ETileType,
  EPageName,
} from './sxm-analytics.enum';
import { SxmAnalyticsDecodeConstants } from './sxm-analytics-decode.constants';
import {
  ISXMClientTag,
  ISXMDeviceInfo,
  ISXMGlobalTag,
} from './sxm-analytics-tag.interface';
import { AuthenticationModel } from '../authentication/authentication.model';
import { INowPlayingStore } from './now-playing.store';
import {
  AuthenticationAnalyticsTags,
  BufferUnderRunAnalyticsTags,
  CarouselTypeConst,
  InitializationService,
  InitializationStatusCodes,
  ScreenNames,
  toTitleCase,
  TerminalAnalyticsTags,
  VideoPlayerConstants,
  VideoStopAnalyticsTags,
  AnalyticsDefaultValues,
} from '../index';
import { filter, map, pairwise, take } from 'rxjs/operators';
import { combineLatest } from 'rxjs';
import { VideoPlayerService } from '../mediaplayer/videoplayer/video-player.service';
import { MediaTimeLineService } from '../media-timeline/media.timeline.service';
import { MediaUtil } from '../mediaplayer/media.util';
import { SubscriptionLike as ISubscription } from 'rxjs/internal/types';

/**
 * @MODULE:     service-lib
 *
 * @CREATED:    04/03/19
 * @COPYRIGHT:  2019 Sirius XM Radio Inc.
 * @VERSION:    3.22
 *
 * @DESCRIPTION:
 *
 * SxmAnalyticsService to log analytics tags.
 */
export class SxmAnalyticsService {
  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(SxmAnalyticsService, SxmAnalyticsService, [
      SxmAnalyticsDelegate,
      'IAppConfig',
      AuthenticationModel,
      InitializationService,
      VideoPlayerService,
      MediaTimeLineService,
    ]);
  })();

  /**
   * Stores a reference to a subscription
   */
  private timelineSubscription: ISubscription;
  private bufferRanLowSubscription: ISubscription;

  public userDaysListened: number;

  /**
   * Constructor
   * @param analyticsDelegate makes the POST with the combined tag (global and client)
   * @param SERVICE_CONFIG for client configuration info
   */
  constructor(
    private analyticsDelegate: SxmAnalyticsDelegate,
    private SERVICE_CONFIG: IAppConfig,
    private authenticationModel: AuthenticationModel,
    private initializationService: InitializationService,
    private videoPlayerService: VideoPlayerService,
    protected mediaTimeLineService: MediaTimeLineService,
  ) {
    SxmAnalyticsService.logger.debug('Constructor()');

    combineLatest(
      this.authenticationModel.authenticated$,
      initializationService.initState,
    )
      .pipe(
        filter(data => {
          const authenticated = data[0];
          const initState = data[1];
          return (
            authenticated === true &&
            initState === InitializationStatusCodes.RUNNING
          );
        }),
        take(1),
      )
      .subscribe(data => {
        this.logAnalyticsTag({
          name: AuthenticationAnalyticsTags.AUTH_LOGIN_SUCCESS_NAME,
          userPath: AuthenticationAnalyticsTags.AUTH_LOGIN_USER_PATH,
          skipActionSource: true,
          skipNpData: true,
          skipCurrentPosition: true,
        });
      });

    this.authenticationModel.authenticated$
      .pipe(filter(value => value === false))
      .subscribe(value => {
        this.logAnalyticsTag({
          name: AuthenticationAnalyticsTags.AUTH_LOGIN_UNSUCCESS_NAME,
          userPath: AuthenticationAnalyticsTags.AUTH_LOGIN_UNSUCCESS_USER_PATH,
          screen: window.location.href,
          userPathScreenPosition: 2,
          action: EAction.CLICK.toUpperCase(),
          skipNpData: true,
          skipCurrentPosition: true,
        });
      });

    /**
     * Turn on video sdk analytics on supportsVideoSdkAnalytics true
     * */
    if (this.SERVICE_CONFIG.deviceInfo.supportsVideoSdkAnalytics) {
      this.observeBufferUnderRun();
      this.observeMediaTimeLineForStop();
    }
  }

  public static SXM_ANALYTICS_SCHEMA_VERSION = '3.33';
  public static SXM_SCHEMA_CLASS = 'EVEREST';
  public static SXM_CCL_VERSION = 'Web';
  public static CLEAN = undefined;

  private nowPlayingData: INowPlayingStore;
  private HIT_COUNTER = 0;

  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('SxmAnalyticsService');

  public updateNowPlayingData(nowPlayingData: INowPlayingStore): void {
    this.nowPlayingData = nowPlayingData;
  }

  /**
   * Post the tag data to the endpoint
   * @param clientTag
   */
  public logAnalyticsTag(clientTag: ISXMClientTag): void {
    this.analyticsDelegate
      .postAnalyticsCall(this.buildAnalyticsTag(clientTag))
      .subscribe(
        resp => {
          SxmAnalyticsService.logger.debug('logAnalyticsTag - success');
        },
        error => {
          SxmAnalyticsService.logger.debug(
            'logAnalyticsTag - ' + JSON.stringify(error),
          );
        },
      );
  }

  /**
   * Gets the numeric code from the attribute name
   * @param name
   */
  public getCodeForAttribute(name: string): number {
    const attribute = SxmAnalyticsDecodeConstants.ATTRIBUTE_DECODE_LIST.find(
      attribute => attribute.name === name,
    );

    if (!!attribute) {
      return attribute.code;
    }
  }

  /**
   * May need to map the data to the right format
   * @param tagObject
   */
  public buildAnalyticsTag(tagObject: ISXMClientTag) {
    const clientTag = this.makeClientTag(tagObject);
    let tag = { tags: [] };

    Object.keys(clientTag).forEach(key => {
      if (clientTag[key] instanceof Array) {
        const tags = clientTag[key][0];
        let hold = {};
        for (const key of Object.keys(tags)) {
          const value = tags[key];
          // NOTE: check below is to remove the empty/null values optional properties.
          if (value !== SxmAnalyticsService.CLEAN) {
            hold[this.getCodeForAttribute(key)] = tags[key];
          }
        }

        tag.tags[0] = hold;
      } else {
        tag[this.getCodeForAttribute(key)] = clientTag[key];
      }
    });

    return tag;
  }

  private getDeviceInfoTag(): ISXMDeviceInfo {
    const deviceOS = this.SERVICE_CONFIG.deviceInfo
      ? this.SERVICE_CONFIG.deviceInfo.osVersion
      : null;
    const deviceOSSwitch = platform =>
      ({
        Windows: EPlatform.WINDOWS,
        Mac: EPlatform.MAC_OS,
      }[platform]);

    const appRegionSwitch = region =>
      ({
        US: EAppCountry.US,
        CA: EAppCountry.CANADA,
      }[region]);

    const languageSwitch = language =>
      ({
        en: ELanguage.ENGLISH,
        fr: ELanguage.FRENCH,
      }[language]);

    return {
      GupId:
        this.SERVICE_CONFIG.allProfilesData &&
        this.SERVICE_CONFIG.allProfilesData.profileData[0]
          ? this.SERVICE_CONFIG.allProfilesData.profileData[0].gupId
          : AnalyticsDefaultValues.DEFAULT_GUP_WHEN_UNAVAILABLE,
      DeviceID: this.SERVICE_CONFIG.deviceInfo
        ? this.SERVICE_CONFIG.deviceInfo.clientDeviceId
        : null,
      Platform: deviceOSSwitch(deviceOS),
      Application: EApplication.EVEREST_WEB,
      UiVersion: this.SERVICE_CONFIG.deviceInfo.sxmAppVersion,
      DeviceType: this.SERVICE_CONFIG.deviceInfo.platform,
      DeviceOS: deviceOSSwitch(deviceOS),
      AppCountry: appRegionSwitch(this.SERVICE_CONFIG.deviceInfo.appRegion),
      Language: languageSwitch(this.SERVICE_CONFIG.deviceInfo.language),
      ChannelLineupID:
        this.SERVICE_CONFIG.clientConfiguration &&
        this.SERVICE_CONFIG.clientConfiguration.channelLineupId
          ? this.SERVICE_CONFIG.clientConfiguration.channelLineupId.toString()
          : AnalyticsDefaultValues.UNKNOWN,
      UserProfile:
        this.SERVICE_CONFIG.allProfilesData &&
        this.SERVICE_CONFIG.allProfilesData.profileData[0]
          ? this.SERVICE_CONFIG.allProfilesData.profileData[0].gupId
          : AnalyticsDefaultValues.DEFAULT_GUP_WHEN_UNAVAILABLE,
      SessionId: this.SERVICE_CONFIG.uniqueSessionId,
      SchemaVersion: SxmAnalyticsService.SXM_ANALYTICS_SCHEMA_VERSION,
      SchemaClass: SxmAnalyticsService.SXM_SCHEMA_CLASS,
      CclVersion: SxmAnalyticsService.SXM_CCL_VERSION,
    };
  }

  public fireTerminalEvent(type, name, text, userPath, route, action): void {
    this.logAnalyticsTag({
      type: type,
      name: name,
      text: text,
      userPath: userPath,
      screen: route,
      action: action,
    });
  }

  public makeClientTag(tagData: ISXMClientTag): ISXMGlobalTag {
    const deviceInfoTagData: ISXMDeviceInfo = this.getDeviceInfoTag();
    const currentPosition: string = tagData.skipCurrentPosition
      ? SxmAnalyticsService.CLEAN
      : this.getCurrentPosition();
    const currentScreen: string = !tagData.skipScreenName
      ? this.getScreenName(tagData.screen)
      : null;
    const currentUserPath: string = tagData.userPathScreenPosition
      ? this.getUserPath(
          tagData.userPath,
          tagData.userPathScreenPosition,
          currentScreen,
        )
      : tagData.userPath;
    const bannerInd: EBannerInd = tagData.bannerInd
      ? EBannerInd[tagData.bannerInd.replace(/\s+/g, '')]
      : SxmAnalyticsService.CLEAN;
    const consumedInd: EConsumedInd = tagData.skipConsumedPerc
      ? SxmAnalyticsService.CLEAN
      : this.getConsumedIndTag(bannerInd, tagData.consumedPerc);
    /**
     *  Here I need to find all of these values or use null or undefined if they don't exist in context
     */
    return {
      // globals
      GupId: deviceInfoTagData.GupId,
      DeviceID: deviceInfoTagData.DeviceID,
      Platform: deviceInfoTagData.Platform,
      Application: deviceInfoTagData.Application,
      UiVersion: deviceInfoTagData.UiVersion,
      DeviceType: deviceInfoTagData.DeviceType,
      DeviceOS: deviceInfoTagData.DeviceOS,
      AppCountry: deviceInfoTagData.AppCountry,
      Language: deviceInfoTagData.Language,
      ChannelLineupID: deviceInfoTagData.ChannelLineupID,
      UserProfile: deviceInfoTagData.UserProfile,
      SessionId: deviceInfoTagData.SessionId,
      SchemaVersion: deviceInfoTagData.SchemaVersion,
      SchemaClass: deviceInfoTagData.SchemaClass,
      CclVersion: deviceInfoTagData.CclVersion,
      UserDaysListened: this.userDaysListened,
      // end globals

      SubscriptionLevel: this.getSubscriptionLevel(),
      tags: [
        {
          ActionTime: new Date().getTime() + '',
          TagShortName: tagData.name || SxmAnalyticsService.CLEAN, // TODO CHECK what values to be populated.
          UserPath: currentUserPath || SxmAnalyticsService.CLEAN,
          Screen: currentScreen || SxmAnalyticsService.CLEAN,
          PageFrame:
            <any>EPageFrame[tagData.pageFrame] || SxmAnalyticsService.CLEAN,
          CarouselName: tagData.carouselName || SxmAnalyticsService.CLEAN,
          CarouselPosition: Number.isInteger(tagData.carouselPosition)
            ? tagData.carouselPosition
            : SxmAnalyticsService.CLEAN,
          ElementType:
            <any>EElementType[tagData.elementType] || SxmAnalyticsService.CLEAN,
          ButtonName: tagData.buttonName || SxmAnalyticsService.CLEAN,
          LinkName: tagData.linkName || SxmAnalyticsService.CLEAN,
          Text:
            tagData &&
            tagData.type &&
            tagData.type === TerminalAnalyticsTags.TERMINAL_EVENT_TYPE
              ? SxmAnalyticsService.CLEAN
              : tagData.text || SxmAnalyticsService.CLEAN,
          ElementPosition: tagData.elementPosition,
          Action: EAction[tagData.action] || SxmAnalyticsService.CLEAN,
          ActionSource: tagData.skipActionSource
            ? SxmAnalyticsService.CLEAN
            : tagData.actionSource
            ? EActionSource[tagData.actionSource]
            : EActionSource.MAIN,
          InputType: EInputType[tagData.inputType] || SxmAnalyticsService.CLEAN,
          ContentSource: tagData.skipContentSource
            ? SxmAnalyticsService.CLEAN
            : EContentSource.IP,
          TileType: tagData.tileType
            ? this.getTileType(tagData.tileType)
            : SxmAnalyticsService.CLEAN,
          ZoneName: tagData.zoneTitle || SxmAnalyticsService.CLEAN,
          ZonePosition: Number.isInteger(tagData.zoneOrder)
            ? tagData.zoneOrder
            : SxmAnalyticsService.CLEAN,

          /* don't know where to get these from */
          ChannelGuid:
            tagData &&
            tagData.tileAssetInfo &&
            tagData.tileAssetInfo.channelGuid
              ? tagData.tileAssetInfo.channelGuid
              : SxmAnalyticsService.CLEAN,
          ShowGuid:
            tagData && tagData.tileAssetInfo && tagData.tileAssetInfo.showGuid
              ? tagData.tileAssetInfo.showGuid
              : SxmAnalyticsService.CLEAN,
          CategoryGuid:
            tagData &&
            tagData.tileAssetInfo &&
            tagData.tileAssetInfo.categoryGuid
              ? tagData.tileAssetInfo.categoryGuid
              : SxmAnalyticsService.CLEAN,
          EpisodeGuid:
            tagData &&
            tagData.tileAssetInfo &&
            tagData.tileAssetInfo.episodeGuid
              ? tagData.tileAssetInfo.episodeGuid
              : SxmAnalyticsService.CLEAN,
          AodEpisodeGuid:
            tagData &&
            tagData.tileAssetInfo &&
            tagData.tileAssetInfo.aodEpisodecaId
              ? tagData.tileAssetInfo.aodEpisodecaId
              : SxmAnalyticsService.CLEAN,
          VodEpisodeGuid:
            tagData &&
            tagData.tileAssetInfo &&
            tagData.tileAssetInfo.vodEpisodeGuid
              ? tagData.tileAssetInfo.vodEpisodeGuid
              : SxmAnalyticsService.CLEAN,

          ContentType: tagData.tileContentType
            ? this.getContentType(tagData.tileContentType)
            : SxmAnalyticsService.CLEAN,
          NpChannelGuid:
            !tagData.skipNpData &&
            this.nowPlayingData &&
            this.nowPlayingData.channel &&
            this.nowPlayingData.channel.channelGuid
              ? this.nowPlayingData.channel.channelGuid
              : SxmAnalyticsService.CLEAN,
          NpShowGuid:
            !tagData.skipNpData &&
            this.nowPlayingData &&
            this.nowPlayingData.show &&
            this.nowPlayingData.show.assetGUID
              ? this.nowPlayingData.show.assetGUID
              : SxmAnalyticsService.CLEAN,
          NpEpisodeGuid:
            !tagData.skipNpData &&
            this.nowPlayingData &&
            this.nowPlayingData.episode
              ? this.nowPlayingData.episode.assetGUID
              : SxmAnalyticsService.CLEAN,
          NpAodEpisodeGuid:
            !tagData.skipNpData &&
            this.nowPlayingData &&
            this.nowPlayingData.episode
              ? this.nowPlayingData.episode.assetGUID
              : SxmAnalyticsService.CLEAN,
          NpVodEpisodeGuid:
            !tagData.skipNpData &&
            this.nowPlayingData &&
            this.nowPlayingData.episode &&
            this.nowPlayingData.episode.vodEpisodeGUID
              ? this.nowPlayingData.episode.vodEpisodeGUID
              : SxmAnalyticsService.CLEAN,
          CurrentPosition: currentPosition || SxmAnalyticsService.CLEAN,

          Orientation: tagData.skipOrientation
            ? SxmAnalyticsService.CLEAN
            : EOrientation.LANDSCAPE,
          HitId: this.getHitId(),

          AssetType: SxmAnalyticsService.CLEAN,
          CollectionGuid: tagData.collectionGuid || SxmAnalyticsService.CLEAN,
          PlayingStatus: SxmAnalyticsService.CLEAN, // TODO
          NpCategoryGuid: SxmAnalyticsService.CLEAN,
          FindingMethod:
            EFindingMethod[tagData.findingMethod] ||
            tagData.findingMethod ||
            SxmAnalyticsService.CLEAN,
          SearchTerm: tagData.searchTerm
            ? tagData.searchTerm
            : SxmAnalyticsService.CLEAN,
          NumSearchResults:
            tagData.numSearchResults >= 0
              ? tagData.numSearchResults
              : SxmAnalyticsService.CLEAN,
          DirectTuneEntry: SxmAnalyticsService.CLEAN,
          NewFavoriteIndex: Number.isInteger(tagData.newFavoriteIndex)
            ? tagData.newFavoriteIndex
            : SxmAnalyticsService.CLEAN,
          ErrorNumber: tagData.errorNumber || 411,
          LeadKeyId: SxmAnalyticsService.CLEAN,
          MessageType: tagData.messageType || SxmAnalyticsService.CLEAN,
          MetricEventCode: SxmAnalyticsService.CLEAN,
          PushKeyId: SxmAnalyticsService.CLEAN,
          MessageExpDate: SxmAnalyticsService.CLEAN,
          NullSearch: Number.isInteger(tagData.numSearchResults)
            ? tagData.numSearchResults > 0
              ? 0
              : 1
            : SxmAnalyticsService.CLEAN,
          Coachmark: SxmAnalyticsService.CLEAN,
          Toast: tagData.toast || SxmAnalyticsService.CLEAN,
          Modal:
            EModal[tagData.modal] || tagData.modal || SxmAnalyticsService.CLEAN,
          Overlay: tagData.overlayName || SxmAnalyticsService.CLEAN,
          LiveEventGuid: SxmAnalyticsService.CLEAN,
          TeamGuid: SxmAnalyticsService.CLEAN,
          LeagueGuid: SxmAnalyticsService.CLEAN,
          NpTileType: !tagData.skipNpData
            ? this.getNPTileType()
            : SxmAnalyticsService.CLEAN,
          NpAssetType: SxmAnalyticsService.CLEAN,
          NpContentType: !tagData.skipNpData
            ? this.getNPContentType()
            : SxmAnalyticsService.CLEAN,
          NpStreamingType: !tagData.skipNpData
            ? this.getNPStreamingType()
            : SxmAnalyticsService.CLEAN,
          NpLiveEventGuid: SxmAnalyticsService.CLEAN,
          NpTeamGuid: SxmAnalyticsService.CLEAN,
          NpLeagueGuid: SxmAnalyticsService.CLEAN,
          NpStatus: SxmAnalyticsService.CLEAN,
          BannerInd: bannerInd,
          MarketingId: SxmAnalyticsService.CLEAN,
          ConsumedInd: consumedInd || SxmAnalyticsService.CLEAN,
          StreamingType: tagData.tileContentType
            ? this.getStreamingType(tagData.tileContentType)
            : SxmAnalyticsService.CLEAN,
          Edp: tagData.edp || SxmAnalyticsService.CLEAN,
          SeekTo: SxmAnalyticsService.CLEAN,
          SeekFrom: SxmAnalyticsService.CLEAN,
          PageName: tagData.pageName,
        },
      ],
    };
  }

  private getSubscriptionLevel(): string {
    if (
      this.authenticationModel.userSession &&
      this.authenticationModel.userSession.activeOpenAccessSession
    ) {
      return ESubscriptionLevel.OPEN_ACCESS;
    }
    if (
      this.authenticationModel.userSession &&
      this.authenticationModel.userSession.authenticated
    ) {
      return ESubscriptionLevel.SUBSCRIBER;
    }

    return ESubscriptionLevel.UNKNOWN;
  }

  private getHitId(): string {
    this.HIT_COUNTER = this.HIT_COUNTER + 1;
    return this.HIT_COUNTER.toString();
  }

  private streamingTypeSwitch = type =>
    ({
      aod: EStreamingType.AUDIO,
      vod: EStreamingType.VIDEO,
      seededradio: EStreamingType.AUDIO,
      live: EStreamingType.AUDIO,
      vlive: EStreamingType.VIDEO,
      additionalchannels: EStreamingType.AUDIO,
      additionalchannel: EStreamingType.AUDIO,
      liveaudio: EStreamingType.AUDIO,
    }[type]);

  private contentTypeSwitch = type =>
    ({
      seededradio: EContentType.SEEDED_RADIO,
      vod: EContentType.VOD,
      aod: EContentType.AOD,
      nowplaying: EContentType.NOW_PLAYING,
      upcomingaudio: EContentType.UPCOMING_AUDIO,
      liveaudio: EContentType.LIVE_AUDIO,
      buffervideo: EContentType.BUFFER_VIDEO,
      upcomingvideo: EContentType.UPCOMING_VIDEO,
      favorites: EContentType.FAVORITES,
      livevideo: EContentType.LIVE_VIDEO,
      bufferaudio: EContentType.BUFFER_AUDIO,
      notification: EContentType.NOTIFICATION,
      satonly: EContentType.SAT_ONLY,
      additionalchannel: EContentType.ADDITIONAL_CHANNEL,
      additionalchannels: EContentType.ADDITIONAL_CHANNEL,
      channelschedule: EContentType.CHANNEL_SCHEDULE,
      showschedule: EContentType.SHOW_SCHEDULE,
      live: EContentType.LIVE_AUDIO,
      vlive: EContentType.LIVE_VIDEO,
      collection: EContentType.UNKNOWN,
      video_landing: EContentType.VOD,
      podcasts_all: EContentType.AOD,
    }[type]);

  private getContentType(type): EContentType {
    return this.contentTypeSwitch(type) || EContentType[type];
  }

  /**
   * method to get Tile Type
   * */
  private getTileType(tileContentType): ETileType {
    if (tileContentType) {
      const contentType = tileContentType.toLowerCase();
      if (
        contentType === CarouselTypeConst.CHANNEL_TILE ||
        contentType === CarouselTypeConst.LIVE ||
        contentType === CarouselTypeConst.LIVE_AUDIO ||
        contentType === CarouselTypeConst.LIVE_CONTINUE_LISTENING_TILE ||
        contentType === CarouselTypeConst.SEEDED_RADIO ||
        contentType === CarouselTypeConst.LIVE_SHOW_TILE
      ) {
        return ETileType.CHANNEL;
      }
      if (contentType === CarouselTypeConst.EPISODE_TILE) {
        return ETileType.EPISODE;
      }
      if (contentType === CarouselTypeConst.SHOW_TILE) {
        return ETileType.SHOW;
      }
      if (contentType === CarouselTypeConst.CATEGORY_TILE) {
        return ETileType.CATEGORY;
      }
      if (
        contentType === CarouselTypeConst.COLLECTION ||
        contentType === CarouselTypeConst.PAGE_TILE
      ) {
        return ETileType.COLLECTION;
      }
      if (contentType === CarouselTypeConst.VIEW_ALL_TILE.toLowerCase()) {
        return ETileType.VIEW_ALL;
      }

      return ETileType[tileContentType];
    }
  }

  private getStreamingType(type): EStreamingType {
    return this.streamingTypeSwitch(type) || EStreamingType.UNKNOWN;
  }

  /**
   * method to get Now Playing Streaming Type
   * */
  private getNPStreamingType(): EStreamingType {
    const type =
      this.nowPlayingData && this.nowPlayingData.mediaType
        ? this.nowPlayingData.mediaType
        : null;
    return this.streamingTypeSwitch(type) || SxmAnalyticsService.CLEAN;
  }

  /**
   * method to get Now Playing Tile Type
   * */
  private getNPTileType(): ETileType {
    const type =
      this.nowPlayingData && this.nowPlayingData.mediaType
        ? this.nowPlayingData.mediaType
        : null;
    const mediaTypeSwitch = type =>
      ({
        aod: ETileType.EPISODE,
        vod: ETileType.EPISODE,
        seededradio: ETileType.CHANNEL,
        live: ETileType.CHANNEL,
        vlive: ETileType.EPISODE,
        additionalchannel: ETileType.CHANNEL,
        additionalchannels: ETileType.CHANNEL,
      }[type]);

    return mediaTypeSwitch(type) || SxmAnalyticsService.CLEAN;
  }

  /**
   * method to get Now Playing Content Type
   * */
  private getNPContentType(): EContentType {
    const type =
      this.nowPlayingData && this.nowPlayingData.mediaType
        ? this.nowPlayingData.mediaType
        : null;
    return this.contentTypeSwitch(type) || SxmAnalyticsService.CLEAN;
  }

  /**
   * method to get the formatted screen names
   * */
  private getScreenName(screen: string): string {
    if (!screen || screen.length === 0) {
      return SxmAnalyticsService.CLEAN;
    }

    if (screen.includes(ScreenNames.HOME_FORYOU)) {
      return ScreenNames.HOME_FORYOU_SCREEN_NAME;
    }

    if (screen.includes(ScreenNames.RECENTS)) {
      return ScreenNames.RECENTS_SCREEN_NAME;
    }

    if (screen === ScreenNames.SEARCH) {
      return ScreenNames.SEARCH_LANDING_SCREEN_NAME;
    }

    if (screen.includes(ScreenNames.SEARCH)) {
      return ScreenNames.SEARCH_RESULT_SCREEN_NAME;
    }

    if (screen.includes(ScreenNames.HOME_MUSIC)) {
      return ScreenNames.HOME_MUSIC_SCREEN_NAME;
    }

    if (screen.includes(ScreenNames.HOME_SPORTS)) {
      return ScreenNames.HOME_SPORTS_SCREEN_NAME;
    }

    if (screen.includes(ScreenNames.HOME_NEWS)) {
      return ScreenNames.HOME_NEWS_SCREEN_NAME;
    }

    if (screen.includes(ScreenNames.HOME_ENTERTAINMENT)) {
      return ScreenNames.HOME_ENTERTAINMENT_SCREEN_NAME;
    }

    if (screen.includes(ScreenNames.HOME_HOWARD)) {
      return ScreenNames.HOME_HOWARD_SCREEN_NAME;
    }

    if (screen.includes(ScreenNames.FAVORITES_CHANNELS)) {
      return ScreenNames.FAVORITES_CHANNELS_SCREEN_NAME;
    }

    if (screen.includes(ScreenNames.FAVORITES_SHOWS)) {
      return ScreenNames.FAVORITES_SHOWS_SCREEN_NAME;
    }

    if (screen.includes(ScreenNames.FAVORITES_EPISODES)) {
      return ScreenNames.FAVORITES_EPISODES_SCREEN_NAME;
    }

    if (screen.includes(ScreenNames.NOW_PLAYING)) {
      return ScreenNames.NOW_PLAYING_SCREEN_NAME;
    }

    if (screen.includes(ScreenNames.CATEGORY_LISTING)) {
      const superCatScreen = this.getSuperCatScreenName(screen);
      const subCatScreen = screen.split('/')[3];
      return `${superCatScreen}.${
        ScreenNames.CATEGORY_LISTING_SCREEN_NAME
      } ${toTitleCase(subCatScreen)}`;
    }

    if (screen.includes(ScreenNames.ON_DEMAND_EPISODES)) {
      const channelName = screen.split('/')[2];
      return `${ScreenNames.ON_DEMAND_EPISODES_SCREEN_NAME}.${channelName}`;
    }

    if (screen.includes(ScreenNames.VIEW_ALL)) {
      return ScreenNames.VIEW_ALL_SCREEN_NAME;
    }

    if (screen.includes(ScreenNames.ALL_CHANNELS)) {
      return ScreenNames.ALL_CHANNELS_SCREEN_NAME;
    }

    if (screen.includes(ScreenNames.APPLICATION_SETTINGS)) {
      return ScreenNames.APPLICATION_SETTINGS_SCREEN_NAME;
    }

    if (screen.includes(ScreenNames.REMINDER_SETTINGS)) {
      return ScreenNames.REMINDER_SETTINGS_SCREEN_NAME;
    }

    if (screen.includes(ScreenNames.MANAGE_SHOW_REMINDERS)) {
      return ScreenNames.MANAGE_SHOW_REMINDERS_SCREEN_NAME;
    }

    if (screen.includes(ScreenNames.MESSAGING_SETTINGS)) {
      return ScreenNames.MESSAGING_SETTINGS_SCREEN_NAME;
    }

    if (screen.includes(ScreenNames.MANAGE_PANDORA_STATIONS)) {
      return ScreenNames.MANAGE_PANDORA_STATIONS_SCREEN_NAME;
    }

    if (screen.includes(ScreenNames.COLLECTION_CAROUSEL)) {
      return ScreenNames.COLLECTION_CAROUSEL_SCREEN_NAME;
    }

    if (screen.includes(ScreenNames.ENHANCED_SHOW_EDP)) {
      return ScreenNames.ENHANCED_SHOW_EDP_SCREEN_NAME;
    }

    if (screen.includes(ScreenNames.ENHANCED_CHANNEL_EDP)) {
      return ScreenNames.ENHANCED_CHANNEL_EDP_SCREEN_NAME;
    }

    if (screen.includes(ScreenNames.SHOW_REMINDER_DETAILS)) {
      return ScreenNames.SHOW_REMINDER_DETAILS_SCREEN_NAME;
    }

    if (screen.includes(ScreenNames.LOGIN)) {
      return ScreenNames.LOGIN_SCREEN_NAME;
    }

    if (screen.includes(ScreenNames.CATEGORY_PAGE)) {
      const categoryType = this.getSuperCatScreenName(screen);
      return `${ScreenNames.CATEGORY_PAGE_SCREEN_NAME}.${categoryType}`;
    }

    return screen;
  }

  /**
   * method to append screen name to the userpath
   */
  private getUserPath(
    userPath: string,
    userPathScreenPos: number,
    screen: string,
  ): string {
    if (userPath && userPathScreenPos && screen) {
      const userPathsArray = userPath.split('_');
      userPathsArray.splice(userPathScreenPos - 1, 0, screen);
      return userPathsArray.join('_');
    }
    return userPath;
  }

  /**
   * method to create super category screenName if exists in URL
   * */
  private getSuperCatScreenName(screenUrl) {
    if (screenUrl.includes(ScreenNames.MUSIC_SUPER_CATEGORY)) {
      return ScreenNames.HOME_MUSIC_SCREEN_NAME;
    }

    if (screenUrl.includes(ScreenNames.SPORTS_SUPER_CATEGORY)) {
      return ScreenNames.HOME_SPORTS_SCREEN_NAME;
    }

    if (screenUrl.includes(ScreenNames.NEWS_SUPER_CATEGORY)) {
      return ScreenNames.HOME_NEWS_SCREEN_NAME;
    }

    if (screenUrl.includes(ScreenNames.TALK_SUPER_CATEGORY)) {
      return ScreenNames.HOME_ENTERTAINMENT_SCREEN_NAME;
    }

    if (screenUrl.includes(ScreenNames.ALL_VIDEOS)) {
      return ScreenNames.ALL_VIDEOS_SCREEN_NAME;
    }

    if (screenUrl.includes(ScreenNames.PODCASTS)) {
      return ScreenNames.PODCASTS_SCREEN_NAME;
    }
  }

  /**
   * method to get the consumed percentage tag
   **/
  private getConsumedIndTag(
    bannerInd: EBannerInd,
    percentConsumed: number,
  ): EConsumedInd {
    const zeroConsumed: boolean = percentConsumed == 0;
    const unConsumed: boolean = percentConsumed <= 1;
    const started: boolean = percentConsumed >= 1 && percentConsumed <= 98;
    const complete: boolean = percentConsumed > 98;

    if (bannerInd === EBannerInd.NEW && zeroConsumed) {
      return EConsumedInd.NEW_UNCONSUMED;
    }
    if (zeroConsumed) {
      return EConsumedInd.NO_DATA_AVAILABLE;
    }
    if (unConsumed) {
      return EConsumedInd.UNCONSUMED;
    }
    if (started) {
      return EConsumedInd.STARTED;
    }
    if (complete) {
      return EConsumedInd.COMPLETE;
    }
    return SxmAnalyticsService.CLEAN;
  }

  /**
   * method to get the playhead current position
   **/
  private getCurrentPosition(): string {
    let currentPlayheadPosition: number = null;
    const defaultCurrentPosition: number = 0;

    if (this.nowPlayingData && this.nowPlayingData.playhead) {
      currentPlayheadPosition = this.nowPlayingData.playhead.currentTime
        .zuluMilliseconds;

      switch (this.nowPlayingData.mediaType) {
        case EContentType.AOD:
        case EContentType.VOD:
          if (
            this.nowPlayingData &&
            this.nowPlayingData.episode &&
            this.nowPlayingData.episode.times
          ) {
            const episodeStartTime = this.nowPlayingData.episode.times
              .zuluStartTime;
            currentPlayheadPosition =
              currentPlayheadPosition - episodeStartTime;
          }
          break;
      }
    }

    return Number.isInteger(currentPlayheadPosition)
      ? currentPlayheadPosition.toString()
      : defaultCurrentPosition.toString();
  }

  /**
   * Observe data changes on the media time line. If video is changed to audio or video stream is changed send ana log
   */
  private observeMediaTimeLineForStop(): void {
    if (this.timelineSubscription) {
      this.timelineSubscription.unsubscribe();
    }
    this.timelineSubscription = this.mediaTimeLineService.mediaTimeLine
      .pipe(pairwise())
      .subscribe(mediaTimeLinePair => {
        const prevMediaTimeLine = mediaTimeLinePair[0];
        const currMediaTimeLIne = mediaTimeLinePair[1];

        if (prevMediaTimeLine && currMediaTimeLIne) {
          const isPrevVideoMediaType = MediaUtil.isVideoMediaType(
            prevMediaTimeLine.mediaType,
          );
          const isNewMediaId: boolean =
            prevMediaTimeLine.mediaId !== currMediaTimeLIne.mediaId;
          const isNewMediaType: boolean =
            prevMediaTimeLine.mediaType !== currMediaTimeLIne.mediaType;
          if (isPrevVideoMediaType && (isNewMediaId || isNewMediaType)) {
            this.logAnalyticsTag({
              name: VideoStopAnalyticsTags.VIDEO_STOP_NAME,
              messageType: VideoStopAnalyticsTags.VIDEO_STOP_MESSAGE_TYPE,
              skipActionSource: true,
              skipContentSource: true,
              skipCurrentPosition: true,
            });
          }
        }
      });
  }

  /**
   * Observe for buffer underrun to send ana log
   */
  private observeBufferUnderRun() {
    if (this.bufferRanLowSubscription) {
      this.bufferRanLowSubscription.unsubscribe();
    }
    this.bufferRanLowSubscription = this.videoPlayerService.stalled$
      .pipe(
        filter(
          () =>
            this.videoPlayerService.playbackStateSubject.getValue() !==
            VideoPlayerConstants.SEEKING,
        ),
      )
      .subscribe(() => {
        this.logAnalyticsTag({
          name: BufferUnderRunAnalyticsTags.BUFFER_UNDER_RUN_NAME,
          messageType:
            BufferUnderRunAnalyticsTags.BUFFER_UNDER_RUN_MESSAGE_TYPE,
          skipActionSource: true,
          skipContentSource: true,
          skipCurrentPosition: true,
        });
      });
  }
}
