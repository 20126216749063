import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import {
  getChannel,
  getChannelLogoUrl,
  getAlbumImage,
  getArtistName,
  getTrackName,
  getShow,
  getIsAod,
  getIsVod,
  getEpisode,
} from '../../redux/selector/now-playing.store';
import { selectChannelLogoUrl as selectChannelLogoUrlAction } from '../../redux/action/now-playing.action';
import { Marquee } from '../marquee';
import './navigation-menu.component.scss';
import {
  ServiceFactory,
  MediaPlayerService,
  MediaPlayerConstants,
} from '../../servicelib';
import { NowPlayingStoreService } from '../../sxmservicelayer/now-playing/now-playing.store.service';
import { ttsPlayback } from '../../utils/ttsPlayback.util';
import useMiniPlayerHook from './useMiniPlayerHook';
let channelDesc: any = null;

const MiniPlayer = props => {
  const { isFocused } = props;
  const dispatch = useDispatch();
  const nowPlayingStoreService = ServiceFactory.getInstance(
    NowPlayingStoreService,
  ) as NowPlayingStoreService;

  const channel = useSelector(getChannel);
  const show = useSelector(getShow);
  const isAod = useSelector(getIsAod);
  const isVod = useSelector(getIsVod);

  useEffect(() => {
    const url = nowPlayingStoreService.setChannelLogoImageSrc(
      channel,
      show,
      isAod,
      isVod,
    );

    dispatch(selectChannelLogoUrlAction(url));
  }, [channel, show, isAod, isVod]);

  const episode = useSelector(getEpisode);
  const [emissionDay, setEmissionDay] = useState('');
  const ttsText = useMiniPlayerHook();
  useEffect(() => {
    if (isVod && episode && episode.originalIsoAirDate) {
      if (moment(episode.originalIsoAirDate).isSame(moment(), 'day')) {
        setEmissionDay('Today');
      } else {
        setEmissionDay(
          moment(episode.originalIsoAirDate).format('MMM D, YYYY'),
        );
      }
    }
  }, [isVod, episode && episode.originalIsoAirDate]);

  const mediaPlayerService = ServiceFactory.getInstance(
    MediaPlayerService,
  ) as MediaPlayerService;
  const [isPlaying, setIsPlaying] = useState(false);
  useEffect(() => {
    if (mediaPlayerService.mediaPlayer) {
      const subscription = mediaPlayerService.mediaPlayer.playbackStateSubject.subscribe(
        playbackState => {
          setIsPlaying(
            playbackState === MediaPlayerConstants.PLAY ||
              playbackState === MediaPlayerConstants.PLAYING,
          );
        },
      );

      return () => subscription.unsubscribe();
    }
  }, [mediaPlayerService.mediaPlayer]);

  const channelLogoUrl = useSelector(getChannelLogoUrl);
  const albumImageUrl = useSelector(getAlbumImage);
  const artistName = useSelector(getArtistName);
  const trackName = useSelector(getTrackName);

  const backgroundImage = !albumImageUrl
    ? `url("${channelLogoUrl}?width=120&height=96&preserveAspect=true")`
    : `url("${albumImageUrl}?width=96&height=96&preserveAspect=true")`;

  useEffect(() => {
    if (
      (isFocused && channel) ||
      (channel && channelDesc !== channel.mediumDescription)
    ) {
      channelDesc = channel.mediumDescription;
      ttsPlayback(ttsText);
    }
  }, [isFocused, channel]);

  return (
    <div className={'core-menu-now-playing'}>
      <div className="core-menu-now-playing-bars">
        <div className={`bar ${!isPlaying ? 'paused' : ''}`}></div>
        <div className={`bar ${!isPlaying ? 'paused' : ''}`}></div>
        <div className={`bar ${!isPlaying ? 'paused' : ''}`}></div>
      </div>
      <div className="core-menu-now-playing-image-container">
        <div
          className={`core-menu-now-playing-image ${
            !albumImageUrl ? 'channel-logo' : 'album-cover'
          }`}
          style={{
            backgroundImage,
          }}
        />
      </div>
      <div className="core-menu-now-playing-texts">
        <div className="core-menu-now-playing-text-channel">
          Now Playing{' '}
          {channel && channel.channelNumber
            ? `on Ch. ${channel.channelNumber}`
            : ''}
        </div>
        <Marquee
          className="core-menu-now-playing-text-artist"
          text={isVod ? episode && episode.longTitle : artistName}
          isFocused={isFocused}
          lightBackground={false}
        />
        <Marquee
          className="core-menu-now-playing-text-name"
          text={isVod ? emissionDay : trackName}
          isFocused={isFocused}
          lightBackground={false}
        />
      </div>
    </div>
  );
};

export default MiniPlayer;
