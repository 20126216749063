import { share, map } from 'rxjs/operators';
import {
  addProvider,
  HttpProvider,
  IProviderDescriptor,
  Logger,
  ServiceEndpointConstants,
} from '../index';

import * as _ from 'lodash';

import { IButtons, INotification, INotificationFeedback } from './notification';

import { Observable } from 'rxjs';
import { ApiDelegate } from '../http';
import { ModuleAreaRequest } from '../http/types/module.list.request';
import { MessagingConsts, MessageTypes } from './messaging.consts';
import { ShowReminder } from './show-reminder';

export class MessagingDelegate {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('MessagingDelegate');

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(MessagingDelegate, MessagingDelegate, [HttpProvider]);
  })();

  constructor(public httpProvider: HttpProvider) {}

  /**
   * Make notification request to the HttpProvider
   * Upon receiving response, dispatch it to the Subject
   *
   * @param url
   */
  public requestNotification(url: string): Observable<INotification[]> {
    return this.httpProvider
      .get(url)
      .pipe(map(response => this.normalizeResponse(response)));
  }

  /**
   * Normalize the API response
   * @param response - the raw response from the API
   * @returns results - an array of INotifications mapped from the raw response
   */
  public normalizeResponse(response: any): INotification[] {
    MessagingDelegate.logger.debug('normalizeResponse()');

    let responseBody: any;
    const results: Array<INotification> = [];

    if (response) {
      responseBody = _.get(response, 'notificationList', {
        notifications: [],
      });

      if (!responseBody.notifications) {
        responseBody.notifications = [];
      }

      responseBody.notifications.forEach(notification => {
        const rawButtons = _.get(notification, 'buttons', null);
        const normalizedButtons: IButtons = this.normalizeButtons(rawButtons);

        let normalizedNotification: INotification = {
          notificationKey: _.get(
            notification,
            'notificationMetadata.notificationKey',
            null,
          ),
          required: _.get(notification, 'notificationMetadata.required', false),
          inAppOnly: _.get(
            notification,
            'notificationMetadata.inAppOnly',
            false,
          ),
          immediate: _.get(
            notification,
            'notificationMetadata.immediate',
            false,
          ),
          confirmationUrl: _.get(
            notification,
            'notificationMetadata.confirmationUrl',
            '',
          ),
          bodyText: _.get(notification, 'bodyText', ''),
          outOfAppText: _.get(notification, 'outOfAppText', ''),
          icon: _.get(notification, 'icon', ''),
          titleText: _.get(notification, 'titleText', ''),
          channelName: _.get(notification, 'channelName', ''),
          buttons: normalizedButtons,
          leadKeyId: _.get(notification, 'leadKeyId', ''),
          displayed: _.get(notification, 'displayed', false),
          messageType: _.get(notification, 'messageType', ''),
          expirationDate: _.get(notification, 'expirationDate', ''),
          priority: _.get(notification, 'priority', 1),
          isMarketingMessage: true,
        };

        const messageType = normalizedNotification.messageType;
        if (
          messageType === MessageTypes.SHOW_REMINDER ||
          messageType === MessageTypes.LIVE_VIDEO_REMINDER ||
          messageType ===
            MessageTypes.SUGGESTED_LIVE_VIDEO_REMINDER_HOWARD_STERN ||
          messageType === MessageTypes.SUGGESTED_SHOW_REMINDER ||
          messageType === MessageTypes.SUGGESTED_LIVE_VIDEO_REMINDER
        ) {
          normalizedNotification = new ShowReminder(normalizedNotification);
        }

        results.push(normalizedNotification);
      });
    }
    return results;
  }

  /**
   * Turn the buttons part of the API response
   * into a structure adhering to IButtons Interface
   *
   * @param rawButtons
   */
  public normalizeButtons(rawButtons: any): IButtons {
    let keys: Array<string>;
    const normalizedButtons: IButtons = {};

    if (rawButtons) {
      keys = Object.getOwnPropertyNames(rawButtons);

      keys.map(key => {
        if (rawButtons[key] instanceof Object === false) return;

        if (rawButtons[key].actionType) {
          rawButtons[key].neriticLink = rawButtons[key].actionType
            ? rawButtons[key].actionType.trim()
            : '';
          delete rawButtons[key].actionType;
        }

        normalizedButtons[key] = rawButtons[key];

        return rawButtons[key];
      });
    }

    return normalizedButtons;
  }

  /**
   * Send GET request to the confirmationURL of the INotification
   */
  public sendConfirm(currentNotification: INotification): Observable<any> {
    MessagingDelegate.logger.debug('sendConfirm()');

    ApiDelegate.addNoResponseDataException(
      currentNotification.confirmationUrl,
      'true',
    );
    return this.httpProvider.get(currentNotification.confirmationUrl);
  }

  /**
   * Send POST request to the feedback API endpoint for notifications
   */
  public sendFeedback(options: INotificationFeedback): Observable<any> {
    MessagingDelegate.logger.debug('sendFeedback()');

    const url: string =
      ServiceEndpointConstants.endpoints.NOTIFICATION.V3_FEEDBACK_NOTIFICATION;
    ApiDelegate.addNoResponseDataException(url, 'true');

    const areaRequest = new ModuleAreaRequest(
      MessagingConsts.NOTIFICATIONS,
      MessagingConsts.FEEDBACK,
      { notificationFeedback: options },
    );

    return this.httpProvider
      .postModuleAreaRequest(url, areaRequest, null)
      .pipe(share());
  }
}
