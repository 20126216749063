import
{
    addProvider
} from "../service/providerDescriptors";
import { IProviderDescriptor } from "../service/provider.descriptor.interface";

/**
 * @MODULE:     service-lib
 * @CREATED:    7/07/20
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *      This performance util is responsible to update the performance measures.
 */

export class PerformanceUtil {

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor : IProviderDescriptor = function()
    {
        return addProvider(PerformanceUtil, PerformanceUtil, []);
    }();

    public static moments = {
        START_APP: 'START_APP',
        INIT_APP: "INIT_APP",
        STORE_INITIALIZED: 'STORE_INITIALIZED',
        APP_INITIALIZED: 'APP_INITIALIZED',
        FIRST_PAINT: 'FIRST_PAINT'
    };

    private static isFirstPaint: boolean = false;

    constructor(){}

    /**
     * @Description: update the performance measure by custom moment name.
     * @param moment(string)
     */
    public static markMoment(moment: string): void
    {
        if(performance && !PerformanceUtil.isFirstPaint)
        {
            if(moment === PerformanceUtil.moments.FIRST_PAINT)
            {
                PerformanceUtil.isFirstPaint = true;
            }
            performance.measure(moment);
        }
    }

}
