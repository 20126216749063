import {
    of as observableOf,
    combineLatest as observableCombineLatest,
    Observable
} from 'rxjs';
import { share, map, catchError } from 'rxjs/operators';
import { ApiDelegate } from "../http/api.delegate";
import { HttpProvider } from "../http/http.provider";
import { ModuleAreaRequest } from "../http/types/module.list.request";
import { Logger }                   from "../logger/logger";
import { ConsumeConsts }            from "../service/consts/api.request.consts";
import { ContentTypes }             from "../service/types/content.types";
import { ServiceEndpointConstants } from "../service/consts/service.endpoint.constants";
import { IProviderDescriptor }      from "../service/provider.descriptor.interface";
import { addProvider,
         ConfigService }            from "../index";
import { IConsumeRequest }                                   from "./consume.interface";
import { ConsumeEventConsts }       from "./consume-event.const";
import { ConsumeActionConsts }      from "./consume-action.const";

/**
 * @MODULE:     service-lib
 * @CREATED:    10/24/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *  Consume delegate is used to call the consume API that reports business intelligence analytics on media playback.
 */
export class ConsumeDelegate
{
    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("ConsumeDelegate");

    /**
     * Error message for an unknown media type.
     */
    public static ERROR_UNKNOWN_MEDIA_TYPE: string = "The media type is unknown: ";

    /**
     * Error message when an API request object can't be created.
     */
    public static ERROR_CANNOT_CREATE_REQUEST: string = "Can't create request object. Most likely due to missing required params.";

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor : IProviderDescriptor = function()
    {
        return addProvider(ConsumeDelegate,ConsumeDelegate,[HttpProvider, ConfigService]);
    }();

    /**
     * Constructor.
     * @param {HttpProvider} http
     */
    constructor(private http: HttpProvider,
                private configService: ConfigService)
    {
        // Make sure there API Delegate knows it's ok that there's no response data coming back in the response.
        // NOTE: Don't bother removing this ever as the consume response never has data coming back and that's
        // always ok. If this changes in the future we can always add the remove to the result handler below.

        ApiDelegate.addNoResponseDataException(ServiceEndpointConstants.endpoints.CONSUME.V4_ALL,
                                               ServiceEndpointConstants.endpoints.CONSUME.V4_ALL);

        ApiDelegate.addNoResponseDataException(ServiceEndpointConstants.endpoints.CONSUME.V2_ALL,
                                               ServiceEndpointConstants.endpoints.CONSUME.V2_ALL);

    }

    /**
     * Hits the consume API endpoint.
     *
     * @param {IConsumeRequest[]} consumeRequests
     * @returns {Observable<any>}
     */
    public consume(consumeRequests: IConsumeRequest[]): Observable<any>
    {
        ConsumeDelegate.logger.debug("consume()");

        const v2Url: string = ServiceEndpointConstants.endpoints.CONSUME.V2_ALL;
        const v4Url: string = ServiceEndpointConstants.endpoints.CONSUME.V4_ALL;
        const moduleType: string = this.getModuleType(consumeRequests[0].mediaType);
        let v2Params = null;
        let v4Params = null;

        try
        {
            v4Params = {
                consumeRequests: consumeRequests.map((request) =>
                {
                    let event;
                    let eventAction = request.consumeEvent.action;

                    switch (request.consumeEvent.event)
                    {
                        case ConsumeEventConsts.MARKER_START:
                        case ConsumeEventConsts.START:
                            event = ConsumeEventConsts.START;
                            break;
                        case ConsumeEventConsts.MARKER_END:
                        case ConsumeEventConsts.END:
                            event = ConsumeEventConsts.STOP;
                            break;
                        case ConsumeEventConsts.PAUSE:
                            event       = ConsumeEventConsts.STOP;
                            eventAction = ConsumeActionConsts.PAUSE;
                            break;

                        default:
                            event = request.consumeEvent.event;
                            break;
                    }

                    return {
                        consumeEvent: event,
                        consumeEventAction: eventAction,
                        consumptionInfo: request.consumptionInfo,
                        consumeDateTime: request.consumeDateTime,
                        consumeStreamDateTime: request.consumeStreamDateTime,
                        aodDownload: false,
                        offline: false
                    };
                })
            };

            v2Params = {
                consumeRequests: consumeRequests.map((request) =>
                {
                    return {
                        consumeEvent         : request.consumeEvent.event,
                        consumeEventAction   : request.consumeEvent.action,
                        consumptionInfo      : request.consumptionInfo,
                        consumeDateTime      : request.consumeDateTime,
                        consumeStreamDateTime: request.consumeStreamDateTime,
                        aodDownload          : false,
                        offline              : false
                    };
                 })
            };
        }
        catch (error)
        {
            ConsumeDelegate.logger.warn(`consume( Cannot call consume API most likely due to missing required params: ${error} )`);
            throw new Error(ConsumeDelegate.ERROR_CANNOT_CREATE_REQUEST);
        }

        const v4Request: ModuleAreaRequest = new ModuleAreaRequest(ConsumeConsts.MODULE_AREA, moduleType, v4Params);
        const v2Request: ModuleAreaRequest = new ModuleAreaRequest(ConsumeConsts.MODULE_AREA, moduleType, v2Params);

        const v4Obs$: Observable<boolean> = this.configService.consumeConfiguration.enableV4Consume ?
                                              this.http.postModuleAreaRequest(v4Url, v4Request, null)
                                                .pipe( map(() => true),
                                                       catchError(onV4Fault))
                                              : observableOf(true);

        const v2Obs$: Observable<boolean> =  this.http.postModuleAreaRequest(v2Url, v2Request, null).pipe(
                                                                                  map(() => true),
                                                                                  catchError(onV2Fault));


        return observableCombineLatest(
            v4Obs$, v2Obs$,
            (v4Obs: boolean, v2Obs: boolean) => v4Obs && v2Obs).pipe(
            share());

        function onV4Fault(error): Observable<any>
        {
            ConsumeDelegate.logger.error(`consume( V4 Consume call error ${JSON.stringify(error)} )`);

            return observableOf(false);
        }

        function onV2Fault(error): Observable<any>
        {
            ConsumeDelegate.logger.error(`consume( V2 Consume call error ${JSON.stringify(error)} )`);

            return observableOf(false);
        }
    }

    /**
     * Returns the module type for the consume request object that matches the current playing media type.
     */
    private getModuleType(mediaType: string): string
    {
        ConsumeDelegate.logger.debug(`getModuleType()`);

        switch (mediaType.toLowerCase())
        {
            case ContentTypes.LIVE_AUDIO.toLowerCase():
                return ConsumeConsts.MODULE_TYPE_LIVE;

            case ContentTypes.AOD.toLowerCase():
                return ConsumeConsts.MODULE_TYPE_AOD;

            case ContentTypes.LIVE_VIDEO.toLowerCase():
                return ConsumeConsts.MODULE_TYPE_VLIVE;

            case ContentTypes.VOD.toLowerCase():
                return ConsumeConsts.MODULE_TYPE_VOD;

            case ContentTypes.ADDITIONAL_CHANNELS.toLowerCase():
                return ConsumeConsts.MODULE_TYPE_AIC;

            case ContentTypes.SEEDED_RADIO.toLowerCase():
                return ConsumeConsts.MODULE_TYPE_SEEDED;

            case ContentTypes.PODCAST.toLowerCase():
                return ConsumeConsts.MODULE_TYPE_PODCAST;

            default:
                ConsumeDelegate.logger.debug(`getModuleType( Unknown media type: ${mediaType} )`);
                throw new Error(ConsumeDelegate.ERROR_UNKNOWN_MEDIA_TYPE + mediaType);
        }
    }
}
