export class PlayerConfig {
  public id = '';
  public keyPath = '';
  public getTokenStrategy = null;
  public getTokenizedUrl = null;
  public refreshPlayerBuffer = false;
  public playbackType = '';
  public connectivityUrl = '';
  public startTime = 0;
  public autoPlay = false;
  public tuneStart = false;
  public crossFadeEnabled = false;
  public crossfade = {};
  public duration = 0;
  public FAILOVER_RETRY_LIMIT = null;
  public higherQualityAudio = false;
  public maxQualityAudio = false;
  public lockInMaximumBitrate = false;
  public hlsProxyEnabled = false;
  public loggerEnabled = false;
  public primaryAudioURL = '';
  public cuePoints = [];
  public audioURLs = [];
  public audioFirstURLs = [];
  public hlsConsumptionInfo = '';
  public channelId = '';
  public mediaType = '';
  public assetGuid = '';
  public useMediaElement = true;
}
