export interface ICreativeArtsItem
{
    type: string;
}

export class CreativeArtsTypes
{
    public static IMAGE = "IMAGE";
    public static BIO   = "BIO";
}
