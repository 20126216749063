export class AffinityConstants
{
    public static LIKE = "LIKE";
    public static DISLIKE = "DISLIKE";
    public static NEUTRAL = "NEUTRAL";
}
export class AffinityEventTypes
{
    public static UPDATE: string = "UPDATE";
}

export class AffinityConstMapping
{
    public static LIKE = 1;
    public static DISLIKE = -1;
    public static NEUTRAL = 0;
}

export type AffinityType = "LIKE" | "DISLIKE" | "NEUTRAL" | "";
