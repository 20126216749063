/* eslint-disable */
import * as _ from 'lodash';

import { IAppConfig } from '../servicelib';
import { getServiceConfig } from './service.config.utils';
import { PrivateBrowsingUtil } from '../utils/privateBrowsing.util';
import { appRouteConstants } from '../routing/app.route.constants';

/**
 * Return an app config object for the web client.  This method creates a bespoke object that contains the necessary
 * client side configuration data (some observable bases) so that the service layer can configure itself properly based
 * on the client
 *
 * @returns {IAppConfig}
 */
export function getWebServiceConfig(): IAppConfig {
  const webServiceConfig = getServiceConfig();

  webServiceConfig.inPrivateBrowsingMode = PrivateBrowsingUtil.checkPrivateBrowsingMode(
    window,
  ) as any;
  webServiceConfig.nuDetect = getNuDetectInfo();
  //webServiceConfig.loginRequired =
  //  location.pathname.indexOf(appRouteConstants.AUTH.LOGIN) >= 0;

  return webServiceConfig;
}

function getNuDetectInfo() {
  return {
    api: _.get(window, 'ndsapi', null),
    ndsReady: false,
    beginBehavioralMonitoring: beginBehavioralMonitoring,
    stopBehavioralMonitoring: stopBehavioralMonitoring,
    requestCall: requestCall,
    getHiddenValue: function() {
      const element = <HTMLInputElement>(
        document.getElementsByName('nds-pmd')[0]
      ) || { value: '' };
      return element.value;
    },
    sessionId: '',
  };

  function beginBehavioralMonitoring() {
    const callback = () => {
      this.api.clear();
      this.api.bindNewFields();
    };

    this.requestCall(callback);
  }

  function stopBehavioralMonitoring() {
    const callback = () => {
      this.api.stop();
    };

    this.requestCall(callback);
  }

  function requestCall(callback) {
    if (this.ndsReady) {
      callback();
    } else {
      this.ndsReadyCallback = callback;
    }
  }
}
