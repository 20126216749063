import { IAppStore, IRecentlyPlayedStore } from '../../redux/selector';
import {
  CarouselService,
  ICarouselDataByType,
  ITile,
  RecentlyPlayedService,
  IProviderDescriptor,
  addProvider,
} from '../../servicelib';
import { CarouselStoreService } from '../carousel-store/carousel.store.service';
import { Observable } from 'rxjs';

export class RecentlyPlayedStoreService {
  /**
   * List of recently played list wrapped in observables from the NGRX recently played store.
   */
  public recentlyPlayed: Observable<IRecentlyPlayedStore>;
  //public recents: Store<ICarouselDataByType>;
  public recents: any;

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(RecentlyPlayedStoreService, RecentlyPlayedStoreService, [
      RecentlyPlayedService,
      //private store: Store<IAppStore>,
      CarouselStoreService,
      CarouselService,
    ]);
  })();

  /**
   * Creates an instance of RecentlyPlayedStoreService
   *
   * Sets up a subscriber to servicelib recentlyPlayedService. When the API returns a new list of recently played items,
   * it dispatches an even to the store in order to update the store with the list of recently played items.
   * @param {RecentlyPlayedService} recentlyPlayedService
   * @param {Store<IAppStore>} store
   * @param {CarouselStoreService} carouselStoreService
   * @param {CarouselService} carouselService
   */
  constructor(
    private recentlyPlayedService: RecentlyPlayedService,
    //private store: Store<IAppStore>,
    private carouselStoreService: CarouselStoreService,
    private carouselService: CarouselService,
  ) {
    //this.recentlyPlayed = this.store.select(state => state.recentlyPlayedStore);
  }

  /**
   * Removes all the recently played data
   * @returns {Observable<boolean>}
   */
  public removeAllRecentlyPlayed() {
    this.recentlyPlayedService
      .removeAllRecentlyPlayed()
      .subscribe((response: boolean) => {
        if (response) {
          this.carouselStoreService.selectRecents();
        }
      });
  }

  /**
   * Removes a recently played item from the recently played data
   * @param {ITile} tile
   */
  public removeRecentlyPlayedItem(tile: ITile) {
    if (!tile.tileAssetInfo.recentPlayGuid) {
      return;
    }

    this.recentlyPlayedService
      .removeRecentlyPlayedItem(
        tile.tileAssetInfo.recentPlayGuid,
        tile.tileAssetInfo.recentPlayAssetGuid,
        tile.tileAssetInfo.recentPlayType,
      )
      .subscribe((response: boolean) => {
        if (response) {
          this.carouselStoreService.selectRecents();
        }
      });
  }
}
