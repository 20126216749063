import { IProviderDescriptor, addProvider } from '../service';
import { StorageKeyConstant } from './storage.constants';
import { storageManager } from '../../../config/storage-manager';

/**
 * @MODULE:     service-lib
 * @CREATED:    07/19/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *       StorageService is  wrapper on top of localStorage. Service can handle Add/Get/Remove/RemoveAll items from
 *       localStorage.
 */

export class StorageService {
  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(StorageService, StorageService);
  })();

  private cloudCookieManager: any;

  constructor() {
    this.cloudCookieManager = storageManager.cloudCookieManager;
  }

  /**
   * Getter for username which gets from localStorage
   * @returns {string}
   */
  get username(): string {
    return this.cloudCookieManager.get(StorageKeyConstant.USER);
  }

  /**
   * Gets an item from localStorage, returns a default value if the item is not present, or null if no default
   * value is specified
   *
   * @param key - StorageKeyConstant is refers part of the key in localStorage
   * @param defaultVal - (optional) default value to return if there is not item with the given key in local storage
   * @returns string value which gets from localStorage
   */
  public getItem(
    key: StorageKeyConstant,
    defaultVal: string = undefined,
  ): string {
    const item = this.cloudCookieManager.get(this.getFullKey(key));

    return item ? item : defaultVal;
  }

  /**
   * get the parsed data of an item in localStorage
   * @param key - StorageKeyConstant is refers part of the key in localStorage
   */
  public getParsedItem(key: StorageKeyConstant): any {
    const item = this.getItem(key);

    try {
      return JSON.parse(item);
    } catch (e) {}

    return item;
  }

  /**
   * Clears all keys from localStorage
   * To avoid, to resume the previous signedin user's last session info, we have to clear the storage,except username
   * & clientDeviceId username to prepoluate the username
   */
  public clearAll(): void {
    const clientDeviceId =
      this.getItem(StorageKeyConstant.CLIENT_DEVICE_ID) || null;
    const localUserName = this.getItem(StorageKeyConstant.USERNAME) || null;
    const appRegion = this.getItem(StorageKeyConstant.APP_REGION) || null;
    const autoplayonStartup =
      this.getItem(StorageKeyConstant.AUTOPLAY_ON_STARTUP) || null;
    const freeTrialDurationText =
      this.getItem(StorageKeyConstant.FREE_TRIAL_DURATION_TEXT) || null;
    const platinumPlanText =
      this.getItem(StorageKeyConstant.PLATINUM_PLAN_TEXT) || null;

    //Clear all the store data and re-assign the clientDeviceId, appRegion, autoplayOnStartup and username
    this.cloudCookieManager.clear();
    this.setItem(StorageKeyConstant.CLIENT_DEVICE_ID, clientDeviceId);
    this.setItem(StorageKeyConstant.USERNAME, localUserName);
    this.setItem(StorageKeyConstant.APP_REGION, appRegion);
    this.setItem(StorageKeyConstant.AUTOPLAY_ON_STARTUP, autoplayonStartup);
    this.setItem(
      StorageKeyConstant.FREE_TRIAL_DURATION_TEXT,
      freeTrialDurationText,
    );
    this.setItem(StorageKeyConstant.PLATINUM_PLAN_TEXT, platinumPlanText);
  }

  /**
   * Insert an item to localStorage
   *
   * @param key - StorageKeyConstant is refers part of the key in localStorage
   */
  public setItem(key: StorageKeyConstant, value: any) {
    this.cloudCookieManager.set(this.getFullKey(key), value);
  }

  /**
   * Removes an item from localStorage
   * Note: If key is USER then no need to append username to the key
   * @param  key - StorageKeyConstant is refers key in localStorage
   */

  public removeItem(key: StorageKeyConstant): void {
    if (key === StorageKeyConstant.USER) {
      this.cloudCookieManager.unset(key.toString());
    } else {
      this.cloudCookieManager.unset(this.getFullKey(key));
    }
  }

  /**
   *
   * @param  key - StorageKeyConstant is refers key in localStorage
   * @returns String - Appends username to the key which will save to localStorage
   */
  private getFullKey(key: StorageKeyConstant): string {
    return (this.username || '') + key.toString();
  }
}
