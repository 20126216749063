import { BehaviorSubject, Observable } from 'rxjs';
import { ToastData, ToastInformation } from '../../types/toast.interface';
import { IProviderDescriptor, addProvider } from '../../servicelib';

export class ToastService {
  /**
   * Subject to store the data of the toast overlay component
   * @type {ToastInformation}
   */
  public toastInformationSubject: BehaviorSubject<ToastInformation>;
  public toastInformation: Observable<ToastInformation> = null;

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(ToastService, ToastService, []);
  })();

  constructor() {
    this.toastInformationSubject = new BehaviorSubject({
      isOpen: false,
      data: null,
    } as ToastInformation);

    this.toastInformation = this.toastInformationSubject;
  }

  /**
   * Used to open the toast overlay component.
   */
  public open(toastData: ToastData): void {
    this.toastInformationSubject.next({
      isOpen: true,
      data: toastData,
    });
  }

  /**
   * Used to close the toast overlay component.
   * If it's called with toast data, then
   * only close it if the error type matches
   * the error type of the toast that is currently up.
   */
  public close(toastData?: ToastData): void {
    const currentToastData = this.toastInformationSubject.getValue().data;
    if (
      toastData &&
      currentToastData &&
      toastData.errorType !== currentToastData.errorType
    ) {
      return;
    }
    this.toastInformationSubject.next({
      isOpen: false,
      data: null,
    });
  }
}
