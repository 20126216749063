import { map } from 'rxjs/operators';
import { Observable } from "rxjs";
import {
    IProviderDescriptor,
    addProvider
}                     from "../../service";
import {
    ICarouselData,
    ICarouselDataByType
}                     from "../carousel.interface";
import
{
    IGroupedFavorites
}                     from "../../favorite";
import { ITile }      from "../raw.tile.interface";
import { CarouselTypeConst }                         from "../index";
import {ICarouselSelector, ICarouselSelectorSegment} from "../carousel.interface";

export class FavoriteCarouselNormalizer
{
    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(FavoriteCarouselNormalizer, FavoriteCarouselNormalizer, []);
    }();

    constructor(){}

    /**
     * Normalizes favorite tiles and groups them by asset type
     *
     * @static
     * @param {IRawFavoriteCarouselResponse} response
     * @returns {IGroupedFavorites}
     * @memberof V2CarouselNormalizer
     */
    public static normalizeFavoritesCarousel(response: ICarouselDataByType): IGroupedFavorites
    {
        const defaultGroupedFavs: IGroupedFavorites = { channels: [], shows: [], episodes: [] };

        const favorites = response.selectors.find((selector : ICarouselSelector) =>
            selector.class === CarouselTypeConst.CONTENT_TOGGLE_SELECTOR);

        if (!favorites) return defaultGroupedFavs;

        return favorites.segments.reduce(favoritesCollector,defaultGroupedFavs as IGroupedFavorites);

        /**
         * Will collect tiles from the different selector carousels in a segment and add them into an array
         * in groupedFavs hashed by the segment title
         *
         * @param {IGroupedFavorites} groupedFavs is the groupedFavs object that tiles are hashed into
         * @param {ICarouselSelectorSegment} segment is the current segment being processed
         * @returns {IGroupedFavorites} new groupedFavs with any additional tiles found concatted into array if tiles
         *                              hashed by segment name
         */
        function favoritesCollector(groupedFavs : IGroupedFavorites, segment : ICarouselSelectorSegment)
        {
            const hashName = segment.title.toLowerCase();
            groupedFavs[hashName] = [];

            segment.carousels.forEach((carousel: ICarouselData) =>
                carousel.tiles.forEach((tile: ITile) =>
                {
                    groupedFavs[ hashName ].push(tile);
                }));

            groupedFavs[hashName].sort((a, b) => a.tabSortOrder - b.tabSortOrder);

            return groupedFavs;
        }
    }
}
