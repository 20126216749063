import {
    addProvider,
    AppErrorCodes,
    IProviderDescriptor,
    Logger
} from "../index";

import { HttpCodes }                from "../service/consts/http.codes";
import { ConnectivityDelegate }     from "./connectivity.delegate";
import { ServiceEndpointConstants } from "../service/consts/service.endpoint.constants";
import { ErrorService }             from "../error/error.service";
import { EError }                   from "../error/error.enum";
import { HttpProvider }             from "../http";
import { BehaviorSubject }                  from "rxjs";
import { filter } from 'rxjs/operators';

export class ConnectivityService
{
    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("ConnectivityService");

    private static providerDescriptor : IProviderDescriptor = function()
    {
        return addProvider(
            ConnectivityService,
            ConnectivityService,
            [ConnectivityDelegate, ErrorService, HttpProvider]
        );
    }();

    private isNoConnection: boolean;

    public isNoConnectionSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(public connectivityDelegate: ConnectivityDelegate,
                public errorService: ErrorService,
                httpProvider : HttpProvider)
    {
        this.isNoConnection = false;
        this.isNoConnectionSubject.next(this.isNoConnection);
        this.connectivityDelegate.setHttpProvider(httpProvider);

        this.errorService.errorInformation
            .pipe(filter((info: any) =>
            {
                return info.errorType === EError.NO_IP_CONNECTION && info.isInErrorState;
            }))
            .subscribe(() =>
            {
                this.connectivityDelegate.startRequesting();
            });

        httpProvider.onNetworkError.subscribe((networkError) =>
        {
           this.checkFailure(networkError.code, networkError.url);
        });

        httpProvider.onNetworkSuccess.subscribe(() => { this.checkSuccess(); });
    }

    public checkFailure(httpStatus, url): void
    {
        try
        {
            if (httpStatus != HttpCodes.NETWORK_TIMEOUT && this.isIgnorableResponse(url)) { return; }

            if (this.isConnectivity(url))
            {
                this.isNoConnection = true;
                this.isNoConnectionSubject.next(this.isNoConnection);
                this.errorService.handleError({ type: AppErrorCodes.FLTT_NO_IP_CONNECTION });  // pop toast
            }
            this.connectivityDelegate.startRequesting();
        }
        catch (error)
        {
            ConnectivityService.logger.error("checkFailure Exception:", error);
        }
    }

    public checkSuccess()
    {
        try
        {
            this.connectivityDelegate.stopRequesting();
            if(this.isNoConnection)
            {
                //Only clear Error if the state changes from No_connection --> Connection
                // we shouldn't clear if we are already Connected and cleared toaster once
                this.isNoConnection = false;
                this.isNoConnectionSubject.next(this.isNoConnection);
                this.errorService.clearError({ type: AppErrorCodes.FLTT_NO_IP_CONNECTION });
            }
        }
        catch (error)
        {
            ConnectivityService.logger.error("checkSuccess Exception:", error);
        }
    }

    public isIgnorableResponse(url): boolean
    {
        return url.indexOf('geolocation') >= 0
        || url.indexOf('consume') >= 0;
    }

    public isConnectivity(url)
    {
        return url === ServiceEndpointConstants.NETWORK_CONNECTIVITY;
    }
}
