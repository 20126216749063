export class ConsumeEventConsts
{
    public static START: string = "start";
    public static END: string = "end";
    public static STOP: string = "stop";
    public static MARKER_START: string = "markerStart";
    public static MARKER_END: string = "markerEnd";
    public static PAUSE : string = "pause";
    public static UPDATE: string = "update";
    public static UNKNOWN: string = "unknown";
}
