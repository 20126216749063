type ItemMetaParser<ItemDataType, ItemExtraData> = {
  data: ItemDataType[];
  getItemData?: (
    item: ItemDataType,
  ) => ItemDataType;
  pageId: string;
  swimlaneId: string;
  title: string;
  itemWrapperClassName?: string;
  category?: string;
  isGrid?: boolean;
  itemExtraData?: ItemExtraData;
  customOnClick?: (data: any) => void;
  onItemFocus: (index: number) => any;
  onItemBlur: any;
  restoreFocusTrail: any;
  component: React.ComponentType;
};

export const composeItemId = (swimlaneId: string, index: number) =>
  `${swimlaneId}-ITEM-${index}`;

export const getItemsProps = <ItemDataType, ItemExtraData>({
  data,
  getItemData,
  pageId,
  swimlaneId,
  title,
  category,
  itemWrapperClassName,
  isGrid = false,
  itemExtraData,
  customOnClick,
  onItemFocus,
  onItemBlur,
  restoreFocusTrail,
  component,
}: ItemMetaParser<ItemDataType, ItemExtraData>): SwimlaneItemWrapperProps<
  ItemDataType | ItemExtraData
>[] => {

  return data.map((item, i) => {
    const isFirstItem = i === 0;
    const isLastItem = i === data.length - 1;
    const id = composeItemId(swimlaneId, i);
    const itemData = getItemData ? getItemData(item) : item;
    const nav = {
      id,
      parent: swimlaneId,
      ...(!isFirstItem && { nextleft: composeItemId(swimlaneId, i - 1) }),
      ...(!isLastItem && { nextright: composeItemId(swimlaneId, i + 1) }),
      internal: {
        nextleft: () => {
          onItemBlur(id);
        },
        nextright: () => {
          onItemBlur(id);
        },
        nextup: () => {
          restoreFocusTrail('nextup');
        },
        nextdown: () => {
          restoreFocusTrail('nextdown');
        },
      },
    };

    return {
      nav,
      swimlaneId,
      pageId,
      onItemFocus,
      customOnClick,
      component: component,
      category,
      isGrid,
      title,
      key: id,
      index: i,
      className: itemWrapperClassName,
      data: {
        ...itemData,
        ...itemExtraData,
      },
    };
  });
};
