import * as _                                   from "lodash";
import { IMediaEpisode, IMediaItem, IMediaSegment } from "../tune/tune.interface";
import { IMediaCut }                            from "../tune/tune.interface";
import { IAlbumImage, IImage, POSTER_TILE_IMAGE_NAME } from "../service/types/image.interface";
import { CreativeArtsTypes, ICreativeArtsItem } from "../service/types/creative.arts.item";
import { IMarkerList }                          from "../service/types/marker.types";

/**
 * Finds a requested MediaItem by timestamp.
 *
 * As a rule we'll say we've found the MediaItem when the given timestamp is between a given cut's start timestamp
 * and the next cut's start timestamp - 1 millisecond. This is b/c it's all too possible to miss a requested cut due
 * to bad timestamp data from the API. The "-1" from the next item's start time is to make sure our search doesn't
 * create overlaps, so we're truly always searching the start and end time boundaries for each item and not using
 * the start time of the next item -- this was biting us badly and desperately needed to be fixed.
 *
 * It's also possible that the next item can't be found so we'll use the current item's end timestamp.
 *
 * If no cut found, either use the first cut or the last cut...we'll use the first cut if if the timestamp is
 * earlier than the very first, otherwise we'll use the last cut.
 *
 * Finally, the last timestamp is often zero so if no cut is found we'll grab the latest cut, aka the last one
 * in the list.
 *
 * @param {number} timestamp
 * @param {Array<IMediaItem>} collection
 * @param {boolean} clamp is optional.  If true (the default) if a marker for the given time cannot be found, then
 *        return either the first or the last available marker, depending on whether the time given is less than
 *        the time in the first available marker.
 * @returns {IMediaCut}
 */

export function findMediaItemByTimestamp(timestamp: number, collection: Array<IMediaItem>, clamp = true): IMediaItem
{
    if (!_.isEmpty(collection))
    {
        let findMediaItem: any = (item: IMediaItem, index: number, collection: Array<any>): boolean =>
        {
            const startTime: number = item.times && item.times.zuluStartTime ? item.times.zuluStartTime : 0;
            const endTime: number   = getEndTime();

            return (timestamp >= startTime) && (!endTime || timestamp <= endTime);

            function getEndTime(): number
            {
                const nextItem: IMediaEpisode = collection[ index + 1 ] as IMediaEpisode;
                const zuluEndTime: number     = item.times && item.times.zuluEndTime ? item.times.zuluEndTime : 0;
                return nextItem && nextItem.times && nextItem.times.zuluStartTime ? nextItem.times.zuluStartTime - 1
                    : zuluEndTime;
            }
        };

        let mediaItem = _.find(collection, findMediaItem);

        if (!mediaItem && clamp === true)
        {
            const firstMediaItem = _.first(collection);
            const lastMediaItem  = _.last(collection);
            mediaItem            = (timestamp <= firstMediaItem.times.zuluStartTime) ? firstMediaItem : lastMediaItem;
        }

        return mediaItem;
    }
    return null;
}

/**
 * For some content (AOD/VOD), the number of segments and the number of cuts are the same.  In this case
 * we can use the cuts to populate the segments.
 *
 * In other cases (live) the segments and cuts do not line up and the segment titles are usually empty
 *
 * In both case we use the cuts and synthesize segments from those cuts.
 */

export function normalizeEpisodeSegments(episode:IMediaEpisode):IMediaEpisode
{

    episode.segments =
        episode.segments.reduce( ( accumulator : Array<IMediaSegment>, segment : IMediaSegment ) : Array<IMediaSegment> =>
        {
            if ((segment.duration !== 0 && segment.times.zuluEndTime > episode.times.zuluStartTime) || !segment.times.zuluEndTime)
            {
                if (segment.times.zuluStartTime < episode.times.zuluStartTime)
                {
                    segment.times.zuluStartTime = episode.times.zuluStartTime;
                    segment.times.isoStartTime = new Date(segment.times.zuluStartTime).toISOString();

                    if(segment.times.zuluEndTime)
                    {
                        segment.duration = (segment.times.zuluEndTime - segment.times.zuluStartTime) / 1000;
                    }
                }
                if (segment.times.zuluEndTime && segment.times.zuluEndTime >= episode.times.zuluEndTime)
                {
                    segment.times.zuluEndTime = episode.times.zuluEndTime - 1;
                    segment.times.isoEndTime = new Date(segment.times.zuluEndTime).toISOString();
                    segment.duration = (segment.times.zuluEndTime - segment.times.zuluStartTime) / 1000;
                }

                accumulator.push(segment);
            }

            return accumulator;
        },[] );

    /**
     * Make sure the first segment starts at the beginning of the episode and the last segment ends at the
     * end of the episode
     */
    if (episode.segments.length > 0)
    {
        const firstSegment = episode.segments[0];
        const lastSegment = episode.segments[episode.segments.length - 1];

        firstSegment.times.zuluStartTime = episode.times.zuluStartTime;
        firstSegment.times.isoStartTime  = new Date( episode.times.zuluStartTime ).toISOString();

        firstSegment.duration = (firstSegment.times.zuluEndTime - firstSegment.times.zuluStartTime) / 1000;

        lastSegment.times.zuluEndTime = episode.times.zuluEndTime;
        lastSegment.times.isoEndTime  = new Date( episode.times.zuluEndTime ).toISOString();

        lastSegment.duration = (lastSegment.times.zuluEndTime - lastSegment.times.zuluStartTime) / 1000;
    }
    return episode;
}

/**
 * Returns the artist name for a given cut.
 *
 * @param {IMediaCut} cut
 * @returns {string}
 */
export function getArtistNameForCut(cut: IMediaCut): string
{
    return _.get(cut, "artists[0].name", "") as string;
}

/**
 * Returns the poster image.
 * @param episode
 */
export function getPosterImageForEpisode(episode: IMediaEpisode): string
{
    const posterImage: IImage = episode.images ? episode.images.find(image => image.name === POSTER_TILE_IMAGE_NAME) : null;
    return posterImage ? posterImage.url : "";
}

/**
 * Returns the album name for a given cut.
 *
 * @param {IMediaCut} cut - The cut that contains an album name.
 * @returns {string}
 */
export function getAlbumNameForCut(cut: IMediaCut): string
{
    return _.get(cut, "album.title", "") as string;
}

/**
 * Returns the album image URL for a given cut.
 *
 * @param {IMediaCut} cut - The cut that contains an album image.
 * @param {string} size - The requested image size.
 * @returns {string}
 */
export function getAlbumArtForCut(cut: IMediaCut, size: string = "medium"): string
{
    const creativeArts: Array<IAlbumImage> = _.get(cut, "album.creativeArts", []) as Array<IAlbumImage>;
    const albums: Array<IAlbumImage>       = creativeArts.filter((item: IAlbumImage) => item.type.toLowerCase() === "image");
    const album: IAlbumImage               = _.find(albums, findAlbumArt) as IAlbumImage || null;
    return album ? album.url : "";

    function findAlbumArt(item: ICreativeArtsItem)
    {
        if (item.type === CreativeArtsTypes.IMAGE)
        {
            const artwork     = item as IAlbumImage;
            const artworkSize = artwork.size ? artwork.size : "";
            return (size.toLowerCase() === artworkSize.toLowerCase());
        }
    }
}

/**
 * Returns the markerlist for the given marker layer within markers
 *
 * @param {Array<>} markers array of marker arrays by layer
 * @param {string} layer of markers that we want to extract from markers
 * @returns {Array<any>} given maker layer, or empty array if layer is not present
 */
export function getMarkers(markers : Array<IMarkerList>,layer : string) : Array<any>
{
    const markerList : IMarkerList = _.find( markers, ( markers : IMarkerList ) => markers.layer === layer );
    return _.get( markerList, "markers", [] ) as Array<any>;
}
