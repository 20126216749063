import { filter } from 'rxjs/operators';
/*import { Injectable } from '@angular/core';
import {
  Event,
  NavigationEnd,
  NavigationExtras,
  Router,
} from '@angular/router';
import { Location } from '@angular/common';*/
import { IProviderDescriptor, addProvider } from '../../servicelib';
import { appRouteConstants } from '../../routing/app.route.constants';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';

export class NavigationService {
  /**
   * Holds the active route details
   */
  //public activeRoute: any = null;

  /**
   * Holds the Previous route details
   */
  //public previousRoute: NavigationEnd = null;
  public previousRoute: any = null;

  /**
   * Holds the list of navigation Everest has done
   */
  //public navigationHistory: Array<NavigationEnd> = [];
  public navigationHistory: any = [];

  /**
   * Behavior Subject holding router url.
   */
  public routerEventUrl: BehaviorSubject<string> = new BehaviorSubject('');

  public history: any;

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(NavigationService, NavigationService, []);
  })();

  /*constructor(private router: Router, private location: Location) {
    this.router.events
      .pipe(
        filter(
          (event: Event) =>
            event instanceof NavigationEnd && event.hasOwnProperty('id'),
        ),
      )
      .subscribe((event: NavigationEnd) => {
        const previousId = this.activeRoute ? this.activeRoute.id : null;
        if (event['id'] && event['id'] !== previousId) {
          this.previousRoute = this.navigationHistory[
            this.navigationHistory.length - 1
          ];
          this.navigationHistory.push(event);
          this.activeRoute = event;
        }

        this.routerEventUrl.next(event.url);
      });
  }*/
  constructor() {}

  /**
   * Wraps Angular's Router.navigate while providing default options.
   *
   * https://angular.io/api/router/Router#navigate
   *
   * Returns a promise that:
   *     resolves to 'true' when navigation succeeds,
   *     resolves to 'false' when navigation fails,
   *     is rejected when an error happens
   */
  /*public go(commands: Array<any>, extras?: NavigationExtras): Promise<boolean> {
    const _extras: NavigationExtras = {
      // set default options here
      queryParamsHandling: 'preserve',

      // default options can be optionally overridden and extended
      ...extras,
    };

    return this.router.navigate(commands, _extras);
  }*/

  public go(commands: Array<any>, extras?: any): Promise<boolean> {
    const seperator = extras === '' ? extras : '/'
    this.history.push(commands.join(seperator));

    return Promise.resolve(true);
  }

  /**
   * Goes back to previous screen
   */
  /*public goBack(skipPageCount?: number): void {
    const index = this.navigationHistory.findIndex(
      item => item.id === this.activeRoute.id,
    );
    const event = this.navigationHistory[index - 1];

    const url = _.get(event, 'url', '') || '';
    const isWelcomeRoute = url.indexOf(appRouteConstants.WELCOME) !== -1;
    const isLoginRoute = url.indexOf(appRouteConstants.AUTH.LOGIN) !== -1;

    if (!!url && !isWelcomeRoute && !isLoginRoute) {
      window.history.go(-1 * (skipPageCount || 1));
    } else {
      this.router.navigate([appRouteConstants.HOME], {
        queryParamsHandling: 'preserve',
      });
    }
  }*/

  public goBack(skipPageCount?: number): void {}

  get activeRoute(): any {
    return {
      url: this.history.location.pathname,
    };
  }
}
