import { IHttpResponse }      from "./types/http.types";
import { INetworkError }      from "./types/http.types";
import { HTTP_ERROR_MESSAGE } from "../service/consts";
import { HttpCodes }          from "../service/consts";

/**
 * @MODULE:     service-lib
 * @CREATED:    07/24/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * http.provider.request.interceptor provides a request interceptor that will add in SiriusXM common API protocol
 * elements to a request
 */

export interface IHttpHeaderDelegate
{
    handleHttpHeader(headerName : string, header : any);
}

/**
 * HttpDelegate provides static methods for handling HTTP responses and headers from the SiriusXM API
 */
export class HttpDelegate
{
    public static getHTTPError(response: IHttpResponse) : INetworkError
    {
        const statusCode = response && response.status ? response.status : HttpCodes.NETWORK_TIMEOUT;

        return {
            message         : HTTP_ERROR_MESSAGE,
            code            : statusCode,
            url             : response.config.url,
            detailedMessage : HTTP_ERROR_MESSAGE + ` status = ${statusCode}`,
            modules         : null
        };
    }

    /**
     * Check the HTTP response code and throw an exception if it was not a HTTP success code
     * @param response
     */
    public static checkHTTPResponse(response: IHttpResponse)
    {
        if (!response || response.status !== HttpCodes.OK)
        {
            throw HttpDelegate.getHTTPError(response);
        }
    }

    /**
     * Check the http headers from an HTTP response and handle appropriately
     * @param response is the HTTP response
     * @param httpHeaderDelegate is used to process any http headers that may be received
     */
    public static checkHTTPHeaders(response: IHttpResponse, httpHeaderDelegate : IHttpHeaderDelegate)
    {
        if (response.headers)
        {
            Object.keys(response.headers)
                .forEach((key) =>
                {
                    httpHeaderDelegate.handleHttpHeader(key, response.headers[ key ]);
                });
        }
    }
}
