import { Observable, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';

import {
  IProviderDescriptor,
  addProvider,
  Logger,
  relativeUrlToAbsoluteUrl,
  IRelativeUrlSetting,
  ConfigService,
  ResumeModel,
  AuthenticationService,
} from '../index';
import { IAppConfig } from '../config/interfaces/app-config.interface';
import { FreeTierDelegate } from './free-tier.delegate';
import {
  IFreeTierRawField,
  IFreeTierScreen,
  IFreeTierScreenInfo,
  IWelcomeScreenInfo,
  IWelcomeSubScreenInfo,
  ISubscribeScreenInfo,
  IUpsellBannerSubscribeInfo,
  IFreeTierLink,
  IUpsellScreenInfo,
  IFreeTierButton,
  IFreeTierNeriticAction,
  EFreeTierFlow,
} from './free-tier.interface';
import { FreeTierConstants } from './free-tier.constants';

/**
 * @MODULE:     service-lib
 * @CREATED:    04/16/2021
 * @COPYRIGHT:  2021 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *      Free Tier Service to normalize the Free Tier screen data
 */

export class FreeTierService {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('FreeTierService');

  private screensInfo: IFreeTierScreen[] = [];
  private relativeUrls: Array<IRelativeUrlSetting>;

  private freeTierScreenInfoSubject = new BehaviorSubject<any[]>(
    this.screensInfo,
  );
  public freeTierScreenInfo$: Observable<any>;

  private previewEndTimeSubject = new BehaviorSubject<string>('');
  public previewEndTime$: Observable<string> = this.previewEndTimeSubject;

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(FreeTierService, FreeTierService, [
      FreeTierDelegate,
      ConfigService,
      ResumeModel,
      AuthenticationService,
      'IAppConfig',
    ]);
  })();

  constructor(
    private freeTierDelegate: FreeTierDelegate,
    private configService: ConfigService,
    private resumeModel: ResumeModel,
    private authenticationService: AuthenticationService,
    private SERVICE_CONFIG: IAppConfig,
  ) {
    this.freeTierScreenInfo$ = this.freeTierScreenInfoSubject.pipe(
      filter(data => !!data && data.length != 0),
    );
    this.freeTierDelegate.screensInfo$.subscribe(
      (screenInfo: IFreeTierScreenInfo[]) => {
        this.relativeUrls = this.configService.getRelativeUrlSettings();
        this.screensInfo =
          screenInfo[0] && screenInfo[0].screens ? screenInfo[0].screens : [];
        this.freeTierScreenInfoSubject.next(this.screensInfo);
      },
    );

    this.subscribeResume();
  }

  private subscribeResume() {
    this.resumeModel.globalSettings$.subscribe(globalSettings => {
      const previewEndTimeSetting = _.filter(globalSettings, {
        settingName: 'iapPreviewEndTime',
      })[0];
      const endTime = previewEndTimeSetting
        ? previewEndTimeSetting.settingValue
        : null;
      this.previewEndTimeSubject.next(endTime);
    });

    this.resumeModel.resumeComplete$
      .pipe(filter(val => val && this.SERVICE_CONFIG.isFreeTierEnable))
      .subscribe(() => {
        if (this.screensInfo.length == 0) {
          this.freeTierDelegate.getFreeTierScreenInfo();
        }
      });
  }

  /**
   * Returns normalized page/display content
   * @param pageName
   */
  public getScreenInfo(pageName: string): any {
    if (this.screensInfo.length == 0) return;

    let pageInfo: IFreeTierScreen = _.filter(this.screensInfo, {
      id: pageName,
    })[0];

    switch (pageName) {
      case FreeTierConstants.PAGE_NAME.WELCOME_SCREEN:
        return this.normalizeWelcomeScreenInfo(pageInfo);
      case FreeTierConstants.PAGE_NAME.SUBSCRIBE_SCREEN:
      case FreeTierConstants.PAGE_NAME.ACCESS_NOW:
      case FreeTierConstants.PAGE_NAME.USER_ALREADY_SUBSCRIBED:
        return this.normalizeRegistraionOverlayInfo(pageInfo);
      case FreeTierConstants.PAGE_NAME.UPSELL_BANNER_SUBSCRIBE:
        return this.normalizeUpsellBannerSubscribeInfo(pageInfo);
      case FreeTierConstants.PAGE_NAME.UPSELL_SCREEN:
        return this.normalizeUpsellScreenInfo(pageInfo);
      case FreeTierConstants.PAGE_NAME.FOOTER_SUBSCRIBE:
      case FreeTierConstants.PAGE_NAME.FOOTER_WELCOME_SCREEN:
        return this.normalizeFooterSubscribeInfo(
          pageInfo,
          EFreeTierFlow.SUBSCRIBE,
        );
      case FreeTierConstants.PAGE_NAME.FOOTER_ACCESS_NOW:
        return this.normalizeFooterSubscribeInfo(
          pageInfo,
          EFreeTierFlow.ACCESS_NOW,
        );
    }
    return;
  }

  private normalizeWelcomeScreenInfo(
    pageInfo: IFreeTierScreen,
  ): IWelcomeScreenInfo {
    let welcomeScreenInfo: IWelcomeScreenInfo = {} as IWelcomeScreenInfo;

    welcomeScreenInfo.logo = this.getValueByType(pageInfo.fields, 'sxm-logo');
    welcomeScreenInfo.carouselImages = this.getValueByName(
      pageInfo.fields,
      'img-group',
    ) as string[];
    welcomeScreenInfo.backgroundImg = this.getValueByType(
      pageInfo.fields,
      'bg-img',
    );
    welcomeScreenInfo.buttonOne = this.getButtonData(
      pageInfo.fields,
      'button1',
    );
    welcomeScreenInfo.buttonTwo = this.getButtonData(
      pageInfo.fields,
      'button2',
    );
    welcomeScreenInfo.subScreenName = this.getNeriticAction(
      pageInfo.fields,
      'footer',
    ).screen;

    return welcomeScreenInfo;
  }

  private normalizeRegistraionOverlayInfo(
    pageInfo: IFreeTierScreen,
  ): ISubscribeScreenInfo {
    let subscribeScreenInfo: ISubscribeScreenInfo = {} as ISubscribeScreenInfo;

    subscribeScreenInfo.logo = this.getValueByType(pageInfo.fields, 'sxm-logo');
    subscribeScreenInfo.backgroundImg = this.getValueByType(
      pageInfo.fields,
      'bg_img',
    );
    subscribeScreenInfo.registerNow = this.getValueByType(
      pageInfo.fields,
      'header',
    );
    subscribeScreenInfo.username = this.getValueByType(
      pageInfo.fields,
      'enter_email',
    );
    subscribeScreenInfo.emailError = this.getValueByType(
      pageInfo.fields,
      'error_email_invalid',
    );
    subscribeScreenInfo.ftNotEligibleError = this.getValueByType(
      pageInfo.fields,
      'error_account_exists',
    );
    subscribeScreenInfo.password = this.getValueByType(
      pageInfo.fields,
      'enter_password',
    );
    subscribeScreenInfo.forgotPassword = this.getLinkData(
      pageInfo.fields,
      'forgot_password',
    );
    subscribeScreenInfo.subScreenName = this.getNeriticAction(
      pageInfo.fields,
      'footer',
    ).screen;
    subscribeScreenInfo.checkbox = this.getValueByType(
      pageInfo.fields,
      'tc_checkbox',
    );
    subscribeScreenInfo.submitBtn = this.getButtonData(
      pageInfo.fields,
      'button1',
    );

    return subscribeScreenInfo;
  }

  private normalizeUpsellBannerSubscribeInfo(
    pageInfo: IFreeTierScreen,
  ): IUpsellBannerSubscribeInfo {
    let upsellBannerSubscribeInfo: IUpsellBannerSubscribeInfo = {} as IUpsellBannerSubscribeInfo;

    upsellBannerSubscribeInfo.backgroundImg = this.getValueByType(
      pageInfo.fields,
      'bg-img',
    );
    upsellBannerSubscribeInfo.line1 = this.getValueByType(
      pageInfo.fields,
      'text1',
    );
    upsellBannerSubscribeInfo.line2 = this.getValueByType(
      pageInfo.fields,
      'text2',
    );
    upsellBannerSubscribeInfo.subscribeBtn = this.getButtonData(
      pageInfo.fields,
      'button1',
    );

    return upsellBannerSubscribeInfo;
  }

  private normalizeUpsellScreenInfo(
    pageInfo: IFreeTierScreen,
  ): IUpsellScreenInfo {
    let upsellScreenInfo: IUpsellScreenInfo = {} as IUpsellScreenInfo;

    upsellScreenInfo.header = this.getValueByType(pageInfo.fields, 'header');
    upsellScreenInfo.description = this.getValueByType(
      pageInfo.fields,
      'description',
    );
    upsellScreenInfo.accessCopy = this.getValueByType(pageInfo.fields, 'text1');
    upsellScreenInfo.subscribeStreaming = this.getButtonData(
      pageInfo.fields,
      'button_filled',
    );
    upsellScreenInfo.logIn = this.getButtonData(pageInfo.fields, 'button1');

    return upsellScreenInfo;
  }

  private normalizeFooterSubscribeInfo(
    pageInfo: IFreeTierScreen,
    feature: string,
  ): IWelcomeSubScreenInfo {
    let subScreenInfo: IWelcomeSubScreenInfo = {
      legalLinks: [],
    } as IWelcomeSubScreenInfo;
    const buttonText =
      feature === EFreeTierFlow.ACCESS_NOW ? 'button_outline' : 'button_link';
    subScreenInfo.accessHeader = this.getValueByType(pageInfo.fields, 'text1');
    subScreenInfo.accessBtn = this.getButtonData(pageInfo.fields, buttonText);
    subScreenInfo.legalLinks = this.getLegalLinks(pageInfo.fields);

    return subScreenInfo;
  }

  private getLegalLinks(fields: IFreeTierRawField[]): IFreeTierLink[] {
    let legalLinks: IFreeTierLink[] = [];
    const legalFieldNames = [
      'customer_agreement',
      'privacy_policy',
      'ccpa_request',
    ];
    legalFieldNames.forEach(key => {
      const legalLink = this.getLinkData(fields, key);
      if (legalLink) {
        legalLinks.push(legalLink);
      }
    });
    return legalLinks;
  }

  private getLinkData(
    fields: IFreeTierRawField[],
    fieldName: string,
  ): IFreeTierLink {
    const field: IFreeTierRawField = this.getField(fields, fieldName);
    if (!field || field.type !== 'link') return;

    return {
      name: fieldName ? fieldName : '',
      url: field.url ? field.url : '',
      value: field.value ? field.value : '',
    };
  }

  private getButtonData(
    fields: IFreeTierRawField[],
    fieldName: string,
  ): IFreeTierButton {
    const buttonData: IFreeTierButton = {
      text: '',
    } as IFreeTierButton;

    const field: IFreeTierRawField = this.getField(fields, fieldName);
    if (!field) return buttonData;

    buttonData.text = this.getValueByType(fields, fieldName);
    buttonData.neriticAction = this.getNeriticAction(fields, fieldName);
    buttonData.url = field.url ? field.url : null;

    return buttonData;
  }

  private getValueByType(
    fields: IFreeTierRawField[],
    fieldName: string,
  ): string {
    const field: IFreeTierRawField = this.getField(fields, fieldName);
    if (!field) return '';

    switch (field.type) {
      case 'image':
        return relativeUrlToAbsoluteUrl(field.value, this.relativeUrls);
      default:
        return field.value ? field.value : null;
    }
  }

  private getValueByName(
    fields: IFreeTierRawField[],
    fieldName: string,
  ): string | string[] {
    const field: IFreeTierRawField = this.getField(fields, fieldName);
    if (!field) return;

    switch (field.name) {
      case 'img-group': {
        const images: string[] = [];
        field.fields.forEach((data: IFreeTierRawField) => {
          const image = relativeUrlToAbsoluteUrl(data.value, this.relativeUrls);
          images.push(image);
        });
        return images;
      }
      default:
        return field.value ? field.value : null;
    }
  }

  private getNeriticAction(
    fields: IFreeTierRawField[],
    fieldName: string,
  ): IFreeTierNeriticAction {
    const neriticAction: IFreeTierNeriticAction = {
      app: '',
      videoUrl: '',
      screen: '',
      flowType: null,
    };
    const field: IFreeTierRawField = this.getField(fields, fieldName);
    const actionNerticLink = field
      ? field.type == 'sub-screen'
        ? field.value
        : field.actionNeriticLink
      : null;
    if (!actionNerticLink) return;
    const neriticLinks = actionNerticLink.split(';');

    neriticLinks.forEach(neriticLink => {
      const splitedLink = neriticLink.split(':');
      if (splitedLink[0] === 'App') {
        if (splitedLink[1] === 'video') {
          neriticAction.videoUrl = relativeUrlToAbsoluteUrl(
            splitedLink[2],
            this.relativeUrls,
          );
        } else {
          neriticAction.app = splitedLink[2];
        }
      } else if (splitedLink[0] === 'AppScreen') {
        neriticAction.screen = splitedLink[2];
        const flowType = splitedLink[3] ? splitedLink[3].split('=')[1] : '';
        neriticAction.flowType = flowType ? (flowType as EFreeTierFlow) : null;
      }
    });

    return neriticAction;
  }

  private getField(
    fields: IFreeTierRawField[],
    fieldName: string,
  ): IFreeTierRawField | null {
    const field: IFreeTierRawField = _.filter(fields, { name: fieldName })[0];
    if (!field) return;

    return field;
  }
  public getIsFreeTrialEnabled(): boolean {
    return this.SERVICE_CONFIG.clientConfiguration.freeTier;
  }
}
