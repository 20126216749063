import { MediaPlayerConstants } from '../media-player.consts';

export class VideoPlayerConstants extends MediaPlayerConstants {
  public static TYPE: string = 'Video';
  public static LIVE = 'playbackType.liveChannel';
  public static VOD = 'playbackType.vodEpisode';

  public static DEFAULT_URL_DOMAIN = 'siriusxm-priprodlivevideo.siriusxm.com';
  public static DEFAULT_SXM_GUID = 'TODO';

  public static LIVE_PRIMARY_HLS = '%VIDEO_LIVE_HA_HOST%';
  public static LIVE_PRIMARY_HOST = 'VIDEO_LIVE_HA_HOST';

  public static SXM_GUID_TOKEN = '%SXM_GUID%';

  public static LIVE_VIDEO_ON = 'started';
  public static LIVE_VIDEO_OFF = 'stopped';

  public static RESET_MP4_VIDEO_ID = 'resetAsset';
  public static SAFARI_CHECK = 'safari-check';
}
