/**
 * @MODULE:     service-lib
 * @CREATED:    07/19/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *      Defines the interface for Session object .
 */

export interface ISession {
  username: string;
  loggedOut: boolean;
  exited: boolean;
  loginAttempts: number;
  authenticated: boolean;
  accountLocked: boolean;
  accountExpired: boolean;
  trialAccountExpired: boolean;
  remainingLockoutMinutes: number;
  remainingLockoutSeconds: number;
  sessionID: string;
  forgotPassword: boolean;
  startTrial: boolean;
  timeStamp: number;
  loginFaultCode: number;
  loginFaultMessages: Array<string>;
  activeOpenAccessSession: boolean;
  openAccessStatus: OpenAccessStatus;
  isInPrivateBrowsingMode: boolean;
  duplicateLogin: boolean;
  itDown: boolean;
  activeFreeTierSession: boolean;
  freeTierStatus: FreeTierStatus;
  registrationCode: string;
  regExpiration: string;
  pollFrequency: number;
}

/**
 * Union type for the status of Open Access
 *
 * unavailable - API has disabled Open Access
 * active - The user is eligible for Open Access
 * expired - The user has previously signed up for an Open Access trial but it is now expired
 */

// TODO: RW - Update this to be a String Enum once TypeScript is updated to version 2.4 or higher
export type OpenAccessStatus = 'unavailable' | 'eligible' | 'ineligible';

export const openAccessStatus: { [key: string]: OpenAccessStatus } = {
  ELIGIBLE: 'eligible',
  UNAVAILABLE: 'unavailable',
  INELIGIBLE: 'ineligible',
};

export enum FreeTierStatus {
  UNAVAILABLE,
  ELIGIBLE,
  INELIGIBLE,
  EXPIRED,
}
