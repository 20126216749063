import {BehaviorSubject} from "rxjs";
import {addProvider, IProviderDescriptor} from "../service";

export class MediaPlayerModel {
    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(
            MediaPlayerModel,
            MediaPlayerModel,
            []
        );
    }();

    constructor() {}
    public playbackState$ : BehaviorSubject<string> = new BehaviorSubject<string>('');
    public restart$ : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public bufferUnderFlow$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
}
