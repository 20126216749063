import DiscoverIconOn from '../../assets/images/discover-on-white.svg';
import DiscoverIconOff from '../../assets/images/discover-off.svg';
import FavoritesIconOn from '../../assets/images/favorite-on-white.svg';
import FavoritesIconOff from '../../assets/images/favorite-off-nav.svg';
import RecentIconOn from '../../assets/images/recent-on-blue.svg';
import RecentIconOff from '../../assets/images/recent-off.svg';
import SearchIconOn from '../../assets/images/srch-on-blue.svg';
import SearchIconOff from '../../assets/images/srch-off-nav.svg';
import SettingsIconOn from '../../assets/images/settings-on-blue.svg';
import SettingsIconOff from '../../assets/images/settings-off.svg';
import { MENU, RECENT, SEARCH, SETTINGS } from '../../utils/navigationMap';
import { appRouteConstants } from '../../routing/app.route.constants';

const { ZONE_MENU } = MENU;
const { PAGE: RECENT_PAGE } = RECENT;
const { PAGE: SEARCH_PAGE } = SEARCH;
const { PAGE: SETTINGS_PAGE } = SETTINGS;

export const coreMenuItems = [
  { id: 'now-playing-bar', iconName: '', iconOn: '', iconOff: '' },
  {
    name: 'Discover',
    id: 'discover-button',
    iconName: 'Discover Icon',
    iconOn: DiscoverIconOn,
    iconOff: DiscoverIconOff,
  },
  {
    name: 'Favorites',
    id: 'favorites-button',
    iconName: 'Favorites Icon',
    iconOn: FavoritesIconOn,
    iconOff: FavoritesIconOff,
  },
  {
    name: 'Recent',
    id: 'recent-button',
    iconName: 'Recent Icon',
    iconOn: RecentIconOn,
    iconOff: RecentIconOff,
  },
  {
    name: 'Search',
    id: 'search-button',
    iconName: 'Search Icon',
    iconOn: SearchIconOn,
    iconOff: SearchIconOff,
  },
  {
    name: 'Settings',
    id: 'settings-button',
    iconName: 'Settings Icon',
    iconOn: SettingsIconOn,
    iconOff: SettingsIconOff,
  },
];

export const checkCoreIconWithPath = path => {
  if (path.includes(zoneCategory.discoverZone.path)) {
    return coreMenuItems[1].id;
  }
  if (path.includes(zoneCategory.favoritesZone.path)) {
    return coreMenuItems[2].id;
  }
  if (path.includes(zoneCategory.recentZone.path)) {
    return coreMenuItems[3].id;
  }
  if (path.includes(zoneCategory.searchZone.path)) {
    return coreMenuItems[4].id;
  }
  if (path.includes(zoneCategory.settingsZone.path)) {
    return coreMenuItems[5].id;
  }
};

export const checkCoreNextdownWithPath = path => {
  if (
    path.includes(zoneCategory.discoverZone.path) ||
    path.includes(zoneCategory.favoritesZone.path) ||
    path.includes(zoneCategory.podcastsVideosZone.path)
  ) {
    return ZONE_MENU;
  } else if (path.includes(zoneCategory.recentZone.path)) {
    return RECENT_PAGE;
  } else if (path.includes(zoneCategory.searchZone.path)) {
    return SEARCH_PAGE;
  } else if (path.includes(zoneCategory.settingsZone.path)) {
    return SETTINGS_PAGE;
  }

  return '';
};

export const checkDisplayZoneMenuWithPath = (
  path: string,
  comingFromSearch: boolean,
) => {
  if (
    path.includes(zoneCategory.searchZone.path) ||
    path.includes(zoneCategory.searchZone.resultsPath) ||
    path.includes(zoneCategory.allChannelsZone.path) ||
    path.includes(zoneCategory.categoryZone.path) ||
    path.includes(zoneCategory.collectionZone.path) ||
    path.includes(zoneCategory.viewAllZone.path) ||
    path.includes(zoneCategory.episodeZone.path) ||
    path.includes(zoneCategory.enhancedEDPZone.path) ||
    path.includes(zoneCategory.settingsZone.path) ||
    comingFromSearch
  ) {
    return false;
  }
  return true;
};

export const checkIfFreeTrialCounterShouldBeDisplayed = (path: string) => {
  return (
    path.includes(zoneCategory.settingsZone.path) ||
    path.includes(zoneCategory.viewAllZone.path) ||
    path.includes(zoneCategory.categoryZone.path) ||
    path.includes(zoneCategory.allChannelsZone.path) ||
    path.includes(zoneCategory.collectionZone.path)
  );
};

export const showNavBar = (path: string) => {
  if (
    path.includes(appRouteConstants.NOW_PLAYING) ||
    path.includes(appRouteConstants.AUTH.LOGIN) ||
    path.includes(appRouteConstants.AUTH.LOGIN_CONFIRMATION)
  ) {
    return false;
  }
  return true;
};

export const zoneCategory = {
  discoverZone: {
    path: '/home',
    buttons: [
      {
        id: 'for-you-button',
        title: 'For You',
        type: 'zone-inactive',
        className: 'zone-menu-name',
        superCategory: 'foryou',
        itemWidth: '10.19rem',
      },
      {
        id: 'music-button',
        title: 'Music',
        type: 'zone-inactive',
        className: 'zone-menu-name',
        superCategory: 'music',
        itemWidth: '6.75rem',
      },
      {
        id: 'sports-button',
        title: 'Sports',
        type: 'zone-inactive',
        className: 'zone-menu-name',
        superCategory: 'sports',
        itemWidth: '7.75rem',
      },
      {
        id: 'news-button',
        title: 'News',
        type: 'zone-inactive',
        className: 'zone-menu-name',
        superCategory: 'news',
        itemWidth: '6.44rem',
      },
      {
        id: 'talk-button',
        title: 'Talk',
        type: 'zone-inactive',
        className: 'zone-menu-name',
        superCategory: 'entertainment',
        itemWidth: '6.4375rem',
      },
      {
        id: 'howard-button',
        title: 'Howard',
        type: 'zone-inactive',
        className: 'zone-menu-name',
        superCategory: 'howard',
        itemWidth: '10.13rem',
      },
      {
        id: 'podcast-button',
        title: 'Podcast',
        type: 'zone-inactive',
        className: 'zone-menu-name',
        superCategory: 'all_podcasts',
        itemWidth: '10.56rem',
        marginRight: '1.13rem',
      },
      {
        id: 'videos-button',
        title: 'Videos',
        type: 'zone-inactive',
        className: 'zone-menu-name',
        superCategory: 'all_video',
        itemWidth: '8.8rem',
      },
    ],
  },
  podcastsVideosZone: {
    path: '/category-page',
  },
  allChannelsZone: {
    path: '/all-channels',
  },
  favoritesZone: {
    path: '/favorites',
    buttons: [
      {
        id: 'channels-button',
        title: 'Channels',
        type: 'zone-inactive',
        className: 'zone-menu-name',
        superCategory: 'channels',
      },
      {
        id: 'shows-button',
        title: 'Shows',
        type: 'zone-inactive',
        className: 'zone-menu-name',
        superCategory: 'shows',
      },
      {
        id: 'episodes-button',
        title: 'Episodes',
        type: 'zone-inactive',
        className: 'zone-menu-name',
        superCategory: 'episodes',
      },
    ],
  },
  recentZone: {
    path: '/recently-played',
  },
  searchZone: {
    path: '/query',
    resultsPath: '/search-result',
  },
  settingsZone: {
    path: '/application-settings',
  },
  categoryZone: {
    path: '/category-listing',
  },
  collectionZone: {
    path: '/collection-carousel',
  },
  viewAllZone: {
    path: '/view-all',
  },
  episodeZone: {
    path: '/on-demand-episodes',
  },
  enhancedEDPZone: {
    path: '/enhanced-edp',
  },
};

export const originalDiscoverZoneButtons = [
  {
    id: 'for-you-button',
    title: 'For You',
    type: 'zone-inactive',
    className: 'zone-menu-name',
    superCategory: 'foryou',
    itemWidth: '10.19rem',
  },
  {
    id: 'music-button',
    title: 'Music',
    type: 'zone-inactive',
    className: 'zone-menu-name',
    superCategory: 'music',
    itemWidth: '6.75rem',
  },
  {
    id: 'sports-button',
    title: 'Sports',
    type: 'zone-inactive',
    className: 'zone-menu-name',
    superCategory: 'sports',
    itemWidth: '7.75rem',
  },
  {
    id: 'news-button',
    title: 'News',
    type: 'zone-inactive',
    className: 'zone-menu-name',
    superCategory: 'news',
    itemWidth: '6.44rem',
  },
  {
    id: 'talk-button',
    title: 'Talk',
    type: 'zone-inactive',
    className: 'zone-menu-name',
    superCategory: 'entertainment',
    itemWidth: '6.4375rem',
  },
  {
    id: 'howard-button',
    title: 'Howard',
    type: 'zone-inactive',
    className: 'zone-menu-name',
    superCategory: 'howard',
    itemWidth: '10.13rem',
  },
  {
    id: 'podcast-button',
    title: 'Podcast',
    type: 'zone-inactive',
    className: 'zone-menu-name',
    superCategory: 'all_podcasts',
    itemWidth: '10.56rem',
    marginRight: '1.13rem',
  },
  {
    id: 'videos-button',
    title: 'Videos',
    type: 'zone-inactive',
    className: 'zone-menu-name',
    superCategory: 'all_video',
    itemWidth: '8.8rem',
  },
];

export const podcastsDiscoverZoneButton = {
  id: 'podcast-button',
  title: 'Podcasts',
  type: 'zone-inactive',
  className: 'zone-menu-name',
  superCategory: 'all_podcasts',
  itemWidth: '10.56rem',
  marginRight: '1.13rem',
};

export const videosDiscoverZoneButton = {
  id: 'videos-button',
  title: 'Videos',
  type: 'zone-inactive',
  className: 'zone-menu-name',
  superCategory: 'all_video',
  itemWidth: '8.8rem',
};
