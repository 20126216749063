import moment from 'moment';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  getChannel,
  getEpisode,
  getIsVod,
  getArtistName,
  getTrackName,
} from '../../redux/selector/now-playing.store';

export default function useMiniPlayerHook() {
  const channel = useSelector(getChannel);
  const isVod = useSelector(getIsVod);
  const episode = useSelector(getEpisode);
  const artistName = useSelector(getArtistName);
  const trackName = useSelector(getTrackName);
  const [text, setText] = useState('');
  useEffect(() => {
    let emissionDay = '';
    if (isVod && episode && episode.originalIsoAirDate) {
      if (moment(episode.originalIsoAirDate).isSame(moment(), 'day')) {
        emissionDay = 'Today';
      } else {
        emissionDay = moment(episode.originalIsoAirDate).format('MMM D, YYYY');
      }
    }
    setText(
      `Now Playing Button.       Now Playing ${
        channel && channel.channelNumber ? `on Ch ${channel.channelNumber}` : ''
      } ${isVod ? episode && episode.longTitle : artistName} ${
        isVod ? emissionDay : trackName
      }`,
    );
  });
  return text;
}
