import { BehaviorSubject } from 'rxjs';

import { IProviderDescriptor, addProvider, Logger, IPlayhead } from '../index';
import { Playhead } from './playhead.interface';

/**
 * @MODULE:     service-lib
 * @CREATED:    07/12/18
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * PlayheadTimestampService to acquire a playhead of current playing mediaPlayer and pass the data between two player
 */

export class PlayheadTimestampService {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('PlayheadTimestampService');

  private zeroPlayhead = new Playhead();

  /**
   * subject for delivering current playhead
   * @type {any}
   */
  public playhead: BehaviorSubject<IPlayhead> = new BehaviorSubject(
    this.zeroPlayhead,
  );

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(PlayheadTimestampService, PlayheadTimestampService);
  })();

  /**
   * Constructor.
   */
  constructor() {}
}
