import { of as observableOf, BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { ChromecastConst } from "./chromecast.const";
import { ChromecastPlayerService } from "../mediaplayer/chromecastplayer/chromecast-player.service";
import { IMetaData } from "./chromecast.interface";
import { Logger } from "../logger/index";
import { addProvider, IProviderDescriptor, PlayerTypes } from "../service";

export class ChromecastModel
{
    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("ChromecastModel");

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(ChromecastModel,
            ChromecastModel,
            []);
    }();

    /**
     * A zulu based timestamp used to switch audio back and forth between sender and receiver. Ideally we want
     * to switch devices (to/from the Sender and Receiver) and play auio seamlessly using the last known timestamp;
     * this value is set by listening to events from both the web client's audio player (sender) and the Chromecast
     * audio player (receiver).
     * @type {Number}
     */
    public playheadZulu: number                      = NaN;
    public playheadStartZulu: number                 = NaN;
    public playheadDurationSeconds: number           = NaN;
    /**
     * A zero based time that indicates the number of milliseconds the web client audio player has played into
     * the current playlist.
     *
     * NOTE: This is not currently used as the seamless audio issue was solved with a custom implementation
     * on the Receiver side of the house, but we're leaving it here for the moment for context and in
     * event that Google says this is the correct way to seek.
     *
     * @type {Number}
     */
    public timeIntoPlaylist: number                  = 0;
    /**
     * Reference to the actual Chromecast audio player facade. This is the underlying audio player that controls
     * the remote Chromecast Receiver audio player and is used by the web client's high-level AudioPlayer facade;
     * When switching from the native HTML5 audio player inside the AudioPlayer facade this is the Chromecast
     * audio player that's used.
     *
     * @type {ChromecastPlayer}
     */
    public chromecastPlayer: ChromecastPlayerService = null;
    /**
     * Reference to the Chromecast session.
     * @type {null}
     */
    public session                                   = NaN;
    public remotePlayer                              = null;
    public remotePlayerController                    = null;
    public isReconnection                            = false;
    public isDisconnecting                           = false;
    public autoPlay                                  = true;
    public loadInProgress                            = false;

    public currentlyPlayingData: IMetaData = {} as IMetaData;

    /**
     * Indicates the casting state.
     */
    public castState$: BehaviorSubject<string> = new BehaviorSubject("");

    /**
     * Indicates the casting session's state.
     */
    public castSessionState$: BehaviorSubject<string> = new BehaviorSubject("");

    /**
     * Indicates the casting state.
     */
    public state$: BehaviorSubject<string> = new BehaviorSubject("");

    /**
     * subject for delivering device settings through volume Observable.
     * @type {any}
     */
    private volumeChangedSubject$: BehaviorSubject<{}> = new BehaviorSubject({});

    /**
     * An observable (hot, subscribe returns most recent item) that can be used to obtain the volume info
     * and be notified when the volume data changes.
     */
    public volumeChangedFromRemotePlayer$: Observable<any> = observableOf({});

    /**
     * subject for delivering device settings through settings Observable.
     * @type {any}
     */
    private chromcastFatalErrorSubject: BehaviorSubject<{}> = new BehaviorSubject({});

    /**
     * An observable (hot, subscribe returns most recent item) that can be used to obtain the deviceSettingsList &
     * GlobalSettingsList and be notified when the deviceSettingsList data changes.
     */
    public chromcastFatalError$: Observable<any> = observableOf({});

    /**
     * Represents a Casting Device Friendly Name.
     * @type {string}
     */
    public deviceFriendlyName: string = "";

    /**
     * Represents a Casting Device supports audio only or not.
     * @type {string}
     */
    public isDeviceVideoSupported: boolean = false;

    /**
     * subject for delivering new tune req info through tuneChanged$ Observable.
     * @type {any}
     */
    private tuneChangedSubject: BehaviorSubject<IMetaData> = new BehaviorSubject({} as IMetaData);

    /**
     * An observable (hot, subscribe returns most recent item) that can be used to obtain the tune change
     * and be notified when the tune change.
     */
    public tuneChanged$: Observable<IMetaData> = observableOf({} as IMetaData);

    /**
     * subject for delivering device settings through tuneChanged$ Observable.
     * @type {any}
     */
    private sessionTransferredSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

    /**
     * An observable (hot, subscribe returns most recent item) that can be used to obtain the session transfer from R
     * and be notified when the session transfer changes.
     */
    public sessionTransferred: Observable<boolean> = observableOf(false);

    public playerType: string = PlayerTypes.LOCAL;


    /**
     * subject for delivering device settings through tuneAIC$ Observable.
     * @type {any}
     */
    private tuneAICSubject: BehaviorSubject<IMetaData> = new BehaviorSubject(null);

    /**
     * An observable (hot, subscribe returns most recent item) that can be used to obtain the  AIC Tune Requested
     */
    public tuneAIC$: Observable<IMetaData> = this.tuneAICSubject.pipe(filter(metaData => !!metaData));

    /**
     * subject for delivering tracks through updateTracks$ Observable.
     * @type {any}
     */
    private updateTracksSubject: BehaviorSubject<IMetaData> = new BehaviorSubject(null);

    /**
     * An observable (hot, subscribe returns most recent item) that can be used to obtain the tracks change
     * and be notified when the track change.
     */
    public updateTracks$: Observable<IMetaData> = this.updateTracksSubject;

    /**
     * Constructor
     */
    constructor()
    {
        this.volumeChangedFromRemotePlayer$ = this.volumeChangedSubject$.pipe(filter((data: any) => !!data));

        this.chromcastFatalError$ = this.chromcastFatalErrorSubject;
        this.tuneChanged$         = this.tuneChangedSubject;
        this.sessionTransferred   = this.sessionTransferredSubject;
    }

    /**
     * Shortcut to acquiring the Chromecast session.
     * @returns {any}
     */
    public getSession(): any
    {
        // If we're not able to cast, go ahead and halt.
        // That way we don't call failing code in IE/Firefox/Safari.
        if (!ChromecastConst.IS_CASTING_AVAILABLE)
        {
            ChromecastModel.logger.warn(`getSession( Casting is not available so no session. )`);
            return null;
        }

        const castSession = ChromecastConst.CF.CastContext.getInstance().getCurrentSession();

        return castSession;
    }

    /**
     * Used to set the device details
     */
    public setDeviceDetails() : void
    {
        const castSession = this.getSession();
        const castDevice = castSession.getCastDevice();
        ChromecastModel.logger.debug(`chrome getSessionDevice Device Detail ` + JSON.stringify(castDevice));
        this.deviceFriendlyName = castDevice && castDevice.friendlyName ? castDevice.friendlyName : "";
        const videoOut = castDevice.capabilities.find((capability) => capability === ChromecastConst.CC.Capability.VIDEO_OUT);
        this.isDeviceVideoSupported = !!videoOut;
    }

    /**
     * Used to trigger the session transferred update
     * @param value
     */
    public triggerSessionTransferred(value: boolean): void
    {
        this.sessionTransferredSubject.next(value);
    }

    /**
     * Used to trigger the volume update
     * @param volumeData
     */
    public triggerVolumeUpdate(volumeData: any): void
    {
        this.volumeChangedSubject$.next(volumeData);
    }

    /**
     * Used to trigger when fatal error raised
     */
    public triggerChromcastFatalError(): void
    {
        this.chromcastFatalErrorSubject.next(true);
    }

    /**
     * resets the chromcastFatalError subject
     */
    public resetChromcastFatalError(): void
    {
        this.chromcastFatalErrorSubject.next(false);
    }

    /**
     * Used to trigger the tuneChanged$ observable
     * @param {IMetaData} metadata
     */
    public triggerTuneChanged(metadata: IMetaData): void
    {
        this.tuneChangedSubject.next(metadata);
    }

    public triggerAICTune(metadata: IMetaData): void
    {
        this.tuneAICSubject.next(metadata);
    }

    public triggerTracksUpdate(response: any): void
    {
        this.updateTracksSubject.next(response);
    }
}
