import { IAction } from './action.interface';
import { IRecentlyPlayed } from '../../servicelib';

export const LOAD_RECENTLY_PLAYED_LIST: string =
  '[Recently-Played-List] loadRecentlyPlayedList';

export class loadRecentlyPlayedList implements IAction {
  readonly type = LOAD_RECENTLY_PLAYED_LIST;

  constructor(public payload: Array<IRecentlyPlayed>) {}
}

export type RecentlyPlayedAction = loadRecentlyPlayedList;
