import {
  ITile,
  Logger,
  SeededStationsService,
  SeededStationUpdateItem,
  IProviderDescriptor,
  addProvider,
} from '../../servicelib';
import { CarouselStoreService } from '../carousel-store/carousel.store.service';

export class SeededStationsClientService {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger(
    'SeededStationsClientService',
  );

  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(
      SeededStationsClientService,
      SeededStationsClientService,
      [SeededStationsService, CarouselStoreService],
    );
  })();

  constructor(
    private seededStationsService: SeededStationsService,
    private carouselStoreService: CarouselStoreService,
  ) {}

  /**
   * Remove Seeded Station
   * @param stationGuid
   * @param stationId
   */
  public removeSeededStation(
    stationId: string,
    stationFactoryId: string,
  ): void {
    this.seededStationsService.removeSeededStation(stationId, stationFactoryId);
  }

  /**
   * Remove all Seeded Stations
   * Note: Timeout added because new list from pandora taking time to return which ended carousel call returns old list.
   * for now added 5 sec delay before making carousel call - API-20680
   * @param tile
   */
  public removeAllSeededStations(tiles: ITile[]): void {
    const removeStations = tiles.map(tile => {
      return new SeededStationUpdateItem(
        tile.tileAssetInfo.stationId,
        tile.tileAssetInfo.channelGuid,
      );
    });

    this.seededStationsService.removeAllSeededStations(removeStations);
  }
}
