import { ICdnAccessToken } from "../../http/index";

/**
 * @MODULE:     service-lib
 * @CREATED:    07/19/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *  TokenStrategies are Token Strategies for Akamai and limeLight 
 */
export class TokenStrategies
{
    public static API =
        {
          AKTOKEN_STRATEGY: "Akamai",
          LLTOKEN_STRATEGY: "Limelight",
          NOTOKEN_STRATEGY: "None",
          ADSWIZZ_STRATEGY: "AdsWizz"
        };

    public static TOKEN_STRATEGIES = [
        {
            urlPattern: "adswizz.com",
            strategy: TokenStrategies.API.ADSWIZZ_STRATEGY,
            keyPattern: "/key/",
            assetStartPattern: "/AAC",
            invalidTokenHTTPCode: 403
        },
        {
            urlPattern: "ais.mountain.siriusxm.com",
            strategy: TokenStrategies.API.ADSWIZZ_STRATEGY,
            keyPattern: "/key/",
            assetStartPattern: "/AAC",
            invalidTokenHTTPCode: 403
        },
        {
            urlPattern: "livetest.akamaized.net",
            strategy: TokenStrategies.API.AKTOKEN_STRATEGY,
            keyPattern: "/key/",
            assetStartPattern: "/AAC",
            invalidTokenHTTPCode: 403
        },
        {
            urlPattern: "live.akamaized.net",
            strategy: TokenStrategies.API.AKTOKEN_STRATEGY,
            keyPattern: "/key/",
            assetStartPattern: "/AAC",
            invalidTokenHTTPCode: 403
        },
        {
            urlPattern: "aod.akamaized.net",
            strategy: TokenStrategies.API.AKTOKEN_STRATEGY,
            keyPattern: "/key/",
            assetStartPattern: "/HLS",
            invalidTokenHTTPCode: 403
        },
        {
            urlPattern: "altcom.hs.llnwd.net",
            strategy: TokenStrategies.API.LLTOKEN_STRATEGY,
            keyPattern: "/key/",
            assetStartPattern: "/AAC",
            invalidTokenHTTPCode: 400
        },
        {
            urlPattern: "mountain.siriusxm",
            strategy: TokenStrategies.API.NOTOKEN_STRATEGY,
            keyPattern: "/key/",
            assetStartPattern: "/clips",
            invalidTokenHTTPCode: 403
        }
    ];

    /**
     * Used to find Strategy under TOKEN_STRATEGIES for given Url.
     * @param {string} url
     * @returns {any}
     */
    public static getTokenStrategy(url: string): any
    {
        for (let index = 0; index < TokenStrategies.TOKEN_STRATEGIES.length; index++)
        {
            if ((url.indexOf(TokenStrategies.TOKEN_STRATEGIES[ index ].urlPattern) >= 0))
            {
                return TokenStrategies.TOKEN_STRATEGIES[ index ];
            }
        }

        return null;
    }

    /**
     * Used to append token information to the url.
     * @param {string} url
     * @param {ICdnAccessToken} cdnAccessToken
     * @returns {string}
     */
    public static getTokenizedUrl(url: string, cdnAccessToken: ICdnAccessToken): string
    {
        let urlParam = "token=";
        let tokenStrategy = TokenStrategies.getTokenStrategy(url);
        let token = null;

        if (!cdnAccessToken)
        {
            return url;
        }
        if (!tokenStrategy)
        {
            tokenStrategy = { strategy: undefined };
        }
        switch (tokenStrategy.strategy)
        {
            case TokenStrategies.API.AKTOKEN_STRATEGY:
                token = (cdnAccessToken.akamai || "")
                    .replace("d=", '')
                    .replace(',v=1,"', "");
                break;

            case TokenStrategies.API.LLTOKEN_STRATEGY:
                urlParam = "";
                token = (cdnAccessToken.limeLight || "");
                break;

            case TokenStrategies.API.ADSWIZZ_STRATEGY:
                // for all the chunks with adswizz origin, if com_adswizz_synchro_decorateUrl() is available we are modifying the chunk_url
                url = window['com_adswizz_synchro_decorateUrl'] ? window['com_adswizz_synchro_decorateUrl'](url) : url;
                break;

            default:
                break;
        }

        if (!token)
        {
            return url;
        }

        let existingUrlParameters = "";
        let baseUrl = url;
        let qindex = url.indexOf("?");

        if (qindex > 0)
        {
            existingUrlParameters = url.substring(qindex).replace("?", "&");
            baseUrl = url.substring(0, qindex);
        }

        token = "?" + urlParam + token + existingUrlParameters;
        url = baseUrl + token;

        return url;
    }
}
