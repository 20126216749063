import { of as observableOf, Observable } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import * as _ from 'lodash';
import {
  ICarouselDataByType,
  CarouselNormalizer,
  CarouselPageParameter,
} from './carousel.interface';
import { IBaseCarouselResponse } from './carousel.types';
import { IBaseCategory } from '../channellineup';
import { IAppByPassState } from '../app-monitor';
import { IProviderDescriptor, addProvider, ApiLayerTypes } from '../service';
import { CarouselTypeConst } from './carousel.const';
import { CarouselConsts, ServiceEndpointConstants } from '../service/consts';
import { HttpProvider } from '../http';
import { V2CarouselNormalizer } from './carousel-normalizer';
import { ConfigService, IRelativeUrlSetting } from '../config';
import { BypassMonitorService } from '../app-monitor';

export class CarouselDelegate {
  /**
   * Used to holds the carousel by pass state
   * @type {boolean}
   */
  private carouselByPassState: boolean = false;

  /**
   * Use to store Profile Gup By pass mode. If is in gup by pass mode , we should not make notification,
   * recents and favorite carousel calls.
   * @type {boolean}
   */
  private gupByPass: boolean = false;

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(CarouselDelegate, CarouselDelegate, [
      HttpProvider,
      BypassMonitorService,
      ConfigService,
    ]);
  })();

  constructor(
    private http: HttpProvider,
    private bypassMonitorService: BypassMonitorService,
    private configService: ConfigService,
  ) {
    this.bypassMonitorService.bypassErrorState.subscribe(
      (state: IAppByPassState) => {
        this.carouselByPassState = state.CAROUSEL_BYPASS;
        this.gupByPass = state.GUP_BYPASS2 || state.GUP_BYPASS;
      },
    );
  }

  /**
   * Uses the supercategory's key to retrieve the correct carousel
   * data from the API. The key is referred to as page name by the API.
   *
   * @param superCategory that the carousels should be obtained from
   * @returns a hot observable that can be used to get the response
   * @memberof CarouselDelegate
   */
  public getCarouselsBySuperCategory(
    superCategoryKey: string,
  ): Observable<ICarouselDataByType> {
    // TODO: RW - This function is only called for the supercategories Music and Talk.
    // when the other pages are ready a new method for getting carousels will need to be created
    // for the other pages
    const params = _.cloneDeep(CarouselConsts.PARAMS);
    params[CarouselConsts.PAGE_NAME] = `supercategory_${superCategoryKey}`;
    params['result-template'] = 'HLSP'; //Needed so that categories show appropriate background images and to get the Podcasts moreSelector

    return this.getCarouselsByParams(params) as Observable<ICarouselDataByType>;
  }

  /**
   * Uses the sub category's key to retrieve the correct carousel
   * data from the API. The key is referred to as page name by the API.
   *
   * @param subCategory that the carousels should be ontained from
   * @returns
   * @memberof CarouselDelegate
   */
  public getCarouselsBySubCategory(
    subCategory: IBaseCategory,
  ): Observable<ICarouselDataByType> {
    const params = _.cloneDeep(CarouselConsts.PARAMS);
    params[CarouselConsts.PAGE_NAME] = `category_${subCategory.key}`;

    return this.getCarouselsByParams(params) as Observable<ICarouselDataByType>;
  }

  /**
   * Uses the pageName to retrieve the correct carousel
   * data from the API. The key is referred to as page name by the API.
   * @param pageName is the name of the page to get the carousels for
   * @param requestParams is an (optional) array of parameters to be used as url params on the carousel call;
   * @returns a hot observable that can be used to get the response
   * @memberof CarouselDelegate
   */
  public getCarouselsByPage(
    pageName: string,
    requestParams: Array<CarouselPageParameter> = [],
    webTemplate: boolean = false,
  ): Observable<ICarouselDataByType> {
    const params = _.cloneDeep(
        webTemplate ? CarouselConsts.WEB_PARAMS : CarouselConsts.PARAMS,
      ),
      normalizer = V2CarouselNormalizer.normalizeCarousels;

    requestParams.forEach(
      (param: CarouselPageParameter) =>
        (params[param.paramName] = param.paramValue),
    );
    params[CarouselConsts.PAGE_NAME] = pageName;

    return this.getCarouselsByParams(params, normalizer);
  }

  /**
   * Uses complete pageUrl to retrieve the correct carousel data from the API.
   * @param {string} pageName is used to check is enable when user in bypass state or not.
   * @param {string} pageUrl contains pageName and other params like contextGuid to get the carousels for.
   * @returns {Observable<ICarouselDataByType>} a hot observable that can be used to get the response
   * @memberof CarouselDelegate
   */
  public getCarouselsByPageUrl(
    pageName: string,
    pageUrl: string,
  ): Observable<ICarouselDataByType> {
    const fullUrl = encodeURI('result-template=everest|tenfot&' + pageUrl);
    const normalizer = V2CarouselNormalizer.normalizeCarousels;

    return this.getCarouselsByUrl(pageName, fullUrl, normalizer);
  }

  /**
   * Generic get carousel call for V4 schema carousels using parameters.
   *
   * @param params contains the url parameters for the API call
   * @param normalizer is used to normalize the carousel responses into a common format
   * @param previousResponse, for zones we need to make multiple calls to get all zones- so we combine previous response
   *  with new one and send to cache entry in a single shot
   *
   * @returns {Observable<R>} response can be obtained from the returned observable
   */
  private getCarouselsByParams(
    params: any,
    normalizer: CarouselNormalizer = V2CarouselNormalizer.normalizeCarousels,
    previousResponse?: IBaseCarouselResponse,
  ): Observable<ICarouselDataByType> {
    if (this.carouselByPassState)
      return observableOf(normalizer({} as IBaseCarouselResponse));

    const pageName = params[CarouselConsts.PAGE_NAME];

    if (
      this.gupByPass &&
      (pageName === CarouselTypeConst.FAVORITES ||
        pageName === CarouselTypeConst.RECENTS ||
        pageName === CarouselTypeConst.SHOW_REMINDERS)
    ) {
      return observableOf(normalizer({} as IBaseCarouselResponse));
    }

    const urlMaps: Array<IRelativeUrlSetting> = this.configService.getRelativeUrlSettings();

    return this.http
      .get(
        ServiceEndpointConstants.endpoints.CAROUSEL.V4_CAROUSEL_BY_PAGE,
        null,
        { params },
      )
      .pipe(
        mergeMap((response: IBaseCarouselResponse) => {
          if (response.zone && response.zone.length > 0) {
            //prepare previousResponse - as this will be the one with all the concatinated zones which
            //we will be sending to normalizer and to cache-entry
            if (previousResponse) {
              previousResponse.zone = previousResponse.zone.concat(
                response.zone,
              );
              previousResponse.zoneInformation = response.zoneInformation;
              previousResponse.expiry = response.expiry;
            } else {
              previousResponse = response;
            }

            //If we have more zones that needs to be fetched then we need to make another request before
            // send it to carousel-cache-entry
            if (
              response.zoneInformation &&
              response.zoneInformation.moreZonesAvailable === true &&
              response.screen !== 'for_you_zoned' //TODO: temporarily adding this exception as server returns 400 when trying to fetch remaining zones for For You
            ) {
              params.start =
                previousResponse.zone[previousResponse.zone.length - 1]
                  .zoneOrder + 1;
              return this.getCarouselsByParams(
                params,
                normalizer,
                previousResponse,
              );
            }
          }
          return observableOf(
            normalizer(
              previousResponse || response,
              this.configService.liveVideoEnabled(),
              urlMaps,
            ),
          );
        }),
        catchError(error => {
          if (previousResponse) {
            return observableOf(
              normalizer(
                previousResponse,
                this.configService.liveVideoEnabled(),
                urlMaps,
              ),
            );
          } else {
            throw error;
          }
        }),
      );
  }

  /**
   * Generic get carousel call for V4 schema carousels using a url string
   *
   * @param {string} pageName
   * @param {string} url is used after a '?' in the API carousel request
   * @param {CarouselNormalizer} normalizer is used to normalize the carousel responses into a common format
   *
   * @returns {Observable<ICarouselDataByType>} response can be obtained from the returned observable
   */
  private getCarouselsByUrl(
    pageName: string,
    url: string,
    normalizer: CarouselNormalizer = V2CarouselNormalizer.normalizeCarousels,
  ): Observable<ICarouselDataByType> {
    if (this.carouselByPassState)
      return observableOf(normalizer({} as IBaseCarouselResponse));

    if (
      this.gupByPass &&
      (pageName === CarouselTypeConst.FAVORITES ||
        pageName === CarouselTypeConst.RECENTS ||
        pageName === CarouselTypeConst.SHOW_REMINDERS)
    ) {
      return observableOf(normalizer({} as IBaseCarouselResponse));
    }

    const urlMaps: Array<IRelativeUrlSetting> = this.configService.getRelativeUrlSettings();

    return this.http
      .get(
        ServiceEndpointConstants.endpoints.CAROUSEL.V4_CAROUSEL_BY_PAGE +
          '?' +
          url,
        null,
        null,
      )
      .pipe(
        map((response: IBaseCarouselResponse) =>
          normalizer(response, this.configService.liveVideoEnabled(), urlMaps),
        ),
      );
  }
}
