import React, { useState, useEffect, useContext, createContext } from 'react';
import {
  ServiceFactory,
  AuthenticationService,
  StorageService,
  StorageKeyConstant,
} from '../servicelib';
import { SXMServiceLayerModule } from '../sxmservicelayer/sxm.service.layer.module';
import { useObservable } from '../hooks';

const authContext = createContext({});
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
  return useContext(authContext);
};

// Provider hook that creates auth object and handles state
function useProvideAuth() {
  const [userSession, setUserSession] = useState({});
  const proceedToInjection = useObservable(
    SXMServiceLayerModule.proceedToInjection$,
  );

  let authenticationService;
  if (proceedToInjection) {
    authenticationService = ServiceFactory.getInstance(
      AuthenticationService,
    ) as AuthenticationService;
  }

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    if (authenticationService) {
      const storageService = ServiceFactory.getInstance(
        StorageService,
      ) as StorageService;
      const userSessionSubscription = authenticationService.userSession.subscribe(
        latestUserSession => {
          latestUserSession.username = storageService.getItem(
            StorageKeyConstant.USERNAME,
          );
          setUserSession(latestUserSession);
        },
      );

      return () => userSessionSubscription.unsubscribe();
    }
  }, [authenticationService]);

  return {
    userSession,
  };
}
