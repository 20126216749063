import { take } from 'rxjs/operators';
import {
  IChannel,
  ILiveVideoReminder,
  IMediaVideo,
  INotification,
  IProviderDescriptor,
  ISuperCategory,
} from '../index';
import moment from 'moment';
import { BehaviorSubject } from 'rxjs';
import { addProvider } from '../service';
import { LiveTimeService } from '../livetime';
import { CarouselService } from '../carousel';
import { TuneService } from '../tune/tune.service';
import { ChannelLineupService } from '../channellineup/channel.lineup.service';
import { ContentTypes } from './../index';

export class LiveVideoReminderService {
  public showReminderSubject: BehaviorSubject<INotification>;
  public liveVideoReminderShown: Array<ILiveVideoReminder> = new Array<
    ILiveVideoReminder
  >();

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(LiveVideoReminderService, LiveVideoReminderService, [
      TuneService,
      ChannelLineupService,
      LiveTimeService,
      CarouselService,
    ]);
  })();

  /**
   *  Constructor.
   * @param {TuneService} tuneService
   * @param {ChannelLineupService} channelLineupService
   * @param {LiveTimeService} liveTimeService
   * @param {CarouselService} carouselService
   */
  constructor(
    private tuneService: TuneService,
    private channelLineupService: ChannelLineupService,
    private liveTimeService: LiveTimeService,
    private carouselService: CarouselService,
  ) {
    this.showReminderSubject = new BehaviorSubject<INotification>(null);
    this.watchLiveVideoMarkers();
  }

  private watchLiveVideoMarkers(): void {
    this.channelLineupService.channelLineup.liveVideoReminder.subscribe(
      (data: ILiveVideoReminder) => {
        const liveVideoReminder: ILiveVideoReminder = data;

        //look for live reminder/ not the re-broadcast video markers
        //and also take only New video markers which are not already been used for show reminder
        let newVideoMarkers = liveVideoReminder.videos.filter(video => {
          return (
            this.isLiveVideo(video) && !this.isReminderShownForTheVideo(video)
          );
        });

        if (newVideoMarkers) {
          this.liveTimeService.liveTime$.pipe(take(1)).subscribe(liveTime => {
            //postpone the reminder until it is right time to show the reminder
            newVideoMarkers.forEach(video => {
              let delayInMilliseconds = moment(video.times.zuluStartTime).diff(
                liveTime.zuluMilliseconds,
              );
              delayInMilliseconds =
                delayInMilliseconds < 0 ? 0 : delayInMilliseconds;

              setTimeout(() => {
                this.createLiveVideoReminder(liveVideoReminder.channel, video);
              }, delayInMilliseconds);
            });
          });
        }
      },
    );
  }

  /**
   * create a video reminder for live video if the live video is available and starting.
   * @param {IChannel} channel
   * @param {IMediaVideo} video
   */
  public createLiveVideoReminder(channel: IChannel, video: IMediaVideo): void {
    if (this.isReminderShownForTheVideo(video)) {
      return;
    }

    let liveVideoReminder: ILiveVideoReminder = {
      channel: channel,
      videoAlertedFor: video,
    } as ILiveVideoReminder;

    //TODO : Removing the refresh carousel call - as it is putting too much stress on the backend
    // will need some more architecture work around this- once the decision is made, will comeback to it

    // this.refreshCarousel(channel.firstSubCategory);
    this.liveVideoReminderShown.push(liveVideoReminder);
  }

  /**
   * Check if the live video reminder was already shown for this video.
   * @param {IMediaVideo} video
   * @returns {boolean}
   */
  public isReminderShownForTheVideo(video: IMediaVideo): boolean {
    let reminderShown = this.liveVideoReminderShown.some(vReminder => {
      return vReminder.videoAlertedFor.assetGUID === video.assetGUID;
    });
    return reminderShown;
  }

  /**
   * Check if the live video has started.
   * @param {IMediaVideo} video
   * @returns {boolean}
   */
  public isLiveVideo(video: IMediaVideo): boolean {
    return video.airingType === ContentTypes.LIVE_AUDIO;
  }

  /**
   * Refresh the carousel to get updated contextual banner - live video.
   * @param superCategory
   */
  public refreshCarousel(subCategory): void {
    let superCategory = {} as ISuperCategory;
    superCategory.key = 'howard';
    superCategory.name = subCategory.name;
    superCategory.categoryList = [subCategory];

    //TODO:Vijaya - add code for dynamic carousel
    this.carouselService
      .getCarouselsBySuperCategory(superCategory, true)
      .subscribe();
    this.carouselService
      .getCarouselsBySubCategory(subCategory, true)
      .subscribe();
  }
}
