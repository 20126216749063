export type ApiCode = number;

export class ApiCodes
{
    public static NO_API_RESPONSE: number                     = -1;
    public static API_SUCCESS_CODE: number                    = 100;
    public static INVALID_CREDENTIALS: number                 = 101;
    public static ACCOUNT_LOCKED: number                      = 102;
    public static SATELLITE_ACCOUNT_ONLY: number              = 103;
    public static IT_DOWN: number                             = 104;
    public static OAC_PASSWORD: number                        = 105;
    public static OPEN_ACCESS_ELIGIBLE: number                = 106;
    public static OPEN_ACCESS_NOT_ELIGIBLE: number            = 107;
    public static MULTIPLE_SXIR: number                       = 115;
    public static FREE_TIER_NOT_ELIGIBLE: number              = 121;
    public static FREE_TIER_EXPIRED: number                   = 122;
    public static ALC_CODE_NOT_FOUND: number                  = 123;
    public static STREAMING_ACC_NOT_FOUND: number             = 127;
    public static INVALID_COOKIE: number                      = 203;
    public static AUTH_REQUIRED: number                       = 201;
    public static SESSION_RETRY: number                       = 202;
    public static SIMULTANEOUS_LISTEN_SAME_DEVICE: number     = 203;
    public static SIMULTANEOUS_LISTEN: number                 = 204;
    public static SESSION_IN_CASTING_MODE: number             = 205;
    public static SESSION_RESUME: number                      = 208;
    public static INVALID_REQUEST: number                     = 301;
    public static INTERNAL_API_ERROR: number                  = 302;
    public static GEO_LOCATION_ERROR: number                  = 303;
    public static MYSXM_CHANNEL_NOT_AVAILABLE: number         = 304;
    public static UNAVAILABLE_CONTENT: number                 = 305;
    public static UNAVAILABLE_RESUME_CONTENT: number          = 1305; // an "internal" client generated API error
    public static UNAVAILABLE_CONTENT_IN_CACHE: number        = 306;
    public static GEO_LOCATION_UNAVAILABLE: number            = 307;
    public static CHANNEL_NOT_AVAILABLE: number               = 309;
    public static MYSXM_SETTINGS_CHANGED: number              = 310;
    public static GUP_UNAVAILABLE: number                     = 313;
    public static UNAVAILABLE_GUP_PAUSE_POINT: number         = 314;
    public static FLTT_SR_SOURCE_ENDED: number                = 315; //SHC: I'm told, MYSXM_TRACK_NOT_AVAILABLE has been deprecated
    public static AOD_EXPIRED_CONTENT: number                 = 317;
    public static CONTENT_NOT_IN_SUBSCRIPTION_PACKAGE: number = 318;
    public static CONTENT_NOT_IN_PACKAGE_BUSINESS: number     = 319;
    public static UNAVAILABLE_ONDEMAND_EPISODE: number        = 320;
    public static ARTIST_RADIO_STATION_LIMIT_REACHED: number  = 324;
    public static FLTT_SR_NO_CLEAN_CONTENT: number            = 325;
    public static FLTT_STREAMING_GAME_BLACKOUT: number        = 330;
    public static SR_REQ_FORBIDDEN:number                     = 403;
    public static REQUEST_TIMEOUT: number                     = 408;
    public static GATEWAY_TIMEOUT: number                     = 503;
    public static FLTT_MODULE_ERROR: number                   = 600;
    public static UNEXPECTED_ERROR: number                    = 602;
    public static CONSUME_FAILED: number                      = 606;
    public static SEARCH_FAILURE: number                      = 609;
    public static GUP_UPDATE_FAILED: number                   = 610;
    public static GUP_UPDATE_RECENTLY_PLAYED_FAILED: number   = 611;
    public static FLTT_NO_STREAMING_ACCESS: number            = 617;
    public static CAROUSEL_API_ERROR: number                  = 621;
    public static AOD_BYPASS: number                          = 700;
    public static AIC_BYPASS: number                          = 701;
    public static GUP_BYPASS: number                          = 702;
    public static GUP_BYPASS2: number                         = 704;
    public static SEARCH_BYPASS: number                       = 705;
    public static IT_BYPASS: number                           = 708;
    public static VOD_BYPASS: number                          = 709;
    public static CAROUSEL_BYPASS: number                     = 710;
    public static ARTIST_RADIO_BYPASS: number                 = 712;
    public static ADSWIZZ_FAILOVER: number                    = 800;
    public static EXPIRED_PROSPECT_TRIAL_ACCOUNT: number      = 5140;
    public static ACCOUNT_INACTIVE_ACCOUNT_SUSPENDED: number  = 5200;
    public static EXPIRED_SUBSCRIPTION: number                = 5230;
    public static BUSINESS_CREDENTIALS: number                = 5260;
}
