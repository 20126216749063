export class HttpCodes
{
    public static OK: number                    = 200;
    public static NOT_FOUND: number             = 404;
    public static INTERNAL_SERVER_ERROR: number = 500;
    public static BAD_GATEWAY: number           = 502;
    public static SERVICE_UNAVAILABLE: number   = 503;
    public static GATEWAY_TIMEOUT: number       = 504;
    public static NETWORK_TIMEOUT: number       = 10000; // this is fake, but used by the HTTP provider service
}

// Http Error message used to send to service if we receive any Http error
export const HTTP_ERROR_MESSAGE: string = "HTTP status error";
export const HTTP_CANCELED_MESSAGE: string = "HTTP request canceled";
export const HTTP_NETWORK_ERROR: string = "Network Error";
