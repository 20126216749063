import { merge as observableMerge, Subject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import * as PriorityQueue        from "js-priority-queue";
import * as _                    from "lodash";
import { IAppConfig }              from "../config";
import {
    IProviderDescriptor,
    addProvider
}                                  from "../service";
import { Logger }                  from "../logger";
import { BypassMonitorService }    from "./bypass-monitor.service";
import {
    nonBypassOrAuthApiCodes,
    tuneApiCodes,
    apiToFault,
    AppErrorCodes,
    FaultCode, ApiCodes, ServiceEndpointConstants
}                                  from "../service/consts";
import { ApiDelegate, ApiMessage } from "../http/api.delegate";
import { SessionMonitorService }   from "../session/session-monitor.service";
import { IHttpResponse }           from "../http";

export interface ClientFault
{
    apiCode?: number;
    faultCode: FaultCode;
    url?: string;
    httpCode?: number;
    retryCount?: number;
    context?: any;
    metaData?: IMetaData;
}

export interface IMetaData
{
    description? : string;
}

interface IIgnoredFault
{
    code : number;
    url : string;
}

export class AppMonitorService
{

    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("AppMonitorService");

    private manualFaultError = new Subject<ClientFault>();
    private manualApiError = new Subject<number>();

    public faultStream: Observable<ClientFault> =
                          observableMerge(this.sessionMonitorService
                                               .appCodeSubject$.pipe(
                                               map((code : number) => AppMonitorService.toClientFault(code))),
                                           this.bypassMonitorService
                                               .bypassErrors.pipe(
                                               map((code : number) => AppMonitorService.toClientFault(code))),
                                           this.manualFaultError);

    private faultPriorityQueue: PriorityQueue<ClientFault> =
                new PriorityQueue<ClientFault>({comparator: (faultA, faultB) =>
                    {
                        return faultA.faultCode.priority - faultB.faultCode.priority;
                    }});

    //no way to search the priorityQueue so we need to keep track of what we put in it
    private queuedFaults: ClientFault[] = [];

    private apiErrorsToIgnore : Array<IIgnoredFault> = [];

    public hasQueuedFaults():boolean
    {
        return  this.faultPriorityQueue.length > 0;
    }

    public newFaults = new Subject<boolean>();

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(AppMonitorService, AppMonitorService, [
            SessionMonitorService, BypassMonitorService, ApiDelegate, "IAppConfig"
        ]);
    }();

    /**
     * Constructor
     */
    constructor(private sessionMonitorService: SessionMonitorService,
                private bypassMonitorService: BypassMonitorService,
                private apiDelegate: ApiDelegate,
                private SERVICE_CONFIG: IAppConfig)
    {
        this.faultStream.pipe(filter((fault) => !!fault)).subscribe((fault : ClientFault) =>
        {
            this.queueFault(fault);
        });

        nonBypassOrAuthApiCodes.forEach((code) =>
        {
            this.apiDelegate.addApiCodeHandler(code,
                                               (codes : Array<number>,
                                                messages : Array<ApiMessage>,
                                                response : IHttpResponse ) =>
                                               {
                                                   this.handleDeepLink(code);
                                                   const fault : ClientFault = this.apiCodeHandler(code,
                                                                                                   codes,
                                                                                                   messages,
                                                                                                   response);

                                                   if (!!fault) { this.triggerFaultError(fault); }
                                               });
        });
    }

    /**
     * This function gets called when the API delegate detects an error
     *
     * @param {number} triggeringCode code that handler was registered for
     * @param {Array<number>} codes array of codes that triggered the handler to be called
     * @param {Array<ApiMessage>} messages array of messages that accompanies the error code
     * @param {IHttpResponse} response is the entire http response for the error
     */
    private apiCodeHandler(triggeringCode : number,
                           codes : Array<number>,
                           messages : Array<ApiMessage>,
                           response : IHttpResponse ) : ClientFault
    {
        const errorCode = codes.find( (codeReceived) => triggeringCode === codeReceived);

        if (!!errorCode)
        {
            const url         = _.get(response, "request.responseURL", "").replace(/&cacheBuster=\d+/, "");
            const retryCount  = _.get(response, "config.retryCount", 0);
            const faultCode   = apiToFault.get(errorCode);
            const ignoreFault = this.apiErrorsToIgnore
                                    .find((faultToIgnore : IIgnoredFault) =>
                                          {
                                              return faultToIgnore.code === triggeringCode
                                                     && (url.indexOf(faultToIgnore.url) >= 0);
                                          });

            if (!!ignoreFault) { return;  } // Ignore these errors

            if (faultCode) { return AppMonitorService.toClientFault(errorCode,url,retryCount); }

            return undefined;
        }
    }

    /**
     * Add a fault to the fault queues (priority and regular)
     *
     * @param {ClientFault} fault is the fault to add
     */
    private queueFault(fault : ClientFault)
    {
        if(this.queuedFaults.findIndex( queuedFault => queuedFault.faultCode === fault.faultCode) === -1 )
        {
            this.faultPriorityQueue.queue(fault);
            this.queuedFaults.push(fault);
        }

        this.newFaults.next(this.hasQueuedFaults());
    }

    public ignoreFault(code : number, url : string)
    {
        const onListAlready = this.apiErrorsToIgnore
                                  .find((faultToIgnore : IIgnoredFault) =>
                                            faultToIgnore.code === code && faultToIgnore.url === url);

        if (!onListAlready) { this.apiErrorsToIgnore.push({ code : code, url : url }); }
    }

    /**
     * Get the next fault on the priority fault queue, but do not take it off the queue
     *
     * @returns {ClientFault} next fault on the priority fault queue
     */
    public getNextFault(): ClientFault
    {
        return this.faultPriorityQueue.peek();
    }

    /**
     * Take the next fault off the priority fault queue and also remove the same fault from the regular fault queue
     */
    public topFaultHandled(): void
    {
        const fault = this.faultPriorityQueue.dequeue();

        const index = this.queuedFaults.findIndex( queuedFault => queuedFault.faultCode === fault.faultCode);
        this.queuedFaults.splice(index, 1);

    }

    /**
     * Convert an API code into a ClientFault object
     *
     * @param {number} apiCode
     * @param {string} url is the (optional) url for the fault
     * @param {number} retryCount is the option retry count for the fault, defaults to zero
     * @returns {ClientFault}
     */
    public static toClientFault(apiCode: number, url : string = "", retryCount : number = 0): ClientFault
    {
        return {
            faultCode: apiToFault.get(apiCode),
            apiCode: apiCode,
            url : url,
            retryCount : retryCount
        };
    }

    /**
     * Trigger a fault from an API error
     *
     * @param code is the api code for the error
     * @param url is the url that resulted in the error
     * @param retries is the number of retries attempted
     */
    public triggerFaultApiError(code : number,url : string, retries : number = 0)
    {
        const fault = AppMonitorService.toClientFault(code,url,retries);

        if (!!fault) { this.triggerFaultError(fault); }
    }

    /**
     * Trigger the manualFault error observable with a new fault
     *
     * @param {ClientFault} fault to trigger
     */
    public triggerFaultError(fault: ClientFault): void
    {
        this.manualFaultError.next(fault);
    }

    /**
     * Trigger the manualApi error observable with a new error code
     *
     * @param {number} apiCode is the code to trigger
     */
    public triggerApiError(apiCode)
    {
        this.manualApiError.next(apiCode);
    }

    /**
     * Trigger a generic fault for error codes that happen for deep link urls
     *
     * @param {number} code is the API code to check to see if a deep link error needs to be triggered
     */
    public handleDeepLink(code)
    {
        if (tuneApiCodes.indexOf(code) >= 0
            && this.SERVICE_CONFIG.contextualInfo.deepLink)
        {
            this.triggerFaultError({ faultCode: AppErrorCodes.FLTT_DEEP_LINK_INVALID });
        }
    }
}
