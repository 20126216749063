import { share, filter } from 'rxjs/operators';
import * as _ from "lodash";

import { Observable ,  BehaviorSubject } from "rxjs";

import { IOnDemandShow, IOnDemandEpisode } from "./ondemand.interfaces";

import { ContentTypes } from "../service/types/content.types";

import {
    IChannel,
    IBaseChannel
} from "./channels.interfaces";

import {
    ISuperCategory,
    ISubCategory,
    IBaseCategory
} from "./categories.interfaces";

import {
    IFavoriteItem,
    IMarkerList,
    ApiLayerTypes,
    FavoriteAssetTypes,
    Logger,
    ICutMarker,
    IAodEpisode,
    IVodEpisode,
    ILiveVideoReminder
} from "../index";
import { mockForYouData } from "./mock-for-you-data";
import { LiveTime } from "../livetime/live-time.interface";

import { IMediaVideo } from "../tune/tune.interface";
import { TuneDelegate } from "../tune/tune.delegate";

/**
 * @MODULE:     service-lib
 * @CREATED:    08/07/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *  Channel Lineup class for containing channel lineups and observing channel lineup changes
 */
export class ChannelLineup
{
    private static logger = Logger.getLogger("ChannelLineup");

    /**
     * The array of live channels that represents the current lineup
     */
    private liveChannels: Array<IChannel> = [];

    /**
     * The array of supercategories in the current lineup
     */
    private superCategoryList: Array<ISuperCategory> = [];

    /**
     * subject for delivering channel lineups through the lineup observable
     */
    private channelsSubject: BehaviorSubject<Array<IChannel>> = null;

    /**
     * subject for delivering super selectCategory lists through the superCategories observable
     */
    private superCategoriesSubject: BehaviorSubject<Array<ISuperCategory>> = null;

    /**
     * List of channels that have been favorited
     */
    private favoriteChannels : Array<IFavoriteItem> = [];

    /**
     * List of shows that have been favorited
     */
    private favoriteShows : Array<IFavoriteItem> = [];

    /**
     * List of episodes that have been favorited
     *
     * @private
     * @type {Array<IFavoriteItem>}
     * @memberof ChannelLineup
     */
    private favoriteEpisodes: Array<IFavoriteItem> = [];

    /**
     * An observable (hot, subscribe returns most recent item) that can be used to obtain the lineup and be
     * notified when the lineup changes
     */
    public channels: Observable<Array<IChannel>> = null;

    /**
     * An observable (hot, subscribe returns most recent item) that can be used to obtain the list of super categories
     * and all the subcategories and channels contained within
     */
    public superCategories: Observable<Array<ISuperCategory>> = null;

    /**
     * subject for delivering live video reminder through the liveVideoReminder observable
     */
    private liveVideoReminderSubject: BehaviorSubject<ILiveVideoReminder>;

    /**
     * An observable (hot, subscribe returns most recent item) that can be used to obtain the live video reminder and be
     * notified when the liveVideoReminder changes
     */
    public liveVideoReminder:Observable<ILiveVideoReminder>;

    constructor()
    {
        this.channelsSubject = new BehaviorSubject(this.liveChannels);
        this.channels = this.channelsSubject;

        this.superCategoriesSubject = new BehaviorSubject(this.superCategoryList);
        this.superCategories = this.superCategoriesSubject;

        this.liveVideoReminderSubject = new BehaviorSubject(null);
        this.liveVideoReminder = this.liveVideoReminderSubject.pipe(filter((reminder) => !!reminder), share());
    }

    /**
     * Sets the super categories list.  Note, this does not trigger the sueprCategories observable, because we want
     * to bring in the live channel lineup first before we let anybody see the lists of channels within the
     * subcategories so that we can merge the lineup and live channel data into those channel lists first
     * @param superCategories is the list of super categories from the API
     */
    public setSuperCategories(superCategories: Array<ISuperCategory>)
    {
        this.superCategoryList = _.sortBy(superCategories, [ (category: ISuperCategory) => category.sortOrder ]);
        this.superCategoriesSubject.next(this.superCategoryList);
    }

    /**
     * Returns the first one in the super categories.
     */
    public getDefaultSuperCategory() : ISuperCategory
    {
        if(!this.superCategoryList || this.superCategoryList.length === 0)
        {
            return null;
        }

        return this.superCategoryList[0];
    }

    /**
     * Sets the channel lineup, then checks the subcategory channels to filter out channels in the subcategories that
     * do not exist in the lineup, and the replaces all the channels in all subcategories with the actual channels
     * from the lineup
     *
     * There are 3 different "lineups" that the API gives us.  There is the complete channel lineup, there are the
     * channels that are available in sobcategories within the lineup, and then there are the live channel list
     * responses that give us access to what is currently playing on each channel at any given time.
     *
     * To deal with this, we take the channel lineup, and replace channels in the subcategories with the
     * corresponding channel from the lineup.  Then, once the live channel data becomes available, we can go
     * through the lineup and update that data, and it will update the lineup and the channels contained within each
     * subcategory in a single step
     *
     * @param channels is the channel lineup
     */
    public setChannelLineup(channels: Array<IChannel>)
    {
        this.liveChannels = channels.filter((channel: IChannel) =>
        {
            return (channel.satOnly === false
                    && channel.isAvailable === true
                    && channel.subscribed === true);
        });

        this.liveChannels.forEach((channel: IChannel) => replaceChannelInSubcategories(channel, this.superCategoryList));

        /**
         * Filter and prune out the categories.  This is required because the categories sometimes contain channels
         * that are not present in the channel lineup.  We take these channels out of the sub categories, and if
         * a subcategory ends up with no channels in it, then we take out that subcategory as well
         */
        this.superCategoryList
            .forEach((superCategory: ISuperCategory) =>
            {
                superCategory.categoryList = _.sortBy(superCategory.categoryList,
                                                   [ (category: ISubCategory) => (category.order || category.sortOrder) ]);
                filterAndPruneSubCategories(superCategory, this.liveChannels);
            });

        this.superCategoryList = this.superCategoryList
                                     .filter((category : ISuperCategory) =>
        {
            return (category.key === mockForYouData.key || category.categoryList.length > 0);
        });

        this.channelsSubject.next(this.liveChannels);

        /**
         * Replace the channel object in the subcategories with the actual channel object from the lineup
         * @param channel to replace in the subcategories
         * @param superCategoryList is a list of all supercategories in the lineup
         * @returns {Array<IChannel>}
         */
        function replaceChannelInSubcategories(channel: IChannel, superCategoryList: Array<ISuperCategory>): IChannel
        {
            channel.categoryList =
                channel.categoryList
                    .map((category: IBaseCategory) => replaceChannelInSubCategory(channel,
                                                                                  category.categoryGuid,
                                                                                  superCategoryList));

            return channel;
        }

        /**
         * Find a channel in the subcategory with the given guid and replace it with the given channel
         * @param channel is the channel to replace the subcategory channel with
         * @param categoryGuid if ths guid of the category that contains the channel
         * @param superCategoryList list that contains all super and sub categories
         * @returns {ISubCategory} subcategory object with the channel now present in its channel list
         */
        function replaceChannelInSubCategory(channel: IChannel,
                                             categoryGuid: string,
                                             superCategoryList: Array<ISuperCategory>)
        {
            const subCategory = ChannelLineup.findSubCategory(categoryGuid, superCategoryList);

            if(!subCategory)
            {
                return subCategory;
            }

            if (!channel.firstSuperCategory)
            {
                const superCategory = ChannelLineup.findSuperCategoryForSubCategory(categoryGuid, superCategoryList);
                channel.firstSuperCategory = superCategory;
                channel.firstSubCategory = subCategory;
            }

            if (!subCategory)
            {
                ChannelLineup.logger.debug(`sub category not found for the channel ${channel.channelId}, category${categoryGuid}`);
                return;
            }

            subCategory.channelList.forEach((subCategoryChannel: IChannel, index : number) =>
            {
                if (subCategoryChannel.channelGuid === channel.channelGuid)
                {
                    subCategory.channelList[index] = channel;
                }
            });

            return subCategory;
        }

        /**
         * Take the categories within a super category and filter out channels that are not present in the channel
         * lineup and prune any subcategories that end up with empty channel lists
         * @param superCategory is the super category to filter and prune
         * @param lineup is the channel lineup to use for filtering channels
         */
        function filterAndPruneSubCategories(superCategory: ISuperCategory, lineup: Array<IChannel>)
        {
            if (superCategory.categoryList)
            {
                superCategory.categoryList
                    = superCategory.categoryList
                    .filter((category : ISubCategory) : boolean =>
                    {
                        return pruneSubCategoryChannels(category, lineup);
                    });
            }
        }

        /**
         * There are some channels that show up in subcategories that are not present in the channel lineup.  This
         * function will take those channels out of a given subcategory so that the client does not have to deal with
         * them.
         *  Note: Podcast will not available on any SXM channel lineup. so skip podcast category.
         *
         * @param category is the subcategory to check for missing channels
         * @param lineup is the current channel lineup
         * @returns will return true if the given subcategory has channels left in it, otherwise returns false
         */
        function pruneSubCategoryChannels(category: ISubCategory, lineup: Array<IChannel>): boolean
        {
            category.channelList = category
                .channelList
                .filter((channel: IChannel) : boolean => { return isChannelInLineup(channel,lineup); });

            return (category.channelList.length !== 0 || category.categoryType == ContentTypes.PODCAST);

            /**
             * Check and see if a given channel is in the given lineup
             * @param channel is the channel to check for
             * @param lineup is the lineup to check in
             * @returns {boolean} is true if the channel exists in the lineup, false otherwise
             */
            function isChannelInLineup(channel:IChannel, lineup: Array<IChannel>) : boolean
            {
                const id = channel.channelGuid;
                const channelInLineup: IChannel = _.find(lineup, (chan: IChannel) => chan.channelGuid === id);

                /*
                 * For some subcategories, channels are referenced from the subcategory, but not referenced from
                 * the categoryList in the channel in the lineup.  This will fix that.  This way, when we get
                 * live channel data from discover-channels calls, all the subcategories' channel lists will get
                 * updated properly with metadata
                 */
                if (channelInLineup)
                {
                    const subCategoryInChannel =
                              _.find(channelInLineup.categoryList,
                                  (cat: IBaseCategory) => !!cat && (category.categoryGuid ===  cat.categoryGuid));

                    if (!subCategoryInChannel)
                    {
                        channelInLineup.categoryList.push(category);
                    }
                }

                return (channelInLineup != undefined);
            }

        }
    }

    /**
     * Sets the lineup using the channels array.  If lineupChannels is present, it will be used to populate
     * this.liveChannels by merging channels into lineupChannels and returning a new channel array that will be in the
     * same order as channels.
     *
     * @param channels array of live channels (contains information about what is currently playing)
     */
    public setLiveChannels(channels: Array<IBaseChannel>)
    {
        if (this.liveChannels.length > 0)
        {
            /* At this point, we can just take the new channels and merge the live data into the existing lineup.  THe
             * order of channels will have been set after the first discover-channel-list call was made.
             */
            for (let index = 0; index < channels.length; index++)
            {
                if (this.liveChannels [ index ] && this.liveChannels[ index ].channelId !== channels[ index ].channelId)
                {
                    ChannelLineup.logger.info(`Live lineup order has changed`);

                    /*
                     * Lineup order has changed, reorder to the new order, then bail out of the loop
                     *
                     * Take each channel from channels
                     *   1) find the channel in lineupChannels that matches the channel from channels
                     *   2) merge the properties from the lineupChannel and the channel together
                     *   3) create a new array with the merged channels in the new order
                     *
                     * This is required because the order of the channels from the channel lineup call and the order of
                     * the list of channels from the discover-channel-list call are different.  Since the
                     * discover-channel-list call is made on a periodic basis, we take the channel ordering from the
                     * discover-channel-list call and merge in the channels from the channel lineup call.
                     *
                     * This is only done after the first discover-channel-list call, after which we can just update the
                     * channels in-order for subsequent discover-channel-list calls.
                     */
                    this.liveChannels =
                        this.liveChannels.map((channel: IChannel) =>
                            mapLineupToLive(channel, channels));

                    break;
                }

                ChannelLineup.mergeLiveToLineup(this.liveChannels[ index ], channels[ index ]);
            }

            /*
             * It is possible that we get "live" channels that are not in the original lineup.  If
             * this happens, then we will have "null" channels in the lineup.  Want to filter
             * those out before they cause trouble.
             */
            this.liveChannels = this.liveChannels.filter((channel:IChannel) => channel !== null);
            this.detectLiveVideoMarkers();

            this.channelsSubject.next(this.liveChannels);
            this.superCategoriesSubject.next(this.superCategoryList);
        }

        /**
         * Take a live channel (from the discover-channel-list call) and finds the channel in lineupChannels (from the
         * origin get discover call for channel lineup) that matches.  The properties from liveChannel will be merged
         * into the channel from the channel lineup, and that will be returned to the caller.
         *
         * @param liveChannel is the live channel we wish to find the channel lineup channel for to merge in
         * @param lineupChannels is the list of channels from the channel lineup call
         * @returns {IChannel}
         */
        function mapLineupToLive(liveChannel: IChannel,
                                 lineupChannels: Array<IBaseChannel>): IChannel
        {
            let channel: IChannel = liveChannel;

            for (let i = 0; i < lineupChannels.length; i++)
            {
                if (lineupChannels[ i ].channelId === liveChannel.channelId)
                {
                    channel = ChannelLineup.mergeLiveToLineup(liveChannel, lineupChannels[i]);
                }
            }

            return channel;
        }
    }

    /**
     * Returns live video markers from the channel lineup
     */
    public detectLiveVideoMarkers(): void
    {
        this.liveChannels.forEach((channel:IChannel) =>
        {
            //sending live video as true since it shouldn't matter in the lineup.
            let videos: Array<IMediaVideo> = TuneDelegate.normalizeVideoMarker(channel, ApiLayerTypes.LIVE_VIDEO_LAYER, true);
            if (videos.length > 0)
            {
                this.liveVideoReminderSubject.next({channel:channel, videos:videos} as ILiveVideoReminder);
            }
        });
    }

    /**
     * Returns an array that contains for all the supercategories present in the lineup.  These keys are needed
     * by come API calls in order to uniquely identify a supercategory
     * @returns {string[]}
     */
    public getSupercategoryKeys(): Array<string>
    {
        return this.superCategoryList
            .map((superCategory: ISuperCategory): string =>
            {
                return superCategory.key;
            });
    }

    /**
     * Returns an array that contains for all the subcategories present in the lineup within a given supercategory.
     * These keys are needed by come API calls in order to uniquely identify a supcategory
     * @returns {string[]}
     */
    public getSubcategoryKeys(superCategoryKey: string): Array<string>
    {
        const superCategory = this.superCategoryList.find((superCategory: ISuperCategory): boolean =>
        {
            return superCategory.key === superCategoryKey;
        });

        return superCategory.categoryList
            .map((subCategory: ISubCategory): string =>
            {
                return subCategory.key;
            });
    }

    /**
     * Set the AOD shows for a given subcategory within a given supercategory, and then trigger the observable
     * to indicate that a subcategory has possibly changed
     * @param superCategoryKey is the key for the supercategory
     * @param subCategoryKey is the key for the subcategory wthin the supercategory
     * @param shows is the shows to set on the supercategory
     */
    public setOnDemandShows(superCategoryKey: string, subCategoryKey: string, shows: Array<IOnDemandShow>)
    {
        const superCategory =
            _.find(this.superCategoryList,
                (superCategory: ISuperCategory): boolean => superCategory.key === superCategoryKey);

        const subCategory: ISubCategory =
            _.find(superCategory.categoryList,
                (subCategory: ISubCategory): boolean => subCategory.key === subCategoryKey);

        // Need to clear all the channels in the subcategory of shows.  Its a brand new day
        if (subCategory) { subCategory.channelList.forEach((channel: IChannel) => channel.shows = []); }
        shows.forEach((show: IOnDemandShow) =>
        {
            this.setFavoriteStatus("show", show, this.favoriteShows);
            show.aodEpisodes.forEach(episode => this.setFavoriteStatus("episode", episode, this.favoriteEpisodes));
            show.vodEpisodes.forEach(episode => this.setFavoriteStatus("episode", episode, this.favoriteEpisodes));
            addShowToChannels(show, subCategory);
            addCategoriesToShow(show, superCategory, subCategory);
        });

        subCategory.onDemandShows = shows;
        subCategory.onDemandPullTime = Date.now();

        this.superCategoriesSubject.next(this.superCategoryList);

        /**
         * Add a show to all the related channels within the given category, and to the channels in the lineup
         * @param show is the AOD show to add
         * @param category is the category to place show into for related channels
         */
        function addShowToChannels(show: IOnDemandShow, category: ISubCategory)
        {
            if (!show || !show.showDescription)
            {
                return;
            }
            show.showDescription
                .relatedChannelIds
                .forEach((channelId: string) => addShowToChannel(channelId, show, category.channelList));
        }

        /**
         * Add the (first) supercategory/subcategory the show belongs to.  If the show already has a super/sub
         * category then this function will do nothing
         * @param show to set the supercategpry for
         * @param superCategory supercategory to set
         * @param subCategory subcategory to set
         */
        function addCategoriesToShow(show: IOnDemandShow, superCategory: ISuperCategory, subCategory: ISubCategory)
        {
            if (!show.firstSuperCategory)
            {
                show.firstSuperCategory = superCategory;
                show.firstSubcategory = subCategory;
            }
        }

        /**
         * Add a show to a given channel in the list of channels supllied
         * @param channelId is the id of the channel to add the show to
         * @param show is the show to add to the given channel
         * @param channelList is the list of channels to find the given channel in
         */
        function addShowToChannel(channelId: string,
                                  show: IOnDemandShow,
                                  channelList: Array<IChannel>)
        {
            let channel = _.find(channelList, (channel: IChannel) => channel.channelId === channelId);

            if (channel)
            {
                channel.shows.push(show);
                channel.aodShowCount++;
                channel.aodEpisodeCount += show.aodEpisodes.length;
            }
        }
    }

    /**
     * Returns the time when the On Demand Shows for a subcategory were last retrieved from the API
     * @param {string} superCategoryKey for the on demand shows
     * @param {string} subCategoryKey for the on demand shows
     * @returns {Date}
     */
    public getOnDemandUpdateTime(superCategoryKey: string, subCategoryKey: string)
    {
        const superCategory =
                  _.find(this.superCategoryList,
                      (superCategory: ISuperCategory): boolean => superCategory.key === superCategoryKey);

        const subCategory: ISubCategory =
                  _.find(superCategory.categoryList,
                      (subCategory: ISubCategory): boolean => subCategory.key === subCategoryKey);

        return (subCategory) ? subCategory.onDemandPullTime : 0;
    }

    /**
     * Returns the On Demand shows for a given supercatgeory/subcategory combination
     * @param {string} superCategoryKey for the on demand shows
     * @param {string} subCategoryKey for the on demand shows
     * @returns {Array<IOnDemandShow> | Array}
     */
    public getOnDemandShows(superCategoryKey: string, subCategoryKey: string)
    {
        const superCategory =
                  _.find(this.superCategoryList,
                      (superCategory: ISuperCategory): boolean => superCategory.key === superCategoryKey);

        const subCategory: ISubCategory =
                  _.find(superCategory.categoryList,
                      (subCategory: ISubCategory): boolean => subCategory.key === subCategoryKey);

        return (subCategory) ? subCategory.onDemandShows : [];
    }

    /**
     * Set the favorites shows/channels from the list of favorites.  Will parse the list for channels and
     * shows and then mark the channels/shows in the current lineup and favorited or not based on
     * whether then
     * @param favorites is the list of favorite shows and channels
     */
    public setFavorites(favorites: Array<IFavoriteItem>)
    {
        this.favoriteShows = favorites.filter(fav => fav.assetType === FavoriteAssetTypes.SHOW);
        this.favoriteChannels = favorites.filter(fav => fav.assetType === FavoriteAssetTypes.CHANNEL);
        this.favoriteEpisodes = favorites.filter(fav => fav.assetType === FavoriteAssetTypes.EPISODE);

        this.liveChannels.forEach(
            channel =>
            {
                this.setFavoriteStatus("channel", channel, this.favoriteChannels);
                channel.shows.forEach(
                    show =>
                    {
                        this.setFavoriteStatus("show", show, this.favoriteShows);
                        show.aodEpisodes.forEach(
                            episode => this.setFavoriteStatus("episode", episode, this.favoriteEpisodes)
                        );

                        show.vodEpisodes.forEach(
                            episode => this.setFavoriteStatus("episode", episode, this.favoriteEpisodes)
                        );
                    }
                );
            }
        );

        this.channelsSubject.next(this.liveChannels);
        this.superCategoriesSubject.next(this.superCategoryList);
    }

    private setFavoriteStatus(assetType: string, asset: IChannel | IOnDemandEpisode | IOnDemandShow, favoriteAssets: IFavoriteItem[])
    {
        switch (assetType)
        {
            case "channel":
                asset.isFavorite = !!favoriteAssets.filter(fav =>
                {
                    return (fav.assetGUID === (asset as IChannel).channelGuid
                            || fav.channelId === (asset as IChannel).channelId);
                }).length;
            break;

            case "show":
                asset.isFavorite = !!favoriteAssets.filter(fav => fav.assetGUID === (asset as IOnDemandShow).assetGUID).length;
            break;

            case "episode":
                (asset.type === ContentTypes.AOD)
                    ? asset.isFavorite = !!favoriteAssets.filter(fav => fav.assetGUID === (asset as IAodEpisode).favoriteEpisodeCAId).length
                    : asset.isFavorite = !!favoriteAssets.filter(fav => fav.assetGUID === (asset as IVodEpisode).episodeGuid).length;
            break;
        }
    }

    /**
     * find channel from live channels array that matched the given channelId
     * @param {string} channelId -
     * @returns {IChannel}
     */
    public findChannelById(channelId: string): IChannel
    {
        let channel = _.find(this.liveChannels, (channel: IChannel) =>
        {
            return channel.channelId === channelId;
        });

        return channel;
    }

    /**
     * find channel from live channels array that matched the given channelGuid or channelId
     * @param {string} channelId -
     * @returns {IChannel}
     */
    public findChannelByIdOrGuid(channelIdentifier:string): IChannel
    {
        return _.find(this.liveChannels, (channel: IChannel) =>
        {
            return channel.channelId === channelIdentifier || channel.channelGuid === channelIdentifier;
        });
    }

    /**
     * find channel from live channels array that matched the given channelNumber
     * @param {string} channelNumber -
     * @returns {IChannel}
     */
    public findChannelByNumber(channelNumber:number): Array<IChannel>
    {
        let channel = _.find(this.liveChannels, (channel: IChannel) =>
        {
            return channel.channelNumber === channelNumber;
        });

        return channel && channel.channelNumber ? [ channel ] : [];
    }

    /**
     * find channels ByName from live channels array that matched the given Name
     * @param {string} channelName -
     * @returns {Array<IChannel>}
     */
    public findChannelsByName(channelName:string): Array<IChannel>
    {
        channelName = channelName.toLowerCase();
        let channels = _.filter(this.liveChannels, (channel: IChannel) => channel.name.toLowerCase().indexOf(channelName) > -1);

        return channels ? channels : [];
    }

    /**
     * Get a subcategory object for the given guid
     * @param subCategoryGuid is the guid for the subcategory
     * @returns {ISubCategory}
     */
    public findSubcategoryByGuid(subCategoryGuid) : ISubCategory
    {
        return ChannelLineup.findSubCategory(subCategoryGuid,this.superCategoryList);
    }

    /**
     * Get a super category object for the given sub category
     * @param subCategory is the subcategory to get the supercategory for
     * @returns {ISubCategory}
     */
    public findSuperCategoryForSubcategory(subCategory: ISubCategory) : ISuperCategory
    {
        let categoryGuid: string = _.get(subCategory, "categoryGuid", "") as string;
        return ChannelLineup.findSuperCategoryForSubCategory(categoryGuid, this.superCategoryList);
    }

    /**
     * Get a super category object for the given sub category guid
     * @param subCategoryGuid is the subcategory guid to get the supercategory for
     * @returns {ISubCategory}
     */
    public findSuperCategoryBySubcategoryGuid(subCategoryGuid: string) : ISuperCategory
    {
        return ChannelLineup.findSuperCategoryForSubCategory(subCategoryGuid,this.superCategoryList);
    }

    /**
     * Used to set the playing artist and title as live time changes
     * @param {LiveTime} liveTime
     */
    public setLivePdt(liveTime: LiveTime): void
    {
        let modified: boolean = false;

        this.liveChannels.forEach((channel: IChannel) =>
        {
            if (channel.markerStartZuluTime
                && channel.markerEndZuluTime
                && liveTime
                && liveTime.zuluSeconds
                && ( liveTime.zuluSeconds >= channel.markerStartZuluTime
                    && liveTime.zuluSeconds < channel.markerEndZuluTime))
            {
                modified = modified ? modified : false;
                return;
            }

            const cutMarkerList: IMarkerList = channel.markerLists ?
                                               channel.markerLists.find((list) => list.layer === ApiLayerTypes.CUT_LAYER) : null;

            if (!cutMarkerList || !cutMarkerList.markers)
            {
                modified = modified ? modified : false;
                return;
            }

            const markers = cutMarkerList.markers as Array<ICutMarker>;
            let marker = markers.find((marker) =>
            {
                const markerEndTime = marker.duration ? marker.time + (marker.duration * 1000) : 0;
                return liveTime.zuluMilliseconds >= marker.time && liveTime.zuluMilliseconds < markerEndTime;
            });

            if (!marker)
            {
                marker = markers.find((marker) => marker.time < liveTime.zuluMilliseconds);
                marker = !marker ? markers[ 0 ] : markers[ markers.length - 1];
            }

            if (!marker || !marker.cut || !marker.cut.artists)
            {
                modified = modified ? modified : false;
                return;
            }

            const artist = marker && marker.cut && marker.cut.artists
                ? marker.cut.artists.find((artist: any) => artist.name) || { name: "" } : "";

            const artistName   = artist && artist.name ? artist.name : "";
            const cutTitle     = marker && marker.cut && marker.cut.title ? marker.cut.title : "";


            if (channel.playingArtist !== artistName
                || channel.playingTitle !== cutTitle)
            {
                channel.playingArtist       = artistName;
                channel.playingTitle        = cutTitle;
                channel.markerStartZuluTime = marker.time;
                channel.markerEndZuluTime   = marker.duration ? marker.time + (marker.duration * 1000) : 0;
                modified                    = true;
            }
        });

        if(modified)
        {
            this.channelsSubject.next(this.liveChannels);
        }

    }

    /**
     * mark the given favorited channels in the lineup and in all the subcategory channel lists that the channel is
     * present in
     * @param favoriteChannel contains the information about the channel to be favorited
     * @param channelLineup is the complete list of channels in the lineup where the favorite will be recorded
     */
    private static favoriteChannel(favoriteChannel: IFavoriteItem, channelLineup: Array<IChannel>)
    {
        if (channelLineup && channelLineup.length > 0)
        {
            const channelInLineup = channelLineup.find((channel : IChannel) =>
                                                       channel.channelId === favoriteChannel.channelId);

            if (channelInLineup)
            {
                channelInLineup.isFavorite = true;
            }
            else
            {
                ChannelLineup.logger.error(`${favoriteChannel.channelId} favorited but is not present in lineup`);
            }
        }
    }

    /**
     * Find the supercategory for the given subcategory
     *
     * @param subCategoryGUID
     * @param superCategories
     * @returns {ISuperCategory|T}
     */
    private static findSuperCategoryForSubCategory(subCategoryGUID: string, superCategories: Array<ISuperCategory>): ISuperCategory
    {
        return _.find(superCategories, (superCategory: ISuperCategory) =>
        {
            let found = _.find(superCategory.categoryList, (category: ISubCategory) =>
            {
                return category.categoryGuid === subCategoryGUID;
            });

            return (found !== undefined);
        });
    }

    /**
     * Find a subcategory in the supercategories array that matches the given GUID
     *
     * @param categoryGUID is the GUID of the caregory to search for
     * @param superCategories
     * @returns {ISubCategory}
     */
    private static findSubCategory(categoryGUID: string, superCategories: Array<ISuperCategory>): ISubCategory
    {
        let superCategory: ISuperCategory = ChannelLineup.findSuperCategoryForSubCategory(categoryGUID, superCategories);

        if (superCategory && superCategory.categoryList)
        {
            return _.find(superCategory.categoryList,
                          (category: ISubCategory) => category.categoryGuid === categoryGUID);
        }

        return undefined;
    }

    /**
     * Take a channel from the lineup call and add the properties from the discover-channel-list channels into the
     * lineup channel, then mergd that channel into wherever it shows up in the sub categories within the given
     * supercategories object
     *
     * @param lineupChannel is the channel from the channel lineup call
     * @param liveChannel is the channel from the discover-channel-list call
     * @returns {IChannel}
     */
    private static mergeLiveToLineup(lineupChannel: IChannel,
                                     liveChannel: IBaseChannel): IChannel
    {
        if (!lineupChannel) { return; }

        Object.keys(liveChannel)
            .forEach((property: string) =>
            {
                if(lineupChannel)
                {
                    lineupChannel[ property ] = liveChannel[ property ];
                }
            });

        return lineupChannel;
    }
}
