import {
  AffinityType,
  IArtist,
  IChannel,
  IConnectInfo,
  IDmcaInfo,
  IDrmInfo,
  IImage,
  ILegacyId,
  IShowImage,
} from '../index';
import { IPlayhead } from '../mediaplayer';
import { MultiTrackList } from '../mediaplayer/multitrackaudioplayer/multi-track-list';
import { IModalData } from './modal.interface';

export interface ITime {
  zuluStartTime: number; //API delivers
  isoStartTime: string;
  zeroStartTime: number;
  zuluEndTime?: number;
  isoEndTime?: string;
  zeroEndTime?: number;
}

export interface IMediaItem {
  assetGUID: string;
  layer: string;
  times: ITime; // API delivers absolute(start,end) & time(start,end), gets normalized to this.
}

export interface ILiveVideoReminder {
  channel: IChannel;
  videos?: Array<IMediaVideo>;
  videoAlertedFor?: IMediaVideo;
}

export interface ICurrentlyPlayingMedia {
  playhead?: IPlayhead;
  firstCut: IMediaCut;
  video: IMediaVideo;
  cut: IMediaCut;
  episode?: IMediaEpisode;
  mediaType: string;
  mediaId: string;
  channelId: string;
  show?: IMediaShow;
  duration?: number;
  dmcaInfo: IDmcaInfo;
  youJustHeard?: Array<IYouJustHeard>;
  inactivityTimeOut?: number;
  cutEntryTimestamp?: number; // NOTE: only tracks cut changes, cannot be used for general purpose monitor of playhead
  channel?: IChannel;
  stationId?: string;
}

export interface IMediaVideo extends IMediaItem {
  airingType: string;
  clientMessage: string;
  concurrentAudioCutGuid: string;
  description: string;
  episodeGUID: string;
  liveEpisodeGuid: string;
  liveVideoStatus: string;
  liveVideoUrl: string;
  showGuid: string;
  title: string;
  videoStreamTimestamp: string;
  zeroStartTime: number;
  duration: number;
  consumptionInfo: string;
}

export interface IYouJustHeard {
  albumImageUrl: string;
  title: string;
  artistName: string;
  cut: IMediaCut;
}

export interface ILiveVideoPDT {
  showName?: string;
  trackName: string;
  artistName: string;
}

export interface IMediaCut extends IMediaItem {
  album?: any;
  affinity?: AffinityType;
  artists?: Array<IArtist>;
  consumptionInfo: string;
  duration?: number;
  legacyId: ILegacyId; //API delivers legacyIds, gets normalized to this.
  title: string;
  cutContentType: string; // API delivers cutContentType and saves to this. Note: Only this property used
  // keep tracking API Value. Dont use this .
  contentType: string;
  lastPlaybackTimestamp?: number;
  galaxyAssetId?: string;
  stationFactory?: string;
  liveGame?: boolean;
}

export interface IMediaLiveCuePoint extends IMediaItem {
  event: string;
}

export interface IMediaScoreUpdate extends IMediaItem {
  awayScore: number;
  division: string;
  homeScore: number;
  liveEventGuid: string;
  possession: string;
  state: string;
}

export interface IMediaSegment extends IMediaItem {
  duration?: number;
  legacyId: ILegacyId;
  longDescription: string;
  shortDescription: string;
  endTimeInSeconds: number;
  startTimeInSeconds: number;
  title?: string;
  segmentType?: string; // API passes this back
  type: string; // Will get normalized to this.
  style?: string;
}

export interface IMediaDescription {
  longDescription: string;
  longTitle: string;
  mediumTitle: string;
  shortDescription: string;
}

export interface IMediaEpisode extends IMediaDescription, IMediaItem {
  allowDownload: boolean;
  backlotEmbedCode?: boolean;
  disableAllBanners: boolean;
  disableAutoBanners: boolean;
  disableRecommendations: boolean;
  dmcaInfo: IDmcaInfo;
  drmInfo?: IDrmInfo;
  episodeGUID: string;
  images?: Array<IImage>;
  offlinePlayback?: {
    highProfile: string;
    lowProfile: string;
    mediumProfile: string;
  };
  highlighted?: boolean;
  hosts?: Array<IHost>;
  hot?: boolean;
  isFavorite?: boolean;
  legacyId?: ILegacyId; //API delivers Legacyid.shortId, gets normalized to this.
  originalIsoAirDate: string; //API delivers originalAirDate, gets normalized to this.
  originalAirDate?: string;
  publicationInfo?: {
    publicationDate: string;
    expiryDate: string;
    identifier: string;
    accessControlIdentifier: string;
  };
  rating?: string;
  show: IMediaShow;
  valuable?: boolean;
  cuts?: Array<IMediaCut>;
  videos?: Array<IMediaVideo>;
  originalSegments?: Array<IMediaSegment>;
  segments?: Array<IMediaSegment>;
  duration?: number;
  title?: string;
  vodDuration?: string;
  vodEpisodeGUID?: string;
}

export interface IHost {
  personGUID: string;
}

export interface IMediaShow extends IMediaDescription, IMediaItem {
  aodEpisodeCount?: number;
  connectInfo: IConnectInfo;
  disableRecommendations: boolean; //API delivers Array of strings, gets normalized to this.
  futureAirings: Array<IFutureAiring>;
  images: Array<IShowImage>;
  isFavorite?: boolean;
  isPlaceholderShow: boolean;
  isLiveVideoEligible: boolean;
  legacyId: ILegacyId; //API delivers Legacy id.shortId, gets normalized to this.
  programType: string;
  vodEpisodeCount?: number;
  type: string;
  channelId: string;
  hideFromChannelList?: boolean;
  isPodcast?: boolean;
  isIrisPodcast?: boolean;
}

export interface IFutureAiring {
  channelId: string;
  duration: number;
  isoTime: string;
}

export interface IChunk {
  interChunkOffset: number;
  isoTimestamp: string;
  zuluTimestamp: number;
  key: string;
  keyUrl: string;
  url: string;
}

export interface IPosition {
  isoTimestamp: string; //API delivers timeStamp, gets normalized to this.
  zuluTimestamp: number;
  positionType: string; //API delivers position, gets normalized to this.
}

export interface IMediaEndPoint {
  mediaFirstChunks: Array<IChunk>; // API delivers chunks.chunks, gets normalized to this.
  manifestFiles: Array<IHlsManifestFile>; //API delivers hlsAudioInfos, gets normalized to this.
  name: string;
  position: IPosition;
  size: string;
  url: string;
  type?: string;
}

export interface IHlsManifestFile {
  name: string;
  size: string;
  url: string;
}

export interface ICrossfade {
  crossFade: number;
  fade: number;
  fadeDownDuration: number;
  fadeDownPos: number;
  fadeUpDuration: number;
  fadeUpPos: number;
  introPos: number;
  outroPos: number;
}

// IClips are also Tracks.
export interface IClip extends IMediaItem {
  albumName: string;
  artistName: string;
  assetType: string;
  category: string;
  clipImageUrl: string;
  consumptionInfo: string;
  mediaEndPoints: Array<IMediaEndPoint>;
  crossfade: ICrossfade;
  duration: number;
  title: string;
  previousTrack?: IClip;
  nextTrack?: IClip;
  status: TrackStatus;
  contentUrlList: any;
  index: number;
  affinity?: AffinityType;
  galaxyAssetId?: string;
  stationFactory?: string;
}

/**
 * type that allows for the different type of clip status.
 */
export type TrackStatus =
  | 'ENDED'
  | 'SKIP'
  | 'ERROR'
  | 'CURRENT'
  | 'FUTURE'
  | 'PRELOAD';

// Create a type that allows for different types of Media Items each containing a `duration` property.
export type IMediaItemWithDuration =
  | IMediaCut
  | IMediaEpisode
  | IMediaVideo
  | IMediaSegment;

export class MediaTimeLine {
  zuluOffset: number;
  mediaType: string; // API delivers this as moduleType, gets normalized to this
  channelId: string; // for live, same as mediaId, for on demand, the channelId for the episode, for seeded it is null.
  mediaId: string; // Can be a id for LIVE channel, or guid for AOD show, mediaType dictates this, for seeded it is stationFactory.
  connectInfo: IConnectInfo;
  liveCuePoint: IMediaLiveCuePoint;
  videos?: Array<IMediaVideo>;
  clips?: MultiTrackList;
  cuts: Array<IMediaCut>; // API delivers cuePointList.cuePoints , gets normalized to this.
  originalSegments?: Array<IMediaSegment>;
  segments: Array<IMediaSegment>;
  episodes: Array<IMediaEpisode>;
  futureEpisodes: Array<IMediaEpisode>;
  shows: Array<IMediaShow>;
  scoreUpdates: Array<IMediaScoreUpdate>;
  mediaEndPoints?: Array<IMediaEndPoint>; //API delivers customAudioInfos, gets normalized to this.
  hlsConsumptionInfo: string;
  aodEpisodeCount: number;
  isDataComeFromResume: boolean;
  isDataComeFromResumeWithDeepLink: boolean;
  inactivityTimeOut?: number;
  playerType?: string; // Remote (Casting) or Local (audio/video)
  startTime?: number;
  sequencerSessionId?: string;
  wallClockRenderTime: number;
  channel?: IChannel;
  stationId?: string;
  streamingAdsId?: string;
  forceRetune?: boolean;
}

export class TuneResponse extends MediaTimeLine {
  updateFrequency: number;
}

export interface TunePayload {
  channelId: string; // Holds channelId for the live channels, channelGuid is for additional channels,
  // stationFactory for seeded channels.
  // TODO - vpaindla do better way to handle id's
  contentType: string;
  channel?: IChannel;
  episodeIdentifier?: string; // from neritic link spec
  // = publicationInfo.accessControlIdentifier (caid) for aod and vodEpisodeGuid for vod
  startTime?: number;
  modelData?: IModalData;
  showGuid?: string; //need to send this showGuid for VOD's ( accomadated with
  // Additional Videos feature where a single VOD can be part of multiple shows
  isPandoraPodcast?: boolean; // true if it is pandora podcast
  cutAssetGuid?: string; // live channel current cut asset guid
}
