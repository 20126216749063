import { IProviderDescriptor } from './provider.descriptor.interface';

/*
 * This gets filled in at runtime when the service lib is initialized.  Each class that wants to be exported to the
 * outside world should add a descriptor to this array so that the client knows what is available and how to perform
 * any needed dependency injection
 */
export const providers: Array<any> = [];

/*
 * Add a provider descriptor for a given provider, and return that new descriptor to the caller
 *
 * @param provide is the name of the provider (usually the class)
 * @param useClass is the class to create when an instance of the provider is needed
 * @param dependencies is an (optional, defaults to []) array of dependencies for creating instance(s)
 * @param singleton is true (the default) if this provider is a singleton and false otherwise
 */
export function addProvider(
  provide: Function,
  useClass: Function,
  dependencies: Array<any> = [],
  singleton = true,
): IProviderDescriptor {
  const providerExists = providers.find(
    (provider: IProviderDescriptor) => provider.provide === provide,
  );

  if (!!providerExists) {
    return providerExists;
  }

  const providerDescriptor: IProviderDescriptor = {
    provide: provide,
    useClass: useClass,
    singleton: singleton,
    deps: dependencies,
  };

  providers.push(providerDescriptor);

  return providerDescriptor;
}

/*
 * Add the application config "provider" to the list of providers for dependency injection.  This will also
 * inject the app config dependency into any providers that need it.
 */
export function injectAppConfig(APP_CONFIG: any) {
  providers.forEach((provider: IProviderDescriptor) => {
    if (!provider.deps) {
      return;
    }

    provider.deps.forEach((dependency: any, index: number) => {
      // IAppConfig is an interface, and therefore only a compile time construct.  Class that need IAppConfig
      // as a dependency for injection into their constructor can put the string "IAppConfig" into the deps
      // array, which will indicate that the implementation from APP_CONFIG.provide should be placed into the
      // array as a dependency in place of "IAppConfig".  This way, we can specify the interface for IAppConfig
      // and then provide a concrete implementation of that interface from the client at runtime, which can then
      // be used to satisfy the need for IAppConfig.
      if (dependency === 'IAppConfig') {
        provider.deps[index] = APP_CONFIG.provide;
      }
    });
  });

  providers.unshift(APP_CONFIG);
}

/*
 * Add the audio player "provider" to the list of providers for dependency injection.  This will also
 * inject the audio player dependency into any providers that need it.
 */
export function injectAudioPlayer(AUDIO_PLAYER: any) {
  providers.forEach((provider: IProviderDescriptor) => {
    if (!provider.deps) {
      return;
    }

    provider.deps.forEach((dependency: any, index: number) => {
      // IAudioPlayer is an interface, and therefore only a compile time construct.  Class
      // that need IAppConfig as a dependency for injection into their constructor can put
      // the string "IAppConfig" into the deps array, which will indicate that the
      // implementation from AUDIO_PLAYER.provide should be placed into the array as a
      // dependency in place of "IAudioPlayer".  This way, we can specify the interface for
      // IAudioPlayer and then provide a concrete implementation of that interface from the
      // client at runtime, which can then be used to satisfy the need for IAudioPlayer.
      if (dependency === 'IAudioPlayer') {
        provider.deps[index] = AUDIO_PLAYER.provide;
      }
    });
  });

  providers.unshift(AUDIO_PLAYER);
}

/**
 * Find the provider descriptor for a given class
 *
 * @param {Function} classConstructor is the constructor for the class we want the provider descriptor for
 * @returns {IProviderDescriptor} the provider descriptor for the class, of undefined if none exists
 */
export function findProvider(classConstructor: Function): IProviderDescriptor {
  return providers.find((provider: IProviderDescriptor) => {
    return provider.useClass === classConstructor;
  });
}

/*
 * Add the video player "provider" to the list of providers for dependency injection.  This will also
 * inject the video player dependency into any providers that need it.
 */
export function injectVideoPlayer(VIDEO_PLAYER: any) {
  providers.forEach((provider: IProviderDescriptor) => {
    if (!provider.deps) {
      return;
    }

    provider.deps.forEach((dependency: any, index: number) => {
      // IVideoPlayer is an interface, and therefore only a compile time construct.  Class
      // that need IAppConfig as a dependency for injection into their constructor can put
      // the string "IAppConfig" into the deps array, which will indicate that the
      // implementation from VIDEO_PLAYER.provide should be placed into the array as a
      // dependency in place of "IVideoPlayer".  This way, we can specify the interface for
      // IVideoPlayer and then provide a concrete implementation of that interface from the
      // client at runtime, which can then be used to satisfy the need for IAudioPlayer.
      if (dependency === 'IVideoPlayer') {
        provider.deps[index] = VIDEO_PLAYER.provide;
      }
    });
  });

  providers.unshift(VIDEO_PLAYER);
}
