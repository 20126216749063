import { share, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { IConfig } from './interfaces/config.interface';

import {
  IProviderDescriptor,
  IHttpRequestConfig,
  addProvider,
  HttpProvider,
  Logger,
  ServiceEndpointConstants,
  MediaPlayerConstants,
} from '../index';
import { IAppConfig } from './interfaces/app-config.interface';

/**
 * @MODULE:     service-lib
 * @CREATED:    07/11/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *      This delegate is responsible for making the API get configuration call and returning a known
 *      client domain model representing the config data.
 */
export class ConfigDelegate {
  /**
   * Internal logger.
   * @type {Logger}
   */
  private static logger: Logger = Logger.getLogger('ConfigDelegate');

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(ConfigDelegate, ConfigDelegate, [
      HttpProvider,
      'IAppConfig',
    ]);
  })();

  /**
   * Constructor
   * @param http - The http object is used to make HTTP API requests.
   */
  constructor(private http: HttpProvider, private SERVICE_CONFIG: IAppConfig) {}

  /**
   * Make the HTTP API call to get the application configuration and return it as a known domain  model.
   *
   * @returns {Observable<IConfig>}
   */
  public getConfig(): Observable<IConfig> {
    ConfigDelegate.logger.debug('getConfig()');

    const url: string = ServiceEndpointConstants.endpoints.CONFIG.V2_GET;

    const config: IHttpRequestConfig = {
      params: {
        //'result-template': this.SERVICE_CONFIG.deviceInfo.player, // based off audio player type
        'result-template': this.SERVICE_CONFIG.resultTemplate, //TODO: Use this for comcast devices
        'app-region': '', // will be filled in by the request interceptor based on the runtime config
        deviceMake: 'vizioeverest', //TODO: Use this for comcast devices
      },
    };

    return this.http.get(url, null, config).pipe(
      map(response => {
        ConfigDelegate.logger.debug(
          `getConfigResult( Contains ${response.configuration.components.length} components. )`,
        );

        // NOTE: If we wanted to only grab specific pieces of data from the response and map it to a model
        // or model interface we'd do so here, but since we want the entire data object we'll
        // return the TypeScript object IConfig.
        return response.configuration;
      }),
      share(),
    );
  }
}
