import React, { useEffect, useState, useRef } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { distinctUntilChanged } from 'rxjs/operators';
import xdk, { device } from '@accedo/xdk-core';
import { focusManager } from '@accedo/vdkweb-navigation';
import { pageRedux, setPageDefaultActions } from '@accedo/vdkweb-tv-ui';

import { ProtectedRoute } from './routing/protected-route.component';
import { appRouteConstants } from './routing/app.route.constants';

import { NavigationMenu } from './components/navigation-menu/navigation-menu.component';
import {
  SplashComponent,
  Loading,
  SearchResultComponent,
  EpisodeComponent,
  EnhancedComponent,
  OpenAccessConfirmationComponent,
  OpenAccessExpiredComponent,
  LoginComponent,
  LoginConfirmationComponent,
  WelcomeComponent,
  SearchComponent,
  RecentComponent,
  NowPlayingComponent,
  FavoritesComponent,
  HomeComponent,
  PodcastsVideosComponent,
  CollectionComponent,
  CategoryComponent,
  ViewAllPageComponent,
  AllChannelsComponent,
  SettingsComponent,
  ActivateDeviceComponent,
  PrivacyPolicyComponent,
} from './AppLayout';

import './preflight';
import './App.scss';
import { useAuth } from './auth/provide-auth';

import {
  ServiceFactory,
  StorageService,
  StorageKeyConstant,
  SettingsConstants,
  VideoPlayerService,
  SessionMonitorService,
  APP_ERROR_STATE_CONST,
  AuthenticationService,
  BypassMonitorService,
  MediaPlayerService,
  SettingsService,
} from './servicelib';
import { NavigationService } from './sxmservicelayer/navigation/navigation.service';
import {
  pageRight,
  pageLeft,
  keyPressed,
  ffwKeyPressed,
  rewindKeyPressed,
  playPauseKeyPressed,
  stopKeyPressed,
  doubleTapRight,
  doubleTapLeft,
} from './redux/action/xdk.action';
import { HandleAutoplayOnStartup } from './components/handle-autoplay-on-startup';
import { InactivityTracker } from './components/inactivity-tracker';
import Modal from './components/Modal/Modal';
import UpNext from './components/up-next/up-next.component';
import { MENU, NOW_PLAYING } from './utils/navigationMap';
import { SkippingTracker } from './components/skipping-tracker';
import { showNavBar } from './components/navigation-menu/navigation-menu-utils';
import FreeTrialTracker from './components/free-trial-counter/free-trial-tracker.component';
import { ttsPlayback } from './utils/ttsPlayback.util';

let hasSXMPlayerInitialized = false;
// declare const $badger: any;

const VIDEO_WRAPPER_ID = 'video-wrapper';
const AUTOPLAY_STATES = {
  INITIALIZATION: 'INITIALIZATION',
  AUTOPLAY: 'AUTOPLAY',
  COMPLETE: 'COMPLETE',
};
const unauhtenticatedPaths = [
  appRouteConstants.FT_WELCOME,
  appRouteConstants.AUTH.LOGIN,
  appRouteConstants.AUTH.LOGIN_CONFIRMATION,
  appRouteConstants.AUTH.OPEN_ACCESS_CONFIRMATION,
  appRouteConstants.AUTH.OPEN_ACCESS_EXPIRED,
  appRouteConstants.ACTIVATE_DEVICE,
  appRouteConstants.PRIVACY_POLICY,
];
const exitPathBackButton = [
  appRouteConstants.AUTH.LOGIN_CONFIRMATION,
  appRouteConstants.AUTH.OPEN_ACCESS_CONFIRMATION,
  appRouteConstants.FT_UPSELL,
  appRouteConstants.SUBSCRIBE_SCREEN,
  appRouteConstants.EXPLORE,
  appRouteConstants.ACCESS_NOW,
  appRouteConstants.AUTH.FRICTIONLESS,
];

const welcomeNav = [
  'CORE_MENU',
  'qr-free-button-nav',
  'welcome-sign-in-button',
  'free-trial-button',
  'privacy-policy-button',
];

const initXdk = async (
  callback: (message: string) => void,
  history: any,
  dispatch: any,
  retrieveExitAppPopup: any,
  switchShowExitAppPopup: any,
  setModalData: any,
  EXIT_MODAL_DATA: any,
) => {
  await xdk.load();
  const platform = xdk.system.getDeviceType();

  console.log(`XDK is loaded in the platform: ${platform}`);
  callback(`XDK is loaded in the platform: ${platform}`);

  const { actions: pageActions } = pageRedux;
  const {
    UP,
    DOWN,
    LEFT,
    RIGHT,
    OK,
    BACK,
    EXIT,
    FF,
    RW,
    PLAY,
    PAUSE,
    PLAY_PAUSE,
    STOP,
    NEXT,
    PREV,
  } = xdk.CONSTANT.KEY;
  const KEY_EVENT_PREFIX = 'device:vkey:';
  focusManager.setPersistTrail(true);
  let doubleTapTimeoutId = null;

  setPageDefaultActions({
    backAction: () => {
      history.goBack();
      const exitAppPopupValue = retrieveExitAppPopup();
      if (
        !exitAppPopupValue &&
        focusManager.getCurrentFocus() !== NOW_PLAYING.ID &&
        (exitPathBackButton.includes(history.location.pathname) ||
          welcomeNav.includes(focusManager.getCurrentFocus()) ||
          focusManager.isChildFocused(MENU.CORE_MENU))
      ) {
        switchShowExitAppPopup(true);
        setModalData(EXIT_MODAL_DATA);
      } else {
        switchShowExitAppPopup(false);
        history.goBack();
      }
    },
    exitAction: () => {
      try {
        device.system.exit();
      } catch (e) {
        console.warn(e);
      }
    },
  });
  xdk.environment.addEventListener(xdk.environment.SYSTEM.KEYDOWN, ({ id }) => {
    switch (id) {
      case UP.id:
      case DOWN.id:
      case LEFT.id:
      case RIGHT.id: {
        const direction = `next${id.substring(KEY_EVENT_PREFIX.length)}`;
        focusManager.navigateFocus(direction);
        dispatch(keyPressed());
        const next = focusManager.getCurrentFocus();
        const isNowPlaying = location.pathname.includes(
          appRouteConstants.NOW_PLAYING,
        );
        let tap = false;

        if (history.location.pathname === appRouteConstants.NOW_PLAYING) {
          //Dispatches actions so the right and left key double tap presses for skipping
          //can be detected in the Now Playing screen
          if (id === LEFT.id) {
            if (doubleTapTimeoutId === null) {
              doubleTapTimeoutId = setTimeout(() => {
                dispatch(pageLeft());
                if (next === NOW_PLAYING.ID && isNowPlaying) {
                  tap = true;
                  ttsPlayback('Rewind');
                }
                doubleTapTimeoutId = null;
              }, 350);
            } else {
              clearTimeout(doubleTapTimeoutId);
              doubleTapTimeoutId = null;
              dispatch(doubleTapLeft());
              if (next === NOW_PLAYING.ID && isNowPlaying) {
                tap = true;
                ttsPlayback('Rewind By 15 seconds');
              }
            }
          } else if (id === RIGHT.id) {
            if (doubleTapTimeoutId === null) {
              doubleTapTimeoutId = setTimeout(() => {
                dispatch(pageRight());
                if (next === NOW_PLAYING.ID && isNowPlaying) {
                  tap = true;
                  ttsPlayback('Fast Foward');
                }
                doubleTapTimeoutId = null;
              }, 350);
            } else {
              clearTimeout(doubleTapTimeoutId);
              doubleTapTimeoutId = null;
              dispatch(doubleTapRight());
              if (next === NOW_PLAYING.ID && isNowPlaying) {
                tap = true;
                ttsPlayback('Fast Forward By 15 seconds');
              }
            }
          }
        }
        if (window.previousFocus === next && !tap) {
          ttsPlayback('Navigation is not allowed');
        }
        window.previousFocus = next;

        break;
      }
      case OK.id:
        focusManager.click();
        dispatch(keyPressed());
        break;
      case BACK.id: {
        // Connect BACK KeyDown to dispatch pageAction
        const exitAppPopupValue = retrieveExitAppPopup();
        if (
          !exitAppPopupValue &&
          focusManager.getCurrentFocus() !== NOW_PLAYING.ID &&
          (exitPathBackButton.includes(history.location.pathname) ||
            welcomeNav.includes(focusManager.getCurrentFocus()) ||
            focusManager.isChildFocused(MENU.CORE_MENU))
        ) {
          switchShowExitAppPopup(true);
          setModalData(EXIT_MODAL_DATA);
        } else {
          if (
            !(
              exitAppPopupValue &&
              history.location.pathname.includes(appRouteConstants.AUTH.LOGIN)
            )
          ) {
            dispatch(pageActions.pageBack());
          }

          switchShowExitAppPopup(false);
          dispatch(keyPressed());
        }
        break;
      }
      case EXIT.id:
        // Connect EXIT KeyDown to dispatch pageAction
        switchShowExitAppPopup(true);
        setModalData(EXIT_MODAL_DATA);
        break;
      case FF.id:
      case NEXT.id:
        dispatch(keyPressed());
        dispatch(ffwKeyPressed());
        break;
      case RW.id:
      case PREV.id:
        dispatch(keyPressed());
        dispatch(rewindKeyPressed());
        break;
      case PLAY.id:
      case PAUSE.id:
      case PLAY_PAUSE.id:
        dispatch(keyPressed());
        dispatch(playPauseKeyPressed());
        break;
      case STOP.id:
        dispatch(keyPressed());
        dispatch(stopKeyPressed());
        break;
      default:
        break;
    }
  });
};

const App = props => {
  const [, setMessage] = useState('Loading XDK...');
  const [dismissSplashScreen, shouldDismissSplashScreen] = useState(false);
  const [isCoreMenuOpen, setIsCoreMenuOpen] = useState(true);
  const [autoplayOnStartup, setAutoplayOnStartup] = useState(
    AUTOPLAY_STATES.INITIALIZATION,
  );
  const [hasError, setHasError] = useState(false);
  const [isOnFreeTrial, setIsOnFreeTrial] = useState(false);
  const showExitAppPopup = useRef(false);
  const { actions: pageActions } = pageRedux;
  const history = useHistory();
  const dispatch = useDispatch();
  const [, setRand] = useState(0);

  const SIMULTANEOUS_LISTENING_MODAL_DATA = {
    title:
      'Only one device can be listening at a time and another is logged in.',
    text: 'Would you like to continue on this device?',
    actions: [
      {
        label: 'Continue',
        action: () => {
          const authenticationService = ServiceFactory.getInstance(
            AuthenticationService,
          ) as AuthenticationService;
          const mediaPlayerService = ServiceFactory.getInstance(
            MediaPlayerService,
          ) as MediaPlayerService;
          mediaPlayerService?.mediaPlayer?.resume().subscribe();
          authenticationService.reclaimSession();
          setHasError(false);
        },
      },
      {
        label: 'Cancel',
        action: () => {
          handleExitApp();
          setHasError(false);
        },
      },
    ],
  };

  const EXIT_MODAL_DATA = {
    title: 'Are you sure you want to exit?',
    text: '',
    actions: [
      {
        label: 'No',
        action: () => handleCloseExitAppPopup(),
      },
      {
        label: 'Yes',
        action: () => handleExitApp(),
      },
    ],
  };

  const [modalData, setModalData] = useState(EXIT_MODAL_DATA);
  useEffect(() => {
    if (dismissSplashScreen) {
      const sessionMonitorService = ServiceFactory.getInstance(
        SessionMonitorService,
      ) as SessionMonitorService;
      const mediaPlayerService = ServiceFactory.getInstance(
        MediaPlayerService,
      ) as MediaPlayerService;

      sessionMonitorService.appErrorState.subscribe({
        next: payload => {
          if (
            payload.IS_SIMULTANEOUS_LISTEN ===
            APP_ERROR_STATE_CONST.SIMULTANEOUS_LISTEN_ENABLED
          ) {
            mediaPlayerService?.mediaPlayer?.pause().subscribe();
            setModalData(SIMULTANEOUS_LISTENING_MODAL_DATA);
            setHasError(true);
          }
        },
      });
    }
  }, [dismissSplashScreen]);

  useEffect(() => {
    initXdk(
      setMessage,
      history,
      dispatch,
      retrieveExitAppPopup,
      switchShowExitAppPopup,
      setModalData,
      EXIT_MODAL_DATA,
    );
  }, []);

  const handleExitApp = () => {
    try {
      dispatch(pageActions.pageExit());
      xdk.system.exit();
      if (device?.platform?.toLowerCase() === 'vizio-smartcast') {
        document.removeEventListener('VIZIO_LIBRARY_DID_LOAD', () => {
          console.warn('REMOVING VIZIO LIBRARY LISTENER');
        });
      }
    } catch (e) {
      console.warn(e);
    }
  };

  const switchShowExitAppPopup = (val: boolean) => {
    showExitAppPopup.current = val;
    setRand(Math.random());
  };

  const retrieveExitAppPopup = () => {
    return showExitAppPopup.current;
  };

  const handleCloseExitAppPopup = () => {
    switchShowExitAppPopup(false);
  };

  const auth: any = useAuth();
  useEffect(() => {
    if (dismissSplashScreen) {
      /*
      const mediaPlayerService = ServiceFactory.getInstance(
        MediaPlayerService,
      ) as MediaPlayerService;
      */

      const onGlobalKeyDown = event => {
        const { keyCode } = event;

        if (
          keyCode === 37 ||
          keyCode === 38 ||
          keyCode === 39 ||
          keyCode === 40
        ) {
          //Prevents undesired scrolling when up and down arrow keys are pressed or going back
          event.preventDefault();
        }
      };

      window.addEventListener('keydown', onGlobalKeyDown);

      return () => window.removeEventListener('keydown', onGlobalKeyDown);
    }
  }, [dismissSplashScreen]);

  useEffect(() => {
    const navigationService = ServiceFactory.getInstance(
      NavigationService,
    ) as NavigationService;
    navigationService.history = history;
  }, []);

  useEffect(() => {
    window.previousFocus = focusManager.getCurrentFocus();
  }, [history.location]);

  useEffect(() => {
    if (
      dismissSplashScreen &&
      autoplayOnStartup === AUTOPLAY_STATES.INITIALIZATION
    ) {
      const storageService = ServiceFactory.getInstance(
        StorageService,
      ) as StorageService;

      if (!storageService.getItem(StorageKeyConstant.AUTOPLAY_ON_STARTUP)) {
        storageService.setItem(
          StorageKeyConstant.AUTOPLAY_ON_STARTUP,
          SettingsConstants.ON,
        );
      }
      const isAutoplayOnStartupOn =
        storageService.getItem(StorageKeyConstant.AUTOPLAY_ON_STARTUP) ===
        SettingsConstants.ON;
      if (isAutoplayOnStartupOn) {
        setAutoplayOnStartup(AUTOPLAY_STATES.AUTOPLAY);
      }
    }
  }, [dismissSplashScreen, autoplayOnStartup]);

  const shouldLoadWelcomeVideo = () => {
    if (!auth) {
      return true;
    } else if (
      auth &&
      auth.userSession &&
      Object.keys(auth.userSession).length === 0
    ) {
      return true;
    } else if (
      auth &&
      auth.userSession &&
      auth.userSession.username === 'null'
    ) {
      return true;
    } else if (
      auth &&
      auth.userSession &&
      (!auth.userSession.username ||
        (auth.userSession.username && !auth.userSession.authenticated))
    ) {
      //Username could still be populated but resume API will mark the user as not authenticated, so they will still get redirected to the Welcome screen
      return true;
    } else {
      false;
    }
  };
  const videoWrapperRef = useRef(null);
  const isMounted = useRef(false);
  const loadSXMPlayer = !shouldLoadWelcomeVideo() && !hasSXMPlayerInitialized;
  useEffect(() => {
    if (
      isMounted &&
      isMounted.current &&
      dismissSplashScreen &&
      loadSXMPlayer
    ) {
      const videoPlayerService = ServiceFactory.getInstance(
        VideoPlayerService,
      ) as VideoPlayerService;

      if (videoWrapperRef && videoWrapperRef.current) {
        //The videoPlayerService must only be initialized ONCE per app execution
        hasSXMPlayerInitialized = true;
        videoPlayerService.init(videoWrapperRef.current, VIDEO_WRAPPER_ID);
        videoPlayerService.subscribeToStartVideo();
      }
    } else {
      isMounted.current = true;
    }
  }, [videoWrapperRef.current, dismissSplashScreen, loadSXMPlayer]);

  /**** GUP AND IT BYPASS MODAL LOGIC - START  ******/

  const bypassSubscriptionRef = useRef(null);
  const [scanForBypass, setScanForBypass] = useState(false);
  useEffect(() => {
    if (
      dismissSplashScreen &&
      auth &&
      auth.userSession &&
      auth.userSession.authenticated &&
      auth.userSession.username
    ) {
      const bypassMonitorService = ServiceFactory.getInstance(
        BypassMonitorService,
      ) as BypassMonitorService;

      if (scanForBypass) {
        bypassSubscriptionRef.current &&
          bypassSubscriptionRef.current.unsubscribe();
      }

      bypassSubscriptionRef.current = bypassMonitorService.bypassErrorState
        .pipe(
          distinctUntilChanged((prev, current) => {
            return (
              prev.GUP_BYPASS === current.GUP_BYPASS &&
              prev.GUP_BYPASS2 === current.GUP_BYPASS2 &&
              prev.IT_BYPASS === current.IT_BYPASS &&
              prev.SEARCH_BYPASS === current.SEARCH_BYPASS
            );
          }),
        )
        .subscribe(bypassErrorState => {
          if (bypassErrorState.GUP_BYPASS || bypassErrorState.GUP_BYPASS2) {
            const modalData = {
              title: "We're experiencing some technical difficulties.",
              text:
                'Some of your settings and preferences are temporarily unavailable.',
              actions: [
                {
                  label: 'OK',
                  action: () => {
                    setHasError(false);
                  },
                },
              ],
            };
            console.log(focusManager.getCurrentFocus());
            setModalData(modalData);
            setHasError(true);
            setTimeout(() => {
              //Focus is getting lost when the page loads, so we need to restore it.
              focusManager.changeFocus('CONTAINER');
            }, 50);
          } else if (bypassErrorState.IT_BYPASS) {
            const modalData = {
              title: "We're experiencing some technical difficulties.",
              text: 'Please try again later.',
              actions: [
                {
                  label: 'Dismiss',
                  action: () => {
                    const authenticationService = ServiceFactory.getInstance(
                      AuthenticationService,
                    ) as AuthenticationService;
                    authenticationService.reclaimSession();
                    setHasError(false);
                  },
                },
              ],
            };
            setModalData(modalData);
            setHasError(true);
            setTimeout(() => {
              //Focus is getting lost when the page loads, so we need to restore it.
              focusManager.changeFocus('CONTAINER');
            }, 50);
          } else if (bypassErrorState.SEARCH_BYPASS) {
            const modalData = {
              title: "Sorry, we're experiencing some technical difficulties.",
              text: 'Search is currently unavailable.',
              actions: [
                {
                  label: 'OK',
                  action: () => {
                    setHasError(false);
                  },
                },
              ],
            };
            setModalData(modalData);
            setHasError(true);
            setTimeout(() => {
              //Focus is getting lost when the page loads, so we need to restore it.
              focusManager.changeFocus('CONTAINER');
            }, 50);
          }
        });
    }
  }, [
    dismissSplashScreen,
    auth && auth.userSession && auth.userSession.authenticated,
    scanForBypass,
  ]);

  /**** GUP AND IT BYPASS MODAL LOGIC - END  ******/

  const [showUpNext, setShowUpNext] = useState(false);
  const [isAutoPlayOn, setIsAutoPlayOn] = useState(false);
  useEffect(() => {
    if (dismissSplashScreen) {
      const settingsService = ServiceFactory.getInstance(
        SettingsService,
      ) as SettingsService;

      const subscription = settingsService.settings.subscribe(() => {
        setIsAutoPlayOn(
          settingsService.getGlobalSettingValue(SettingsConstants.AUTO_PLAY) ===
            SettingsConstants.ON,
        );
      });

      return () => subscription.unsubscribe();
    }
  }, [dismissSplashScreen]);

  const location = useLocation();
  const [isSkippingTrackerEnabled, enableSkippingTracker] = useState(false);
  useEffect(() => {
    if (location.pathname.includes(appRouteConstants.NOW_PLAYING)) {
      enableSkippingTracker(false);
    } else if (!isSkippingTrackerEnabled) {
      enableSkippingTracker(true);
    }
  }, [location.pathname]);

  const setBackgroundColor = pathname => {
    if (
      location.pathname.includes(appRouteConstants.AUTH.LOGIN_CONFIRMATION) ||
      location.pathname.includes(
        appRouteConstants.AUTH.OPEN_ACCESS_CONFIRMATION,
      ) ||
      location.pathname.includes(appRouteConstants.AUTH.OPEN_ACCESS_EXPIRED)
    ) {
      document.getElementsByTagName('html')[0].style.backgroundColor = 'black';
      return { backgroundColor: 'black' };
    } else {
      document.getElementsByTagName('html')[0].style.backgroundColor =
        '#1d2737';
      return { backgroundColor: null };
    }
  };

  // useEffect(() => {
  //   if ((window as any)?.VIZIO) {
  //     (window as any)?.VIZIO.setContentChangeHandler(contentUrl => {
  //       const query = contentUrl.substring(contentUrl.lastIndexOf('/') + 1);
  //       if (query) {
  //         history.replace(`${appRouteConstants.SEARCH}/${query}`);
  //       }
  //     });
  //   }
  // }, [dismissSplashScreen]);

  useEffect(() => {
    const kochava = props?.kochava;
    kochava.setLogLevel('Info');
    kochava.sendPageEvent();
  }, [location.pathname]);

  return (
    <div
      className={`${
        unauhtenticatedPaths.includes(location.pathname) ? 'app darkBkg' : 'app'
      }`}
      style={setBackgroundColor(location.pathname)}
    >
      <div id={VIDEO_WRAPPER_ID} ref={videoWrapperRef} />
      <Modal
        title={modalData.title}
        text={modalData.text}
        show={hasError || showExitAppPopup.current}
        actions={modalData.actions}
        restoreFocus={focusManager.getCurrentFocus() || 'CORE_MENU'}
      />
      {dismissSplashScreen &&
        auth &&
        auth.userSession &&
        auth.userSession.authenticated &&
        auth.userSession.username &&
        (autoplayOnStartup === AUTOPLAY_STATES.INITIALIZATION ||
          autoplayOnStartup === AUTOPLAY_STATES.AUTOPLAY) && (
          <HandleAutoplayOnStartup
            AUTOPLAY_STATES={AUTOPLAY_STATES}
            setAutoplayOnStartup={setAutoplayOnStartup}
            autoplayOnStartup={autoplayOnStartup}
          />
        )}
      {dismissSplashScreen && isAutoPlayOn && (
        <UpNext setShowUpNext={setShowUpNext} showUpNext={showUpNext} />
      )}
      {dismissSplashScreen && (
        <InactivityTracker
          setModalData={setModalData}
          auth={auth}
          setHasError={setHasError}
          setIsCoreMenuOpen={setIsCoreMenuOpen}
        />
      )}
      {dismissSplashScreen &&
        auth &&
        auth.userSession &&
        auth.userSession.authenticated &&
        auth.userSession.username &&
        isSkippingTrackerEnabled && <SkippingTracker showUpNext={showUpNext} />}
      {dismissSplashScreen &&
        !unauhtenticatedPaths.some(route =>
          location.pathname.includes(route),
        ) && (
          <FreeTrialTracker
            setIsOnFreeTrial={setIsOnFreeTrial}
            isOnFreeTrial={isOnFreeTrial}
            setIsCoreMenuOpen={setIsCoreMenuOpen}
            username={auth.userSession.username}
          />
        )}
      {dismissSplashScreen &&
        auth &&
        auth.userSession &&
        auth.userSession.authenticated &&
        auth.userSession.username &&
        showNavBar(location.pathname) && (
          <NavigationMenu
            isCoreMenuOpen={isCoreMenuOpen}
            setIsCoreMenuOpen={setIsCoreMenuOpen}
            isOnFreeTrial={isOnFreeTrial}
          />
        )}

      <React.Suspense fallback={<Loading />}>
        <Switch>
          <Route exact={dismissSplashScreen} path={'/'}>
            <SplashComponent
              shouldDismissSplashScreen={shouldDismissSplashScreen}
            />
          </Route>
          <ProtectedRoute
            kochava={props?.kochava}
            path={appRouteConstants.FT_WELCOME}
          >
            <WelcomeComponent kochava={props?.kochava} />
          </ProtectedRoute>
          <ProtectedRoute
            kochava={props?.kochava}
            exact
            path={appRouteConstants.AUTH.LOGIN}
          >
            <LoginComponent kochava={props?.kochava} />
          </ProtectedRoute>
          <ProtectedRoute
            kochava={props?.kochava}
            path={appRouteConstants.AUTH.LOGIN_CONFIRMATION}
          >
            <LoginConfirmationComponent
              setScanForBypass={setScanForBypass}
              kochava={props?.kochava}
            />
          </ProtectedRoute>
          <ProtectedRoute
            kochava={props?.kochava}
            path={appRouteConstants.ACTIVATE_DEVICE}
          >
            <ActivateDeviceComponent kochava={props?.kochava} />
          </ProtectedRoute>
          <ProtectedRoute
            kochava={props?.kochava}
            path={appRouteConstants.PRIVACY_POLICY}
          >
            <PrivacyPolicyComponent kochava={props?.kochava} />
          </ProtectedRoute>
          <ProtectedRoute
            kochava={props?.kochava}
            path={appRouteConstants.AUTH.OPEN_ACCESS_CONFIRMATION}
          >
            <OpenAccessConfirmationComponent />
          </ProtectedRoute>
          <ProtectedRoute
            kochava={props?.kochava}
            path={appRouteConstants.AUTH.OPEN_ACCESS_EXPIRED}
          >
            <OpenAccessExpiredComponent />
          </ProtectedRoute>
          <ProtectedRoute
            kochava={props?.kochava}
            path={appRouteConstants.NOW_PLAYING}
          >
            <NowPlayingComponent
              setShowUpNext={setShowUpNext}
              showUpNext={showUpNext}
              isOnFreeTrial={isOnFreeTrial}
              hasError={hasError}
            />
          </ProtectedRoute>

          <>
            <ProtectedRoute
              kochava={props?.kochava}
              path={`${appRouteConstants.HOME}/:superCategory`}
            >
              <HomeComponent />
            </ProtectedRoute>
            <ProtectedRoute
              kochava={props?.kochava}
              path={`${appRouteConstants.FAVORITES}/:superCategory`}
            >
              <FavoritesComponent />
            </ProtectedRoute>
            <ProtectedRoute
              kochava={props?.kochava}
              path={appRouteConstants.RECENTLY_PLAYED}
            >
              <RecentComponent
                isCoreMenuOpen={isCoreMenuOpen}
                setIsCoreMenuOpen={setIsCoreMenuOpen}
              />
            </ProtectedRoute>
            <ProtectedRoute
              kochava={props?.kochava}
              exact
              path={appRouteConstants.SEARCH}
            >
              <SearchComponent
                isCoreMenuOpen={isCoreMenuOpen}
                setIsCoreMenuOpen={setIsCoreMenuOpen}
                isOnFreeTrial={isOnFreeTrial}
              />
            </ProtectedRoute>
            <ProtectedRoute
              kochava={props?.kochava}
              path={`${appRouteConstants.SEARCH}/:query`}
            >
              <SearchComponent
                isCoreMenuOpen={isCoreMenuOpen}
                setIsCoreMenuOpen={setIsCoreMenuOpen}
                isOnFreeTrial={isOnFreeTrial}
              />
            </ProtectedRoute>
            <ProtectedRoute
              kochava={props?.kochava}
              path={appRouteConstants.SEARCH_RESULT}
            >
              <SearchResultComponent />
            </ProtectedRoute>
            <ProtectedRoute
              kochava={props?.kochava}
              path={`${appRouteConstants.CATEGORY_PAGE}/:superCategory`}
            >
              <PodcastsVideosComponent setIsCoreMenuOpen={setIsCoreMenuOpen} />
            </ProtectedRoute>
            <ProtectedRoute
              kochava={props?.kochava}
              path={`${appRouteConstants.ON_DEMAND.EPISODES_LIST}`}
            >
              <EpisodeComponent setIsCoreMenuOpen={setIsCoreMenuOpen} />
            </ProtectedRoute>
            <ProtectedRoute
              kochava={props?.kochava}
              path={`${appRouteConstants.ENHANCED_EDP}`}
            >
              <EnhancedComponent setIsCoreMenuOpen={setIsCoreMenuOpen} />
            </ProtectedRoute>
            <ProtectedRoute
              kochava={props?.kochava}
              path={`${appRouteConstants.COLLECTION}`}
            >
              <CollectionComponent
                setIsCoreMenuOpen={setIsCoreMenuOpen}
                isOnFreeTrial={isOnFreeTrial}
              />
            </ProtectedRoute>
            <ProtectedRoute
              kochava={props?.kochava}
              path={`${appRouteConstants.CATEGORY}/:category/:type`}
            >
              <CategoryComponent
                setIsCoreMenuOpen={setIsCoreMenuOpen}
                isOnFreeTrial={isOnFreeTrial}
              />
            </ProtectedRoute>
            <ProtectedRoute
              kochava={props?.kochava}
              path={`${appRouteConstants.VIEW_ALL}/:carouselId`}
            >
              <ViewAllPageComponent
                setIsCoreMenuOpen={setIsCoreMenuOpen}
                isOnFreeTrial={isOnFreeTrial}
              />
            </ProtectedRoute>
            <ProtectedRoute
              kochava={props?.kochava}
              path={appRouteConstants.ALL_CHANNELS}
            >
              <AllChannelsComponent
                setIsCoreMenuOpen={setIsCoreMenuOpen}
                isOnFreeTrial={isOnFreeTrial}
              />
            </ProtectedRoute>
            <ProtectedRoute
              kochava={props?.kochava}
              path={appRouteConstants.APPLICATION_SETTINGS}
            >
              <SettingsComponent
                isCoreMenuOpen={isCoreMenuOpen}
                setIsCoreMenuOpen={setIsCoreMenuOpen}
                isOnFreeTrial={isOnFreeTrial}
                setAutoplayOnStartup={setAutoplayOnStartup}
                AUTOPLAY_STATES={AUTOPLAY_STATES}
              />
            </ProtectedRoute>
          </>
        </Switch>
      </React.Suspense>
    </div>
  );
};

export default App;
