import { Observable, timer, race, merge, BehaviorSubject } from 'rxjs';
import {
  distinctUntilChanged,
  switchMap,
  filter,
  take,
  map,
  skip,
} from 'rxjs/operators';
import { AppErrorCodes } from '../../service/consts';
import { AppMonitorService } from '../../app-monitor';
import { ErrorService } from '../../error';
import { Logger } from '../../logger';
import { VideoPlayerEventTypes } from './video-player.event-types';
import { VideoPlayerService } from './video-player.service';
import { VideoPlayerConstants } from './video-player.consts';
import { addProvider } from '../../service';
import { IProviderDescriptor } from '../../index';

interface IUnderflowErrorState {
  noVideoSystemErrorState: boolean;
  videoRecoveryTimeoutSystemErrorState: boolean;
}

export class VideoPlayerBufferUnderflowMonitor {
  /**
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(
      VideoPlayerBufferUnderflowMonitor,
      VideoPlayerBufferUnderflowMonitor,
      [ErrorService, AppMonitorService],
    );
  })();
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger(
    'VideoPlayerBufferUnderflowMonitor',
  );

  /**
   * Number of seconds allowed after a buffer underflow before we say video hasn't recovered.
   * @type {number}
   */
  private static RECOVERY_TIMEOUT_SYSTEM_ERROR_PERIOD: number = 60 * 1000;

  private static NO_VIDEO_SYSTEM_ERROR_PERIOD: number = 10 * 1000;

  private videoPlayerService: VideoPlayerService;

  private slowAttemptToPlay$: Observable<boolean>; // true means bad thing happened.

  private bufferRanLow$: Observable<boolean>; // true means bad thing happened

  private recovery$: BehaviorSubject<boolean>; // true means good thing happened

  private underflowErrorState$ = new BehaviorSubject<IUnderflowErrorState>({
    videoRecoveryTimeoutSystemErrorState: false,
    noVideoSystemErrorState: false,
  });

  /**
   * Constructor.
   * @param {ErrorService} errorService
   * @param {AppMonitorService} appMonitorService
   */
  constructor(
    private errorService: ErrorService,
    private appMonitorService: AppMonitorService,
  ) {}

  public setupUnderflowMonitor(videoPlayerService: VideoPlayerService) {
    this.videoPlayerService = videoPlayerService;

    this.recovery$ = new BehaviorSubject<boolean>(true);

    this.videoPlayerService.playbackStateSubject.subscribe(state => {
      if (
        state === VideoPlayerConstants.PAUSED ||
        state === VideoPlayerConstants.PLAYING ||
        state === VideoPlayerConstants.FINISHED
      ) {
        this.recovery$.next(true);

        this.underflowErrorState$.next({
          noVideoSystemErrorState: false,
          videoRecoveryTimeoutSystemErrorState: false,
        });
      }
    });

    this.slowAttemptToPlay$ = merge(
      this.videoPlayerService.startVideo$.pipe(
        filter(startVideo => !!startVideo),
      ),
      this.videoPlayerService.playbackStateSubject.pipe(
        distinctUntilChanged(),
        filter(state => state === VideoPlayerConstants.SEEKING),
      ),
    ).pipe(
      switchMap(() => {
        this.recovery$.next(false);

        return race(
          timer(VideoPlayerBufferUnderflowMonitor.NO_VIDEO_SYSTEM_ERROR_PERIOD),
          this.recovery$.pipe(filter(val => !!val)), // only emit a true recovery
        ).pipe(
          map(val => {
            return val === 0; // true if timer won, false otherwise.
          }),
          take(1),
        );
      }),
    );

    /**
     *  Observe Stalled event from Video Player
     *  if Stalled, display Toast bar when no playhead updates
     */
    this.bufferRanLow$ = this.videoPlayerService.stalled$.pipe(
      switchMap(() => {
        return race(
          timer(VideoPlayerBufferUnderflowMonitor.NO_VIDEO_SYSTEM_ERROR_PERIOD),
          this.videoPlayerService.playhead$.pipe(
            skip(3),
            map((/*val*/) => 1),
          ),
        ).pipe(
          map(val => {
            return val === 0; // true if timer won, false otherwise.
          }),
          take(1),
        );
      }),
    );

    merge(
      this.slowAttemptToPlay$,
      this.bufferRanLow$.pipe(
        filter(
          () =>
            this.videoPlayerService.playbackStateSubject.getValue() !==
            VideoPlayerConstants.SEEKING,
        ),
      ),
    ).subscribe(val => {
      if (val) {
        this.underflowErrorState$.next({
          noVideoSystemErrorState: true,
          videoRecoveryTimeoutSystemErrorState: false,
        });
      }
    });

    this.underflowErrorState$
      .pipe(
        filter(state => !!state.noVideoSystemErrorState),
        switchMap(() => {
          this.recovery$.next(false);

          return race(
            timer(
              VideoPlayerBufferUnderflowMonitor.RECOVERY_TIMEOUT_SYSTEM_ERROR_PERIOD,
            ),
            this.recovery$.pipe(filter(val => !!val)), // only emit true recovery.
          ).pipe(
            map(val => {
              if (val === 0) {
                // if timer won,
                this.underflowErrorState$.next({
                  noVideoSystemErrorState: false,
                  videoRecoveryTimeoutSystemErrorState: true,
                });
              }
            }),
            take(1),
          );
        }),
      )
      .subscribe();

    this.underflowErrorState$.subscribe(
      state => {
        if (
          !state.noVideoSystemErrorState &&
          !state.videoRecoveryTimeoutSystemErrorState
        ) {
          // both false? we're done
          this.clearNoVideoSystemError();
          this.clearVideoRecoveryTimeoutSystemError();
        } else if (state.videoRecoveryTimeoutSystemErrorState) {
          // otherwise if more alarming error has happened
          this.clearNoVideoSystemError();
          this.videoRecoveryTimeoutSystemError();
        } // we poppin' the banner. rofl
        else {
          this.clearVideoRecoveryTimeoutSystemError();
          this.noVideoSystemError();
        }
      },
      err => {
        console.error('this is a problem, monitor obs errored out', err);
      },
    );
  }

  /**
   * Clear the error.
   */
  private clearNoVideoSystemError(): void {
    VideoPlayerBufferUnderflowMonitor.logger.debug(
      'clearBufferUnderflowError()',
    );
    this.errorService.clearError({
      type: VideoPlayerEventTypes.PLAYBACK_READY,
    });
    this.errorService.clearError({ type: VideoPlayerEventTypes.BUFFER_EMPTY });
  }

  private noVideoSystemError(): void {
    VideoPlayerBufferUnderflowMonitor.logger.debug('noVideoSystemError');

    this.errorService.handleError({ type: VideoPlayerEventTypes.BUFFER_EMPTY });
    this.appMonitorService.triggerFaultError({
      faultCode: AppErrorCodes.FLTT_NO_VIDEO_SYSTEM_ERROR,
    });
  }

  clearVideoRecoveryTimeoutSystemError(): void {
    /// ??
  }

  private videoRecoveryTimeoutSystemError(): void {
    VideoPlayerBufferUnderflowMonitor.logger.debug(
      'videoRecoveryTimeoutSystemError',
    );

    this.videoPlayerService.playbackFailed();
    this.appMonitorService.triggerFaultError({
      faultCode: AppErrorCodes.FLTT_VIDEO_RECOVERY_TIMEOUT_SYSTEM_ERROR,
    });
  }
}
