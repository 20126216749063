import React, {
  useState,
  useMemo,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import classNames from 'classnames';

import { focusManager, navigationService } from '@accedo/vdkweb-navigation';
import { composeItemId, getItemsProps } from './swimlaneUtils';
import VisualWindow from './VisualWindow';
import styles from './swimlane.module.scss';
import useLatest from '../../hooks/useLatest';
import { getRelativeVWPixel } from '../../utils/relativePxValues';
import useUpdateEffect from '../../hooks/useUpdateEffect';
import FocusDiv from '../focus-div/FocusDiv';
import { verticalSmoothScroll, verticalScroll } from '../../utils/pageUtils';
import { useSelector } from 'react-redux';
import { getEnableAnimations } from '../../redux/selector/xdk.store';

const MIN_SCROLL = 0;

type Props<ItemDataType, ItemExtraData> = {
  pageId?: string;
  nav: XDKNav;
  data: ItemDataType[];
  itemExtraData?: ItemExtraData;
  hideOverflow?: boolean;
  resetAxisOnItemsChanged?: boolean;
  className?: string;
  headerClassName?: string;
  itemWrapperClassName?: string;
  extraPush?: number;
  category?: string;
  fixedFocus?: boolean;
  displayText?: string;
  shouldScroll?: boolean;
  animation?: boolean;
  component: React.ComponentType<any>;
  onFocus?: (id: string) => void;
  customOnClick?: (data: any) => void;
  onSwimlaneReady?: () => void;
  getItemData?: (item: ItemDataType) => ItemDataType;
};

const Swimlane = <ItemDataType, ItemExtraData>({
  pageId,
  nav,
  data = [],
  itemExtraData,
  hideOverflow = false,
  resetAxisOnItemsChanged = false,
  className = '',
  headerClassName = '',
  itemWrapperClassName = '',
  extraPush = 0,
  category = '',
  fixedFocus = false,
  displayText = '',
  shouldScroll = true,
  animation,
  component,
  onFocus,
  customOnClick,
  onSwimlaneReady,
  getItemData,
}: Props<ItemDataType, ItemExtraData>) => {
  if (!nav) {
    return null;
  }

  const [axis, setAxis] = useState(0);
  const latestAxisRef = useLatest(axis);
  const latestOnFocusRef = useLatest(onFocus);
  const shouldResetAxisRef = useLatest(resetAxisOnItemsChanged);
  const currentIndexRef = useRef(0);
  const [focusTrail, setFocusTrail] = useState<any>({
    lastFocusedEl: nav.id,
    curFocusedEl: '',
  });
  const [isSwimlaneFocused, setIsSwimlaneFocused] = useState(false);
  const isWrapperFocus = useRef(0);
  const enableAnimations = useSelector(getEnableAnimations);

  const onItemBlur = useCallback(
    currentId => {
      if (focusTrail.lastFocusedEl !== nav.id) {
        return;
      }

      setFocusTrail({
        ...focusTrail,
        lastFocusedEl: currentId,
        curFocusedEl: focusManager.getCurrentFocus(),
      });
    },
    [focusTrail],
  );

  const restoreFocusTrail = useCallback((direction: string) => {
    setFocusTrail({
      ...focusTrail,
      lastFocusedEl: nav.id,
      curFocusedEl: '',
    });

    if (direction === 'nextup' && nav.nextup) {
      setIsSwimlaneFocused(false);
    }

    if (direction === 'nextdown' && nav.nextdown) {
      setIsSwimlaneFocused(false);
    }
  }, []);

  const onItemFocus = useCallback(
    index => {
      (document.activeElement as any).blur();
      const wrapper = document.getElementById(nav.id);
      const currentItem = document.getElementById(composeItemId(nav.id, index));
      const currentAxis = latestAxisRef.current;

      if (!currentItem || !wrapper) {
        return;
      }

      if (latestOnFocusRef.current) {
        latestOnFocusRef.current(composeItemId(nav.id, index));
      }

      currentIndexRef.current = index;

      let newAxis = currentAxis;

      if (fixedFocus) {
        newAxis = getFixedFocusScroll(currentItem);
      } else {
        newAxis = getFloatingFocusScroll(currentAxis, currentItem, wrapper);
      }

      if (currentAxis !== newAxis) {
        setAxis(Math.min(newAxis, MIN_SCROLL));
      }
    },
    [extraPush],
  );

  const getFixedFocusScroll = (currentItem: HTMLElement): number => {
    return currentItem.offsetLeft * -1;
  };

  const getFloatingFocusScroll = (
    currentAxis: number,
    currentItem: HTMLElement | null,
    wrapper: HTMLElement | null,
  ): number => {
    if (!currentItem || !wrapper) {
      return currentAxis;
    }

    const swimlaneWidth = wrapper.offsetWidth;
    const swimlaneLeft = wrapper.offsetLeft;
    const itemLeft = currentItem.offsetLeft;
    const itemWidth = currentItem.offsetWidth;
    // If no extra push is provided, it will default to an extra push of itemWidth / 3
    const extraAxisPush = getRelativeVWPixel(extraPush) || itemWidth / 3;

    const itemRight = itemLeft + itemWidth;
    let newAxis = currentAxis;

    if (itemRight + currentAxis > swimlaneWidth) {
      // scroll right
      newAxis = swimlaneWidth - (itemRight + extraAxisPush);
    } else if (itemLeft + swimlaneLeft + currentAxis < swimlaneLeft) {
      // scroll left
      newAxis = -(itemLeft - extraAxisPush);
    }

    return newAxis;
  };

  const onWrapperFocus = useCallback(() => {
    if (shouldScroll) {
      if (enableAnimations) {
        verticalSmoothScroll(nav.id);
      } else {
        verticalScroll(nav.id);
      }
    }
    isWrapperFocus.current = 1;
    focusManager.changeFocus(composeItemId(nav.id, currentIndexRef.current));
    setIsSwimlaneFocused(true);
  }, [nav.id]);

  useUpdateEffect(() => {
    if (shouldResetAxisRef.current && latestAxisRef.current !== 0) {
      currentIndexRef.current = 0;
      setAxis(MIN_SCROLL);
    }
  }, [data]);

  const items = useMemo(() => {
    return getItemsProps<ItemDataType, ItemExtraData>({
      data: data,
      getItemData,
      component,
      category,
      itemWrapperClassName,
      title: displayText,
      customOnClick,
      onItemFocus,
      onItemBlur,
      restoreFocusTrail,
      itemExtraData,
      swimlaneId: nav.id,
      pageId,
    });
  }, [data]);

  const [show, shouldShow] = useState(false);
  const observer = useRef(null);
  const rowRef = useRef(null);

  const isVisible = entries => {
    entries.forEach(entry => {
      shouldShow(entry.isIntersecting);
    });
  };

  useEffect(() => {
    if (show) {
      const data = navigationService.getData();
      if (data.currentFocus.includes(nav.id)) {
        //Allows focus to be restored if tiles haven't been created yet by the time the navigation happens.
        focusManager.changeFocus(data.currentFocus);
      }
    }
  }, [show]);

  useEffect(() => {
    if (!observer.current) {
      const options = {
        root: document.getElementById('app'),
        rootMargin: '0px',
        threshold: 0,
      };

      observer.current = new IntersectionObserver(isVisible, options);
    }

    if (rowRef.current) {
      observer.current.observe(rowRef.current);
      return () => {
        observer.current.unobserve(rowRef.current);
      };
    }
  }, [rowRef.current, observer.current]);

  let swimlaneStyle = {};
  const headerHeight = displayText ? 57 : 0;
  let tileHeight = 0;
  let swimlaneHeight = 0;

  if (itemWrapperClassName === 'hero-wrapper') {
    tileHeight = 432;
  } else if (itemWrapperClassName === 'category-wrapper') {
    tileHeight = 256;
  } else if (itemWrapperClassName === 'channel-wrapper') {
    tileHeight = 384;
  }

  swimlaneHeight = (headerHeight + tileHeight) / 16;
  swimlaneStyle = {
    height: `${swimlaneHeight}rem`,
  };

  return (
    <FocusDiv
      nav={nav}
      onFocus={onWrapperFocus}
      className={classNames(styles.wrapper, className, {
        [styles.noOverflow]: hideOverflow,
      })}
      domRef={rowRef}
      style={swimlaneStyle}
    >
      {displayText && (
        <div
          className={classNames(headerClassName || styles.header, {
            [styles.headerFocused]: isSwimlaneFocused,
          })}
        >
          {displayText}
        </div>
      )}
      <div
        className={classNames(styles.innerWrapper, {
          [styles.animation]: enableAnimations && animation,
          [styles.noAnimation]: !enableAnimations && !animation,
        })}
        style={{
          transform: `translateX(${axis}px)`,
        }}
      >
        {show && items.length && (
          <VisualWindow
            pageId={pageId}
            swimlaneId={nav.id}
            items={items}
            onSwimlaneReady={onSwimlaneReady}
            displayText={displayText}
            isWrapperFocus={isWrapperFocus}
          />
        )}
      </div>
    </FocusDiv>
  );
};

export default Swimlane;
