import { BehaviorSubject, Observable } from 'rxjs';

import { Logger } from '../logger';
import { addProvider, IProviderDescriptor } from '../service';
import { IResumeGlobalSetting } from './resume.interface';

export class ResumeModel {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('ResumeModel');

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(ResumeModel, ResumeModel, []);
  })();

  public globalSettingsSubject: BehaviorSubject<
    IResumeGlobalSetting[]
  > = new BehaviorSubject(null);
  public globalSettings$: Observable<IResumeGlobalSetting[]> = this
    .globalSettingsSubject;

  public resumeComplete$: Observable<boolean>;

  /**
   * Constructor
   */
  constructor() {
    ResumeModel.logger.debug('Resume Model constructor()');
  }
}
