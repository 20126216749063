import React from 'react';
import { FocusButton } from '@accedo/vdkweb-tv-ui';
import './blue-button.component.scss';
import { ReactComponent as Spinner } from '../../assets/images/spinner-white.svg';

const BlueButtonComponent = props => {
  const {
    children,
    type = 'primary',
    isActive = true,
    isLoading = false,
    klassName = 'blue-button-default',
    ...rest
  } = props;
  const theme = {
    button: `blue-button blue-button-${type} ${klassName}`,
    buttonFocused: 'blue-button-focused',
    buttonActive: 'blue-button-active',
  };
  return (
    <FocusButton theme={theme} isActive={isActive} {...rest}>
      {isLoading ? <Spinner /> : children}
    </FocusButton>
  );
};

export default BlueButtonComponent;
