import {
    ITile,
    CarouselType
} from "./index";

export class CarouselData
{
    tiles;
    type;
    title;
    guid;

    constructor(tiles: ITile[], type: CarouselType, id: string, header: string = "")
    {
        this.tiles = tiles;
        this.type = type;
        this.title = header;
        this.guid = id;
    }
}
