import { combineLatest as observableCombineLatest, Observable } from 'rxjs';
import { take, filter, distinctUntilChanged } from 'rxjs/operators';
import * as _ from 'lodash';

import {
  FavoriteChangeType,
  IMediaShow,
  IFavoriteItem,
  FavoriteService,
  IChannel,
  IOnDemandShow,
  FavoriteAssetType,
  InitializationService,
  ContentTypes,
  FavoriteContentType,
  CarouselTypeConst,
  FavoriteContentTypes,
  IFavoriteAsset,
  ITileAssetInfo,
  FavoriteAssetTypes,
  FavoriteChangeTypes,
  IMediaEpisode,
  CarouselService,
  FavoriteUpdateItem,
  IProviderDescriptor,
  addProvider,
} from '../../servicelib';
import * as FavoriteListActions from '../../redux/action';
import { IAppStore, IFavoriteListStore } from '../../redux/selector';
import {
  getChannel,
  getEpisode,
  getIsAIC,
  getIsAod,
  getIsOnDemand,
  getIsSeededRadio,
  getShow,
} from '../../redux/selector/now-playing.store';
import { FavoriteButtonConstant } from '../../types/favorite-button.constant';
import { tileAssetUtil } from '../../utils/tileAsset.util';
import store from '../../redux/store';
import { favoritesLoaded } from '../../redux/action';

export class FavoriteListStoreService {
  private channel$: Observable<IChannel>; //= this.store.select(getChannel);
  private show$: Observable<IMediaShow>; //= this.store.select(getShow);
  private episode$: Observable<IMediaEpisode>; //= this.store.select(getEpisode);
  private isOnDemand$: Observable<boolean>; //= this.store.select(getIsOnDemand);
  public isAod$: Observable<boolean>; //= this.store.select(getIsAod);
  public isAIC$: Observable<boolean>; //= this.store.select(getIsAIC);
  public isSeededRadio$: Observable<
    boolean
  >; /*= this.store.select(
    getIsSeededRadio,
  );*/
  public favorites: Observable<IFavoriteListStore>;

  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(FavoriteListStoreService, FavoriteListStoreService, [
      CarouselService,
      InitializationService,
      FavoriteService,
    ]);
  })();

  /**
   * Creates an instance of FavoriteListStoreService.
   *
   * Sets up a subscriber to the servicelib favoriteService. When the API returns
   * a new list of favorites it dispatches an event to the store in order
   * to updat the store with the up to dat list of favorites.
   *
   * @param {FavoritesCarouselService} favoritesCarouselService is used to retrieve the favorites carousel from the API
   * @param {InitializationService} initializationService is used to determine whether the APP is running yet or not
   * @param {FavoriteService} favoriteService
   * @param {Store<IAppStore>} store
   * @memberof FavoriteListStoreService
   */
  constructor(
    private carouselService: CarouselService,
    private initializationService: InitializationService,
    private favoriteService: FavoriteService, //private store: Store<IAppStore>,
  ) {
    // this.favorites = store.select(state => state.favoriteList);
    /**
         * NOTE: If we want to prefetch carousels, the following code should be uncommented
         *
         * If we do this, we will hit the API with multiple carousel calls.  If the API can handle it
         * the user experience will be better, but if the API cannot handle this then we need to make sure
         * we do not pre-cache.

         initializationService.initState
         .skipWhile((state: string) => state !== InitializationStatusCodes.RUNNING)
         .pipe(take(1))
         .subscribe(this.getFavoriteCarousels.bind(this));
         */
  }

  /**
   * Method that handles adding, removing, and reordering of favorites using
   * the servicelib favorite service to update the API.
   *
   * @memberof FavoriteListStoreService
   */
  public updateFavorite(favorite: FavoriteUpdateItem) {
    this.favoriteService
      .updateFavorite(favorite)
      .pipe(
        filter(response => response),
        take(1),
      )
      .subscribe(() => this.getFavoriteCarousels());
  }

  /**
   * Method updates multiple favorites at once
   * @param {Array<FavoriteUpdateItem>} items
   */
  public updateFavorites(items: Array<FavoriteUpdateItem>) {
    this.favoriteService.updateFavorites(items);
  }

  /**
   * Gets the favorite carousels.
   */
  public getFavoriteCarousels(): void {
    this.carouselService.getFavoriteTiles().subscribe(
      favorites => {
        store.dispatch(favoritesLoaded(favorites))
      },
      //this.store.dispatch(new FavoriteListActions.FavoritesLoaded(favorites)),
    );
  }

  /**
   * Deterfines the favorite asset
   * @param {string} favoriteButtonType
   * @returns {Observable<IFavoriteAsset>}
   */
  public determineFavoriteAsset(
    favoriteButtonType: string = '',
  ): Observable<IFavoriteAsset> {
    return (observableCombineLatest(
      this.channel$,
      this.show$,
      this.episode$,
      this.isAod$,
      this.isOnDemand$,
      this.isAIC$,
      this.isSeededRadio$,
      (channel, show, episode, isAod, isOnDemand, isAIC, isSeededRadio) => {
        if (!channel) {
          return;
        }

        if (
          isOnDemand &&
          favoriteButtonType ===
            FavoriteButtonConstant.NOW_PLAYING_SHOW_AND_EPISODE_VIEW
        ) {
          if (!episode) {
            return;
          }
          const channelId =
            episode && episode.show ? episode.show.channelId : '';
          const accessControlIdentifer = _.get(
            episode,
            'publicationInfo.accessControlIdentifier',
          ) as string;
          const vodEpisodeGuid = episode ? episode.vodEpisodeGUID : '';
          const podcastEpisodeGuid = episode ? episode.episodeGUID : '';

          let assetGuid = isAod ? accessControlIdentifer : vodEpisodeGuid;
          assetGuid =
            show && show.isIrisPodcast ? podcastEpisodeGuid : assetGuid;

          return {
            channelId: channelId,
            assetGuid: assetGuid,
            title: episode.longTitle,
            subContentType: isAod
              ? CarouselTypeConst.AOD_EPISODE_TILE
              : CarouselTypeConst.VOD_EPISODE_TILE,
            contentType: CarouselTypeConst.EPISODE_TILE,
            isFavorite: !!this.getFavorite(assetGuid),
            isIrisPodcast: show.isIrisPodcast ? show.isIrisPodcast : false,
          };
        } else if (
          (!isOnDemand &&
            favoriteButtonType ===
              FavoriteButtonConstant.NOW_PLAYING_SHOW_AND_EPISODE_VIEW) ||
          (isOnDemand &&
            favoriteButtonType === FavoriteButtonConstant.NOW_PLAYING_VIEW)
        ) {
          if (!show || Object.keys(show).length === 0) {
            return;
          }
          return {
            channelId: show.channelId,
            assetGuid: show.assetGUID,
            title: show.longTitle,
            subContentType: CarouselTypeConst.SHOW_TILE,
            isPlaceholderShow: show.isPlaceholderShow,
            contentType: CarouselTypeConst.SHOW_TILE,
            isFavorite: !!this.getFavorite(show.assetGUID),
            isIrisPodcast: show.isIrisPodcast ? show.isIrisPodcast : false,
          };
        } else if (isAIC) {
          return {
            channelId: channel.channelId,
            assetGuid: channel.channelGuid,
            title: channel.name,
            subContentType: CarouselTypeConst.ADDITIONAL_CHANNEL,
            isPlaceholderShow: show.isPlaceholderShow,
            contentType: CarouselTypeConst.CHANNEL_TILE,
            isFavorite: !!this.getFavorite(channel.channelGuid),
            isIrisPodcast: show.isIrisPodcast ? show.isIrisPodcast : false,
          };
        } else if (isSeededRadio) {
          return {
            channelId: channel.channelId,
            assetGuid: channel.channelGuid,
            title: channel.name,
            subContentType: CarouselTypeConst.SEEDED_RADIO,
            isPlaceholderShow: show.isPlaceholderShow,
            contentType: CarouselTypeConst.CHANNEL_TILE,
            isFavorite: !!this.getFavorite(channel.channelGuid),
            isIrisPodcast: show.isIrisPodcast ? show.isIrisPodcast : false,
          };
        } else {
          return {
            channelId: channel.channelId,
            assetGuid: channel.channelGuid,
            title: channel.name,
            subContentType: CarouselTypeConst.CHANNEL_TILE,
            isPlaceholderShow: show.isPlaceholderShow,
            contentType: CarouselTypeConst.CHANNEL_TILE,
            isFavorite: !!this.getFavoriteForChannel(channel.channelId),
            isIrisPodcast: show.isIrisPodcast ? show.isIrisPodcast : false,
          };
        }
      },
    ).pipe(
      filter(data => data && data.isFavorite !== undefined),
      distinctUntilChanged(),
    ) as any) as Observable<IFavoriteAsset>;
  }

  /**
   * Toggles the favorite
   * @param {boolean} isFavorite
   * @param {string} tileContentType
   * @param {string} tileContentSubType
   * @param {string} channelId
   * @param {string} assetGuid
   */
  public toggleFavorite(
    isFavorite: boolean,
    tileContentType: string,
    tileContentSubType: string,
    channelId: string,
    assetGuid: string,
    isIrisPodcast: boolean,
  ): void {
    const changeType = isFavorite
      ? FavoriteChangeTypes.DELETE
      : FavoriteChangeTypes.ADD;

    const favoriteItem = this.createFavoriteUpdateItem(
      changeType,
      tileContentType,
      tileContentSubType,
      channelId,
      assetGuid,
      isIrisPodcast,
    );

    this.updateFavorite(favoriteItem);
  }

  /**
   * Used to create the favorite update item
   * @param {string} changeType
   * @param {string} tileContentType
   * @param {string} tileContentSubType
   * @param {string} channelId
   * @param {string} assetGuid
   * @param {number} tabSortOrder
   * @returns {FavoriteUpdateItem}
   */
  public createFavoriteUpdateItem(
    changeType: string,
    tileContentType: string,
    tileContentSubType: string,
    channelId: string,
    assetGuid: string,
    isIrisPodcast: boolean,
    tabSortOrder?: number,
  ): FavoriteUpdateItem {
    let contentType;
    let assetType;

    switch (tileContentType) {
      case CarouselTypeConst.CHANNEL_TILE:
        contentType = FavoriteContentTypes.LIVE;
        assetType = FavoriteAssetTypes.CHANNEL;

        //For Additional Channel Tile
        if (tileContentSubType === CarouselTypeConst.ADDITIONAL_CHANNEL) {
          contentType = FavoriteContentTypes.ADDITIONAL_CHANNEL;
          assetType = FavoriteAssetTypes.CHANNEL;
        }
        if (tileContentSubType === CarouselTypeConst.SEEDED_RADIO) {
          contentType = FavoriteContentTypes.SEEDED_RADIO;
          assetType = FavoriteAssetTypes.CHANNEL;
        }
        break;
      case CarouselTypeConst.SHOW_TILE:
      case CarouselTypeConst.LIVE_SHOW_TILE:
        contentType = isIrisPodcast
          ? FavoriteContentTypes.SHOW_PODCAST
          : FavoriteContentTypes.SHOW;
        assetType = FavoriteAssetTypes.SHOW;
        break;
      case CarouselTypeConst.EPISODE_TILE:
        contentType = isIrisPodcast
          ? FavoriteContentTypes.PODCAST
          : tileContentSubType === CarouselTypeConst.AOD_EPISODE_TILE
          ? FavoriteContentTypes.AOD
          : FavoriteContentTypes.VOD;
        assetType = FavoriteAssetTypes.EPISODE;

        break;
    }

    return new FavoriteUpdateItem(
      channelId,
      contentType as FavoriteContentType,
      assetType as FavoriteAssetType,
      changeType as FavoriteChangeType,
      assetGuid,
      tabSortOrder,
    );
  }

  /**
   * Gets the Favorite
   * @param {string} assetGuid
   * @returns {IFavoriteItem}
   */
  public getFavorite(assetGuid: string): IFavoriteItem {
    return this.favoriteService.getFavorite(assetGuid);
  }

  /**
   * Gets the Favorite for the given channel
   * @param {string} channelId
   * @returns {IFavoriteItem}
   */
  public getFavoriteForChannel(channelId: string): IFavoriteItem {
    return this.favoriteService.getFavoriteForChannel(channelId);
  }

  /**
   * Gets the Asset guid based on content and sub content type
   * @param {string} contentType
   * @param {string} subContentType
   * @param {ITileAssetInfo} tileAssetInfo
   * @returns {string}
   */
  public static getAssetGuid(
    contentType: string,
    subContentType: string,
    tileAssetInfo: ITileAssetInfo,
  ): string {
    if (tileAssetInfo.favAssetGuid) {
      return tileAssetInfo.favAssetGuid;
    }

    if (
      contentType === CarouselTypeConst.CHANNEL_TILE ||
      contentType === CarouselTypeConst.ADDITIONAL_CHANNEL ||
      contentType === CarouselTypeConst.SEEDED_RADIO
    ) {
      return tileAssetInfo.channelGuid;
    }

    if (
      contentType === CarouselTypeConst.SHOW_TILE ||
      contentType === CarouselTypeConst.LIVE_SHOW_TILE
    ) {
      return tileAssetInfo.showGuid;
    }

    if (
      contentType === CarouselTypeConst.EPISODE_TILE &&
      tileAssetUtil.isIrisPodcastTile(tileAssetInfo)
    ) {
      return tileAssetInfo.episodeGuid;
    }

    switch (subContentType) {
      case ContentTypes.AOD:
        return tileAssetInfo.aodEpisodecaId;
      case ContentTypes.VOD:
        return tileAssetInfo.vodEpisodeGuid;
    }
  }
}
