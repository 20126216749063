import React from 'react';
import { FocusButton } from '@accedo/vdkweb-tv-ui';
import './up-next.component.scss';

const UpNextActionButton = props => {
  const { children, className, ...rest } = props;
  const theme = {
    button: `up-next-action-button ${className || ''}`,
    buttonFocused: 'up-next-action-button-focused',
  };
  return (
    <FocusButton theme={theme} {...rest}>
      {children}
    </FocusButton>
  );
};

export default UpNextActionButton;
