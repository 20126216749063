import { concat, of as observableOf, BehaviorSubject, Observable } from 'rxjs';
import { take, skip, tap, filter, switchMap } from 'rxjs/operators';
import {
  ContentTypes,
  Logger,
  SettingsService,
  SettingsConstants,
  TuneService,
  RefreshTracksService,
  CurrentlyPlayingService,
  TunePayload,
  MediaTimeLineService,
  AppMonitorService,
  AppErrorCodes,
  ClientCodes,
  ChromecastModel,
  MediaUtil,
  MultiTrackList,
  DmcaService,
  MediaPlayerService,
  SeekService,
  IDmcaInfoItem,
  IProviderDescriptor,
  addProvider,
} from '../../servicelib';
import { appRouteConstants } from '../../routing/app.route.constants';
import { ChannelListStoreService } from '../channel-list/channel-list.store.service';
import { NavigationService } from '../navigation/navigation.service';
import { NowPlayingStoreService } from '../now-playing/now-playing.store.service';
import { IModalData } from '../modal/modal.interface';
//import { ModalService } from '../modal/modal.service';

export class TuneClientService {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('TuneClientService');

  /**
   * Check MiniPlaying
   */
  public tuneResponseSubject: BehaviorSubject<number> = new BehaviorSubject(
    ClientCodes.SUCCESS,
  );

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(TuneClientService, TuneClientService, [
      TuneService,
      RefreshTracksService,
      CurrentlyPlayingService,
      NowPlayingStoreService,
      ChannelListStoreService,
      DmcaService,
      MediaPlayerService,
      NavigationService,
      SettingsService,
      SeekService,
      MediaTimeLineService,
      AppMonitorService,
      ChromecastModel,
      //ModalService,
    ]);
  })();

  /**
   * Constructor.
   * @param {TuneService} tuneService
   * @param {NowPlayingStoreService} nowPlayingStoreService
   * @param {ChannelListStoreService} channelListStoreService
   */
  constructor(
    private tuneService: TuneService,
    private refreshTracksService: RefreshTracksService,
    private currentlyPlayingService: CurrentlyPlayingService,
    private nowPlayingStoreService: NowPlayingStoreService,
    private channelListStoreService: ChannelListStoreService,
    private dmcaService: DmcaService,
    private mediaPlayerService: MediaPlayerService,
    private navigationService: NavigationService,
    private settingsService: SettingsService,
    private seekService: SeekService,
    private mediaTimeLineService: MediaTimeLineService,
    private appMonitorService: AppMonitorService,
    private chromecastModel: ChromecastModel, //private modalService: ModalService,
  ) {}

  /**
   * Attempts to tune by:
   *
   * 1) Calling the now-playing API endpoint for a given content type.
   * 2) Set the selected channel for all content types.
   * 3) Set the selected AOD or VOD episode for those content types.
   * 4) Optionally, Navigate the user to the now-playing route.
   *
   * @param {TunePayload} payload
   */
  public tune(payload: TunePayload): Observable<number> {
    let modal = null;
    if (payload.modelData) {
      modal = this.openModal(payload.modelData);
    }

    let startTime: number = payload.startTime || 0;
    let episodeIdentifier: any = payload.episodeIdentifier || null;

    if (!this.canTuneToOnDemand(payload.contentType, episodeIdentifier)) {
      TuneClientService.logger.warn(
        `tune( Cannot tune to On Demand content type "${payload.contentType}" as there's no episode data. )`,
      );
      return;
    }

    // TODO: Jordan D. Nelson
    // TuneService.tune only accepts a channelIdentifier.
    // This causes us to need to pass a channelGuid as a channelIdentifier.
    // According to API those are two different things.
    // This can lead to confusion of ideas and bugs.
    //
    // We should make TuneService.tune accept an IChannel and a ContentType.
    // It will then "know" what to do based on the content type.
    // https://github.siriusxm.com/WebClientTeam/web-client/issues/2308
    if (payload.contentType === ContentTypes.ADDITIONAL_CHANNELS) {
      if (payload.channel && payload.channel.channelGuid) {
        payload.channelId = payload.channel.channelGuid;
      }
    }

    const mediaPlayer = this.mediaPlayerService.retrieveMediaPlayer(
      payload.contentType,
    );
    const warmUpObs = mediaPlayer.warmUp().pipe(take(1));

    const apiCallObs = this.getApiCallObs(payload);

    concat(warmUpObs, apiCallObs)
      .pipe(skip(1))
      .subscribe((code: number) => {
        if (modal) {
          this.runPostModalTune(code, modal);
        }

        let faultCode;

        if (code === ClientCodes.CONTENT_NOT_SUPPORTED_ON_REMOTE_PLAYER) {
          faultCode = MediaUtil.isVideoMediaType(payload.contentType)
            ? AppErrorCodes.FLTT_CHROME_CAST_CONTENT_NOT_SUPPORTED_VOD
            : AppErrorCodes.FLTT_CHROME_CAST_CONTENT_NOT_SUPPORTED_AIC;
        }

        if (code === ClientCodes.AIC_IN_BYPASS_MODE) {
          faultCode = AppErrorCodes.FLTT_AIC_BYPASS;
        }

        if (code === ClientCodes.ARTIST_RADIO_IN_BYPASS_MODE) {
          faultCode = AppErrorCodes.FLTT_ARTIST_RADIO_BYPASS;
        }
        if (faultCode && code !== ClientCodes.SUCCESS) {
          return this.appMonitorService.triggerFaultError({
            faultCode: faultCode,
            metaData: { description: this.chromecastModel.deviceFriendlyName },
          });
        }

        if (code === ClientCodes.SUCCESS) {
          if (startTime > 0) {
            this.seekService.seek(startTime, false);
          }

          this.tuneResponseSubject.next(code);

          if (
            this.currentlyPlayingService.isTunedTo(payload) &&
            this.mediaPlayerService.mediaPlayer.isPaused()
          ) {
            this.mediaPlayerService.mediaPlayer.resume();
          }
        } else {
          this.currentlyPlayingService.triggerCurrentlyPlayingData();
        }

        this.refreshTracksService.setTuneInProgress(false);
      });

    if (
      !this.tuneService.isPlayingContentSupported(payload.contentType) ||
      this.tuneService.isAICOnByPassMode(payload.contentType) ||
      this.tuneService.isArtistRadioOnByPass(payload.contentType)
    ) {
      return (apiCallObs as any) as Observable<number>;
    }

    if (!this.currentlyPlayingService.isTunedTo(payload)) {
      this.nowPlayingStoreService.resetNowPlaying();
      this.refreshTracksService.setTuneInProgress(true);
    }

    //Just navigate to Now-playing screen if it supposed to, don't need to wait for the tune call to success
    // we are already handling resolving in NowPlayingRouteGaurd

    /*
    if (this.isVideoContent(payload.contentType) || !this.isMiniPlayEnabled()) {
      this.navigationService.go([appRouteConstants.NOW_PLAYING]).then(() => {
        window.scrollTo(0, 0);
      });
    }
    */

    this.nowPlayingStoreService.enterNowPlaying(startTime, payload.contentType);

    const channelObs = new BehaviorSubject(payload.channel);

    if (!channelObs.getValue()) {
      this.mediaTimeLineService.mediaTimeLine
        .pipe(take(1))
        .subscribe(mediaTimeLine => {
          channelObs.next(mediaTimeLine.channel);
        });
    }

    channelObs
      .pipe(
        filter(channel => !!channel),
        take(1),
      )
      .subscribe(channel => {
        this.nowPlayingStoreService.selectNowPlayingChannel(channel);
        this.channelListStoreService.selectChannel(channel);
      });

    return (apiCallObs as any) as Observable<number>;
  }

  /**
   * Allow a switch between live audio playback and live video playback.  For channels that have both audio and
   * video available, this lets us re-trigger the media time line to switch between different types of content
   *
   * @returns {boolean} true if the playback type was switched, false if the switch could not be performed
   */
  public switchPlayback(startTime?: number): void {
    startTime = startTime
      ? startTime
      : this.currentlyPlayingService.getCurrentlyPlayingTime();
    this.mediaTimeLineService.switchPlayback(startTime);
  }

  /**
   * Determines if mini play is enabled in application settings.
   * @returns {boolean}
   */
  private isMiniPlayEnabled(): boolean {
    return this.settingsService.isDeviceSettingOn(SettingsConstants.MINI_PLAY);
  }

  /**
   * Determines if the content type has video.
   * @param {string} contentType
   * @returns {boolean}
   */
  private isVideoContent(contentType: string): boolean {
    return (
      contentType === ContentTypes.VOD ||
      contentType === ContentTypes.LIVE_VIDEO
    );
  }

  /**
   * Determines if the content type is On Demand.
   * @param {string} contentType
   * @returns {boolean}
   */
  private isOnDemand(contentType: string): boolean {
    const isAod: boolean = contentType === ContentTypes.AOD;
    const isVod: boolean = contentType === ContentTypes.VOD;
    return isAod || isVod;
  }

  /**
   * Determines if the client can tune to On Demand content; if it can't it's b/c its content type is
   * in fact an On Demand type, BUT there is no episode data. This can happen when arriving to a tune via
   * a neritic link.
   * @param {string} contentType
   * @param {string} episodeGuid
   * @returns {boolean}
   */
  private canTuneToOnDemand(contentType: string, episodeGuid: string): boolean {
    if (this.isOnDemand(contentType)) {
      return !!episodeGuid;
    }
    return true;
  }

  private getApiCallObs(payload: TunePayload): Observable<number> {
    if (MediaUtil.isSeededRadioMediaType(payload.contentType)) {
      const item: IDmcaInfoItem = {
        mediaId: payload.channelId,
      } as IDmcaInfoItem;
      if (!this.dmcaService.hasSkipsRemaining(item)) {
        const skipInfo = this.dmcaService.getSkipInfo(item);

        return (this.refreshTracksService
          .refreshTracksSeeded(new MultiTrackList(skipInfo.mediaId || '', []), {
            playerType: this.tuneService.getPlayerType(),
            stationFactory: skipInfo.mediaId || '',
            stationId: skipInfo.secondaryMediaId || '',
            isSkipLimitReached: true,
            mediaId: skipInfo.mediaId || '',
          })
          .pipe(
            tap(code => {
              if (code === ClientCodes.SUCCESS) {
                this.tuneService.stopPeriodicLiveUpdateRequests();
                this.tuneService.updateMediaId(skipInfo.mediaId);
              }
            }),
          ) as any) as Observable<number>;
      }
    }

    return (this.tuneService.tune(payload) as any) as Observable<number>;
  }

  private openModal(modalData: IModalData): any {
    //return this.modalService.addDynamicModal(modalData);
  }

  private runPostModalTune(responseCode, dynamicComponent): void {
    if (responseCode === ClientCodes.SUCCESS) {
      setTimeout(() => {
        dynamicComponent.instance.close();
      }, 1200);
    } else {
      dynamicComponent.instance.close();
    }
  }
}
