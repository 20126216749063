//TODO @vpaindla/@chrisYates -- Rename Constant names to Uppercase to sync with other places AND Rename file.
export class EInputType
{
    public static TOUCH: string                   = "Touch";
    public static FIVE_WAY: string                = "FiveWay";
    public static IN_CAR: string                  = "Incar";
    public static HARD_KEY: string                = "Hardkey";
    public static ROTARY: string                  = "Rotary";
    public static STEERING_WHEELR_IGHT_UP: string = "SteeringWheelRightUp";
    public static STEERING_WHEEL: string          = "SteeringWheel";
    public static MOUSE: string                   = "Mouse";
    public static VOICE: string                   = "Voice";
    public static UNKNOWN: string                 = "Unknown";
}

export class EPageFrame
{
    public static HEADER: string              = "Header";
    public static SEARCH_HEADER: string       = "SearchHeader";
    public static BODY: string                = "Body";
    public static SUPER_CATEGORY_MENU: string = "SuperCategoryMenu";
    public static CORE_NAV_BAR: string        = "CoreNavBar";
    public static MAIN_NAV: string            = "MainNav";
    public static DIRECT_TUNE: string         = "DirectTune";
    public static LINEAR_TUNER: string        = "LinearTuner";
    public static ACCOUNT_SETTINGS: string    = "AccountSettings";
    public static NOW_PLAYING_MAIN: string    = "NowPlayingMain";
    public static OVERLAY: string             = "Overlay";
    public static MODAL: string               = "Modal";
    public static SHIM: string                = "Shim";
    public static PRESET_BAR: string          = "PresetBar";
    public static REMOTE: string              = "Remote";
    public static PAGE: string                = "Page";
    public static BOTTOM: string              = "Bottom";
    public static EDP: string                 = "EDP";
    public static UNKNOWN: string             = "Unknown";
}

export class EModal
{
    public static MINI_NOW_PLAYING_BAR: string = "MiniNowPlayingBar";
    public static TEAM_FAVORITES: string       = "TeamFavorites";
    public static CONFIRM_DELETE: string       = "ConfirmDelete";
    public static UNKNOWN: string              = "Unknown";
    public static MINI_VIDEO: string           = "MiniVideo";
}

export class EElementType
{
    public static SCREEN: string       = "Screen";
    public static BUTTON: string       = "Button";
    public static LINK: string         = "Link";
    public static MODAL: string        = "Modal";
    public static MENU: string         = "Menu";
    public static NOTIFICATION: string = "Notification";
    public static OVERLAY: string      = "Overlay";
    public static SHIM: string         = "Shim";
    public static TOAST: string        = "Toast";
    public static MESSAGE: string      = "Message";
    public static UNKNOWN: string      = "Unknown";
    public static COACH_MARK: string   = "Coachmark";
}

export class EAction
{
    public static OPEN: string        = "Open";
    public static CLOSE: string       = "Close";
    public static TAP: string         = "Tap";
    public static LONG_PRESS: string  = "LongPress";
    public static DOUBLE_TAP: string  = "DoubleTap";
    public static DRAG: string        = "Drag";
    public static SWIPE: string       = "Swipe";
    public static PINCH_OPEN: string  = "PinchOpen";
    public static PINCH_CLOSE: string = "PinchClose";
    public static CLICK: string       = "Click";
    public static TUNE: string        = "Tune";
    public static DISPLAY: string     = "Display";
    public static SCRUB: string       = "Scrub";
    public static VOICE: string       = "Voice";
    public static UNKNOWN: string     = "Unknown";
    public static PAGE_LOAD: string   = "PageLoad";
}

export class EActionSource
{
    public static MAIN: string             = "Main";
    public static MININP: string           = "MiniNP";
    public static LOCK: string             = "Lock";
    public static CONTROL_CENTER: string   = "ControlCenter";
    public static REMOTE_CONTROL: string   = "RemoteControl";
    public static NOW_PLAYING_MAIN: string = "NowPlayingMain";
    public static DIRECT_TUNE: string      = "DirectTune";
    public static LINEAR_TUNER: string     = "LinearTuner";
    public static PRESET_BAR: string       = "PresetBar";
    public static WEB_WELCOME_LOAD         = "WebWelcomeLoad";
    public static WEB_FLOW                 = "WebFlow";
    public static UNKNOWN: string          = "Unknown";
}

export class EContentSource
{
    public static IP: string      = "IP";
    public static SAT: string     = "SAT";
    public static UNKNOWN: string = "Unknown";
}

export class ETileType
{
    public static CHANNEL: string             = "Channel";
    public static SHOW: string                = "Show";
    public static EPISODE: string             = "Episode";
    public static VIEW_ALL: string            = "ViewAll";
    public static SPORTS_LEADER_BOARD: string = "SportsLeaderBoard";
    public static CATEGORY: string            = "Category";
    public static CUT: string                 = "Cut";
    public static LEAGUE: string              = "League";
    public static ALERT: string               = "Alert";
    public static SPORTS_PXP: string          = "SportsPxP";
    public static TEAM: string                = "Team";
    public static COLLECTION: string          = "Collection";
    public static UNKNOWN: string             = "Unknown";
    public static SHOW_SCHEDULE: string       = "ShowSchedule";
    public static ADDITIONAL_EPISODES: string = "AdditionalEpisodes";
    public static CAROUSEL_ACTION: string     = "Carousel_Action";
    public static CHANNEL_SCHEDULE: string    = "ChannelSchedule";
    public static BUTTON: string              = "Button";
    //public static CAROUSEL_ACTION: string     = "CarouselAction";
}

export class EAssetType
{
    public static MUSIC: string   = "Music";
    public static SPORTS: string  = "Sports";
    public static NEWS: string    = "News";
    public static TALK: string    = "Talk";
    public static HOWARD: string  = "Howard";
    public static UNKNOWN: string = "Unknown";
}

export class EContentType
{
    public static VOD: string                = "VOD";
    public static AOD: string                = "AOD";
    public static CONTINUE_LISTENING: string = "ContinueListening";
    public static NOW_PLAYING: string        = "NowPlaying";
    public static UPCOMING_AUDIO: string     = "UpcomingAudio";
    public static LIVE_AUDIO: string         = "LiveAudio";
    public static BUFFER_VIDEO: string       = "BufferVideo";
    public static UPCOMING_VIDEO: string     = "UpcomingVideo";
    public static FAVORITES: string          = "Favorites";
    public static LIVE_VIDEO: string         = "LiveVideo";
    public static BUFFER_AUDIO: string       = "BufferAudio";
    public static NOTIFICATION: string       = "Notification";
    public static SAT_ONLY: string           = "SatOnly";
    public static ADDITIONAL_CHANNEL: string = "AdditionalChannel";
    public static UNKNOWN: string            = "Unknown";
    public static SEEDED_RADIO: string       = "SeededRadio";
    public static CHANNEL_SCHEDULE: string   = "ChannelSchedule";
    public static SHOW_SCHEDULE: string      = "ShowSchedule";
}

export class EStreamingType
{
    public static VIDEO: string   = "Video";
    public static AUDIO: string   = "Audio";
    public static UNKNOWN: string = "Unknown";
}

export class EBannerInd
{
    public static UNAVAILABLE: string         = "Unavailable";
    public static NEW: string                 = "New";
    public static NEWCHANNEL: string          = "NewChannel";
    public static CONTINUELISTENING: string   = "ContinueListening";
    public static EXPIRING: string            = "Expiring";
    public static EXPIRINGSOON: string        = "Expiring";
    public static STAFFPICK: string           = "StaffPick";
    public static CUSTOM: string              = "Custom";
    public static SCHEDULED: string           = "Scheduled";
    public static PREGAME: string            = "PreGame";
    public static INPROGRESS: string         = "InProgress";
    public static FINAL: string               = "Final";
    public static DELAYED: string             = "Delayed";
    public static ABANDONED: string           = "Abandoned";
    public static SUSPENDED: string           = "Suspended";
    public static RESCHEDULED: string         = "Rescheduled";
    public static TRENDING: string            = "Trending";
    public static POPULAR: string             = "Popular";
    public static ONAIR: string              = "OnAir";
    public static NODATAAVAILABLE: string   = "NoDataAvailable";
    public static SUBSCRIBETOLISTEN: string = "SubscribeToListen";
    public static UNKNOWN: string             = "Unknown";
}

export class EOrientation
{
    public static LANDSCAPE: string = "Landscape";
    public static PORTRAIT: string  = "Portrait";
    public static UNKNOWN: string   = "Unknown";
}

export class EConsumedInd
{

    public static NEW_UNCONSUMED: string    = "NewUnconsumed";
    public static UNCONSUMED: string        = "Unconsumed";
    public static STARTED: string           = "Started";
    public static COMPLETE: string          = "Complete";
    public static NO_DATA_AVAILABLE: string = "NoDataAvailable";
    public static UNKNOWN: string           = "Unknown";
}

export class EPlatform
{
    public static ANDROID: string = "Android";
    public static IOS: string     = "iOS";
    public static WINDOWS: string = "Windows";
    public static MAC_OS: string  = "MacOS";
}

export class EApplication
{
    public static EVEREST_WEB = "EverestWeb";
}

export class EDeviceType
{
    public static PHONE: string       = "Phone";
    public static TABLET: string      = "Tablet";
    public static DESKTOP: string     = "Desktop";
    public static LAPTOP: string      = "Laptop";
    public static HOME_DEVICE: string = "HomeDevice";
    public static UNKNOWN: string     = "Unknown";
}

export class EAppCountry
{
    public static US: string      = "US";
    public static CANADA: string  = "Canada";
    public static UNKNOWN: string = "Unknown";
}

export class ELanguage
{
    public static ENGLISH: string = "English";
    public static FRENCH: string  = "French";
    public static UNKNOWN: string = "Unknown";
}

export class ESubscriptionLevel
{
    public static OPEN_ACCESS: string      = "OpenAccess";
    public static TRIAL: string            = "Trial";
    public static SUBSCRIBER: string       = "Subscriber";
    public static O_DATA_AVAILABLE: string = "oDataAvailable";
    public static UNKNOWN: string          = "Unknown";
}

export class EFindingMethod
{
    public static CAROUSEL: string          = "Carousel";
    public static SPORTS_PXP: string        = "SportsPxP";
    public static LINEAR_TUNER: string      = "LinearTuner";
    public static BROWSE: string            = "Browse";
    public static FAVORITES: string         = "Favorites";
    public static RECENTLY_PLAYED: string   = "RecentlyPlayed";
    public static SAVED_FOR_LATER: string   = "SavedForLater";
    public static RELATED: string           = "Related";
    public static RECOMMENDED: string       = "Recommended";
    public static SEARCH: string            = "Search";
    public static RECENT: string            = "Recent";
    public static ON_DEMAND: string         = "OnDemand";
    public static ARTIST_RADIO: string      = "ArtistRadio";
    public static LISTENING_HISTORY: string = "ListeningHistory";
    public static UNKNOWN: string           = "Unknown";
    public static EDP: string               = "EDP";
    public static MODAL: string             = "Modal";
    public static TOAST: string             = "Toast";
}

export class EPageName {
    public static DEFAULT: string           = "Default";
    public static COLD_START: string        = "ColdStart";
}


