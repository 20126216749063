import { IClip } from '../../tune/tune.interface';
import { MultiTrackConstants } from './multi-track.consts';
import { msToSeconds } from '../../util/utilities';

/**
 * @MODULE:     service-lib
 * @CREATED:    10/31/18
 * @COPYRIGHT:  2018 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * MultiTrackList is an immutable object representing a list of tracks to play.
 */
export class MultiTrackList {
  /**
   * holds the channel guid
   */
  private channelIdentifier: string;

  /**
   * holds the list of tracks
   */
  private tracks: IClip[];

  /**
   * Constructor
   * @param channelIdentifier
   * @param tracks
   */
  constructor(channelIdentifier: string, tracks: IClip[]) {
    this.channelIdentifier = channelIdentifier;
    this.tracks = tracks.map(
      (track: IClip, index: number): IClip => {
        track.previousTrack = index > 0 ? tracks[index - 1] : null;
        track.nextTrack = index < tracks.length - 1 ? tracks[index + 1] : null;
        track.status = track.status ? track.status : MultiTrackConstants.FUTURE;
        return track;
      },
    );
    this.cleanCrossfade(this.tracks);
  }

  /**
   * used to append tracks
   * @param channelGUID
   * @param tracksToAppend
   */
  public appendTracks(
    channelIdentifier: string,
    tracksToAppend: IClip[],
  ): MultiTrackList {
    if (channelIdentifier === this.channelIdentifier) {
      tracksToAppend = tracksToAppend.filter((newTrack: IClip) => {
        /* If we do *not* find the new track in the existing list of
         * tracks, then we can keep the new track and append it.
         *
         * If we *do* find this track already in the track list then
         * it will *not* be appended.
         */
        return !this.tracks.find(
          (track: IClip): boolean => newTrack.assetGUID === track.assetGUID,
        );
      });

      tracksToAppend = this.tracks.concat(tracksToAppend);

      tracksToAppend = tracksToAppend.filter((track: IClip) => {
        return (
          track.status !== MultiTrackConstants.ENDED &&
          track.status !== MultiTrackConstants.ERROR &&
          track.status !== MultiTrackConstants.SKIP
        );
      });
    }

    return new MultiTrackList(channelIdentifier, tracksToAppend);
  }

  /**
   * Returns the first track in the track list.
   */
  public firstTrack(): IClip {
    let first;
    let track;

    // search for first error track from the right.
    // land on first item in the array if no error tracks.
    for (let index = this.tracks.length - 1; index >= 0; --index) {
      track = this.tracks[index];
      if (track && track.status === MultiTrackConstants.ERROR) {
        first = track.nextTrack;
        break;
      }
      first = track;
    }

    if (!first) {
      throw 'there are no tracks in this track list';
    }
    return first;
  }

  /**
   * Returns the track by assetGuid.
   * @param assetGUID
   */
  public findTrackByGuid(assetGUID: string): IClip | null {
    return this.tracks.find(track => {
      return track.assetGUID === assetGUID;
    });
  }

  /**
   * Returns the list after one track is started playing
   * NOTE: When user in casting mode,
   * R notifies to S  about track loaded then we call this method to set track to current state
   * @param assetGUID
   */
  public listAfterStarted(assetGUID: string): MultiTrackList {
    const track = this.findTrackByGuid(assetGUID);

    if (!track) {
      return;
    }
    track.status = MultiTrackConstants.CURRENT;

    return new MultiTrackList(this.channelIdentifier, this.tracks);
  }

  /**
   * Returns the list after finish the track
   * @param assetGUID
   */
  public listAfterFinish(assetGUID: string): MultiTrackList {
    const track = this.findTrackByGuid(assetGUID);
    if (!track) {
      return;
    }
    track.status = MultiTrackConstants.ENDED;

    if (track.nextTrack) {
      track.nextTrack.status = MultiTrackConstants.CURRENT;
    }

    return new MultiTrackList(this.channelIdentifier, this.tracks);
  }

  /**
   * Returns the list after resume
   */
  public listAfterResume(): MultiTrackList {
    let track = this.tracks.find(track => {
      return track.status === MultiTrackConstants.CURRENT;
    });

    if (!track) {
      track = this.tracks.find(track => {
        return track.status === MultiTrackConstants.PRELOAD;
      });
    }

    if (!track) {
      return;
    }
    track.status = MultiTrackConstants.CURRENT;

    return new MultiTrackList(this.channelIdentifier, this.tracks);
  }

  /**
   * Returns the list after skip the track
   */
  public listAfterSkip(): MultiTrackList {
    const track = this.getCurrentTrack();
    if (!track) {
      return;
    }
    track.status = MultiTrackConstants.SKIP;
    if (track.nextTrack) {
      track.nextTrack.status = MultiTrackConstants.CURRENT;
    }

    return new MultiTrackList(this.channelIdentifier, this.tracks);
  }

  /**
   * Returns the list after a track is marked as an error.
   */
  public listAfterError(assetGUID): MultiTrackList {
    this.tracks.forEach(track => {
      track.status = null;
    });

    const track = this.findTrackByGuid(assetGUID);

    if (!track) {
      return;
    }
    track.status = MultiTrackConstants.ERROR;

    return new MultiTrackList(this.channelIdentifier, this.tracks);
  }

  /**
   * Returns the currently playing track
   * @todo move checking preload into a different function
   */
  public getCurrentTrack(): IClip | null {
    const track = this.tracks.find(track => {
      return (
        track.status === MultiTrackConstants.CURRENT ||
        track.status === MultiTrackConstants.PRELOAD
      );
    });
    return track || this.tracks[0];
  }

  /**
   * Returns the currently pre loaded track
   */
  public getPreLoadedTrack(): IClip | null {
    return this.tracks.find(track => {
      return track.status === MultiTrackConstants.PRELOAD;
    });
  }

  /**
   * This returns the next track that should be preloaded.
   * Furthest right that isn't consumed. or error. or skip.
   *  or preload
   */
  public nextForPreload(): IClip | null {
    return this.tracks.find(track => {
      return track.status === MultiTrackConstants.FUTURE;
    });
  }

  /**
   * toArray
   */
  public toArray(): IClip[] {
    return this.tracks ? this.tracks : [];
  }

  /**
   * Returns the channel identifier
   */
  public getChannelIdentifier(): string {
    return this.channelIdentifier;
  }

  /**
   * Returns a boolean about whether the list is pristine.
   */
  public isPristine(): boolean {
    return !this.tracks.some((track: IClip) => {
      return (
        track.status === MultiTrackConstants.PRELOAD ||
        track.status === MultiTrackConstants.CURRENT ||
        track.status === MultiTrackConstants.SKIP
      );
    });
  }

  /**
   * Returns a boolean about whether the list is preloading a song.
   */
  public isPreloading(): boolean {
    return this.tracks.some((track: IClip) => {
      return track.status === MultiTrackConstants.PRELOAD;
    });
  }

  public updateTrackAffinity(affinity, assetGUID): void {
    this.tracks.some(track => {
      if (track.assetGUID === assetGUID) {
        track.affinity = affinity;
        return true;
      }
    });
  }

  /**
   * Prevents edge case where an interstitial can be shorter than
   * previous track crossFade duration which is a nonsensical situation.
   * And should be prevented.
   */
  public cleanCrossfade(tracks): void {
    tracks.forEach(track => {
      if (!track.previousTrack) {
        return;
      }

      // 0.4 seconds of tolerance.
      const overlapPeriod =
        msToSeconds(track.previousTrack.crossfade.crossFade) + 0.4;

      if (overlapPeriod > track.duration) {
        // this track ends before previous track ends. lol.
        track.previousTrack.crossfade.crossFade = -1; // turn off overlapping.
      }
    });
  }
}
