import { mergeMap, filter } from 'rxjs/operators';
import { Logger } from '../../logger/logger';
import { IPlayhead, Playhead } from '../playhead.interface';
import { PlayheadTimestampService } from '../playhead-timestamp.service';
import { ChromecastModel } from '../../chromecast/chromecast.model';
import { ChromecastPlayerConsts } from './chromecast-player.consts';
import { ChromecastMessageBus } from '../../chromecast/chromecast.message-bus';
import { msToSeconds } from '../../util/utilities';
import { BehaviorSubject } from 'rxjs';
import { CurrentlyPlayingService } from '../../currently-playing/currently.playing.service';
import { MediaUtil } from '../media.util';

export class ChromecastPlayerEventMonitor {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger(
    'ChromecastPlayerEventMonitor',
  );

  /**
   * Indicates when the video player is ready for playback.
   */
  public playbackReady$: BehaviorSubject<any> = null;

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the current playhead timestamp.
   */
  public playhead$: BehaviorSubject<IPlayhead> = null;

  /**
   * Stream that emits a flag indicating that the currently loaded media asset has
   * played in its entirety, and a subsequent "play" request has not yet been made.
   */
  public playbackComplete$: BehaviorSubject<boolean> = null;

  /**
   * Contains permutations of the playhead value in both the zero-based and zulu-based milliseconds and seconds.
   */
  private playhead: IPlayhead = new Playhead();

  /**
   * The duration of the audio content.
   */
  private duration: number = 0;

  /**
   * The start time for the current episode. This is used to calculate the zero-based playhead value by
   * subtracting the episode's zuku start time from the current playhead zulu time.
   */
  private episodeZuluStartTime: number = 0;

  /**
   * Constructor
   * @param {PlayheadTimestampService} playheadTimeStampService
   * @param {ChromecastModel} chromecastModel
   * @param {ChromecastMessageBus} chromecastMessageBus
   * @param {CurrentlyPlayingService} currentlyPlayingService
   */
  constructor(
    public playheadTimeStampService: PlayheadTimestampService,
    private chromecastModel: ChromecastModel,
    private chromecastMessageBus: ChromecastMessageBus,
    private currentlyPlayingService: CurrentlyPlayingService,
  ) {
    this.playhead$ = new BehaviorSubject(this.playhead);
    this.listenToMessageBus();
  }

  /**
   * Used to listen the message bus. and does the actions based on message type
   */
  public listenToMessageBus() {
    this.chromecastModel.sessionTransferred
      .pipe(
        filter(sessionTransferred => sessionTransferred === true),
        mergeMap(() => this.chromecastMessageBus.message$),
        filter(
          (event: any) =>
            event.type ===
              ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT
                .UPDATE_METADATA ||
            event.type ===
              ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.MEDIA_FINISHED ||
            event.type ===
              ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.MEDIA_SKIPPED ||
            event.type ===
              ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.MEDIA_LOADED ||
            event.type ===
              ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.ERROR,
        ),
      )
      .subscribe(event => this.onMessageReceived(event));
  }

  private onMessageReceived(event): void {
    if (event) {
      // Grab the event's data in a shortcut var.
      const eventData: any = event.data;
      const eventType: string = event.type;
      ChromecastPlayerEventMonitor.logger.info(
        `onMessageReceived( ${eventType} )`,
      );

      // Determine the event type and respond accordingly.
      switch (eventType) {
        // This is essentially the playhead event or heartbeat and should reflect the Chromecast Receiver's
        // audio player's current timestamp. It's main is to help the web client's UI reflect the Chromecast
        // Receiver's audio player's place in audio.
        case ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.UPDATE_METADATA:
          ChromecastPlayerEventMonitor.logger.info(
            `playheadZulu: ${new Date(eventData.playheadZulu).toTimeString()}`,
          );
          ChromecastPlayerEventMonitor.logger.info(
            `playheadStartZulu : ${new Date(
              eventData.playheadStartZulu,
            ).toTimeString()}`,
          );
          ChromecastPlayerEventMonitor.logger.info(
            `startedZulu : ${new Date(eventData.startedZulu).toTimeString()}`,
          );
          ChromecastPlayerEventMonitor.logger.info(
            `duration       : ${eventData.duration}`,
          );
          //ChromecastReceiverEventManager.logger.info(`eventData   : ${JSON.stringify(eventData)}`);
          ChromecastPlayerEventMonitor.logger.info(
            '**********************************************',
          );

          // Save the last known playhead timestamp
          this.chromecastModel.playheadZulu = eventData.playheadZulu;
          this.chromecastModel.playheadStartZulu = eventData.playheadStartZulu;
          this.chromecastModel.playheadDurationSeconds = eventData.duration;

          if (
            !this.chromecastModel.loadInProgress &&
            this.chromecastModel.currentlyPlayingData.assetGuid !==
              event.data.metaData.assetGuid
          ) {
            this.chromecastModel.currentlyPlayingData = {
              channelId: event.data.metaData.channelId,
              assetGuid: event.data.metaData.assetGuid,
              mediaType: event.data.metaData.mediaType,
              tuneResponse: event.data.metaData.tuneResponse
                ? event.data.metaData.tuneResponse
                : null,
            };
            this.chromecastModel.triggerTuneChanged({
              channelId: this.chromecastModel.currentlyPlayingData.channelId,
              assetGuid: this.chromecastModel.currentlyPlayingData.assetGuid,
              mediaType: this.chromecastModel.currentlyPlayingData.mediaType,
            });
          }

          if (
            !this.chromecastModel.loadInProgress &&
            this.chromecastModel.currentlyPlayingData.assetGuid ===
              event.data.metaData.assetGuid &&
            MediaUtil.isMultiTrackAudioMediaType(event.data.metaData.mediaType)
          ) {
            this.chromecastModel.currentlyPlayingData.tuneResponse = event.data
              .metaData.tuneResponse
              ? event.data.metaData.tuneResponse
              : null;
          }
          if (!this.playhead) {
            this.playhead = new Playhead();
          }

          this.playhead.startTime.milliseconds = 0;
          this.playhead.startTime.seconds = 0;
          this.playhead.startTime.zuluMilliseconds =
            eventData.playheadStartZulu;
          this.playhead.startTime.zuluSeconds = msToSeconds(
            eventData.playheadStartZulu,
          );

          const playheadMs: number =
            eventData.playheadZulu - eventData.playheadStartZulu;
          this.playhead.currentTime.milliseconds = playheadMs;
          this.playhead.currentTime.seconds = msToSeconds(playheadMs);
          this.playhead.currentTime.zuluMilliseconds = eventData.playheadZulu;
          this.playhead.currentTime.zuluSeconds = msToSeconds(
            eventData.playheadZulu,
          );
          this.playhead.id = eventData.currentTrackGuid;
          this.playhead.assetGuid = event.data.metaData.assetGuid;
          this.playhead.type = '';
          this.playhead$.next(this.playhead);
          this.playheadTimeStampService.playhead.next(this.playhead);

          // TODO: BMR: 07/12/2018: Need to finish Chromecast update metadata and playhead handling from Receiver to Sender.
          this.currentlyPlayingService.setCurrentPlayingData(
            this.playhead,
            eventData.channelType,
          );
          break;
        case ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.MEDIA_FINISHED: {
          this.playhead.type = eventType;
          this.playhead.id = eventData.currentTrackId;
          this.playhead$.next(this.playhead);
          break;
        }
        case ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.MEDIA_SKIPPED: {
          this.playhead.type = eventType;
          this.playhead.id = eventData.currentTrackId;
          this.playhead$.next(this.playhead);
          break;
        }
        case ChromecastPlayerConsts.CHROMECAST_RECEIVER_EVENT.MEDIA_LOADED: {
          this.playhead.type = eventType;
          this.playhead.id = eventData.currentTrackId;
          this.playhead$.next(this.playhead);
          break;
        }
      }
    }
  }
}
