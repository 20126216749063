import { of as observableOf, Observable, BehaviorSubject } from 'rxjs';
import { share, catchError, mergeMap, map } from 'rxjs/operators';
import * as _ from 'lodash';
import {
  IAutoCompleteResult,
  IRecentSearchResult,
  ISearchResults,
} from './search.interface';
import { IProviderDescriptor, ISetting, ISettings, IChannel } from '../index';
import { addProvider } from '../service';
import { Logger } from '../logger';
import { SearchDelegate } from './search.delegate';
import { SettingsService } from '../settings';
import { SearchConsts } from '../service/consts/api.request.consts';
import { ChannelLineupService } from '../channellineup/channel.lineup.service';

/**
 * @MODULE:     service-lib
 * @CREATED:    09/13/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * SearchService to get the recently played data for the user.
 */

const clearSearchSettingConst: string = 'SearchDeletionTimestamp';

export class SearchService {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('SearchService');

  /**
   * An observable (hot, subscribe returns current search keyword) that can be used on client side
   */
  public searchKeyword: Observable<string>;

  /**
   * subject for delivering searchKeyword.
   */
  private searchKeywordSubject: BehaviorSubject<string> = new BehaviorSubject(
    '',
  );

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the recentSearchResults Data
   * and be notified when the recentSearchResultsData.
   */
  public searchResults: Observable<ISearchResults>;

  /**
   * The current recentSearchResultsData.
   */
  private searchResultsData: ISearchResults;

  /**
   * subject for delivering search Results Data through the searchResults observable.
   */
  private searchResultsSubject: BehaviorSubject<
    ISearchResults
  > = new BehaviorSubject(null);

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the recentSearchResults Data
   * and be notified when the recentSearchResults data changes.
   */
  public recentSearchResults: Observable<Array<IRecentSearchResult>>;

  /**
   * The current recent SearchResults Data
   */
  private recentSearchResultsData: Array<IRecentSearchResult> = [];

  /**
   * subject for delivering recentSearchResultsData through the recentSearchResults observable.
   */
  private recentSearchResultsSubject: BehaviorSubject<
    Array<IRecentSearchResult>
  > = new BehaviorSubject([]);

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the autoCompleteSearchResults Data
   * and be notified when the autoCompleteSearchResults data changes.
   */
  public autoCompleteSearchResults: Observable<Array<IAutoCompleteResult>>;

  /**
   * Holds the autoComplete Search Data
   */
  private autoCompleteSearchResultsData: Array<IAutoCompleteResult> = [];

  /**
   * subject for delivering autoCompleteSearchResultsData through the autoCompleteSearchResults observable.
   */
  private autoCompleteSearchResultsSubject: BehaviorSubject<
    Array<IAutoCompleteResult>
  > = new BehaviorSubject([]);

  /**
   * An observable (hot, subscribe returns most recent item) that can be used to obtain the directTuneSearchResults Data
   * and be notified when the directTuneSearchResults data changes.
   */
  public directTuneSearchResults: Observable<Array<IChannel>>;

  /**
   * Holds the direct Tune Search Data
   */
  private directTuneSearchResultsData: Array<IChannel> = [];

  /**
   * subject for delivering directTuneSearchResultsData through the directTuneSearchResults observable.
   */
  private directTuneSearchResultsSubject: BehaviorSubject<
    Array<IChannel>
  > = new BehaviorSubject([]);

  /**
   * Holds the User settings - used to clear the recent search results by updating the settings
   */
  private settings: ISettings;

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(SearchService, SearchService, [
      SearchDelegate,
      SettingsService,
      ChannelLineupService,
    ]);
  })();

  /**
   * Constructor -
   * @param searchDelegate gives us access to the search api's
   * @param settingsService gives us access to update the settings to cleat the search results
   * @param channelLineupService is used to fill out search results from data from the channel lineup
   */
  constructor(
    private searchDelegate: SearchDelegate,
    private settingsService: SettingsService,
    private channelLineupService: ChannelLineupService,
  ) {
    this.recentSearchResults = this.recentSearchResultsSubject;

    this.searchResults = this.searchResultsSubject;

    this.autoCompleteSearchResults = this.autoCompleteSearchResultsSubject;

    this.directTuneSearchResults = this.directTuneSearchResultsSubject;

    this.searchKeyword = this.searchKeywordSubject;

    this.settingsService.settings.subscribe(response => {
      this.settings = response;
    });
  }

  /**
   * Used to get Recent Search Results.
   * updates the recentSearchResultsData
   * @returns {Observable<boolean | {}>} - return boolean value based on success or failure API call.
   */
  public getRecentSearchResults(): Observable<boolean> {
    SearchService.logger.debug('getSearchResults()');

    return this.searchDelegate.getRecentSearchResults().pipe(
      map((response: Array<IRecentSearchResult>) => {
        this.recentSearchResultsData = response;
        this.recentSearchResultsSubject.next(this.recentSearchResultsData);

        return true;
      }),
      catchError(error => {
        SearchService.logger.error(`getRecentSearchResults FAILED ${error}`);

        // NOTE: Don't want gup failure to cause harm here. always pass back true, log the failure.  This keeps app
        // bootstrap from failing because we cannot get the recent search results.
        return observableOf(true);
      }),
      share(),
    );
  }

  /**
   * Used to update recentSearchResults with new search keywords
   * @param {Keyword:string}
   */
  public getSearchResults(keyword): void {
    SearchService.logger.debug('getSearchResults()');
    this.updateRecentSearchList(keyword);
  }

  /**
   * Used to update search keyword
   * @param {Keyword:string}
   */
  public updateSearchKeyword(keyword): void {
    SearchService.logger.debug('updateSearchKeyword()');
    this.searchKeywordSubject.next(keyword);
  }

  /**
   * Used to fetch the autocomplete search results.
   * @param {Keyword:string}
   * @returns {Observable<boolean | {}>} - return boolean value based on success or failure API call.
   */
  public getAutoCompleteSearchResults(keyword): Observable<boolean | {}> {
    SearchService.logger.debug(`getAutoCompleteSearchResults(${keyword})`);

    this.getTuneResults(keyword);

    this.autoCompleteSearchResultsData = [];
    this.autoCompleteSearchResultsSubject.next(
      this.autoCompleteSearchResultsData,
    );

    return this.searchDelegate.getAutoCompleteSearchResults(keyword).pipe(
      mergeMap((response: Array<IAutoCompleteResult>) => {
        this.autoCompleteSearchResultsData = response;
        this.autoCompleteSearchResultsSubject.next(
          this.autoCompleteSearchResultsData,
        );

        return observableOf(true);
      }),
      catchError((/*error*/) => {
        return observableOf(false);
      }),
      share(),
    );
  }

  /**
   * Used to clear the search results by updating the global Settings for User.
   * @returns {Observable<boolean | {}>} - return boolean value based on success or failure API call.
   */
  private getTuneResults(searchKeyword): void {
    const keyword = searchKeyword.trim();
    const isNumber = !isNaN(parseInt(keyword, 10));
    let channels: Array<IChannel>;

    this.directTuneSearchResultsData = [];

    if (isNumber) {
      channels =
        keyword.length > 1
          ? this.channelLineupService.findChannelByNumber(keyword)
          : this.channelLineupService.findChannelByNumber('0' + keyword);

      this.directTuneSearchResultsData = channels || [];
    }

    this.directTuneSearchResultsData = this.directTuneSearchResultsData.concat(
      this.channelLineupService.findChannelsByName(keyword),
    );

    this.directTuneSearchResultsData = _.uniqBy(
      this.directTuneSearchResultsData,
      'channelNumber',
    );

    this.directTuneSearchResultsSubject.next(this.directTuneSearchResultsData);
  }

  /**
   * Used to clear the search results by updating the global Settings for User.
   * @returns {Observable<boolean | {}>} - return boolean value based on success or failure API call.
   */
  public clearSearchResults(): Observable<boolean | {}> {
    SearchService.logger.debug('clearSearchResults()');

    const settingsPayload: ISettings = this.settings;

    const searchDeletionTimestamp: ISetting = settingsPayload.globalSettings.find(
      setting => setting.name == clearSearchSettingConst,
    );
    const deletionTimestamp = new Date().toISOString();

    if (!searchDeletionTimestamp) {
      settingsPayload.globalSettings.push({
        name: clearSearchSettingConst,
        value: deletionTimestamp,
      });
    } else {
      searchDeletionTimestamp.value = deletionTimestamp;
    }

    return this.settingsService.updateSettings(settingsPayload).pipe(
      map(response => {
        if (response) {
          this.recentSearchResultsData = [];
          this.recentSearchResultsSubject.next(this.recentSearchResultsData);
        }
        return response;
      }),
    );
  }

  /**
   * Used to update the recent-search results when user create a new search record.
   */
  private updateRecentSearchList(keyword: string): void {
    const recentSearchItem: IRecentSearchResult = {
      searchString: keyword,
      searchDateTime: new Date(),
    };
    this.recentSearchResultsData.unshift(recentSearchItem);
    this.recentSearchResultsData.splice(SearchConsts.MAX_SUGGESTED_RESULTS);
    this.recentSearchResultsSubject.next(this.recentSearchResultsData);
  }
}
