/**
 * @CREATED:    11/15/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * carouselNameConstants are used to display carousels and to determine carousel action.
 **/

/**
 * Used carousel names to determine carousel actions
 **/
export const carouselNameConstants = {
  RECENTLY_PLAYED: 'recently played',
  RECENTLY_PANDORA_STATIONS: 'recent pandora stations',
};
