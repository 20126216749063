export const kochavaAnalyticsConstants = {
    FLOWS: {
        SUBSCRIBE: "Subscribe",
        EXPLORE: "Explore",
        BANNER: "Banner",
        EXPIRED: "Expired",
        SIGN_IN_SIGN_UP: "SignInSignUp",
        GENERAL: "General"
    },
    SCREEN_NAMES: {
        WELCOME: "Welcome",
        CREDENTIALS: "Credentials",
        REEL: "Reel",
        FREE_ACCESS: "FreeAccess",
        STREAMING_OVERLAY: "StreamingOverlay"
    },
    EVENT_TYPES: {
        CLICK: "Click",
        RESPONSE: "Response",
        LOAD: "Load"
    },
    EVENT_ACTIONS: {
        SUBSCRIBE: "Subscribe",
        EXPLORE: "Explore",
        ACCESS_NOW: "AccessNow",
        LOAD: "Load",
        ACCEPT_CONTINUE: "AcceptContinue",
        PRIVACY_CHECKBOX: "PrivacyCheckbox",
        SUCCESS: "Success",
        ERROR: "Error",
        CLOSE: "Close",
        EXPLORE_PLANS: "ExplorePLans",
        LOGIN: "Login",
        GET_STARTED: "GetStarted"
    },
    EVENT_NAMES: {
        GENERAL_WELCOME_SUBSCRIBE: "General-Welcome-Subscribe",
        GENERAL_WELCOME_EXPLORE: "General-Welcome-Explore",
        GENERAL_WELCOME_ACCESSNOW: "General-Welcome-AccessNow",
        GENERAL_WELCOME_LOAD: "General-Welcome-Load",
        SUBSCRIBE_CREDENTIALS_ACCEPTCONTINUE: "Subscribe-Credentials-AcceptContinue",
        SUBSCRIBE_CREDENTIALS_PRIVACYCHECKBOX: "Subscribe-Credentials-PrivacyCheckbox",
        SUBSCRIBE_CREDENTIALS_ACCESSNOW: "Subscribe-Credentials-AccessNow",
        SUBSCRIBE_CREDENTIALS_SUCCESS: "Subscribe-Credentials-Success",
        SUBSCRIBE_CREDENTIALS_ERROR: "Subscribe-Credentials-Error",
        SUBSCRIBE_CREDENTIALS_LOAD: "Subscribe-Credentials-Load",
        EXPLORE_CREDENTIALS_ACCEPTCONTINUE: "Explore-Credentials-AcceptContinue",
        EXPLORE_CREDENTIALS_PRIVACYCHECKBOX: "Explore-Credentials-PrivacyCheckbox",
        EXPLORE_CREDENTIALS_ACCESSNOW: "Explore-Credentials-AccessNow",
        EXPLORE_CREDENTIALS_ERROR: "Explore-Credentials-Error",
        EXPLORE_CREDENTIALS_SUCCESS: "Explore-Credentials-Success",
        EXPLORE_CREDENTIALS_LOAD: "Explore-Credentials-Load",
        SIGNINSIGNUP_CREDENTIALS_LOGIN: "SignInSignUp-Credentials-Login",
        SIGNINSIGNUP_CREDENTIALS_LOAD: "SignInSignUp-Credentials-Load",
        SIGNINSIGNUP_CREDENTIALS_GETSTARTED: "SignInSignUp-Credentials-GetStarted",
        SIGNINSIGNUP_CREDENTIALS_ERROR: "SignInSignUp-Credentials-Error",
        EXPLORE_REEL_CLOSE: "Explore-Reel-Close",
        EXPLORE_REEL_ACCEPTCONTINUE: "Explore-Reel-AcceptContinue",
        EXPLORE_REEL_PRIVACYCHECKBOX: "Explore-Reel-PrivacyCheckbox",
        EXPLORE_REEL_ACCESSNOW: "Explore-Reel-AccessNow",
        EXPLORE_REEL_SUCCESS: "Explore-Reel-Success",
        EXPLORE_REEL_ERROR: "Explore-Reel-Error",
        EXPLORE_REEL_LOAD: "Explore-Reel-Load",
        BANNER_FREEACCESS_SUBSCRIBE: "Banner-FreeAccess-Subscribe",
        BANNER_FREEACCESS_LOAD: "Banner-FreeAccess-Load",
        BANNER_FREEACCESS_EXPLORE: "Banner-FreeAccess-Explore",
        EXPIRED_STREAMINGOVERLAY_LOAD: "Expired-StreamingOverlay-Load",
        EXPIRED_STREAMINGOVERLAY_EXPLOREPLANS: "Expired-StreamingOverlay-ExplorePLans",
        EXPIRED_STREAMINGOVERLAY_LOGIN: "Expired-StreamingOverlay-Login",
        EXPIRED_CREDENTIALS_EXPLOREPLANS: "SignInSignUp-Credentials-ExplorePLans"
    }
};

