import { INeriticLinkData } from '../carousel';

export interface IResumeMedia {
  data: any;
  updateFrequency: number;
  type: string;
  wallClockRenderTime?: string;
  isDeepLinkResume: boolean;
  deepLinkContentType?: string;
  resumeAction?: INeriticLinkData;
}

export interface IResumeGlobalSetting {
  settingName: string;
  settingValue: string;
}

//all the other deeplink types are just passed throught, but we need to watch for this one.
export const LIVE_VIDEO_DEEPLINK_TYPE = 'livevideo';
