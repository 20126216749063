import { Logger } from '../logger';
import { addProvider, IProviderDescriptor } from '../service';
import { BehaviorSubject, Observable } from 'rxjs';
import { MultiTrackList } from '../mediaplayer';
import {
  ICurrentMetaData,
  //ITuneModel,
  //TuneState,
  //TuneStatus,
} from '../tune/tune.model';

export class RefreshTracksModel {
  /**
   * Internal logger.
   */
  private static logger: Logger = Logger.getLogger('RefreshTracksModel');

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(RefreshTracksModel, RefreshTracksModel, []);
  })();

  private model: IRefreshTracksModel = {
    refreshState: 'IDLE',
    refreshStatus: 'SUCCESS',
    currentMetaData: null,
  };

  private refreshModelSubject: BehaviorSubject<
    IRefreshTracksModel
  > = new BehaviorSubject(this.model);

  public refreshTracksModel$: Observable<IRefreshTracksModel> = this
    .refreshModelSubject;

  public set refreshState(refreshState: RefreshState) {
    if (this.model.refreshState === refreshState) {
      return;
    }

    this.model.refreshState = refreshState;

    this.refreshModelSubject.next(this.model);
  }

  public get refreshState() {
    return this.model.refreshState;
  }

  public set refreshStatus(refreshStatus: RefreshStatus) {
    if (this.model.refreshStatus === refreshStatus) {
      return;
    }

    this.model.refreshStatus = refreshStatus;

    this.refreshModelSubject.next(this.model);
  }

  public get refreshStatus() {
    return this.model.refreshStatus;
  }

  public set currentMetaData(currentMetaData: ICurrentMetaData) {
    if (
      this.model.currentMetaData &&
      this.model.currentMetaData.mediaId === currentMetaData.mediaId
    ) {
      return;
    }

    this.model.currentMetaData = currentMetaData;

    this.refreshModelSubject.next(this.model);
  }

  public get currentMetaData() {
    return this.model.currentMetaData;
  }

  public set refreshModelData(refreshModel: IRefreshTracksModel) {
    this.model = refreshModel;

    this.refreshModelSubject.next(this.model);
  }

  public get refreshModelData(): IRefreshTracksModel {
    return this.model;
  }

  /**
   * Constructor
   */
  constructor() {
    RefreshTracksModel.logger.debug('constructor()');
  }
}

export interface IRefreshTracksModel {
  refreshStatus?: RefreshStatus;
  refreshState?: RefreshState;
  currentMetaData?: ICurrentRefreshMetaData;
}

export interface ICurrentRefreshMetaData {
  mediaType: string;
  mediaId: string; // station id
  channelId: string; // station factory
  tracks?: MultiTrackList;
}

export type RefreshStatus = 'SUCCESS' | 'FAILURE';

export type RefreshState = 'IDLE' | 'REFRESHING';
