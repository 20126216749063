import { MediaPlayerConstants } from '../media-player.consts';

export class ChromecastPlayerConsts extends MediaPlayerConstants {
  public static PRIMARY_END_POINT = 'primary';

  public static LARGE_SIZE = 'large';

  public static TYPE: string = 'Chromecast';

  public static STATE = {
    UNKNOWN: 'UNKNOWN',
    CONNECTED: 'CASTING_CONNECTED',
    DISCONNECTED: 'DISCONNECTED',
    MODULEREADY: 'MODULEREADY',
  };

  public static CHROMECAST_RECEIVER_EVENT = {
    PING: 'ping',
    LOG: 'log',
    TRANSFER_SESSION_COMPLETE: 'transfer-session-complete',
    SENDER_DISCONNECT: 'sender-disconnect',
    SENDER_DISCONNECT_RECAST: 'sender-disconnect-recast',
    SESSION_IN_PROGRESS: 'session-in-progress',
    SESSION_UPDATE: 'session-update',
    RECOVER_SESSION: 'recover-session',
    UPDATE_METADATA: 'update-metadata',
    MYSXM_NOT_AVAILABLE_FOR_CASTING: 'mysxm-not-available-for-casting',
    PAUSE: 'pause',
    SKIP: 'skip',
    ERROR: 'error',
    STOPPED: 'stopped',
    VOLUME: 'volume',
    AIC_TUNE_RESPONSE: 'aic-tune-response',
    SEEDED_TUNE_RESPONSE: 'seeded-tune-response',
    MEDIA_FINISHED: 'media-finished',
    MEDIA_LOADED: 'media-loaded',
    MEDIA_SKIPPED: 'media-skipped',
    REFRESH_TRACKS_RESPONSE: 'refresh-tracks-response',
    SEEDED_REFRESH_TRACKS_RESPONSE: 'seeded-refresh-tracks-response',
  };

  public static CHROMECAST_PLAYER_STATE = {
    IDLE: 'idle',
    LOADING: 'loading',
    LOADED: 'loaded',
    PLAYING: 'playing',
    PAUSED: 'paused',
    STOPPED: 'stopped',
    ERROR: 'error',
  };

  public static CHROMECAST_SENDER_EVENT = {
    PING: 'ping',
    TRANSFER_SESSION: 'transfer-session',
    SKIP: 'skip',
    REFRESH_TRACKS: 'refresh-tracks',
    SEEDED_REFRESH_TRACKS: 'seeded-refresh-tracks',
    SEEDED_AFFINITY: 'seeded-affinity',
  };

  /**
   * chromecast error types receiver from the Receiver
   * @type {String}
   */
  public static CHROMECAST_ERRORS = {
    AOD_PLAYBACK_FAILURE: 'aod-playback-failure',
    LIVE_PLAYBACK_FAILURE: 'live-playback-failure',
    LIVE_FAILOVER_FAILURE: 'live-failover-failure',
    AUDIO_PLAYBACK_TIMEOUT: 'audio-playback-timeout',
    LOAD_REQUEST_CONFIG: 'load-request-config',
    SIMULTANEOUS_LOGIN: 'simultaneous-login',
    TRANSFER_SESSION_FAILED: 'transfer-failed',
  };
}
