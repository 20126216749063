import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import {
  IProviderDescriptor,
  addProvider,
  HttpProvider,
  Logger,
  //ModuleAreaDetailRequest,
  ServiceEndpointConstants,
  IHttpRequestConfig,
  ApiLayerTypes,
  //ModuleRequest,
  ModuleAreaRequest,
  ApiDelegate,
} from '../index';
import { SeededStationUpdateItem } from './seeded-stations.interface';

/**
 * @MODULE:     service-lib
 * @CREATED:    08/30/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * This delegate is responsible remove seeded station.
 */
export class SeededStationsDelegate {
  /**
   * Internal logger.
   * @type {Logger}
   */
  private static logger: Logger = Logger.getLogger('SeededStationsDelegate');

  /**
   * Required!!!
   * Specifically used to keep the deps array in sync with the parameters the constructor takes.
   */
  private static providerDescriptor: IProviderDescriptor = (function() {
    return addProvider(SeededStationsDelegate, SeededStationsDelegate, [
      HttpProvider,
    ]);
  })();

  /**
   * Constructor
   * @param  http - Used to make API calls
   */
  constructor(private http: HttpProvider) {}

  public removeSeededStation(
    gupId: string,
    seededStation: SeededStationUpdateItem,
  ): Observable<Array<any>> {
    return this.removeSeededStations(gupId, [seededStation]);
  }

  public removeSeededStations(
    gupId: string,
    seededStations: Array<SeededStationUpdateItem>,
  ): Observable<Array<any>> {
    const request = {
      stationsList: seededStations,
    };

    const areaRequest = new ModuleAreaRequest(
      'Profile',
      'seededStations',
      request,
    );

    const config: IHttpRequestConfig = { params: {} };
    config.params[ApiLayerTypes.GUP_ID] = gupId;
    ApiDelegate.addNoResponseDataException(
      ServiceEndpointConstants.endpoints.SEEDED_RADIO.V4_REMOVE_SEEDED_STATION,
      ServiceEndpointConstants.endpoints.SEEDED_RADIO.V4_REMOVE_SEEDED_STATION,
    );

    return this.http
      .postModuleAreaRequest(
        ServiceEndpointConstants.endpoints.SEEDED_RADIO
          .V4_REMOVE_SEEDED_STATION,
        areaRequest,
        config,
      )
      .pipe(share());
  }
}
