import { ApiLayerTypes } from '../service/consts';

export class StorageKeyConstant {
  public static CONSUME = 'storageKey.consume';
  public static VOLUME = 'storageKey.volume';
  public static IS_MUTED = 'storageKey.isMuted';
  public static DMCA = 'storageKey.dmca';
  public static USER = 'storageKey.user';
  public static YOUJUSTHEARD = 'storageKey.youjh';
  public static FAVORITES = 'storageKey.favorites';
  public static THROGHPOPOUT = 'storageKey.throughpopout';
  public static PAUSEPOINT = 'storageKey.pausepoint';
  public static SKIP_DATA = 'storageKey.skipData';
  public static PREV_PAUSEPOINT = 'storageKey.pausepoint.prev';
  public static VERSION_INFO = 'storageKey.versionInfo';
  public static COACHMARKS = 'storageKey.coachMarks';
  public static EDP_SORTPREFERENCE = 'storageKey.edpSortPreference';
  public static USERNAME = 'username';
  public static LANGUAGE = 'language';
  public static CLIENT_DEVICE_ID = ApiLayerTypes.CLIENT_DEVICE_ID;
  public static IS_DEEPLINKED = 'storageKey.isDeeplinked';
  public static AUTOPLAY_ON_STARTUP = 'autoplayOnStartup';
  public static APP_REGION = 'storageKey.appRegion';
  public static FREE_TRIAL_DURATION_TEXT = 'storageKey.freeTrialDurationText';
  public static PLATINUM_PLAN_TEXT = 'storageKey.platinumPlanText';
  public static READY_TO_LISTEN = 'stroageKey.readyToListen';
  public static READY_TO_LISTEN_TITLE = 'stroageKey.readyToListenTitle';
}
