export class HttpUtilities
{
    public static getCookie(cookieName: string): any
    {
        let cookies = document.cookie;
        let cookieStart = cookies.indexOf(cookieName + "=");

        if (cookieStart >= 0)
        {
            const cookie = cookies.substring(cookieStart);
            const cookieEnd = cookie.indexOf(";");
            return cookie.substring(0, (cookieEnd > 0) ? cookieEnd : undefined);
        }
    }

    public static getCookieBody(cookieName: string): any
    {
        let cookie = HttpUtilities.getCookie(cookieName);
        if (cookie)
        {
            const bodyStart = cookie.indexOf("=");
            if (bodyStart >= 0)
            {
                return cookie.substring(bodyStart + 1).replace('"', '');
            }
        }
    }
}
