import { of as observableOf, BehaviorSubject, Observable } from 'rxjs';
import { catchError, mergeMap, map } from 'rxjs/operators';
import { IAppConfig }         from "../config/interfaces/app-config.interface";
import {
    IProviderDescriptor,
    addProvider
} from "../service";
import {
    DeepLinkType,
    IContextualData,
    IContextualLandingData,
    DeepLinkTypes
}                             from "./contextual.interface";
import { Logger }             from "../logger";
import { ContextualDelegate } from "./contextual.delegate";
import { ContextualUtilities } from "./contextual.utilities";
import { ContextualConstants } from "./contextual.constants";


/**
 * @MODULE:     service-lib
 * @CREATED:    01/10/18
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * Use a delegate to make an HTTP API call and the stores the data on itself as a service model.
 */
export class ContextualService
{
    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("ContextualService");

    /**
     * Subject used to trigger the contextualData observable
     * @type {BehaviorSubject<IContextualData>}
     */
    private contextualDataSubject: BehaviorSubject<IContextualData> = new BehaviorSubject<IContextualData>({} as IContextualData);

    /**
     * Observable that can used to get notified when the contextualData have changed
     * @type {BehaviorSubject<IContextualData>}
     */
    public contextualData: Observable<IContextualData> = this.contextualDataSubject;

    /**
     * Required!!!
     * Specifically used to keep the deps array in sync with the parameters the constructor takes.
     */
    private static providerDescriptor: IProviderDescriptor = function ()
    {
        return addProvider(ContextualService, ContextualService, [ ContextualDelegate, "IAppConfig" ]);
    }();

    /**
     * Constructor
     * @param contextualDelgate used to make calls API calls
     */
    constructor(private contextualDelgate: ContextualDelegate, private SERVICE_CONFIG: IAppConfig) {}

    /**
     * gets the contextual landing data.
     * @returns {Observable<IContextualLandingData>}
     */
    public initializeContextualLanding(isFrenchLanguageLoaded: boolean = false): Observable<IContextualLandingData>
    {
        const queryString = this.SERVICE_CONFIG.contextualInfo.queryString;
        const userAgent   = this.SERVICE_CONFIG.contextualInfo.userAgent;

        const contentType       = ContextualUtilities.getQueryParameterValue(queryString, ContextualConstants.CONTENT_TYPE);
        const channelName       = ContextualUtilities.getQueryParameterValue(queryString, ContextualConstants.CHANNEL_NAME);
        const iosVersion        = ContextualUtilities.getIOSVersion(userAgent);
        const operatingSystem   = ContextualUtilities.getOperatingSystem(userAgent);
        const contextualAppInfo = ContextualUtilities.getContextualAppInfo(operatingSystem);
        let universalLink       = "";
        const storeUrl          = ContextualUtilities.getStoreUrl(userAgent, this.SERVICE_CONFIG.deviceInfo.appRegion);
        const actionButtonUrl   = ContextualUtilities.getActionButtonUrl(userAgent,
                                                                         this.SERVICE_CONFIG.deviceInfo.appRegion,
                                                                         queryString,
                                                                         isFrenchLanguageLoaded);
        const isIOS = operatingSystem === ContextualConstants.IOS;
        const isIOSUS           = operatingSystem === ContextualConstants.IOS
                                && this.SERVICE_CONFIG.deviceInfo.appRegion === ContextualConstants.REGION_US;
        const actionButtonText  = isIOS ? "landing.signUp": "landing.downloadApp";
        let appDeepLinkUrl      = "";

        if (iosVersion && iosVersion >= 9)
        {
            universalLink = ContextualUtilities.getUniversalLink(this.SERVICE_CONFIG.contextualInfo.hostName,
                this.SERVICE_CONFIG.contextualInfo.protocol,
                queryString,
                contentType,
                channelName);
            appDeepLinkUrl      = this.getDeepLinkUrl(true);
        }
        else
        {
            appDeepLinkUrl      = this.getDeepLinkUrl();
        }

        if (!contentType && !channelName)
        {
            ContextualService.logger.debug(`initializeContextualLanding() - deep link type & id are empty`);
            return observableOf({
                contentType             : ContextualUtilities.getContentType(contentType),
                channelName             : channelName,
                iosVersion              : iosVersion,
                isIOS                   : isIOS,
                universalLink           : universalLink,
                storeUrl                : storeUrl,
                actionButtonUrl         : actionButtonUrl,
                operatingSystem         : operatingSystem,
                contextualLandingAppInfo: contextualAppInfo,
                appDeepLink             : appDeepLinkUrl,
                contextualData          : null,
                actionButtonText        : actionButtonText
            });
        }

        return this.getContextualData(channelName, contentType as DeepLinkType).pipe(
                       map((response: IContextualData) =>
                       {
                           return {
                               contentType             : ContextualUtilities.getContentType(contentType),
                               channelName             : channelName,
                               iosVersion              : iosVersion,
                               isIOS                   : isIOS,
                               universalLink           : universalLink,
                               storeUrl                : storeUrl,
                               actionButtonUrl         : actionButtonUrl,
                               operatingSystem         : operatingSystem,
                               contextualLandingAppInfo: contextualAppInfo,
                               appDeepLink             : appDeepLinkUrl,
                               contextualData          : response,
                               actionButtonText        : actionButtonText
                           };
                       }));
    }

    /**
     * Used to get the contextual PDT. if channel name and type existed then it makes the deep link
     * API call otherwise it returns the default data.
     * @returns {Observable<IContextualData>}
     */
    public getContextualPDT(): void
    {
        const contentType = this.SERVICE_CONFIG.contextualInfo.type as DeepLinkType;
        const channelName = this.SERVICE_CONFIG.contextualInfo.id;

        if (!contentType && !channelName)
        {
            ContextualService.logger.debug(`getContextualPDT() - deep link type & id are empty`);
            this.contextualDataSubject.next(null);
            return;
        }

        this.getContextualData(channelName, contentType);
    }

    /**
     * gets the deeplink url based on device type desktop /mobile
     *  Note : New Deeplink "/login" have only content type.
     * @returns {string}
     */
    public getDeepLinkUrl(useAppLink?: boolean): string
    {
        const queryString = this.SERVICE_CONFIG.contextualInfo.queryString;
        const userAgent   = this.SERVICE_CONFIG.contextualInfo.userAgent;

        const contentType  = ContextualUtilities.getQueryParameterValue(queryString, ContextualConstants.CONTENT_TYPE);
        const channelName  = ContextualUtilities.getQueryParameterValue(queryString, ContextualConstants.CHANNEL_NAME);
        const params       = ContextualUtilities.getCleanedSearchString(queryString);
        const iosVersion   = ContextualUtilities.getIOSVersion(userAgent);
        const isIOSNine    = iosVersion && iosVersion >= 9;
        const deviceType   = ContextualUtilities.getDeviceType(userAgent);
        let mobileDeepLink = 'SIRIUSXM://player.siriusxm.com/';
        let webDeepLink    = this.SERVICE_CONFIG.contextualInfo.protocol + "//"
            + this.SERVICE_CONFIG.contextualInfo.host + "/" + params;

        if (contentType && channelName)
        {
            const type = params ? "&type=" : "?type=";
            webDeepLink += type + contentType + "&id=" + channelName;
            mobileDeepLink += contentType + '/' + channelName;
        }
        else if(contentType)
        {
            const type = params ? "&type=" : "?type=";
            webDeepLink += type + contentType;
            mobileDeepLink += contentType;
        }

        mobileDeepLink += params;

        if (deviceType === ContextualConstants.MOBILE && (!isIOSNine || useAppLink))
        {
            // Because of some behaviors in iOS 9+, we won't deeplink
            // to any version newer than that.
            // Instead we rely on Apple's Universal Links

            return mobileDeepLink;
        }
        else if (deviceType === ContextualConstants.DESKTOP)
        {
            return webDeepLink;
        }
        else
        {
            return "";
        }
    }

    /**
     * method used to get the login PDT data based on deep link ID and Type.
     * @param deepLinkId
     * @param deepLinkType
     * @returns observable that can be subscribed to landing data response
     */
    private getContextualData(deepLinkId: string, deepLinkType: DeepLinkType): Observable<IContextualData>
    {
        let deepLinkContentType = ContextualUtilities.getContentType(deepLinkType);

        const response = this.contextualDelgate.getContextualPDT(deepLinkId, deepLinkContentType as DeepLinkType);

        response.subscribe(onLandingDataAvailable.bind(this), onLandingDataFault.bind(this));

        //TODO - Can we clean this up, and make it more succinct.
        return response.pipe(mergeMap((response: any) =>
                       {
                           return observableOf(response);

                       }),
                       catchError(() => observableOf(null))) as Observable<IContextualData>;

        /**
         * Once the contextual data available,  kicks of observable.
         * @param contextual - returned by the delegate
         */
        function onLandingDataAvailable(contextualData: IContextualData)
        {
            this.contextualDataSubject.next(contextualData);
        }

        /**
         * Once delegate throws exception then fault handler get called.
         * @param error - returned by the delegate
         */
        function onLandingDataFault(error: Error)
        {
            ContextualService.logger.error(`getLoginPDT - Error (${JSON.stringify(error)})`);
            this.contextualDataSubject.next(null);
        }
    }
}
