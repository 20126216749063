import { ENowPlayingStatus } from '../../types/now-playing-status.enum';
import { IVideoPlayerData } from '../../types/video-player-data.interface';
import {
  IChannel,
  IDmcaInfo,
  IMediaCut,
  IMediaEpisode,
  IMediaItem,
  IMediaShow,
  IMediaVideo,
  IOnDemandEpisode,
  IOnDemandShow,
  IPlayhead,
  ISubCategory,
  ISuperCategory,
  IYouJustHeard,
} from '../../servicelib';
import { IAction } from './action.interface';

export interface INowPlayingActionPayload {
  albumName: string;
  albumImage: string;
  artistInfo: string;
  artistName: string;
  backgroundImage: string;
  backgroundColor?: string;
  posterImageUrl?: string;
  channel: IChannel;
  channelLogoAltText?: string;
  channelLogoUrl?: string;
  channelName: string;
  channelNumber: string;
  cut: IMediaCut;
  dmcaInfo: IDmcaInfo;
  episode: IMediaEpisode;
  mediaId: string;
  mediaType: string;
  playhead: IPlayhead;
  show: IMediaShow;
  trackName: string;
  type: string;
  youJustHeard: Array<IYouJustHeard>;
  video: IMediaItem;
  stationId?: string;
  hideFromChannelList?: boolean;
  isPodcast?: boolean;
}

export interface INowPlayingRouteActionPayload {
  channel: IChannel;
  onDemandEpisode: IOnDemandEpisode;
  onDemandShow: IOnDemandShow;
  assetType: string;
  subCategory: ISubCategory;
  superCategory: ISuperCategory;
}

export interface INowPlayingStatusActionPayload {
  nowPlayingStatus: ENowPlayingStatus;
}

export interface ISelectVideoPlayerDataActionPayload {
  videoPlayerData: IVideoPlayerData;
}

export interface TuneActionPayload {
  startTime: number;
  mediaType: string;
}

export interface EnterNowPlayingPayload {
  startTime: number;
  mediaType: string;
}

export interface LeaveNowPlayingPayload {
  startTime: number;
}

export interface ChangeContentTypePayload {
  mediaType: string;
}

export const SELECT_NOW_PLAYING: string = '[Now-Playing] select';
export const SELECT_NOW_PLAYING_ROUTE: string = '[Now-Playing-Route] select';
export const SELECT_CHANNEL_LOGO_URL: string =
  '[Now-Playing-Channel-Logo] select';
export const SELECT_CHANNEL_LOGO_ALT_TEXT: string =
  '[Now-Playing-Channel-Logo-Alt-Text] select';
export const SELECT_PLAYING_CHANNEL: string =
  '[Now-Playing-Playing-Channel] select';
export const SELECT_VIDEO_PLAYER_DATA: string =
  '[Now-Playing] Select video player data';
export const SHOW_MINI_PLAYER: string = '[Now-Playing] Show mini player';
export const HIDE_MINI_PLAYER_AND_PDT: string =
  '[Now-Playing] Hide mini player and PDT';
export const ENTER_NOW_PLAYING: string = '[Now-Playing] Enter now playing';
export const CHANGE_CONTENT_TYPE: string = '[Now-Playing] Change content type';
export const LEAVE_NOW_PLAYING: string = '[Now-Playing] Leave now playing';
export const UPDATE_LAST_PLAYHEAD: string =
  '[Now-Playing] Update last playhead';
export const UPDATE_PLAYHEAD: string = '[Now-Playing] Update playhead';
export const RESET_NOW_PLAYING: string = '[Now-Playing] Reset now playing';
export const UPDATE_VIDEO_MARKER: string = '[Now-Playing] Update video marker';
export const SELECT_BACKGROUND_COLOR: string =
  '[Now-Playing] Select background color';
export const UPDATE_MEDIA_PLAYING: string = '[Now-Playing] Is media playing';

/*export class SelectNowPlaying implements IAction {
  readonly type = SELECT_NOW_PLAYING;

  constructor(public payload: INowPlayingActionPayload) {}
}*/

export const selectNowPlaying = (
  payload: INowPlayingActionPayload,
): IAction => {
  return {
    type: SELECT_NOW_PLAYING,
    payload: payload,
  };
};

export class SelectNowPlayingRoute implements IAction {
  readonly type = SELECT_NOW_PLAYING_ROUTE;

  constructor(public payload: INowPlayingRouteActionPayload) {}
}

/*export class SelectChannelLogoUrl implements IAction {
  readonly type = SELECT_CHANNEL_LOGO_URL;

  constructor(public payload: string) {}
}*/

export const selectChannelLogoUrl = (payload: string): IAction => {
  return {
    type: SELECT_CHANNEL_LOGO_URL,
    payload: payload,
  };
};

export class SelectChannelLogoAltText implements IAction {
  readonly type = SELECT_CHANNEL_LOGO_ALT_TEXT;

  constructor(public payload: string) {}
}

/*
export class SelectPlayingChannel implements IAction {
  readonly type = SELECT_PLAYING_CHANNEL;

  constructor(public payload: IChannel) {}
}
*/

export const selectPlayingChannel = (payload: IChannel): IAction => {
  return {
    type: SELECT_PLAYING_CHANNEL,
    payload: payload,
  };
};

export class SelectVideoPlayerData implements IAction {
  readonly type = SELECT_VIDEO_PLAYER_DATA;

  constructor(public payload: ISelectVideoPlayerDataActionPayload) {}
}

export class ShowMiniPlayer implements IAction {
  readonly type = SHOW_MINI_PLAYER;

  constructor(public payload: boolean) {}
}

export class HideMiniPlayerAndPdt implements IAction {
  readonly type = HIDE_MINI_PLAYER_AND_PDT;

  constructor(public payload: boolean) {}
}

/*export class EnterNowPlaying implements IAction {
  readonly type = ENTER_NOW_PLAYING;

  constructor(public payload: EnterNowPlayingPayload) {}
}*/

export const enterNowPlaying = (payload: EnterNowPlayingPayload): IAction => {
  return {
    type: ENTER_NOW_PLAYING,
    payload: payload,
  };
};

export class LeaveNowPlaying implements IAction {
  readonly type = LEAVE_NOW_PLAYING;

  constructor(public payload: LeaveNowPlayingPayload) {}
}

export class UpdateLastPlayhead implements IAction {
  readonly type = UPDATE_LAST_PLAYHEAD;

  constructor(public payload: number) {}
}

/*export class UpdatePlayhead implements IAction {
  readonly type = UPDATE_PLAYHEAD;

  constructor(public payload: IPlayhead) {}
}*/

export const updatePlayhead = (payload: IPlayhead): IAction => {
  return {
    type: UPDATE_PLAYHEAD,
    payload: payload,
  };
};

export class UpdateVideoMarker implements IAction {
  readonly type = UPDATE_VIDEO_MARKER;

  constructor(public payload: IMediaVideo) {}
}

export class SelectBackgroundColor implements IAction {
  readonly type = SELECT_BACKGROUND_COLOR;

  constructor(public payload: string) {}
}

export class ChangeContentType implements IAction {
  readonly type = CHANGE_CONTENT_TYPE;

  constructor(public payload: ChangeContentTypePayload) {}
}

export class ResetNowPlaying {
  readonly type = RESET_NOW_PLAYING;

  constructor() {}
}

/*export class UpdateMediaPlaying implements IAction {
  readonly type = UPDATE_MEDIA_PLAYING;

  constructor(public payload: boolean) {}
}*/

export const updateMediaPlaying = (payload: boolean): IAction => {
  return {
    type: UPDATE_MEDIA_PLAYING,
    payload: payload,
  };
};

/*
export type NowPlayingAction =
  | SelectNowPlaying
  | SelectNowPlayingRoute
  | SelectChannelLogoUrl
  | SelectChannelLogoAltText
  | SelectPlayingChannel
  | SelectVideoPlayerData
  | ShowMiniPlayer
  | HideMiniPlayerAndPdt
  | EnterNowPlaying
  | LeaveNowPlaying
  | UpdateLastPlayhead
  | UpdatePlayhead
  | UpdateVideoMarker
  | SelectBackgroundColor;
  */
