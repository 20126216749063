import React, { ReactNode, useCallback, useState, useEffect } from 'react';
import { ReactComponent as WarningIcon } from '../../assets/images/warning.svg';
import { FocusDiv } from '@accedo/vdkweb-tv-ui';
import { environment } from '@accedo/xdk-core';
import { focusManager } from '@accedo/vdkweb-navigation';
import Button from '../button/Button';
import styles from './modal.module.scss';
import { ttsPlayback } from '../../utils/ttsPlayback.util';

const CONTAINER = 'CONTAINER';
const ACTION = 'ACTION';

const nav = {
  CONTAINER: {
    id: CONTAINER,
    forwardFocus: `${ACTION}_0`,
  },
};

type actionType = {
  label: string;
  action: () => void;
};

type PropTypes = {
  toggleModal?: (ev: Event) => void;
  resetModal?: () => void;
  title?: string;
  text?: string;
  actions?: actionType[];
  children?: ReactNode;
  styleOverride?: { modal: string; closeButton: string };
  restoreFocus?: string;
  show?: boolean;
};

const Modal = ({
  toggleModal,
  resetModal,
  title = '',
  text = '',
  actions = [],
  show,
  children,
  styleOverride = {
    modal: '',
    closeButton: '',
  },
  restoreFocus = '',
}: PropTypes) => {
  const [lastFocus, setLastFocus] = useState<string>('');
  const handleToggle = useCallback(
    ev => {
      if (toggleModal) {
        toggleModal(ev);
      }
    },
    [show],
  );

  const onKeyDown = () => {
    if (resetModal) {
      resetModal();
    }
  };

  useEffect(() => {
    if (show) {
      setLastFocus(focusManager.getCurrentFocus());
      focusManager.changeFocus(CONTAINER);
      if (!children) {
        ttsPlayback(
          `${title || ''}. ${text || ''}. ${actions
            ?.map(action => `${action.label} Button`)
            ?.join(' ') || ''}. You have currently selected ${
            actions[0].label
          } button`,
        );
      }
    } else {
      focusManager.changeFocus(lastFocus || restoreFocus);
    }
  }, [show]);

  useEffect(() => {
    environment.addEventListener(environment.SYSTEM.KEYDOWN, onKeyDown);

    return () => {
      environment.removeEventListener(environment.SYSTEM.KEYDOWN, onKeyDown);
    };
  }, []);

  return (
    <div
      className={styles.overlay}
      onClick={handleToggle}
      style={{
        display: show ? 'block' : 'none',
      }}
    >
      <div className={`${styles.modal} ${styleOverride.modal}`}>
        {children ? (
          <FocusDiv nav={nav.CONTAINER}>{children}</FocusDiv>
        ) : (
          <>
            <div className={styles.warningWrapper}>
              <WarningIcon className={styles.warningIcon} />
            </div>
            <div className={styles.title}>{title}</div>
            <div className={styles.text}>{text}</div>
            <FocusDiv nav={nav.CONTAINER} className={styles.actions}>
              {actions?.map((action, i) => (
                <Button
                  nav={{
                    id: `${ACTION}_${i}`,
                    parent: CONTAINER,
                    nextleft:
                      i === 0
                        ? `${ACTION}_${actions.length - 1}`
                        : `${ACTION}_${i - 1}`,
                    nextright:
                      i === actions.length - 1
                        ? `${ACTION}_0`
                        : `${ACTION}_${i + 1}`,
                  }}
                  className={styles.button}
                  classNameFocused={styles.buttonFocused}
                  key={i}
                  onClick={() => action.action()}
                  onFocus={() => ttsPlayback(`${action.label} button`)}
                >
                  {action.label}
                </Button>
              ))}
            </FocusDiv>
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;
