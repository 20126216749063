import { Observable } from "rxjs";
import { ServiceEndpointConstants } from "../service/consts/service.endpoint.constants";
import { AffinityEventTypes } from "./affinity.consts";
import {
    addProvider,
    IProviderDescriptor,
    Logger
} from "../index";
import {
    SeededRadioModuleRequest,
    ModuleListRequest
} from "../http/types/module.list.request";
import { HttpProvider } from "../http/http.provider";
/**
 * @MODULE:     service-lib
 * @CREATED:    01/17/19
 * @COPYRIGHT:  2019 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 * This delegate is responsible for making calls to affinity endpoint.
 */
export class AffinityDelegate
{
    /**
     * Internal logger.
     */
    private static logger: Logger = Logger.getLogger("AffinityDelegate");
    private static providerDescriptor : IProviderDescriptor = function()
    {
        return addProvider(
            AffinityDelegate,
            AffinityDelegate,
            [HttpProvider]
        );
    }();
    public observable: Observable<number>;
    constructor(private httpProvider: HttpProvider) {}
    /**
     * makes API call to seeded-radio/affinities end-point
     *
     * @param payload container for request params
     * @returns Observable{any} response from API
     */
    public updateAffinity(payload: any) : Observable<any>
    {
        AffinityDelegate.logger.debug("updateAffinity()");

        const moduleRequest = new SeededRadioModuleRequest();

        moduleRequest.type = AffinityEventTypes.UPDATE;
        moduleRequest.stationFactory = payload.stationFactory;
        moduleRequest.stationId = payload.stationId;
        moduleRequest.tracks = payload.tracks;

        const moduleListRequest = new ModuleListRequest();

        moduleListRequest.addModuleRequest(moduleRequest);
        return this.httpProvider.post(
            ServiceEndpointConstants.endpoints.SEEDED_RADIO.V4_AFFINITIES,
            moduleListRequest,
            { isRaw: true }
            );
    }
}
