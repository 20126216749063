import { IGroupedFavorites } from '../../servicelib';
import * as FavoriteListActions from '../action';
import { IAction } from '../action/action.interface';
import { IFavoriteListStore } from '../selector/favorite-list.store';

const defaultAction: IAction = {
  type: '',
  payload: null,
};

const initialState: IFavoriteListStore = {
  favorites: {
    channels: [
      {
        defaultFav: true,
      },
    ],
    shows: [
      {
        defaultFav: true,
      },
    ],
    episodes: [
      {
        defaultFav: true,
      },
    ],
  },
};

const favoritesLoaded: Function = (
  state: IFavoriteListStore,
  payload: IGroupedFavorites,
): IFavoriteListStore => {
  return {
    ...state,
    favorites: payload,
  };
};

export function favoriteListReducer(
  state = initialState,
  action: IAction = defaultAction,
): IFavoriteListStore {
  switch (action.type) {
    case FavoriteListActions.FAVORITES_LOADED:
      return favoritesLoaded(state, action.payload as IGroupedFavorites);

    default:
      return state;
  }
}
