import { ConfigFinderService } from "./config-finder.service";
import { IComponentConfig }    from "../interfaces/config.interface";

export class UrlConfigFinder
{
    public static getUrl(components : Array<IComponentConfig>,
                         componentName: string,
                         settingName: string,
                         filter: {name: string, match:string }): string
    {
        const config = ConfigFinderService.findConfigurationComponent(components,componentName);
        const urlSetting = (config) ? ConfigFinderService.findUrlSetting(config,settingName, filter) : null;
        return (urlSetting && urlSetting.value) ? urlSetting.value : "";
    }

    public static getString(components : Array<IComponentConfig>,
                            componentName: string,
                            settingName: string): string
    {
        const config = ConfigFinderService.findConfigurationComponent(components,componentName);
        const setting = (config) ? ConfigFinderService.findStringSetting(config,settingName) : undefined;
        return (setting && setting.value) ? setting.value : "";
    }
}
