/**
 * @MODULE:     service-lib
 * @CREATED:    08/01/17
 * @COPYRIGHT:  2017 Sirius XM Radio Inc.
 *
 * @DESCRIPTION:
 *
 *      Defines the interfaces for Image objects and name values.
 */
import { ICreativeArtsItem } from './creative.arts.item';

export interface IImage {
  name: string;
  platform: string;
  url: string;
  width: number;
  height: number;
}

export interface IAlbumImage extends IImage, ICreativeArtsItem {
  encrypted: boolean;
  size: string;
}

export interface IShowImage extends IImage {
  encrypted: boolean;
  type: string;
}

// Types to access images from API responses .
export const IMAGE: string = 'image';
export const NAME_BACKGROUND: string = 'background';
export const PLATFORM_WEBEVEREST: string = 'EVEREST_RESPONSIVE';
export const PLATFORM_ANY: string = '';
export const NAME_BLACK_LOGO: string = 'black logo';
export const NAME_BLACK_LOGO_THUMBNAIL: string = 'black logo thumbnail';
export const NAME_COLOR_LOGO: string = 'color logo';
export const NAME_LIST_VIEW_CHANNEL_LOGO: string = 'list view channel logo';
export const NAME_LIST_VIEW_SHOW_LOGO: string = 'show logo on dark';
export const NAME_SHOW_LOGO_WHITE: string = 'show logo white';
export const NAME_SHOW_LOGO_ON_LIGHT: string = 'show logo on light';
export const NAME_LOCK_SCREEN_LOGO: string = 'lock screen logo';
export const NAME_LOGO_ON_WHITE_BG: string = 'logo on white background';
export const NAME_RADIO_CHANNEL_LOGO: string = 'radio channel logo';
export const NAME_WHITE_LOGO: string = 'white logo';
export const NAME_WHITE_LOGO_THUMBNAIL: string = 'white logo thumbnail';
export const NAME_COLOR_CHANNEL_LOGO: string = 'color channel logo (on dark)';
export const PROMO_TILE_IMAGE_NAME = 'promo';
export const POSTER_TILE_IMAGE_NAME = 'poster';
export const TILE_BACKGROUND_IMAGE_NAME = 'background';
export const TILE_CHANNEL_LOGO_IMAGE_NAME = 'channel logo';
export const TILE_CHANNEL_BACKGROUND = 'everest tile channel background';
export const TILE_CHANNEL_THUMB_BACKGROUND =
  'everest tile channel thumb background';
export const TILE_SHOW_BACKGROUND = 'everest tile show background';
export const TILE_SHOW_LOGO_IMAGE_NAME = 'show logo';

// Dimensions to access images from API responses.
export const POSTER_TILE_IMAGE_WIDTH: number = 1920;
export const POSTER_TILE_IMAGE_HEIGHT: number = 1080;

export const NAME_SHOW_LOGO_ON_LIGHT_WIDTH: number = 150;
export const NAME_SHOW_LOGO_ON_LIGHT_HEIGHT: number = 150;

export const NAME_LIST_VIEW_SHOW_LOGO_WIDTH: number = 150;
export const NAME_LIST_VIEW_SHOW_LOGO_HEIGHT: number = 150;

export const NAME_COLOR_CHANNEL_LOGO_WIDTH: number = 250;
export const NAME_COLOR_CHANNEL_LOGO_HEIGHT: number = 150;

export const TILE_CHANNEL_BACKGROUND_WIDTH: number = 256;
export const TILE_CHANNEL_BACKGROUND_HEIGHT: number = 144;

export const NAME_LIST_VIEW_CHANNEL_LOGO_WIDTH: number = 120;
export const NAME_LIST_VIEW_CHANNEL_LOGO_HEIGHT: number = 120;

export const IMAGE_WIDTH: number = 270;
export const IMAGE_HEIGHT: number = 270;

export const AIC_IMAGE_WIDTH: number = 400;
export const AIC_IMAGE_HEIGHT: number = 320;

export const LIVE_IMAGE_WIDTH: number = 900;
export const LIVE_IMAGE_HEIGHT: number = 720;

export const DEFAULT_WIDTH: number = 150;
export const DEFAULT_HEIGHT: number = 150;

export const SIZE_THUMBNAIL: string = 'THUMBNAIL';
export const SIZE_SMALL: string = 'SMALL';
export const SIZE_MEDIUM: string = 'MEDIUM';
