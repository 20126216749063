export class MediaPlayerConstants {
  public static IDLE = 'IDLE'; // local players are idle when user is playing remotely
  public static LOADING = 'LOADING';
  public static LOADED = 'LOADED';
  public static LOAD_START = 'LOAD_START';
  public static ERROR = 'ERROR';
  public static PLAY = 'PLAY';
  public static PLAYING = 'PLAYING';
  public static PLAYED = 'PLAYED';
  public static STOPPED = 'STOPPED';
  public static UNDER_FLOWED = 'UNDER_FLOWED';
  public static BUFFERING = 'BUFFERING';
  public static SEEKING = 'SEEKING';
  public static SEEKED = 'SEEKED';
  public static PRELOADING = 'PRELOADING';
  public static PAUSED = 'PAUSED';
  public static PAUSED_BY_SAFARI = 'PAUSED_BY_SAFARI';
  public static FINISHED = 'FINISHED';
  public static HLS = 'hls';
  public static MIN_VOLUME_VALUE = 0;
  public static MID_VOLUME_VALUE = 50;
  public static MAX_VOLUME_VALUE = 100;
  public static PLAYLIST_SIZE_LARGE = 'LARGE';
  public static PLAYLIST_SIZE_MEDIUM = 'MEDIUM';
  public static PLAYLIST_SIZE_SMALL = 'SMALL';
  public static HTML5_PLAYER = 'html5';
  public static FLASH_PLAYER = 'web';
  public static WINDOWS_PLAYER = 'windows';
}
